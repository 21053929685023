
//#region Configuración
const ACTUALIZAR_CONFIGURACION = `
    mutation update($input: ConfiguracionUpdateInput!) {
        actualizarConfiguracion(input: $input) {
            descripcionLogin
            precioConIVA
            porcentajeDescuento
            ventaSinExistencia
            modificarPrecioPuntoVenta
            serviciosInventariables
            tasaIVA
            descripcionTasaIVA
            porcentajeTasaIVA
            maxPorcentajeDescto
            descripcionMaxPorcentajeDescto
            precisionAgenda
            precisionAgendaFormat
            tiempoIniAgenda
            tiempoFinAgenda
            tiempoCarrusel
            leyendaQRCard
            maxPorcentajeComision
            permitirAgendarClientes
            generarReceta
            permitirModificarHorarioProfesional
            permitirModificarHorarioCliente
            validaHorarios
        }
    }
`;
const ACTUALIZAR_CONFIGURACION_PUNTO_VENTA = `
    mutation update($input: ConfiguracionUpdatePasswordInput!) {
        actualizarConfiguracionPuntoVenta(input: $input) {
            activarSeguridadPuntoVenta
        }
    }
`;
const ACTUALIZAR_URL_LOGOTIPO = `
    mutation updateurlLogotipo($input: ConfiguracionLogotipoInput) {
        actualizarLogo(input: $input) {
            urlLogotipo
        }
    }
`;

const ACTUALIZAR_CORREO_REGISTRO = `
mutation updateCorreoRegistro ($input :ConfiguracionCorreoRegistroInput!)
{
  actualizarCorreoRegistro(input : $input)
  {
    configuracionID
    correoRegistro
  }
}
`;

//#endregion

export {
    ACTUALIZAR_CONFIGURACION,
    ACTUALIZAR_CONFIGURACION_PUNTO_VENTA,
    ACTUALIZAR_URL_LOGOTIPO,
    ACTUALIZAR_CORREO_REGISTRO
}
