import React, { useState, useEffect } from 'react'

//Redux
import { useSelector } from 'react-redux';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_TICKETS } from '../../query';
import { CANCELAR_TICKET } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//helpers
import { formatoMoneda } from '../../Helpers/constantes/formatos';

//Iconos
import { PrintIcon, BlockIcon } from '../../componentesGenericos/Icons';

function Ticket() {
    const clinica = useSelector((state) => state.selecciondeClinica.seleccionClinica[0]);
    let clinicaId = clinica.clinicaId;

    //#region queries
    const { loading, data, error, refetch } = useQuery(LISTADO_TICKETS, {
        fetchPolicy: 'no-cache',
        variables: {
            clinicaId: Number(clinicaId),
            doctorId: null,
            convenioId: null,
            vendedorId: null,
            clienteId: null,
            serieId: null,
            folioId: null,
            fecha:null
        },
        onError: (e) => {
            if (!e.message.includes("No se encontraron registros")) console.log(e.message);
            setErrorState(true);
            setFila(-1);
            setSeleccion(seleccionInicial);

            setListado(null);
        },
        onCompleted: () => {
            setErrorState(false);
            setFila(-1);
            setSeleccion(seleccionInicial);
            let ltk = [];
            let sf = [];
            let fechas = [];

            if (data) {
                data.listadoTickets.tickets.map(p => {

                    if (!sf.includes(`${p.serie}-${p.folio}`)) {

                        ltk[sf.length] = {
                            ...p,
                            servicios: p.descripcion
                        }
                        fechas.push({ fecha: p.fechaEmision, posicion: sf.length });
                        sf.push(`${p.serie}-${p.folio}`);

                    } else {
                        if (p.metodoPago === 'PPD') {
                            let encontrado = fechas.find(fe => fe.fecha === p.fechaEmision);

                            if (encontrado) {
                                ltk[encontrado.posicion] = {
                                    ...p,
                                    servicios: ltk[encontrado.posicion].servicios + ", " + p.descripcion
                                }
                            } else {
                                ltk[sf.length] = {
                                    ...p,
                                    servicios: p.descripcion
                                }
                                fechas.push({ fecha: p.fechaEmision, posicion: sf.length });
                                sf.push(`${p.serie}-${p.folio}`);
                            }
                        } else {
                            let i = sf.indexOf(`${p.serie}-${p.folio}`);

                            ltk[i] = {
                                ...p,
                                servicios: ltk[i].servicios + ", " + p.descripcion
                            }
                        }
                    }
                });
            }
            setListado(ltk);
        }
    });
    //#endregion

    //#region mutations
    const [cancelarTicket] = useMutation(CANCELAR_TICKET, {
        onError: (e) => {
            console.log("Ocurrio un error al cancelar: ", e.message);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace('GraphQL error: ', '') });
        },
        onCompleted: () => {
            refetch().then(({ data }) => {
                setErrorState(false);
                setFila(-1);
                setSeleccion(seleccionInicial);
                let ltk = [];
                let sf = [];

                if (data) {
                    data.listadoTickets.tickets.map(p => {
                        if (!sf.includes(`${p.serie}-${p.folio}`)) {

                            ltk[sf.length] = {
                                ...p,
                                servicios: p.descripcion
                            }
                            sf.push(`${p.serie}-${p.folio}`);

                        } else {
                            let i = sf.indexOf(`${p.serie}-${p.folio}`);

                            ltk[i] = {
                                ...p,
                                servicios: ltk[i].servicios + ", " + p.descripcion
                            }

                        }
                    });
                }
                setListado(ltk);


            }).catch(e => {
                if (!e.message.includes("No se encontraron registros")) console.log(e.message);
                setErrorState(true);
                setFila(-1);
                setSeleccion(seleccionInicial);

                setListado(null);
                setMensaje({ ...mensaje, ...mensajeError, texto: e.message });
            })

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se canceló correctamente el ticket "${seleccion.serie}-${seleccion.folio}"` });
        }
    });
    //#endregion

    //#region states
    const seleccionInicial = {
        servicioId: [],
        descripcion: [],
        cantidad: [],

        porcentajeDescuentoConvenio: [],
        porcentajeDescuento: [],
        porcentajeIva: [],

        precioUnitario: [],
        precioUnitarioDescuento: [],
        precioUnitarioDescuentoConvenio: [],

        descuentoUnitario: [],
        descuentoConvenioUnitario: [],
        descuentoUnitarioTotal: [],

        ivaUnitario: [],
        ivaUnitarioDescuento: [],
        ivaUnitarioTotal: [],

        precioUnitarioTotal: [],

        descuentoCantidad: [],
        descuentoConvenioCantidad: [],
        precioDescuentoCantidad: [],
        precioDescuentoConvenioCantidad: [],

        descuentoTotal: [],
        ivaTotal: [],
        precioTotal: [],

        garantia: [],
        garantiaId: [1],

        empresaId: null,
        clinicaId: null,
        nombreClinica: "",
        almacenId: [],
        direccionId: 266,

        vendedorId: null,
        clienteId: -1,
        doctorId: [],

        formasCobro: [],
        formasCobroCantidad: [],
        metodoPago: null,
        clavePago: null,

        serieId: -1,
        serie: null,
        folioId: -1,
        folio: null,
        turnoId: -1,
        turno: null,

        conceptoId: 24,
        tipoDocumentoId: 4,

        importePago: 0,
        cambio: 0,
        descuentoTotalTicket: 0,
        descuentoTotalConvenioTicket: 0,
        descuentoTotalFinalTicket: 0,
        subtotalTicket: null,
        ivaTotalTicket: 0,
        totalTicket: 0
    };

    const [filtro, setFiltro] = useState("");
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [listado, setListado] = useState(errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : undefined);
    //#endregion

    //#region constantes

    const columnas = errorState ? [
        {
            id: 'msj', label: '', format: (valor) => {
                return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
            }
        }
    ] : [
            { id: 'ticketId', label: 'ticketId', minWidth: 20, hide: true ,aliasColor:"estatus"},
            { id: 'serieId', label: 'serieId', hide: true },
            { id: 'serie', label: 'Serie', hide: true },
            { id: 'folioId', label: 'folioId', hide: true },
            { id: 'folio', label: 'Folio', obtenerColumna: 'serie', align: 'left', textAlign: 'left', format: (v, serie) => { return serie + '-' + v } },
            { id: 'turnoId', label: 'turnoId', hide: true },
            { id: 'turno', label: 'Turno', align: 'left', textAlign: 'left' },

            { id: 'clinica', label: 'Clinica', align: 'left', textAlign: 'left' },

            { id: 'vendedor', label: 'Vendedor', align: 'left', textAlign: 'left' },
            { id: 'cliente', label: 'Cliente', obtenerColumna: 'clienteId', align: 'left', textAlign: 'left', format: (v, id) => id === -1 ? 'Publico general' : v },
            // { id: 'doctorId', label: 'doctor' },
            { id: 'doctor', label: 'Doctor', align: 'left', textAlign: 'left' },
            { id: 'servicios', label: 'Productos', align: 'left', textAlign: 'left' },
            { id: 'descuentoTotalFinalTicket', label: 'Descuento total', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'subtotalTicket', label: 'Subtotal', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'ivaTotalTicket', label: 'IVA total', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'totalTicket', label: 'Total', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'importePago', label: 'Pago', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'metodoPago', label: 'Método de pago', align: 'center', textAlign: 'center', minWidth: 80},
            { id: 'formasCobroTexto', label: 'Descripción  de forma de pago', align: 'left', textAlign: 'left'},
            { id: 'cambio', label: 'Cambio', align: 'right', textAlign: 'right', format: (valor) => formatoMoneda(valor, 2) },
            { id: 'estatus', label: 'Estatus', format: (v) => { return v == 0 ? "Cancelado" : "Activo" } },
            {
                id: 'fechaEmision', label: 'Fecha de emisión', minWidth: 50,
                format: (valor) => {
                    return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
                }
            },

        ];
    const menu = [
        { texto: "Reimprimir Ticket", icono: <PrintIcon />, onClick: () => handleReimprimirClick() },
        { texto: "Cancelar Ticket", icono: <BlockIcon />, onClick: () => handleCancelarClick() },
    ];
    // const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    //     : data?.listadoTickets.tickets;
    //#endregion

    //#region handlers
    function handleReimprimirClick() {
        if (seleccion.serie && seleccion.folio) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea reimprimir el ticket con serie "${seleccion.serie}" y folio "${seleccion.folio}"?`, onAccept: ftReimprimir });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Seleccione un ticket para reimprimir" });
        }
    }

    function handleCancelarClick() {
        if (seleccion.serie && seleccion.folio) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de cancelar el ticket con serie "${seleccion.serie}" y folio "${seleccion.folio}"?`, onAccept: ftCancelar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Seleccione un ticket para cancelar" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setFiltro(texto)
    }

    function handlePaginaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }


    //#endregion

    //#region funciones
    function ftReimprimir() {
        setDisabledAccept(true);
        window.open('/ver-ticket/' + seleccion.ticketId, '_blank');
        ftMensajeClose();
        setDisabledAccept(false);
    }

    function ftCancelar() {
        cancelarTicket({
            variables: {
                serieId: seleccion.serieId,
                folio: Number(seleccion.folio)
            }
        });
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    //#endregion

    useEffect(() => {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }, [clinica])

    return (
        <MenuLateral titulo="Tickets" menu={menu}>
            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledAccept} />

            <Tabla titulo="Listado de tickets" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} claseCelda={{ fontSize: 14, padding: '8px 5px' }} claseEncabezado={{ padding: '2px 8px' }} claseTabla={{ maxHeight: '75vh' }} onPaginaChange={handlePaginaChange} titleResponsive={["serie", "folio", "clinica", "vendedor", "doctor"]} />

        </MenuLateral >
    )
}

export default Ticket
