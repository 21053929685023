import React from 'react';
//Iconos
import { GroupAddIcon, DeleteForever,SwitchIcon,EditIcon,ThumbUpIcon} from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    clinicaId:null,
    nombre:null,
    alias:null,
    estatus:null
};

//menu lateral
const menu = (handleAgregarClick, handleModificarClick,handleEliminarClick,handleCambiarEstatusClick,checked,redirige) => [
    { texto: "Asignar convenio", icono: <GroupAddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar fecha de baja", icono: <EditIcon />, onClick: () => handleModificarClick() },
    { texto: "Eliminar asignación", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
    { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
    { texto: "Desplazarse a convenios", icono: <ThumbUpIcon />, onClick: (e) => redirige("/convenios") }

];

/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    clinicaId:null,
    nombre:null,
    alias:null,
    estatus:null
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
  };


export {  seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,Style };
