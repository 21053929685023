import gql from 'graphql-tag';

//#region LISTADO ENCABEZADOS
const FILTRO_ENCABEZADO = gql`
	query ListadoEncabezado($limite: Int, $pagina: Int, $encabezadoId: Int, $almacenId: Int, $ccodtoId: Int) {
    listadoEncabezado(limite: $limite, pagina: $pagina, encabezadoId: $encabezadoId, almacenId: $almacenId, ccostoId: $ccodtoId) {
     encabezados{
      encabezadoId
      clinicaId
      nombreClinica
      almacenId
      nombreAlmacen
      ccostoId
      nombreCcosto
    }
    registros
    paginas
    }
  }
`;
//=============================================
////#endregion

export {
    FILTRO_ENCABEZADO
}
