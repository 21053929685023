import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Input from '../../Reportes/componentes/Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { queryConfiguracion,queryConfiguracionAdminis,ActualizaCorreoRegistro } from '../Metodos';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'

import { CRMContext } from '../../../context/Configuracion';

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ConfiguracionCorreoRegistro({ setMensaje }) {

    const classes = useStyles();

    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;

    const defaultState = {
        correo: null
    };

    const [ state, setState ] = useState(defaultState);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [actualizar, setActualizar] = useContext(CRMContext);
    const [listAdmins,setListAdmins] = useState({});

	const campos = [{
        id: 'correo',
        texto: 'Seleccionar correo',
        valor: state.correo,
        tipo: 'select',
        opciones:listAdmins,
        propiedades: { readOnly: false },
        xs: 12
    }];
	const handleChange = e => {
        console.log("STATE:",e.target);
        setState({...state,correo: e.target.value})
    }
    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                let { error } = await ActualizaCorreoRegistro(state.correo);

                if(error) throw new Error(error);

                setActualizar(true);
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡Se guardo correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
            }
            setDisabledAccept(false);
        }
        click();
    }

    useEffect(() => {
        async function ftEffect() {
            let sesiones = process.env.REACT_APP_DB;

            const configuracion = await queryConfiguracion({
                nombreDB: sesiones,
            });
            const administradores = await queryConfiguracionAdminis();
            setListAdmins(administradores);
            setState({...state,correo: configuracion.correoRegistro});
        } ftEffect();

    }, []);

    return(
        <>
         <Typography variant="h5" component="h5">Seleccionar correo para registro</Typography>
        <Input campos={campos} handleChange={handleChange} state={state} />
        <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Guardar" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
    </>);



}

export default ConfiguracionCorreoRegistro;
