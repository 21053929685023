import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputGrid from '../../Formulario/componentes/InputGrid';

import Elementos from './Elementos';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

export default function ActionsInAccordionSummary({
    columnas = [],
    datos = [],
    titleColumna = "",
    pagina,
    filasPagina,
    onClick = () => { },
    claseCelda,
    claseFila,
    hover,
    indice = -1,
    seleccionable  = true,
    encabezado = true,
    filtro,
    titulo,
    onSearch,
    busqueda = true,
    tituloBusqueda = "Buscar",
    filtros = [],
    titleResponsive = [],
    onFiltrosChange = () => { }
}) {

    const classes = useStyles();
    const [checked, setChecked] = useState({});

    function ftClick(row, key) { onClick(row, key); }
    let id = "";
    let seleccionada = false;

    function cleanChecked (datos, pagina, filasPagina, key) {
        let ids = {};
        datos.slice(pagina * filasPagina, pagina * filasPagina + filasPagina).forEach((row, key) => {
            if(key === `${row[Object.keys(row)[0]]}_${key}`) return;
            ids = { ...ids, [`${row[Object.keys(row)[0]]}_${key}`]: false };
        });
        return ids;
    }

    let ids = cleanChecked(datos, pagina, filasPagina);

    function onChange(e) {
        onFiltrosChange(e);
    }

    useEffect(() => {
        if(indice === -1 || Number(pagina) > 1) {
            let ids = cleanChecked(datos, pagina, filasPagina);
            setChecked({ ...ids });
        }
    }, [indice, pagina]);

    useEffect(() => {
        if(Object.keys(checked).length === 0) setChecked({ ...ids });
    }, []);

    return (
        <div className={classes.root}>
            {
                encabezado && <div style={{ padding: "1em" }}>
                    <Typography variant="h6">
                        {titulo}
                    </Typography>
                </div>
            }
            <br />
            {
                filtros.length ? <InputGrid xs={4} tipo="select" id={filtros[0].id} texto={filtros[0].texto} valor={filtros[0].valor} opciones={filtros[0].opciones} /*otros estilosGrid?*/ handleChange={onChange} /> : null
            }
            <br />
            {busqueda === true ?
                <div style={{ padding: '0 5px', margin: '5px', border: '1px solid #c9c9c9', borderRadius: '5px' }}>
                    <InputBase value={filtro} name='filtroTabla' style={{ width: "80%" }} placeholder={tituloBusqueda} inputProps={{ 'aria-label': 'Search' }} onChange={(e) => onSearch ? onSearch(e.target.value) : null} title={tituloBusqueda} />
                    <IconButton type="submit" className={classes.iconButton} aria-label="search" style={{ width: "10%" }}>
                        <SearchIcon />
                    </IconButton>
                </div>
                :
                null
            }
             <br />
            {(datos[0] && datos[0].msj === "No se encontraron registros") ? <div style={{ padding: "1em" }}>
                    <Typography variant="h6">
                        No se encontraron registros
                    </Typography>
                </div> : datos.slice(pagina * filasPagina, pagina * filasPagina + filasPagina).map((row, key) => {
                seleccionada = seleccionable ? Number(indice) === key : false;
                let titulo = "";
                if(Array.isArray(titleResponsive) && titleResponsive.length > 0) {
                    titleResponsive.forEach(key => {
                        columnas.forEach(columna => {
                            if(columna.id === key && !columna.hide) {
                                if(columna.obtenerColumna) id = row[columna.obtenerColumna];
                                const value = row[columna.id];

                                let formato = columna.format ? columna.format(value, id) : value
                                titulo += `${columna.label}: ${formato} - `;

                            }
                        });
                    });
                    titulo = titulo.substring(0, titulo.length - 3);
                }

                return <Accordion key={`${row[Object.keys(row)[0]]}_${key}`}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    >
                        <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            control={<Checkbox checked={checked[`${row[Object.keys(row)[0]]}_${key}`] ? checked[`${row[Object.keys(row)[0]]}_${key}`] : seleccionada} onClick={() => {
                                ftClick(row, key);
                                let ids = cleanChecked(datos, pagina, filasPagina, [`${row[Object.keys(row)[0]]}_${key}`]);
                                setChecked(checked => ({ ...ids, [`${row[Object.keys(row)[0]]}_${key}`]: !checked[`${row[Object.keys(row)[0]]}_${key}`] }));
                            }} />}
                            label={<span style={{ fontSize: '0.75em'}}>{titulo}</span>}
                        />
                    </AccordionSummary>
                    {
                        columnas.map((column, llave) => {
                            if( column.obtenerColumna) {
                                id=row[column.obtenerColumna];
                            }
                            const value = row[column.id];

                            return <div key={llave}>
                                {
                                    !column.hide && <AccordionDetails>
                                        <Typography>
                                            <b>{column.label}</b> { column.format ? column.format(value,id) : Elementos(column.component, value,id) }
                                        </Typography>
                                    </AccordionDetails>
                                }
                            </div>
                        })
                    }
                </Accordion>
            })}
        </div>
    );
}
