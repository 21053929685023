import gql from 'graphql-tag';

//#region Serie
const LISTADO_SERIE=gql`
query listadoSerie(
  $clinicaId: Int
  $serieNombre: String
  $tipoDocumentoId: Int
  $estatusSerie: Int
  $porDefecto: Int
) {
  listadoSerie(
    clinicaId: $clinicaId
    serieNombre: $serieNombre
    tipoDocumentoId: $tipoDocumentoId
    estatusSerie: $estatusSerie
    porDefecto: $porDefecto
  ) {
    series {
      serieId
      serieNombre
      tipoDocumentoId
      nombreDocumento
      estatusSerie
      porDefecto
      clinicaId
      nombreClinica
      aliasClinica
      estatusClinica
      fechaAltaSerie
    }
  }
}
  `;

const OBTENER_SERIE=gql`
  query obtenerSerie($clinicaId: Int!) {
    obtenerSerie(clinicaId: $clinicaId) {
      serieId
      serieNombre
      tipoDocumentoId
      nombreDocumento
      estatusSerie
      porDefecto
      clinicaId
      nombreClinica
      aliasClinica
      estatusClinica
    }
  }
  `;
 //#endregion

export {
    LISTADO_SERIE,
    OBTENER_SERIE
}
