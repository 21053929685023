import React, { useContext, useState, useEffect } from 'react';
//Core
import { Grid,FormHelperText } from '../../../../componentesGenericos/Core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/ConveniosUsuarios';
//Query
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_PACIENTE_FETCH } from '../../../../query/componentes/Usuario';
import {LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH} from '../../../../query/componentes/Convenio';
//Dependencias


function AgregarRelacion() {

    let [conveniosUsuarios, guardarConveniosUsuarios] = useContext(CRMContext);
    let [listadoPacientes, guardarListadoPacientes] = useState([]);
    let [listadoConvenios, guardarListadoConvenios] = useState([]);

    //EVENTOS
    const handleChangeGuardarStateConvenioId = (event, values) => {
        if (values === null || values.length === 0  ) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    convenioId: {
                        error: true, valor:""
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    convenioId: {
                        error: false, valor:values.convenioId
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateUsuarioId = (event, values) => {
        if (values === null || values.length === 0  ) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    usuarioId: {
                        error: true, valor:""
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    usuarioId: {
                        error: false, valor:values.usuarioId
                    }
                }
            });
        }

    }

    //API
    const obtenerPacientes = async () => {
        await FetchGrahpQL({
            query: LISTADO_PACIENTE_FETCH,
            variables: {
                offset: 0,
                moroso:null
            }
        })
            .then(resp => {
                let row = [];
                resp.listadoPaciente.pacientes.map(function (x) {
                    row.push({nombreCompleto:x.nombreCompleto,email:x.email,usuarioId:x.usuarioId});
                });
                guardarListadoPacientes(row)
            })
            .catch(() => {
            });
    };
    const obtenerConvenios = async () => {
        await FetchGrahpQL({
            query: LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH,
            variables: {
                offset: 0
            }
        })
            .then(resp => {

                let row = [];
                resp.listadoTodosLosConvenios.convenios.map(function (x) {
                    row.push({convenioId:x.convenioId,empresa:x.empresa});
                });
                guardarListadoConvenios(row)
            })
            .catch((err) => {

            });

    };

    //CICLO DE VIDA
    useEffect(() => {
        obtenerPacientes();
        obtenerConvenios();
    }, []);

    return (<>
        <Fieldset contenido={
            <div>
                <Grid spacing={3} container direction="row">
                    <Grid item xs={12}>
                        <Autocomplete
                        noOptionsText={"Sin convenio"}
                        fullWidth
                            id="combo-box-demo"
                            options={listadoConvenios}
                            onChange={handleChangeGuardarStateConvenioId}
                            name="convenioId"
                            getOptionLabel={(option) => {return (`${option.empresa}`)}}
                            renderInput={(params) => <TextField required={true} {...params}   fullWidth label="Selecione el convenio" variant="outlined" />}

                        />
                        <FormHelperText>Seleccione el convenio activo y con vigencia valida</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                        noOptionsText={"Sin clientes"}
                        fullWidth
                            id="combo-box-demo"
                            options={listadoPacientes}
                            name="usuarioId"
                            onChange={handleChangeGuardarStateUsuarioId}
                            getOptionLabel={(option) => {return (`${option.nombreCompleto} (${option.email})`)}}
                            renderInput={(params) => <TextField required={true} {...params} fullWidth label="Selecione al cliente" variant="outlined" />}
                        />
                        <FormHelperText>Seleccione el cliente</FormHelperText>
                    </Grid>


                </Grid>
            </div>
        } />
    </>);
}

export default AgregarRelacion;
