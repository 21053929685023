import React from 'react';
//Iconos
import { GroupAddIcon, EditIcon} from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    clinicaId:null,
    nombre:null,
    alias:null,
    estatus:null
};
//seleccion para columnas
const columnas = [
    { id: 'clinicaId', label: 'ID ', minWidth: 0, hide: false },
    { id: 'nombre', label: 'Nombre', minWidth: 170, align: 'center', filtro: true },
    { id: 'alias', label: 'Alias', minWidth: 170, align: 'center', filtro: true },
    {
        id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center', format: (valor) => {
            return valor.toString().replace("false", "Inactivo").replace("true", "Activo");
        }
    }
];
//menu lateral
const menu = (handleAgregarClick, handleModificarClick) => [
    { texto: "Asignar  profesionales a sucursales", icono: <GroupAddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Desasignar  profesionales de sucursales", icono: <EditIcon />, onClick: () => handleModificarClick() }
];

/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    clinicaId:null,
    nombre:null,
    alias:null,
    estatus:null
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
  };


export { columnas, seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,Style };
