import React, { useState, useEffect } from 'react';
//apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { FILTRO_ENCABEZADO, LISTADO_CENTRO_COSTO,  LISTADO_ALMACENES, LISTADO_CLINICA_MATRIZ, SELECT_CLINICAS } from '../../query';
//Mutations
import { NUEVA_ENCABEZADO, MODIFICAR_ENCABEZADO, ELIMINAR_ENCABEZADO } from '../../mutation';
//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';
//Constantes
import {  actualizarState, filaState, seleccionInicial, formularioState, seleccionState, menu } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';
//REDUX
import { useSelector, useDispatch } from 'react-redux';
//Helpers
import { rgxNumero, rgxNoVacio } from '../../Helpers/constantes/Rgx'
import { distinto } from '../../Helpers/constantes/Config/Helper'

export default function Inventario() {

    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const productoIdRef = React.useRef();
    const clinicaIdRef = React.useRef();
    const [seleccionclinica2, setSeleccionclinica2] = useState(null);
    const [opcionesAlm, setOpcionesAlm] = useState([{ label: "Seleccione una sucursal", value: -1 }])
    const [opcionesCc, setOpcionesCc] = useState([{ label: "Seleccione una sucursal", value: -1 }])
    // # Region Queries & Mutations
    const { loading, error, data, refetch } = useQuery(FILTRO_ENCABEZADO, {
        variables: { limite: 10000, pagina: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            setErrorState(true);
        },
        onCompleted: (e) => {
            setErrorState(false);

        }
    });

    const { data: dataClinicaMatriz } = useQuery(LISTADO_CLINICA_MATRIZ, {
        variables: { matriz: true },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            // console.log(e)
        },
        onCompleted: () => {
            setErrorState(false);
            // let matriiz ;
            // let matrizClinica = dataClinicaMatriz ?
            //     dataClinicaMatriz.listadoClinicas.clinicas.map(
            //     // matriiz = matrizClinica[0].value
            //     //     valor => ({
            //     //     label: valor.nombreClinica,
            //     //     value: valor.clinicaId
            //     // })
            //     )
            //     : null

            // let matriz = matrizClinica[0].value;

            // setCmatriz(Number(matriz));
        }
    });


    // const { data: dataProductos } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
    //     variables: { offset: 0, tipo: 2, disponible: 1, clinica: null },
    //     onError: (e) => {
    //         if (!e.message.includes('No se encontraron registros'))
    //             setErrorState(true);
    //     },
    //     onCompleted: () => {
    //         setErrorState(false);
    //         if (dataProductos) {
    //             setProductoList(dataProductos.listadoProductosServicios.productosServicios)
    //         }
    //     }
    // });

    const { data: dataClinica } = useQuery(SELECT_CLINICAS, {
        variables: { limite: 100, activas: true },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
            if (dataClinica) {

                setClinicasList(dataClinica.listadoClinicas.clinicas);

            }
        }
    });

    const { data: dataAlmacenes, refetch: AlmacenesRefecth } = useQuery(LISTADO_ALMACENES, {
        variables: { limite: 100, clinica: Number(seleccionclinica2), almacenId: seleccionclinica2 || -1, estatus: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);

    let opcionesAlmacen = []

    if (dataAlmacenes) if (seleccionclinica2 !== null) {
        dataAlmacenes.listadoAlmacen.Almacenes.map(valor => {

            if (valor.clinicaId === seleccionclinica2) {
                opcionesAlmacen.push({
                    label: valor.descripcion,
                    value: valor.almacenId
                })
            }
        })
        setOpcionesAlm(opcionesAlmacen)
    } else{
        setOpcionesAlm([{ label: "Seleccione una sucursal", value: -1 }]);
    }
        }
    });



    const { data: dataCcosto, refetch: CcRefetch } = useQuery(LISTADO_CENTRO_COSTO, {
        variables: { estatus: true, clinicaId: seleccionclinica2, estatus: true },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
            let opcionesCcosto = [];
    if (dataCcosto) if (seleccionclinica2 !== null) {
        if (dataCcosto.listadoCentrocostos) {
            dataCcosto.listadoCentrocostos.CentrosDeCostos.map(valor => {
                if (valor.clinicaId === seleccionclinica2) {
                    opcionesCcosto.push({
                        label: valor.nombreCcosto,
                        value: valor.ccostoId
                    })
                }
            })
        }
        setOpcionesCc(opcionesCcosto)
    }else{
        setOpcionesCc([{ label: "Seleccione una sucursal", value: -1 }]);
    }
        }
    });

    const [addEncabezado] = useMutation(NUEVA_ENCABEZADO, {
        onError: (e) => {

            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el encabezado` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            // setSeleccionclinica2({...seleccionclinica2, ...seleccionInicial})
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modEncabezado] = useMutation(MODIFICAR_ENCABEZADO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            let opcion = campo === 0 ? "entrada" : "salida"
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el registro` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteEncabezado] = useMutation(ELIMINAR_ENCABEZADO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el registro` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    // # Región States


    const [clinicaslist, setClinicasList] = useState([{ nombre: 'No se encontraron sucursales', id: null }]);

    const [productolist, setProductoList] = useState([{ nombre: 'No se encontraron productos', id: null }]);

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);

    const [entrada, setEntrada] = useState([]);

    const [campo, setCampo] = React.useState('');

    const [cmatriz, setCmatriz] = React.useState('');

    const [fila, setFila] = useState(filaState);

    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);


    const colExistencias = errorState ? [{
        id: 'msj', label: 'No se encontraron registros', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }]
        : [
            { id: 'existenciaId', label: 'existenciaId', minWidth: 0, hide: true },
            // { id: 'empresaId', label: 'empresaId', minWidth: 0, hide: true },
            // { id: 'nombreEmpresa', label: 'Empresa', minWidth: 100, align: 'center', textAlign: 'center' },
            { id: 'clinicaId', label: 'clinicaID', minWidth: 0, hide: true },
            { id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left' },
            { id: 'almacenId', label: 'almacenId', minWidth: 0, hide: true },
            { id: 'nombreAlmacen', label: 'Almacén', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            // { id: 'productoId', label: 'productoId', minWidth: 0, hide: true },
            // { id: 'producto', label: 'Producto', minWidth: 100, align: 'center', textAlign: 'center', filtro: true },
            { id: 'ccostoId', label: 'ccostoId', minWidth: 0, hide: true },
            { id: 'nombreCcosto', label: 'Centro de costo', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            // { id: 'existenciaInicial', label: 'Existencia Inicial', minWidth: 100, align: 'center', textAlign: 'center', filtro: true }, { id: 'entradas', label: 'Entrada', minWidth: 100, align: 'center', textAlign: 'center', filtro: true },
            // { id: 'salidas', label: 'Salida', minWidth: 100, align: 'center', textAlign: 'center', filtro: true },
            // { id: 'existenciaActual', label: 'Existencia Actual', minWidth: 100, align: 'center', textAlign: 'center', filtro: true }
        ];

    //tipo de formulario
    const [formulario, setFormulario] = useState(formularioState);







    const clinicasProps = {
        options: clinicaslist,
        getOptionLabel: option => option.nombre,
        noOptionsText:'No se encontraron registros'
    }


    // // Campos Formulario
    const camposFormulario = [
        {
            id: "clinicaId",
            texto: "Sucursal *",
            valor: clinicaslist ? seleccion.clinicaId ? clinicaslist.find(val => Number(val.clinicaId) == Number(seleccion.clinicaId)) || -1 : -1 : -1,
            tipo: "autocomplete",
            regexp: rgxNoVacio,
            ayuda: "Campo obligatorio",
            xs: 12,
            propiedades: clinicasProps,
            onChange: handleClinicaChange,
            cleanDatoState: [{ id: 'servicioId', value: '' }, { id: 'almacenId', value: '' }]
        },
        { id: "almacenId", texto: "Almacén *", valor: seleccion.almacenId !== null ? seleccion.almacenId : -1, tipo: "select", opciones: opcionesAlm, xs: 12, styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'},},

        { id: "ccostoId", texto: "Centro de costo", valor: seleccion.ccostoId !== null ? Number(seleccion.ccostoId) : -1, tipo: "select", opciones: opcionesCc, xs: 12, styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'}, },
        {
            id: "etiqueta", texto: "etiqueta", valor: null, tipo: "hidden", xs: 12
        }

    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoEncabezado?.encabezados;

    //Region Handlers
    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: (state) => ftAgregar(state) });
    }

    function handleModificarClick() {
        if (seleccion.encabezadoId !== null) {
            setFila(-1);
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });

        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar una ubicación" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.encabezadoId !== null) {
            setFila(-1);
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la ubicación seleccionada?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar una ubicación" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setFila(index);
            setSeleccionclinica2(datos.clinicaId);
        }
    }



    function handleClinicaChange(e, val, limpiar) {
        clinicaIdRef.current = val ? Number(val.clinicaId) : null;
        setSeleccion({ clinicaId: val ? Number(val.clinicaId) : null, ...limpiar })
        setSeleccionclinica2(val !== null ? Number(val.clinicaId) : 0);
    }

    // //Region funciones

    function ftAgregar({ clinicaId, almacenId, ccostoId, cleanStateFormulario }) {

        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                clinicaId = clinicaId || seleccion.clinicaId;

                if (ftValidar([clinicaId, almacenId])) {

                    await addEncabezado({
                        variables: {
                            encabezado: {
                                clinicaId: Number(clinicaId),
                                almacenId: Number(almacenId),
                                ccostoId: ccostoId !== -1 ? Number(ccostoId) : null,

                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);


            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function ftModificar({ clinicaId, almacenId, ccostoId, cleanStateFormulario }) {

        async function ftModificar() {
            try {
                setDisabledAccept(true);
                clinicaId = clinicaId || seleccion.clinicaId;
                almacenId = almacenId || seleccion.almacenId;
                ccostoId = ccostoId || seleccion.ccostoId;

                if (ftValidar([seleccion.encabezadoId,]) === true) {
                    await modEncabezado({
                        variables: {
                            encabezado: {
                                encabezadoId: Number(seleccion.encabezadoId),
                                clinicaId: Number(clinicaId),
                                almacenId: Number(almacenId),
                                ccostoId: ccostoId !== -1 ? Number(ccostoId) : null,
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                    refetch();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteEncabezado({ variables: { id: Number(seleccion.encabezadoId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    // function ftMensajeCancel() {
    //     ftMensajeClose();
    // }

    function ftFormularioClose() {
        setSeleccion(seleccionInicial)
        setFormulario({ ...formulario, abrir: false });
    }

    // function ftFormularioModificarClose() {
    //     setFormularioModificar({ ...formulariomodificar, abrir: false });
    // }

    function stateOut(valorID = {}) {
        setSeleccion({
            ...seleccion,
            ...valorID
        });

    }

    function redireccionar() {
        if (seleccion.encabezadoId !== null && seleccion.clinicaId !== null && seleccion.encabezadoId !== undefined && seleccion.clinicaId !== undefined) {
            window.location.href = "/existencias/" + seleccion.encabezadoId + "/" + seleccion.clinicaId
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una ubicación" })
        }
    }

    // Recargar
    if (actualizar === true) { refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) }); setActualizar(false); }

    useEffect(() => {
        setActualizar(true);
    }, [_clinicaID]);

    useEffect(()=>{
        AlmacenesRefecth()
        .then((data) => {console.log("Data:", data)})
        .catch((e) => {
            setOpcionesAlm([{ label: `${e.message.replace('GraphQL error:',"")}`, value: -1 }])
        })

        CcRefetch()
        .then((data) => {console.log("Data:", data)})
        .catch((e) => {
            setOpcionesCc([{label: `${e.message.replace('GraphQL error:',"")}`, value: -1 }])
        })
    },[seleccionclinica2])

    return (
        <MenuLateral titulo="Entradas y salidas"
            menu={menu(
                handleAgregarClick,
                handleModificarClick,
                handleEliminarClick,
                redireccionar
            )}
        >
            <Formulario
                abrir={formulario.abrir}
                campos={camposFormulario}
                titulo="Ubicación física "
                onClose={ftFormularioClose}
                onAccept={formulario.onAccept}
                datos={seleccion}
                disabledAccept={disabledAccept}
                cleanAwait={true}
                stateLimpio={seleccionInicial}
                stateOut={stateOut}
                fullWidth={true} />

            <Mensaje
                titulo={mensaje.titulo}
                abrir={mensaje.abrir}
                texto={mensaje.texto}
                onClose={ftMensajeClose}
                icono={mensaje.icono}
                tipo={mensaje.tipo}
                color={mensaje.color}
                onAccept={mensaje.onAccept}
                disabledAccept={disabledDelete}
                progress={disabledDelete}
            />

            <Tabla titulo="Inventarios" columnas={colExistencias} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreClinica", "nombreAlmacen"]} />

        </MenuLateral>
    )
}
