import { CONVENIOS_USUARIOS } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_CONVENIO_USUARIO_ID, MODIFICAR_CONVENIO_USUARIO_ID } from "../../mutation/componentes/ConveniosUsuarios";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos,ActivarDesactivarBoton,ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVO_CONVENIO_USUARIO_ID,
                variables: {
                    convenioId: datos.convenioId.valor,
                    usuarioId: datos.usuarioId.valor
                }
            })
                .then(() => {
                    Swal.fire("¡Éxito!", `Se asignó el convenio correctamente al cliente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado, inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        } else {
            FetchGrahpQL({
                query: MODIFICAR_CONVENIO_USUARIO_ID,
                variables: {
                    convenioId: datos.convenioId.valor,
                    usuarioId: datos.usuarioId.valor,
                    fechaBajaConveniosUsuarios: datos.fechaBajaConveniosUsuarios.valor,
                }
            })
                .then(() => {
                     Swal.fire("¡Éxito!", `Se modificó  correctamente`, "success");
                     ActivarDesactivarBoton(false);
                     actualiza();
                     ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                     Swal.fire("¡Aviso!", err.message, "warning");
                    }
                     ActivarDesactivarBoton(false);

                });
        }
        dispatch(Asingar({ activo: true }))
    }
}


export const Asingar = datos => {
    return {
        type: CONVENIOS_USUARIOS,
        payload: datos
    }
}




