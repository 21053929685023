
export function obtenerStateInicialSelectedMenu(menu) {

    let menuSelected = {};

    if(Array.isArray(menu)) {

        menu.forEach(({id, subMenu = []}) => {

            let sMenu = {}, dato = {};

            if(id) dato = { [id]: false };

            if(Array.isArray(subMenu)) {

                subMenu.forEach(({subId}) => {

                    let sDato = {}

                    if(subId) sDato = { [subId]: false };

                    sMenu = {
                        ...sMenu,
                        ...sDato
                    };

                });

            }

            menuSelected = {
                ...menuSelected,
                ...sMenu,
                ...dato
            }

        });

    }

    return menuSelected;

}

export function obtenerIDSelectURL(menu, path) {

    let ruta = `/${path}`;
    let resultado = {};

    if(Array.isArray(menu)) {

        menu.forEach(({id, subMenu = [], url}) => {

            if(ruta === url) {
                resultado = { select: id }
                return;
            }

            if(Array.isArray(subMenu)) {

                subMenu.forEach(({subId, url}) => {

                    if(ruta === url) {
                        resultado = {
                            open: id,
                            select: subId
                        }
                        return;
                    }

                });

            }

        });

    }

    return resultado;

}

export function handleClick(menuSeleccion, setMenu, id, menu, cleanAll = {}) {

    let limpiarSeleccion = { ...menuSeleccion[menu] };
    let keys = Object.keys(limpiarSeleccion);

    keys.forEach(key => {
        limpiarSeleccion = {
            ...limpiarSeleccion,
            [key]: (menu === "openMenu" && key === id) ? menuSeleccion[menu][id] : false
        }
    });

    let newID = !id ? { } : { [id]: !limpiarSeleccion[id] };

    setMenu({
        ...menuSeleccion,
        ...cleanAll,
        [menu]: {
            ...limpiarSeleccion,
            ...newID
        }
    });

}

export function limpiarMenu(menuSeleccion) {

    let limpiarSeleccion = { ...menuSeleccion };
    let keys = Object.keys(limpiarSeleccion);

    keys.forEach(key => {

        let limpiarSubSeleccion = { ...limpiarSeleccion[key] };
        let subKeys = Object.keys(limpiarSubSeleccion);

        subKeys.forEach(subKey => {
            limpiarSubSeleccion = {
                ...limpiarSubSeleccion,
                [subKey]: false
            }
        });

        limpiarSeleccion = {
            ...limpiarSeleccion,
            [key]: { ...limpiarSubSeleccion }
        }

    });

    return { ...limpiarSeleccion };

}
