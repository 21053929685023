import React from 'react';

//Material-ui
import { DialogContent, DialogContentText } from '@material-ui/core';
import Fieldset from '../../FieldSet';

export default function ContenidoDialog({ tipo = "dialogo", texto = "dialogo", color, children, classes = {}, titulo = "", subParrafo = '' }) {

    return (
        <DialogContent className={classes.root}>
            {
                tipo === "dialogo" ?
                    (children ? children :
                        <>
                            <DialogContentText color={color || "inherit"} style={{ fontSize: '1.125em' }}>
                                {texto}
                            </DialogContentText>
                            <DialogContentText color={color || "inherit"} style={{ fontSize: '0.8em' }}>
                                {subParrafo}
                            </DialogContentText>

                        </>)
                    :
                    <Fieldset contenido={children} Titulo={titulo}></Fieldset>
            }
        </DialogContent>
    );
}
