import gql from 'graphql-tag';

//#region Seguridad
const LISTADO_SEGURIDAD= gql`
    query listadoSeguridad($limite:Int,$pagina:Int){
        listadoSeguridad(limite:$limite,pagina:$pagina){
            Seguridad{
                seguridadId
                perfilId
                tipoUsuarioId
                descripcion
                permisos
            }
            registros
            paginas
        }
    }
`;

const OBTENER_PERMISO=gql`
    query obt($tipo:Int!,$vista:String!){
        obtenerPermiso(tipo:$tipo,vista:$vista)
    }
`;

// const OBTENER_SEGURIDAD=gql`
//     query obtenerSeguridad($id:ID!){
//         obtenerSeguridad(id:$id){
//             seguridadId
//             tipoUsuarioId
//             perfilId
//             descripcion
//             permisos
//         }
//     }
// `;
//#endregion
//================================================

export {
    LISTADO_SEGURIDAD,
    OBTENER_PERMISO
}
