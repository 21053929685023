import React, { useState, useContext } from 'react';
import { CRMContext, CRMProvider } from './../../context/ConveniosUsuarios';
//Apollo*****
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries****
import { LISTAR_CONVENIO_USUARIO_ID } from '../../query/componentes/ConvenioUsuarios';
import {
    ELIMINAR_CONVENIO_USUARIO
} from '../../mutation/componentes/ConveniosUsuarios';
import {
    CAMBIAR_ESTATUS_CONVENIO_USUARIO
} from '../../mutation/componentes/ConveniosUsuarios';
//Componentes***
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Agregar from './Agregar';
import Modificar from './Modificar';


//constantes***
import { seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState, Style } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

function ConveniosPacientes(props) {
    let { history } = props;


    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTAR_CONVENIO_USUARIO_ID, {
        variables: { offset: 0, },
        fetchPolicy: 'no-cache',
        onError: (e) => {  }
    });

    const [deleteConvenioUsuario] = useMutation(ELIMINAR_CONVENIO_USUARIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el convenio con el cliente` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusUsuario] = useMutation(CAMBIAR_ESTATUS_CONVENIO_USUARIO, {

        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: (e) => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente` });
            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });


    //#endregion

    //#region states
    const [asignacion, guardarAsignacion] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    //#endregion

    //#region columnas
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'convenioUsuarioId', label: 'ID ', minWidth: 0, hide: true ,aliasColor:"estatusConveniosUsuarios"},
            { id: 'convenioId', label: 'Convenio Id', minWidth: 0, hide: true },
            { id: 'usuarioId', label: 'Usuario Id', minWidth: 0, hide: true },
            { id: 'qrConveniosUsuarios', label: 'Qr Id', minWidth: 0, hide: true },
            { id: 'nombreCompleto', label: 'Nombre ', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
            { id: 'email', label: 'Email ', minWidth: 170, align: 'left', textAlign: 'left', filtro: false, filtro: true },
            { id: 'empresaConvenio', label: 'Empresa del convenio', align: 'left', textAlign: 'left', minWidth: 0, hide: false,filtro: true },
            { id: 'RFCConvenio', label: 'RFC del convenio', align: 'left', textAlign: 'left',minWidth: 0, hide: false, filtro: true },
            {
                id: 'fechaBajaConveniosUsuarios', label: 'Fecha de baja convenios usuarios', minWidth: 170, align: 'center', hide: true,
                format: (valor) => {
                    return valor !== null ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
                }
            },
            {
                id: 'estatus', label: 'Estatus de usuario', minWidth: 170, align: 'center', component: "Checkbox"

            },
            // {
            //     id: 'estatusConvenio', label: 'Estatus del convenio', minWidth: 170, align: 'center', component: "Checkbox"
            // },
            {
                id: 'estatusConveniosUsuarios', label: 'Asignar convenio cliente', minWidth: 170, align: 'center', component: "Checkbox"
            },
            {
                id: 'tipo', hide: true, label: 'Tipo', minWidth: 100, align: 'center', textAlign: 'center', format: (valor) => {
                    return valor.toString().replace("1", "Doctor").replace("2", "Paciente").replace("3", "Admin").replace("4", "Usuario");
                }
            },
            { id: 'nombres', label: 'Nombre ', minWidth: 0, hide: true },
            { id: 'apellidos', label: 'apellidos ', minWidth: 0, hide: true },
            {
                id: 'fechaEgreso', label: 'Fecha de egreso', minWidth: 170, align: 'center',
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: true
            },
            {
                id: 'fechaAlta', label: 'Fecha de alta de usuarios', minWidth: 170, align: 'center',
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: true
            },
            {
                id: 'fechaAltaConvenio', label: 'Fecha de alta del convenio', minWidth: 170, align: 'center',hide:true,filtro: false,
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }
            },
            {
                id: 'fechaAltaConveniosUsuarios', label: 'Fecha de alta del convenio con el cliente', minWidth: 170, align: 'center',
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
                }, hide: false,filtro: true
            },
            {
                id: 'fechaInicialConvenio', label: 'Fecha de inicial del convenio', minWidth: 170, align: 'center',
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: true
            },
            {

                id: 'fechaBajaConveniosUsuarios', label: 'Fecha de baja del convenio con el cliente', minWidth: 170, align: 'center',filtro:true,
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: false
            },
            {

                id: 'fechaFinalConvenio', label: 'Fecha final del convenio', minWidth: 170, align: 'center',filtro:true,
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: false
            },
            { id: 'sexo', label: 'Sexo', minWidth: 0, hide: true },
            { id: 'correo', label: 'Correo estatus', minWidth: 0, hide: true },
            { id: 'telefono', label: 'Telefono', minWidth: 0, hide: true },
            { id: 'tipoPagoConvenio', label: 'tipoPagoConvenio', minWidth: 0, hide: true },
            { id: 'opcionesPagoConvenio', label: 'opcionesPagoConvenio', minWidth: 0, hide: true },
            { id: 'emailConvenio', label: 'Email del convenio', minWidth: 0, hide: true },
        ];
    //#endregion columnas

    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }


    function ftMensajeCancel() {
        setChecked(seleccion.estatusConveniosUsuarios !== 0);
        ftMensajeClose();
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
    }

    function handleAgregarClick() {
        ftCloseRespuestaAbrir();
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function handleModificarClick() {
        if (seleccion.convenioUsuarioId !== null && seleccion.convenioUsuarioId !== undefined && fila !== -1) {
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está  seguro de eliminar el convenio del cliente "${seleccion.nombreCompleto}"?`, onAccept: ftEliminar });
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
        }
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
     await   statusUsuario({
            variables: {
                usuarioId: Number(seleccion.usuarioId),
                convenioId: seleccion.convenioId,
                estatusConveniosUsuarios: seleccion.estatusConveniosUsuarios === 0 ? 1 : 0,
            }
        })
        setDisabledDelete(false);
    }  ftCambiarEstatus();
    }

    function handleCambiarEstatusClick() {
        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            setChecked(seleccion.estatusConveniosUsuarios === 0);
            let opcion = seleccion.estatusConveniosUsuarios === 1 ? "desactivar" : "activar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} el convenio del cliente "${seleccion.nombreCompleto}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel});
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
        }
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }



    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatusConveniosUsuarios !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }
    const actualizarTabla = () => {
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }


    //#endregion

    //#region funciones

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteConvenioUsuario({
                variables: {
                    convenioId: Number(seleccion.convenioId),
                    usuarioId: Number(seleccion.usuarioId)
                }
            });
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseRespuestaAbrir() {
        setOpen(true);
    }

    const handleClose = newValue => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const redirige=(texto)=>{
        history.push(texto);
    }

    //#endregion

    //#region actualiza

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listarConvenioUsuario?.convenioUsuarios;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }



    //#endregion

    return (<>
        <CRMProvider value={[asignacion, guardarAsignacion]}>
            <MenuLateral titulo="Asignacion" menu={menu(handleAgregarClick, handleModificarClick, handleEliminarClick, handleCambiarEstatusClick, checked,redirige)}>

                <Agregar
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    botonder={"Agregar"}
                    botonizq={"Cancelar"}
                    actualiza={() => actualizarTabla()}
                    ftClose={handleClose}
                    index={setFila}
                />
                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} botonder={"Modificar"}
                    botonizq={"Cancelar"} value={value} />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />

                <Tabla titulo="Asignar convenios a clientes" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["qrConveniosUsuarios","nombreCompleto","empresaConvenio","RFCConvenio",]} />


            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default ConveniosPacientes;
