import {combineReducers} from 'redux';
import sesionReducer from './SesionReducer';
import usuarioReducer from './UsuariosReducer';
import especialidadReducer from './EspecialidadesReducer';
import clinicaDoctorCrud from './ClinicaDoctoresReducer';
import convenioReducer from  './ConvenioReducer';
import seleccionClinica from  './SeleccionarClinicaReducer';
import SeleccionTurno from './SeleccionarTurnoReducer';
import clinicaUsuarioCrud from './ClinicaUsuarioReducer';
import FotoPefilCrud from './FotoPerfilReducer';
import ConvenioUsuario from './ConvenioReducer';
import ConfiguracionReducer from './ConfiguracionReducer';
import MenuReducer from './MenuReducer';
import NotificacionSonido from './SonidoNotificacionReducer';
import AutorizarDatos from './AutorizarDatosReducer';
import NotificacionCredito from './RecordatorioCreditoReducer';
import Anamnesis from './AnamnesisReducer';
import kalendaySesiones from './KalendaySesionesReducer';
export default combineReducers({
    anamnesisCrud:Anamnesis,
    notificacionCrud:NotificacionCredito,
    actuarizarDatos:AutorizarDatos,
    user:sesionReducer,
    crudUser:usuarioReducer,
    crudEsp:especialidadReducer,
    crudClinicaDoctor:clinicaDoctorCrud,
    crudConvenio: convenioReducer,
    selecciondeClinica: seleccionClinica,
    crudClinicaUsuario:clinicaUsuarioCrud,
    seleccionTurnos: SeleccionTurno,
    fotoPerfil:FotoPefilCrud,
    convenioUsuarios:ConvenioUsuario,
    configuracion:ConfiguracionReducer,
    menu:MenuReducer,
    notificacion:NotificacionSonido,
    kalendaySesiones
});
