import {ANAMNESIS}from '../Types';

const initialState={
    anamnensis: [{ activo: false }]
}

export default function(state=initialState,action){
    switch(action.type){
        case ANAMNESIS:
            return {
                ...state,
                anamnensis:[action.payload]
            }
        default:
            return state;
    }
}
