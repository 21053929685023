import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//REDUX
import { useDispatch } from "react-redux";
import { SonidoActivacion } from "../../../../Redux/Actions/SonidoNotificacionesAction";
//CORE
import { Button, CardContent, CardActions, Card, Grid, FormControlLabel, Switch, } from '../../../../componentesGenericos/Core';
//CONTEXT
import { CRMContext } from '../../../../context/Perfil';
import { OBTENER_SONIDO_NOTIFICACION } from '../../../../query/componentes/Usuario';
import { ACTUALIZAR_SONIDO_NOTIFICACION } from '../../../../mutation';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//HELPERS
import {
    verificaValores
} from "../../../../Helpers/Validacion/ValidacionCadenas";

//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONSTANTES
import { mensajeError, mensajeInicial,mensajeExito } from '../../../../Helpers/constantes';
//ESTILOS
const useStyles = makeStyles({
    root: {
        maxWidth: 345,

    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1: {
        marginBottom: "10px"
    }

});

function Cedula(props) {

    //DECLARACIONES
    const classes = useStyles();
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [boton, setBoton] = useState(false);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const agregarSonido = (datos) => dispatch(SonidoActivacion(datos));

    //EVENTOS
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        guardarPerfiles({
            ...perfil,
            General: {
                ...perfil.General,
                [name]: {
                    ...perfil.General[name],
                    valor: event.target.checked === true ? true : false
                }
            }
        });
    }

    const EventClickModificar = async () => {
        setBoton(true);
        let campos = [
            "notificacion",
        ];
        let datos = verificaValores(campos, perfil.General);
        if (datos.estado === false) {
            setBoton(false);
        } else {
            await FetchModificar();
            setBoton(false);
        }
    };

    //API
    const FetchObtener = async () => {
        await FetchGrahpQL({
            query: OBTENER_SONIDO_NOTIFICACION,
            variables: {
                id: parseInt(props.usuarioId)
            }
        })
            .then((res) => {
                guardarPerfiles(perfiles => ({
                    ...perfiles,
                    General: {
                        ...perfiles.General,
                        notificaciones: {
                            ...perfiles.General.notificaciones,
                            error: false, valor: res.ObtenerUsuarioGeneral.sonidoNotificacion===1?true:false
                        },
                    }
                }));
            })
            .catch((err) => {
            });
    };
    const FetchModificar = async () => {

        await FetchGrahpQL({
            query: ACTUALIZAR_SONIDO_NOTIFICACION,
            variables: {
                usuarioId: parseInt(props.usuarioId),
                sonidoNotificacion: perfil.General.notificaciones.valor === true ? 1 : 0
            }
        })
            .then(() => {
                agregarSonido({ activo:perfil.General.notificaciones.valor});
                setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente  la información` });

            })
            .catch((err) => {
            });
    };

    //FUNCIONES
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    //CICLO DE VIDA
    useEffect(() => {
        FetchObtener();
    }, []);


    return (
        <Card className={classes.mb1}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Configuración</p>
            </div>
            <CardContent>
                <Grid spacing={3} container direction="row">
                    <Grid item sm={12} md={12}>
                        <FormControlLabel
                            style={{ width: "100%" }}
                            name="notificaciones"
                            control={<Switch checked={perfil.General.notificaciones.valor} onChange={handleChangeGuardarState} color="primary" name="notificaciones" />
                            }
                            label="Activar sonido en  notificaciones"
                        />
                    </Grid>
                </Grid>
            </CardContent>


            <CardActions>

                <Button size="small" color="primary" onClick={EventClickModificar} disabled={boton}>
                    GUARDAR
             </Button>
            </CardActions>
        </Card>


    );
}

export default Cedula;
