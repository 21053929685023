import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [actualizar, setActualizar] = useState(false);
    return (
        <CRMContext.Provider value={[actualizar, setActualizar]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
