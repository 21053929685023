import gql from 'graphql-tag';

import {
    NUEVO_ABONO,
    ABONOS_PARTIDAS,
    ABONO_PARCIAL,
    ABONO_CAPITAL,
    ABONO_TOTAL
} from './componentes/Creditos'

import {
    NUEVA_GARANTIA,
    MODIFICAR_GARANTIA,
    ELIMINAR_GARANTIA
} from './componentes/Garantias';

import { NUEVA_RECETA } from './componentes/Receta';

import {
    NUEVO_PRODUCTO_SERVICIO,
    MODIFICAR_PRODUCTO_SERVICIO,
    ELIMINAR_PRODUCTO_SERVICIO
} from './componentes/ProductosServicios';

import {
    NUEVO_TIPO_PADECIMIENTO,
    MODIFICAR_TIPO_PADECIMIENTO,
    ELIMINAR_TIPO_PADECIMIENTO
} from './componentes/TipoPadecimiento';

import { NUEVA_ENCABEZADO, MODIFICAR_ENCABEZADO, ELIMINAR_ENCABEZADO } from './componentes/Encabezado'

import { NUEVA_COMISION, ESTATUS_COMISION, MODIFICAR_COMISION } from './componentes/Comision';

export {
    NUEVA_GARANTIA,
    MODIFICAR_GARANTIA,
    ELIMINAR_GARANTIA,
    NUEVO_PRODUCTO_SERVICIO,
    MODIFICAR_PRODUCTO_SERVICIO,
    ELIMINAR_PRODUCTO_SERVICIO,
    NUEVO_TIPO_PADECIMIENTO,
    MODIFICAR_TIPO_PADECIMIENTO,
    ELIMINAR_TIPO_PADECIMIENTO,
    NUEVA_ENCABEZADO,
    MODIFICAR_ENCABEZADO,
    ELIMINAR_ENCABEZADO,
    NUEVA_RECETA,
    NUEVA_COMISION,
    ESTATUS_COMISION,
    MODIFICAR_COMISION,
    NUEVO_ABONO,
    ABONOS_PARTIDAS,
    ABONO_PARCIAL,
    ABONO_CAPITAL,
    ABONO_TOTAL

}


//========================================
//#region ImagenesPromociones
export const NUEVA_IMAGENPROMOCION = gql`
	mutation nuevo($imagenpromo: ImagenPromocionInput!) {
		nuevaImagenPromocion(input: $imagenpromo) {
			imgPromoId
			descripcion
			urlImagen
			estatus
			fechaAlta
			vigenciaIni
			vigenciaFin
		}
	}
`;

export const CAMBIAR_ESTATUSIMAGENPROMOCION = gql`
	mutation cambiarEstatus($id: Int!, $estatus: Int!) {
		cambiarEstatusImagenPromocion(id: $id, estatus: $estatus) {
			imgPromoId
			descripcion
			estatus
			fechaAlta
			vigenciaIni
			vigenciaFin
		}
	}
`;

export const MODIFICAR_IMAGENPROMOCION = gql`
	mutation modificar($imagenpromo: ImagenPromocionInput!) {
		modificarImagenPromocion(input: $imagenpromo) {
			imgPromoId
			descripcion
			urlImagen
			estatus
			fechaAlta
			vigenciaIni
			vigenciaFin
		}
	}
`;

export const ELIMINAR_IMAGENPROMOCION = gql`
	mutation eliminar($id: Int!) {
		eliminarImagenPromocion(id: $id)
	}
`;
//#endregion

//#region ServiciosDoctores

//#region ServiciosDoctores
//=========ServiciosDoctores=============
export const NUEVO_SERVICIO_DOCTOR = gql`
mutation nuevoServicioDoctor($nuevoServicioDoctor:ServicioDoctorInput!){
    nuevoServicioDoctor(input:$nuevoServicioDoctor)
    {
        relacionIdGeneral
        serviciosId
        usuarioId
        clinicaId
        clinicaDoctorId
    }
}
`;

export const MODIFICAR_SERVICIO_DOCTOR = gql`
mutation modificarServicioDoctor($modificarServicioDoctor:ServicioDoctorActualizarInput!){
    modificarServicioDoctor(input:$modificarServicioDoctor)
}
`;

export const ELIMINAR_SERVICIO_DOCTOR = gql`
mutation eliminar($id:Int!, $servicios: String!){
    eliminarServicioDoctor(id:$id, servicios: $servicios)
  }
`;

//========================================
//#endregion

//#region Clinicas
// =================Clinicas=======================
export const ELIMINAR_CLINICA = gql`
	mutation EliminarClinica($id: Int!) {
		eliminarClinica(id: $id)
	}
`;

export const NUEVA_CLINICA = gql`
	mutation NuevaClinica($clinica: ClinicaInput!) {
		nuevaClinica(input: $clinica) {
			clinicaId
			nombre
			alias
			clave
			estatus
			fechaAlta
			padreId
			matriz
		}
	}
`;

export const MODIFICAR_CLINICA = gql`
	mutation ModificarClinica($ClinicaActualizar: ClinicaActualizarInput!) {
		modificarClinica(input: $ClinicaActualizar) {
			clinicaId
			nombre
			alias
			clave
			estatus
			fechaAlta
			padreId
			matriz
		}
	}
`;

export const CAMBIAR_ESTATUS_CLINICA = gql`
	mutation CambiarEstatusClinica($id: Int!, $estatus: Int!) {
		cambiarEstatusClinica(clinicaId: $id, estatus: $estatus) {
			clinicaId
			nombre
			alias
			clave
			estatus
			fechaAlta
			padreId
			matriz
		}
	}
`;
// =================end Clinicas=======================

// =================Direcciónes=======================

export const NUEVA_DIRECCION = gql`
	mutation nuevaDireccion($direccion: DireccionInput!) {
		nuevaDireccion(input: $direccion) {
			direccionId
            calle
			codigoPostal
			claveColonia
			colonia
			claveLocalidad
			localidad
			claveMunicipio
			municipio
			claveEstado
			estado
			clavePais
			pais
		}
	}
`;

export const ELIMINAR_DIRECCION = gql`
	mutation EliminarDireccion($id: Int!) {
		eliminarDireccion(id: $id)
	}
`;

export const MODIFICAR_DIRECCION = gql`
	mutation ModificarDireccion($direccion: DireccionActualizarInput!) {
		modificarDireccion(input: $direccion) {
			direccionId
            calle
            clinicaId
			codigoPostal
			claveColonia
			colonia
			claveLocalidad
			localidad
			claveMunicipio
			municipio
			claveEstado
			estado
			clavePais
			pais

		}
	}
`;

export const CAMBIAR_ESTATUS_DIRECCION = gql`
    mutation CambiarStatusDireccion($direccionId: Int!, $estatus: Int!, $clinicaId: Int!){
        cambiarStatusDireccion(direccionId: $direccionId, estatus: $estatus, clinicaId:$clinicaId){
            direccionId,
            codigoPostal,
            calle,
            claveColonia,
            colonia,
            claveLocalidad,
            localidad,
            claveMunicipio,
            municipio,
            claveEstado,
            estado,
            clavePais,
            pais,
            clinicaId,
            nombreClinica,
            estatus
        }
    }
`;
// ================= End Direcciónes=======================
// ================= TELEFONOS =========================

export const NUEVO_TELEFONO = gql`
	mutation nuevoTelefono($telefono: TelefonoInput!) {
		nuevoTelefono(input: $telefono) {
			telefonoId
			telefono
			direccionId
		}
	}
`;

export const MODIFICAR_TELEFONO = gql`
	mutation ModificarTelefono($telefono: TelefonoActualizarInput!) {
		modificarTelefono(input: $telefono) {
			telefonoId
			telefono
			direccionId
		}
	}
`;

export const ELIMINAR_TELEFONO = gql`
	mutation EliminarTelefono($id: Int!) {
		eliminarTelefono(id: $id)
	}
`;
// ================ END TELEFONOS ===========================

//#region Especialidades Doctores
//=========Especialidades==Doctores=============

export const NUEVA_ESPECIALIDAD_DOCTOR = `
mutation nuevaEspecialidadDoctor($descripcion:String!){
    nuevaEspecialidadDoctor(input:{
      descripcion:$descripcion
    }){
      especialidadId
      descripcion
    }
  }
`;

export const MODIFICAR_ESPECIALIDAD_DOCTOR = `
mutation modificarEspecialidadesDoctor($especialidadId:ID!,$descripcion:String!){
    modificarEspecialidadesDoctor(input:{
      especialidadId:$especialidadId
      descripcion:$descripcion
    }){
      especialidadId
      descripcion
    }
  }
`;

export const ELIMINAR_ESPECIALIDAD_DOCTOR = gql`
mutation eliminarEspecialidadesDoctor($id:Int!){
  eliminarEspecialidadesDoctor(id:$id)
}
`;

//#endregion
//===============================================

//#region Especialidades Doctores
//=========Clinica=Doctor========================


export const ELIMINAR_CLINICA_USUARIO = `
mutation eliminarClinicaUsuario($usuarioId: String!, $clinicaId: ID!) {
    eliminarClinicaUsuario(
      input: { usuarioId: $usuarioId, clinicaId: $clinicaId }
    )
  }
  `;

export const ELIMINAR_CLINICA_DOCTOR = `
mutation obtenerDoctoresAsignadosClinicas($doctorId: String!, $clinicaId: ID!) {
    eliminarClinicaDoctor(input: { doctorId: $doctorId, clinicaId: $clinicaId })
  }`;


export const NUEVO_MODIFICAR_CLINICA_DOCTOR = `
mutation agregarActualizarClinicaDoctor($doctorId: String!, $clinicaId: ID!) {
    agregarActualizarClinicaDoctor(
      input: { doctorId: $doctorId, clinicaId: $clinicaId }
    ) {
      mensaje
    }
  }
`;

export const NUEVO_MODIFICAR_CLINICA_USUARIO = `
mutation agregarActualizarClinicaUsuario($clinicaId: ID!, $usuarioId: String!) {
    agregarActualizarClinicaUsuario(
      input: { clinicaId: $clinicaId, usuarioId: $usuarioId }
    ) {
      success
      mensaje
      respuesta
      token
      datos
    }
  }
`;



//#endregion
//===============================================

//#region  ANAMNESI
//==========ANAMNESI========================

export const ELIMINAR_ANAMNESI = gql`mutation eliminarAnamnesis ($anamnesisId: Int!, $padecimientoId: Int!){
  eliminarAnamnesi(anamnesisId: $anamnesisId, padecimientoId: $padecimientoId)
}`;

export const ELIMINAR_ANAMNESI_FETCH = `mutation eliminarAnamnesis ($anamnesisId: Int!, $padecimientoId: Int!){
  eliminarAnamnesi(anamnesisId: $anamnesisId, padecimientoId: $padecimientoId)
}`;

export const NUEVO_ANAMNESIS_FETCH = `
mutation nuevoAnamnesisPadecimiento($nuevoAnamnesis: AnamnesiAgregar!)
{
  nuevoAnamnesis(input: $nuevoAnamnesis)
}
`;

export const MODIFICAR_ANAMNESIS_FETCH = `
mutation modificarAnamnesis($modAnamnesis: AnamnesiAgregar!)
{
  modificarAnamnesis(input:$modAnamnesis )
}`;

//#endregion
//===============================================

export const NUEVO_ADMINISTRADOR = `
mutation nuevoAdministrador($nombres:String!,$apellidos:String!,
    $email:String!,$password:String!,$sexo:Int!,$telefono:String!,$fechaNacimiento:Date!){
      nuevoAdministrador(input:{
        nombres:$nombres
          apellidos:$apellidos
        email:$email
        password:$password
        sexo:$sexo
        telefono:$telefono
        fechaNacimiento:$fechaNacimiento
      }){
        usuarioId
        tipo
        nombres
        email
        password
        fechaAlta
        fechaEgreso
        estatus
        sexo
        tarjetaQr
        correo
        telefono
      }
    }`;


export const AUTORIZACION_MODIFICACION = `
mutation autorizarModificacion(
    $nombreCompletoSolicitante:String!
    $datosUsuariosAutorizacionId:Int!
    $correoSolicitante:String!
    $nombres: String
    $apellidos: String
    $email: String
    $password: String
    $sexo: Int
    $especialidadId: Int
    $cedula: String
    $telefono: String
    $fechaNacimiento: Date
    $fechaEgreso: Date
    $usuarioId: ID!
    $rechazo: Int
    $tipo:Int!
  ) {
    autorizarModificacion(
      input: {
        nombreCompletoSolicitante:$nombreCompletoSolicitante
        correoSolicitante:$correoSolicitante
        tipo:$tipo
        datosUsuariosAutorizacionId:$datosUsuariosAutorizacionId
        nombres: $nombres
        apellidos: $apellidos
        email: $email
        password: $password
        sexo: $sexo
        cedula: $cedula
        especialidadId: $especialidadId
        telefono: $telefono
        fechaNacimiento: $fechaNacimiento
        fechaEgreso: $fechaEgreso
        usuarioId: $usuarioId
        rechazo: $rechazo
      }
    ) {
        success
        mensaje
        error
        respuesta
        token
        datos
    }
  }
  `;

export const NUEVO_USUARIO = `
mutation nuevoUsuario($nombres:String!,$apellidos:String!,
    $email:String!,$password:String!,$sexo:Int!,$telefono:String!,$fechaNacimiento:Date!){
      nuevoUsuario(input:{
        nombres:$nombres
          apellidos:$apellidos
        email:$email
        password:$password
        sexo:$sexo
        telefono:$telefono
        fechaNacimiento:$fechaNacimiento
      }){
        usuarioId
        tipo
        nombres
        email
        password
        fechaAlta
        fechaEgreso
        estatus
        sexo
        tarjetaQr
        correo
        telefono
      }
    }`;


export const MODIFICAR_DATOS_GENERALES_USUARIOS = `mutation cambiarDatosGenerales(
    $usuarioId: Int!
    $nombres: String!
    $apellidos: String!
    $sexo: Int!
    $telefono: String!
    $fechaNacimiento: Date!
    $email: String!
  ) {
    cambiarDatosGenerales(
      input: {
        email:$email
        usuarioId: $usuarioId
        nombres: $nombres
        apellidos: $apellidos
        sexo: $sexo
        telefono: $telefono
        fechaNacimiento: $fechaNacimiento
      }
    ) {
      success
      mensaje
      error
      respuesta
      token
      datos
    }
  }

  `;

export const NUEVO_DOCTOR = `
mutation nuevoDoctor($nombres:String!,$apellidos:String!,
    $email:String!,$password:String!,$sexo:Int!,$especialidad:Int!,$cedula:String!,$telefono:String!,$fechaNacimiento:Date!,$fechaEgreso:Date){
      nuevoDoctor(input:{
        nombres:$nombres
        apellidos:$apellidos
        email:$email
        password:$password
        sexo:$sexo
        cedula:$cedula
        especialidadId:$especialidad
        telefono:$telefono
        fechaNacimiento:$fechaNacimiento
        fechaEgreso:$fechaEgreso
      }){
        usuarioId
        tipo
        nombres
        email
        password
        cedula
        fechaAlta
        fechaEgreso
        estatus
        sexo
        tarjetaQr
        correo
        especialidadId
        descripcion
        telefono
      }
    }`;


export const NUEVO_PACIENTE = `
    mutation nuevoPaciente(
        $nombres: String!
        $apellidos: String!
        $email: String!
        $password: String!
        $sexo: Int
        $telefono:String
        $fechaNacimiento: Date
        $nombreDB: String!
      ) {
        nuevoPaciente(
          input: {
            nombres: $nombres
            apellidos: $apellidos
            email: $email
            password: $password
            sexo: $sexo
            telefono:$telefono
            fechaNacimiento:$fechaNacimiento
            nombreDB: $nombreDB
          }
        ) {
          usuarioId
          tipo
          nombres
          apellidos
          email
          password
          fechaEgreso
          fechaAlta
          estatus
          sexo
          tarjetaQr
          correo
          telefono
        }
      }
      `;

export const MODIFICAR_USUARIO = `
      mutation modificarUsuario(
          $usuarioId: ID!
          $nombres: String
          $apellidos: String
          $email: String
          $fechaEgreso: Date
          $sexo: Int
          $telefono:String
          $fechaNacimiento:Date
          $password:String
        ) {
          modificarUsuario(
            input: {
              password:$password
              usuarioId: $usuarioId
              nombres: $nombres
              apellidos: $apellidos
              email: $email
              fechaEgreso: $fechaEgreso
              sexo: $sexo
              telefono:$telefono
              fechaNacimiento:$fechaNacimiento
            }
          ) {
            usuarioId
            tipo
            nombres
            apellidos
            email
            password
            fechaEgreso
            fechaAlta
            estatus
            sexo
            tarjetaQr
            correo
            telefono
          }
        }`;




export const RECUPERAR_CONTRASEÑA = `
mutation cambiar($email: String!,$password: String!, $nombreDB: String!){
    cambiarPassword(input:{
      email:$email,
      password:$password,
      nombreDB:$nombreDB
    }){
      success
      mensaje
      error
      respuesta
      token
      datos
    }
  }
  `;

export const MODIFICAR_ADMINISTRADOR = `
mutation modificarAdministrador(
  $usuarioId: ID!
  $nombres: String!
  $apellidos: String!
  $email: String!
  $fechaEgreso: Date
  $sexo: Int!
  $telefono:String!
  $fechaNacimiento:Date!
) {
  modificarAdministrador(
    input: {
      usuarioId: $usuarioId
      nombres: $nombres
      apellidos: $apellidos
      email: $email
      fechaEgreso: $fechaEgreso
      sexo: $sexo
      telefono:$telefono
      fechaNacimiento:$fechaNacimiento
    }
  ) {
    usuarioId
    tipo
    nombres
    apellidos
    email
    password
    fechaEgreso
    fechaAlta
    estatus
    sexo
    tarjetaQr
    correo
    telefono
  }
}`;


export const MODFICAR_DOCTOR = `
mutation modificarDoctor(
    $usuarioId: ID!
    $nombres: String!
    $apellidos: String!
    $email: String!
    $fechaEgreso: Date
    $sexo: Int!
    $especialidadId:Int!
    $telefono:String!
    $fechaNacimiento:Date!
    $cedula:String
    $password:String
  ) {
    modificarDoctor(
      input: {
        password:$password
        cedula:$cedula
        usuarioId: $usuarioId
        nombres: $nombres
        apellidos: $apellidos
        email: $email
        fechaEgreso: $fechaEgreso
        sexo: $sexo
        especialidadId:$especialidadId
        telefono:$telefono
        fechaNacimiento:$fechaNacimiento
      }
    ) {
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      especialidadId
      descripcion
      correo
      cedula
      telefono
    }
  }`;


export const ACTUALIZAR_CORREO = `
mutation modificarPaciente(
    $usuarioId: Int!
    $email: String!
    $nombres: String!
  ) {
    actualizarCorreo(
      input: { usuarioId: $usuarioId, nombres: $nombres, email: $email }
    ) {
      success
      mensaje
      error
      respuesta
      token
      datos
    }
  }
  `;

export const ACTUALIZAR_ESTATUS_CORREO = gql`
  mutation actualizarCorreoEstatus(
    $usuarioId: Int!
    $tipo: Int!
    $estatus: Int!
  ) {
    actualizarCorreoEstatus(
      input: { usuarioId: $usuarioId, estatus: $estatus, tipo: $tipo }
    ) {
      usuarioId
      tipo
      nombres
      apellidos
      email
      fechaAlta
      nombreCompleto
      fechaNacimiento
    }
  }
  `;

export const MODIFICAR_PACIENTE = `
mutation modificarPaciente(
  $usuarioId: ID!
  $nombres: String!
  $apellidos: String!
  $email: String!
  $fechaEgreso: Date
  $sexo: Int!
  $telefono:String!
  $fechaNacimiento:Date!
  $password:String!
) {
  modificarPaciente(
    input: {
      password: $password
      usuarioId: $usuarioId
      nombres: $nombres
      apellidos: $apellidos
      email: $email
      fechaEgreso: $fechaEgreso
      sexo: $sexo
      telefono: $telefono
      fechaNacimiento: $fechaNacimiento
    }
  ) {
    usuarioId
    tipo
    nombres
    apellidos
    email
    password
    fechaEgreso
    fechaAlta
    estatus
    sexo
    tarjetaQr
    correo
    telefono
  }
} `;


export const ACTUALIZAR_ESTATUS_USUARIOS = gql`
  mutation ActualizarEstatusUsuarios(
    $usuarioId: ID!
    $tipo: Int!
    $estatus:Int!
  ) {
    actualizarEstatusUsuarios(
      input: {
       usuarioId:$usuarioId
        tipo:$tipo
        estatus:$estatus
      }
    ) {
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      correo
    }
  }`;

export const ELIMINAR_USUARIO = gql`
	mutation eliminarUsuarios($usuarioId: Int!) {
		eliminarUsuarios(id: $usuarioId)
	}
`;

export const ACTUALIZAR_CEDULA = `
  mutation actualizarCedula($usuarioId: ID!,$cedula:String!) {
    actualizarCedula(input:{
      usuarioId:$usuarioId,
      cedula:$cedula
    }){
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      especialidadId
      descripcion
      correo
    }
  }`;


export const ACTUALIZAR_SONIDO_NOTIFICACION = `
mutation actualizarSonidoNotificacion(
    $usuarioId: ID!
    $sonidoNotificacion: Int!
  ) {
    actualizarSonidoNotificacion(
      input: { usuarioId: $usuarioId, sonidoNotificacion: $sonidoNotificacion }
    ) {
      sonidoNotificacion
      email
      nombres
      apellidos
    }
  }
  `;

export const ACTUALIZAR_DATOS_PROFESIONALES = `
mutation modificarDatosProfesionales(
    $usuarioId: Int!
    $especialidadId: Int!
    $fechaEgreso: Date!
  ) {
    modificarDatosProfesionales(
      input: {
        usuarioId: $usuarioId
        especialidadId: $especialidadId
        fechaEgreso: $fechaEgreso
      }
    ) {
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      especialidadId
      descripcion
      correo
      telefono
      nombreCompleto
      clinicaId
      fechaNacimiento
    }
  }
`;
export const INICIAR_SESION = `
mutation iniciarSesion($email:String!,$password:String!, $nombreDB: String!){
    iniciarSesion(input:{
      email:$email
      password:$password
      nombreDB: $nombreDB
    }){
      success
      mensaje
      error
      respuesta
      token
      datos
    }
  }
`;

export const VERIFICA_TOKEN = `
mutation verificaToken($token:String!){
    verificaToken(token:$token){
      success
      mensaje
      error
      respuesta
      token
      datos
    }
  }`;
//#endregion
//===============================================

//#region Horarios clinica
//=========== Horarios clinica ==========================
export const NUEVO_HORARIO_CLINICA = gql`
mutation NuevoHorarioClinica($horario: HorariosClinicaInput!){
  nuevoHorarioClinica(input: $horario){
    horarioId
    dias
    desde
    hasta
    clinicaId
    nombre
    clave
    estatusClinica
    matriz
    estatus
    ausencia

  }
}`;

export const NUEVO_HORARIO_DOCTOR = gql`
mutation nuevoHorarioDoctor($horario: HorariosDoctorInput!){
  nuevoHorarioDoctor(input: $horario)
}`;


export const MODIFICAR_HORARIO = gql`
mutation ModificarHorario($horario: HorariosDoctorActualizar!){
  modificarHorarioDoctor(input:$horario)
}`;

export const MODIFICAR_HORARIO_CLINICA = gql`
mutation ModificarHorario($horario: HorariosClinicaActualizar!){
    modificarHorarioClinica(input:$horario)
}`;


export const ELIMINAR_HORARIO = gql`
 mutation eliminarHorario($id: Int!) {
    eliminarHorarioDoctor(id: $id)
  }`;

export const ESTATUS_HORARIO_CLINICA = gql`
mutation ESTATUS_HORARIO_CLINICA($id: Int!, $estatus: Int!) {
  cambiarEstatusHorarioClinica(id:$id estatus:$estatus)
}`;

export const ESTATUS_HORARIO_DOCTOR = gql`
mutation ESTATUS_HORARIO_DOCTOR($id: Int!, $estatus: Int!){
  cambiarEstatusHorarioDoctor(id:$id estatus:$estatus)
}`;
//#endregion

//#region Turnos
//============Turnos==================
export const NUEVO_TURNO = gql`
	mutation nuevoTurno($turno: TurnoInput!) {
		nuevoTurno(input: $turno) {
			turnoId
			nombreCompleto
      nombreClinica
			descripcion
			horaInicio
			horaFin
      clinicaUsuarioId
		}
	}
`;

export const MODIFICAR_TURNO = gql`
	mutation modificarTurno($turno: TurnoActualizarInput!) {
		modificarTurno(input: $turno) {
			turnoId
			nombreCompleto
      nombreClinica
			descripcion
			horaInicio
			horaFin
      ini
      fin
      clinicaUsuarioId
		}
	}
`;

export const ELIMINAR_TURNO = gql`
	mutation eliminarTurno($id: Int!) {
		eliminarTurno(id: $id)
	}
`;

//#endregion
//==========================================

//==========================================
//#region AGENDA
//==========================================
export const NUEVO_EVENTO = gql`
	mutation agregarEvento($evento: AddEvento!) {
		agregarEvento(input: $evento) {
            agendaID
            title
            descripcion
            start
            end
            redirigirPuntoVenta
            clinicaDoctoID
            clinicaID
            doctorID
            pacienteID
            estatusID
            servicioDoctorID
            servicioID
            descripcionEstatus
		}
	}
`;

export const MODIFICAR_EVENTO = gql`
	mutation modificarEvento($evento: UpdateEvento!) {
		actualizarEvento(input: $evento) {
            agendaID
            title
            descripcion
            start
            end
            redirigirPuntoVenta
            clinicaDoctoID
            clinicaID
            doctorID
            pacienteID
            estatusID
            servicioDoctorID
            servicioID
            descripcionEstatus
		}
	}
`;

export const ELIMINAR_EVENTO = gql`
	mutation deleteEvento($agendaID: Int!) {
        eliminarEvento(id: $agendaID)
    }
`;

export const MODIFICAR_TIME_EVENTO = gql`
	mutation UpdateTimeEvento($agendaID: ID!, $fechaIni: Date!, $fechaFin: Date!) {
        actualizarTimeEvento(agendaID: $agendaID, fechaIni: $fechaIni, fechaFin: $fechaFin) {
            agendaID
            title
            start
            end
            redirigirPuntoVenta
            servicioDoctorID
            servicioID
        }
    }
`;
//#endregion
//==========================================

//==========================================
//#region AGENDA
//==========================================
export const NUEVO_ESTATUS_AGENDA = gql`
	mutation agregarEstatus($estatusPredeterminado: Boolean!, $citaNoModificable: Boolean!, $omitirValidacion: Boolean!, $colorEstatus: String, $descripcionEstatus: String!, $redirigirPuntoVenta: Boolean!, $horaNoModificable: Boolean!){
        nuevoEstatusCita(input: {
        estatusPredeterminado: $estatusPredeterminado
        colorEstatus: $colorEstatus
        descripcionEstatus: $descripcionEstatus
        citaNoModificable: $citaNoModificable
        omitirValidacion: $omitirValidacion
        redirigirPuntoVenta: $redirigirPuntoVenta
        horaNoModificable: $horaNoModificable
        }) {
            estatusID
            estatusPredeterminado
            descripcionEstatus
            colorEstatus
            citaNoModificable
            omitirValidacion
            redirigirPuntoVenta
            horaNoModificable
        }
    }
`;
export const MODIFICA_ESTATUS_AGENDA = gql`
	mutation modificarEstatus($estatusID: ID!, $estatusPredeterminado: Boolean!, $citaNoModificable: Boolean!, $omitirValidacion: Boolean!, $colorEstatus: String, $descripcionEstatus: String!, $redirigirPuntoVenta: Boolean!, $horaNoModificable: Boolean!){
        modificarEstatusCita(input: {
        estatusID: $estatusID
        estatusPredeterminado: $estatusPredeterminado
        colorEstatus: $colorEstatus
        descripcionEstatus: $descripcionEstatus
        citaNoModificable: $citaNoModificable
        omitirValidacion: $omitirValidacion
        redirigirPuntoVenta: $redirigirPuntoVenta
        horaNoModificable: $horaNoModificable
        }) {
            estatusID
            estatusPredeterminado
            descripcionEstatus
            colorEstatus
            citaNoModificable
            omitirValidacion
            redirigirPuntoVenta
            horaNoModificable
        }
    }
`;
export const ELIMINAR_ESTATUS_AGENDA = gql`
	mutation eliminarEstatus($estatusID: Int!) {
        eliminarEstatusCita(id: $estatusID)
    }
`;
//#endregion
//==========================================

//#region Cajas
//============Cajas==================
export const NUEVA_CAJA = gql`
  mutation agregarCaja($caja: CajaInput!)
  {
    nuevaCaja(input: $caja) {
      cajaId
      sucursalId
      numeroCaja
      responsable
      folioId
    }
}`;
export const MODIFICAR_CAJA = gql`
mutation modificarCaja($caja: CajaActualizarInput!){
  modificarCaja(input:$caja) {
        cajaId
    sucursalId
    numeroCaja
    responsable
    cobrarEn
    folioTicket
    turno
    numeroTurnos
    folioId
  }
}`;
export const ELIMINAR_CAJA = gql`
  mutation eliminarCaja($id: Int!) {
    eliminarCaja(id: $id)
  }
`;
//#endregion
//==========================================


//#region Convenios
//============Convenios==================
export const NUEVO_CONVENIO = gql`
mutation addConvenio ( $convenio: ConvenioInput!)
{
  nuevoConvenio(input: $convenio)
  {
    convenioId
    empresa
    tipoPago
    opcionesPago
    email
    fechaInicial
    fechaFinal
    RFC
    urlLogo
    tipoConvenio
    promotorID
    promotor
    comision
  }
}
`;

export const MODIFICAR_CONVENIO = gql`
mutation modConvenio ($convenio: ConvenioActualizarInput!)
{
  modificarConvenio(input:$convenio )
  {
    convenioId
    empresa
    tipoPago
    opcionesPago
    email
    fechaInicial
    fechaFinal
    RFC
    urlLogo
    tipoConvenio
    promotorID
    promotor
    comision
  }
}
`;

export const ELIMINAR_CONVENIO = gql`
mutation deleteConvenio ($id: Int!)
{
  eliminarConvenio(id:$id)
}
`;

export const NUEVO_REGISTRO_USUARIO_CONVENIO = `
mutation enviarRegistroUsuariosConvenio($input : correosRegistroConveniosInput!)
{
  enviarCorreoUsuarioConvenio(input : $input)
}
`;

export const NUEVO_PROMOTOR = gql`
mutation nuevoPromotor($input : PromotorInput!)
{
  nuevoPromotor(input : $input)
  {
      promotorID
      promotor
      comision
      clave
  }
}
`;

export const MODIFICAR_PROMOTOR = gql`
mutation modificarPromotor($input : PromotorActualizarInput!)
{
  modificarPromotor(input : $input)
  {
      promotorID
      promotor
      comision
      clave
  }
}
`;

export const ELIMINAR_PROMOTOR = gql`
	mutation eliminarPromotor($id: Int!) {
		eliminarPromotor(id: $id)
	}
`;


//#endregion
//==========================================

//#region RECORDATORIOS
//==========================================
export const NUEVO_RECORDATORIO = gql`
	mutation agregarRecordatorio($estatusID: ID!, $medio: [eMedios]!, $dia: Int!, $hora: Int!, $minuto: Int!, $tituloRecordatorio: String!, $descripcionRecordatorio: String, $notificarAntesCita: Boolean, $confirmacionEmailID: Int, $descripcionConfirmacion: String) {
        agregarRecordatorio(input: {
            estatusID: $estatusID
            medio: $medio
            dia: $dia
            hora: $hora
            minuto: $minuto
            tituloRecordatorio: $tituloRecordatorio
            descripcionRecordatorio: $descripcionRecordatorio
            notificarAntesCita: $notificarAntesCita
            confirmacionEmailID: $confirmacionEmailID
            descripcionConfirmacion: $descripcionConfirmacion
        }) {
            estatusID
            descripcionEstatus
            colorEstatus
            estatusPredeterminado
            recordatorioID
            medio
            dia
            hora
            minuto
            tituloRecordatorio
            descripcionRecordatorio
            notificarAntesCita
            confirmacionEmailID
            descripcionEstatusConfirmacionEmail
            colorEstatusConfirmacionEmail
            descripcionConfirmacion
        }
    }
`;
export const MODIFICA_RECORDATORIO = gql`
	mutation modificarRecordatorio($recordatorioID: ID!, $estatusID: ID!, $medio: [eMedios]!, $dia: Int!, $hora: Int!, $minuto: Int!, $tituloRecordatorio: String!, $descripcionRecordatorio: String, $notificarAntesCita: Boolean, $confirmacionEmailID: Int, $descripcionConfirmacion: String) {
        actualizarRecordatorio(input: {
            recordatorioID: $recordatorioID
            estatusID: $estatusID
            medio: $medio
            dia: $dia
            hora: $hora
            minuto: $minuto
            tituloRecordatorio: $tituloRecordatorio
            descripcionRecordatorio: $descripcionRecordatorio
            notificarAntesCita: $notificarAntesCita
            confirmacionEmailID: $confirmacionEmailID
            descripcionConfirmacion: $descripcionConfirmacion
        }) {
            estatusID
            descripcionEstatus
            colorEstatus
            estatusPredeterminado
            recordatorioID
            medio
            dia
            hora
            minuto
            tituloRecordatorio
            descripcionRecordatorio
            notificarAntesCita
            confirmacionEmailID
            descripcionEstatusConfirmacionEmail
            colorEstatusConfirmacionEmail
            descripcionConfirmacion
        }
    }
`;
export const ELIMINAR_RECORDATORIO = gql`
	mutation eliminarRecordatorio($id: Int!) {
        eliminarRecordatorio(id: $id)
    }
`;
//#endregion
//==========================================


//#region Centro de Costos
export const NUEVO_CENTRO_COSTO = gql`
mutation NuevoCentroCostos($CentroCosto: CentroCostoInput!){
  nuevoCentrocostos(input:$CentroCosto){
    ccostoId
    clinicaId
    nombre
    numCcosto
    nombreCcosto
    fechaAlta
    estatusCcostos
  }
}`;

export const MODIFICAR_CENTRO_COSTO = gql`
mutation ModificarCentroCostos($CentroCosto: CentroCostoInput!){
  modificarCentrocostos(input: $CentroCosto){
    ccostoId
    clinicaId
    nombre
    numCcosto
    nombreCcosto
    fechaAlta
    estatusCcostos
  }
}`;

export const ESTATUS_CENTRO_COSTO = gql`
mutation EstatusCentroCosto($id: Int!, $estatusCcostos: Int!){
  cambiarEstatusCcostos(id: $id, estatusCcostos: $estatusCcostos){
    ccostoId
    clinicaId
    nombre
    numCcosto
    nombreCcosto
    fechaAlta
    estatusCcostos
  }
}`;

export const ELIMINAR_CENTRO_COSTO = gql`
mutation EliminarCentroCosto($id: Int!){
  eliminarCentroCostos(id:$id)
}`;
//#endregion


//#region Convenios
//==========================================
export const AGREGAR_CONVENIO_SERVICIO = gql`
 mutation addConvenioServicio ($convenioServicio: ConvenioServicioInput!)
{
 nuevoConvenioServicio(input: $convenioServicio)
 {
  	 convenioServicioIdGeneral
      convenioId
      servicios
      porcentajeDescuento
      fechaAltaConvenio
}
}
`;

export const MODIFICAR_CONVENIO_SERVIIO = gql`
 mutation updateConvenioServicio ($convenioServicio: ConvenioServicioActualizarInput!)
{
 modificarConvenioServicio(input: $convenioServicio){
      convenioServicioIdGeneral
      convenioId
      servicios
      porcentajeDescuento
}
}
`;

export const ELIMINAR_CONVENIO_SERVICIO = gql`
    mutation deleteConvenioServicio ($id: Int!, $servicios: String!){
  eliminarConvenioServicio(id:$id,servicios: $servicios )
}
`;
//#endregion

//==========================================
//#region Seguridad
export const ELIMINAR_SEGURIDAD = gql`
    mutation borrarSeguridad($id:ID!){
        eliminarSeguridad(id:$id)
    }
`;

export const NUEVA_SEGURIDAD = gql`
    mutation agregarSeguridad($seguridad: SeguridadInput!)
    {
        nuevaSeguridad(input: $seguridad){
            seguridadId
            perfilId
            descripcion
            tipoUsuarioId
            permisos
        }
    }
`;
export const MODIFICAR_SEGURIDAD = gql`
    mutation modificarSeguridad($seguridad: SeguridadInput!){
        modificarSeguridad(input: $seguridad){
            seguridadId
            perfilId
            descripcion
            tipoUsuarioId
            permisos
        }
    }
`;
//#endregion


//#region  Inventarios
export const NUEVO_INVENTARIO = gql`
mutation NuevaExistencia($existencia: ExistenciaInput!){
  nuevaExistencia(input: $existencia){
    existenciaId
        encabezadoId
        empresaId
        nombreEmpresa
        clinicaId
        nombreClinica
        almacenId
        nombreAlmacen
        centroCostosId
        centroCostos
        conceptoId
        nombreConcepto
      	servicioId
      	descripcion
      	codigoProducto
      	codigoBarras
      	precioUnitario
      	iva
      	precioTotal
      	porcentajeDescuento
      	descuentoTotal
      	precioDescuento
      	ivaDescuento
      	PrecioTotalDescuento
      	comision
      	tipo
      	especialidadId
      	agendable
      	inventariable
      	disponible
      	tipoIva
      	tasaIva
      	noAplicaIva
      	fechaInicialDescuento
      	fechaFinalDescuento
      	venderSinExistencia
        existenciaInicial
        entradas
        salidas
        existenciaActual
        referencia
  }
}`;

export const MODIFICAR_ENTRADAS_INVENTARIO = gql`
mutation ModificarExistencia($existencia: ExistenciaInput!){
  modificarExistencia(input: $existencia){
    existenciaId
        encabezadoId
        empresaId
        nombreEmpresa
        clinicaId
        nombreClinica
        almacenId
        nombreAlmacen
        centroCostosId
        centroCostos
        conceptoId
        nombreConcepto
      	servicioId
      	descripcion
      	codigoProducto
      	codigoBarras
      	precioUnitario
      	iva
      	precioTotal
      	porcentajeDescuento
      	descuentoTotal
      	precioDescuento
      	ivaDescuento
      	PrecioTotalDescuento
      	comision
      	tipo
      	especialidadId
      	agendable
      	inventariable
      	disponible
      	tipoIva
      	tasaIva
      	noAplicaIva
      	fechaInicialDescuento
      	fechaFinalDescuento
      	venderSinExistencia
        existenciaInicial
        entradas
        salidas
        existenciaActual
        referencia
  }
}`;

export const ELIMINAR_INVENTARIO = gql`
mutation EliminarExistencia($id:ID!){
    eliminarExistencia(id:$id)
}`;
//#endregion

//#region Almacen
export const AGREGAR_ALMACEN = gql`
mutation AgregarAlmacen($Almacen:AlmacenInput!){
    nuevoAlmacen(input:$Almacen){
      almacenId
      clinicaId
      clinica
      descripcion
      estatus
    }
  }
`;

export const MODIFICAR_ALMACEN = gql`
mutation modificarAlmacen($Almacen:AlmacenInput!){
    modificarAlmacen(input:$Almacen){
      almacenId
      clinicaId
      clinica
      descripcion
      estatus
    }
}
`;

export const CAMBIAR_ESTATUS_ALMACEN = gql`
mutation cambiarEstatusAlmacen($id:Int!,$estatus:Int!){
    cambiarEstatusAlmacen(id:$id,estatus:$estatus){
      almacenId
      clinicaId
      clinica
      descripcion
      estatus
    }
  }
`;

export const ELIMINAR_ALMACEN = gql`
mutation eliminarAlmacen($id:Int!){
  eliminarAlmacen(id:$id)
}
`;
//#endregion

//#region Conceptos de inventario
//==========================================
export const ELIMINAR_CONCEPTO_INVENTARIO = gql`
mutation eliminarConcepto($id: Int!) {
    eliminarConcepto(id: $id)
  }
`;



export const NUEVO_CONCEPTO = gql`
mutation nuevoConceptos($descripcion: String!, $tipo: Int!) {
  nuevoConceptos(input: { descripcion: $descripcion, tipo: $tipo }) {
    conceptoId
    descripcion
    tipo
  }
}`;

export const MODIFICAR_CONCEPTO = gql`
mutation modificarConceptos(
    $descripcion: String!
    $tipo: Int!
    $conceptoId: ID!
  ) {
    modificarConceptos(
      input: { descripcion: $descripcion, tipo: $tipo, conceptoId: $conceptoId }
    ) {
      conceptoId
      descripcion
      tipo
    }
  }`;

//#endregion


//#region notificaciones
export const AGREGAR_NOTIFICACION = gql`
    mutation AgregarNotificacion($notificacion:NotificacionInput!){
        nuevaNotificacion(input:$notificacion){
            notificacionId
            usuarioId
            mensaje
            fecha
            diasRepeticion
            estatus
            fechaCreacion
        }
    }
`;

export const MODIFICAR_NOTIFICACION = gql`
    mutation modificarNotificacion($notificacion:NotificacionInput!){
        modificarNotificacion(input:$notificacion){
            notificacionId
            usuarioId
            mensaje
            fecha
            diasRepeticion
            estatus
            fechaCreacion
        }
    }
`;

export const CAMBIAR_ESTATUS_NOTIFICACION = gql`
    mutation cambiarEstatusNotificacion($id:Int,$estatus:Int!,$usuarioId:Int){
        cambiarEstatusNotificacion(id:$id,estatus:$estatus,usuarioId:$usuarioId){
            notificacionId
            usuarioId
            mensaje
            fecha
            diasRepeticion
            estatus
            fechaCreacion
        }
    }
`;

export const ELIMINAR_NOTIFICACION = gql`
    mutation eliminarNotificacion($id:Int!){
        eliminarNotificacion(id:$id)
    }
`;
//#endregion

//#region Serie
export const ACTUALIZAR_ESTATUS_PORDEFECTO_SERIE = gql`
mutation actualizarEstatusPorDefecto(
  $serieId: Int!
  $estatus: Int!
  $clinicaId: Int!
  $tipoDocumentoId: Int!
) {
  actualizarEstatusPorDefecto(
    input: { serieId: $serieId, estatus: $estatus, clinicaId: $clinicaId, tipoDocumentoId: $tipoDocumentoId }
  ) {
    serieId
    serieNombre
    tipoDocumentoId
    nombreDocumento
    estatusSerie
    porDefecto
    clinicaId
    nombreClinica
    aliasClinica
    estatusClinica
  }
}

  `;

export const ACTUALIZAR_ESTATUS_SERIE = gql`
  mutation actualizarEstatusSerie($serieId:Int!,$estatus:Int!){
    actualizarEstatusSerie(input:{
      serieId:$serieId,
      estatus:$estatus
    }) {
        serieId
      serieNombre
      tipoDocumentoId
      nombreDocumento
      estatusSerie
      porDefecto
      clinicaId
      nombreClinica
      aliasClinica
      estatusClinica
    }
  }
  `;

export const NUEVA_SERIE = `
  mutation nuevaSerie($serieNombre: String!, $tipoDocumentoId: Int!, $clinicaId: Int!) {
    nuevaSerie(input: { serieNombre: $serieNombre, tipoDocumentoId: $tipoDocumentoId, clinicaId: $clinicaId }) {
      serieId
      serieNombre
      tipoDocumentoId
      nombreDocumento
      estatusSerie
      porDefecto
      clinicaId
      nombreClinica
      aliasClinica
      estatusClinica
    }
  }
  `;

export const MODIFICAR_SERIE = `
  mutation modificarSerie($serieNombre: String!, $tipoDocumentoId: Int!, $serieId: ID!) {
    modificarSerie(input: { serieNombre: $serieNombre, tipoDocumentoId: $tipoDocumentoId, serieId: $serieId }) {
      serieId
      serieNombre
      tipoDocumentoId
      nombreDocumento
      estatusSerie
      porDefecto
      clinicaId
      nombreClinica
      aliasClinica
      estatusClinica
    }
  }`;

export const NUEVO_TICKET_TEMPORAL_AGENDA = `
mutation TicketTemporalAgenda($input: TicketTemporalAgendaInput!){
  ticketTemporalAgenda(input: $input){
    ticketEncabezadoId
    servicioId
    descripcion
    cantidad
    porcentajeDescuento
    porcentajeDescuentoConvenio
    porcentajeIva
    precioUnitario
    precioUnitarioDescuento
    precioUnitarioDescuentoConvenio
    descuentoUnitario
    descuentoConvenioUnitario
    descuentoUnitarioTotal
    ivaUnitario
    ivaUnitarioDescuento
    ivaUnitarioTotal
    precioUnitarioTotal
    descuentoCantidad
    descuentoConvenioCantidad
    precioDescuentoCantidad
    precioDescuentoConvenioCantidad
    descuentoTotal
    ivaTotal
    precioTotal
    clinicaId
    nombreClinica
    direccionId
    cliente
    doctorId
    metodoPago
    clavePago
    conceptoId
    tipoDocumentoId
    calle
    codigoPostal
    colonia
    estado
    localidad
    municipio
    fechaCreacion
  }
}`;


//#endregion

//#region Reporte
export const ELIMINAR_REPORTE = gql`
    mutation deleteReporte($reporteID: Int!) {
        eliminarReporte(id: $reporteID)
    }
 `;
//#endregion

//#region tickets
export const AGREGAR_TICKET = gql`
mutation nuevoTicket($ticket:TicketInput!){
  nuevoTicket(input:$ticket){
        ticketId,
        # doctorId,
        # doctor,
        # empresaId,
        # empresa,
        # clinicaId,
        # clinica,
        # tipoDocumentoId,
        # tipoDocumento,
        # vendedorId,
        # vendedor,
        # servicioMaterialId,
        # servicioMaterial,
        # almacenId,
        # almacen,
        # conceptoId,
        # concepto,
        # divisaId,
        # pacienteId,
        # paciente,
        # cCostoId,
        # centroCostos,
        # paisId,
        # serie,
        # folio,
        # fechaEmision,
        # turnoId,
        # turno,
        # cantidad,
        # importe,
        # descuento,
        # descuentoTotalPartida,
        # subtotal,
        # porcDescuento,
        # porcIva,
        # total,
        # tipoCambio,
        # importePago,
        # importeComision,
        # cambio,
        # metodoPago,
        # clavePago
  }
}
`;

export const TICKET_TEMPORAL_AGENDA = gql`
mutation TicketTemporalAgenda($input: TicketTemporalAgendaInput!){
  ticketTemporalAgenda(input: $input){
    ticketEncabezadoId
    servicioId
    descripcion
    cantidad
    porcentajeDescuento
    porcentajeDescuentoConvenio
    porcentajeIva
    precioUnitario
    precioUnitarioDescuento
    precioUnitarioDescuentoConvenio
    descuentoUnitario
    descuentoConvenioUnitario
    descuentoUnitarioTotal
    ivaUnitario
    ivaUnitarioDescuento
    ivaUnitarioTotal
    precioUnitarioTotal
    descuentoCantidad
    descuentoConvenioCantidad
    precioDescuentoCantidad
    precioDescuentoConvenioCantidad
    descuentoTotal
    ivaTotal
    precioTotal
    clinicaId
    nombreClinica
    direccionId
    cliente
    doctorId
    metodoPago
    clavePago
    conceptoId
    tipoDocumentoId
    calle
    codigoPostal
    colonia
    estado
    localidad
    municipio
    fechaCreacion
  }
}`;

export const GUARDAR_TICKET_TEMPORAL = gql`
mutation GuardarTicketTemporal($ticket:TicketInput!){
    GuardarTicketTemporal(input:$ticket){
        # ticketId,
        # doctorId,
        # doctor,
        # empresaId,
        # empresa,
        # clinicaId,
        # clinica,
        # tipoDocumentoId,
        # tipoDocumento,
        vendedorId,
        # vendedor,
        # servicioMaterialId,
        # servicioMaterial,
        # almacenId,
        # almacen,
        # conceptoId,
        # concepto,
        # divisaId,
        # pacienteId,
        # paciente,
        # cCostoId,
        # centroCostos,
        # paisId,
        # serie,
        # folio,
        # fechaEmision,
        # turnoId,
        # turno,
        # cantidad,
        # importe,
        # descuento,
        # descuentoTotalPartida,
        # subtotal,
        # porcDescuento,
        # porcIva,
        # total,
        # tipoCambio,
        # importePago,
        # importeComision,
        # cambio,
        # metodoPago,
        # clavePago
  }
}
`;

export const CANCELAR_TICKET = gql`
    mutation cancelarTicket($serieId:Int!,$folio:Int!){
    cancelarTicket(serieId:$serieId,folio:$folio)
    }
`;
//#endregion
