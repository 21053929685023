import { ASIGNAR_CLINICA_USUARIOS } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_MODIFICAR_CLINICA_USUARIO, ELIMINAR_CLINICA_USUARIO } from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos,ActivarDesactivarBoton,ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVO_MODIFICAR_CLINICA_USUARIO,
                variables: {
                    clinicaId: datos.Clinicas.clinicaId.valor,
                    usuarioId: datos.Clinicas.usuarioId.valor
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `${resp.agregarActualizarClinicaUsuario.mensaje}`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        } else {
            FetchGrahpQL({
                query: ELIMINAR_CLINICA_USUARIO,
                variables: {
                    clinicaId: datos.Clinicas.clinicaId.valor,
                    usuarioId: datos.Clinicas.usuarioId.valor
                }
            })
                .then(resp => {
                    if(resp.eliminarClinicaUsuario[0].toLowerCase() == "alertmessage")
                        Swal.fire("¡Aviso!", `${resp.eliminarClinicaUsuario[1]}`, "warning");//warning, question,info
                    else
                        Swal.fire("¡Éxito!", `${resp.eliminarClinicaUsuario}`, "success");
                     ActivarDesactivarBoton(false);
                     actualiza();
                     ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                     Swal.fire("¡Aviso!", err.message, "warning");
                    }
                     ActivarDesactivarBoton(false);
                });
        }
        dispatch(AsingarClinicaDoctor({ activo: true }))
    }
}


export const AsingarClinicaDoctor = clinicaUsuarioCrud => {
    return {
        type: ASIGNAR_CLINICA_USUARIOS,
        payload: clinicaUsuarioCrud
    }
}




