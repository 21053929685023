
/**obtiene cuando cambio el input producto y guarda en seleccion y partida**/
export function handleProductoChange(val, seleccionInicial, setSeleccion, descConven) {
    val = val || seleccionInicial;

    ftRecalculo(1, 1, val, setSeleccion, descConven);
}


export const handleClienteChange = (val, setTicket, setSeleccion) => {
    val = val || { nombres: 'Publico', apellidos: "general", usuarioId: -1 };

    setTicket((ticket) => ({ ...ticket, clienteId: Number(val.usuarioId) }));
    setSeleccion((seleccion) => ({ ...seleccion, clienteId: val }));
}


export const handleCantidadChange = (val, seleccion, setSeleccion, descConven, almacenes) => {
    if (val) {
        val = Number(val);
        if (seleccion.inventariable === 1 || seleccion.tipoProducto === 2) {
            let almElegido = null;

            if (almacenes) {
                almElegido = almacenes.obtenerExistencia?.Existencias.find(al => al.almacenId === seleccion.almacenId && al.centroCostosId === (seleccion.centroCostosId === -1 ? null : seleccion.centroCostosId));
            }

            if (almElegido) {
                //si el almacen no tiene existencias del producto
                if (val > almElegido.existenciaActual) {
                    //Si esta configurado en vender sin existencia
                    if (seleccion.venderSinExistenciaGeneral) {
                        ftRecalculo(2, val, seleccion, setSeleccion, descConven);
                    } else {
                        //si el producto esta marcado para vender sin existencia
                        if (seleccion.venderSinExistencia) {
                            ftRecalculo(2, val, seleccion, setSeleccion, descConven);
                        } else {
                            //si no tiene existencia ni se puede, vender manda mensaje
                            console.log("El producto no tiene existencias en el almacen elegido");
                        }
                    }
                } else {
                    ftRecalculo(2, val, seleccion, setSeleccion, descConven);
                }
            } else {
                console.log("El producto no tiene existencias en el almacen elegido");
            }
        } else {
            ftRecalculo(2, val, seleccion, setSeleccion, descConven);
        }
    }
}

export const handleAlmacenChange = (val, seleccionInicial, setSeleccion, setTicket) => {
    if (val) {
        setSeleccion((seleccion) => ({ ...seleccion, almacenId: val }));
    }
}

export const ftRecalculo = async (modo, cantidad, val, setSeleccion, descConven, regresar = false) => {
    let precioUnitario = val.precioUnitario || 0;
    let descuentoUnitario = val.descuentoUnitario || 0;
    let precioUnitarioDescuento = val.precioUnitarioDescuento || 0;
    let descuentoConvenioUnitario = val.descuentoConvenioUnitario || 0;
    let descuentoUnitarioTotal = val.descuentoUnitarioTotal || 0;
    let precioUnitarioDescuentoConvenio = val.precioUnitarioDescuentoConvenio || 0;
    let ivaUnitario = val.ivaUnitario || 0;
    let ivaUnitarioDescuento = val.ivaUnitarioDescuento || 0;
    let ivaUnitarioTotal = val.ivaUnitarioTotal || 0;
    let precioUnitarioTotal = val.precioUnitarioTotal || 0;
    if (modo === 1) {
        //unitarios
        precioUnitario = val.precioUnitario || 0;
        descuentoUnitario = val.descuentoTotal || 0;
        precioUnitarioDescuento = val.precioUnitarioDescuento || 0;

        descuentoConvenioUnitario = (precioUnitarioDescuento || 0) * (val.servicioId !== -1 ? (descConven[val.servicioId] || 0) : 0);
        descuentoUnitarioTotal = descuentoUnitario + descuentoConvenioUnitario;

        precioUnitarioDescuentoConvenio = precioUnitario - descuentoUnitarioTotal;

        ivaUnitario = val.iva || 0;
        ivaUnitarioDescuento = val.ivaDescuento || 0;
        ivaUnitarioTotal = precioUnitarioDescuentoConvenio * (val.porcentajeIva || 0);

        precioUnitarioTotal = precioUnitarioDescuentoConvenio + ivaUnitarioTotal;
    }

    //---------------Visibles---------------------
    // por cantidad
    let descuentoCantidad = descuentoUnitario * cantidad;
    let descuentoConvenioCantidad = descuentoConvenioUnitario * cantidad;
    let precioDescuentoCantidad = precioUnitarioDescuento * cantidad;
    let precioDescuentoConvenioCantidad = precioUnitarioDescuentoConvenio * cantidad;

    //Totales
    let descuentoTotal = descuentoUnitarioTotal * cantidad;
    let ivaTotal = ivaUnitarioTotal * cantidad;
    let precioTotal = precioUnitarioTotal * cantidad;
    //----------------------------------------
    if (regresar) {
        return {
            ...val,
            cantidad,
            porcentajeDescuentoConvenio: descConven[val.servicioId] || 0,
            precioUnitario,
            descuentoUnitario,
            precioUnitarioDescuento,
            descuentoConvenioUnitario,
            descuentoUnitarioTotal,
            precioUnitarioDescuentoConvenio,
            ivaUnitario,
            ivaUnitarioDescuento,
            ivaUnitarioTotal,
            precioUnitarioTotal,
            descuentoCantidad,
            descuentoConvenioCantidad,
            precioDescuentoCantidad,
            precioDescuentoConvenioCantidad,
            descuentoTotal,
            ivaTotal,
            precioTotal,
        };
    } else {
        setSeleccion((seleccion) => {

            return ({
                ...seleccion,
                ...val,
                cantidad,
                porcentajeDescuentoConvenio: descConven[val.servicioId] || 0,
                precioUnitario,
                descuentoUnitario,
                precioUnitarioDescuento,
                descuentoConvenioUnitario,
                descuentoUnitarioTotal,
                precioUnitarioDescuentoConvenio,
                ivaUnitario,
                ivaUnitarioDescuento,
                ivaUnitarioTotal,
                precioUnitarioTotal,
                descuentoCantidad,
                descuentoConvenioCantidad,
                precioDescuentoCantidad,
                precioDescuentoConvenioCantidad,
                descuentoTotal,
                ivaTotal,
                precioTotal,
            })
        }
        );
    }
};
