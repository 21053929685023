import gql from 'graphql-tag';

//#region Medios
//==========================================================
const LISTADO_MEDIOS = gql`
    query listadoMedios($limite: Int) {
        listadoMedios(limite: $limite) {
            medios {
                medioID
                descripcion
                clave
            }
            registros
            paginas
        }
    }
`;
const LISTADO_MEDIOS_FETCH = `
    query listadoMedios($limite: Int) {
        listadoMedios(limite: $limite) {
            medios {
                medioID
                descripcion
                clave
            }
            registros
            paginas
        }
    }
`;
//#endregion
//=====================================================

export {
    LISTADO_MEDIOS,
    LISTADO_MEDIOS_FETCH
}
