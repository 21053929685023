import React from 'react';

import InputGrid from '../../../componentesGenericos/Formulario/componentes/InputGrid';

function Input({ campos, handleChange = () => { }, state }) {

    function validarRegexp({ id, value, setError }) {
        try {
            campos.forEach(campo => {
                if (campo.id === id && campo.regexp) {
                    setError((error) => ({
                        ...error,
                        [id]: !campo.regexp.test(value)
                    }));
                }
            })
        } catch (e) {
            console.log("Error: ",e.message)
        }
    }

    return (<>
        {
            campos.map((campo, index) => (
                <InputGrid
                    key={index}
                    xs={campo.xs}
                    styleSelect={campo.styleSelect}
                    styleInput={campo.styleInput}
                    styleMenuItem={campo.styleMenuItem}
                    propiedades={campo.valor ? campo.propiedades : {}}
                    tipo={campo.tipo}
                    id={campo.id}
                    texto={campo.texto}
                    margen={campo.margen}
                    valor={state[campo.id] ? state[campo.id] : campo.valor}
                    handleChange={(e, val) => handleChange(e, campo.id, campo.multiselect, campo.onChange, campo.cleanDatoState, val, validarRegexp)}
                    multiselect={campo.multiselect}
                    opciones={campo.opciones}
                    {...campo.otros}
                    disabled={campo.disabled}
                    color={campo.color}
                    regexp={campo.regexp}
                    helperText={campo.error ? campo.ayuda : ""}
                    error={typeof campo.error === "boolean" ? campo.error : false}
                    {...campo.otros}
                />
            ))
        }
    </>);
}

export default Input;
