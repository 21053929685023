import React from 'react';
//Componentes
import Mensaje from '../Mensaje';
import { CircularProgress } from '@material-ui/core';
import Tabla from '../Tabla';

//constantes
import { mensajeInicial, mensajeError } from '../../Helpers/constantes';

function ContenedorTabla({ titulo = "Listado",tmWidth='100%', titleColumna,columnas, loading, datos, indice, filtro,filtrosSeleccionables=[], error = null, onClick, onSearch, msjError = "No se encontraron registros", claseFila, claseCelda, claseEncabezado, claseTabla, hover, seleccionable,onPaginaChange, components, noResponsive = false, titleResponsive = [] }) {

    msjError = msjError.replace(/^.+:(.+)$/, "$1");

    let colError = [{ id: 'msj', label: '', format: (valor) => <p style={{ fontSize: 18, margin: 0 }}>{valor}</p> }];
    error = datos ? datos[0]?.msj ? true : false : true;


    const [message, setMessage] = React.useState(msjError);
    const [modalMensaje, setModalMensaje] = React.useState({ ...mensajeInicial, ...mensajeError });
    const [abrirModal, setAbrirModal] = React.useState(!msjError.includes("No se encontraron registros"));

    function ftMensajeClose() {
        setMessage("No se encontraron registros");
        setAbrirModal(false);
    }

    React.useEffect(() => {
        let val = !msjError.includes("No se encontraron registros");

        if (abrirModal !== val) {
            setAbrirModal(val)
        }
    }, [msjError]);

    return (
        <div style={{ width: tmWidth, textAlign: 'center' }}>

            <Mensaje titulo={modalMensaje.titulo} abrir={abrirModal} texto={msjError} onClose={ftMensajeClose} icono={modalMensaje.icono} tipo={modalMensaje.tipo} color={modalMensaje.color} onAccept={ftMensajeClose} onCancel={ftMensajeClose} />

            {loading ? <CircularProgress /> :
                !error ? <Tabla titulo={titulo} titleColumna={titleColumna}columnas={columnas} datos={datos} onClick={onClick} onSearch={onSearch} indice={indice} filtro={filtro} claseCelda={claseCelda} claseFila={claseFila} claseEncabezado={claseEncabezado} claseTabla={claseTabla} hover={hover} seleccionable={seleccionable} filtrosSeleccionables={filtrosSeleccionables[0]} onPaginaChange={onPaginaChange} components={components} noResponsive={noResponsive} titleResponsive={titleResponsive} />
                    : <Tabla titulo={titulo} columnas={colError} datos={[{ msj: message }]} filtro={filtro} claseCelda={claseCelda} claseFila={claseFila} claseEncabezado={claseEncabezado} hover={false} seleccionable={false} noResponsive={noResponsive} titleResponsive={titleResponsive} />
            }
        </div>
    )
}

export default ContenedorTabla
