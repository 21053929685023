import React from 'react'
import ProductosServicios from './Listado';

function Servicios() {
    return (
        <ProductosServicios titulo="productos" titulo2="producto" tipo="2" />
    )
}

export default Servicios
