import { makeStyles } from '@material-ui/core/styles';

export const estilos = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        color: '#0d67e1'
    },
    item: {
        '&:hover': {
            backgroundColor: '#f0f0f0'
        }
    },
    avatar: {
        minWidth: '30px'
    },
    verTodo:{
        backgroundColor: '#eaeaea',
        padding:'2px 16px',
        cursor: 'pointer'
    }
}));

