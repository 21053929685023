import React from 'react';

//Componentes
import { CircularProgress } from '@material-ui/core';
import MenuLateral from '../../componentesGenericos/SideBar';

//Helpers
// import { getUsuario } from './Helpers/Usuario';

function Loader() {

    // let infoUser = null;
    // if (user.token) infoUser = getUsuario(user.token);

    // function validarRuta() {
    //     if (infoUser) {
    //         if (rutas.some(ruta => ruta.path === window.location.pathname)) { return Loader; }

    //         return Menu;
    //     }

    //     return Login;
    // }

    return (
        <MenuLateral titulo="" menu={[]}>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <CircularProgress />
            </div>
        </MenuLateral>
    )
}

export default Loader
