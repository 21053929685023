import gql from 'graphql-tag';

const LISTAR_DATOS_USUARIOS_AUTORIZACIONES=gql`
query {
  ListadoDatosUsuariosAutorizaciones {
    datosUsuariosAutorizacion {
      datosUsuariosAutorizacionId
      usuarioId_nuevo
      nombres_nuevo
      apellidos_nuevo
      email_nuevo
      cedula_nuevo
      fechaEgreso_nuevo
      estatus_nuevo
      especialidadId_nuevo
      sexo_nuevo
      telefono_nuevo
      fechaNacimiento_nuevo
      autorizacion
      solicitantesId
      nombre_actual
      apellidos_actual
      email_actual
      cedula_actual
      fechaEgreso_actual
      estatus_actual
      especialidadId_actual
      sexo_actual
      telefono_actual
      fechaNacimiento_actual
      tipo
      nombreCompletoSolicitante
      correoSolicitante
      tipoUsuarioSolicitante
      nombreCompletoAfectado
      password_nuevo
      password_nuevo_estatus
    }
  }
}
`;
export {
    LISTAR_DATOS_USUARIOS_AUTORIZACIONES
}
