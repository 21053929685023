import React, { useState, useContext } from 'react';
import { CRMContext, CRMProvider } from './../../context/AsignacionUsuariosClinicas';
//Apollo*****
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries****
import { LISTADO_CLINICAS } from '../../query';
import {
    ELIMINAR_ESPECIALIDAD_DOCTOR
} from '../../mutation';
//Componentes***
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Agregar from './Agregar';
import Modificar from './Modificar';


//constantes***
import { seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState, Style } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

function AsignarClinicasUsuarios() {

    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 10000, pagina: 1 },
        fetchPolicy: 'no-cache',
        onError:(e)=>{}
    });

    const [deleteEspecialidad] = useMutation(ELIMINAR_ESPECIALIDAD_DOCTOR, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la especialidad "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });


    //#endregion

    //#region states
    const [asignacion, guardarAsignacion] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    //#endregion

    //#region columnas
    const columnas =errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'clinicaId', label: 'ID ', minWidth: 0, hide: true ,aliasColor:"estatus"},
        { id: 'nombre', label: 'Nombre', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'alias', label: 'Alias', minWidth: 170,align: 'left', textAlign: 'left', filtro: true },
        {
            id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center',component: "Checkbox"
        }
    ];
    //#endregion columnas

    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
    }

    function handleAgregarClick() {
        if (seleccion.clinicaId !== null && fila!==-1) {
            ftCloseRespuestaAbrir();
            // setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una sucursal" });
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function handleModificarClick() {
        if (seleccion.clinicaId !== null && seleccion.clinicaId!==undefined  && fila!==-1){
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una sucursal" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.especialidadId !== null && seleccion.especialidadId!==undefined  && fila!==-1) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `Esta seguro de eliminar la especialidad "${seleccion.descripcion}"?`, onAccept: ftEliminar });
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una especialidad" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }
    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }
    const actualizarTabla=()=>{
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
           });
           setActualizar(false);
    }


    //#endregion

    //#region funciones

    function ftEliminar() {
        deleteEspecialidad({ variables: { id: Number(seleccion.especialidadId) } });
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseRespuestaAbrir() {
        setOpen(true);
    }

    const handleClose = newValue => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    //#endregion

     //#region actualiza

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoClinicas?.clinicas;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
           });
           setActualizar(false);
       }



//#endregion

    return (<>
        <CRMProvider value={[asignacion, guardarAsignacion]}>
            <MenuLateral titulo="Asignacion" menu={menu(handleAgregarClick, handleModificarClick, handleEliminarClick, checked)}>
                <Agregar
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    botonder={"Agregar"}
                    botonizq={"Cancelar"}
                    actualiza={() => actualizarTabla()}
                    ftClose={handleClose}
                    seleccion={seleccion}
                    index={setFila}
                />
                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica}   actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} botonder={"Modificar"}
                botonizq={"Cancelar"} value={value} />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />


                                <Tabla titulo="Asignar usuarios a sucursales" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombre", "alias"]}/>


            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default AsignarClinicasUsuarios;
