import React, { useState, useEffect } from 'react';
import { urlServer } from '../../Helpers/index';
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
import { rutaPublica } from '../../Helpers/constantes/Config/urlRutas';
//Componentes
import { useParams } from "react-router-dom";
import { DecodificarToken } from "../../componentesGenericos/Estructura/Autenticar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import { CircularProgress } from '@material-ui/core';

import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
//Query
import { queryConfiguracionInicial } from '../Configuracion/Metodos';

export default function ConvenioRegistroUsuario({ history }) {

    const { datos } = useParams();
    const { convenioID, nombreDB } = DecodificarToken(datos);

    const cleanState = { nombres: "", apellidos: "", correo: "", password: "", showPassword: false };
    const cleanError = {
        nombres: { error: false, helperText: "" },
        apellidos: { error: false, helperText: "" },
        correo: { error: false, helperText: "" },
        password: { error: false, helperText: "" },
        error: { error: false, helperText: "" }
    };

    const [state, setState] = useState(cleanState);
    const [error, setError] = useState(cleanError);
    const [disabled, setDisabled] = useState(false);
    const [success, setSuccess] = useState(null);
    const [configuracion, setConfiguracion] = useState({});

    const REGISTRO_USUARIO_CONVENIO = `
    mutation registrarUsrConvenio($input : RegistroUsuarioConvenioInput!)
    {
      registroUsuarioConvenio(input: $input)
      {
        usuarioId
        convenioId
        nombres
        apellidos
        correo
      }
    }
    `;

    const useStyles = makeStyles((theme) => ({
        root: {
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
        },
        main: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(2),
        },
        footer: {
            padding: theme.spacing(3, 2),
            marginTop: "auto",
            backgroundColor:
                theme.palette.type === "light"
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
        },
    }));

    const classes = useStyles();

    function Copyright() {
        return (
            <Typography variant="body2" color="textSecondary">
                {"Copyright © "}
                <Link color="inherit" href="https://dentalperfiles.kalenday.app">
                    Dental Perfiles
                </Link>{" "}
                {new Date().getFullYear()}
                {"."}
            </Typography>
        );
    }

    const registrarUsuario = async (variables) => {
        try {
            let { registroUsuarioConvenio } = await FetchGrahpQL({
                variables: { input: variables },
                query: REGISTRO_USUARIO_CONVENIO
            });


            return registroUsuarioConvenio;

        } catch ({ message }) { throw new Error(message); }
    }

    const FetchConfiguracion = async () => {
        let data = await queryConfiguracionInicial({
            nombreDB,
        });
        setConfiguracion(data);
    }

    const inputs = [{
        disabled,
        error: error.nombres.error,
        id: "nombres",
        value: state.nombres,
        label: "Nombres",
        placeholder: "Capture su nombre",
        helperText: error.nombres.helperText,
        onChange: ftHandleChange
    }, {
        disabled,
        error: error.apellidos.error,
        id: "apellidos",
        value: state.apellidos,
        label: "Apellidos",
        placeholder: "Capture sus apellidos",
        helperText: error.apellidos.helperText,
        onChange: ftHandleChange
    }, {
        disabled,
        error: error.correo.error,
        id: "correo",
        value: state.correo,
        label: "Correo",
        placeholder: "Capture su correo",
        helperText: error.correo.helperText,
        onChange: ftHandleChange
    }, {
        disabled,
        error: error.password.error,
        id: "password",
        value: state.password,
        tipo: 'password',
        label: "Contraseña",
        placeholder: "Capture su contraseña",
        helperText: error.password.helperText || 'La contraseña debe contener una letra mayúscula, minúscula, número y un caracter especial (@#$%¡!*=<>()|¿?&-), tener como mínimo 8 caracteres y máximo 30 caracteres',
        onChange: ftHandleChange,
    }];

    function ftHandleChange({ target: { value, id } }) { setState({ ...state, [id]: value }); }

    /*    const handleClickShowPassword = () => {
           setState({ ...state, showPassword: !state.showPassword });
         }; */

    function ftValidar(data) {

        let keys = Object.keys(data),
            newObjError = {};

        keys.forEach(key => {
            if (!/^.+$/.test(data[key])) newObjError = {
                ...newObjError,
                [key]: {
                    error: true,
                    helperText: "Este campo es obligatorio"
                }
            }
            if (key === "correo") {
                if (!/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(data[key])) newObjError = {
                    ...newObjError,
                    [key]: {
                        error: true,
                        helperText: "Por favor capture un correo válido"
                    }
                }
            }
            if (key === "password") {
                if (!/^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/.test(data[key])) newObjError = {
                    ...newObjError,
                    [key]: {
                        error: true,
                        helperText: "Por favor capture una contraseña válida: debe contener una letra mayúscula, minúscula, número y un caracter especial (@#$%¡!*=<>()|¿?&-), tener como mínimo 8 caracteres y máximo 30 caracteres"
                    }
                }
            }
        });

        setError({ ...cleanError, ...newObjError });

        return Object.keys(newObjError).length === 0;

    }

    function ftRegistarUsuario() {
        async function registrar() {
            try {

                setDisabled(true);

                const { apellidos, correo, nombres, password } = state;

                if (!ftValidar({ apellidos, correo, nombres, password })) { setDisabled(false); return; }

                await registrarUsuario({ apellidos, correo, nombres, password, nombreDB, convenioID });

                setError({ ...cleanError });
                setState({ ...cleanState });
                setSuccess("Usuario creado, recibirás un correo para activar tu cuenta (no olvides buscar en correo no deseado)");

            } catch ({ message: helperText }) {
                setSuccess(null);
                setError({ ...cleanError, error: { error: true, helperText } });
            }
            setDisabled(false);
        } registrar();
    }

    function ftRedireccionar() {
        async function Redireccionar() {
            history.push("/")
        } Redireccionar();
    }

    useEffect(() => {
        FetchConfiguracion();
    }, []);

    return (<>
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="sm">
                <div style={{ margin: "0, auto", textAlign: "center" }}>
                    {
                        configuracion.urlLogotipo ? <img src={`${rutaPublica}${configuracion.urlLogotipo}`} alt="logotipo" style={{ maxWidth: "170px", width: "100%", height: "auto" }} /> : <CircularProgress />
                    }
                </div>
                <br />
                <Typography variant="h2" component="h2" align="center" gutterBottom>
                    Registro
                </Typography>
                <br /><br />
                {
                    (error.error.error || success) && <><Typography variant="body1" color={success ? "primary" : "error"}>
                        {error.error.error && <>Una disculpa, ocurrio el siguiente problema: <br /><b>{error.error.helperText === "Failed to fetch" ? "Lo sentimos, el servidor no se encuentra disponible, intentalo más tarde." : error.error.helperText}</b></>}
                        {success && <b>{success}</b>}
                    </Typography>
                        <br /> <br /></>
                }
                <Grid container spacing={1}>
                    {
                        inputs.map(({ tipo = 'text', disabled, error, id, value, label, placeholder, helperText, onChange, multiline = false }, key) => (<Grid item xs={12} key={key}>
                            {/*  <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                edge="end"
                                >
                                {state.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton> */}
                            <TextField
                                type={tipo}
                                disabled={disabled}
                                error={error}
                                id={id}
                                value={value}
                                label={label}
                                placeholder={placeholder}
                                helperText={helperText}
                                onChange={onChange}
                                multiline={multiline}
                                fullWidth
                            />
                            {/*   </InputAdornment> */}
                        </Grid>))
                    }
                    <Grid item xs={12}>
                        <br /><br />
                        <Button
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<SendIcon />}
                            onClick={ftRegistarUsuario}
                            fullWidth
                        >
                            {disabled ? "Espere un momento..." : "Crear usuario"}
                        </Button>
                        <br />
                        <br />
                        <Button
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            endIcon={<SendIcon />}
                            onClick={ftRedireccionar}
                            fullWidth
                        >
                            {disabled ? "Espere un momento..." : "Entrar a Dental Perfiles"}
                        </Button>
                    </Grid>
                </Grid>
                <br />
            </Container>
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Typography variant="body1">
                        {"Puedes contactarnos directamente al siguiente correo:"}
                        {" "}
                        <Link color="inherit" href="mailto:clinicadentalperfiles@gmail.com">
                            clinicadentalperfiles@gmail.com
                        </Link>
                    </Typography>
                    <Copyright />
                </Container>
            </footer>
        </div>
    </>
    );

}
