import gql from 'graphql-tag';
const LISTADO_HISTORIAL_CLINICO = gql `
query ListadoHistorialClinico($limite: Int,$pagina: Int,$historialId: Int,$anamnesisId: Int,$tipoPadecimientoId: Int,$pacienteId: Int,$desde: Date!, $hasta: Date!,$nombreCompleto: String){
  listadoHistorialClinico(limite:$limite, pagina: $pagina,historialId: $historialId, anamnesisId:$anamnesisId,tipoPadecimientoId: $tipoPadecimientoId, pacienteId: $pacienteId, desde: $desde, hasta: $hasta, nombreCompleto:$nombreCompleto ){
  HistorialPacientes{
    historialId
    observacionesHistorial
    anamnesisId
    fechaHistorial
    descripcion
    tipoPadecimientoId
    ultimaObservacion
    fechaAlta
    pacienteId
    nombreCompleto
    email
    sexo
    telefono
    fechaNacimiento
    padecimiento
  }
    paginas,
    registros
  }
}`;
export
{
    LISTADO_HISTORIAL_CLINICO
}
