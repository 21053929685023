
export const limpiarEvento = {
    agendaID: 0,
    clinicaID: 0,
    clinicaDoctoID: 0,
    pacienteID: 0,
    estatusID: 0,
    servicioDoctorID: 0,
    servicioID: 0,
    title: "",
    descripcion: ""
};
