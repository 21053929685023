import React, { useState, useContext, useEffect } from 'react';
//Componentes Core
import { Dialog, Button, DialogTitle, DialogContent, DialogActions,MenuItem }
    from '../../../componentesGenericos/Core';
//context
import { CRMContext } from './../../../context/Registro';
//Fetch
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//Componentes
import Desk from './Formularios/Desk';
import Mensaje from '../../../componentesGenericos/Mensaje';
//helpers
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
//query
import { NUEVO_PACIENTE } from "../../../mutation";
//constantes
import { agregarPaciente } from './../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial,mensajeExito } from '../../../Helpers/constantes';

function RegistroDesk(props) {

    //DECLARACIONES
    let { cerrarRegistro, open } = props;
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [getNotificacion, setNotificacion] = useState(agregarPaciente);
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [activarBoton, setActivarBoton] = useState(false);
    let sesiones = process.env.REACT_APP_DB;

    //Eventos
    const handleClickPaciente = async () => {
        setActivarBoton(true);
        let campos = [
            "nombres",
            "apellidos",
            "email",
            "password"
        ];
        let datos = verificaValores(campos, usuarios.Paciente);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            setActivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            await FetchRegistro();
        }
    }


    //API
    const FetchRegistro = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        FetchGrahpQL({
            query: NUEVO_PACIENTE,
            variables: {
                nombres: usuarios.Paciente.nombres.valor.trim(),
                apellidos: usuarios.Paciente.apellidos.valor.trim(),
                email: usuarios.Paciente.email.valor,
                password: usuarios.Paciente.password.valor,
                sexo: null,
                telefono: null,
                fechaNacimiento: null,
                nombreDB: sesiones
            }
        })
            .then(resp => {
                setMensaje({ ...mensaje, ...mensajeExito, texto: `Se creó correctamente la cuenta de ${resp.nuevoPaciente.email},y se envió un correo para confirmar ` });
                setActivarBoton(false);
                cerrarRegistro();
            })
            .catch((err) => {
                if (err.message === "Failed to fetch") {
                    setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto:"Ha ocurrido algo inesperado,inténtalo  más tarde"});
                }else{
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
                }
                setActivarBoton(false);
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };

    //Funciones
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }


    //Ciclo de vida
    useEffect(() => {
        guardarUsuarios(agregarPaciente)
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }, [open]);

    return (
        <>
            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />
            <Dialog maxWidth="sm" open={open}
                onClose={cerrarRegistro}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Registro</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Desk />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={cerrarRegistro}
                        color="primary">
                        Cancelar
                </Button>
                    <a href="#arriba"
                        style={{ textDecoration: "none" }}

                    >
                        <Button
                            onClick={handleClickPaciente}
                            disabled={activarBoton}
                            color="primary">
                            Enviar
              </Button>
                    </a>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default RegistroDesk;
