import { cuatroCaracteres } from "../formatos";

export const rgxEmail = "^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$";
export const rgxCorreo = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/;
export const rgxMultiCorreos = /^(([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}(\.[a-z]{2,3})+(;)?)+$/;
export const rgxtel = "^[0-9]{10,10}$";

export const rgxRFCFisico = /^[A-Z,Ñ,&]{3,4}[0-9]{2}[0,1][0-9][0-3][0-9][A-Z,0-9][A-Z,0-9][0-9,A-Z]$/;

export const rgxNumeroMayor1 = /^[0-9]*[1-9]+[0-9]*$/;

export const rgxNoVacio = /^.+$/;

export const rgxNumero = /^[0-9]+$/;

export const rgxTelefono = /^[0-9]{10,10}$/;

export const rgxCodigoPostal = /^[0-9]{5,5}$/;

export const rgxEspacioEnBlanco = /^[^]+$/;

export const rgxDecimales = /^[0-9]{1,8}(\.{1}[0-9]{1,6}){0,1}$/;

export const rgxDecimalesCero = /^[0-9]{1,8}(\.{1}[0-9]{0,6}){0,1}$/;

export const rgxDecimalesPV = /^[0-9]*(\.{1}[0-9]{0,6}){0,1}$/;

export const rgxLetras = /^(\s*[a-zA-ZÀ-ÿ.]+\s*)+$/;

export const rgxLetras50max = /^[a-zA-ZÀ-ÿ\s\_\-\.\,]{1,50}$/;

export const rgxPassword = /^(?=(.*[\d]){1,})(?=(.*[a-zñ]){1,})(?=(.*[A-ZÑ]){1,})(?=(.*[@#$%¡!*=<>()|¿?&-]){1,})(?:[\da-zA-ZÑñ=@#$%¡!*<>()|¿?&-]){8,30}$/;

export const rgxPorcentaje = /^(100|([0-9]{1,2}|[0-9]{1,2}\.[0-9]{1,10}))$/;

export const rgxSoloLetras = /^\D+$/;

export const rgxAlmenosUnaLetra = /^((\s*[\d]*)*[a-zA-Zá-úÁ-ÚÑñ,]+(\s*[\d]*)*)+$/;

export const rgxSinEspacios =  /^[A-Za-z][A-Za-z0-9]*$/;

export const rgxSinSaltoDeLinea = /^[\n]*$/;

export const rgxSelectID = /^([1-9]|[0-9]{2,})$/;

export const rgxHexadecimal = /^#[\w\d]{6}$/;

export const rgxNumCentroCosto = /^[0-9]{0,5}$/;

export const rgxCuatroCaracter =  /^\w$/;

export const rgxNumeroDosDigitosSinCero = /^[1-9][0-9]?$/;

export const rgxNumeroConHastaSeisDecimales = /^\d+(\.\d{1,6})?$/;

// Hora formato 00:00:00
export const rgxHora = /^([01]\d|[2][0-3])(\:([0-5]\d)){2}$/;

export const rgxFechaValida= /^20[0-9][0-9]-((0[1-9])|(1[0-2]))-(0[1-9]|[12][0-9]|3[01])$/;

export const rgxLetrasPrimeraMayuscula=/^[A-Z][a-zA-ZÀ-ÿ.]+(\s*[a-zA-ZÀ-ÿ.]+\s*)*$/;

export const rgxPorcentajeEntero1=/^(100|([0-9]{1}[1-9]{1}|[0]*[1-9]{1,2}|[0]*[1-9]{1}[0]{1}))$/;
