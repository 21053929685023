import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from "react-router-dom"
import { OBTENER_SESION_EMPRESA } from '../../../query/componentes/KalendaySesiones';
import { CRMContext, CRMProvider } from '../../../context/Registro';
import Mensaje from '../../../componentesGenericos/Mensaje';
import { Grid, InputAdornment, TextField, Typography, Button, ButtonGroup, CardContent, Card, Link, CssBaseline, Paper, Avatar, Checkbox, Box, FormControlLabel, Dialog, DialogTitle, IconButton, Fade, Hidden }
    from '../../../componentesGenericos/Core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../../../index.css';
import { LockOpenIcon, PersonIcon, LockOutlinedIcon, CloseIcon } from '../../../componentesGenericos/Icons';
import BotonBoostrap from "../../../componentesGenericos/Botones/BotonBoostrap";

import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";


import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
import { mensajeError, mensajeInicial } from '../../../Helpers/constantes';
import { notificacionesLogin, usuarioLogin } from '../../../Helpers/constantes/State';
//****REDUX***********/
import { useDispatch } from "react-redux";
import { IniciarSesion } from "../../../Redux/Actions/SesionAction";
import { SonidoActivacion } from "../../../Redux/Actions/SonidoNotificacionesAction";
import { ObtenerSesiones } from "../../../Redux/Actions/KalendaySesionesAction";
import { FotoPerfil } from "../../../Redux/Actions/FotoPerfilAction";

import { UseFocus } from "../../../Helpers/Eventos/Hooks";
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
//****** MATERIAL UI ******
import { makeStyles } from '@material-ui/core/styles';
import { urlServer, strUrlSCliente } from '../../../Helpers';

//Socket
import { socket } from '../../../App';
import { obtenerStateStorage, guardarStateStorage } from '../../../Helpers/LocalStorage/LocalStorage';
import QrReader from 'react-qr-reader';
import { formatearCadena, verificaValores } from '../../../Helpers/Validacion/ValidacionCadenas';

import Input from '@material-ui/core/Input';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        cursor: "pointer",
        backgroundRepeat: 'no-repeat!important',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover!important',
        backgroundPosition: 'center!important',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    invisible: {
        width:'0px'
    }
}));


function IniciarSesionDesk(props) {
    const { urlLogotipo = "/images/icon.png" } = props;
    const logotipo = urlLogotipo;

    //Variables
    const inputEmpresaDB = {
        claveEmpresaDB: { error: false, valor: "" },
        desactivarBoton: { error: false, valor: "" }
    };
    const [openImg, setOpenImg] = useState(false);
    const [usuario, guardarUsuario] = useContext(CRMContext);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [getNotificacion, setNotificacion] = useState(notificacionesLogin);
    const [getUsuario, setUsuario] = useState(usuarioLogin);
    const [open, setOpen] = useState(false);
    const [abrirRecuperarPassword, enviarRecuperarPassword] = useState(false);
    const [photo, setPhoto] = useState("");
    const [vista, setVista] = useState("");
    const [configuracion, setConfiguracion] = useState("");
    const [claveEmpresaDB, setClaveEmpresaDB] = useState("");
    const [redirigir, setRedirigir] = useState(false);
    const [datosEmpresaDB, setDatosEmpresaDB] = useState(inputEmpresaDB);
    const [abrirLector, setAbrirLector] = useState(false);
    const [valueInput, setValueInput] = useState("");
    const listImgKalenday = [
        {
            id: "1",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC9jMDE2MzQ0Zi0wOTYxLTRmMTctOGZkNS1kOTA1ZGYxZTIyYjQucG5n",
            descripcion: "Publicidad Kalenday"
        },
        {
            id: "2",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC9mNzYwMjJkZC0yNmU1LTRkZGQtYTIyMi1lOTk2MGZhMWM3MDQucG5n",
            descripcion: "Publicidad Kalenday"
        },
        {
            id: "3",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC8xYjc1MDBhNi03NjYzLTRjMjEtODcxNy1mNWZlOTcyODUxNjMucG5n",
            alt: "Publicidad Kalenday"
        },
        {
            id: "4",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC8zODQ5MmIwNC0yNzMyLTQ0MWUtYmY1ZS05YjRjMThmMGI0YzgucG5n",
            alt: "Publicidad Kalenday"
        }
    ]


    const [input, setInput] = useState(false);

    const classes = useStyles();

    const { history } = props;

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const agregarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const agregaRutaPerfil = (datos) => dispatch(FotoPerfil(datos));
    const agregarSonido = (datos) => dispatch(SonidoActivacion(datos));
    const sesionesKalenday = (datos) => dispatch(ObtenerSesiones(datos));

    //Eventos
    const EventChangeDatos = (event) => {
        let { name,value } = event.currentTarget;
        value = value.trim();
        setValueInput(value)
        if(value === null || value.length === 0)
        {
            setDatosEmpresaDB({...datosEmpresaDB,[name]: {error: true, valor: ""}});
        }else
        {
            setDatosEmpresaDB({...datosEmpresaDB,[name]: {error: false, valor: value}});
        }
        //setClaveEmpresaDB(value);
    };

    const handleScan = (data) => {
        if (data) {
            setClaveEmpresaDB(data);
            setDatosEmpresaDB({
            claveEmpresaDB: { error: false, valor: data },
            desactivarBoton: { error: false, valor: "" }
            });
            EventAutenticarCliente();
        }
    };
    const handleError = (err) => {
        console.error(err);
    };

    const EventAutenticarCliente = async () => {
        ActivarDesactivarBoton(true);

        let campos = ["claveEmpresaDB"];
        let datos = verificaValores(campos,setDatosEmpresaDB);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchSesiones();
        }
       /*  if (claveEmpresaDB.trim() === "") {
            ActivarDesactivarBoton(false);
            setMensaje({
                ...mensaje,
                ...mensajeError,
                titulo: "¡Aviso!",
                texto: "Es necesario introducir una clave",
            });
        } else {
            await FetchSesiones();
        } */
    };

    const eventoQr = (event) => {
        if (event) {
            console.log(event)
            setAbrirLector(!abrirLector);
        }
    };

    const userIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonIcon style={{ color: valor }} />
                </InputAdornment>
            ),
        };
    };
    const passwordIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <LockOpenIcon style={{ color: valor }} />
                </InputAdornment>
            ),
        };
    };
    const AbrirRegistro = () => {
        setOpen(true);
    };
    const CerrarRegistro = () => {
        setOpen(false);
    };
    const CerrarRecuperarPassword = () => {
        enviarRecuperarPassword(false);
    };
    const AbrirRecuperarPassword = () => {
        enviarRecuperarPassword(true);
    };
    const ftCloseImg = () => {
        setOpenImg(false);
    };

    const generarSelect = () => {
    let row = [];
    listImgKalenday.map(function (x) {
            row.push(
                <Fade in={true} key={x.imgPromoId}>
                    <div key={x.id} className="maximizaAlto"  onClick={() => { setOpenImg(true); setVista(`${x.url}`) }} >
                        <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${x.url}`} />
                    </div>
                </Fade>
            );
    });
    return row;
};

    //API
    // console.log("STATE_EMPRRSA_DB:", datosEmpresaDB);

    const FetchSesiones = async () => {
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });
        if (datosEmpresaDB.claveEmpresaDB.valor !== ""){

        FetchGrahpQL({
            query: OBTENER_SESION_EMPRESA,
            variables: { input: { clave: datosEmpresaDB.claveEmpresaDB.valor } },
        })
            .then((resp) => {
                // console.log("RES_PUESTA:",resp)
                //setDatosEmpresaDB(resp);
                sesionesKalenday(resp.obtenerSesionEmpresa);
                guardarStateStorage({ sesiones: resp.obtenerSesionEmpresa,},"kalendaySesiones");
                ActivarDesactivarBoton(false);
                setRedirigir(true);
            })
            .catch(({ message }) => {
                // throw new Error(message)
                //console.log("ERROR:", message);
                if (message === "Failed to fetch") {
                    setMensaje({
                        ...mensaje,
                        ...mensajeError,
                        titulo: "¡Aviso!",
                        texto:
                            "Ha ocurrido algo inesperado,inténtalo  más tarde ",
                    });
                } else {
                    setMensaje({
                        ...mensaje,
                        ...mensajeError,
                        titulo: "¡Aviso!",
                        texto: message,
                    });
                }
                ActivarDesactivarBoton(false);
            });
            //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
       }
    };

    //Ciclo de vida
    useEffect(()=>{
        async function iniciar() {
            try{
                if(input){
                    await FetchSesiones();
                }
            }catch({message}){
                console.log(message);
            }
        }iniciar();
    }, [input]);

    useEffect(() => {
        let sesion = process.env.REACT_APP_DB;
        if (redirigir) {
            // console.log("Estoy redirigiendo al login redirigir")
            setTimeout(function () {
                history.push("/");
            }, 500);
        }
        //Si el usuario ya había ingresado el código de una empresa e intenta regresar a la página principal (kalenday.app) se le redirecciona al login de su respectiva empresa.
        if (sesion) {
            // console.log("Estoy redirigiendo al login sesion")
            history.push("/");
        }
        // console.log("redirigir:",redirigir);
        // console.log("sesión:",sesion)
    }, [redirigir]);

    //Funciones
    const ActivarDesactivarBoton = (tipo) => {
        setUsuario({
            ...getUsuario,
            desactivarBoton: { error: tipo, valor: "" },
        });
    };
    const ftMensajeClose = () => {
        setMensaje({ ...mensaje, abrir: false });
    };

    return (
        <Grid
            container
            component="main"
            className={classes.root}
            style={{ width: "100%", overflow: "hidden" }}
        >
                <CssBaseline />

                <Mensaje
                    titulo={mensaje.titulo}
                    abrir={mensaje.abrir}
                    texto={mensaje.texto}
                    onClose={ftMensajeClose}
                    icono={mensaje.icono}
                    tipo={mensaje.tipo}
                    color={mensaje.color}
                    onAccept={mensaje.onAccept}
                />

                <Dialog open={openImg}>
                    <DialogTitle
                        disableTypography
                        style={{ textAlign: "Right" }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={ftCloseImg}
                            style={{ padding: 0 }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <img src={vista} width={"100%"} />
                    </DialogTitle>
                </Dialog>

                <Grid item xs={false} sm={4} md={7}>
                    <Carousel
                        showThumbs={false}
                        autoPlay={true}
                        showStatus={false}
                        infiniteLoop={true}
                        transitionTime={1000}
                    >
                        {generarSelect(photo)}
                    </Carousel>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <div className={classes.paper}>
                        {/* <Avatar
                            alt="Remy Sharp"
                            src={logotipo}
                            className={classes.large}
                            style={{ minWidth: "120px", minHeight: "120px" }}
                        /> */}
                          <img
                    alt={`Icono Kalenday`}
                    className="maximizaAltoImagen"
                    src={`${logotipo}`}
                    style={{ maxWidth: "120px", maxHeight: "120px" }}
                />
                <br/>
                        <Typography component="h1" variant="h5">
                            {"¡Bienvenido a Kalenday!"}
                        </Typography>
                        <form className={classes.form} noValidate>
                            {abrirLector === true ? (
                                <Grid
                                    item
                                    xs={12}
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                >
                                    <QrReader
                                        delay={300}
                                        onError={handleError}
                                        onScan={
                                            abrirLector === true
                                                ? handleScan
                                                : console.log("apagando lector")
                                        }
                                        style={{ width: "50%" }}
                                    />
                                </Grid>
                            ) : (
                                <> </>
                            )}
                            <Grid>
                            <TextField
                            id="claveEmpresaDB"
                            label="Clave de Empresa"
                            name="claveEmpresaDB"
                            margin="dense"
                           onChange={EventChangeDatos}
                            type="text"
                            required
                            fullWidth
                            InputProps={
                                passwordIcon(datosEmpresaDB.claveEmpresaDB.error),
                                {onKeyPress: event => {
                                    const { key } = event;
                                    if (key === 'Enter' && valueInput.trim()!=="") {
                                        EventAutenticarCliente();
                                        // return;
                                      }
                                }
                            }}
                            color="primary"
                            error={datosEmpresaDB.claveEmpresaDB.error}
                        />
                                <TextField
                                    id="claveEmpresa2"
                                    // label="Clave de Empresa"
                                    // disabled
                                    type="text"
                                    className={classes.invisible}
                                    // inputProps={
                                    // Width="0px"
                                    // }
                                />
                            </Grid>

                            <BotonBoostrap
                                Texto={"ENTRAR"}
                                Color="primary"
                                EventoClick={EventAutenticarCliente}
                            ></BotonBoostrap>
                            {/*   <BotonBoostrap
                                Texto={abrirLector === false ? "LEER QR":"CERRAR LECTOR"}
                                Color="primary"
                                EventoClick={eventoQr}
                            ></BotonBoostrap> */}


                            <Box mt={5}></Box>
                        </form>
                    </div>
                </Grid>
        </Grid>
    );
}

export default withRouter(IniciarSesionDesk);
