import {ASIGNAR_CLINICA_DOCTORES}from '../Types';

const initialState={
    clinicaDoctorCrud: [{ activo: false }]
}

export default function(state=initialState,action){
    switch(action.type){
        case ASIGNAR_CLINICA_DOCTORES:
            return {
                ...state,
                clinicaDoctorCrud:[action.payload]
            }
        default:
            return state;
    }
}
