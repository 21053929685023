import {FOTO_PERFIL}from '../Types';

const initialState={
    perfil:[{ruta:""}]
}

export default function(state=initialState,action){
    switch(action.type){
        case FOTO_PERFIL:
            return {
                ...state,
                perfil:[action.payload]
            }
        default:
            return state;
    }
}
