import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Formulario from './Formulario';
import { CRMContext } from '../../../context/AutorizarDatos';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/AutorizarDatosAction";
//helper
import { agregarUsuario } from './../../../Helpers/constantes/State';
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Agregar(props) {

    //Declaraciones
    const { actualiza, seleccion, index } = props;
    let { open, ftClose } = props;
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(agregarUsuario);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();

    const Crud = (estatus,evento, datos, desactivar, cerrar) => dispatch(CRUD(estatus, evento, datos, desactivar, cerrar));

    //Eventos
    const handleClickAutorizar = () => {
        ActivarDesactivarBoton(true);
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(1, actualiza, usuarios.Usuario, (n) => ActivarDesactivarBoton(n), ftClose);
            limpiarMensaje();
    }

    const handleClickRechazar = () => {
        ActivarDesactivarBoton(true);
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(2,actualiza, usuarios.Usuario, (n) => ActivarDesactivarBoton(n), ftClose);
            limpiarMensaje();
    }

    //Funciones
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    const ActivarDesactivarBoton = (tipo) => {
        guardarUsuarios({
            ...usuarios,
            Usuario: {
                ...usuarios.Usuario,
                desactivarBoton: {
                    ...usuarios.Usuario.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }

    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        index(-1)
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Autorización de datos</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Formulario seleccion={seleccion} />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cerrar
             </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button
                                disabled={usuarios.Usuario.desactivarBoton.error}
                            onClick={handleClickRechazar}
                            color="primary">
                            RECHAZAR
                      </Button>
                    </a>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button
                                disabled={usuarios.Usuario.desactivarBoton.error}
                            onClick={handleClickAutorizar}
                            color="primary">
                            AUTORIZAR
                      </Button>
                    </a>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Agregar;
