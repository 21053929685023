import React, { useEffect, useState } from 'react';
import Encabezado from '../../../componentesGenericos/EncabezadoComponentes';
import GraficaPie from '../../../componentesGenericos/Graficas';
import Grid from '@material-ui/core/Grid';

import { queryGraficas } from './Metodos';

import uuid from 'react-uuid';
import { useSelector } from "react-redux";

export default function Graficas() {

    /*let hoy = new Date();
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let fechaSpanish = hoy.toLocaleDateString('es-ES', options);*/

    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const [ state, setState ] = useState([]);
    const clinicaID = _clinicaID ? Number(_clinicaID) : null;

    useEffect(() => {
        async function ftEffect() {
            
            const resultado = [];
            const PAtendidos = await queryGraficas({ grafica: "PAtendidos", clinicaID });
            const PAtendidosEspecialidad = await queryGraficas({ grafica: "PAtendidosEspecialidad", clinicaID });
            const TratamientosEspecialidad = await queryGraficas({ grafica: "TratamientosEspecialidad", clinicaID });
            const AgendaEstatus = await queryGraficas({ grafica: "AgendaEstatus", clinicaID });            

            if(!PAtendidos.error) resultado.push(PAtendidos);
            if(!PAtendidosEspecialidad.error) resultado.push(PAtendidosEspecialidad);
            if(!TratamientosEspecialidad.error) resultado.push(TratamientosEspecialidad);
            if(!AgendaEstatus.error) resultado.push(AgendaEstatus);

            setState(resultado);

        } ftEffect();
    }, [_clinicaID]);

    return(<>
        {
            state.length > 0 && <>
                <Encabezado titulo={`Estadistícas del mes actual`} />
                <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                    {
                        state.map(({ nombre, total, data }) => (
                            <Grid container item direction="row" justify="center" alignItems="center" xs={12} md={6} lg={3} spacing={3} key={uuid()}>
                                <Grid item>
                                    <GraficaPie text={nombre} total={total} data={data} />
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </>
        }
    </>);
}
