import React from 'react'
import { InputBase, IconButton, Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputGrid from '../../Formulario/componentes/InputGrid';

function Encabezado({ titulo, h = 1, classes = {}, filtro = "", onSearch, busqueda = true, tituloBusqueda = "Buscar", filtros = [], onFiltrosChange = () => { } }) {
    function onChange(e) {
        onFiltrosChange(e);
    }

    return (
        <Grid container direction={"row"} className={classes.encabezado}>
            <Grid container item xs={12} justify={"center"} autosize={true}>
                {h === 1 ? <h1 >{titulo}</h1>
                    : h === 2 ? <h2>{titulo}</h2>
                        : h === 3 ? <h3>{titulo}</h3>
                            : <h6>{titulo}</h6>
                }
            </Grid>

            <Grid container item xs={8} justify={"center"} alignItems={"center"}>
                {
                    filtros.length ? <InputGrid xs={4} tipo="select" id={filtros[0].id} texto={filtros[0].texto} valor={filtros[0].valor} opciones={filtros[0].opciones} /*otros estilosGrid?*/ handleChange={onChange} /> : null
                }
            </Grid>

            <Grid container item xs={4} justify={"center"} alignItems={"center"}>

                {busqueda === true ?
                    <div style={{ padding: '0 1px', margin: '0 0 10px 5px', border: '1px solid #c9c9c9', borderRadius: '5px' }}>
                        <InputBase value={filtro} name='filtroTabla' className={classes.input} placeholder={tituloBusqueda} inputProps={{ 'aria-label': 'Search' }} onChange={(e) => onSearch ? onSearch(e.target.value) : null} title={tituloBusqueda} />
                        <IconButton type="submit" className={classes.iconButton} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </div>
                    :
                    null
                }
            </Grid>

            <Grid container item xs={12}><br /></Grid>
        </Grid>
    )
}

export default Encabezado
