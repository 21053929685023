import gql from 'graphql-tag';

//#region FOTO DE PERFIL
const OBTENER_FOTO_PERFIL = `
query obtenerFotoPerfil($id: Int!) {
    obtenerFotoPerfil(id: $id) {
      fotoId
      usuarioId
      ruta
    }
  }
`;

const OBTENER_FOTO_PERFIL_DOCTOR = gql`
query obtenerFotoPerfil($id: Int!) {
    obtenerFotoPerfil(id: $id) {
      fotoId
      usuarioId
      ruta
    }
  }
`;

const LISTADO_FOTO_PERFIL = `
query listadoFotoPerfil($offset: Int!) {
    listadoFotoPerfil(offset: $offset) {
      fotoPerfiles {
        fotoId
        usuarioId
        ruta
      }
    }
  }`;

const LISTADO_FOTO_PERFIL_DOCTOR =  gql`
query listadoFotoPerfil($offset: Int!) {
    listadoFotoPerfil(offset: $offset) {
      fotoPerfiles {
        fotoId
        usuarioId
        ruta
      }
    }
  }`;
//#endregion
/// foto de perfil de los profesionales

const LISTADO_FOTOS_PROFESIONALES = gql`
query ListadoFotosProfesionales ($offset: Int){
  listadoFotosProfesionales(offset: $offset){
    fotoProfesionales{
      fotoId
      ruta
      usuarioId
      tipo
      nombre
      apellido
      email
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      genero
      telefono
      especialidadId
      especialidad
    }
  }
}`;
const LISTADO_FOTOS_PROFESIONALES_FETCH =`
query ListadoFotosProfesionales ($offset: Int){
  listadoFotosProfesionales(offset: $offset){
    fotoProfesionales{
      fotoId
      ruta
      usuarioId
      tipo
      nombre
      apellido
      email
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      genero
      telefono
      especialidadId
      especialidad
    }
  }
}`;
/// end fotos profesionales

export {
    OBTENER_FOTO_PERFIL,
    LISTADO_FOTO_PERFIL,
    OBTENER_FOTO_PERFIL_DOCTOR,
    LISTADO_FOTO_PERFIL_DOCTOR,
    LISTADO_FOTOS_PROFESIONALES,
    LISTADO_FOTOS_PROFESIONALES_FETCH
}
