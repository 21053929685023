import React, { useState } from 'react';
//apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_TIPO_PADECIMIENTO } from '../../query';
// Mutations
import { ELIMINAR_TIPO_PADECIMIENTO, NUEVO_TIPO_PADECIMIENTO,  MODIFICAR_TIPO_PADECIMIENTO } from '../../mutation';
//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//Constantes
import { seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';

//Redux
//import { useSelector } from 'react-redux';

//Helpers
import { rgxAlmenosUnaLetra } from '../../Helpers/constantes/Rgx';
import{cuatroCaracteres} from '../../Helpers/constantes/formatos';



export default function TipoPadecimiento() {
    // # Region Queries & Mutations
    const { loading, error, data, refetch } = useQuery(LISTADO_TIPO_PADECIMIENTO, {
        fetchPolicy: 'no-cache',
        variables: { limite: 1000, pagina: 1 },
        onError: (e) => {
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const [deleteTipoPadecimiento] = useMutation(ELIMINAR_TIPO_PADECIMIENTO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente ${seleccion.descripcion}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addTipoPadecimiento] = useMutation(NUEVO_TIPO_PADECIMIENTO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente ${seleccion.descripcion}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modTipoPadecimiento] = useMutation(MODIFICAR_TIPO_PADECIMIENTO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente ${seleccion.descripcion}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    // # Región States
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [formulario, setFormulario] = useState(formularioState);





    const camposFormulario = [
        { id: "tipoPadecimientoId", texto: "tipoPadecimientoId", valor: seleccion.tipoPadecimientoId, tipo: "hidden" },

        { id: "descripcion", texto: "Nombre del padecimiento *", valor: seleccion.descripcion, tipo: "text",
        xs: 12, ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)', regexp: rgxAlmenosUnaLetra,
        formato: cuatroCaracteres}
    ];

    //seleccion para Columnas


    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }]
        : [
            { id: 'tipoPadecimientoId', label: 'tipoPadecimientoId', minWidth: 0, hide: true },
            { id: 'descripcion', label: 'Padecimiento', minWidth: 100, align: 'left', textAlign: 'left', filtro: true }
        ];


    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data ?.listadoTipoPadecimiento ?.TiposPadecimientos;

    //#region handlers
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: (state) => ftAgregar(state) });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function handleModificarClick() {
        if (seleccion.tipoPadecimientoId !== null) {
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un padecimiento" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.tipoPadecimientoId !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar ${seleccion.descripcion}?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un padecimiento" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setFila(index);
        }
    }

    // #Región funciones

    function ftAgregar({  tipoPadecimientoId, descripcion, cleanStateFormulario }) {

        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                if (ftValidar([window.descripcion.value])) {
                    setSeleccion({ ...seleccion,  descripcion });
                    await addTipoPadecimiento({
                        variables: {
                            input: {
                                tipoPadecimientoId,
                                descripcion: window.descripcion.value
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();
    }

    function ftModificar({ descripcion, cleanStateFormulario }) {

        async function ftModificar() {
            try {
                setDisabledAccept(true);

                descripcion = descripcion || seleccion.descripcion;

                if (ftValidar([seleccion.tipoPadecimientoId]) === true) {
                    await modTipoPadecimiento({
                        variables: {
                            input: {
                                tipoPadecimientoId: seleccion.tipoPadecimientoId,
                                descripcion: descripcion
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                    refetch();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();

    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteTipoPadecimiento({ variables: { id: Number(seleccion.tipoPadecimientoId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }


    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }


    // Recargar

    if (actualizar === true) { refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) }); setActualizar(false); }

    return (

        <MenuLateral titulo="Listado de padecimientos" menu={menu(handleAgregarClick, handleModificarClick, handleEliminarClick)}>

            <Mensaje
                titulo={mensaje.titulo}
                abrir={mensaje.abrir}
                texto={mensaje.texto}
                onClose={ftMensajeClose}
                icono={mensaje.icono}
                tipo={mensaje.tipo}
                color={mensaje.color}
                onAccept={mensaje.onAccept}
                disabledAccept={disabledDelete}
                progress={disabledDelete}
            />


            <Formulario
                abrir={formulario.abrir}
                campos={camposFormulario}
                titulo="Padecimiento"
                onClose={ftFormularioClose}
                onAccept={formulario.onAccept}
                valor={seleccion}
                disabledAccept={disabledAccept}
                cleanAwait={true}
                fullWidth= {true} />


            <Tabla titulo="Listado de padecimientos" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.message} onPaginaChange={handlePaginaChange} titleResponsive={["tipoPadecimientoId", "descripcion"]} />
        </MenuLateral>

    )

}
