import React, { useState, useEffect } from 'react';

import { LISTADO_SERVICOS_PACIENTES } from '../../../query';
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';

import MenuLateral from '../../../componentesGenericos/SideBar';
import Encabezado from '../../../componentesGenericos/EncabezadoComponentes';
import Servicios from './index';
import { Grid } from '../../../componentesGenericos/Core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    fondo: {
        backgroundColor: 'white'
    }
}));

function ServiciosPaciente () {
    const classes = useStyles();
    const [clinicas, setClinicas] = useState([]);
    const [error, setError] = useState(false);

    const queryListadoClinica = () => {
        async function queryListadoClinica() {
            try {
                let resultado = await FetchGrahpQL({ query: LISTADO_SERVICOS_PACIENTES });
                let { listadoServiciosPacientes } = resultado;
                setClinicas(listadoServiciosPacientes);
            } catch({message}) {
                setError(true);
            }
        }
        return queryListadoClinica();
    };

    useEffect(() => {
        async function fEffect() {
            await queryListadoClinica();
        }
        fEffect();
    }, []);

    // let xs = clinicas.length >= 3 ? 4 : (clinicas.length === 1 ? 6 : 6);
    let xs = 3;
    let componente = error ? <> <h3>No se encontraron registros</h3> </> : clinicas.map((clinica, index) => (<Servicios key={index} xs={xs} clinica={clinica} />))
    console.log("Erro",error)
    return (
        <MenuLateral titulo="Servicios">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Encabezado titulo="Servicios" />
                </Grid>
				<Grid item xs={12} className={classes.fondo}>
                    <Grid container justify="center" spacing={2} >
                        { componente }
                    </Grid>
                </Grid>
            </Grid>
        </MenuLateral>
    );

}

export default ServiciosPaciente;
