import React from 'react';
import { useSelector } from 'react-redux';
import { socket } from '../App';
import { useUsuario, useKillSession } from './Funciones';

function Sockets({ children }) {
    const validarSesion = useUsuario();
    const terminarSesion = useKillSession();
    const user = useSelector(state => state.user?.usuario[0]?.user?.user);


    if (socket) {
        socket.on('connect', function (data) {

        })

        socket.on('notificacion', function (data) {
            if (window.refresh && user) if (user.token !== "") window.refresh();
            let { pathname } = window.location;
            if ((pathname === "/" || pathname === "/Menu" || pathname === "/agenda") && window.actAgenda) window.actAgenda();
        });

        socket.on('usuarioChange', function (data) {
            validarSesion();
        });

        socket.on('killSession', function (data) {
            console.log("conexión cerrada")
            terminarSesion();
            socket.emit('unregister',data);
        });
    }

    return (
        <>
            {children}
        </>
    )
}

export function cerrarSessionServidor(usuario) {

    if (socket) {
        socket.emit('killSession', usuario);
    }
}
export default Sockets
