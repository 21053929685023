const GRAFICA_FETCH = `
    query  Grafica ($grafica: String!, $clinicaID: Int) {
        obtenerGrafica(grafica: $grafica, clinicaID: $clinicaID) {
            total
            nombre
            data {
                id
                label
                angle
                radius
            }
        }
    }
`;

export {
    GRAFICA_FETCH
};
