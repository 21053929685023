import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './Reducers';
import {
    obtenerStateStorage,
    guardarStateStorage
} from '../Helpers/LocalStorage/LocalStorage';


let user=obtenerStateStorage('user');
let fotoPerfil=obtenerStateStorage('perfil');
let notificacion=obtenerStateStorage('notificacion');
let menu=obtenerStateStorage('menu');
let kalendaySesiones=obtenerStateStorage('kalendaySesiones');
if(user!==undefined){
    user=obtenerStateStorage('user').user;
}

if(fotoPerfil!==undefined){
    fotoPerfil=obtenerStateStorage('perfil').perfil;
}

if(notificacion!==undefined){
    notificacion=obtenerStateStorage('notificacion').notificacion;
}

if(menu!==undefined){
    menu=obtenerStateStorage('menu').menu;
}

// if(kalendaySesiones!==undefined){
//     kalendaySesiones=obtenerStateStorage('kalendaySesiones').kalendaySesiones;
// }


const initialState = {
    user,
    fotoPerfil,
    notificacion,
    menu,
    // kalendaySesiones
  }

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    compose(applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ?
            window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
);


store.subscribe(() => {
    guardarStateStorage({
        perfil: store.getState().fotoPerfil
    }, 'perfil')
});


store.subscribe(() => {
    guardarStateStorage({
        user: store.getState().user
    }, 'user')
});

store.subscribe(() => {
    guardarStateStorage({
        notificacion: store.getState().notificacion
    }, 'notificacion')
});

store.subscribe(() => {
    guardarStateStorage({
        menu: store.getState().menu
    }, 'menu')
});

// store.subscribe(() => {
//     guardarStateStorage({
//         kalendaySesiones: store.getState().kalendaySesiones
//     }, 'kalendaySesiones')
// });

export default store;

