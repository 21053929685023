import React, { useContext, useState, useEffect, useRef } from 'react';
//context
import PropTypes from 'prop-types';
//Core
import { FormControlLabel, Radio, RadioGroup, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
//Redux
import { useDispatch, useSelector } from "react-redux";
import { CRUD } from "../../../Redux/Actions/UsuariosAction";
//Formularios
import Usuarios from "./Formulario/Usuario";
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
import Administrador from "./Formulario/Administrador";
//Provider
import { CRMContext } from './../../../context/Usuarios/Usuarios';
//Helper
import { limpiarStateGeneralUsuarios } from '../../../Helpers/constantes/State';
import { agregarUsuario } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";

function Agregar(props) {
    //Declaraciones
    const { onClose, value: valueProp, open, actualiza, ...other } = props;
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [abrirFormularioUsuarios, setAbrirFormularioUsuarios] = useState(false);
    const [abrirFormularioAdminstrador, setAbrirFormularioAdministrador] = useState(false);
    const [value, setValue] = useState(valueProp);
    const [getNotificacion, setNotificacion] = useState(agregarUsuario);
    const radioGroupRef = useRef(null);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);
    const Crud = (categoria, tipo, evento, datos, desactivar, cerrar) => dispatch(CRUD(categoria, tipo, evento, datos, desactivar, cerrar));

    useEffect(() => {
        if (!open) {
            setValue(valueProp);
        }
    }, [valueProp, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
            radioGroupRef.current.focus();
        }
    };
    const handleChange = event => {
        setValue(event.target.value);
    };

    //Eleccion
    const handleCancelarEleccion = () => {
        onClose();
    };
    const handleAceptarEleccion = () => {
        onClose(value);


        switch (value) {
            case "Usuario":
                handleClickOpenUsuario()
                break;
            case "Administrador":
                handleClickOpenAdministrador()
                break;
        }
    };

    //Usuarios
    const handleClickCloseUsuario = () => {
        setAbrirFormularioUsuarios(false);
    };
    const handleClickOpenUsuario = () => {
        setAbrirFormularioUsuarios(true);
    };
    const handleClickUsuario = () => {
        ActivarDesactivarBotonUsuarios(true);
        let campos = [
            "nombres",
            "apellidos",
            "email",
            "password",
            "sexo",
            "telefono",
            "fechaNacimiento"
        ];
        usuarios.Usuario.solicitanteId = { error: false, valor: userToken.usuarioId };
        let datos = verificaValores(campos, usuarios.Usuario, [["sexo", "género"], ["fechaNacimiento", "fecha de nacimiento"], ["telefono", "teléfono"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBotonUsuarios(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(0, "Usuario", actualiza, usuarios.Usuario, (n) => ActivarDesactivarBotonUsuarios(n), () => handleClickCloseUsuario());
            limpiarMensaje();

        }
    }
    const ActivarDesactivarBotonUsuarios = (tipo) => {
        guardarUsuarios({
            ...usuarios,
            Usuario: {
                ...usuarios.Usuario,
                desactivarBoton: {
                    ...usuarios.Usuario.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }

    //Administrador
    const handleClickOpenAdministrador = () => {
        setAbrirFormularioAdministrador(true);
    };
    const handleClickCloseAdministrador = () => {
        setAbrirFormularioAdministrador(false);
    };
    const handleClickAdministrador = () => {
        ActivarDesactivarBotonAdministrador(true)
        let campos = [
            "nombres",
            "apellidos",
            "email",
            "password",
            "sexo",
            "telefono",
            "fechaNacimiento"
        ];
        usuarios.Administrador.tipo = { error: false, valor: userToken.tipo };
        let datos = verificaValores(campos, usuarios.Administrador, [["sexo", "género"], ["fechaNacimiento", "fecha de nacimiento"], ["telefono", "teléfono"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBotonAdministrador(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            Crud(0, "Administrador", actualiza, usuarios.Administrador, (n) => ActivarDesactivarBotonAdministrador(n), () => handleClickCloseAdministrador());
            limpiarMensaje();

        }
    }
    const ActivarDesactivarBotonAdministrador = (tipo) => {
        guardarUsuarios({
            ...usuarios,
            Administrador: {
                ...usuarios.Administrador,
                desactivarBoton: {
                    ...usuarios.Administrador.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }

    //Funciones
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    //Ciclo de vida
    useEffect(() => {
        guardarUsuarios(limpiarStateGeneralUsuarios);
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={abrirFormularioUsuarios} onClose={handleClickCloseUsuario} aria-labelledby="form-dialog-title" style={{ zIndex: 1350 }}>
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Agregar usuario</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Usuarios />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button onClick={handleClickCloseUsuario} color="primary">
                        Cancelar
                    </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button onClick={handleClickUsuario} disabled={usuarios.Usuario.desactivarBoton.error} color="primary">
                            GUARDAR
                </Button>
                    </a>
                </DialogActions>
            </Dialog>


            <Dialog maxWidth="sm" open={abrirFormularioAdminstrador} onClose={handleClickCloseAdministrador} aria-labelledby="form-dialog-title" style={{ zIndex: 1350 }}>
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Agregar administrador</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Administrador />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button onClick={handleClickCloseAdministrador} color="primary">
                        Cancelar
                    </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button onClick={handleClickAdministrador} disabled={usuarios.Administrador.desactivarBoton.error} color="primary">
                            GUARDAR
                </Button>
                    </a>
                </DialogActions>
            </Dialog>


            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={handleEntering}
                aria-labelledby="confirmation-dialog-title"
                open={open}
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">Tipo de usuarios</DialogTitle>
                <DialogContent dividers>
                    <RadioGroup
                        ref={radioGroupRef}
                        aria-label="ringtone"
                        name="ringtone"
                        value={value}
                        onChange={handleChange}
                    >

                            {
                              userToken.tipo===3? props.datos.map(option => (
                                    <FormControlLabel value={option} key={option} control={<Radio />} label={option} />
                                )):  <FormControlLabel value={"Usuario"} key={"jKtcdsm"} control={<Radio />} label={"Usuario"} />
                            }

                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancelarEleccion} color="primary">
                        {props.botonizq}
                    </Button>
                    <Button onClick={handleAceptarEleccion} color="primary">
                        {props.botonder}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

Agregar.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
};

export default Agregar;
