import React, { useState, useContext,useEffect } from 'react';
import clsx from 'clsx';
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, IconButton, InputAdornment,MenuItem } from '../../../../componentesGenericos/Core';
//Rgx
import { rgxEmail, rgxtel, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Registro';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//iconos
import { VisibilityIcon, VisibilityOffIcon } from '../../../../componentesGenericos/Icons';
//Fetch
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
//Helpers
import {ftAnioNoValido,ftAniosRangosValidos,ftAniosRangosValidosHoy} from "../../../../Helpers/";
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    espaciado: {
        padding: "20px"
    }
}));


function Desk() {

    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxLetra = new RegExp(rgxLetras);
    let rgxTelefono = new RegExp(rgxtel);
    let rgxPasswords = new RegExp(rgxPassword);
    const [sexo, setSexo] = useState(0);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0  || ftAniosRangosValidosHoy(value)==false || ftAnioNoValido(value)===true) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value) && value.length>1 ) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateSexo = event => {
        let { name, value } = event.target;

        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                [name]: {
                    ...datos.Paciente[name],
                    error: false, valor: value
                }
            }
        });
    }

    const handleChangeGuardarTelefono = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //API
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //FUNCIONES
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
    }, []);

    return (
        <>
            <Grid spacing={3} container direction="column"
                justify="center" className={classes.espaciado}>
                <Grid item xs={12}>
                    <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener numeros" error={datos.Paciente.nombres.error} />
                </Grid>
                <Grid item xs={12}>
                    <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener numeros" onChange={handleChangeGuardarNombresApellidos} error={datos.Paciente.apellidos.error} />
                </Grid>
                <Grid item xs={12}>
                    <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} />
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={clsx(classes.margin, classes.textField)}>
                        <InputLabel required={true} error={datos.Paciente.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                        <Input
                            style={{ paddingTop: "5px" }}
                            id="standard-adornment-password"
                            name="password"
                            error={datos.Paciente.password.error}
                            type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                            value={datos.Paciente.password.valor}
                            onChange={handleChangeGuardarStatePassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText>La contraseña debe contener una letra mayúscula, minúscula, número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"}, tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Paciente.telefono.error} />
                </Grid>

                <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    labelId={`genero`}
                                    id={`genero`}
                                    onChange={handleChangeGuardarStateSexo}
                                    name="sexo"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge el género '}</MenuItem>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                <Grid item xs={12}>
                    <form className={classes.container} noValidate style={{ width: "100%" }}>
                        <TextField
                            required={true}
                            style={{ width: "100%" }}
                            id="date"
                            helperText="La fecha de nacimiento es obligatoria"
                            label="Fecha de nacimiento"
                            type="date"
                            onChange={handleChangeGuardarStateFecha}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="fechaNacimiento"
                        />
                    </form>
                </Grid> */}
            </Grid>
        </>
    )

}

export default Desk;
