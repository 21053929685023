import {KALENDAY_SESIONES}from '../Types';

const initialState={
    sesiones:[{sesion:""}]
}

export default function(state=initialState,action){
    switch(action.type){
        case KALENDAY_SESIONES:
            return {
                ...state,
                sesion:[action.payload]
            }
        default:
            return state;
    }
}
