import gql from 'graphql-tag';
const LISTADO_RECORDATORIOS_CREDITOS=gql`
query {
    listadoRecordatoriosCreditos{
     recordatorioCreditos{
      recordatorioPagoId
      plazoCreditoId
      descripcionPlazo
      tituloRecordatorio
      descripcionPlazo
      dias
      estatus
      recordarAntesDespues
      descripcionRecordatorio
    }
    }
  }
  `;


export {

    LISTADO_RECORDATORIOS_CREDITOS
}
