import 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { VisibilityIcon, VisibilityOffIcon } from '../../Icons';
import uuid from 'react-uuid'

//material-ui Componentes
import { TextField, Grid, MenuItem, FormControlLabel, Radio, RadioGroup, Checkbox, Hidden, Input, Chip, InputLabel, Select, FormControl, FormLabel, InputAdornment, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    fileContainer: {
        border: '1px solid grey',
        overflow: 'hidden',
        borderRadius: 15,
        width: '100%',
        maxHeight: '46px'
    },
    btnFile: {
        padding: '5px 0',
        background: '#0d47a1',
        cursor: 'pointer',
        height: '100%',
        '&:hover': {
            background: '#3173d4',
        }
    },
    fileUploader: {
        margin: '0',
        padding: '8px',
        background: '#0d47a1',
        color: '#fff',
        height: '100%',
        '&:hover': {
            color: '#fff',
            background: '#3173d4',
            cursor: 'pointer'
        }
    },
    fileName: {
        fontSize: 14,
        paddingLeft: 5
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

function InputGrid({ xs = 12, sm, md, lg, tipo = "text", id, propiedades = { readOnly: false }, texto, margen = "normal", styleSelect = { paddingRight: 5 }, styleMenuItem = {},
    styleInput = {}, valor = "", handleChange, opciones = null, color = "primary", multiselect = false, disabled = false, otros, estilosGrid, handleClickShowPassword = () => { }, showPassword = false, ...otraProp }) {
    otros = { ...otros, ...otraProp };

    const classes = useStyles();
    const theme = useTheme();
    const [image, setImage] = React.useState(valor);

    let select = {}, componente;
    if (tipo === "select") select.select = true;

    let valorFormat = (valor !== undefined && valor !== null && valor !== "") ? valor : "";

    let endAdornment = tipo === 'password' ?
        <InputAdornment position="end">
            <IconButton
                aria-label="password visibilidad"
                onClick={handleClickShowPassword}
                onMouseDown={e => e.preventDefault()}
            >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
        </InputAdornment> : null;

    switch (tipo) {
        case "radio": {
            componente = <FormControlLabel
                value={valorFormat}
                control={<Radio id={id} />}
                label={texto}
                {...otros}
            />;
            break;
        }
        case "superradio": {
            componente = <FormControl component="fieldset">
                <FormLabel component="legend">{texto}</FormLabel> <RadioGroup
                    id={id}
                    name={id}
                    value={valorFormat}
                    onChange={handleChange}
                    {...otros}
                >
                    {
                        opciones.map(({ value, label }, index) => (
                            <FormControlLabel key={`${value}-${index}`} value={value} control={<Radio />} label={label} />
                        ))
                    }
                </RadioGroup>
            </FormControl>
            break;
        }
        case "checkbox": {
            let checked = typeof valor === "boolean" ? valor : false;
            delete otros.helperText;
            delete otros.error;
            componente = <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        checked={checked}
                        onChange={handleChange}
                        color={color}
                        disabled={disabled}
                        value={checked}
                    />
                }
                label={texto}
                {...otros}
            />;
            break;
        }
        case "hidden": {
            componente = <Hidden xsDown smDown mdDown lgDown xlDown>
                <TextField
                    id={id}
                    type={tipo}
                    name={id}
                    label={texto}
                    value={valorFormat}
                    {...otros}
                >
                    {opciones ? opciones.map(option => (
                        <MenuItem
                            key={uuid()}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )) : ""}
                </TextField>
            </Hidden>;
            break;
        }
        case "textarea": {
            componente = <TextField
                id={id}
                name={id}
                label={texto}
                margin={margen}
                value={valorFormat}
                inputProps={propiedades}
                onChange={handleChange}
                fullWidth
                style={{ paddingRight: 5 }}
                disabled={disabled}
                multiline
                // rows="4"
                {...otros}
            />;
            break;
        }
        case "autocomplete": {
            //Propiedades puede tener el multiple y disableCloseOnSelect
            componente = <Autocomplete {...propiedades} id={id} autoHighlight onChange={handleChange} value={propiedades.multiple ? valor : (typeof valorFormat === "object" || valorFormat !== -1 ? valorFormat : null)} renderInput={params => (
                <TextField {...params} label={texto} margin={margen} fullWidth {...otros} />
            )} />
            break;
        }
        case "label": {
            componente = <div {...otros}><p id={id} {...propiedades} >{valor}</p></div>
            break;
        }
        case "hr": {
            componente = <hr />;
            break;
        }
        case "title":{
            componente = <div {...otros}><h1 id={id} {...propiedades} >{valor}</h1></div>
        }
        case "superFile": {
            componente =
                <Grid container item xs={12} direction="row" alignItems="center" className={classes.fileContainer}>

                    <Grid container justify="center" item xs={3} className={classes.btnFile}>
                        <label for={id} className={classes.fileUploader}> Subir archivo </label>
                        <input id={id} onChange={(e) => { handleChange && handleChange(e); setImage(e.target?.files[0]?.name) }} type="file" style={{ display: 'none' }} accept="image/*" />
                    </Grid>

                    <Grid item xs={8} className={classes.fileName}><div id="info">{image}</div></Grid>
                </Grid>
            break;
        }
        default:
            componente = multiselect ? (<FormControl className={classes.formControl}>
                <InputLabel id={`labelmulti-${id}`}>{texto}</InputLabel>
                <Select
                    labelId={`labelmulti-${id}`}
                    id={id}
                    multiple
                    value={Array.isArray(valor) ? valor : []}
                    onChange={handleChange}
                    input={<Input id={`sel-mul-chip-${id}`} />}
                    inputProps={propiedades}
                    disabled={disabled}
                    style={styleSelect}
                    renderValue={selected => {
                        if (selected.map)
                            return <div className={classes.chips}>
                                {selected.map((value, index) => {
                                    let chip = null;
                                    opciones.forEach(({ value: valor, label }) => {
                                        if (value === valor) chip = label;
                                    });
                                    return (
                                        <Chip key={`${value}-${index}`} label={chip} className={classes.chip} />
                                    )
                                })}
                            </div>
                    }}
                    fullWidth
                    MenuProps={MenuProps}
                >
                    {opciones && Array.isArray(opciones) ? opciones.map((option, index) => (
                        <MenuItem
                            key={`${option.value}-${index}`}
                            value={option.value}
                            style={{ fontWeight: theme.typography.fontWeightRegular, color: option.color ? option.color : "#212", whiteSpace: 'break-spaces', ...styleMenuItem }}
                            disabled={typeof option.disabled === "boolean" ? option.disabled : false}
                        >
                            {option.label}
                        </MenuItem>
                    ))
                        : <MenuItem key={uuid()} value={opciones}>
                            No hay opciones
                    </MenuItem>}
                </Select>
                {otros.helperText && <FormHelperText style={{ color: 'red' }}>{otros.helperText}</FormHelperText>}
            </FormControl>)
                :
                (<TextField
                    id={id}
                    type={tipo}
                    name={id}
                    label={texto}
                    margin={margen}
                    value={valorFormat}
                    inputProps={propiedades}
                    onChange={handleChange}
                    fullWidth
                    {...select}

                    disabled={disabled}
                    {...otros}
                    style={{ paddingRight: 5, ...styleInput }}
                    endAdornment={endAdornment}
                >
                    {opciones && Array.isArray(opciones) ? opciones.map((option, index) => (
                        <MenuItem key={`${option.value}-${index}`} value={option.value} disabled={typeof option.disabled === "boolean" ? option.disabled : false}
                            style={{ color: option.color ? option.color : "#212", whiteSpace: 'break-spaces', ...styleMenuItem }}>
                            {option.label}
                        </MenuItem>
                    )) : opciones ? <MenuItem key={uuid()} value={opciones}>No hay opciones</MenuItem> : ""}
                </TextField>);
            break;
    }

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} style={estilosGrid || { textAlign: "left" }}>
            {componente}
        </Grid>
    )
}

InputGrid.propTypes = {
    id: PropTypes.string
};

export default InputGrid;
