import React from 'react';


const seleccionDirecciones = {
    direccionId: null,
    calle: '',
    codigoPostal: '',
    claveColonia: '',
    colonia: '',
    claveLocalidad: '',
    localidad: '',
    claveMunicipio: '',
    municipio: '',
    claveEstado: '',
    estado: '',
    clavePais: '',
    pais: '',
    clinicaId: '',
    colonias: [],
    estatus: false,
    nombre: ''
}


const actualizarState = false;
const campoState = false;
const filaState = -1;

const formularioState = (ftFormularioClose) => {
    return{
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

const formularioModificarState = (ftFormularioClose) => {
    return{
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

const Style = {
    height: "90%", textAlign: "center", width:"100%"
};



export {seleccionDirecciones, actualizarState, campoState, filaState, formularioState, formularioModificarState, Style};
