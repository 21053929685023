import React, { useState, useEffect } from 'react';
//COMPONENTES
import MenuLateral from '../../componentesGenericos/SideBar';
import { Typography, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, Grid, FormHelperText, Select, FormControl, InputLabel, MenuItem, CardContent, Card, TextField, Button } from '../../componentesGenericos/Core';
import Fieldset from '../../componentesGenericos/FieldSet';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';

//REDUX*****
import { useSelector } from "react-redux";
//FETCH
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Query
import { LISTADO_DOCTOR_CADENA } from "../../query/componentes/Usuario";
import { LISTADO_CLINICAS_CADENA } from '../../query';
import { LISTADO_DOCTOR_ASIGNADOS } from '../../query';
import { SELECT_CLINICAS_DOCTOR_FETCH } from "../../query/componentes/Agenda";
import { LISTADO_COMISIONES_PROFESIONAL, LISTADO_COMISIONES_PROFESIONAL_TOTAL } from "../../query/componentes/Comision";
//Help
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
import { ftAnioNoValido, ftAniosRangosValidosAlterno } from "../../Helpers";
import { comisionesPorfesionales } from "../../Helpers/constantes/State";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    verificaValores,
    formatearCadena
} from "../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../Helpers/Validacion/ValidacionesCadenasClose";
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
let estilo = {
    border: `1.5px solid #267f9d`,
    borderRadius: '5px',
    marginBottom: '10px',
    width: `100%`
}


function ComosionesProfesionales() {

    //DECLARACIONES
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [comision, setComision] = useState('');
    const [clinicas, setClinicas] = useState('');
    const [doctor, setDoctor] = useState('');
    const [calculoComision, setcalculoComision] = useState('');
    const [getNotificacion, setNotificacion] = useState(comisionesPorfesionales);
    const [peticion, setPeticion] = useState(comisionesPorfesionales);
    const [boton, setBoton] = useState(false);
    //REDUX
    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;
    let datos = DecodificarToken(token);
    //EVENTOS
    const handleChangeFechaFinal = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) === false || ftAnioNoValido(value) === true) {

            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos.fechaFinal,
                        error: true, valor: value
                    },
                    fechaInicio: {
                        ...peticion.datos.fechaInicio,
                        error: true
                    }
                }
            });


        }
        else {


            if (ftVerificaFecha(peticion.datos.fechaInicio.valor, value) === true) {

                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaFinal: {
                            ...peticion.datos.fechaFinal,
                            error: false, valor: value
                        },
                        fechaInicio: {
                            ...peticion.datos.fechaInicio,
                            error: false
                        }
                    }
                });

            } else {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaFinal: {
                            ...peticion.datos.fechaFinal,
                            error: false, valor: value
                        }
                    }
                });
            }

        }
    }
    const handleChangeFechaInicio = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) === false || ftAnioNoValido(value) === true) {


            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: true, valor: value
                    },
                    fechaFinal: {
                        ...peticion.datos.fechaFinal,
                        error: true,
                    }
                }
            });



        }
        else {


            if (ftVerificaFecha(value, peticion.datos.fechaFinal.valor) === true) {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaInicio: {
                            error: false, valor: value
                        },
                        fechaFinal: {
                            error: false,
                            valor: peticion.datos.fechaFinal.valor
                        }
                    }
                });

            } else {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaInicio: {
                            error: false, valor: value
                        },
                    }
                });
            }

        }
    }
    const handleChangeDatos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || value === -1) {
            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: true, valor: value
                    }
                }
            });
        }
        else {
            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const handleClick = async () => {
        deshabilitarBoton(true);
        let campos = [
            "fechaInicio",
            "fechaFinal",
            "usuarioId",
            "clinicaId"
        ];
        let datos = verificaValores(campos, peticion.datos, [["fechaInicio", "Fecha inicial"], ["fechaFinal", "Fecha final"], ["usuarioId", "Profesional"], ["clinicaId", "Sucursal"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });

            await FetchDatosComisiones();
            await FetchDatosComisionesCalculo();
            deshabilitarBoton(false);

        }
    }

    //FUNCIONES
    const ftVerificaFecha = (inicio, final) => {
        if ((inicio > final)) {
            return false;
        } else {
            return true;
        }
    }
    const ftGenerarBloques = (data) => {
        if (data === '') return null;
        if (data === null) return <h3 style={{ textAlign: "center" }}>No se encontraron registros</h3>;
        let row = [];
        if (data !== undefined) {
            data.listadoComisionesProfesional.comisionesProfesional.map(function (info, index) {
                row.push(
                    <ExpansionPanel key={`${index}-exp1`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}-panel1bh-content`}
                            id={`${index}-panel1bh-header`}
                        >
                            <Grid container justify="space-between"
                                alignItems="center">
                                <Typography className={classes.heading}>{info.descripcionProducto} ({info.tipoServicioProductoMaterial})</Typography>
                                <Typography className={classes.secondaryHeading}>{`${info.porcComision * 100}%`}</Typography>
                                <Typography className={classes.secondaryHeading}>{info.nombreClinica}</Typography>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={3} >
                                {ftGenerarVentas(calculoComision, info.productoId, info.porcComision * 100)}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                );
                return true;
            });
            return row;
        }
    };
    const ftGenerarSelectClinicas = (data) => {
        if (data === null) return null;
        if (data === '') return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            if (datos.tipo === 1) {
                data.listadoClinicasPorDoctor.map(function (x) {
                    row.push(<MenuItem key={`${x.clinicaId}-doc1`} value={x.clinicaId} readOnly>{x.nombre}</MenuItem>);
                    return true;
                });
                return row;
            } else {
                data.listadoClinicas.clinicas.map(function (x) {
                    row.push(<MenuItem key={`${x.clinicaId}-doc1`} value={x.clinicaId} readOnly>{x.nombre}</MenuItem>);
                    return true;
                });
                return row;
            }
        }
    };
    const ftGenerarSelectDoctores = (data) => {
        if (data === null) return null;
        if (data === '') return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.obtenerDoctoresAsignadosClinicas.clinicaDoctores.map(function (x) {
                row.push(<MenuItem key={`${x.doctorId}-user1`} value={x.doctorId} readOnly>
                    {`${x.nombreCompleto} (${x.email})`}</MenuItem>);
                return true;
            });
            return row;
        }
    };
    const ftGenerarVentas = (data, idProducto, porComision) => {
        if (data === null) {
            return <p>Sin registros</p>;
        }
        if (data === '') {
            return <p>Sin registros</p>;
        }

        let row = [];
        row.push();
        if (data !== undefined) {
            data.listadoComisionesProfesionalTotal.comisionesProfesionalTotal.map(function (x, index) {

                if (x.servicioId === idProducto) {
                    row.push(
                        <Fieldset key={`${index}-user12`} contenido={
                            <Grid container spacing={1}>
                                <Grid item xs={12} style={{ marginTop: "10px" }}>
                                    <p>Fecha de venta: {x.fechaEmision.split('T')[0]}</p>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "10px" }}>
                                    <p>Comisión al vender: { x.porcComisionAlvender===null || x.porcComisionAlvender===0 || x.porcComisionAlvender===""?0: x.porcComisionAlvender}%</p>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "10px" }}>
                                    <p>Precio total:
                                       ${x.precioTotal === null || x.precioTotal === '' ? '0' : `${x.precioTotal.toFixed(2)}`}
                                    </p>
                                </Grid>
                                <Grid item xs={4} style={{ marginTop: "10px" }}>
                                    <p>cantidad: {x.cantidad}</p>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: "10px" }}>

                                    <p>Total:
                                         ${x.ComisionProductoVenta === null || x.ComisionProductoVenta === 0 || x.ComisionProductoVenta === '' ? '0' : `${x.ComisionProductoVenta.toFixed(2)}`}
                                    </p>
                                </Grid>
                            </Grid>
                        } Titulo="" estilos={estilo}></Fieldset>
                    );
                }
                return true;
            });


            if (row.length === 0) {
                return <p>Sin registros</p>;
            } else {
                return row;
            }
        }
    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }

    //API
    const FetchDatosComisiones = async () => {
        await FetchGrahpQL({
            query: LISTADO_COMISIONES_PROFESIONAL,
            variables: {
                comisionProfesional: {
                    clinicaId: parseInt(peticion.datos.clinicaId.valor),
                    profesionalId: datos.tipo === 1 ? parseInt(datos.usuarioId) : parseInt(peticion.datos.usuarioId.valor)
                }
            }
        })
            .then((data) => {
                setComision(data);
            })
            .catch((err) => {
                setComision(null);
            });
    };
    const FetchDatosComisionesCalculo = async () => {
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        await FetchGrahpQL({
            query: LISTADO_COMISIONES_PROFESIONAL_TOTAL,
            variables: {
                comisionProfesionalTotal: {
                    fechaInicio: peticion.datos.fechaInicio.valor,
                    fechaFinal: peticion.datos.fechaFinal.valor,
                    clinicaId: parseInt(peticion.datos.clinicaId.valor),
                    profesionalId: parseInt(peticion.datos.usuarioId.valor)
                }
            }
        })
            .then((data) => {
                setcalculoComision(data);
            })
            .catch(() => {
                setcalculoComision(null);
            });

        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };
    const FetchListadoClinicasAdm = async () => {
        await FetchGrahpQL({
            query: LISTADO_CLINICAS_CADENA,
            variables: { limite: 1000, pagina: 1 }
        })
            .then((data) => {
                setClinicas(data);
            })
            .catch(() => {
            });
    };
    const FetchObtenerDoctoresAsignados = async () => {

        await FetchGrahpQL({
            query: LISTADO_DOCTOR_ASIGNADOS,
            variables: {
                id: parseInt(peticion.datos.clinicaId.valor),
                correo: 0,
                estatus: 0
            }
        })
            .then((data) => {
                setDoctor(data);
            })
            .catch(() => {
                setDoctor('');
            });
    };

    //CICLO DE VIDA
    useEffect(() => {
        FetchListadoClinicasAdm()
        FetchObtenerDoctoresAsignados();
    }, []);

    useEffect(() => {

        //se limpia el state del doctor
        setPeticion({
            ...peticion,
            datos: {
                ...peticion.datos,
                usuarioId: {
                    ...peticion.datos.usuarioId,
                    error: true, valor: ''
                }
            }
        });

        FetchObtenerDoctoresAsignados();
    }, [peticion.datos.clinicaId]);

    return (
        <MenuLateral>
        <Encabezado titulo="Comisiones"/>
            <Card className={classes.mb1}>
                <CardContent>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            {GenerarNotificacionv2(getNotificacion, "w100")}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" disabled required={true}>Sucursal </InputLabel>

                                <Select
                                    labelId={`clinicaId`}
                                    id={`clinicaId`}
                                    name="clinicaId"
                                    onChange={handleChangeDatos}
                                    required={true}
                                //  value={peticion.datos.clinicaId.valor}
                                >
                                    <MenuItem value={'-1'} disabled>{'Sucursal'}</MenuItem>
                                    {ftGenerarSelectClinicas(clinicas)}
                                </Select>

                                <FormHelperText>La sucursal es campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" disabled required={true}>Profesional</InputLabel>

                                <Select
                                    labelId={`usuarioId`}
                                    id={`usuarioId`}
                                    name="usuarioId"
                                    required={true}
                                    value={peticion.datos.usuarioId.valor}
                                    onChange={handleChangeDatos}
                                >

                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Profesional'}</MenuItem>
                                    {ftGenerarSelectDoctores(doctor)}
                                </Select>

                                <FormHelperText>El profesional es campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    onChange={handleChangeFechaInicio}
                                    style={{ width: "100%" }}
                                    id="date"
                                    value={peticion.datos.fechaInicio.valor}
                                    required={true}
                                    label="Desde"
                                    helperText="La fecha de inicio es obligatoria"
                                    type="date"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaInicio"
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    value={peticion.datos.fechaFinal.valor}
                                    onChange={handleChangeFechaFinal}
                                    style={{ width: "100%" }}
                                    id="date"
                                    required={true}
                                    label="Hasta"
                                    helperText="La fecha final es obligatoria"
                                    type="date"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaFinal"
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                            <Button variant="contained" color="primary" disabled={boton} onClick={handleClick}>
                                Buscar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.root} style={{ marginTop: "15px" }}>
                                {ftGenerarBloques(comision)}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </MenuLateral>

    );
}

export default ComosionesProfesionales;
