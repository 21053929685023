import { useState, useEffect, useRef } from 'react';

//Apollo
import { useMutation } from '@apollo/react-hooks';

//Queries
import { AGREGAR_NOTIFICACION, MODIFICAR_NOTIFICACION, CAMBIAR_ESTATUS_NOTIFICACION, ELIMINAR_NOTIFICACION } from '../../../mutation';

import { socket } from '../../../App';

export function useSendNotification() {
    const error = useRef();

    const [addNotificacion] = useMutation(AGREGAR_NOTIFICACION, {
        onError: (e) => {
            console.log(e.message);
            error.current = e.message;
        }
    });

    async function send({ mensaje, usuarioId, fecha, diasRepeticion, estatus }) {
        let input = { mensaje, usuarioId, fecha, diasRepeticion, estatus };

        return addNotificacion({ variables: { notificacion: input } }).then(respuesta => {
            if (respuesta) {
                socket.emit('sendNotification', usuarioId);
                return { ok: true, msj: "Notificación enviada" };
            } else {
                return { ok: false, msj: "Ocurrió un error al enviar la notificación. " + error.current.replace("GraphQL error:", "") };
            }
        });
    }

    return send;
}

export function useModNotification() {
    const error = useRef();

    const [modNotificacion] = useMutation(MODIFICAR_NOTIFICACION, {
        onError: (e) => {
            console.log(e.message);
            error.current = e.message;
        }
    });

    async function mod({ notificacionId, mensaje, usuarioId, fecha, diasRepeticion, estatus }) {
        let input = { notificacionId, mensaje, usuarioId, fecha, diasRepeticion, estatus };

        return modNotificacion({ variables: { notificacion: input } }).then(respuesta => {
            if (respuesta) {
                socket.emit('sendNotification', usuarioId);
                return { ok: true, msj: "Notificación modificada correctamente" };
            } else {
                return { ok: false, msj: error.current.replace("GraphQL error:", "") };
            }
        });
    }

    return mod;
}

export function useDelNotification() {
    const error = useRef();

    const [deleteNotificacion] = useMutation(ELIMINAR_NOTIFICACION, {
        onError: (e) => {
            console.log(e.message);
            error.current = e.message;
        }
    });

    async function del({ id }) {

        return deleteNotificacion({ variables: { id: Number(id) } }).then(respuesta => {
            if (respuesta) {
                socket.emit('sendNotification', {});
                return { ok: true, msj: "Notificación eliminada correctamente" };
            } else {
                return { ok: false, msj: error.current.replace("GraphQL error:", "") };
            }
        });
    }

    return del;
}

export function useChangeStatusNotification() {
    const error = useRef();

    const [statNotificacion] = useMutation(CAMBIAR_ESTATUS_NOTIFICACION, {
        onError: (e) => {
            error.current = e.message.replace("GraphQL error:", "");
        }
    });

    async function change({ id, estatus,usuarioId }) {
        return statNotificacion({ variables: { id: Number(id), estatus: Number(estatus),usuarioId:Number(usuarioId) } }).then(respuesta => {
            if (respuesta) {
                socket.emit('sendNotification', {});
                return { ok: true, msj: `Notificación ${estatus ? "activada" : "desactivada"} correctamente` };
            } else {
                return { ok: false, msj: error.current.replace("GraphQL error:", "") };
            }
        });
    }

    return change;
}
