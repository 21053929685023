import gql from 'graphql-tag';

//#region Convenio
//=========Convenio=============

const LISTADO_CONVENIOS = gql `
query listadoConvenios($offset:Int,$empresa:Int,$estatus: Int, $correo: String)
{
  listadoConvenios(offset:$offset, empresa:$empresa, estatus: $estatus, correo:$correo){
    convenios
    {
      convenioId
      empresa
      tipoPago
      opcionesPago
      email
      estatus
      fechaFinal
      fechaInicial
      RFC
      urlLogo
      tipoConvenio
      promotorID,
      promotor,
      comision
    }
    registros
    paginas
  }
}
`;

const LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH =
`query listadoTodosLosConvenios($offset: Int) {
    listadoTodosLosConvenios(offset: $offset) {
      convenios {
        convenioId
        empresa
        tipoPago
        opcionesPago
        email
        estatus
        fechaFinal
        fechaInicial
        RFC
        urlLogo
      }
    }
  }
  `;


const LISTADO_CONVENIOS_FETCH = `
query listadoConvenios($offset:Int,$empresa:Int,$estatus: Int, $correo: String)
{
  listadoConvenios(offset:$offset, empresa:$empresa, estatus: $estatus, correo:$correo){
    convenios
    {
      convenioId
      empresa
      tipoPago
      opcionesPago
      email
      estatus
      fechaFinal
      fechaInicial
      RFC
      urlLogo
    }
    registros
    paginas
  }
}
`;

const LISTADO_PROMOTOR = `
query listarPromotores{
  listadoPromotores{
    promotores{
      promotorID
      promotor
      comision
      clave
    }
  }
}
`;

const LISTADO_PROMOTOR_GQL = gql `
query listarPromotores{
  listadoPromotores{
    promotores{
      promotorID
      promotor
      comision
      clave
    }
  }
}
`;

const OBTENER_CONVENIO = `
query obtenerConvenio ($id: Int!)
{
	obtenerConvenio(id:$id)  {
    convenioId
    empresa
    tipoPago
    opcionesPago
    email
    urlLogo
  }
}
`;

const OBTENER_FORMA_PAGO = `
    query obtenerFormaPago
    {
        getFormaPago{
            claveFormaPago
            descripcion
          }
    }
`;
 //#endregion

 //#region ConvenioServicios
//==========================================================

const LISTADO_CONVENIOS_SERVICIOS = gql `
    query listadoConvenioServicios($limite: Int,$pagina: Int){
  listadoConveniosServicios(limite:$limite, pagina:$pagina){
    convenioServicios
    {
      convenioServicioIdGeneral
      convenioId
      empresa
      correo
      tipoPago
      opcionesPago
      porcentajeDescuento
      servicios
      descripcionServicios
      precioUnitario
      fechaAltaConvenio
      servicioClinica
    }
    registros
    paginas
  }
}
`;
//#endregion
//==========================================================


export {
    LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH,
    LISTADO_CONVENIOS,
    OBTENER_CONVENIO,
    OBTENER_FORMA_PAGO,
    LISTADO_CONVENIOS_SERVICIOS,
    LISTADO_CONVENIOS_FETCH,
    LISTADO_PROMOTOR,
    LISTADO_PROMOTOR_GQL
}
