import { urlServer, strUrlSCliente, strUrlSArchivos } from '../../../Helpers';

export const rutaPublicaEmpresa = localStorage.getItem('rutaPublicaEmpresa');

export const rutaPrivadaEmpresa = localStorage.getItem('rutaPrivadaEmpresa');

export const rutaPublica =  strUrlSArchivos + '/public/kalenday';

export const rutaPrivada = strUrlSArchivos + '/private/kalenday';



