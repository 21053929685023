import { AGREGAR_USUARIO } from '../Types';
import { useSelector, useDispatch } from 'react-redux';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_USUARIO, NUEVO_PACIENTE, NUEVO_ADMINISTRADOR, NUEVO_DOCTOR, MODIFICAR_USUARIO, MODIFICAR_PACIENTE, MODIFICAR_ADMINISTRADOR, MODFICAR_DOCTOR } from "../../mutation/index";
import { MODIFICAR_DATOS_USUARIOS_AUTORIZADOS } from "../../mutation/componentes/DatosUsuariosAutorizacion";
import { obtenerStateStorage } from '../../Helpers/LocalStorage/LocalStorage'

//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, tipo, actualiza, datos, ActivarDesactivarBoton, ftClose) {
    return async (dispatch) => {
        let sesiones = process.env.REACT_APP_DB;
        if (categoria === 0) {
            switch (tipo) {
                case "Usuario":
                    FetchGrahpQL({
                        query: NUEVO_USUARIO,
                        variables: {
                            nombres: datos.nombres.valor.trim(),
                            apellidos: datos.apellidos.valor.trim(),
                            email: datos.email.valor,
                            password: datos.password.valor,
                            sexo: parseInt(datos.sexo.valor),
                            telefono: datos.telefono.valor,
                            fechaNacimiento: datos.fechaNacimiento.valor
                        }
                    })
                        .then(resp => {
                            Swal.fire("¡Éxito!", `Se ha enviado un correo a  ${resp.nuevoUsuario.email}, para confirmar cuenta`, "success");
                            ActivarDesactivarBoton(false);
                            actualiza();
                            ftClose();
                        })
                        .catch((err) => {
                            if (err.message === "Failed to fetch") {
                                Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                            } else {
                                Swal.fire("¡Aviso!", err.message, "warning");
                            }
                            ActivarDesactivarBoton(false);


                        });
                    break;
                case "Doctor":
                    await FetchGrahpQL({
                        query: NUEVO_DOCTOR,
                        variables: {
                            nombres: datos.nombres.valor.trim(),
                            apellidos: datos.apellidos.valor.trim(),
                            email: datos.email.valor,
                            password: datos.password.valor,
                            sexo: parseInt(datos.sexo.valor),
                            especialidad: parseInt(datos.especialidadId.valor),
                            cedula: datos.cedula.valor,
                            telefono: datos.telefono.valor,
                            fechaNacimiento: datos.fechaNacimiento.valor,
                            fechaEgreso: datos.fechaEgreso.valor,
                        }
                    })
                        .then(resp => {
                            Swal.fire("¡Éxito!", `Se ha enviado un correo, para confirmar cuenta`, "success");
                            ActivarDesactivarBoton(false);
                            actualiza();
                            ftClose();
                        })
                        .catch((err) => {
                            if (err.message === "Failed to fetch") {
                                Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                            } else {
                                Swal.fire("¡Aviso!", err.message, "warning");
                            }
                            ActivarDesactivarBoton(false);
                        });
                    break;
                case "Paciente":
                    await FetchGrahpQL({
                        query: NUEVO_PACIENTE,
                        variables: {
                            nombres: datos.nombres.valor.trim(),
                            apellidos: datos.apellidos.valor.trim(),
                            email: datos.email.valor,
                            password: datos.password.valor,
                            sexo: parseInt(datos.sexo.valor),
                            telefono: datos.telefono.valor,
                            fechaNacimiento: datos.fechaNacimiento.valor,
                            nombreDB: sesiones.sesiones.nombreDB
                        }
                    })
                        .then(resp => {
                            Swal.fire("¡Éxito!", `Se ha enviado un correo a ${resp.nuevoPaciente.email}, para confirmar cuenta`, "success");
                            ActivarDesactivarBoton(false);
                            actualiza();
                            ftClose();
                        })
                        .catch((err) => {
                            if (err.message === "Failed to fetch") {
                                Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                            } else {
                                Swal.fire("¡Aviso!", err.message, "warning");
                            }
                            ActivarDesactivarBoton(false);


                        });
                    break;
                case "Administrador":
                    await FetchGrahpQL({
                        query: NUEVO_ADMINISTRADOR,
                        variables: {
                            nombres: datos.nombres.valor.trim(),
                            apellidos: datos.apellidos.valor.trim(),
                            email: datos.email.valor,
                            password: datos.password.valor,
                            sexo: parseInt(datos.sexo.valor),
                            telefono: datos.telefono.valor,
                            fechaNacimiento: datos.fechaNacimiento.valor
                        }
                    })
                        .then(resp => {
                            Swal.fire("¡Éxito!", `Se ha enviado un correo a ${resp.nuevoAdministrador.email}, para confirmar cuenta`, "success");
                            ActivarDesactivarBoton(false);
                            actualiza();
                            ftClose();
                        })
                        .catch((err) => {
                            if (err.message === "Failed to fetch") {
                                Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                            } else {
                                Swal.fire("¡Aviso!", err.message, "warning");
                            }
                            ActivarDesactivarBoton(false);


                        });

                    break;
            }
        }

        if (categoria === 1) {

            if (datos.tipo.valor !== 3) {

                switch (tipo) {
                    case "Usuario":
                        FetchGrahpQL({
                            query: MODIFICAR_DATOS_USUARIOS_AUTORIZADOS,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor.trim(),
                                cedula: datos.cedula.valor.trim(),
                                fechaEgreso: datos.fechaEgreso.valor,
                                estatus: parseInt(datos.estatus.valor),
                                especialidad: parseInt(datos.especialidadId.valor),
                                sexo: parseInt(datos.sexo.valor),
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                solicitanteId: parseInt(datos.solicitanteId.valor),
                                password: datos.password.valor,
                                usuarioId: parseInt(datos.usuarioId.valor)
                            }
                        })
                            .then(() => {
                                Swal.fire("¡Éxito!", `Se ha enviado la solicitud al administrador para aplicar las modificaciones`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();

                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);


                            });

                        break;
                    case "Paciente":
                        FetchGrahpQL({
                            query: MODIFICAR_DATOS_USUARIOS_AUTORIZADOS,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor.trim(),
                                cedula: datos.cedula.valor.trim(),
                                fechaEgreso: datos.fechaEgreso.valor,
                                estatus: parseInt(datos.estatus.valor),
                                especialidad: parseInt(datos.especialidadId.valor),
                                sexo: parseInt(datos.sexo.valor),
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                solicitanteId: parseInt(datos.solicitanteId.valor),
                                password: datos.password.valor,
                                usuarioId: parseInt(datos.usuarioId.valor)
                            }
                        })
                            .then(() => {
                                Swal.fire("¡Éxito!", `Se ha enviado la solicitud al administrador para aplicar las modificaciones`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();

                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);


                            });

                        break;
                    case "Administrador":
                        FetchGrahpQL({
                            query: MODIFICAR_ADMINISTRADOR,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor,
                                sexo: parseInt(datos.sexo.valor),
                                fechaEgreso: datos.fechaEgreso.valor,
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor
                            }
                        })
                            .then(resp => {
                                Swal.fire("¡Éxito!", `Se modificó  correctamente los datos de   ${resp.modificarAdministrador.email}`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();
                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);

                            });
                        break;
                    case "Doctor":
                        FetchGrahpQL({
                            query: MODIFICAR_DATOS_USUARIOS_AUTORIZADOS,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor.trim(),
                                cedula: datos.cedula.valor.trim(),
                                fechaEgreso: datos.fechaEgreso.valor,
                                estatus: parseInt(datos.estatus.valor),
                                especialidad: parseInt(datos.especialidadId.valor),
                                sexo: parseInt(datos.sexo.valor),
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                solicitanteId: parseInt(datos.solicitanteId.valor),
                                password: datos.password.valor,
                                usuarioId: parseInt(datos.usuarioId.valor)
                            }
                        })
                            .then(() => {
                                Swal.fire("¡Éxito!", `Se ha enviado la solicitud al administrador para aplicar las modificaciones`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();

                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);


                            });

                        break;
                }

            } else {

                switch (tipo) {
                    case "Usuario":
                        FetchGrahpQL({
                            query: MODIFICAR_USUARIO,
                            variables: {
                                usuarioId: parseInt(datos.usuarioId.valor),
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor,
                                sexo: parseInt(datos.sexo.valor),
                                fechaEgreso: datos.fechaEgreso.valor,
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                password:datos.password.valor||null
                            }
                        })
                            .then(resp => {
                                Swal.fire("¡Éxito!", `Se modificó  correctamente los datos de  ${resp.modificarUsuario.email}`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();
                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);


                            });
                        break;


                    case "Doctor":
                        FetchGrahpQL({
                            query: MODFICAR_DOCTOR,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor,
                                sexo: parseInt(datos.sexo.valor),
                                fechaEgreso: datos.fechaEgreso.valor,
                                especialidadId: parseInt(datos.especialidadId.valor),
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                password:datos.password.valor,
                                cedula:datos.cedula.valor,
                            }
                        })
                            .then(resp => {
                                Swal.fire("¡Éxito!", `Se modificó  correctamente los datos de  ${resp.modificarDoctor.email}`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();
                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);

                            });
                        break;

                    case "Paciente":
                        FetchGrahpQL({
                            query: MODIFICAR_PACIENTE,
                            variables: {
                                usuarioId: parseInt(datos.usuarioId.valor),
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor,
                                sexo: parseInt(datos.sexo.valor),
                                fechaEgreso: datos.fechaEgreso.valor,
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor,
                                password: datos.password.valor
                            }
                        })
                            .then(resp => {
                                Swal.fire("¡Éxito!", `Se modificó  correctamente los datos de  ${resp.modificarPaciente.email}`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();

                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);
                            });
                        break;

                    case "Administrador":
                        FetchGrahpQL({
                            query: MODIFICAR_ADMINISTRADOR,
                            variables: {
                                usuarioId: datos.usuarioId.valor,
                                nombres: datos.nombres.valor.trim(),
                                apellidos: datos.apellidos.valor.trim(),
                                email: datos.email.valor,
                                sexo: parseInt(datos.sexo.valor),
                                fechaEgreso: datos.fechaEgreso.valor,
                                telefono: datos.telefono.valor,
                                fechaNacimiento: datos.fechaNacimiento.valor
                            }
                        })
                            .then(resp => {
                                Swal.fire("¡Éxito!", `Se modificó  correctamente los datos de   ${resp.modificarAdministrador.email}`, "success");
                                ActivarDesactivarBoton(false);
                                actualiza();
                                ftClose();
                            })
                            .catch((err) => {
                                if (err.message === "Failed to fetch") {
                                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                                } else {
                                    Swal.fire("¡Aviso!", err.message, "warning");
                                }
                                ActivarDesactivarBoton(false);

                            });
                        break;
                }
            }


        }
        dispatch(AgregarUsuario({ activo: true }))
    }
}


export const AgregarUsuario = usuariosCrud => {
    return {
        type: AGREGAR_USUARIO,
        payload: usuariosCrud
    }
}


export const ModificarUsuario = usuariosCrud => {
    return {
        type: AGREGAR_USUARIO,
        payload: usuariosCrud
    }
}



