import { Plugins, FilesystemDirectory } from '@capacitor/core';

export const urlServer = process.env.REACT_APP_URL_SERVER || "https://35.225.97.245:4700";

export const urlSat = process.env.REACT_APP_URL_SAT || "https://35.225.97.245:4000";

export const strUrlSCliente = process.env.REACT_APP_URL_CLIENT || "https://kalenday.softwone.com";

export const strUrlSArchivos = process.env.REACT_APP_URL_MEDIA || "https://35.225.97.245:4100";

export const plataforma = "WEB";



// Formatear Select
export const ftSelectFormat = (arreglo, { id, descripcion, otro }, texto) => {
	try {
        const ftDatos = (dato, id) => (typeof id === "string" ? dato[id] : id.valor(dato));
        const msjIni = texto ? [ { value: 0, label: texto } ] : null;
        const datosFormat = arreglo.map((dato) => {
            let resultado = { value: ftDatos(dato, id), label: ftDatos(dato, descripcion) };
            if (otro) {
                if(!Array.isArray(otro)) resultado[otro] = ftDatos(dato, otro);
                else otro.forEach(({id,alias,valor}) => { resultado[alias ? alias : id] = valor ? valor(dato[id]) : dato[id]; });
            } return resultado;
        });
        return msjIni ? msjIni.concat(datosFormat) : datosFormat;
    } catch({message:label}) { return [{ value: 0, label }]; }
};

export const showFile = (archivo, name = 'file.pdf',) => {

    if (plataforma == 'ANDROID'){

    const { Filesystem } = Plugins;
    Filesystem.writeFile({
        path: name,
        data: archivo,
        directory: FilesystemDirectory.Documents,
        // encoding: FilesystemEncoding.UTF8
    }).then(writeFileResponse => {
        console.log('writeFile success => ', writeFileResponse);
        this.apiServices.presentToast(this.message.successDownload, 'success');
    }, error => {
        console.log('writeFile error => ', error);
        this.apiServices.presentToast(this.message.errorDownload, 'danger');
    });

    /*return File.writeFile(
        File.externalRootDirectory + "Download",
        name,
        new Blob([blob]),{
            replace: true
        }
    )*/
    }
    if (plataforma === "WEB"){
        let blob = b64toBlob(archivo, 'application/pdf');
        let url = window.URL.createObjectURL(blob);
        console.log('url', url)
        let a = document.createElement('a');
        a.href = url;
        a.setAttribute("download", name);
        a.download = name;
        a.click();
        a.remove();

    }
};
function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
}

export const trim = dato => {
    if(typeof dato === "string") return dato.trim();
    return dato;
};

export const ftFormatDateSpanish = (fecha = new Date()) => {

    let dia = fecha.getDate();
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    let mes = fecha.getMonth();
    mes = mes === 0 ? "Ene" : (mes === 1 ? "Feb" : (mes === 2 ? "Mar" : (mes === 3 ? "Abr" : (mes === 4 ? "May"
        : ((mes === 5 ? "Jun" : (mes === 6 ? "Jul" : (mes === 7 ? "Ago" : (mes === 8 ? "Sep" : (mes === 9 ? "Oct"
        : (mes === 10 ? "Nov" : (mes === 11 ? "Dic" : ""))))))))))));
    let anio = fecha.getFullYear();
    let hora = fecha.getHours();
    let tmp = Number(hora) >= 12 && Number(hora) <= 24 ? "pm" : "am";
    hora = Number(hora) > 12 ? (Number(hora) - 12).toString() : hora;
    hora = hora.toString().length === 1 ? `0${hora}` : hora;
    let minuto = fecha.getMinutes();
    minuto = minuto.toString().length === 1 ? `0${minuto}` : minuto;

    return `${dia}/${mes}/${anio} - ${hora}:${minuto} ${tmp}`;

}

export const ftStringToDate = campo => {
    if(typeof campo === "string") return new Date(campo);
    return campo;
}

export const ftAnioNoValido = (fecha) => (isNaN(ftStringToDate(fecha).getFullYear()));

export const ftAniosRangosValidos = (fecha) => (1753 <= ftStringToDate(fecha).getFullYear() && ftStringToDate(fecha).getFullYear() <= new Date().getFullYear());

export const ftAniosRangosValidosHoy = (fecha) => (1753 <= ftStringToDate(fecha).getFullYear() && ftStringToDate(fecha).getFullYear() < new Date().getFullYear());


export const ftAniosRangosValidosAlterno = (fecha) => (1753 <= ftStringToDate(fecha).getFullYear() &&  ftStringToDate(fecha).getFullYear()<=2077);

export const timestamp = date => (Math.floor(ftStringToDate(date).getTime()/1000.0));

export function diasFormato(dia) {
    switch(dia) {
        case 1: return "Lunes"
        case 2: return "Martes"
        case 3: return "Miércoles"
        case 4: return "Jueves"
        case 5: return "Viernes"
        case 6: return "Sabádo"
        case 7: return "Domingo"
    }
}

export function horaFormato(fecha) {
    let time = fecha.split("T")[1];
    time = time.split(".")[0];
    let hora = time.split(":");
    let tmp = Number(hora[0]) >= 12 && Number(hora[0]) <= 24 ? "pm" : "am";
    let horaIni = Number(hora[0]) > 12 ? (Number(hora[0]) - 12).toString() : hora[0];
    horaIni = horaIni.toString().length === 1 ? `0${horaIni}` : horaIni;
    return `${horaIni}:${hora[1]} ${tmp}`;
}
