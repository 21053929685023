import React, { useContext, useEffect, useState } from 'react';
import {ftAnioNoValido,ftAniosRangosValidosHoy,ftAniosRangosValidos,ftAniosRangosValidosAlterno} from "../../../../Helpers";
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//Rgx
import { rgxEmail, rgxtel, rgxLetras } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
//redux
import { useDispatch, useSelector } from "react-redux";
import { DecodificarToken } from "../../../../componentesGenericos/Estructura/Autenticar";
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Administrador(props) {

    //Declaraciones
    let { seleccion } = props;
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxLetra = new RegExp(rgxLetras);
    let rgxTelefono = new RegExp(rgxtel);
    const [sexo, setSexo] = React.useState();
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //REDUX
     const dispatch = useDispatch();
     const user = useSelector((state) => state.user);
     const token = user.usuario[0].user.user.token;
     let userToken = DecodificarToken(token);

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0  || ftAniosRangosValidosHoy(value)==false || ftAnioNoValido(value)===true) {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value)  && value.length>1) {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Administrador: {
                ...datos.Administrador,
                [name]: {
                    ...datos.Administrador[name],
                    error: false, valor: value
                }
            }
        });
    }
    const handleChangeGuardarStateCorreo = event => {
        const { name, value } = event.target;
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }
    const handleChangeGuardarTelefono = event => {
        const { name, value } = event.target;
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Administrador: {
                    ...datos.Administrador,
                    [name]: {
                        ...datos.Administrador[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    //API
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //FUNCIONES
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {

                    row.push(<option key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</option>);
                    return true;



            });
            return row;
        }
    };

    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
        guardarDatos({
            ...datos,
            Administrador: {
                ...datos.Administrador,
                nombres: {
                    ...datos.Administrador.nombres,
                    error: false, valor: seleccion.nombres
                },
                apellidos: {
                    ...datos.Administrador.apellidos,
                    error: false, valor: seleccion.apellidos
                },
                email: {
                    ...datos.Administrador.email,
                    error: false, valor: seleccion.email
                },
                sexo: {
                    ...datos.Administrador.sexo,
                    error: false, valor: seleccion.sexo
                },
                usuarioId: {
                    ...datos.Administrador.usuarioId,
                    error: false, valor: seleccion.usuarioId
                },
                fechaEgreso: {
                    ...datos.Administrador.fechaEgreso,
                    error: false, valor: seleccion.fechaEgreso
                },
                telefono: {
                    ...datos.Administrador.telefono,
                    error: false, valor: seleccion.telefono
                },
                fechaNacimiento: {
                    ...datos.Administrador.fechaNacimiento,
                    error: false, valor: seleccion.fechaNacimiento
                }

            }
        });
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Administrador.nombres.error} defaultValue={seleccion.nombres}  />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Administrador.apellidos.error} defaultValue={seleccion.apellidos} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} onChange={handleChangeGuardarStateCorreo} error={datos.Administrador.email.error} defaultValue={seleccion.email} disabled />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Administrador.telefono.error} defaultValue={seleccion.telefono} />
                        </Grid>
                        <Grid item xs={12}>
                        <FormControl className={classes.formControl} style={{width:"100%"}}>
                                <InputLabel htmlFor="demo-dialog-native" required={true}>Género</InputLabel>
                                <Select
                                    name="sexo"
                                    native
                                    onChange={handleChangeGuardarStateSexo}
                                    input={<Input id="demo-dialog-native" />}
                                    value={datos.Administrador.sexo.valor}
                                >
                                    <option selected  value="null" disabled>Escoge el género </option>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                required={true}
                                    style={{ width: "100%" }}
                                    name="fechaNacimiento"
                                    id="date"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    helperText="La fecha de nacimiento es obligatoria"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    error={datos.Administrador.fechaNacimiento.error}
                                    defaultValue={seleccion.fechaNacimiento != null ? (seleccion.fechaNacimiento).split("T")[0] : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Administrador;


