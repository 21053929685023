import React, { useContext, useState, useEffect } from 'react';
import {ftAnioNoValido,ftAniosRangosValidosHoy,ftAniosRangosValidos,ftAniosRangosValidosAlterno} from "../../../../Helpers";
import clsx from 'clsx';
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, IconButton, InputAdornment, MenuItem } from '../../../../componentesGenericos/Core';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
import { VisibilityIcon, VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//Rgx
import { rgxEmail, rgxtel, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Usuarios() {

    //Declaraciones
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxTelefono = new RegExp(rgxtel);
    let rgxPasswords = new RegExp(rgxPassword);
    let rgxLetra = new RegExp(rgxLetras);
    const [sexo, setSexo] = React.useState(null);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0  || ftAniosRangosValidosHoy(value)==false || ftAnioNoValido(value)===true)  {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                [name]: {
                    ...datos.Usuario[name],
                    error: false, valor: value
                }
            }
        });
    }

    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value)  && value.length>1) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateCorreo = event => {
        const { name, value } = event.target;
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleChangeGuardarTelefono = event => {
        const { name, value } = event.target;
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                showPassword: {
                    ...datos.Usuario.showPassword,
                    error: !datos.Usuario.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //API
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //FUNCIONES
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<option key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</option>);
                return true;
            });
            return row;
        }
    };

    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Usuario.nombres.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Usuario.apellidos.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Usuario.email.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={datos.Usuario.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Usuario.password.error}
                                    type={datos.Usuario.showPassword.error ? 'text' : 'password'}
                                    value={datos.Usuario.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Usuario.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText autosize={true}>La contraseña debe contener una letra mayúscula,minúscula,número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Usuario.telefono.error} />
                        </Grid>
                        <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="demo-dialog-native" required={true}>Género</InputLabel>
                                <Select
                                    name="sexo"
                                    native
                                    value={sexo}
                                    onChange={handleChangeGuardarStateSexo}
                                    input={<Input id="demo-dialog-native" />}
                                    defaultValue="null"
                                >
                                    <option selected  value={'null'}  disabled>Escoge el género </option>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                required={true}
                                error={datos.Usuario.fechaNacimiento.error}
                                    style={{ width: "100%" }}
                                    id="date"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    helperText="La fecha de nacimiento es obligatoria"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaNacimiento"
                                />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Usuarios;


