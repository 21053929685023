import gql from 'graphql-tag';

const NUEVO_RECORDATORIO_CREDITO=`
mutation nuevoRecordatoriosCreditos(
    $plazoCredito: ID!
    $tituloRecordatorio: String!
    $descripcionRecordatorio: String!
    $dias: Int!
    $recordarAntesDespues: Int!
  ) {
    nuevoRecordatoriosCreditos(
      input: {
        plazoCreditoId: $plazoCredito
        tituloRecordatorio: $tituloRecordatorio
        descripcionRecordatorio: $descripcionRecordatorio
        dias: $dias
        recordarAntesDespues: $recordarAntesDespues
      }
    ) {
      recordatorioPagoId
      plazoCreditoId
      descripcionPlazo
      tituloRecordatorio
      descripcionRecordatorio
      dias
      estatus
      recordarAntesDespues
    }
  }

  `;



  const MODIFICIAR_RECORDATORIOS_CREDITOS=`
  mutation modificarRecordatoriosCreditos(
    $recordatorioPagoId:ID!
    $plazoCreditoId: ID!
    $tituloRecordatorio: String!
    $descripcionRecordatorio: String!
    $dias: Int!
    $recordarAntesDespues: Int!
  ) {
    modificarRecordatoriosCreditos(
      input: {
        recordatorioPagoId:$recordatorioPagoId
        plazoCreditoId: $plazoCreditoId
        tituloRecordatorio: $tituloRecordatorio
        descripcionRecordatorio: $descripcionRecordatorio
        dias: $dias
        recordarAntesDespues: $recordarAntesDespues
      }

    ) {
      recordatorioPagoId
      plazoCreditoId
      descripcionPlazo
      tituloRecordatorio
      descripcionRecordatorio
      dias
      estatus
      recordarAntesDespues
    }
  }

  `;



  const ACTUALIZAR_ESTATUS_RECORDATORIOS_CREDITOS= gql`
  mutation actualizarEstatusRecordatoriosCreditos(
    $recordatorioPagoId:ID!
    $estatus: Int!

  ) {
    actualizarEstatusRecordatoriosCreditos(
      input: {
        recordatorioPagoId:$recordatorioPagoId
        estatus: $estatus
      }

    )
  }
  `;

export {

    NUEVO_RECORDATORIO_CREDITO,
    MODIFICIAR_RECORDATORIOS_CREDITOS,
    ACTUALIZAR_ESTATUS_RECORDATORIOS_CREDITOS
}
