export const INICIAR_SESION='INICIAR_SESION';
export const AGREGAR_USUARIO='AGREGAR_USUARIO';
export const AGREGAR_ESPECIALIDAD='AGREGAR_ESPECIALIDAD';
export const ASIGNAR_CLINICA_DOCTORES='ASIGNAR_CLINICA_DOCTORES';
export const ASIGNAR_CLINICA_USUARIOS='ASIGNAR_CLINICA_USUARIOS';
// export const GUARDAR_USUARIO_TEMPORAL='GUARDAR_USUARIO_TEMPORAL';
export const AGREGAR_CONVENIO ='AGREGAR_CONVENIO';
export const SELECCIONAR_CLINICA_ACTUAL ='SELECCIONAR_CLINICA_ACTUAL';
export const SELECCIONAR_TURNO_ACTUAL = 'SELECCIONAR_TURNO_ACTUAL';
export const AGREGAR_SERIE='AGREGAR_SERIE';
export const FOTO_PERFIL='FOTO_PERFIL';
export const CONVENIOS_USUARIOS=`CONVENIOS_USUARIOS`;
export const CONFIGURACION=`CONFIGURACION`;
export const MENU=`MENU`;
export const SELECCIONAR_MENU=`SELECCIONAR_MENU`;
export const NOTIFICACION_SONIDO=`NOTIFICACION_SONIDO`;
export const AUTORIZAR_DATOS=`AUTORIZAR_DATOS`;
export const NOTIFICACIONES_CREDITO=`NOTIFICACIONES_CREDITO`;
export const ANAMNESIS=`ANAMNESIS`;
export const KALENDAY_SESIONES=`KALENDAY_SESIONES`;