import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_IMAGENESPROMOCION } from '../../query';
import { ELIMINAR_IMAGENPROMOCION, NUEVA_IMAGENPROMOCION, MODIFICAR_IMAGENPROMOCION, CAMBIAR_ESTATUSIMAGENPROMOCION } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/FormularioAntiguo';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
import { strUrlServer as urlServer } from '../../Helpers/constantes/Config/UrlServer';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever, CloseIcon } from '../../componentesGenericos/Icons';

//Helpers
import { ValidarNull } from '../../Helpers/Validacion/ValidarNuloUndefined';
import { getBase64Image } from '../../Helpers/Archivos';

//Expresiones regulares
import { rgxFechaValida, rgxNumero, rgxAlmenosUnaLetra } from '../../Helpers/constantes/Rgx';

//Formatos
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';
import { getImagen, postImagen, putImagen, deleteArchivo } from '../../Helpers/constantes/Imagenes/getImagen'
import { rutaPublica, rutaPrivada, rutaPrivadaEmpresa } from '../../Helpers/constantes/Config/urlRutas';

export default function Promociones() {

    //#region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_IMAGENESPROMOCION, {
        variables: { limite: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const [deletePromocion] = useMutation(ELIMINAR_IMAGENPROMOCION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la promoción "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addPromocion] = useMutation(NUEVA_IMAGENPROMOCION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente la promoción "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modPromocion] = useMutation(MODIFICAR_IMAGENPROMOCION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la promoción "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statPromocion] = useMutation(CAMBIAR_ESTATUSIMAGENPROMOCION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente a la promoción "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region states
    const dt = new Date();
    const seleccionInicial = {
        imgPromoId: null,
        descripcion: null,
        urlImagen: null,
        imagen: "",
        estatus: 0,
        fechaAlta: null,
        vigenciaIni: dt.toISOString().split("T")[0],
        vigenciaFin: dt.toISOString().split("T")[0],
        cambioImagen: null
    };
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [checked, setChecked] = useState(false);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [openImg, setOpenImg] = React.useState(false);
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [datosPromociones, SetDatosPromociones] = useState([]);
    //#endregion

    //#region constantes
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }]
        : [
            { id: 'imgPromoId', label: 'imgPromoId', minWidth: 0, hide: true,aliasColor:"estatus" },
            {
                id: 'descripcion', label: 'Descripción', minWidth: 100, align: 'left', textAlign: 'left', filtro: true
            },
            {
                id: 'nombreImagen', label: 'Imagen', minWidth: 170, align: 'center', hide: true
            },
            {
                id: 'urlImagen', label: 'Imagen', minWidth: 170, align: 'center', format: (urlImagen, imgPromoId) => {
                   return <img id={urlImagen} src={`${rutaPublica}${urlImagen}${'?'}${Math.random()}`} width={'20%'} onClick={() => seleccionaImagen(urlImagen) } />
                }
            },
            {
                id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center', component: "Checkbox"
            },
            {
                id: 'fechaAlta', label: 'Fecha de alta', minWidth: 170, align: 'center', filtro: true,
                format: (valor) => {
                    return valor ? valor.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
                }
            },
            {
                id: 'vigenciaIni', label: 'Inicio de vigencia', minWidth: 170, align: 'center',
                format: (valor) => {
                    return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
                }
            },
            {
                id: 'vigenciaFin', label: 'Fin de vigencia', minWidth: 170, align: 'center', format: (valor) => {
                    return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
                }
            },
        ];
    const menu = [
        { texto: "Agregar promoción", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar promoción", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Activar/Desactivar", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar promoción", icono: <DeleteForever />, onClick: () => handleEliminarClick() }
    ];
    const camposFormulario = [
        {
            id: "imgPromoId",
            texto: "imgPromoId",
            valor: seleccion.imgPromoId,
            tipo: "hidden",
        },
        {
            id: "descripcion",
            texto: "Descripción *",
            valor: seleccion.descripcion,
            tipo: "text",
            xs: 11,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "",
            texto: "",
            tipo: "hidden",
            xs: 1
        },
        {
            id: "estatus",
            texto: "Estatus *",
            valor: seleccion.estatus,
            tipo: "select",
            xs: 12,
            otros: {
                inputProps: { id: "estatusSelect" }
            },
            ayuda: "Campo obligatorio",
            regexp: rgxNumero,
            opciones: [
                {
                    label: "Inactivo",
                    value: 0
                }, {
                    label: "Activo",
                    value: 1
                }
            ],
        },
        {
            id: "vigenciaIni",
            texto: "Inicio de la vigencia *",
            valor: seleccion.vigenciaIni,
            tipo: "date",
            xs: 12,
            ayuda: 'Formato de fecha no valido',
            regexp: rgxFechaValida
        },
        {
            id: "",
            texto: "",
            tipo: "hidden",
            xs: 12
        },
        {
            id: "vigenciaFin",
            texto: "Fin de la vigencia *",
            valor: seleccion.vigenciaFin,
            tipo: "date",
            xs: 12,
            ayuda: 'Formato de fecha no valido',
            regexp: rgxFechaValida,
        },
        {
            id: "urlImagen",
            texto: "Imagen *",
            valor: seleccion.nombreImagen,
            tipo: "superFile",
            xs: 12,
            otros: {
                inputProps: { accept: "image/*" }
            },
            onChange: handleImagenChange
        },
    ];
    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoImagenesPromociones.ImagenesPromociones;
    //#endregion

    //#region handlers
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }
    function seleccionaImagen(ruta){
        let fila=-1;
        for(var i = 0; i < listado.length; i++ ){
            if (ruta == listado[i].urlImagen ){
                fila = i;
            }
        }
        handleTablaClick(listado[fila], fila);
        //setSeleccion({ ...seleccion, urlImagen: ruta });
        setOpenImg(true);
    }

    function handleModificarClick() {

        if (seleccion.imgPromoId !== null) {
            setSeleccion({
                ...seleccion,
                vigenciaIni: seleccion.vigenciaIni.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, ""),
                vigenciaFin: seleccion.vigenciaFin.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "")
            });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });

        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una promoción" });
        }
    }

    function handleCambiarEstatusClick() {
        if (seleccion.imgPromoId !== null) {
            setChecked(seleccion.estatus === 0);
            let opcion = seleccion.estatus === 0 ? "activar" : "desactivar";

            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la promoción "${seleccion.descripcion}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una promoción" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.imgPromoId !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la promoción "${seleccion.descripcion}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una promoción" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setFiltro(texto)
    }

    function handleImagenChange({ target }) {
        setSeleccion({ ...seleccion, imagen: target.files[0], nombreImagen: target.files[0].name });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }
    //#endregion

    //#region funciones
    async function ftAgregar({ descripcion, urlImagen, estatus, vigenciaIni, vigenciaFin, nombreImagen }) {
        setDisabledAccept(true);

        let valores = { descripcion, estatus, urlImagen, vigenciaIni, vigenciaFin, nombreImagen };

        valores = ValidarNull(valores, { descripcion: window.descripcion.value, estatus: window.estatusSelect.value, urlImagen: window.urlImagen.files[0], vigenciaIni: window.vigenciaIni.value, vigenciaFin: window.vigenciaFin.value, nombreImagen: urlImagen ? window.urlImagen.files[0].name : seleccion.nombreImagen });

        let validacion = ftValidarObligatorios(valores);

        if (validacion === "") {
            validacion = ftValidarFormato(valores);
            if (validacion === "") {
                let empresa = process.env.REACT_APP_NOMBRE_CLINICA;

               let result = await postImagen(empresa+'/imagenes/promociones/', valores.urlImagen, 0);

               if (result !== '')
               {
                    valores.urlImagen = result; //res.ruta;
                    valores.estatus = Number(valores.estatus);
                    setSeleccion({ ...seleccion, ...valores });

                    addPromocion({ variables: { imagenpromo: valores } });
               }else {
                   setDisabledAccept(false);
                   setMensaje({ ...mensaje, ...mensajeError, texto: 'Ocurrio un error al carga la imagen' })
               }

            }
        }

        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion })
        }
    }

    async function ftModificar({ descripcion, urlImagen, estatus, vigenciaIni, vigenciaFin, nombreImagen }) {
        setDisabledAccept(true);

        let valores = { descripcion, estatus, urlImagen, vigenciaIni, vigenciaFin, nombreImagen };

        valores = ValidarNull(valores, { descripcion: window.descripcion.value, estatus: window.estatusSelect.value, urlImagen: window.urlImagen.files[0], vigenciaIni: window.vigenciaIni.value, vigenciaFin: window.vigenciaFin.value, nombreImagen: urlImagen ? window.urlImagen.files[0].name : seleccion.nombreImagen });

        if (!valores.urlImagen) {

            valores.urlImagen = await ftRecuperarImagen(seleccion.urlImagen);
        }

        if (valores.urlImagen instanceof File) {
            let validacion = ftValidarObligatorios(valores);

            if (validacion === "") {
                validacion = ftValidarFormato(valores);
                if (validacion === "") {

                   let ruta = 'public/kalenday'+seleccion.urlImagen;

                    let result = await putImagen(ruta, valores.urlImagen);

                    if (result == true){

                        valores.urlImagen = seleccion.urlImagen;
                        valores.estatus = Number(valores.estatus);
                        setSeleccion({ ...seleccion, ...valores });
                        setActualizar(true);

                            modPromocion({
                                variables: {
                                    imagenpromo: {
                                        imgPromoId: seleccion.imgPromoId,
                                        ...valores
                                    }
                                }
                            });
                           // let img = document.getElementById(seleccion.urlImagen);
                           // img.src = rutaPublica+seleccion.urlImagen+Math.random();

                    }else {
                        setDisabledAccept(false);
                        setMensaje({ ...mensaje, ...mensajeError, texto: 'Ocurrio un error al carga la imagen' })
                    }

                }
            }

            if (validacion !== "") {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto: validacion })
            }
        } else {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: "Ocurrió un error al subir la imagen" })
        }
    }

    function ftCambiarEstatus() {
        setDisabledAccept(true);
        statPromocion({
            variables: {
                id: Number(seleccion.imgPromoId),
                estatus: seleccion.estatus === 0 ? 1 : 0
            }
        })
    }

    function ftEliminar() {
        setDisabledAccept(true);
        let ruta = `${'public/kalenday'}${seleccion.urlImagen}`;
        archivoImg(ruta).then(result => {
            if (result == true){
              deletePromocion({ variables: { id: Number(seleccion.imgPromoId) } });
            }
        });
    }
    const archivoImg = async function(ruta) { //get storage
        let resultado = '';
           resultado = await deleteArchivo(ruta);
        return resultado;
    }

    async function ftRecuperarImagen(urlImagen) {
        return await fetch(rutaPublica + urlImagen)//urlServer + "/promociones/img/" + urlImagen
            .then(response => {
                return response.blob()
            }).then(blob => {
                return new File([blob], "img", { type: blob.type });
            }).catch(e => {
                console.log(e.message);
                return null;
            });
    }

    function ftValidarObligatorios(campos) {
        if (campos.descripcion.trim() === "" || campos.descripcion === undefined) return "Debe especificar una descripción";
        if (campos.urlImagen === "" || campos.urlImagen === undefined) return "Debe seleccionar una imagen";

        return Object.values(campos).every((valor) => (valor !== null && valor !== undefined && valor !== "")) ? "" : "Faltan valores obligatorios";
    }

    function ftValidarFormato(campos) {

        if (campos.descripcion.trim().length < 4) return "La descripción no cumple con el formato solicitado, incluya almenos 1 letra y por lo menos 4 caracteres";

        if (!campos.urlImagen.type.startsWith("image/")) {
            return "Formato de imagen no valido";
        }

        let inicial = new Date(campos.vigenciaIni);
        let final = new Date(campos.vigenciaFin);

        if (inicial > final) {
            return "La fecha de inicio no puede ser mayor a la fecha de fin de vigencia";
        }
        return "";
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFila(-1);
        setSeleccion({ ...seleccionInicial })
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseImg() {
        setOpenImg(false);
    }
    //#endregion


    /*if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log('error en actualizar',e.message) });
    setActualizar(false); }*/


      if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log('error en actualizar',e.message) });
        setActualizar(false); }



    return (
        <MenuLateral titulo="Promociones" menu={menu}>

            <Dialog open={openImg}>
                <DialogTitle disableTypography style={{ textAlign: "Right" }}>
                    <IconButton aria-label="close" onClick={ftCloseImg} style={{ padding: 0 }}><CloseIcon /></IconButton>
                    <img src={`${rutaPublica}${seleccion.urlImagen}`} width={'100%'} />
                </DialogTitle>
            </Dialog>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Promociones" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} />

            <Tabla titulo="Listado de promociones" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcion", "nombreImagen"]} />

        </MenuLateral>
    );
}
