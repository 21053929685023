// Fetch
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
// Planes de Crédito
import { CLIENTES_ACTIVOS_FETCH }  from '../../../query';
import { LISTADO_PLANES_FETCH, LISTADO_PLANES_CREDITO_FETCH, CREDITO_APLICABLE_CLIENTE_FETCH }  from '../../../query/componentes/PlanesCredito';
import { AGREGAR_PLANES_CREDITO_FETCH, ACTUALIZAR_PLANES_CREDITO_FETCH, ELIMINAR_PLANES_CREDITO_FETCH, ASIGNAR_CLIENTES_PLAN_CREDITO_FETCH }  from '../../../mutation/componentes/PlanesCredito';
// Helpers
import { ftSelectFormat } from '../../../Helpers';

//#region Querys
export const selectPlanes = (texto) => {
    async function selectPlanes() {
        try {
            let resultado = await FetchGrahpQL({ query: LISTADO_PLANES_FETCH });
            let { listadoPlanes } = resultado;
            return ftSelectFormat(listadoPlanes, {
                id: "planID",
                descripcion: { valor: data => `${data["descripcionPlan"]} (${data["diasPlan"]} días)` },
                otro: "subDescripcion"
            }, typeof texto === "string" && texto);
        } catch({message:error}) { return { error } }
    } return selectPlanes();
};
export const selectClientesActivos = (texto) => {
    async function selectClientesActivos() {
        try {
            let resultado = await FetchGrahpQL({ query: CLIENTES_ACTIVOS_FETCH });
            let { ClientesActivos } = resultado;
            return ftSelectFormat(ClientesActivos, {
                id: "usuarioId",
                descripcion: { valor: data => `${data["nombreCompleto"]} (${data["email"]})` }
            }, typeof texto === "string" && texto);
        } catch({message:error}) { return { error } }
    } return selectClientesActivos();
};
export const queryListado = () => {
    async function queryListado() {
        try {
            let resultado = await FetchGrahpQL({ query: LISTADO_PLANES_CREDITO_FETCH });
            let { listadoPlanesCredito } = resultado;
            return listadoPlanesCredito;
        } catch({message:error}) { return { error } }
    } return queryListado();
};
// Enviar un objeto con las propiedades { clienteID, monto }, texto = para el select
// Ejemplo: await selectCreditoAplicableCliente({ clienteID: 8, monto: 5000 });
export const selectCreditoAplicableCliente = (variables, texto) => {
    async function selectCreditoAplicableCliente() {
        try {
            let resultado = await FetchGrahpQL({
                query: CREDITO_APLICABLE_CLIENTE_FETCH,
                variables
            });
            let { creditoAplicablePorCliente } = resultado;
            return ftSelectFormat(creditoAplicablePorCliente, {
                id: "planCreditoID",
                descripcion: { valor: data => `${data["descripcionCredito"].split(" (")[0]}` }
            }, typeof texto === "string" && texto);
        } catch({message:error}) { return [{ value: null, label: "No aplica para un crédito" }] }
    } return selectCreditoAplicableCliente();
};
//#endregion

//#region Mutation
export const mutationAddPlanCredito = (input) => {
    async function mutationAddPlanCredito() {
        try {
            let resultado = await FetchGrahpQL({
                query: AGREGAR_PLANES_CREDITO_FETCH,
                variables: { input }
            });
            let { nuevoPlanCredito } = resultado;
            return nuevoPlanCredito;
        } catch({message:error}) { return { error } }
    } return mutationAddPlanCredito();
};
export const mutationUpdatePlanCredito = (input) => {
    async function mutationUpdatePlanCredito() {
        try {
            let resultado = await FetchGrahpQL({
                query: ACTUALIZAR_PLANES_CREDITO_FETCH,
                variables: { input }
            });
            let { modificarPlanCredito } = resultado;
            return modificarPlanCredito;
        } catch({message:error}) { return { error } }
    } return mutationUpdatePlanCredito();
};
export const mutationDeletePlanCredito = (id) => {
    async function mutationDeletePlanCredito() {
        try {
            let resultado = await FetchGrahpQL({
                query: ELIMINAR_PLANES_CREDITO_FETCH,
                variables: { id }
            });
            let { eliminarPlanCredito } = resultado;
            return eliminarPlanCredito;
        } catch({message:error}) { return { error } }
    } return mutationDeletePlanCredito();
};
export const mutationAsignarClientesPlanCredito = (input) => {
    async function mutationAsignarClientesPlanCredito() {
        try {
            let resultado = await FetchGrahpQL({
                query: ASIGNAR_CLIENTES_PLAN_CREDITO_FETCH,
                variables: { input }
            });
            let { asignarClientesCredito } = resultado;
            return asignarClientesCredito;
        } catch({message:error}) { return { error } }
    } return mutationAsignarClientesPlanCredito();
};
//#endregion
