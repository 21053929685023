import React, { useContext, useState, useEffect } from 'react';
//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_CLINICAS, LISTADO_HISTORIAL_PAGO, HISTORIAL_PAGO_FETCH, LISTADO_PACIENTE_FETCH, LISTADO_ADMIN_USUARIO_FETCH, LISTADO_PRODUCTOS_SERVICIOS_FETCH, LISTADO_CLINICAS_CADENA, SELECT_CLINICAS, SELECT_CLINICAS_DOCTOR_FETCH, SELECT_CLINICAS_FETCH, SELECT_CLINICA_USUARIO_FETCH, FOLIOS_FETCH, LISTAR_FORMA_COBRO_FETCH, OBTENER_TICKETS_IDS, OBTENER_TICKETS_IDS_FETCH } from '../../query';
//Mutations
import { NUEVO_ABONO, ABONOS_PARTIDAS, ABONO_PARCIAL, ABONO_CAPITAL, ABONO_TOTAL } from '../../mutation';
//FETCH
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Redux
import { useSelector } from 'react-redux';
//Estilos
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '../../Helpers/constantes/Css/EstilosUi';
//Componentes
import { Grid, Typography, Card, CardContent, FormControl, InputLabel, Button, TextField, Paper } from '../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PaymentIcon from '@material-ui/icons/Payment';
import Tabla from '../../componentesGenericos/ContenedorTabla';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//Componentes
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';
import FormularioParcialCapital from '../../componentesGenericos/FormularioAntiguo';
// import { handleClick } from '../MenuPrincipal/helper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
//Helpers
import { rgxAlmenosUnaLetra, rgxDecimales, rgxDecimalesPV } from '../../Helpers/constantes/Rgx';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
//Formatos
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';
import EscanerQr from '../../componentesGenericos/EscanerQr';
import { timestamp, ftAnioNoValido, ftAniosRangosValidos } from '../../Helpers';

import { CropFree, Print, AccountBalance, AccountBalanceWallet } from '@material-ui/icons';

//Helpers
import { getUsuario } from '../../Helpers/Usuario';
import { TipoUsuario } from '../../Helpers/Enums';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        fontSize: '170%'
    },
}));

export default function PagosClientes() {

    const classes = useStyles();
    const abortController = new AbortController();
    const signal = abortController.signal

    const dt = new Date();
    let anio = dt.getFullYear();
    let mes = dt.getMonth();
    mes = mes < 10 ? `0${mes}` : mes;
    let dia = dt.getDate();
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    const mesAnterior = `${anio}-${mes}-${dia}`;
    dt.setHours(23);


    //#region Redux
    const clinicaId = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let userToken = DecodificarToken(userState);

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(userToken.tipo),
        bTipoUsuario = TipoUsuario.USUARIO.isEqual(userToken.tipo),
        bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(userToken.tipo),
        bTipoAdmin = TipoUsuario.ADMINISTRADOR.isEqual(userToken.tipo);
    //#endregion

    //#region States
    const [state, setState] = useState(0);
    const [opcionesClinicas, setOpcionesClinicas] = useState([]);
    const seleccionInicial = {
        usuarioId: null,
        nombreCompleto: null,
        vendedorId: null,
        nombreVendedor: null,
        productoId: null,
        nombreProducto: null,
        boton: false,
        clinicaId: null,
        nombre: null,
        fechaInicio: mesAnterior,
        fechaFin: dt.toISOString().split("T")[0],
        estatusPago: 0
    }
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [formularioPC, setFormularioPC] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioPCClose,
        onAccept: null,
        onCancel: null
    });
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [abonar, setAbonar] = useState([]);
    const [expanded, setExpanded] = useState('');
    const [datos, setDatos] = useState([]);
    const [clientes, setClientes] = useState([{ nombreCompleto: 'No se encontraron clientes', id: null }]);
    const [vendedor, setVendedor] = useState([{ nombreCompleto: 'Sin resultados', id: null }]);
    const [producto, setProducto] = useState([{descripcion: 'Sin resultados', id: null }]);
    const [clinica, setClinicas] = useState([{ nombreCompleto: 'Sin resultados', id: null }]);
    const [clinicaSelect, setClinicaSelect] = useState([]);
    const [formasCobroSelect, setFormasCobroSelect] = useState([])
    const itemsAbono = {
        historialPagoPartidaId: null,
        fechaPago: null,
        vendedorId: null,
        estatusPartida: null
    };
    const [datosAbono, setDatosAbono] = useState(itemsAbono);
    const componente = {
        sucursal: 0,
        cliente: 0, // v
        vendedor: 0, // v
        producto: 0 // v
    }
    const [errores, setErrores] = useState(componente);
    const [cantPago, setCantPago] = useState(0);
    const [montoAbonar, setMontoAbonar] = useState(0);
    const [operacion, setOperacion] = useState('AbonoCapital');
    const [opcion, setOpcion] = useState('');
    const datosInsert = {
        clinicaId: null,
        usuarioId: userToken.usuarioId,
        serie: null,
        folio: null,
        totalApagar: 0,
        TotalPagos: 0,
    }
    const [insert, setInsert] = useState(datosInsert);
    const [formasPago, setFormasPago] = useState({})
    const [QR, setQR] = useState({
        ok: false,
        abrir: false,
        tipo: "mensaje",
        texto: "",
        codigo: null,
        onAccept: (codigo) => {
            setQR({ ...QR, abrir: false, codigo });
        },
        onCancel: () => {
            setQR({ ...QR, abrir: false });
        }
    });
    const [stImpresion, setStImpresion] = useState({ ticketEncabezadoId: null, fecha: null });
    const [sindatos, SetSinDatos] = useState(0);

    //#endregion

    //#region Fetchs
    const FetchListadoPagosClientes = async () => {

        FetchGrahpQL({
            query: HISTORIAL_PAGO_FETCH,
            variables: {
                filtro: {
                    clinicaId: seleccion.clinicaId,
                    clienteId: seleccion.usuarioId,
                    estatusPago: seleccion.estatusPago === -1 ? null : seleccion.estatusPago,
                    estatusPartida: null,
                    vendedorId: seleccion.vendedorId,
                    fechaAlta: seleccion.fechaInicio,
                    fechaPagoFinal: seleccion.fechaFin,
                    fechaInicioPartida: null,
                    fechaFinalPartida: null,
                    productoId: seleccion.productoId,
                    ticketEncabezadoId: null,
                    PagosAdeudo: null
                }
            },
            signal: signal
        })
            .then((data) => {
                setDatos(data)
                SetSinDatos(0)
            })
            .catch((e) => {
                SetSinDatos(1);
            })
    }

    const FetchClientes = async () => {
        FetchGrahpQL({
            query: LISTADO_PACIENTE_FETCH,
            variables: { offset: 0, moroso: null },
            signal: signal
        })
            .then((data) => {
                if (data) {
                    setClientes(data.listadoPaciente.pacientes)
                    setErrores({ ...errores, cliente: 0 });
                }
            })
            .catch((e) => {
                console.log(e.message)
                setErrores({ ...errores, cliente: 1 });
            })
    }

    const FetchSerieFolio = async () => {
        FetchGrahpQL({
            query: FOLIOS_FETCH,
            variables: { clinicaId: Number(clinicaId) }
        })
            .then((data) => {
                if (data) {
                    let Folio = data.listadoFolios.folios[0].consecutivo;
                    let Serie = data.listadoFolios.folios[0].nombreSerie;
                    setInsert(insert => ({ ...insert, folio: Folio, serie: Serie }));
                }
            })
            .catch(() => {
                console.log("Sin serie")
            })
    }

    const FetchFormaCobro = async () => {
        FetchGrahpQL({
            query: LISTAR_FORMA_COBRO_FETCH,
            variables: { clinicaId: Number(clinicaId) }
        })
            .then((data) => {
                if (data) {
                    setFormasCobroSelect({ ...insert, formaCobro: JSON.parse(data.listadoFormaCobro.formaCobros[0].formaCobro) })
                }
            })
            .catch((e) =>
                console.log(e.message))
    }

    const FetchClinicasUsuarios = async () => {

        FetchGrahpQL({
            query: bTipoDoctor ? SELECT_CLINICAS_DOCTOR_FETCH : (bTipoUsuario ? SELECT_CLINICA_USUARIO_FETCH : SELECT_CLINICAS_FETCH),
            variables: {
                limite: 100,
                activas: (bTipoAdmin ? null : true),
                usuarioID: (bTipoUsuario ? Number(userToken.usuarioId) : null),
                doctorID: (bTipoDoctor ? Number(userToken.usuarioId) : null),
                clinicaID: null
            }
        })
            .then((data) => {
                if (data) {
                    if (bTipoAdmin) {
                        let Datos = data.listadoClinicas.clinicas;
                        setClinicaSelect(Datos);

                    }
                    else if (bTipoUsuario) {
                        let Datos = data.obtenerClinicasUsuario;
                        setClinicaSelect(Datos);
                    } else {
                        throw new Error('No cuenta con permisos para realizar abonos');
                    }
                }
                else {
                    throw new Error('No cuenta con sucursales asignadas');

                }
            })
            .catch(({ message }) => {
                setMensaje({ ...mensaje, ...mensajeError, texto: message.replace("GraphQL error:", "") })

            })
    };

    const FetchVendedores = async () => {
        FetchGrahpQL({
            query: LISTADO_ADMIN_USUARIO_FETCH,
            variables: { offset: 0, moroso: null },
            signal: signal
        })
            .then((data) => {
                if (data) {
                    setVendedor(data.listadoAdministradorUsuario.usuarios)
                    setErrores({ ...errores, vendedor: 0 });
                }
            })
            .catch((e) => {
                setErrores({ ...errores, vendedor: 1 });
                console.log(e.message)
            })
    }

    const FetchProductosServicios = async () => {
        FetchGrahpQL({
            query: LISTADO_PRODUCTOS_SERVICIOS_FETCH,
            variables: { offset: 0, tipo: 4, clinica: seleccion.clinicaId, disponible: null }
        })
            .then((data) => {
                if (data) {
                    setProducto(data.listadoProductosServicios.productosServicios)
                    setErrores({ ...errores, producto: 0 });
                }
            })
            .catch((e) => {
                console.log(e.message)
                setErrores({ ...errores, producto: 1 });
            })
    }

    const FetchClinicas = async () => {
        FetchGrahpQL({
            query: LISTADO_CLINICAS_CADENA,
            variables: { limite: 1000, pagina: 1 }
        })
            .then((data) => {
                setClinicas(data.listadoClinicas.clinicas);
                setErrores({ ...errores, sucursal: 0 });
            })
            .catch((e) => {
                console.log(e.message)
                setErrores({ ...errores, sucursal: 1 });
                setSeleccion({ ...seleccion, clinicaId: null, nombre: "" })

            });
    }
    //#endregion

    //#region querys

    const FetchTickets = async (id, fecha) => {
        FetchGrahpQL({
            query: OBTENER_TICKETS_IDS_FETCH,
            variables: { ticketEncabezadoId: id, Fecha: new Date(fecha).toISOString() },
        })
        .then((data) => {
            let { ticketId, ticketEncabezadoId, clienteId } = data?.obtenerTicketsId?.ticketsObtenerId[0];

            setStImpresion({ ...stImpresion, ticketEncabezadoId: id, fecha });

            let imp = window.open(`/ticketCredito/${clienteId}/${ticketEncabezadoId}/${ticketId}`, '_blank');
        })
        .catch((e) => {
            console.log("error al obtener datos del ticket: ", e.message)
        })
    }
    //#endregion

    //#region mutations
    const [AbonoTotal] = useMutation(ABONO_TOTAL, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") })
            setDisabledAccept(false);
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);
            setDatosAbono(itemsAbono)
            setInsert(insert => ({ ...insert, datosInsert }))
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se realizó correctamente el abono` })
        }
    })

    const [abonarParcial] = useMutation(ABONO_PARCIAL, {
        onError: (e) => {
            console.log(e.message.replace("GraphQL error:", ""));
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") })
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);
            setDatosAbono(itemsAbono)
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se realizó correctamente el abono` })
        }
    })

    const [abonarCapital] = useMutation(ABONO_CAPITAL, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") })
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);
            setDatosAbono(itemsAbono)
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se realizó correctamente el abono a capital` })
        }
    })
    //#endregion

    //#region Constantes
    const menu = [
        { texto: "Escanear QR", icono: <CropFree />, onClick: () => { setQR({ ...QR, abrir: true }) } }
    ];

    const clientesProps = {
        options: clientes,
        getOptionLabel: option => option.nombreCompleto && option.email ? `${option.nombreCompleto} [${option.email}]` : ''
    }

    const vendedorProps = {
        options: vendedor,
        getOptionLabel: option => option.nombreCompleto && option.email ? `${option.nombreCompleto} [${option.email}]` : ''
    }

    const optionClinica = [{ servicioId: "-1", nombre: "Seleccione una sucursal" }]
    const optionProducto = [{ servicioId: "-1", descripcion: "No se encontraron registros" }]

    const productoProps = {
        options: seleccion.clinicaId === null ? optionProducto : producto,
        getOptionLabel: option => option.descripcion
    }

    const clinicaProps = {
        options: clinica,
        getOptionLabel: option => option.nombre
    }

    let opcionesClinica = clinicaSelect.map(valor => ({
        label: valor.nombre,
        value: Number(valor.clinicaId)
    }));


    const camposFormulario = [
        {
            id: "TotalPagos",
            texto: "Cantidad a saldar",
            valor: `$${insert.TotalPagos.toFixed(2)}`,
            tipo: "text",
            xs: 4,
            ayuda: 'Campo de saldo a liquidar',
            regexp: rgxDecimales,
            disabled: true
        },
        {
            id: "abonar",
            texto: "Cant. a abonar",
            valor: `$${montoAbonar.toFixed(2)}`,
            tipo: "text",
            xs: 4,
            ayuda: 'Campo para ingresar cant. a pagar',
            regexp: rgxDecimales,
            disabled: true
        },
        {
            id: "TotalPagos2",
            texto: "Cambio",
            valor: `$${montoAbonar - insert.TotalPagos >= 0 ? (montoAbonar - insert.TotalPagos.toFixed(2)) : 0}`,
            tipo: "text",
            xs: 4,
            ayuda: 'Campo de saldo a liquidar',
            regexp: rgxDecimales,
            disabled: true
        }

    ];

    let camposFormularioPC = [
        {
            id: "total",
            texto: "Total a pagar",
            valor: `$${(datosAbono.totalMonto || 0).toFixed(2)}`,
            tipo: "text",
            xs: 4,
            disabled: true
        },
        {
            id: "abonar",
            texto: "Cant. a abonar",
            valor: montoAbonar,
            tipo: "text",
            xs: 4,
            disabled: true
        },
        {
            id: "cambio",
            texto: "Cambio",
            valor: `$${(montoAbonar - (datosAbono.totalMonto || 0) > 0 ? montoAbonar - (datosAbono.totalMonto || 0) : 0).toFixed(2)}`,
            tipo: "text",
            xs: 4,
            disabled: true
        }
    ]

    window.ma = montoAbonar;
    window.da = datosAbono;
    // useEffect(()=>{
    if (formasCobroSelect !== [] && formasCobroSelect.formaCobro !== undefined && camposFormulario.length < 4) {
        formasCobroSelect.formaCobro.map(valor => {

            camposFormulario.push(
                {
                    id: valor.clave,
                    texto: valor.descripcion,
                    valor: formasPago[valor.clave],
                    tipo: "checkbox",
                    xs: 6,
                    onChange: (e) => {
                        if (e.target) {
                            handleChangeFormaPago(e, e.target.id);
                        }
                    }
                },
                {
                    id: "_" + valor.clave,
                    texto: "Cantidad *",
                    valor: formasPago["_" + valor.clave],
                    xs: 6,
                    regexp: rgxDecimales,
                    ayuda: "Ingresa un monto valido",
                    disabled: formasPago[valor.clave] !== null && formasPago[valor.clave] !== undefined ? false : true,
                    onChange: (e) => {
                        if (e.target) {
                            handleChangeFormaMonto(e, e.target.id);
                        }
                    },
                    propiedades: {
                        onClick: (e) => {
                            if (e.target) {
                                e.target.select();
                            }
                        },
                        onBlur: (e) => {
                            ftValidarPago("_" + valor.clave, e.target.value);
                        }
                    }
                }
            );


            camposFormularioPC.push(
                {
                    id: valor.clave,
                    texto: valor.descripcion,
                    valor: formasPago[valor.clave],
                    tipo: "checkbox",
                    onChange: (e) => {
                        if (e.target) {
                            handleChangeFormaPago(e, e.target.id);
                        }
                    },
                    xs: 6
                },
                {
                    id: "_" + valor.clave,
                    texto: "Cantidad *",
                    valor: formasPago["_" + valor.clave],
                    xs: 6,
                    // regexp: rgxDecimales,
                    // ayuda: "Ingresa un monto valido",
                    tipo: "number",
                    disabled: formasPago[valor.clave] !== null && formasPago[valor.clave] !== undefined ? false : true,
                    onChange: (e) => {
                        if (e.target) {
                            handleChangeFormaMonto(e, e.target.id);
                        }
                    },
                    propiedades: {
                        onClick: (e) => {
                            if (e.target) {
                                e.target.select();
                            }
                        },
                        onBlur: (e) => {
                            ftValidarPago("_" + valor.clave, e.target.value);
                        }
                    }
                }
            )
        });

    } else {
        camposFormulario.push({
            id: "no",
            texto: "No se pudo obtener el listado de formas de cobro",
            valor: "No se pudo obtener el listado de formas de cobro",
            tipo: "label",
            xs: 12,
            propiedades: {
                style: { margin: 20, fontSize: 18, color: 'rgb(107, 36, 21)' }
            },
            estilosGrid: {
                marginTop: 20,
                backgroundColor: 'rgb(253, 236, 234)',
                borderRadius: 4
            }
        })
        camposFormularioPC.push({
            id: "no",
            texto: "No se pudo obtener el listado de formas de cobro",
            valor: "No se pudo obtener el listado de formas de cobro",
            tipo: "label",
            xs: 12,
            propiedades: {
                style: { margin: 20, fontSize: 18, color: 'rgb(107, 36, 21)' }
            },
            estilosGrid: {
                marginTop: 20,
                backgroundColor: 'rgb(253, 236, 234)',
                borderRadius: 4
            }
        })
    }
    // },[formasCobroSelect,formularioPC])

    function checkStatus(datos) {
        return datos.estatusPartida !== true;
    }
    //#endregion

    //#region Handlers
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleFiltroClick = async () => {
        setSeleccion({ ...seleccion, boton: true })
        await FetchListadoPagosClientes();
    }

    // const [val, setVal] = useState([])

    const handleClick = (abono) => (event, nuevoValor) => {


        event.preventDefault();
        setDatosAbono(datosAbono => ({
            ...datosAbono,
            ...abono,
            TotalPagos: abono.partida[0].totalMenosCapital
        }))
        setInsert(insert => ({
            ...insert,
            ...abono,
            TotalPagos: abono.partida[0].totalMenosCapital
        }));

        setOperacion('AbonoCapital');
        setFormulario({ ...formulario, abrir: true, onAccept: (valores) => ftAbonoTotal(abono, valores), onCancel: ftMensajeClose });
    }

    function obtenerPago(datos) {
        //Cambiar por una sumatoria
        // let PAGO = Number(Object.values(datos[0])[0].totalAPagar) * datos[0].length;
        // setInsert({ ...insert, TotalPagos: PAGO });
        const sum = datos[0]
            .map(item => item.totalAPagar)
            .reduce((prev, curr) => prev + curr, 0);
    }

    function checkStatusfilter(datos) {
        let filtro = datos.fechaPagoFinalizado === null;
        return filtro;
    }

    function hancleClienteChange(e, val) {
        if (val) {
            setSeleccion({
                ...seleccion,
                usuarioId: Number(val.usuarioId),
                nombreCompleto: val.nombreCompleto
            })
        }
        else {
            setSeleccion({
                ...seleccion,
                usuarioId: null,
                nombreCompleto: ""
            })
        }
    }


    function handleLimpiarFiltro() {
        setSeleccion(seleccionInicial)
    }

    function handleVendedorChange(e, val) {
        if (val) {
            setSeleccion({
                ...seleccion,
                vendedorId: Number(val.usuarioId),
                nombreVendedor: val.nombreCompleto
            })
        } else {
            setSeleccion({
                ...seleccion,
                vendedorId: null,
                nombreVendedor: ""
            })
        }
    }

    function handleProductoChange(e, val) {
        if (val) {
            setSeleccion({
                ...seleccion,
                productoId: Number(val.servicioId),
                nombreProducto: val.descripcion
            })
        } else {
            setSeleccion({
                ...seleccion,
                productoId: null,
                nombreProducto: ""
            })
        }
    }

    const handleEstatusChange = (event) => {
        setSeleccion({
            ...seleccion,
            estatusPago: event.target.value
        });
    };

    function handleFechaFinChange(e, val) {
        try {
            let hasta = `${e.target.value}`;
            setSeleccion({
                ...seleccion,
                fechaFin: hasta
            });
            // if (timestamp(seleccion.fechaInicio) > timestamp(hasta))
            //     throw new Error('¡La fecha final debe ser mayor a la fecha inicial!');
            // if (ftAnioNoValido(hasta)) throw new Error('¡La fecha no tiene un formato válido!');
            // if (!ftAniosRangosValidos(hasta)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');
        } catch ({ message: descripcion }) {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: descripcion,
                abrir: true
            });
            setSeleccion({
                ...seleccion,
                fechaFin: seleccionInicial.fechaFin
            });
        }
    }

    function handleFechaInicioChange(e, val) {
        try {
            let desde = `${e.target.value}`;
            setSeleccion({
                ...seleccion,
                fechaInicio: desde
            });
            // if (timestamp(desde) > timestamp(seleccion.fechaFin))
            //     throw new Error('¡La fecha inicial debe ser menor a la fecha final!');
            // if (ftAnioNoValido(desde)) throw new Error('¡La fecha no tiene un formato válido!');
            // if (!ftAniosRangosValidos(desde)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');

        } catch ({ message: descripcion }) {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: descripcion,
                abrir: true
            });
            setSeleccion({
                ...seleccion,
                fechaInicio: seleccionInicial.fechaInicio
            });
        }
    }

    function handleClinicaChange(e, val) {
        if (val) {
            setSeleccion({
                ...seleccion,
                clinicaId: Number(val.clinicaId),
                nombre: val.nombre,
                productoId: null,
                nombreProducto: 'No se encontraron registros'
            })

        } else {
            setSeleccion({
                ...seleccion,
                clinicaId: null,
                nombre: "",
                productoId: null,
                nombreProducto: 'No se encontraron registros'
            })
        }
    }

    function handleClinicaSelect(e, val) {
        if (e) {
            let clinicaSelecionada = e.target.value;
            setInsert(insert => ({ ...insert, clinicaId: clinicaSelecionada, totalApagar: 0 }))
            setFormasPago([{}]);
        }
    }

    const handleImprimirClick = (id, fecha, panel) => (event, expandido) => {

        event.preventDefault();
       // setOpcion(()=>(panel));
        FetchTickets(id, fecha);
        setExpanded(panel);
    }

    const handleCapitalClick = (abono, total, panel) => (event, expandido) => {
        event.preventDefault();

        setDatosAbono({
            ...datosAbono,
            ...abono,
            totalMonto: total
        });

        setOperacion('AbonoCapital');
        setFormularioPC({ ...formularioPC, abrir: true, onAccept: (valores) => ftAbonoCapital(abono, valores), onCancel: ftMensajeClose });
        setExpanded(panel);
    }

    const handleParcialClick = (abono, total, panel) => (event, expandido) => {
        event.preventDefault();

        setDatosAbono({
            ...datosAbono,
            ...abono,
            totalMonto: total
        });

        setOperacion('AbonoParcial')
        setFormularioPC({ ...formularioPC, abrir: true, onAccept: (valores) => ftAbonoParcial(abono, valores), onCancel: ftMensajeClose })
        setExpanded(panel);
    }

    const handleQRChange = (data) => {

        if (data) {
            let cnv = getUsuario(data);
            if (cnv) {
                if (cnv.usuarioId) {
                    let usuario = clientes.filter(cliente => cliente.usuarioId == cnv.usuarioId);

                    if (usuario?.length > 0) {
                        // hancleClienteChange(null, usuario[0]);

                        setSeleccion(seleccion => ({
                            ...seleccionInicial,
                            usuarioId: Number(usuario[0].usuarioId),
                            nombreCompleto: usuario[0].nombreCompleto,
                            boton: true
                        }));

                        setQR({
                            ...QR,
                            texto: "Usuario encontrado",
                            codigo: cnv.usuarioId,
                            ok: true
                        });

                        setTimeout(() => {
                            setQR({
                                ...QR,
                                abrir: false,
                                texto: "Usuario encontrado",
                                codigo: cnv.usuarioId,
                                ok: false
                            });
                        }, 2000)
                    } else {
                        setQR({
                            ...QR,
                            texto: "Usuario no encontrado",
                            codigo: null
                        });
                    }


                } else {
                    setQR({
                        ...QR,
                        texto: "El código escaneado es incorrecto",
                        codigo: null
                    });
                }
            } else {
                setQR({
                    ...QR,
                    texto: "El código escaneado es incorrecto",
                    codigo: null
                });
            }
        }
    }

    /*evento para cambiar el estado del chekbox pago */
    const handleChangeFormaPago = async (evt, id) => {
        //TODO change forma pago
        let row = [];
        let cantidad = 0;

        if (evt.target) {
            if (evt.target.checked == false) {
                setFormasPago(formasPago => ({ ...formasPago, [id]: evt?.target?.checked, ["_" + id]: '0.00' }));
            } else {
                cantidad = (ftObtenerMonto()).toFixed(2);
                setFormasPago(formasPago => ({ ...formasPago, [id]: evt?.target?.checked, ["_" + id]: cantidad }));
            }
        }
    }

    const handleChangeFormaMonto = (evt, clave) => {
        //TODO change forma monto
        let row = [];
        let cantidad = 0;
        let rgxNumeros = new RegExp(rgxDecimalesPV);

        if (evt.target) {
            let monto = evt.target?.value;
            // debugger;
            if (rgxNumeros.test(monto) || monto === "") {
                // debugger;
                let nuevoMonto = ftObtenerMontoValido(clave.replace('_', ''), monto !== '.' ? monto : '0');

                setFormasPago(formasPago => ({ ...formasPago, [clave]: (isNaN(nuevoMonto) ? 0 : nuevoMonto) /* + (monto.endsWith('.') ? '.' : monto.endsWith('.0') ? '.0' : monto.endsWith('.00') ? '.00' : (monto.includes('.') && monto.endsWith('0')) ? extraDecimal : '') */ }));

            }

        }
    }

    const ftValidarPago = (clave, monto) => {
        //TODO ftValidarPago blur
        let montoValido = ftObtenerMontoValido(clave.replace('_', ''), (monto + "").replace(/,/g, ''));
        setFormasPago(formasPago => ({ ...formasPago, [clave]: isNaN(montoValido) ? '0.00' : montoValido.toFixed(2) }))
    }

    const ftObtenerMonto = () => {
        //TODO obtener monto
        let restante = Number(operacion === 'AbonoCapital' ? datosAbono.partida[0].totalMenosCapital : datosAbono.totalAPagarMenosParcial) - montoAbonar;
        return Number(restante > 0 ? restante : 0);
    }

    const ftObtenerMontoValido = (forma, monto) => {
        //TODO obtener monto valido
        monto = Number(monto);
        let cantidadAparte = 0;

        let nuevoTotal = Number(operacion === 'AbonoCapital' ? datosAbono.partida[0].totalMenosCapital : datosAbono.totalAPagarMenosParcial);

        let nuevaForma = [];
        Object.keys(formasPago).forEach(form => {
            if (form !== '_' + forma) {
                nuevaForma.push(formasPago[form]);
            }
        });

        cantidadAparte = nuevaForma.reduce((suma, valor) => typeof valor === 'number' ? suma + Number(valor) : 0);

        let maximoMonto = nuevoTotal - cantidadAparte;
        maximoMonto = maximoMonto < 0 ? 0 : maximoMonto;

        if (forma !== "01") {
            if (monto > maximoMonto) {
                monto = maximoMonto;
            }
        }

        return monto;
    }

    //#endregion

    //#region Funciones
    const ftAbonoTotal = (pago, valores) => {
        try {
            let totalAPagar = (pago.deudaSinIva + pago.IVADeuda)
            if (clinicaId === 0) throw new Error('¡Seleccione una sucursal!');

            let TotalAcumulado = window.abonar.value !== undefined ? Number(window.abonar.value.replace("$", "")) : 0;

            if (insert.TotalPagos > TotalAcumulado) throw new Error('¡El pago es menor a la cantidad a pagar!');

            let formas = {};
            Object.keys(valores).map(forma => {

                if (forma.startsWith('_')) {
                    let key = forma.replace("_", "");
                    formas[key] = valores[forma] || 0;
                }
            })
            let formasst = "";
            let formaCantidad = "";
            let formasTexto = "";

            formasCobroSelect.formaCobro.map(formsel => {
                if (Object.keys(formas).includes(formsel.clave)) {
                    if ((formas[formsel.clave] || 0) !== 0) {
                        formasst += formsel.clave + ",";
                        formaCantidad += (formas[formsel.clave] || 0) + ",";
                        formasTexto += formsel.descripcion + ",";
                    }
                }
            })

            formasst = formasst.substring(0, formasst.length - 1);
            formaCantidad = formaCantidad.substring(0, formaCantidad.length - 1);
            formasTexto = formasTexto.substring(0, formasTexto.length - 1);

            if ((TotalAcumulado > insert.TotalPagos) && (!formasTexto.includes('Efectivo'))) throw new Error('¡La forma de cobro no incluye efectivo para dar cambio!');
            if (totalAPagar <= 0) throw new Error('¡El monto a pagar no puede ser $0')
            setDisabledAccept(true);

            AbonoTotal({
                variables: {
                    input: {
                        historialPagoId: pago.historialPagoId,
                        vendedorId: userToken.usuarioId,
                        ticketEncabezadoId: pago.partida[0].ticketEncabezadoId,
                        historialPagoPartidaId: pago.partida[0].historialPagoPartidaId,
                        clinicaId: Number(clinicaId),
                        abono: totalAPagar,
                        cambio: (TotalAcumulado - totalAPagar) >= 0 ? TotalAcumulado - totalAPagar : 0,
                        serie: insert.serie,
                        folio: `${insert.folio}`,
                        formaCobroTexto: formasTexto,
                        formasCobro: formasst,
                        formasCobroCantidad: formaCantidad
                    }
                }
            })
                .then((input) => {
                    if (input)
                        if (input.data) {
                            let { ticketId, ticketEncabezadoId, clienteId } = input.data.AbonoTotal;
                            window.open(`/ticketCredito/${clienteId}/${ticketEncabezadoId}/${ticketId}`, '_blank');
                        }
                    FetchListadoPagosClientes();
                })
                .catch(e => {
                    setMensaje({ ...mensajeError, texto: e.message })
                })
            // if (cleanStateFormulario) cleanStateFormulario();
        } catch ({ message: texto }) {
            setDisabledAccept(false);
            setMensaje({ ...mensajeError, texto: texto })
        }
    }

    let ftAbonoCapital = (pago, valores) => {
        let totalAPagar = (pago.deudaSinIva + pago.IVADeuda);
        let cambio = (montoAbonar - (datosAbono.totalMonto || 0) > 0 ? montoAbonar - (datosAbono.totalMonto || 0) : 0);

        let formas = {};
        Object.keys(valores).map(forma => {
            if (forma.startsWith('_')) {
                let key = forma.replace("_", "");
                formas[key] = valores[forma] || 0;
            }
        })

        let formasst = "";
        let formaCantidad = "";
        let formasTexto = "";

        formasCobroSelect.formaCobro.map(formsel => {
            if (Object.keys(formas).includes(formsel.clave)) {
                if ((formas[formsel.clave] || 0) !== 0) {
                    formasst += formsel.clave + ",";
                    formaCantidad += (formas[formsel.clave] || 0) + ",";
                    formasTexto += formsel.descripcion + ",";
                }
            }
        })

        formasst = formasst.substring(0, formasst.length - 1);
        formaCantidad = formaCantidad.substring(0, formaCantidad.length - 1);
        formasTexto = formasTexto.substring(0, formasTexto.length - 1);

        if (montoAbonar > 0) {
            let historialPagoId = pago.historialPagoId;
            let abono = Number(montoAbonar);
            let iva = 0;

            abonarCapital({
                variables: {
                    abono: {
                        historialPagoId,
                        abono: abono - cambio,
                        iva,
                        ticketEncabezadoId: pago.partida[0]?.ticketEncabezadoId || -1,
                        formasCobro: formasst,
                        formasCobroCantidad: formaCantidad,
                        formasCobroTexto: formasTexto,
                        clinicaId: Number(clinicaId),
                        usuarioId: Number(userToken.usuarioId),
                        cambio
                    }
                }
            }).then(v => {
                if (v) {
                    setFormularioPC({ ...formularioPC, abrir: false })
                    setFormasPago({});
                    setMontoAbonar(0);

                    let { ticketId, ticketEncabezadoId, clienteId } = v.data.AbonoCapital;
                    let imp = window.open(`/ticketCredito/${clienteId}/${ticketEncabezadoId}/${ticketId}`, '_blank');

                }
                FetchListadoPagosClientes();
                // setMontoAbonar(0)

            }).catch(e => {
                setMensaje({ ...mensajeError, texto: e.message })
            })
        } else {
            setMensaje({ ...mensajeError, texto: `Debe ingresar un valor correcto para el abono` })
        }
    }

    let ftAbonoParcial = (pago, valores) => {
        let totalAPagar = pago.totalAPagarMenosParcial;
        let cambio = (montoAbonar - (datosAbono.totalMonto || 0) > 0 ? montoAbonar - (datosAbono.totalMonto || 0) : 0);
        let formas = {};
        Object.keys(valores).map(forma => {
            if (forma.startsWith('_')) {
                let key = forma.replace("_", "");
                formas[key] = valores[forma] || 0;
            }
        })
        let formasst = "";
        let formaCantidad = "";
        let formasTexto = "";

        formasCobroSelect.formaCobro.forEach(({ clave, descripcion }) => {
            let keys = Object.keys(formas);
            if (keys.includes(clave)) {
                if ((formas[clave] || 0) !== 0) {
                    formasst += clave + ",";
                    formaCantidad += (formas[clave] || 0) + ",";
                    formasTexto += descripcion + ",";
                }
            };
        })

        formasst = formasst.substring(0, formasst.length - 1);
        formaCantidad = formaCantidad.substring(0, formaCantidad.length - 1);
        formasTexto = formasTexto.substring(0, formasTexto.length - 1);

        if (montoAbonar > 0) {
            let historialPartidaId = Number(pago.historialPagoPartidaId);
            let monto = Number(montoAbonar);
            let iva = 0;

            abonarParcial({
                variables: {
                    abono: {
                        historialPartidaId,
                        monto: monto - cambio,
                        iva,
                        usuario: userToken.usuarioId,
                        ticketEncabezadoId: pago.ticketEncabezadoId || -1,
                        formasCobro: formasst,
                        formasCobroCantidad: formaCantidad,
                        formasCobroTexto: formasTexto,
                        clinicaId: Number(clinicaId),
                        cambio
                    }
                }
            }).then(v => {
                if (v) {
                    let { ticketId, ticketEncabezadoId, clienteId } = v.data.AbonoParcial;

                    let imp = window.open(`/ticketCredito/${clienteId}/${ticketEncabezadoId}/${ticketId}`, '_blank');

                    setFormularioPC({ ...formularioPC, abrir: false });
                    setFormasPago({});
                    setMontoAbonar(0);
                }
                FetchListadoPagosClientes();

                // setMontoAbonar(0)
            }).catch(e => {
                setMensaje({ ...mensajeError, texto: e.message })
            })
        } else {
            setMensaje({ ...mensajeError, texto: `Debe ingresar un valor correcto para el abono` })
        }
    }


    const ftGenerarCuerpo = (data) => {
        if (data === null) return <p>Sin registros</p>;;
        if (data === '') return <p>Sin registros</p>;;
        let row = [];
        if (data !== undefined) {
            let pagado = false;

            data.map(function (datos, index) {
                row.push(
                    <Grid container spacing={3} key={`${index}-user`}>
                        <Grid item xs={12} md={1} style={{ marginTop: "10px" }}>
                            <p>N°: {datos.numeroPago}</p>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ marginTop: "10px" }}>
                            <p>Descripción: {datos.productos}</p>
                        </Grid>
                        <Grid item xs={12} md={1} style={{ marginTop: "10px" }}>
                            <p>A pagar: {`$${datos.totalAPagar.toFixed(2)}`}</p>
                        </Grid>
                        <Grid item xs={12} md={1} style={{ marginTop: "10px" }}>
                            <p>Abonó: {`$${(datos.totalAPagar - (datos.totalAPagarMenosParcial > 0 ? datos.totalAPagarMenosParcial : 0)).toFixed(2)}`}</p>
                        </Grid>
                        <Grid item xs={12} md={1} style={{ marginTop: "10px" }}>
                            <p>Resta: {`$${(datos.totalAPagarMenosParcial > 0 ? datos.totalAPagarMenosParcial : 0).toFixed(2)}`}</p>
                        </Grid>

                        <Grid item xs={12} md={2} style={{ marginTop: "10px" }}>
                            <p>Fecha de vencimiento: {datos.fechaPago ? datos.fechaPago.split('T')[0] : "No definido"}</p>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ marginTop: "10px" }}>
                            <p>Fecha en que se pagó: {datos.fechaPagoFinalizado ? datos.fechaPagoFinalizado.split('T')[0] : ""}</p>
                        </Grid>
                        <Grid item xs={12} md={1} style={{ marginTop: "10px" }}>
                            <p>Estado: {datos.estatusPartida !== undefined || datos.estatusPartida !== null ? datos.estatusPartida.toString().replace("0", "Pendiente").replace("1", "Pagado").replace("2", "Atrasado").replace("3", "Pago parcial") : ""}</p>
                        </Grid>
                        <Grid item xs={1} md={1} style={{ marginTop: "10px" }}>
                            <IconButton color="primary"
                                onClick={handleParcialClick(datos, datos.totalAPagarMenosParcial)} title="Abonar"
                                disabled={datos.totalAPagarMenosParcial.toFixed(2) <= 0 || (index > 0 ? data[index - 1].estatusPartida !== 1 : false)}

                                style={{ color: datos.totalAPagarMenosParcial.toFixed(2) <= 0 ? '#77ccbb' : (index > 0 ? data[index - 1].estatusPartida !== 1 : false) ? 'rgba(0, 0, 0, 0.26)' : '#2699FB' }}
                            >
                                <AccountBalanceWallet />
                            </IconButton>
                        </Grid>
                    </Grid>
                )
                return row;

            });
            return row;
        } else {
            return <p>Sin registros</p>;
        }
    }

    const ftSinDatos = () => {
        return (

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper elevation={0} className={classes.paper}>No se encontraron registros.</Paper>
                </Grid>
            </Grid>
        )
    }

    const ftGenerarBloques = (data) => {
        if (data === null) return null;
        if (data === '') return null;
        let row = [];

        if (data !== undefined && data.listadoHistorialPago !== undefined) {

            data.listadoHistorialPago.historialPagos.map(function (x, index) {
                row.push(
                    <ExpansionPanel key={`${index}-exp`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>

                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}-panel1bh-content`}
                            id={`${index}-panel1bh-header`}
                        >
                            <Grid container justify="space-between" alignItems="center">

                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={event => event.stopPropagation()}
                                    onFocus={event => event.stopPropagation()}
                                    control={
                                    <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClick(x,`panel${index}`)} title="Abonar" disabled={x.estatusPago === true ? true : false}>
                                    <PaymentIcon />
                                    </IconButton>
                                    }
                                    label=""
                                />

                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={event => event.stopPropagation()}
                                    onFocus={event => event.stopPropagation()}
                                    control={
                                    <IconButton color="primary" onClick={handleCapitalClick(x, (x.partida[0].totalMenosCapital > 0 ? x.partida[0].totalMenosCapital : 0), `panel${index}`)} title="Abonar capital" disabled={x.partida[0].totalMenosCapital.toFixed(2) <= 0 ? true : false}>
                                    <AccountBalance />
                                    </IconButton>
                                    }
                                    label=""
                                />

                                <FormControlLabel
                                    aria-label="Acknowledge"
                                    onClick={event => event.stopPropagation()}
                                    onFocus={event => event.stopPropagation()}
                                    control={
                                    <IconButton onClick={handleImprimirClick(x.partida[0].ticketEncabezadoId, x.fechaPagoTotalFinalizado, `panel${index}`)} color="primary" onFocus={e => e.preventDefault()} title="Enviar por correo" disabled={x.fechaPagoTotalFinalizado ? false : true}>
                                    <Print />
                                    </IconButton>
                                    }
                                    label=""
                                />


                                <Typography className={classes.heading}>
                                    {`Cliente: ${x.nombreCliente}`}
                                </Typography>

                                <Typography className={classes.secondaryHeading}>
                                    {`Total a pagar:  ${x.totalDeuda.toFixed(2)}`}
                                </Typography>

                                <Typography className={classes.secondaryHeading}> &nbsp;</Typography>

                                <Typography className={classes.secondaryHeading}>
                                    {`Restan:  $${(x.partida[0].totalMenosCapital > 0 ? x.partida[0].totalMenosCapital : 0).toFixed(2)}`}
                                </Typography>

                                <Typography className={classes.secondaryHeading}> &nbsp;</Typography>

                                <Typography className={classes.secondaryHeading}>
                                    {`Fecha de alta: ${x.fechaAlta.split('T')[0]}`}
                                </Typography>

                                <Typography className={classes.secondaryHeading}> &nbsp;</Typography>

                                <Typography className={classes.secondaryHeading}>
                                    {`Fecha de inicio: ${x.fechaInicioPago.split('T')[0]}`}
                                </Typography>
                                {/* <Typography className={classes.secondaryHeading}>{`Estatus de credito: ${x.estatusCredito === true ? 'Activo' : 'Inactivo'}`}</Typography> */}
                                <Typography className={classes.secondaryHeading}>
                                    {`Estatus de pago: ${x.estatusPago === true ? 'Pagado' : 'Con adeudo'}`}
                                </Typography>
                            </Grid>
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                            <Grid container spacing={3} >
                                {ftGenerarCuerpo(x.partida)}
                            </Grid>
                        </ExpansionPanelDetails>

                    </ExpansionPanel>
                );
                return true;
            });
            return row;
        }
        // else{

        //     return (
        //     <Grid xs ={12}>
        //         <p>Sin registros</p>;
        //     </Grid>)
        // }
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
        setInsert(insert => ({ ...insert, datosInsert }));
        setFormasPago({});
        setMontoAbonar(0);
    }

    function ftFormularioPCClose() {
        setFormularioPC({ ...formularioPC, abrir: false });
        setFormasPago({});
        setMontoAbonar(0);
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    //#endregion

    //#region UseEffects
    // useEffect(() => {
    //     setFormularioPC({ ...formularioPC, onAccept: operacion === 'AbonoCapital' ? (valores) => ftAbonoCapital(datosAbono, valores) : (valores) => ftAbonoParcial(datosAbono, valores), onCancel: ftMensajeClose })
    // }, [montoAbonar])

    useEffect(() => {
        FetchProductosServicios();
        setSeleccion({...seleccion, productoId: null,
            nombreProducto: 'No se encontraron registros',})
    }, [clinica]);

    useEffect(() => {
        FetchListadoPagosClientes();
    }, [errores, seleccion]);


    useEffect(() => {
        FetchProductosServicios();
    }, [seleccion.clinicaId]);

    useEffect(() => {
        FetchClinicasUsuarios();
    }, [formulario.abrir]);

    useEffect(() => {
        // FetchListadoPagosClientes();
        FetchClientes();
        FetchVendedores();
        FetchClinicas();
        FetchProductosServicios();
        // return function cleanup() {
        //     abortController.abort();
        // }
    }, []);

    useEffect(() => {
        FetchSerieFolio();
        FetchFormaCobro();
        // setFormasPago({});
    }, [clinicaId])

    useEffect(() => {
        FetchListadoPagosClientes();
    }, [insert])

    useEffect(() => {
        if (formasPago !== null) {
            let suma = 0;

            // Object.keys(formasPago).map(form => {
            //     if (form.startsWith('_')) {
            //         suma += Number(formasPago[form]);
            //     }
            // })

            // let formas = Object.values(formasPago);

            // for (let i = 0; i < formas.length; i++) {
            //     suma += formas[i] !== true && formas[i] !== false ? Number(formas[i]) : 0;
            // }

            suma = Object.values(formasPago).reduce((suma, valor) => typeof valor !== 'boolean' ? suma + Number(valor) : 0, montoAbonar);

            setMontoAbonar(suma);

            setFormularioPC({ ...formularioPC, onAccept: operacion === 'AbonoCapital' ? (valores) => ftAbonoCapital(datosAbono, valores) : (valores) => ftAbonoParcial(datosAbono, valores), onCancel: ftMensajeClose });
        }
    }, [formasPago]);
    //#endregion

    return (
        <MenuLateral>

            {/* encabezado del componente */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Encabezado titulo="Pagos de clientes" />
                </Grid>
            </Grid>

            <EscanerQr abrir={QR.abrir} tipo={QR.tipo} onAccept={QR.onAccept} onCancel={QR.onCancel} onChange={handleQRChange} texto={QR.texto} success={QR.ok} />

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            {/* <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={`${seleccion.estatusID ? "Modificar" : "Agregar"} estatus`} onClose={formulario.onClose} onAccept={formulario.onAccept} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} /> */}

            <FormularioParcialCapital abrir={formulario.abrir} campos={camposFormulario} titulo="Abonar" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} valor={insert} datos={insert} /*stateLimpio={datosInsert}*/ sync />

            <FormularioParcialCapital abrir={formularioPC.abrir} campos={camposFormularioPC} titulo={operacion === 'AbonoCapital' ? 'Abonar a capital' : 'Abonar'} onClose={formularioPC.onClose} onAccept={formularioPC.onAccept} valor={seleccion} datos={formasPago} disabledAccept={disabledAccept} sync />

            <Card className={classes.mb1}>
                <CardContent>

                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <Autocomplete
                                    id="cliente" autoHighlight noOptionsText="Sin clientes"
                                    onChange={hancleClienteChange} {...clientesProps}
                                    value={clientes ? seleccion.usuarioId ? clientes.find(val => Number(val.usuarioId) == Number(seleccion.usuarioId)) : null : null}
                                    renderInput={params => (
                                        <TextField {...params} label="Cliente" fullWidth
                                            style={{ color: 'red' }} style={{ marginTop: '8px' }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }}>
                                <Autocomplete
                                    id="vendedor" autoHighlight noOptionsText="Sin resultados"
                                    onChange={handleVendedorChange} {...vendedorProps}
                                    value={vendedor ? seleccion.vendedorId ? vendedor.find(val => Number(val.usuarioId) == Number(seleccion.vendedorId)) : null : null}
                                    renderInput={params => (
                                        <TextField {...params} label="Vendedor" fullWidth
                                            style={{ color: 'red' }} style={{ marginTop: '8px' }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <Autocomplete
                                    id="clinica" autoHighlight noOptionsText="Sin resultados"
                                    onChange={handleClinicaChange}
                                    {...clinicaProps}
                                    value={clinica ? seleccion.clinicaId ? clinica.find(val => Number(val.clinicaId) == Number(seleccion.clinicaId)) : null : null}
                                    renderInput={params => (
                                        <TextField {...params} label="Sucursal" fullWidth
                                            style={{ color: 'red' }} style={{ marginTop: '8px' }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <Autocomplete
                                    id="producto" autoHighlight noOptionsText="Sin resultados"
                                    onChange={handleProductoChange}

                                    {...productoProps}
                                    value={producto ? seleccion.productoId ? producto.find(val => Number(val.servicioId) == Number(seleccion.productoId)) || null : null : null}
                                    renderInput={params => (
                                        <TextField {...params} label="Producto/Servicio" fullWidth
                                            style={{ color: 'red' }} style={{ marginTop: '8px' }}

                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <form className={classes.container} noValidate>
                                <TextField
                                    id="date"
                                    label="Fecha Inicio de pago"
                                    type="date"
                                    value={seleccion.fechaInicio}
                                    onChange={handleFechaInicioChange}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => {
                                        try {
                                            let desde = `${e.target.value}`;

                                            if (timestamp(desde) > timestamp(seleccion.fechaFin))
                                                throw new Error('¡La fecha inicial debe ser menor a la fecha final!');
                                            if (ftAnioNoValido(desde)) throw new Error('¡La fecha no tiene un formato válido!');
                                            if (!ftAniosRangosValidos(desde)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');

                                        } catch ({ message: descripcion }) {
                                            setMensaje({
                                                ...mensaje,
                                                ...mensajeError,
                                                texto: descripcion,
                                                abrir: true
                                            });
                                            setSeleccion({
                                                ...seleccion,
                                                fechaInicio: seleccionInicial.fechaInicio
                                            });
                                        }
                                    }}
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <form className={classes.container} noValidate>
                                <TextField
                                    id="date"
                                    label="Fecha fin de pago"
                                    type="date"
                                    value={seleccion.fechaFin}
                                    onChange={handleFechaFinChange}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onBlur={(e) => {
                                        try {
                                            let hasta = `${e.target.value}`;
                                            setSeleccion({
                                                ...seleccion,
                                                fechaFin: hasta
                                            });
                                            if (timestamp(seleccion.fechaInicio) > timestamp(hasta))
                                                throw new Error('¡La fecha final debe ser mayor a la fecha inicial!');
                                            if (ftAnioNoValido(hasta)) throw new Error('¡La fecha no tiene un formato válido!');
                                            if (!ftAniosRangosValidos(hasta)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');
                                        } catch ({ message: descripcion }) {
                                            setMensaje({
                                                ...mensaje,
                                                ...mensajeError,
                                                texto: descripcion,
                                                abrir: true
                                            });
                                            setSeleccion({
                                                ...seleccion,
                                                fechaFin: seleccionInicial.fechaFin
                                            });
                                        }
                                    }}
                                />
                            </form>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3} lg={3}>
                            <FormControl style={{ paddingRight: '10px' }} className={classes.formControl}>
                                <InputLabel id="estatusPago">Estatus de pago </InputLabel>
                                <Select
                                    labelId="estatusPago"
                                    id="estatusPago"
                                    value={seleccion.estatusPago}
                                    onChange={handleEstatusChange}
                                    autoWidth
                                >
                                    <MenuItem value={-1}>
                                        <em>Mostrar todos</em>
                                    </MenuItem>
                                    <MenuItem value={1}>Pagado</MenuItem>
                                    <MenuItem value={0}>Con adeudo</MenuItem>
                                </Select>
                                <FormHelperText>Pagado / Con adeudo</FormHelperText>
                            </FormControl>
                        </Grid>

                    </Grid>

                    <Grid container justify="center" item xs={12} sm={12} style={{ textAlign: "center", marginTop: "15px" }}>

                        <Grid item xs={12} sm={2} md={1} xl={1}>
                            <Button variant="contained" color="primary"
                                // disabled={boton}
                                fullWidth
                                onClick={() => { setQR({ ...QR, abrir: true }) }}>
                                Leer QR
                            </Button>
                        </Grid>

                        {/* <Grid item xs={12} sm={2} md={1} xl={1}>
                            <Button variant="contained" color="primary"
                                // disabled={boton}
                                onClick={handleFiltroClick}>
                                Buscar
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} sm={2} md={1} xl={1}>
                            <Button variant="contained" color="secondary" style={{ marginLeft: "10px" }}
                                // disabled={boton}
                                onClick={handleLimpiarFiltro}>
                                Limpiar
                            </Button>
                        </Grid>
                    </Grid>


                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className={classes.root} style={{ marginTop: "15px" }}>
                                {sindatos === 1 ? ftSinDatos() : ftGenerarBloques(datos)}
                            </div>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
        </MenuLateral>
    )
}
