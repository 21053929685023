import React from 'react';
//Iconos
import { AddIcon, EditIcon,SwitchIcon } from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    recordatorioPagoId:null,
    plazoCreditoId:null,
    descripcionPlazo:null,
    tituloRecordatorio:null,
    descripcionPlazo:null,
    dias:null,
    estatus:null,
    recordarAntesDespues:null,
};
//menu lateral
const menu = (handleAgregarClick, handleModificarClick,handleCambiarEstatusClick,checked) => [
    { texto: "Agregar notificación de crédito", icono: <AddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar notificación de crédito", icono: <EditIcon />, onClick: () => handleModificarClick() },
    { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
];


/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    recordatorioPagoId:null,
    plazoCreditoId:null,
    descripcionPlazo:null,
    tituloRecordatorio:null,
    descripcionPlazo:null,
    dias:null,
    estatus:null,
    recordarAntesDespues:null,
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

/*CONFIGURACION */

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
};

export {  seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,Style };
