import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [especialidades, guardarEspecialidades] = useState({
        Especialidad: {
            especialidadId: { error: false, valor: "" },
            descripcion: { error: false, valor: "" },
        }
    });
    return (
        <CRMContext.Provider value={[especialidades, guardarEspecialidades]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
