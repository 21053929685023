import gql from 'graphql-tag';

//#region  USUARIO
//==========USUARIO============================


const OBTENER_SONIDO_NOTIFICACION=`
query ObtenerUsuarioGeneral($id: Int!) {
  ObtenerUsuarioGeneral(id: $id) {
    sonidoNotificacion
  }
}
`;

const LISTADO_RELACION_DOCTOR=gql`
query listadoRelacionDoctor($estatus: Int!, $clinicaId: Int!) {
    listadoRelacionDoctor(clinicaId: $clinicaId, estatus: $estatus) {
      doctores {
        fechaNacimiento
        usuarioId
        tipo
        nombres
        apellidos
        email
        password
        cedula
        fechaEgreso
        fechaAlta
        estatus
        sexo
        especialidadId
        descripcion
        correo
        telefono
        nombreCompleto
        clinicaId
      }
    }
  }`;

  const LISTADO_RELACION_USUARIO=gql`
  query listadoRelacionUsuario($estatus: Int!, $clinicaId: Int!) {
    listadoRelacionUsuario(clinicaId: $clinicaId, estatus: $estatus) {
      usuarios {
        fechaNacimiento
        usuarioId
        tipo
        nombres
        apellidos
        email
        password
        fechaEgreso
        fechaAlta
        estatus
        sexo
        tarjetaQr
        correo
        telefono
        nombreCompleto
        clinicaId
      }
    }
  }
  `;

const LISTADO_RELACION_USUARIO_FETCH=`
query listadoRelacionUsuario($estatus: Int!, $clinicaId: Int!) {
  listadoRelacionUsuario(clinicaId: $clinicaId, estatus: $estatus) {
    usuarios {
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      nombreCompleto
      clinicaId
    }
  }
}
`;




const  LISTADO_USUARIO=gql`
query listadoUsuario($offset:Int!, $moroso: Int) {
    listadoUsuario(offset:$offset, moroso: $moroso) {
      registros
      paginas
      usuarios {
        usuarioId
        tipo
        nombres
        apellidos
        email
        password
        fechaEgreso
        fechaAlta
        estatus
        sexo
        tarjetaQr
        correo
        telefono
        nombreCompleto
        moroso
      }
    }
  }`

  const LISTADO_DOCTOR= gql`
  query listadoDoctor($offset:Int!,$moroso: Int) {
    listadoDoctor(offset:$offset,moroso: $moroso) {
      registros
      paginas
      doctores {
        usuarioId
        tipo
        nombres
        apellidos
        nombreCompleto
        email
        password
        cedula
        fechaEgreso
        fechaAlta
        estatus
        sexo
        tarjetaQr
        especialidadId
        descripcion
        correo
        telefono
        moroso
      }
    }
  }`;

const LISTADO_DOCTOR_CADENA=`
query listadoDoctor($offset:Int!,$moroso: Int) {
    listadoDoctor(offset:$offset,moroso: $moroso) {
      registros
      paginas
      doctores {
        usuarioId
        tipo
        nombres
        apellidos
        nombreCompleto
        email
        password
        cedula
        fechaEgreso
        fechaAlta
        estatus
        sexo
        tarjetaQr
        especialidadId
        descripcion
        correo
        telefono
        moroso
      }
    }
  }`;

const LISTADO_DOCTOR_NO_ASIGNADOS=`
query obtenerDoctoresAsignadosClinicas($id: Int!) {
    obtenerDoctoresNoAsignadosClinicas(id: $id) {
      clinicaDoctores {
        doctorId
        nombreCompleto
        email
      }
    }
  }
`;
const LISTADO_DOCTOR_ASIGNADOS=`
query obtenerDoctoresAsignadosClinicas(
    $id: Int!
    $correo: Int!
    $estatus: Int!
  ) {
    obtenerDoctoresAsignadosClinicas(
      id: $id
      correo: $correo
      estatus: $estatus
    ) {
      clinicaDoctores {
        doctorId
        nombreCompleto
        email
        porDefecto
      }
    }
  }

`;

const LISTADO_DOCTOR_ASIGNADOS_GQL= gql`
query obtenerDoctoresAsignadosClinicas($id: Int!, $correo: Int!, $estatus: Int!) {
    obtenerDoctoresAsignadosClinicas(id: $id, correo: $correo, estatus: $estatus) {
      clinicaDoctores {
        clinicaDoctorId
        doctorId
        nombreCompleto
        email
      }
    }
  }
`;
const LISTADO_USUARIO_NO_ASIGNADOS=`
query ObtenerUsuarioNoAsignados($id: Int!) {
    ObtenerUsuarioNoAsignados(id: $id) {
      clinicaUsuarios {
        usuarioId
        nombres
        tipo
        nombres
        apellidos
        nombreCompleto
        email
      }
    }
  }`;
const LISTADO_PACIENTE = gql`
	query listadoPaciente($offset:Int!,$moroso: Int) {
		listadoPaciente(offset:$offset,moroso: $moroso) {
			registros
			paginas
			pacientes {
                fechaNacimiento
				usuarioId
				tipo
				nombres
				apellidos
				email
				password
				fechaEgreso
				fechaAlta
				estatus
				sexo
				tarjetaQr
				correo
                telefono
                nombreCompleto
                moroso
			}
		}
	}
`;

const LISTADO_PACIENTE_FETCH = `
	query listadoPaciente($offset:Int!,$moroso: Int) {
		listadoPaciente(offset:$offset,moroso: $moroso) {
			registros
			paginas
			pacientes {
                fechaNacimiento
				usuarioId
				tipo
				nombres
				apellidos
				email
				password
				fechaEgreso
				fechaAlta
				estatus
				sexo
				tarjetaQr
				correo
                telefono
                nombreCompleto
                moroso
			}
		}
	}
`;


const LISTADO_ADMINISTRADOR = gql`
	query listadoAdministrador($offset:Int!,$moroso: Int) {
		listadoAdministrador(offset:$offset,moroso: $moroso) {
			registros
			paginas
			administrador {
				usuarioId
				tipo
				nombres
				apellidos
				email
				password
				fechaEgreso
				fechaAlta
				estatus
				sexo
				tarjetaQr
				correo
                telefono
                nombreCompleto
                fechaNacimiento
                moroso
			}
		}
	}
`;

const OBTENER_USUARIO = `
  query obtenerUsuario($id: Int!) {
    obtenerUsuario(id: $id) {
        fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      moroso
    }
  }`;

const OBTENER_DOCTOR = `
  query obtenerDoctor($id: Int!) {
    obtenerDoctor(id: $id) {
        fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      especialidadId
      descripcion
      correo
      telefono
      moroso
    }
  }
  `;

  const OBTENER_DOCTOR_ID = gql `
  query obtenerDoctor($id: Int!) {
    obtenerDoctor(id: $id) {
        fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      especialidadId
      descripcion
      correo
      telefono
      moroso
    }
  }
  `;


const OBTENER_ADMINISTRADOR = `
  query obtenerAdministrador($id: Int!) {
    obtenerAdministrador(id: $id) {
        fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      moroso
    }
  }
  `;

const OBTENER_PACIENTE = `
  query obtenerPaciente($id: Int!) {
    obtenerPaciente(id: $id) {
        fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      moroso
    }
  }`;
const LISTADO_ADMIN_USUARIO_FETCH = `
query listadoAdministradorUsuario($offset: Int!,$moroso: Int) {
    listadoAdministradorUsuario(offset: $offset,moroso: $moroso) {
      usuarios {
        fechaNacimiento
        usuarioId
        tipo
        nombres
        apellidos
        email
        password
        fechaAlta
        fechaEgreso
        estatus
        sexo
        tarjetaQr
        correo
        especialidadId
        descripcion
        cedula
        nombreCompleto
        puntoVenta
        telefono
        moroso
      }
    }
  }`;
const OBTENER_LISTADO_ADMINISTRADOR_USUARIOS=gql`
query listadoAdministradorUsuario($offset: Int!,$moroso: Int) {
  listadoAdministradorUsuario(offset: $offset,moroso: $moroso) {
    usuarios {
      fechaNacimiento
      usuarioId
      tipo
      nombres
      apellidos
      email
      password
      fechaAlta
      fechaEgreso
      estatus
      sexo
      tarjetaQr
      correo
      especialidadId
      descripcion
      cedula
      nombreCompleto
      puntoVenta
      telefono
      moroso
    }
  }
}
`;

  const OBTENER_LISTADO_GENERAL_USUARIOS= gql`
 query listadoGeneral($offset:Int!,$moroso: Int){
    listadoGeneral(offset:$offset,moroso: $moroso){
      usuarios{
          fechaNacimiento
        usuarioId
        tipo
        nombres
        apellidos
        email
        password
        fechaAlta
        fechaEgreso
        estatus
        sexo
        tarjetaQr
        correo
        especialidadId
        descripcion
        cedula
        nombreCompleto
        puntoVenta
        telefono
        moroso
      }
    }}`;

const CLIENTES_ACTIVOS_FETCH = `
    query ClientesActivos {
        ClientesActivos {
            usuarioId
            nombreCompleto
            email
            telefono
        }
    }
`;

//#endregion
//===============================================

export {
    OBTENER_LISTADO_ADMINISTRADOR_USUARIOS,
    LISTADO_RELACION_DOCTOR,
    LISTADO_RELACION_USUARIO,
    LISTADO_RELACION_USUARIO_FETCH,
    LISTADO_USUARIO,
    LISTADO_DOCTOR,
    LISTADO_DOCTOR_CADENA,
    LISTADO_DOCTOR_NO_ASIGNADOS,
    LISTADO_DOCTOR_ASIGNADOS,
    LISTADO_DOCTOR_ASIGNADOS_GQL,
    LISTADO_USUARIO_NO_ASIGNADOS,
    LISTADO_PACIENTE,
    LISTADO_ADMINISTRADOR,
    OBTENER_USUARIO,
    OBTENER_DOCTOR,
    OBTENER_ADMINISTRADOR,
    OBTENER_PACIENTE,
    OBTENER_LISTADO_GENERAL_USUARIOS,
    LISTADO_PACIENTE_FETCH,
    OBTENER_SONIDO_NOTIFICACION,
    CLIENTES_ACTIVOS_FETCH,
    LISTADO_ADMIN_USUARIO_FETCH,
    OBTENER_DOCTOR_ID
}
