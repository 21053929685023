import React, { useState, useEffect } from 'react'
import { PDFViewer, Page, Text, View, Document, Image, StyleSheet, Font, BlobProvider } from '@react-pdf/renderer';


//React Router
import { useParams } from 'react-router-dom';

//Apollo
import { useQuery } from '@apollo/react-hooks';

//Queries
import { LISTADO_TICKETS, OBTENER_TICKET, OBTENER_TICKET_FETCH, FILTRO_TELEFONOS, FILTRO_TELEFONOS_FETCH, LISTAR_FORMA_COBRO, OBTENER_TICKETS_IDS, HISTORIAL_PAGO_FETCH } from '../../../query';

//Formatos
import { formatoFolio, formatoFecha, formatoMoneda } from '../../../Helpers/constantes/formatos';

//Helpers
import { strUrlServer } from '../../../Helpers/constantes/Config/UrlServer'
import { rutaPublica } from '../../../Helpers/constantes/Config/urlRutas';

//Constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../../Helpers/constantes';

//FETCH
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";

//estilos
import './estilos.css';
import Mensaje from '../../../componentesGenericos/Mensaje';

//Redux
import { useSelector, useDispatch } from 'react-redux';

function ToPDF({ ticketId, onFinish = () => { } }) {

    Font.register({ family: 'Times New Roman', src: '/fuentes/times.ttf' });

    document.title = "Ticket de Venta";
    document.body.style.cssText = "overflow-x:hidden; overflow-y:hidden;";
    const dt = new Date();
    const fecha = new Date();
    let anio = fecha.getFullYear();
    fecha.setMonth(fecha.getMonth() + 3);
    let mes = fecha.getMonth();
    mes = mes < 10 ? `0${mes}` : mes;
    let dia = fecha.getDate();
    dia = fecha.toString().length === 1 ? `0${dia}` : dia;
    const mesAnterior = `${anio}-${mes}-${dia}`;



    const configuracion = useSelector(state => state.configuracion);
    let { clienteId, ticketEncabezadoId, ticketID } = useParams();

    const [tv, setTV] = useState({
        ticketId: null,
        empresa: "Clinica dental Perfiles",
        clinica: "",
        serie: "TVG",
        folio: "0",
        calle: "Sin dirección",
        colonia: "",
        municipio: "",
        estado: "",
        cp: "",
        vendedor: "Clinica Perfiles",
        vendedorId: "",
        clienteId: null,
        telefono: null,
        servicios: [],
        total: "0.00",
        formasCobro: [],
        formasCobroCantidad: [],
        fechaEmision: null,
    });
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const serieFolio = localStorage.getItem('serieTk');
    const [partidas, setPartidas] = useState([]);
    const [fechaProxPago, setFechaProxPago] = useState("")
    const [formasCobro, setFormasCobro] = useState([]);
    const [formasCobroCantidad, setFormasCobroCant] = useState([]);
    const [telefonos, setTelefonos] = useState("");
    // const [imgLoaded, setImgLoaded] = useState(false);

    const FetchListadoPagosClientes = async () => {
        FetchGrahpQL({
            query: HISTORIAL_PAGO_FETCH,
            variables: {
                filtro: {
                    clinicaId: null,
                    clienteId: Number(clienteId),
                    //919,
                    estatusPago: null,
                    estatusPartida: null,
                    vendedorId: null,
                    fechaAlta: null,
                    fechaPagoFinal: null,
                    fechaInicioPartida: null,
                    fechaFinalPartida: null,
                    productoId: null,
                    ticketEncabezadoId: Number(ticketEncabezadoId),
                    PagosAdeudo: null
                    // 279
                }
            }
        })
            .then((data) => {
                setPartidas(data.listadoHistorialPago.historialPagos[0])
            })
            .catch((e) => {
                console.log(e.message);
            })
    }

    const FetchFechaPagosClientes = async () => {
        FetchGrahpQL({
            query: HISTORIAL_PAGO_FETCH,
            variables: {
                filtro: {
                    clinicaId: null,
                    clienteId: Number(clienteId),
                    //919,
                    estatusPago: null,
                    estatusPartida: null,
                    vendedorId: null,
                    fechaAlta: null,
                    fechaPagoFinal: null,
                    fechaInicioPartida: dt,
                    fechaFinalPartida: mesAnterior,
                    productoId: null,
                    ticketEncabezadoId: Number(ticketEncabezadoId),
                    PagosAdeudo: null
                    // 279
                }
            }
        })
            .then((data) => {
                setFechaProxPago(data.listadoHistorialPago.historialPagos[0].partida[0].fechaPago)
            })
            .catch((e) => {
                console.log(e.message);
            })
    }

    const queryTickets = () => {
        async function queryTickets() {
            try {
                let resultado = await FetchGrahpQL({
                    query: OBTENER_TICKET_FETCH,
                    variables: {id: Number(ticketID), serie: null, folio: null},
                });

                let {obtenerTicket} = resultado;
                let result = obtenerTicket;

                return obtenerTicket;

            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryTickets();
    }

    const { data: ticketData } = useQuery(OBTENER_TICKETS_IDS, {
        variables: { ticketEncabezadoId: Number(ticketEncabezadoId), Fecha: null },
        onError: (e) => {

            console.log("Error: ", e.message)
        },
        onCompleted: () => {
              //console.log("data: ", data)
        }
    });

    //#region queries

    const queryTelefonos = (direccionId) => {
        async function queryTelefonos() {
            try {
                let resultado = await FetchGrahpQL({
                    query: FILTRO_TELEFONOS_FETCH,
                    variables: {limite: 0, pagina: 0, direccionId: direccionId ? direccionId : null},
                });
                let tels = "";

                resultado.listadoTelefonos.telefonos.map(tel => {
                        tels += tel.telefono + ", ";
                });
                tels = tels.substring(0, tels.length > 1 ? tels.length - 2 : 1);
                return tels;

            } catch({message}) { return (console.log('error tel', message)); }
        }
        return queryTelefonos();
    }

    //#endregion


    //#region Funciones
    const enviarPdf = (blob) => {
        let formData = new FormData();
        formData.append('file', blob);
        formData.append('nombre', `ticket_${tv.serie}-${tv.folio}`);
        formData.append('clienteId', tv.clienteId);

        fetch(`${strUrlServer}/ticket/send`, {
            method: 'POST',
            body: formData
        })
            .then((res) => {
                if (res.ok) {
                    // window.close();
                } else {
                    onFinish(false);
                }
                return res.json();
            }).then(val => {
                if (val.ErrorMessage) {
                    setMensaje({ ...mensajeError, texto: val.ErrorMessage });
                    onFinish(false);
                } else {
                    onFinish(true);
                }
                window.print();
                window.close();
            })
            .catch(e => {
                setMensaje({ ...mensajeError, texto: e.message })
                onFinish(false);
            });

        // saveBlob(blob, '');
    }

    const saveBlob = (blob, filename) => {
        let url = URL.createObjectURL(blob);
        let iframe = document.createElement('iframe');

        iframe.onload = function (e) {

            if (e.target.contentWindow) {
                setTimeout(() => {
                    iframe.contentWindow.focus();
                    iframe.contentWindow.print();
                }, 4000)
            }
        };
        iframe.src = url;

    };

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    //#endregion

    //#region Estilos
    const styles = StyleSheet.create({
        page: { backgroundColor: 'blue', fontFamily: 'Times New Roman' },
        ticket: { width: '100%', maxWidth: '100%', backgroundColor: 'white', fontSize: 10 },
        logo: { display: "flex", flexDirection: "row", justifyContent: 'center' },
        img: { width: 90 },
        titulo: { fontSize: 12, marginTop: 2 },
        centrado: { textAlign: "center" },
        blockCentrado: { display: "flex", flexDirection: "row", justifyContent: 'flex-end' },
        tituloDocumento: { textAlign: 'center', margin: '5 0' },
        contenedor: {
            borderTop: "1 solid black", borderBottom: "1 solid black",
            padding: "0", margin: "10px 0",
            fontSize: 10
        },
        contenedor2: {
            borderTop: "1 solid black", borderBottom: "1 solid black",
            padding: "0", marginBottom: "10px ",
            fontSize: 10
        }
    });

    const tablaStyles = StyleSheet.create({
        table: {
            display: "table",
            width: "100%",
            paddingBottom: 5
        },
        tr: {
            margin: "auto",
            flexDirection: "row"
        },
        tc: {
            width: "20%",
            borderStyle: "solid",
            // borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        td: {
            margin: "auto",
            margin: '3 0',
            fontSize: 8
        }
    })
    //#endregion

    useEffect(() => {
        async function ftEffect() {
            try {
                //FetchTicket();
                let resultado = await queryTickets();
                setTV(resultado);

                let ff = { id: [], texto: [], pago: [] };

                ff.id = (resultado.formasCobro + "").split(',');
                ff.pago = (resultado.formasCobroCantidad + "").split(',');
                ff.texto = (resultado.formasCobroTexto + "").split(',');

                setFormasCobro(ff.texto);
                setFormasCobroCant(ff.pago);

                FetchListadoPagosClientes();
                FetchFechaPagosClientes();

            } catch({message}) {
              console.log('error'+message)
            }
        } ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            try {
                if (partidas.length !== 0 &&  tv.length !== 0) {
                    window.print();
                    window.close();
                    //estaba comentado
                }
            } catch({message}) {
              console.log('error'+message)
            }
        } ftEffect();
    }, [partidas]);


   useEffect(() => {
        async function ftEffect() {
            try {
                let tels = await queryTelefonos(tv.direccionId);
                setTelefonos(tels);
            } catch({message}) {
              console.log('error'+message)
            }
        } ftEffect();
    }, [tv]);

    let nuevoTotal = partidas ?.totalDeuda;

    const App = (
        <Document>
            <Page size={[232.44/*, 822.04*/]} style={styles.page}>

                <View style={styles.ticket}>
                    <View style={styles.logo}>
                        <Image src={rutaPublica + configuracion.urlLogotipo} style={styles.img} />
                    </View>

                    <View style={styles.centrado}>
                        <Text style={styles.titulo}>{configuracion.descripcionLogin}</Text>
                        <Text>{tv.rfc}</Text>
                    </View>

                    <Text style={[styles.centrado, styles.titulo]}>{tv.clinica}</Text>

                    <Text style={styles.tituloDocumento}>PAGO DE ABONO</Text>

                    {
                        tv.direccionId &&
                        <View style={[styles.centrado, { fontSize: 10 }]}>
                            <Text >
                                Expedido en:
                            </Text>

                            <Text >
                                {tv.calle}, {tv.colonia},
                            </Text>

                            <Text>
                                {tv.municipio}, {tv.estado}
                                {tv.codigoPostal !== "" ? `, CP. ${tv.codigoPostal}`
                                    : tv.codigoPostal}
                            </Text>

                            <Text >
                                {telefonos !== "" ? `Tels.: ${telefonos}` : ""}
                            </Text>
                        </View>
                    }

                    <View style={[styles.centrado, styles.contenedor]}>
                        <Text >
                            Fecha: {formatoFecha(tv.fechaEmision)}
                        </Text>
                        <Text>
                            Folio: {tv.serie}-{formatoFolio(tv.folio, 6)}
                        </Text>
                        <Text>
                            Cliente: {`${partidas.nombreCliente}`}
                        </Text>

                    </View>
                    {
                        tv.estatusHistorial = 1 ?  <Text></Text> :
                        <View render={() =>(
                            (partidas.partida ?.length > 0 ? partidas.partida[0].totalMenosCapital : 0) < 0.01 ?

                            <View> <Text> </Text></View>
                            :
                            <View style={[styles.centrado, styles.contenedor2]}>
                                <Text>
                                    Total para liquidar hoy: {`${formatoMoneda(partidas.partida ?.length > 0 ? partidas.partida[0].totalMenosCapital : 0)}`}
                                </Text>
                                <Text >
                                    {fechaProxPago ? `Próxima fecha de pago: ${fechaProxPago.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1")}` : ""}
                                </Text>
                            </View>

                        )}/>
                    }


                    <View style={[tablaStyles.table]}>

                        <View style={[tablaStyles.tr]}>
                            <View style={[tablaStyles.tc, { width: '23%', paddingLeft: 3 }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>DESCRIPCIÓN</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '12%', textAlign: "right", paddingRight: 2 }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>CANT.</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '12%', textAlign: "right", paddingRight: 5 }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}> SALDO</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '12%', textAlign: "right", paddingRight: 5 }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>ABONO</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '15%', textAlign: "right" }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>PENDIENTE</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '15%', textAlign: "right" }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>FECHA</Text>
                            </View>
                            <View style={[tablaStyles.tc, { width: '12%', textAlign: "right" }]}>
                                <Text style={[tablaStyles.td, { fontSize: 5 }]}>INTERES</Text>
                            </View>
                        </View>


                        <View render={() => {
                            nuevoTotal = partidas.totalDeuda;
                            return (
                                partidas.partida !== undefined ?
                                    partidas.partida.map((dato, index) => {
                                        nuevoTotal = nuevoTotal;
                                        let tmp = nuevoTotal - dato.totalPago;
                                        nuevoTotal = tmp;
                                        return (
                                            <View key={index} style={tablaStyles.tr}>
                                                <View style={[tablaStyles.tc, { width: '23%', paddingLeft: 3 }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        ABONO{` ${dato.numeroPago} DE ${partidas.descripcionCredito.substring(0, 2)}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '12%', textAlign: "right", paddingRight: 2 }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${formatoMoneda(dato.totalAPagar)}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '12%', textAlign: "right" }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${formatoMoneda(tmp + dato.totalPago)}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '12%', textAlign: "right" }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${formatoMoneda(dato.totalPago)}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '15%', textAlign: "right" }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${(dato.totalAPagarMenosParcial > 0 ? formatoMoneda(dato.totalAPagarMenosParcial) : formatoMoneda(0))}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '15%', textAlign: "right" }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${dato.fechaPagoFinalizado ? dato.fechaPagoFinalizado.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1") : ""}`}
                                                    </Text>
                                                </View>
                                                <View style={[tablaStyles.tc, { width: '12%', textAlign: "right", paddingRight: 3 }]}>
                                                    <Text style={tablaStyles.td, { fontSize: 5 }}>
                                                        {`${formatoMoneda(dato.fechaPagoFinalizado < dt && dato.estatusPartida !== 1 ? (dato.pagoAtrasado - dato.totalAPagar) : 0)}`}
                                                    </Text>
                                                </View>
                                            </View>
                                        )
                                        // `${dato.pagoAtrasadoMenosParcial !== null? formatoMoneda(dato.pagoAtrasadoMenosParcial) : ""}`

                                    })
                                    :
                                    <View style={[tablaStyles.tr, { columnSpan: 'all' }]}>
                                        <View style={tablaStyles.td}  >No se registró ninguna venta</View>
                                    </View>

                            )
                        }}>
                        </View>

                        <View style={[tablaStyles.tr, { width: '100%', borderTop: '1 solid black' }]}>
                        </View>

                    </View>

                    <View style={[styles.blockCentrado]}>
                        <View style={[tablaStyles.table, { width: 'auto', paddingRight: 3 }]}>
                            <View style={[tablaStyles.tr]}>
                                <Text style={[tablaStyles.td, { textAlign: 'right', width: '80%' }]}>PAGASTE:</Text>
                                <Text style={[tablaStyles.td, { textAlign: 'right', width: '20%' }]}>
                                    {formatoMoneda(tv.importePago)}
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View style={{ width: '100%', borderTop: '1 solid black' }} />

                    <Text style={{ textAlign: 'center' }}>Su pago se realizó en:</Text>
                    <View style={[styles.blockCentrado]}>
                        <View style={[tablaStyles.table, { width: '100%', paddingRight: 3 }]} render={() => (
                            formasCobro && formasCobro.map((forma, i) => {
                                return (
                                    <View key={i} style={[tablaStyles.tr]}>
                                        <View style={[tablaStyles.tc, , { width: '80%' }]}>
                                            <Text style={[tablaStyles.td, { textAlign: 'right' }]} >
                                                {forma !== "" ? forma + ":" : "-"}
                                            </Text>
                                        </View>
                                        <View style={[tablaStyles.tc, { width: '20%' }]}>
                                            <Text style={[tablaStyles.td, { textAlign: 'right' }]}>
                                                {formatoMoneda(formasCobroCantidad[i] || 0)}
                                            </Text>
                                        </View>
                                    </View>
                                )
                            })
                        )}>
                        </View>
                    </View>

                    <View style={{ width: '100%', borderTop: '1 solid black' }} />
                    {/* <Text style={{ textAlign: 'center' }}>Su pago se realizó en:</Text> */}
                    <View style={[styles.blockCentrado]}>
                    <View style={[tablaStyles.table, { width: '100%', paddingRight: 3 }]}
                    render={() => (
                        Number(tv.cambio ? tv.cambio.toFixed(2) : 0) > 0 &&
                        <View style={[tablaStyles.table]}>

                            <View style={[tablaStyles.tr]}>

                                <View style={[tablaStyles.tc,  { width: '80%' }]}>
                                    <Text style={[tablaStyles.td, { textAlign: 'right' }]}>CAMBIO:</Text>
                                </View>

                                <View style={[tablaStyles.tc,  { width: '20%' }]}>
                                    <Text style={[tablaStyles.td, { textAlign: 'right' }]}>{formatoMoneda(tv.cambio)}</Text>
                                </View>

                            </View>

                            <View style={tablaStyles.tr} render={() => (
                                tv.metodoPago ?
                                    <View>
                                        <View style={tablaStyles.tc}>
                                            <Text style={tablaStyles.td}> METODO DE PAGO:</Text>
                                        </View>

                                        <View style={tablaStyles.tc}>
                                            <Text style={tablaStyles.td}>{tv.metodoPago}</Text>
                                        </View>
                                    </View>
                                    :
                                    null
                            )}>
                            </View>
                        </View>
                    )}>
                    </View>

                    </View>

                    <View style={styles.centrado}>

                        <View style={{ marginTop: 5 }} />

                        <Text>¡GRACIAS POR PAGAR!</Text>

                        <Text className="centrado">
                            Le atendió: {tv.vendedor}
                        </Text>

                        <View style={{ marginTop: 5 }} />

                        <View style={{ marginBottom: 5 }}>
                            <Text className="centrado">Su pago se aplicará a los pagos con más atraso.</Text>
                        </View>
                    </View>


                </View>

            </Page>
        </Document>
    )




    return (
        <div className="contenedor2" >

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} onAccept={mensaje.onAccept} />

            <div className="cargando2">
                <h2 className="generando2">Enviando ticket</h2>
                <img src="/iconos/engrane.png" className="carga" alt="Cargando" /*onLoad={() => { setImgLoaded(true) }}*/ />
            </div>

            <div className="ticket2" /*style={{ width: '266px' }}*/>
                <div className="logo">
                    <img src={rutaPublica + configuracion.urlLogotipo} alt="Logotipo" />
                </div>

                <p className="centrado">
                    <span className="titulo">{configuracion.descripcionLogin}</span><br />
                    {tv.rfc}
                </p>

                <p className="centrado titulo">{tv.clinica}</p>

                <p className="centrado">PAGO DE ABONO</p>

                {
                    tv.direccionId &&
                    <p className="centrado">
                        Expedido en:<br />
                        {tv.calle}, {tv.colonia}, <br /> {tv.municipio}, {tv.estado}
                        {tv.codigoPostal !== "" ? `, CP. ${tv.codigoPostal}` : tv.codigoPostal} <br />
                        {telefonos && `Tels: ${telefonos}`}
                    </p>
                }

                {/* <p className="centrado">
                    {tv.doctorId == -1 ? '' : `Dr. ${tv.doctor}`}
                </p> */}

                <p className="centrado contenedor">
                    Fecha: {formatoFecha(tv.fechaEmision)}
                    <br />Folio: {tv.serie}-{formatoFolio(tv.folio, 6)}
                    <br /> Cliente: {`${partidas.nombreCliente}`}
                    {/* {tv.numeroPago &&
                        <>
                            <br />
                            Pago {tv.numeroPago} de {tv.numeroTotalPagos}
                        </>
                    } */}
                </p>
                {
                    (partidas.partida ?.length > 0 ? partidas.partida[0].totalMenosCapital : 0) < 0.01 ?
                        <div> </div>
                        :
                        <p className="centrado contenedor">
                            Total para liquidar hoy: {`${formatoMoneda(partidas.partida ?.length > 0 ? partidas.partida[0].totalMenosCapital : 0)}`}
                            <br />{fechaProxPago ? `Próxima fecha de pago: ${fechaProxPago.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1")}` : ""}

                        </p>
                }


                <table className="tablaproductos">
                    <thead>
                        <tr>
                            <th className="espacio" style={{fontSize:7}}></th>
                            <th className="descripcion" style={{fontSize:7}}>DESCRIPCIÓN</th>
                            <th className="cant" style={{fontSize:7}}>CANT.</th>
                            <th className="saldo" style={{fontSize:7}}>SALDO</th>
                            <th className="abono" style={{fontSize:7}}>ABONO</th>
                            <th className="pendiente" style={{fontSize:7}}>PENDIENTE</th>
                            <th className="fecha" style={{fontSize:7}}>FECHA</th>
                            <th className="interes" style={{fontSize:7}}>INTERES</th>
                            <th className="espacio" style={{fontSize:7}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            partidas.partida !== undefined ?
                                partidas.partida.map((dato, index) => {
                                    let tmp = nuevoTotal;
                                    nuevoTotal = nuevoTotal - dato.totalPago
                                    return (
                                        <tr key={index}>
                                            <td className="espacio"></td>
                                            <td className="descripcion" style={{fontSize:8}}>ABONO{` ${dato.numeroPago} DE ${partidas.descripcionCredito.substring(0, 2)}`}</td>

                                            <td className="cant" style={{fontSize:8}}>{`${formatoMoneda(dato.totalAPagar)}`}</td>

                                            <td className="saldo" style={{fontSize:8}}> {`${formatoMoneda(tmp)}`}</td>

                                            <td className="abono" style={{fontSize:8}}>{`${formatoMoneda(dato.totalPago)}`}</td>

                                            <td className="pendiente" style={{fontSize:8}}>{`${(dato.totalAPagarMenosParcial > 0 ? formatoMoneda(dato.totalAPagarMenosParcial) : formatoMoneda(0))}`}</td>

                                            <td className="fecha" style={{fontSize:8}}> {`${dato.fechaPagoFinalizado ? dato.fechaPagoFinalizado.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1") : ""}`}</td>

                                            <td className="interes" style={{fontSize:8}}> {`${formatoMoneda(dato.fechaPagoFinalizado < dt && dato.estatusPartida !== 1 ? (dato.pagoAtrasado - dato.totalAPagar) : 0)}`}</td>

                                            <td className="espacio"></td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={8} className="producto">No se registró ninguna venta</td></tr>
                        }
                        <tr>
                            <td colSpan={8}><hr /></td>
                        </tr>
                    </tbody>
                </table>



                <div className="totales">
                    <table className="tablaTotal">
                        <tbody>
                            <tr>
                                <td colSpan={4} className="izquierda">
                                    PAGASTE:
                                </td>
                                <td colSpan={1} className="totaltxt">
                                    {formatoMoneda(tv.totalTicket)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr style={{ width: '100%' }} />

                <div className="ahorro">Su pago se realizó en:</div>
                <div className="totales">
                    <table className="tablaTotal">
                        <tbody>
                            {
                                formasCobro && formasCobro.map((forma, i) => {
                                    return <tr key={i}>
                                        <td colSpan={4} className="izquierda">
                                            {forma !== "" ? forma + ":" : ""}
                                        </td>
                                        <td colSpan={1} className="totaltxt">
                                            {formatoMoneda(formasCobroCantidad[i] || 0)}
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <hr style={{ width: '100%' }} />
                {
                    Number(tv.cambio ? tv.cambio.toFixed(2) : 0) > 0 &&
                    <div className="totales">
                        <table className="tablaTotal">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="izquierda">
                                        CAMBIO:
                                    </td>
                                    <td colSpan={1} className="derecha">
                                        {formatoMoneda(tv.cambio)}
                                    </td>
                                </tr>

                                {tv.metodoPago ?
                                    <tr>
                                        <td colSpan={4} className="izquierda">
                                            METODO DE PAGO:
                                        </td>
                                        <td colSpan={1} className="derecha">
                                            {tv.metodoPago}
                                        </td>
                                    </tr>
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    </div>
                }

                {/* {
                    tv.descuentoTotalTicket >= 1 &&
                    <>
                        <br />
                        <div className="ahorro">USTED AHORRA: {formatoMoneda(tv.descuentoTotalTicket)}</div>
                    </>
                } */}

                <br />
                <p className="centrado">¡GRACIAS POR PAGAR!</p>

                <p className="centrado">
                    Le atendió: {tv.vendedor}
                </p>
                <br />

                <div>
                    <p className="centrado">Su pago se aplicará a los pagos con más atraso.</p>
                </div>
                {
                    // garantias.length > 0 &&
                    // <div>
                    //     <p className="centrado">Garantías:</p>
                    //     <table className="garantias">
                    //         {garantias}
                    //     </table>

                    // </div>
                }
                <br />
            </div>


            {

                tv.ticketId
                &&
                <div className="noImprimir">
                    {/* <PDFViewer height={1000} width={1000}>
                        {App}
                    </PDFViewer> */}
                    <BlobProvider document={App}>
                        {({ blob, url, loading, error }) => {
                            if (blob && mensaje.texto === '') {
                                enviarPdf(blob);
                            }

                            return <div></div>
                        }}
                    </BlobProvider>
                </div>
            }
        </div >
    )
}

export default ToPDF

