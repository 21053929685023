import React from 'react'
import TablePagination from '@material-ui/core/TablePagination';

function Paginacion({datos=[], filasPagina, pagina, ftPaginaChange, ftFilasPaginaChange,clasePaginacion="",estiloPaginacion={}}) {
    return (
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={datos.length}
            rowsPerPage={filasPagina}
            page={pagina}
            onChangePage={ftPaginaChange}
            onChangeRowsPerPage={ftFilasPaginaChange}
            className={clasePaginacion}

            labelRowsPerPage="Filas por página:"
            labelDisplayedRows={({ from, to, count }) => `${from}-${to === -1 ? count : to} de ${count}`}
            backIconButtonText="Página anterior"
            nextIconButtonText="Página siguiente"

            style={estiloPaginacion}
        />
    )
}

export default Paginacion
