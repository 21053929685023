import gql from 'graphql-tag';

export const FILTRO_RECETAS_PARTIDAS = gql `
query FiltroPartidas($limite: Int, $pagina: Int, $recetaId: Int, $productoId: Int)
{
    filtroPartidas(limite: $limite, pagina: $pagina, recetaId: $recetaId,
        productoId: $productoId){
        recetaId
        agendaId
        tituloCita
        descipcionCita
        servicioId
        servicioAgenda
        clinicaDoctoId
        clinicaId
        doctorId
        pacienteId
        nombrePaciente
        apellidoPaciente
        sexoPAciente
        emailPaciente
        telefonoPaciente
        descripcionReceta
        nombreDoctor
        apellidoDoctor
        emailDoctor
        cedulaDoctor
        especialidadDoctorId
        especialidadDoctor
        telefonoDoctor
        nombreClinica
        fechaReceta

        partidas{
            partidaId
            recetaId
            productoId
            descripcionProducto
            codigoProducto
            codigoBarras
            productoNoenVenta
            anotacionesProducto
            cantidad
            fechaReceta
            resultible
        }

    }
}`;

export const FILTRO_RECETAS_PARTIDAS_FETCH =  `
query FiltroPartidas($limite: Int, $pagina: Int, $recetaId: Int, $productoId: Int)
{
    filtroPartidas(limite: $limite, pagina: $pagina, recetaId: $recetaId,
        productoId: $productoId){
        recetaId
        agendaId
        tituloCita
        descipcionCita
        servicioId
        servicioAgenda
        clinicaDoctoId
        clinicaId
        doctorId
        pacienteId
        nombrePaciente
        apellidoPaciente
        sexoPAciente
        emailPaciente
        telefonoPaciente
        descripcionReceta
        nombreDoctor
        apellidoDoctor
        emailDoctor
        cedulaDoctor
        especialidadDoctorId
        especialidadDoctor
        telefonoDoctor
        nombreClinica
        fechaReceta

        partidas{
            partidaId
            recetaId
            productoId
            descripcionProducto
            codigoProducto
            codigoBarras
            productoNoenVenta
            anotacionesProducto
            cantidad
            fechaReceta
            resultible
        }

    }
}`;
