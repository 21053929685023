import React, { useState } from 'react';
import {obtenerStateStorage} from '../../Helpers/LocalStorage/LocalStorage';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [asignacion, guardarAsignacion] = useState({
        Serie: {
            serieId: { error: false, valor: "" },
            serieNombre:{ error: false, valor: "" },
            tipoDocumentoId: { error: false, valor: "" },
            nombreDocumento: { error: false, valor: "" },
            estatusSerie: { error: false, valor: "" },
            porDefecto: { error: false, valor: "" },
            clinicaId: { error: false, valor: "" },
            nombreClinica:{ error: false, valor: "" },
            aliasClinica:{ error: false, valor: "" },
            estatusClinica:{ error: false, valor: "" },
            token: { error: false, valor: obtenerStateStorage("user").user.usuario[0].user.user.token }
        }
    });
    return (
        <CRMContext.Provider value={[asignacion, guardarAsignacion]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
