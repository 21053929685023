import {AGREGAR_CONVENIO}from '../Types';

const initialState={
    convenioCrud: [{ activo: false }]
}

export default function(state=initialState,action){
    switch(action.type){
        case AGREGAR_CONVENIO:
            return {
                ...state,
                convenioCrud:[action.payload]
            }
        default:
            return state;
    }
}
