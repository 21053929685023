import React, { useState, useEffect, useRef } from 'react'

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

import { withRouter, Redirect } from "react-router-dom";

//Estilos
import { makeStyles } from '@material-ui/core/styles';

//Rgx
import { rgxDecimalesPV } from '../../Helpers/constantes/Rgx';

//Componentes
import { Grid, InputGrid, Button, Checkbox, FormControlLabel, TextField, List, ListItem, ListItemText, Divider, Typography, Chip, Input, FormControl, InputLabel, InputAdornment, CircularProgress } from '../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tabla from '../../componentesGenericos/Tabla';
import Formulario from '../../componentesGenericos/FormularioAntiguo';
import Mensaje from '../../componentesGenericos/Mensaje';
import MenuLateral from '../../componentesGenericos/SideBar';
import EscanerQr from '../../componentesGenericos/EscanerQr';
import FechaHora from './FechaHora';
import SerieFolio from './SerieFolio';
import Doctores from './Componentes/Doctores';
import EscanerReceta from './Componentes/Receta';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
import { Fab, Popover, Badge } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';

import ListItemIcon from '@material-ui/core/ListItemIcon';
//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';

//TODO Helpers
import { formatoMoneda } from '../../Helpers/constantes/formatos';
import { getUsuario } from '../../Helpers/Usuario';

//Funciones
import { handleProductoChange, handleClienteChange, handleCantidadChange, handleAlmacenChange, ftRecalculo } from './Funciones/handlers';
import { TicketTemporal } from './TicketTemporal';
import { selectCreditoAplicableCliente } from '../PlanesCredito/Metodos/index';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';

//TODO queries
import { LISTADO_PRODUCTOS_SERVICIOS, OBTENER_EXISTENCIA, LISTADO_PACIENTE, LISTADO_TURNOS, OBTENER_SERIE, LISTAR_FORMA_COBRO, LISTADO_FOLIOS, LISTADO_CLINICA_MATRIZ, FILTRO_DIRECCIONES, DESCUENTOS_CONVENIOS, OBTENER_SERVICIOS_DOCTORES, LISTADO_TICKET_TEMPORAL, LISTADO_COMISIONES, LISTADO_PLANES_CREDITO, OBTENER_EXISTENCIA_FETCH, LISTADO_COLA_TICKET } from '../../query';

//TODO mutation
import { AGREGAR_TICKET, GUARDAR_TICKET_TEMPORAL } from '../../mutation';
import { queryValidarSeguridadPuntoVenta, queryConfiguracionPuntoVenta } from '../Configuracion/Metodos';
//Iconos
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
// import PrintIcon from '@material-ui/icons/Print';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

//estilos
import './estilos.css';

//FETCH
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";

//Formato Folio
// import { formatoFolio } from '../../Helpers/constantes/formatos';

function PuntoVenta(props) {
    const classes = useStyles();
    let stateFijo = props ? props.location ? props.location.state : {} : {};
    const [referido, setReferido] = useState(props ? props.location ? props.location.state ? props.location.state.servicioId ? props.location.state.servicioId[0] !== "undefined" ? true : false : false : false : false : false);

    // if (stateFijo) {
    //     if (stateFijo.servicioId) {
    //         if (stateFijo.servicioId[0]) {
    //             if (stateFijo.servicioId[0] === "undefined") {
    //                 stateFijo = {};
    //             } else {
    //                 stateFijo.servicioId = stateFijo?.servicioId ? (stateFijo?.servicioId + '').split(',') : null;
    //             }
    //         } else {
    //             stateFijo.servicioId = stateFijo?.servicioId ? (stateFijo?.servicioId + '').split(',') : null;
    //         }
    //     } else {
    //         stateFijo.servicioId = stateFijo?.servicioId ? (stateFijo?.servicioId + '').split(',') : null;
    //     }
    // }


    const [state, setState] = useState(stateFijo);
    const [refs, setRef] = useState({
        cliente: useRef(),
        producto: useRef(),
        cantidad: useRef(),
        add: useRef(),
        selected: null
    });
    const inputRef = useRef();
    let rgxNumeros = new RegExp(rgxDecimalesPV);
    const pwdAdminRef = useRef();

    //#region Redux
    //Clínica en la que esta situado el punto de venta
    let _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    _clinicaID = stateFijo ? stateFijo.clinicaId ? stateFijo.clinicaId : _clinicaID : _clinicaID;
    let nombreClinica = useSelector(state => state.selecciondeClinica.seleccionClinica[0].nombre);
    nombreClinica = stateFijo ? stateFijo.clinicaId ? stateFijo.nombreClinica ? stateFijo.nombreClinica : nombreClinica : nombreClinica : nombreClinica;

    // Configuración
    const configuracion = useSelector(state => state.configuracion);

    //Token de inicio de sesión
    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    //Decodificación de token para obtener datos del usuario
    let userToken = DecodificarToken(userState);
    //Turno seleccionado desde Redux
    const turnoState = useSelector(state => state.seleccionTurnos.seleccionTurno[0]);
    //Obtener la descripcion del turno
    let Redireccion = <Redirect to={{ pathname: "/loginpuntoventa", state: { ...props.location.state } }} />
    let turno = "";
    let { history } = props;
    //#endregion

    if (_clinicaID === 0) {
        history.push("/loginpuntoventa");
    }

    //#region states
    const seleccionInicial = {
        //Fijos
        no: 1,
        servicioId: null,
        descripcion: null,
        codigoBarras: null,
        codigoProducto: null,
        disponible: null,
        agendable: null,
        inventariable: null,
        comision: null,
        relacionId: null,
        tipo: null,

        cantidad: 1,

        //-----------------------------------------
        porcentajeDescuentoConvenio: 0,
        porcentajeDescuento: 0,
        porcentajeIva: 0,
        tasaIva: null,
        tipoIva: null,
        descripcionTipoIva: null,
        //-----------------------------------------

        // TODO seleccion
        //unitarios
        precioUnitario: 0, // precioUnitario val
        precioUnitarioDescuento: 0, //(p unitario - descuentoUnitario) precioUnitarioDescuento val
        precioUnitarioDescuentoConvenio: 0, //precioUnitario - descuentoUnitarioTotal

        descuentoUnitario: 0, // precioUnitario * %descuento (descuentoTotal val)
        descuentoConvenioUnitario: 0, // precioUnitarioDescuento * %convenio
        descuentoUnitarioTotal: 0, //descuentoUnitario + descuentoConvenioUnitario (descuento + convenio)

        ivaUnitario: 0, //iva del precio unitario (iva val)
        ivaUnitarioDescuento: 0, //iva del precioUnitarioDescuento (ivaDescuento val)
        ivaUnitarioTotal: 0, //precioUnitarioDescuentoConvenio * %iva

        precioUnitarioTotal: 0, //precioUnitarioDescuentoConvenio + ivaUnitarioTotal

        // por cantidad
        descuentoCantidad: 0, //descuentoUnitario * cantidad
        descuentoConvenioCantidad: 0, //descuentoConvenioUnitario * cantidad
        precioDescuentoCantidad: 0, //precioUnitarioDescuento * cantidad;
        precioDescuentoConvenioCantidad: 0, //precioDescuentoConvenio * Cantidad

        //Totales
        descuentoTotal: 0, //descuentoUnitarioTotal * cantidad
        ivaTotal: 0, //ivaUnitarioTotal * cantidad
        precioTotal: 0, //precioUnitarioTotal * cantidad

        //----------------------------------------

        clinicaId: null,
        especialidadId: null,
        nombreClinica,
        descripcionEspecialidad: "",
        servicioClinica: null,
        almacenId: -1,
        centroCostosId: null,
        doctorId: -1,
        garantia: null,
        garantiaId: null,
        cambiarTotal: 0,

        cantidadPago: 0,
        clienteId: { nombres: 'Publico general', apellidos: "", id: -1 },
        formaCobro: null,
    };
    const [seleccion, setSeleccion] = useState(seleccionInicial);

    const ticketInicial = {
        // TODO ticket
        servicioId: state ? state.servicioId ? state.servicioId.split(',') : [] : [],
        codProd: [],
        descripcion: [],
        cantidad: [],
        comision: [],

        //#region porcentajes
        porcentajeDescuentoConvenio: [],
        porcentajeDescuento: [],
        porcentajeIva: [],
        //#endregion

        //#region datos producto
        //unitarios
        precioUnitario: [], // precioUnitario val
        precioUnitarioDescuento: [], //(p unitario - descuentoUnitario) precioUnitarioDescuento val
        precioUnitarioDescuentoConvenio: [], //precioUnitario - descuentoUnitarioTotal

        descuentoUnitario: [], // precioUnitario * %descuento (descuentoTotal val)
        descuentoConvenioUnitario: [], // precioUnitarioDescuento * %convenio
        descuentoUnitarioTotal: [], //descuentoUnitario + descuentoConvenioUnitario (descuento + convenio)

        ivaUnitario: [], //iva del precio unitario (iva val)
        ivaUnitarioDescuento: [], //iva del precioUnitarioDescuento (ivaDescuento val)
        ivaUnitarioTotal: [], //precioUnitarioDescuentoConvenio * %iva

        precioUnitarioTotal: [], //precioUnitarioDescuentoConvenio + ivaUnitarioTotal

        // por cantidad
        descuentoCantidad: [], //descuentoUnitario * cantidad
        descuentoConvenioCantidad: [], //descuentoConvenioUnitario * cantidad
        precioDescuentoCantidad: [], //precioUnitarioDescuento * cantidad;
        precioDescuentoConvenioCantidad: [], //precioDescuentoConvenio * Cantidad

        //Totales
        descuentoTotal: [], //descuentoUnitarioTotal * cantidad
        ivaTotal: [], //ivaUnitarioTotal * cantidad
        precioTotal: [], //precioUnitarioTotal * cantidad
        //#endregion Datos producto

        //#region garantia
        garantia: [],
        garantiaId: [],
        //#endregion

        //#region clinica
        empresaId: null,
        clinicaId: _clinicaID ? Number(_clinicaID) : null,
        nombreClinica,
        almacenId: [],
        centroCostosId: [],
        direccionId: null,
        //#endregion

        //#region personas
        vendedorId: userToken.usuarioId,
        clienteId: state ? state.pacienteId ? Number(state.pacienteId) : -1 : -1,
        doctorId: state ? state.doctorId ? Number(state.doctorId) : -1 : -1,
        //#endregion

        //#region formas cobro
        formasCobro: [],
        formasCobroCantidad: [],
        formasCobroTexto: [],
        metodoPago: 'PUE',
        clavePago: null, //string
        //#endregion

        //#region datos ticket
        serieId: -1,
        serie: null,
        folioId: -1,
        folio: null,
        turnoId: null,
        turno: null,

        conceptoId: 24, //obtener de la tabla tblConcepto inventario
        tipoDocumentoId: 4, //4 = ticket tbltipodocumento
        //#endregion

        //#region Finales
        importePago: 0,
        cambio: 0,
        descuentoTotalTicket: 0,
        descuentoTotalConvenioTicket: 0,
        descuentoTotalFinalTicket: 0,
        subtotalTicket: 0,
        ivaTotalTicket: 0,
        totalTicket: 0, //sumatorias de precioTotal
        //#endregion


        //#region direccion
        calle: null,
        codigoPostal: null,
        colonia: null,
        estado: null,
        localidad: null,
        municipio: null,
        //#endregion

        //#region plan credito
        plazoPago: null,
        diasPagar: null,
        numeroPlan: null,
        //#endregion

        referencia: null
        //===========================================
    };

    const [killSession, setKillSession] = useState(false);
    const [tmpId, setTmpId] = useState(null);
    const [ticket, setTicket] = useState(ticketInicial);
    const [pagocon, setPagoCon] = useState(0);
    const [cambio, setCambio] = useState(0);
    const [iva, setIva] = useState(0);
    const [subtotal, setSubTotal] = useState(0.00);
    const [total, setTotal] = useState(0.00);
    const [datos, setDatos] = useState([]);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [QR, setQR] = useState({
        abrir: false,
        tipo: "mensaje",
        texto: "",
        codigo: null,
        onAccept: (codigo) => {
            setQR({ ...QR, abrir: false, codigo });
        },
        onCancel: () => {
            setQR({ ...QR, abrir: false });
        }
    });

    const [receta, setReceta] = useState({
        abrir: false,
        tipo: "mensaje",
        texto: "",
        codigo: null,
        onAccept: (codigo) => {
            setReceta({ ...receta, abrir: false, codigo });
        },
        onCancel: () => {
            setReceta({ ...QR, abrir: false });
        }
    });
    const [opcionesAlmacen, setOpcionesAlmacen] = useState([{ label: "Sin almacén", value: -1 }]);
    const [opcionesCentroCostos, setOpcionesCentroCostos] = useState([{ label: "Sin centro de costos", value: -1 }]);
    const [descConvenio, setDescConvenio] = useState({});
    const [formasPago, setFormasPagos] = useState([]);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [ticketTmpState, setTicketTmpState] = useState(null);
    const [doctorLoading, setDoctorLoading] = useState(false);
    const [pendienteCobrar, setPendienteCobrar] = useState(false);
    const [comisiones, setComisiones] = useState([]);
    const [listaPlanCredito, setlistaPlanCredito] = useState([]);
    const [clienteMoroso, setClienteMoroso] = useState(false)
    const [pwdAdminActiva, setPwdAdminActiva] = useState(null);
    const [process1, setProcess] = useState(null);
    //#endregion

    //#region querys
    const { data: dturnos } = useQuery(LISTADO_TURNOS, {
        variables: { offset: 0, usuarioId: userToken.usuarioId, clinicaId: Number(_clinicaID) },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            if (ticket.turno === null && dturnos) {
                let tmpturn = dturnos.listadoTurnos.turnos.find(turn => {
                    if (turn.turnoId === turnoState + '') {
                        return true;
                    }
                    else
                        return false;
                });

                if (tmpturn) {
                    setTicket(ticket => ({ ...ticket, turno: tmpturn.descripcion || null, turnoId: Number(tmpturn.turnoId) || -2 }));
                } else {
                    setKillSession(true);
                }
            }
        },
        onError: () => {
            if (turnoState === '' && userToken.tipo === 3) {
                setTicket(ticket => ({ ...ticket, turno: 'Administrador', turnoId: null }));
            } else {
                if (turnoState === -1) {
                    setTicket(ticket => ({ ...ticket, turno: 'Administrador', turnoId: null }));
                }
            }
        }
    });

    const { loading: loadingTemp, data: dtemporal } = useQuery(LISTADO_TICKET_TEMPORAL, {
        variables: { clinicaId: Number(_clinicaID), vendedorId: userToken.usuarioId },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            if (!referido) {
                let ticketTmp = TicketTemporal(dtemporal.listadoTicketTemporal.tickets);
                setTicketTmpState({ ...ticketTmp });
            }
        },
        onError: (e) => {
            console.log("error al cargar datos temporales.", e.message)
        }
    });

    const { data: colaTickets } = useQuery(LISTADO_COLA_TICKET, {
        variables: { clinicaId: Number(_clinicaID) },
        fetchPolicy: 'no-cache',
        pollInterval: 3000,
        onCompleted: () => {
        },
        onError: (e) => {
            console.log("Error temporal: ", e.message)
        }
    });

    const { data: dproductos } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        fetchPolicy: 'no-cache',
        variables: { offset: 0, tipo: 4, disponible: 1, clinica: Number(_clinicaID) },
        onCompleted: () => {
        }
    });

    //TODO productos doctor
    const { loading: loadServDoc, data: dproductosDoctor } = useQuery(OBTENER_SERVICIOS_DOCTORES, {
        variables: { id: Number(ticket.doctorId) },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
        }, onError: (e) => {
            console.log(e.message.replace("GraphQL error:", ""));
        }
    });

    const { data: ListadoComisiones } = useQuery(LISTADO_COMISIONES, {
        variables: { tipo: 2, offset: 0, clinicaId: Number(_clinicaID), profesionalId: Number(ticket.doctorId), estatus: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setComisiones([]);
        },
        onCompleted: () => {
            let comis = ListadoComisiones.listadoComisiones.comisiones.map(com =>
                ({ porcentajeComision: com.porcentajeComision, productosIdComision: com.productosIdComision.split(',') })
            )
            setComisiones(comis)
        }
    });

    //TODO exist
    const { loading: loadingAlm, data: almacenes } = useQuery(OBTENER_EXISTENCIA, {
        fetchPolicy: 'no-cache',
        variables: { id: null, servicio: Number(seleccion.servicioId || -1), clinica: Number(_clinicaID) },
        onError: (e) => {
            setOpcionesAlmacen([{ label: "Sin almacén", value: -1 }]);
            setSeleccion({ ...seleccion, almacenId: -1 });
        },
        onCompleted: () => {
            let apto = -1;
            let aptoCentro = -1;
            let sinCentro = false;
            let idsAlma = [];
            let idsCentro = [];

            let opcionesAlma = [];
            let opcionesCentro = [];

            almacenes.obtenerExistencia.Existencias.map(alm => {
                if (apto === -1 && alm.existenciaActual > 0) {
                    apto = alm.almacenId;
                }

                if (aptoCentro === -1 && alm.existenciaActual > 0 && alm.centroCostosId !== null) {
                    aptoCentro = alm.centroCostosId;
                }

                if (sinCentro === false && alm.centroCostosId === null) {
                    sinCentro = true;
                }

                if (!idsAlma.includes(alm.almacenId)) {
                    idsAlma.push(alm.almacenId);
                    opcionesAlma.push({ label: alm.nombreAlmacen, value: alm.almacenId, color: alm.existenciaActual > 0 || alm.centroCostosId !== null ? "#000" : "#e12" })
                }

                if (!idsCentro.includes(alm.centroCostosId) && alm.centroCostosId !== null) {
                    idsCentro.push(alm.centroCostosId);
                    opcionesCentro.push({ label: alm.centroCostos, value: alm.centroCostosId, color: alm.existenciaActual > 0 ? "#000" : "#e12" })
                }
            });

            if (sinCentro === true) opcionesCentro.unshift({ label: "Sin centro de costos", value: -1 });

            apto = opcionesAlma ? opcionesAlma.length > 0 ? apto !== -1 ? apto : opcionesAlma[0].value : -1 : -1;
            setSeleccion({ ...seleccion, almacenId: apto, centroCostosId: aptoCentro });
            setOpcionesAlmacen(opcionesAlma);
            setOpcionesCentroCostos(opcionesCentro);
        }
    });

    const { data: dcliente } = useQuery(LISTADO_PACIENTE, {
        fetchPolicy: 'no-cache',
        variables: { offset: 0, tipo: 1, moroso: null },
    });

    const { data: dserie } = useQuery(OBTENER_SERIE, {
        pollInterval: 5000,
        fetchPolicy: 'no-cache',
        variables: { clinicaId: Number(_clinicaID) },
        onCompleted: () => {
            if (dserie) {
                setTicket(ticket => ({ ...ticket, serieId: Number(dserie.obtenerSerie.serieId), serie: dserie.obtenerSerie.serieNombre }));
            }
        }
    });

    const { data: folios, refetch: refetchFolio } = useQuery(LISTADO_FOLIOS, {
        pollInterval: 1000,
        fetchPolicy: 'no-cache',
        variables: { clinicaId: Number(_clinicaID) },
        onCompleted: () => {
            setTicket(ticket => ({ ...ticket, folioId: Number(folios.listadoFolios.folios[0].folioId), folio: folios.listadoFolios.folios[0].consecutivo + '' }))
        },
        onError: () => {
            setTicket(ticket => ({ ...ticket, folioId: -1, folio: "No asignado" }))
        }
    });


    const { data: formasCobro } = useQuery(LISTAR_FORMA_COBRO, {
        fetchPolicy: 'no-cache',
        variables: { clinicaId: Number(_clinicaID) },
    });

    const { data: matriz } = useQuery(LISTADO_CLINICA_MATRIZ, {
        fetchPolicy: 'no-cache',
        variables: { limite: 1, offset: 0, matriz: true },
        onCompleted: () => {
            setTicket(ticket => ({ ...ticket, empresaId: matriz ? Number(matriz.listadoClinicas.clinicas[0].clinicaId) : -1 }))
        }
    });

    const { data: direcciones } = useQuery(FILTRO_DIRECCIONES, {
        pollInterval: 3000,
        fetchPolicy: 'no-cache',
        variables: { limite: 1000, offset: 0, clinicaId: Number(_clinicaID || -1), direccionId: null },
        onCompleted: () => {
            let direccion = direcciones.listadoDirecciones.direcciones.find(dir => dir.estatus === true);

            if (!direccion) {
                setMensaje({ ...mensaje, ...mensajeError, texto: "La sucursal no cuenta con una dirección activa" });
            }

            setTicket(ticket => ({
                ...ticket,
                direccionId: direccion ? Number(direccion.direccionId) : -1,
                calle: direccion ? direccion.calle : '',
                codigoPostal: direccion ? direccion.codigoPostal : '',
                colonia: direccion ? direccion.colonia : '',
                estado: direccion ? direccion.estado : '',
                localidad: direccion ? direccion.localidad : '',
                municipio: direccion ? direccion.municipio : ''
            }))
        },
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Ocurrió un error al obtener la dirección de la sucursal. " + e.message });
        }
    });

    const { data: descuentosConvenio } = useQuery(DESCUENTOS_CONVENIOS, {
        fetchPolicy: 'no-cache',
        variables: { convenio: Number(QR.codigo || -1), clinica: Number(_clinicaID || -1) },
        onCompleted: () => {
            let descConv = {};
            descuentosConvenio.ObtenerDescuentos.DescuentosConvenio.map(conv => {
                descConv[conv.servicioId] = conv.porcDescuento;
            });
            setDescConvenio(descConv);

            //TODO descuento convenio
            handleProductoChange(seleccion, seleccionInicial, setSeleccion, descConv);
            recalculoConvenioTabla(descConv);
        },
        onError: (e) => {
            console.log(e.message.replace("GraphQL error:", ""))
        }
    });

    const { data: planesCredito } = useQuery(LISTADO_PLANES_CREDITO, {
        fetchPolicy: 'no-cache',
        variables: { cliente: Number(ticket.clienteId) },
        onError: (e) => {
            console.log(e.message.replace("GraphQL error:", ""))
        }
    });
    //#endregion

    //#region mutations
    const [addTicket] = useMutation(AGREGAR_TICKET, {
        onError: (e) => {
            setDisabledAccept(false);
            console.log(e.message.replace("GraphQL error:", ""))
            // setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setDisabledAccept(false);

            refetchFolio().then(({ data: fol }) => {
                setTicket(ticket => ({
                    ...ticketInicial,
                    direccionId: ticket.direccionId,
                    empresaId: ticket.empresaId,
                    folioId: Number(fol.listadoFolios.folios[0].folioId),
                    folio: fol.listadoFolios.folios[0].consecutivo + '',

                    //#region clinica
                    clinicaId: _clinicaID ? Number(_clinicaID) : null,
                    nombreClinica: ticket.nombreClinica,
                    //#endregion

                    //#region personas
                    vendedorId: userToken.usuarioId,
                    //#endregion

                    //#region datos ticket
                    serieId: ticket.serieId,
                    serie: ticket.serie,
                    // folioId: -1,
                    // folio: null,
                    turnoId: ticket.turnoId,
                    turno: ticket.turno,
                    //#endregion


                    //#region direccion
                    calle: ticket.calle,
                    codigoPostal: ticket.codigoPostal,
                    colonia: ticket.colonia,
                    estado: ticket.estado,
                    localidad: ticket.localidad,
                    municipio: ticket.municipio,
                    //#endregion
                }));
            }).catch(e => {
                console.log(e.message.replace("GraphQL error:", ""));
                setTicket(ticket => ({
                    ...ticketInicial,
                    direccionId: ticket.direccionId,
                    empresaId: ticket.empresaId,
                    folioId: -1,
                    folio: "No asignado",

                    //#region clinica
                    clinicaId: _clinicaID ? Number(_clinicaID) : null,
                    nombreClinica: ticket.nombreClinica,
                    //#endregion

                    //#region personas
                    vendedorId: userToken.usuarioId,
                    //#endregion

                    //#region datos ticket
                    serieId: ticket.serieId,
                    serie: ticket.serie,
                    // folioId: -1,
                    // folio: null,
                    turnoId: ticket.turnoId,
                    turno: ticket.turno,
                    //#endregion


                    //#region direccion
                    calle: ticket.calle,
                    codigoPostal: ticket.codigoPostal,
                    colonia: ticket.colonia,
                    estado: ticket.estado,
                    localidad: ticket.localidad,
                    municipio: ticket.municipio,
                    //#endregion
                }));
            })

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se generó correctamente el ticket "${ticket.serie}-${ticket.folio}"` });
            setSeleccion({ ...seleccionInicial });
        }
    });

    const [addTicketTemporal] = useMutation(GUARDAR_TICKET_TEMPORAL, {
        onError: (e) => {
            // setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {

        }
    });
    //#endregion

    //#region constantes/variables
    const Agradecimiento = '¡Gracias por su Compra!';
    const menu = [
        { texto: "Volver", icono: <ArrowBackIos />, onClick: () => { history.goBack() } }
    ];
    const columnas = [
        { id: 'servicioId', label: 'servicioId', minWidth: 0, hide: true },
        { id: 'no', label: 'No.', align: 'center' },
        { id: 'codigoProducto', label: 'Codigo', align: 'center' },
        { id: 'descripcion', label: 'Descripción', align: 'center', textAlign: 'center', minWidth: 0 },
        {
            id: 'cantidad', label: 'Cantidad', style: { margin: "1px", maxWidth: '100px' }, align: 'center', minWidth: 0, obtenerColumna: "servicioId",
            format: (val, id) => {
                return (
                    <Grid container item xs={12} justify="center" style={{ margin: '0 auto' }}>
                        <Grid container item xs={12} sm={6} md={6} justify="center">
                            <TextField type="number" name="cantidad"
                                onChange={(e) => handleChangeModificarCantidad(e, id)} inputProps={{ min: "1", step: "1", value: `${val}` }}

                                onBlur={(e) => {
                                    if (e) {
                                        if (e.target.value < 1) {
                                            e.target.value = 1;
                                            handleChangeModificarCantidad(e, id);
                                        }
                                    }
                                }}
                                onClick={seleccionTexto}
                            />
                        </Grid>
                    </Grid>
                )

            },
        },
        {
            id: 'precioUnitarioTotal', label: 'Precio', minWidth: 80, align: 'center',
            format: (v) => formatoMoneda((v || 0), 2)
        },
        {
            id: 'precioTotal', label: 'Importe', align: 'center', minWidth: 0,
            format: (v) => formatoMoneda(v, 2)
        },
        {
            id: 'eliminar', label: '', align: 'center', minWidth: 0,
            format: (val) => {
                return (
                    <IconButton aria-label="delete" onClick={() => handleEliminarClick(val)}>
                        <DeleteIcon color="secondary" />
                    </IconButton>
                )
            }
        },

    ];
    let productos = [{ descripcion: 'No hay productos en existencia', servicioId: -1 }];
    let clientes = [{ nombres: 'Publico', apellidos: "general", usuarioId: -1 }];
    //#endregion

    //#region handlers
    const handleAgregarClick = (e, enviado, nDat, almacenEnviado, proceso = "agregar") => {
        let retorno = [];

        //si el focus esta en el boton de agregar se cambia la input del cliente y quita el focus
        if (refs.selected === "add") {
            setRef({ ...refs, selected: "cliente" })
            refs.add.current.blur();
        }
        setState({});
        let sel = enviado ? enviado : seleccion;

        //verifica si se selecciono un producto
        if (sel.servicioId !== null && sel.servicioId !== -1) {
            let index = -1;
            let dtmp = nDat ? nDat : datos;

            //Busca si ya se encuentra en el listado de productos agregados
            dtmp.map((item, indice) => {
                if (item.servicioId === sel.servicioId) {
                    index = indice;
                    return;
                }
            });

            if (sel.tipoProducto === 1 && (ticket.doctorId === -1 || !ticket.doctorId) && (!enviado)) {
                setMensaje({
                    ...mensaje, ...mensajeError, texto: `No ha seleccionado a un profesional para el servicio elegido, ¿Desea agregarlo de todas maneras?`, tipo: "confirmacion", onAccept: () => {
                        setMensaje((mensaje) => ({ ...mensaje, abrir: false }));
                        validarAlmacen(index, enviado, nDat);
                    }
                })
            } else {
                retorno = validarAlmacen(index, enviado, nDat, almacenEnviado, proceso);
            }
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, abrir: true, texto: "Selecciona un producto", onAccept: () => setMensaje({ ...mensaje, abrir: false }) })

            return [];
        }
        return retorno;
    };

    const validarAlmacen = (index, enviado, nDat, almacenEnviado, proceso) => {
        let retorno = [];
        let sel = enviado ? enviado : seleccion;
        //si es inventariable

        //TODO validar Almacen
        if (sel.inventariable === 1 && (!enviado || almacenEnviado)) {

            // si no tiene existencia en ningun almacen
            if (sel.existenciaTotal === 0) {
                //Si esta configurado en vender sin existencia
                if (sel.venderSinExistenciaGeneral) {
                    retorno = ftAgregar(index, enviado, nDat, proceso);
                } else {
                    //si el producto esta marcado para vender sin existencia
                    if (sel.venderSinExistencia) {
                        retorno = ftAgregar(index, enviado, nDat, proceso);
                    } else {
                        //si no tiene existencia ni se puede, vender manda mensaje
                        if (!almacenEnviado) {
                            setMensaje({
                                ...mensaje, ...mensajeError, texto: "El producto no tiene existencias", tipo: "mensaje", onAccept: () => {
                                    setMensaje((mensaje) => ({ ...mensaje, abrir: false }));
                                }
                            });
                        }
                        return [];
                    }
                }

            } else {
                let almElegido = null;

                if (almacenes) {
                    almElegido = almacenes.obtenerExistencia?.Existencias.find(al => al.almacenId === sel.almacenId && al.centroCostosId === (sel.centroCostosId === -1 ? null : sel.centroCostosId));
                } else {
                    if (almacenEnviado) {
                        almElegido = almacenEnviado.obtenerExistencia?.Existencias.find(al => al.almacenId === sel.almacenId && al.centroCostosId === (sel.centroCostosId === -1 ? null : sel.centroCostosId));
                    }
                }

                if (almElegido) {
                    //si el almacen no tiene existencias del producto
                    if (almElegido.existenciaActual <= 0) {
                        //Si esta configurado en vender sin existencia
                        if (sel.venderSinExistenciaGeneral) {
                            retorno = ftAgregar(index, enviado, nDat, proceso);
                        } else {
                            //si el producto esta marcado para vender sin existencia
                            if (sel.venderSinExistencia) {
                                retorno = ftAgregar(index, enviado, nDat, proceso);
                            } else {
                                //si no tiene existencia ni se puede, vender manda mensaje
                                if (!almacenEnviado) {
                                    setMensaje({
                                        ...mensaje, ...mensajeError, texto: "El producto no tiene existencias en el almacen elegido", tipo: "mensaje", onAccept: () => {
                                            setMensaje((mensaje) => ({ ...mensaje, abrir: false }));
                                        }
                                    })
                                }
                                return [];
                            }
                        }
                    } else {
                        retorno = ftAgregar(index, enviado, nDat, proceso);
                    }
                } else {
                    if (!almacenEnviado) {
                        setMensaje({
                            ...mensaje, ...mensajeError, texto: "El producto no tiene existencias", tipo: "mensaje", onAccept: () => {
                                setMensaje((mensaje) => ({ ...mensaje, abrir: false }));
                            }
                        });
                    }
                    return [];
                }
            }
        } else {
            //se manda el indice del producto en el listado o -1 si no se encontro
            retorno = ftAgregar(index, enviado, nDat, proceso);
        }
        return retorno;
    }

    const handleLimpiarPartidaClick = () => {
        if (datos.length !== 0) {
            setMensaje({ ...mensaje, texto: "¿Está seguro de limpiar el punto de venta?", abrir: true, onAccept: ftLimpiar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "No hay productos agregados" })
        }
    }

    /**comprueba si se selecciono una partida a eliminar **/
    const handleEliminarClick = (id) => {
        if (id) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: "¿Está seguro de eliminar el producto?", abrir: true, onAccept: () => { ftEliminar(id) } });
        } else {
            setMensaje({ ...mensaje, abrir: true, texto: "selecciona un producto" })
        }
    };

    const handleChangeModificarCantidad = async (event, id) => {
        let { value } = event.target;
        let dtmp = datos;

        //TODO mod cantidad
        await dtmp.map(function (dato, i) {
            if (dato.servicioId === id) {
                dato.cantidad = value;

                // por cantidad
                dato.descuentoCantidad = dato.descuentoUnitario * dato.cantidad;
                dato.descuentoConvenioCantidad = dato.descuentoConvenioUnitario * dato.cantidad;
                dato.precioDescuentoCantidad = dato.precioUnitarioDescuento * dato.cantidad;
                dato.precioDescuentoConvenioCantidad = dato.precioUnitarioDescuentoConvenio * dato.cantidad;

                //Totales
                dato.descuentoTotal = dato.descuentoUnitarioTotal * dato.cantidad;
                dato.ivaTotal = dato.ivaUnitarioTotal * dato.cantidad;
                dato.precioTotal = dato.precioUnitarioTotal * dato.cantidad;
                //----------------------------------------

                //=======================================================
                ticket.cantidad[i] = dato.cantidad;

                //#region datos producto
                // por cantidad
                ticket.descuentoCantidad[i] = dato.descuentoCantidad;
                ticket.descuentoConvenioCantidad[i] = dato.descuentoConvenioCantidad;
                ticket.precioDescuentoCantidad[i] = dato.precioDescuentoCantidad;
                ticket.precioDescuentoConvenioCantidad[i] = dato.precioDescuentoConvenioCantidad;

                //Totales
                ticket.descuentoTotal[i] = dato.descuentoTotal;
                ticket.ivaTotal[i] = dato.ivaTotal;
                ticket.precioTotal[i] = dato.precioTotal;
                //#endregion Datos producto

            }
        });



        await ftCalcularTotal(dtmp);
    };

    const recalculoConvenioTabla = async (descConv) => {
        //TODO recalculo convenio
        let dtmp = datos;

        dtmp.map((dato) => {
            if (descConv[dato.servicioId]) {
                dato.porcentajeDescuentoConvenio = descConv[dato.servicioId] || 0;
                //Por cantidad
                dato.descuentoConvenioUnitario = dato.precioUnitarioDescuento * dato.porcentajeDescuentoConvenio;
                dato.descuentoUnitarioTotal = dato.descuentoUnitario + dato.descuentoConvenioUnitario;

                dato.precioUnitarioDescuentoConvenio = dato.precioUnitario - dato.descuentoUnitarioTotal;

                dato.ivaUnitarioTotal = dato.precioUnitarioDescuentoConvenio * dato.porcentajeIva;
                dato.precioUnitarioTotal = dato.precioUnitarioDescuentoConvenio + dato.ivaUnitarioTotal;

                //Por cantidad
                dato.descuentoCantidad = dato.descuentoUnitario * dato.cantidad;
                dato.descuentoConvenioCantidad = dato.descuentoConvenioUnitario * dato.cantidad;
                dato.precioDescuentoCantidad = dato.precioUnitarioDescuento * dato.cantidad;
                dato.precioDescuentoConvenioCantidad = dato.precioUnitarioDescuentoConvenio * dato.cantidad;

                //Totales
                dato.descuentoTotal = dato.descuentoUnitarioTotal * dato.cantidad;
                dato.ivaTotal = dato.ivaUnitarioTotal * dato.cantidad;
                dato.precioTotal = dato.precioUnitarioTotal * dato.cantidad;
                //----------------------------------------
            }
        });
        await ftCalcularTotal(dtmp);
    };

    const handleCobrarClick = () => {
        if (datos.length !== 0) {
            let formaCantidad = 0;

            formasPago.map(forma => {
                formaCantidad += Number(forma.filas.costo || 0);
            });
            // if (ticket.totalTicket === 0 && ticket.metodoPago === "PPD") {

            //     setMensaje({ ...mensajeAdvertencia, texto: "El total de la compra debe ser mayor a cero para las ventas a credito", abrir: true })
            // } else {
            if (Number(formaCantidad.toFixed(2)) >= Number(ticket.totalTicket.toFixed(2)) || (/*Number(formaCantidad.toFixed(2)) > 0 && */ ticket.metodoPago === 'PPD')) {

                if (clienteMoroso === true && ticket.metodoPago === 'PPD') {
                    if (!pwdAdminActiva) setMensaje({ ...mensajeAdvertencia, texto: "No se puede realizar la venta a crédito debido a que el usuario cuenta con adeudos", abrir: true })
                    else {
                        setCamposFormularioState(camposFormularioAutorizacion);
                        setFormulario({ ...formulario, titulo: "Autorización de administrador", abrir: true, onAccept: ftValidarAutorizacion });
                    }
                } else {
                    setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: "¿Está seguro de realizar la compra?", subParrafo: '(Una vez aceptado no sera posible modificar la compra)', abrir: true, onAccept: ftCobrar });
                }
            }
            else {

                setMensaje({ ...mensajeAdvertencia, texto: "El importe de pago es menor al total de la venta", abrir: true })
            }
            // }

        } else {
            setMensaje({ ...mensajeAdvertencia, texto: "No hay productos por cobrar", abrir: true })
        }

    }

    const onCantidadChange = (e) => {
        //TODO cantidadChange
        handleCantidadChange(e.target.value, seleccion, setSeleccion, descConvenio, almacenes);
    }

    const ClienteChange = async (e, val) => {
        if (val) {
            handleClienteChange(val, setTicket, setSeleccion);

            let select = null;
            selectCreditoAplicableCliente({ clienteID: Number(val.usuarioId), monto: ticket.totalTicket || 0 }, null).then(v => {
                select = v;

                setlistaPlanCredito(select);

                setTicket(ticket => ({ ...ticket, metodoPago: 'PUE', plazoPago: null, diasPagar: null, numeroPlan: null }));

            }).catch(e => console.log(e.message));

            setClienteMoroso(val.moroso)
        }
    }

    //TODO productchange
    function ProductoChange(e, val) {
        handleProductoChange(val, seleccionInicial, setSeleccion, descConvenio);
    }

    function AlmacenChange(e) {
        handleAlmacenChange(e.target.value, seleccionInicial, setSeleccion)
    }

    function CentroChange(e) {
        setSeleccion({ ...seleccion, centroCostosId: e.target.value });
    }

    function DoctorChange(val) {
        setTicket(ticket => ({ ...ticket, doctorId: val.usuarioId }));
        setSeleccion(seleccion => ({ ...seleccion, doctorId: val.usuarioId }))
    }

    function finishDoctorLoading(loaded) {
        setDoctorLoading(loaded);
    }

    /*evento para cambiar el estado del pago */
    const handleChangeFormaPago = async (evt, id) => {
        //TODO change forma pago
        let row = [];
        let cantidad = 0;
        formasPago.map((element, index) => {
            if (element.filas.clave === evt.target.value) {

                element.filas.checkend = evt.target.checked === true ? false : true;
                //si desactiva el checkbox limpiar el campo
                if (evt.target.checked == false) {
                    element.filas.costo = 0;
                    element.filas.texto = '0.00';
                } else {
                    element.filas.costo = ftObtenerMonto();
                    element.filas.texto = formatoMoneda(element.filas.costo).replace('$', '');
                }
                row.push(element);
            } else {
                row.push(element);
            }

            cantidad += element.filas.costo;
        });

        let cambio = cantidad - (ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0));
        cambio = cambio < 0 ? 0 : cambio;

        setTicket(ticket => ({ ...ticket, importePago: cantidad, cambio }))
        setFormasPagos(row);

    }

    const seleccionTexto = (evt, id) => {
        if (evt) if (evt.target) if (evt.target.select) if (typeof evt.target.select === 'function') evt.target.select();
    }
    /*evento para cambiar el monto  en forma de pago*/
    const handleChangeFormaMonto = (evt, clave) => {
        //TODO change forma monto
        let row = [];
        let cantidad = 0;

        formasPago.map((element, index) => {
            if (element.filas.clave === clave) {
                let monto = evt.target.value;
                if (rgxNumeros.test(monto.replace(/,/g, '')) || monto === "") {
                    element.filas.costo = ftObtenerMontoValido(element.filas, monto.replace(/,/g, ''));
                    element.filas.texto = (isNaN(element.filas.costo) ? 0 : element.filas.costo + (monto.endsWith('.') ? '.' : monto.endsWith('.0') ? '.0' : monto.endsWith('.00') ? '.00' : ''));
                }
                row.push(element);
            } else {
                row.push(element);
            }
            ticket.formasCobro[index] = element.filas.clave;
            ticket.formasCobroCantidad[index] = element.filas.costo;
            ticket.formasCobroTexto[index] = element.filas.descripcion;

            cantidad += element.filas.costo;
        });

        let cambio = cantidad - (ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0));
        cambio = cambio < 0 ? 0 : cambio;

        setTicket(ticket => ({ ...ticket, importePago: cantidad, cambio }))
        setFormasPagos(row)
    }

    const handleChangeCredito = (e) => {
        if (e)
            if (e.target)
                if (e.target.checked === true && ticket.clienteId != -1 && listaPlanCredito.length !== 0) {

                    let plazoPago = null, diasPagar = null, numeroPlan = null, porcentajeInteres = null;

                    if (listaPlanCredito?.length > 0) {
                        planesCredito.listadoPlanesCredito.map(plan => {
                            if (plan.planCreditoID === listaPlanCredito[0]?.value) {

                                plazoPago = listaPlanCredito[0]?.value;
                                diasPagar = plan.diasPlan;
                                numeroPlan = plan.plazoCredito;
                                porcentajeInteres = plan.porcentajeInteresDecimal;
                                return true;
                            }
                            return false;
                        })
                    }

                    setTicket(ticket => ({ ...ticket, metodoPago: 'PPD', plazoPago, diasPagar, numeroPlan, porcentajeInteres }))
                    setPendienteCobrar(true);
                    pwdAdminRef.current = null;
                    setCamposFormularioState(camposFormulario);
                    setFormulario({ ...formulario, abrir: true });

                } else {
                    setTicket(ticket => ({ ...ticket, metodoPago: 'PUE', plazoPago: null, diasPagar: null, numeroPlan: null, porcentajeInteres: null }))
                    setPendienteCobrar(true);
                }
    };

    const ftObtenerMonto = () => {
        //TODO obtener monto
        let restante = (ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0)) - ticket.importePago;
        return Number(restante > 0 ? restante : 0);
    }

    const ftObtenerMontoValido = (forma, monto) => {
        //TODO obtener monto valido
        monto = Number(monto);
        let cantidadAparte = 0;
        let nuevoTotal = Number(ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0));

        formasPago.map((element, index) => {
            if (element.filas.clave !== forma.clave) {
                cantidadAparte += element.filas.costo;
            }
        });

        let maximoMonto = nuevoTotal - cantidadAparte;
        maximoMonto = maximoMonto < 0 ? 0 : maximoMonto;

        if (forma.clave !== "01" || forma.descripcion !== "Efectivo") {
            if (monto > maximoMonto) {
                monto = maximoMonto;
            }
        }

        return monto;
    }

    const ftValidarPago = (forma, monto) => {
        //TODO ftValidarPago blur
        let row = [];
        let nuevaCantidad = 0;
        let montoValido = ftObtenerMontoValido(forma, (monto + "").replace(/,/g, ''));

        formasPago.map((element, index) => {
            if (element.filas.clave === forma.clave) {
                element.filas.costo = montoValido
                element.filas.texto = formatoMoneda(montoValido).replace('$', '')
                row.push(element);
                nuevaCantidad += Number(montoValido);
            } else {
                row.push(element);
                nuevaCantidad += Number(element.filas.costo);
            }
        });

        let cambio = nuevaCantidad - (ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0));
        cambio = cambio < 0 ? 0 : cambio;

        setTicket(ticket => ({ ...ticket, importePago: nuevaCantidad, cambio }))
        setFormasPagos(row);
    }
    //#endregion

    //#region funciones
    function ftAgregar(index, enviado, nDat, proceso) {
        let dtmp = nDat ? nDat : datos;
        let i = index;
        let sel = enviado ? enviado : seleccion;

        //TODO agregar
        if (index === -1) {
            i = datos.length;
            dtmp = [...datos, { ...sel, no: datos.length + 1 }];
        } else {
            dtmp[i].cantidad = Number(dtmp[i].cantidad) + Number(sel.cantidad);
            //Por cantidad
            dtmp[i].descuentoCantidad += sel.descuentoCantidad;
            dtmp[i].descuentoConvenioCantidad += sel.descuentoConvenioCantidad;
            dtmp[i].precioDescuentoCantidad += sel.precioDescuentoCantidad;
            dtmp[i].precioDescuentoConvenioCantidad += sel.precioDescuentoConvenioCantidad;
            //Totales
            dtmp[i].descuentoTotal += sel.descuentoTotal;
            dtmp[i].ivaTotal += sel.ivaTotal;
            dtmp[i].precioTotal += sel.precioTotal;
        }

        dtmp[i].eliminar = sel.servicioId;

        //=======================================================
        ticket.servicioId[i] = dtmp[i].servicioId;
        ticket.codProd[i] = dtmp[i].codigoProducto;
        ticket.descripcion[i] = dtmp[i].descripcion;
        ticket.cantidad[i] = dtmp[i].cantidad;

        //#region porcentajes
        ticket.porcentajeDescuentoConvenio[i] = dtmp[i].porcentajeDescuentoConvenio;
        ticket.porcentajeDescuento[i] = dtmp[i].porcentajeDescuento;
        ticket.porcentajeIva[i] = dtmp[i].porcentajeIva;
        //#endregion

        //#region datos producto
        //unitarios
        ticket.precioUnitario[i] = dtmp[i].precioUnitario;
        ticket.precioUnitarioDescuento[i] = dtmp[i].precioUnitarioDescuento;
        ticket.precioUnitarioDescuentoConvenio[i] = dtmp[i].precioUnitarioDescuentoConvenio;

        ticket.descuentoUnitario[i] = dtmp[i].descuentoUnitario;
        ticket.descuentoConvenioUnitario[i] = dtmp[i].descuentoConvenioUnitario;
        ticket.descuentoUnitarioTotal[i] = dtmp[i].descuentoUnitarioTotal;

        ticket.ivaUnitario[i] = dtmp[i].ivaUnitario;
        ticket.ivaUnitarioDescuento[i] = dtmp[i].ivaUnitarioDescuento;
        ticket.ivaUnitarioTotal[i] = dtmp[i].ivaUnitarioTotal;

        ticket.precioUnitarioTotal[i] = dtmp[i].precioUnitarioTotal;

        // por cantidad
        ticket.descuentoCantidad[i] = dtmp[i].descuentoCantidad;
        ticket.descuentoConvenioCantidad[i] = dtmp[i].descuentoConvenioCantidad;
        ticket.precioDescuentoCantidad[i] = dtmp[i].precioDescuentoCantidad;
        ticket.precioDescuentoConvenioCantidad[i] = dtmp[i].precioDescuentoConvenioCantidad;

        //Totales
        ticket.descuentoTotal[i] = dtmp[i].descuentoTotal;
        ticket.ivaTotal[i] = dtmp[i].ivaTotal;
        ticket.precioTotal[i] = dtmp[i].precioTotal;
        //#endregion Datos producto

        //#region garantia
        ticket.garantia[i] = dtmp[i].garantia || '';
        ticket.garantiaId[i] = dtmp[i].garantiaId || null;
        //#endregion

        //#region clinica
        ticket.almacenId[i] = dtmp[i].almacenId || null;
        ticket.centroCostosId[i] = dtmp[i].centroCostosId || null;
        //#endregion

        comisiones.map(cm => {
            if (cm.productosIdComision.includes(dtmp[i].servicioId)) {
                ticket.comision[i] = Number(cm.porcentajeComision) / 100;
            }
        });

        if (proceso !== "receta" && process1 !== 'cola') {
            setDatos([...dtmp]);
            ftCalcularTotal(dtmp);
            setSeleccion({ ...seleccionInicial });
        }
        setProcess(null)
        setPendienteCobrar(true);
        return dtmp[i];
    }

    /**elimina la partida seleccionada**/
    function ftEliminar(id) {
        //TODO eliminar
        let cont = 1;
        let partidas = datos.filter((part, i) => {
            if (Number(part.servicioId) === Number(id)) {
                setSeleccion({ ...seleccion, no: seleccion.no - 1 });
                return false;
            }
            part.no = cont++;
            return true;
        });

        ftCalcularTotal(partidas);
        setDatos(partidas);
        setMensaje({ ...mensaje, abrir: false });
        setPendienteCobrar(true);
    }

    /* arregla el ticket en base a datos */
    function ftRecalcularDatos() {

        //#region reinicio
        ticket.servicioId = [];
        ticket.codProd = [];
        ticket.descripcion = [];
        ticket.cantidad = [];
        ticket.porcentajeDescuentoConvenio = [];
        ticket.porcentajeDescuento = [];
        ticket.porcentajeIva = [];
        ticket.precioUnitario = [];
        ticket.precioUnitarioDescuento = [];
        ticket.precioUnitarioDescuentoConvenio = [];
        ticket.descuentoUnitario = [];
        ticket.descuentoConvenioUnitario = [];
        ticket.descuentoUnitarioTotal = [];
        ticket.ivaUnitario = [];
        ticket.ivaUnitarioDescuento = [];
        ticket.ivaUnitarioTotal = [];
        ticket.precioUnitarioTotal = [];
        ticket.descuentoCantidad = [];
        ticket.descuentoConvenioCantidad = [];
        ticket.precioDescuentoCantidad = [];
        ticket.precioDescuentoConvenioCantidad = [];
        ticket.descuentoTotal = [];
        ticket.ivaTotal = [];
        ticket.precioTotal = [];
        ticket.garantia = [];
        ticket.garantiaId = [];
        ticket.almacenId = [];
        ticket.centroCostosId = [];
        // ticket.doctorId = [];
        ticket.formasCobro = [];
        ticket.formasCobroCantidad = [];
        //#endregion

        datos.map((dtmp, i) => {
            ticket.servicioId[i] = dtmp.servicioId;
            ticket.codProd[i] = dtmp.codigoProducto;
            ticket.descripcion[i] = dtmp.descripcion;
            ticket.cantidad[i] = dtmp.cantidad;

            comisiones.map(cm => {
                if (cm.productosIdComision.includes(dtmp.servicioId)) {
                    ticket.comision[i] = Number(cm.porcentajeComision) / 100;
                }
            });

            if (ticket.comision[i] === undefined) {
                ticket.comision[i] = null
            };

            //#region porcentajes
            ticket.porcentajeDescuentoConvenio[i] = dtmp.porcentajeDescuentoConvenio || 0;
            ticket.porcentajeDescuento[i] = dtmp.porcentajeDescuento || 0;
            ticket.porcentajeIva[i] = dtmp.porcentajeIva || 0;
            //#endregion

            //#region datos producto
            //unitarios
            ticket.precioUnitario[i] = dtmp.precioUnitario || 0;
            ticket.precioUnitarioDescuento[i] = dtmp.precioUnitarioDescuento || 0;
            ticket.precioUnitarioDescuentoConvenio[i] = dtmp.precioUnitarioDescuentoConvenio || 0;

            ticket.descuentoUnitario[i] = dtmp.descuentoUnitario || 0;
            ticket.descuentoConvenioUnitario[i] = dtmp.descuentoConvenioUnitario || 0;
            ticket.descuentoUnitarioTotal[i] = dtmp.descuentoUnitarioTotal || 0;

            ticket.ivaUnitario[i] = dtmp.ivaUnitario || 0;
            ticket.ivaUnitarioDescuento[i] = dtmp.ivaUnitarioDescuento || 0;
            ticket.ivaUnitarioTotal[i] = dtmp.ivaUnitarioTotal || 0;

            ticket.precioUnitarioTotal[i] = dtmp.precioUnitarioTotal || 0;

            // por cantidad
            ticket.descuentoCantidad[i] = dtmp.descuentoCantidad || 0;
            ticket.descuentoConvenioCantidad[i] = dtmp.descuentoConvenioCantidad || 0;
            ticket.precioDescuentoCantidad[i] = dtmp.precioDescuentoCantidad || 0;
            ticket.precioDescuentoConvenioCantidad[i] = dtmp.precioDescuentoConvenioCantidad || 0;

            //Totales
            ticket.descuentoTotal[i] = dtmp.descuentoTotal || 0;
            ticket.ivaTotal[i] = dtmp.ivaTotal || 0;
            ticket.precioTotal[i] = dtmp.precioTotal || 0;
            //#endregion Datos producto

            //#region garantia
            ticket.garantia[i] = dtmp.garantia || '';
            ticket.garantiaId[i] = dtmp.garantiaId || null;
            //#endregion

            //#region clinica
            ticket.almacenId[i] = dtmp.almacenId || null;
            ticket.centroCostosId[i] = dtmp.centroCostosId || null;
            //#endregion
        });
        ftRecalcularFormasPago();
    }

    /**calcula el total de lo que se manda redondeando hacia arriba**/
    function ftCalcularTotal(partidas) {
        //TODO calcular total

        let tmpTotal = 0;
        let descuentoTotalTicket = 0;
        let descuentoTotalConvenioTicket = 0;
        let descuentoTotalFinalTicket = 0;
        let tmpTotalIva = 0;
        let sub = 0;

        partidas.map(part => {
            descuentoTotalTicket += part.descuentoCantidad;
            descuentoTotalConvenioTicket += part.descuentoConvenioCantidad;
            descuentoTotalFinalTicket += part.descuentoTotal;
            sub += part.precioDescuentoConvenioCantidad;
            tmpTotalIva += part.ivaTotal;
            tmpTotal += part.precioTotal;
        });

        setTicket(ticket => ({ ...ticket, subtotalTicket: sub, ivaTotalTicket: tmpTotalIva, totalTicket: tmpTotal, descuentoTotalTicket, descuentoTotalConvenioTicket, descuentoTotalFinalTicket }));

        tmpTotal = Math.ceil(tmpTotal * 1000) / 1000;
        setSubTotal(sub);
        setIva(tmpTotalIva);
        setTotal(tmpTotal);

    }

    function handlePwdAdmin(val) {
        pwdAdminRef.current = val;
    }

    function ftValidarAutorizacion({ passwordAdmin }) {
        async function ftValidarAutorizacion() {
            try {
                passwordAdmin = (passwordAdmin === null || passwordAdmin === undefined) ? pwdAdminRef.current : passwordAdmin;
                if (passwordAdmin === null || passwordAdmin === undefined) throw new Error("Contraseña incorrecta")
                setDisabledAccept(true);

                let respuesta = await queryValidarSeguridadPuntoVenta({
                    password: passwordAdmin
                });

                if (respuesta) {
                    setMensaje({
                        ...mensaje, ...mensajeConfirmacion,
                        texto: "¿Está seguro de realizar la compra?",
                        subParrafo: '(Una vez aceptado no sera posible modificar la compra)',
                        abrir: true, onAccept: ftCobrar
                    });
                    setFormulario({ ...formulario, onClose: ftFormularioClose })
                    pwdAdminRef.current = null;
                }
                else throw new Error("Contraseña incorrecta");

                setDisabledAccept(false);

            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftValidarAutorizacion();
    }

    function ftCobrar() {
        //TODO cobrar
        setDisabledAccept(true);
        let formas = "";
        let formaCantidad = "";
        let formasTexto = "";

        formasPago.map(forma => {
            if ((forma.filas.costo || 0) !== 0) {
                formas += forma.filas.clave + ",";
                formaCantidad += (forma.filas.costo || 0) + ",";
                formasTexto += forma.filas.descripcion + ",";
            }
        });

        formas = formas.substring(0, formas.length - 1);
        formaCantidad = formaCantidad.substring(0, formaCantidad.length - 1);
        formasTexto = formasTexto.substring(0, formasTexto.length - 1);

        addTicket({
            variables: {
                ticket: {
                    ...ticket,

                    servicioId: ticket.servicioId.join(','),
                    codProd: ticket.codProd.join(','),
                    descripcion: ticket.descripcion.join(','),
                    cantidad: ticket.cantidad.join(','),

                    //#region porcentajes
                    porcentajeDescuentoConvenio: ticket.porcentajeDescuentoConvenio.join(','),
                    porcentajeDescuento: ticket.porcentajeDescuento.join(','),
                    porcentajeIva: ticket.porcentajeIva.join(','),
                    //#endregion

                    //#region datos producto
                    precioUnitario: ticket.precioUnitario.join(','),
                    precioUnitarioDescuento: ticket.precioUnitarioDescuento.join(','),
                    precioUnitarioDescuentoConvenio: ticket.precioUnitarioDescuentoConvenio.join(','),

                    descuentoUnitario: ticket.descuentoUnitario.join(','),
                    descuentoConvenioUnitario: ticket.descuentoConvenioUnitario.join(','),
                    descuentoUnitarioTotal: ticket.descuentoUnitarioTotal.join(','),
                    ivaUnitario: ticket.ivaUnitario.join(','),
                    ivaUnitarioDescuento: ticket.ivaUnitarioDescuento.join(','),
                    ivaUnitarioTotal: ticket.ivaUnitarioTotal.join(','),

                    precioUnitarioTotal: ticket.precioUnitarioTotal.join(','),


                    // por cantidad
                    descuentoCantidad: ticket.descuentoCantidad.join(','),
                    descuentoConvenioCantidad: ticket.descuentoConvenioCantidad.join(','),
                    precioDescuentoCantidad: ticket.precioDescuentoCantidad.join(','),
                    precioDescuentoConvenioCantidad: ticket.precioDescuentoConvenioCantidad.join(','),

                    //Totales
                    descuentoTotal: ticket.descuentoTotal.join(','),
                    ivaTotal: ticket.ivaTotal.join(','),
                    precioTotal: ticket.precioTotal.join(','),
                    //#endregion

                    //#region garantia
                    garantiaId: ticket.garantiaId.join(','),
                    garantia: ticket.garantia.join(','),
                    //#endregion

                    //#region clinica
                    almacenId: ticket.almacenId.join(','),
                    centroCostosId: ticket.centroCostosId.join(','),
                    //#endregion

                    //#region personas
                    doctorId: ticket.doctorId + "",
                    //#endregion

                    //#region formas cobro
                    formasCobro: formas,
                    formasCobroCantidad: formaCantidad,
                    formasCobroTexto: formasTexto,
                    //#endregion

                    comision: ticket.comision.join(','),
                    totalTicket: ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0)
                }
            }
        }).then(
            (input) => {
                if (input)
                    if (input.data) {
                        let id = input.data.nuevoTicket.ticketId;
                        let imp = window.open('/ver-ticket/' + id, '_blank');
                        setPendienteCobrar(false);
                        ftLimpiarPV();
                        setReferido(false);
                    }
            }).catch(e => {
                setMensaje({ ...mensajeAdvertencia, texto: e.message, abrir: true })
            })
    }

    /** Limpia toda la partida */
    function ftLimpiar() {
        ftCalcularTotal([]);
        setSeleccion(seleccionInicial);
        setPagoCon(0);
        setCambio(0);
        setIva(0);
        setSubTotal(0);
        setTotal(0);
        setDatos([]);
        setMensaje(mensajeInicial);
        setQR({ ...QR, codigo: null });
        setOpcionesAlmacen([{ label: "Sin almacén", value: -1 }]);
        setDescConvenio({});
        ftLimpiarFormasPago();
        // setFormasPagos([]);
        setDisabledAccept(false);
        setPendienteCobrar(true);

        setTicket(ticket => ({
            ...ticketInicial,
            direccionId: ticket.direccionId,
            empresaId: ticket.empresaId,
            folioId: ticket.folioId,
            folio: ticket.folio,

            //#region clinica
            clinicaId: _clinicaID ? Number(_clinicaID) : null,
            nombreClinica: ticket.nombreClinica,
            //#endregion

            //#region personas
            vendedorId: userToken.usuarioId,
            //#endregion

            //#region datos ticket
            serieId: ticket.serieId,
            serie: ticket.serie,
            // folioId: -1,
            // folio: null,
            turnoId: ticket.turnoId,
            turno: ticket.turno,
            //#endregion


            //#region direccion
            calle: ticket.calle,
            codigoPostal: ticket.codigoPostal,
            colonia: ticket.colonia,
            estado: ticket.estado,
            localidad: ticket.localidad,
            municipio: ticket.municipio,
            //#endregion
        }));
    }

    const ftLimpiarFormasPago = () => {
        let row = [];

        formasPago.map((element, index) => {
            element.filas.checkend = true;
            element.filas.costo = 0;
            element.filas.texto = '0.00';
            row.push(element);
        });

        setCambio(0);
        setPagoCon(0);
        setFormasPagos(row);
    }

    const ftRecalcularFormasPago = () => {

        let tmpTotal = 0;
        let tmpTotalIva = 0;
        let descuentoTotalTicket = 0;
        let descuentoTotalConvenioTicket = 0;
        let descuentoTotalFinalTicket = 0;
        let sub = 0;

        datos.map(part => {
            descuentoTotalTicket += part.descuentoCantidad;
            descuentoTotalConvenioTicket += part.descuentoConvenioCantidad;
            descuentoTotalFinalTicket += part.descuentoTotal;
            sub += part.precioDescuentoConvenioCantidad;
            tmpTotalIva += part.ivaTotal;
            tmpTotal += part.precioTotal;
        });

        setTicket(ticket => ({ ...ticket, subtotalTicket: sub, ivaTotalTicket: tmpTotalIva, totalTicket: tmpTotal, descuentoTotalTicket, descuentoTotalConvenioTicket, descuentoTotalFinalTicket }));

        tmpTotal = Math.ceil(tmpTotal * 1000) / 1000;
        setSubTotal(sub);
        setIva(tmpTotalIva);
        setTotal(tmpTotal);

        let importePago = 0, cambio2 = 0;

        formasPago.map((element, index) => {
            if (element.filas.checkend === false) {
                ticket.formasCobro[index] = element.filas.clave;
                ticket.formasCobroCantidad[index] = element.filas.costo;
                ticket.formasCobroTexto[index] = element.filas.descripcion;
                importePago += element.filas.costo;
            }
        });

        setPagoCon(importePago);
        cambio2 = importePago - (tmpTotal + (tmpTotal * ticket.porcentajeInteres || 0))

        // (ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0));
        cambio2 = cambio2 > 0 ? cambio2 : 0;

        setTicket(ticket => ({ ...ticket, importePago, cambio: cambio2 }));

        setCambio(cambio2);
    }

    function ftLimpiarPV() {
        ftCalcularTotal([]);
        setSeleccion(seleccionInicial);
        setIva(0);
        setSubTotal(0);
        setTotal(0);
        setDatos([]);
        setMensaje(mensajeInicial);
        setQR({ ...QR, codigo: null });
        setOpcionesAlmacen([{ label: "Sin almacén", value: -1 }]);
        setDescConvenio({});
        ftLimpiarFormasPago();
        // setFormasPagos([]);
        setDisabledAccept(false);
    }

    //================================
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    //================================

    const ftValidarExistencia = (producto, listado) => {
        if (producto.inventariable === 1) {
            if (producto.existenciaTotal !== null) {
                if (producto.existenciaTotal <= 0) {
                    listado.push({ ...producto, color: "#e12" });
                } else {
                    listado.push(producto);
                }
            }
        } else {
            listado.push(producto);
        }
    }

    function getDatos() {
        //TODO getDatos
        if (dproductos) {
            productos = [];
            let nDat = datos;
            dproductos.listadoProductosServicios.productosServicios.map(prod => {

                //Si recibe servicio por state se agrega a la tabla de partidas
                if (state?.servicioId?.includes(prod?.servicioId + '')) {
                    if (state?.servicioId) {
                        ftRecalculo(1, 1, prod, (resultado) => {
                            let res = resultado();

                            nDat.push(handleAgregarClick(null, { ...res/*, ...state, servicioId:  state.servicioId[state.servicioId.indexOf(prod?.servicioId + '')]*/ }, nDat));
                        }, descConvenio);
                    }
                }
                //si cargaron los servicios por doctor valida.
                if (dproductosDoctor && ticket.doctorId !== -1) {
                    if (prod.tipoProducto === 1) {

                        if (dproductosDoctor&& dproductosDoctor.ObtenerServicioDoctor) {
                            if ( dproductosDoctor.ObtenerServicioDoctor?.serviciosId?.includes(prod.servicioId)) {
                                ftValidarExistencia(prod, productos);
                            }
                        }
                    } else {
                        ftValidarExistencia(prod, productos);
                    }
                } else {
                    ftValidarExistencia(prod, productos);
                }
            });
        }

        if (dcliente) {
            clientes = [{ nombres: 'Publico', apellidos: "general", usuarioId: -1 }, ...dcliente.listadoPaciente.pacientes];
        }
    }
    getDatos();

    const ftObtenerAlmacenYCentro = (almObt) => {
        let apto = -1;
        let aptoCentro = -1;
        let sinCentro = false;
        let idsAlma = [];
        let idsCentro = [];

        let opcionesAlma = [];
        let opcionesCentro = [];

        almObt.obtenerExistencia.Existencias.map(alm => {
            if (apto === -1 && alm.existenciaActual > 0) {
                apto = alm.almacenId;
            }

            if (aptoCentro === -1 && alm.existenciaActual > 0 && alm.centroCostosId !== null) {
                aptoCentro = alm.centroCostosId;
            }

            if (sinCentro === false && alm.centroCostosId === null) {
                sinCentro = true;
            }

            if (!idsAlma.includes(alm.almacenId)) {
                idsAlma.push(alm.almacenId);
                opcionesAlma.push({ label: alm.nombreAlmacen, value: alm.almacenId, color: alm.existenciaActual > 0 || alm.centroCostosId !== null ? "#000" : "#e12" })
            }

            if (!idsCentro.includes(alm.centroCostosId) && alm.centroCostosId !== null) {
                idsCentro.push(alm.centroCostosId);
                opcionesCentro.push({ label: alm.centroCostos, value: alm.centroCostosId, color: alm.existenciaActual > 0 ? "#000" : "#e12" })
            }
        });

        if (sinCentro === true) opcionesCentro.unshift({ label: "Sin centro de costos", value: -1 });

        apto = opcionesAlma ? opcionesAlma.length > 0 ? apto !== -1 ? apto : opcionesAlma[0].value : -1 : -1;

        return { almacen: apto, centro: aptoCentro };

    }


    const handleRecetaClick = () => {
        setReceta({ ...receta, abrir: true })
    }

    const handleRecetaChange = async (abrir, codigo, dataReceta) => {
        setReceta({ ...receta, abrir, codigo });
        //TODO receta change

        if (dataReceta) {
            let partidasReceta = dataReceta.partidas.map(parti => parti.productoId);

            let noAdd = [];

            dataReceta.partidas.map(parti => {
                if (parti.productoId !== null) {
                    noAdd.push(parti.descripcionProducto);
                } else {
                    noAdd.push(parti.productoNoenVenta);
                }
            });

            let partidasFinal = [...datos];

            for (let i = 0; i < productos.length; i++) {

                let index = partidasReceta.indexOf(Number(productos[i].servicioId));

                if (index >= 0) {

                    let res = await ftRecalculo(1, Number(dataReceta.partidas[index].cantidad || 1), productos[i], (resultado) => { }, descConvenio, true);

                    if (res) {
                        let almacenObt = await FetchGrahpQL(
                            {
                                query: OBTENER_EXISTENCIA_FETCH,
                                variables: { id: null, servicio: Number(productos[i].servicioId || -1), clinica: Number(_clinicaID) }
                            }
                        );

                        if (almacenObt) {
                            almacenObt = ftObtenerAlmacenYCentro(almacenObt);
                        }

                        let datmp = handleAgregarClick(null, {
                            ...res,
                            almacenId: almacenObt.almacen ? almacenObt.almacen : null,
                            centroCostosId: almacenObt.centro ? almacenObt.centro : null
                        }, null, null, "receta");
                        if (datmp) {
                            noAdd = noAdd.filter((desc, i) => desc !== datmp.descripcion);
                        }

                        // if (!partidasFinal.includes(...datmp)) {
                        let indice = partidasFinal.findIndex(partida => partida.servicioId === datmp.servicioId);

                        if (indice >= 0) {
                            partidasFinal[indice] = datmp;
                        } else {
                            partidasFinal.push(datmp);

                        }
                    }
                }

            }

            if (partidasFinal.length > 0) {
                partidasFinal.map((dt, indice) => {
                    dt.no = indice + 1;
                })
                setDatos([...partidasFinal]);
            }

            if (noAdd.length > 0) {
                setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Los siguientes productos no pudieron agregarse: " + noAdd.join(', ') });
            }

            setSeleccion(seleccion => ({ ...seleccion, doctorId: dataReceta.doctorId, clienteId: dataReceta.pacienteId }));
            setTicket(ticket => ({ ...ticket, doctorId: dataReceta.doctorId, clienteId: dataReceta.pacienteId }));

        }
    }

    const handleConvenioChange = (convenioData) => {
        if (convenioData) {
            let cnv = getUsuario(convenioData);
            if (cnv) {
                if (cnv.convenios) {
                    let ids = cnv.convenios.map(con => con.convenioId);

                    setQR({
                        ...QR,
                        texto: "Convenio encontrado",
                        codigo: ids
                    });

                    setTimeout(() => {
                        setReceta({
                            ...receta,
                            abrir: false,
                            texto: "Convenio encontrado",
                            codigo: ids
                        });
                    }, 2000)
                } else {
                    setQR({
                        ...QR,
                        texto: "El código escaneado no coincide con el de un convenio",
                        codigo: null
                    });
                }
            } else {
                setQR({
                    ...QR,
                    texto: "Convenio no encontrado",
                    codigo: null
                });
            }
        }
    }

    //TODO PLan credito
    function handlePlanChange(val) {
        let plazoPago, diasPagar, numeroPlan, porcentajeInteres;

        planesCredito.listadoPlanesCredito.map(plan => {
            if (plan.planCreditoID === val) {
                plazoPago = val;
                diasPagar = plan.diasPlan;
                numeroPlan = plan.plazoCredito;
                porcentajeInteres = plan.porcentajeInteresDecimal;
            }
        })
        setTicket(ticket => ({ ...ticket, plazoPago, diasPagar, numeroPlan, porcentajeInteres }));
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false })
    }

    function ftFormularioAccept(val) {
        setFormulario({ ...formulario, abrir: false })
    }


    const productoProps = {
        options: productos,
        getOptionLabel: option => `${option.descripcion}${option.codigoProducto && option.codigoBarras ? '(' : ''} ${option.codigoProducto ? option.codigoProducto : ''}${option.codigoProducto && option.codigoBarras ? ' | ' : ''}${option.codigoBarras ? option.codigoBarras : ''}${option.codigoProducto && option.codigoBarras ? ')' : ''} `,
    };

    const clienteProps = {
        options: clientes,
        getOptionLabel: option => option.nombres + " " + option.apellidos,
        noOptionsText: 'No se encontraron registros'
    };

    //#endregion

    //#region useEffects
    //TODO uses

    //Obtiene el turno o mata la sesion
    useEffect(() => {

        if (ticket.turno === null && dturnos) {
            let tmpturn = dturnos.listadoTurnos.turnos.find(turn => {
                if (turn.turnoId === turnoState + '') {
                    return true;
                }
                else
                    return false;
            });

            if (tmpturn) {
                setTicket(ticket => ({ ...ticket, turno: tmpturn.descripcion || null, turnoId: Number(tmpturn.turnoId) || -2 }));
            } else {
                setKillSession(true);
            }
        }

    }, [dturnos, turnoState]);

    //si el listado de formas de cobro cambia se actualiza el front
    useEffect(() => {
        if (formasCobro != null || formasCobro != undefined) {
            let arr = [];
            let tmp = (JSON.parse(formasCobro.listadoFormaCobro.formaCobros[0].formaCobro));
            tmp.map(val => {
                arr.push(
                    {
                        filas: {
                            checkend: true,
                            descripcion: val.descripcion,
                            clave: val.clave,
                            costo: 0,
                            texto: "",
                            // referencia:useRef()
                        }
                    }
                );
            });
            setFormasPagos(arr);
        } else {
            setFormasPagos([]);
        }
    }, [formasCobro]);

    //Recalcula los datos cuando cambia el subtotal, iva, total  y datos
    useEffect(() => {
        ftRecalcularDatos();
        ftCalcularTotal(datos);
    }, [datos, iva, total, subtotal, ticket.porcentajeInteres, ticket.plazoPago]);

    //guarda los datos temporales cada que hay un cabio en el ticket
    useEffect(() => {

        if ((pendienteCobrar && ticket.turno) || (pendienteCobrar && ticket.serie && ticket.folio)) {

            let formas = "";
            let formaCantidad = "";
            let formaTexto = "";

            formasPago.map(forma => {
                if ((forma.filas.costo || 0) !== 0) {
                    formas += forma.filas.clave + ",";
                    formaCantidad += (forma.filas.costo || 0) + ",";
                    formaTexto += (forma.filas.descripcion || 0) + ",";
                }
            });

            formas = formas.substring(0, formas.length - 1);
            formaCantidad = formaCantidad.substring(0, formaCantidad.length - 1);
            formaTexto = formaTexto.substring(0, formaTexto.length - 1);

            addTicketTemporal({
                variables: {
                    ticket: {
                        ...ticket,

                        servicioId: ticket.servicioId.join(','),
                        codProd: ticket.codProd.join(','),
                        descripcion: ticket.descripcion.join(','),
                        cantidad: ticket.cantidad.join(','),

                        //#region porcentajes
                        porcentajeDescuentoConvenio: ticket.porcentajeDescuentoConvenio.join(','),
                        porcentajeDescuento: ticket.porcentajeDescuento.join(','),
                        porcentajeIva: ticket.porcentajeIva.join(','),
                        //#endregion

                        //#region datos producto
                        precioUnitario: ticket.precioUnitario.join(','),
                        precioUnitarioDescuento: ticket.precioUnitarioDescuento.join(','),
                        precioUnitarioDescuentoConvenio: ticket.precioUnitarioDescuentoConvenio.join(','),

                        descuentoUnitario: ticket.descuentoUnitario.join(','),
                        descuentoConvenioUnitario: ticket.descuentoConvenioUnitario.join(','),
                        descuentoUnitarioTotal: ticket.descuentoUnitarioTotal.join(','),
                        ivaUnitario: ticket.ivaUnitario.join(','),
                        ivaUnitarioDescuento: ticket.ivaUnitarioDescuento.join(','),
                        ivaUnitarioTotal: ticket.ivaUnitarioTotal.join(','),

                        precioUnitarioTotal: ticket.precioUnitarioTotal.join(','),


                        // por cantidad
                        descuentoCantidad: ticket.descuentoCantidad.join(','),
                        descuentoConvenioCantidad: ticket.descuentoConvenioCantidad.join(','),
                        precioDescuentoCantidad: ticket.precioDescuentoCantidad.join(','),
                        precioDescuentoConvenioCantidad: ticket.precioDescuentoConvenioCantidad.join(','),

                        //Totales
                        descuentoTotal: ticket.descuentoTotal.join(','),
                        ivaTotal: ticket.ivaTotal.join(','),
                        precioTotal: ticket.precioTotal.join(','),
                        //#endregion

                        //#region garantia
                        garantiaId: ticket.garantiaId.join(','),
                        garantia: ticket.garantia.join(','),
                        //#endregion

                        //#region clinica
                        almacenId: ticket.almacenId.join(','),
                        centroCostosId: ticket.centroCostosId.join(','),
                        //#endregion

                        //#region personas
                        doctorId: ticket.doctorId + "",
                        //#endregion

                        //#region formas cobro
                        formasCobro: formas,
                        formasCobroCantidad: formaCantidad,
                        formasCobroTexto: formaTexto,
                        //#endregion

                        comision: ticket.comision.join(',')
                    }
                }
            }).then(
                (input) => {
                    if (input)
                        if (input.data) {
                            let id = input.data.GuardarTicketTemporal.vendedorId;
                            setPendienteCobrar(false);
                        }
                }).catch(e => {
                    //setMensaje({ ...mensajeAdvertencia, texto: e.message, abrir: true })
                })
        }
    }, [datos, ticket]);

    //Recalcula las formas de pago
    useEffect(() => {
        ftRecalcularFormasPago()
    }, [ticket.totalTicket, ticket.porcentajeInteres, ticket.plazoPago]);

    //actualiza el importe de pago, el subtotal y el cambio en el ticket
    useEffect(() => {
        setTicket(ticket => ({ ...ticket, importePago: pagocon, subtotalTicket: subtotal, cambio: cambio }))
    }, [pagocon, subtotal, cambio]);

    //cuando carga el listado de productos se genera el select
    useEffect(() => {
        getDatos();
    }, [dproductos, dproductosDoctor, ticket.doctorId])

    //Si el folio cambia se actualiza en el ticket
    useEffect(() => {
        if (folios)
            if (folios.listadoFolios) {
                setTicket(ticket => ({ ...ticket, folioId: Number(folios.listadoFolios.folios[0].folioId), folio: folios.listadoFolios.folios[0].consecutivo + '' }))
            }
    }, [folios])

    //recalculo si hay datos temporales o cambian las formas de pago
    useEffect(() => {
        if (ticketTmpState !== null && !state.servicioId /*&& !referido*/) {
            if (formasPago.length > 0) {
                let formasP = ticketTmpState.formasPago;

                if (formasP.formasCobro) {
                    if (formasP.formasCobro.length > 0) {
                        formasPago.map((element, index) => {
                            let ind = formasP.formasCobro.indexOf(element.filas.clave);

                            if (ind >= 0) {
                                element.filas.costo = Number(Number(formasP.formasCobroCantidad[ind]).toFixed(2));
                                element.filas.texto = Number(formasP.formasCobroCantidad[ind]).toFixed(2);
                                element.filas.checkend = false;
                            }
                        });

                        if (doctorLoading) {
                            //TODO temporal
                            if (dproductos && ticketTmpState.partidasServId.length > 0) {
                                let datTmp = [];
                                dproductos.listadoProductosServicios.productosServicios.map(prod => {
                                    //Si recibe servicio por temporal se agrega a la tabla de partidas
                                    let index = ticketTmpState.partidasServId.indexOf(prod.servicioId);

                                    if (index >= 0) {
                                        ftRecalculo(1, Number(ticketTmpState.partidasCant[index]), prod, (resultado) => {
                                            let res = resultado();
                                            let datmp = handleAgregarClick(null, { ...res, ...state, servicioId: !state.servicioId ? res.servicioId : state.servicioId }, datTmp);

                                            datTmp.push(datmp);
                                        }, descConvenio);
                                    }
                                });

                                if (datTmp.length > 0) {
                                    datTmp.map((dt, indice) => {
                                        dt.no = indice + 1;
                                    })
                                    setDatos([...datTmp]);
                                }

                                setTicket(ticket => ({ ...ticket, ...ticketTmpState.encabezado, metodoPago: 'PUE' }));
                                setTicketTmpState(null);
                            }
                        }
                    }
                }
            }
        }
    }, [ticketTmpState, formasCobro, formasPago, doctorLoading])

    //porcentaje de la comision del doctor
    useEffect(() => {
        datos.map((dtmp, i) => {
            comisiones.map(cm => {
                if (cm.productosIdComision.includes(dtmp.servicioId)) {
                    ticket.comision[i] = Number(cm.porcentajeComision) / 100;
                }
            });
        });

    }, [comisiones])

    //Credito aplicable y usuario moroso
    useEffect(() => {
        let user = clientes.find(p => Number(p.usuarioId) === ticket.clienteId)

        if (user) {
            let select = null;

            selectCreditoAplicableCliente({ clienteID: Number(ticket.clienteId), monto: ticket.totalTicket || 0 }, null).then(v => {
                select = v;

                setlistaPlanCredito(select);
                setTicket(ticket => ({ ...ticket, metodoPago: 'PUE', plazoPago: null, diasPagar: null, numeroPlan: null }));

            }).catch(e => console.log(e.message));

            setClienteMoroso(user.moroso)
        }
    }, [ticket.clienteId, ticket.totalTicket])

    //Contraseña para vender a morosos
    useEffect(() => {
        async function fEffect() {
            let respuesta = await queryConfiguracionPuntoVenta();
            setPwdAdminActiva(respuesta.activarSeguridadPuntoVenta);
        } fEffect();
    }, []);

    useEffect(() => {

        let tmpcola = [];
        let cola = [];

        if (colaTickets?.listadoCola?.tickets) {
            colaTickets.listadoCola.tickets.forEach(tik => {
                if (!tmpcola.includes(tik.ticketEncabezadoId)) {
                    cola.push(tik);
                    tmpcola.push(tik.ticketEncabezadoId);
                }
            })
        }

        setListadoCola({
            contador: cola.length,
            listado:
                <List component="nav" aria-label="secondary mailbox folders" className={classes.colaTickets}>
                    {cola.length > 0 ?
                        cola.map(tick =>
                            <ListItem button onClick={() => {
                                setProcess('cola')

                                let seleccionados = colaTickets.listadoCola.tickets.filter(ti => ti.ticketEncabezadoId === tick.ticketEncabezadoId)

                                let ticketTmp = TicketTemporal(seleccionados);
                                setTicketTmpState({ ...ticketTmp });
                                setTicket(ticket => ({ ...ticket, referencia: tick.ticketEncabezadoId }))
                                handleClose();
                            }}>
                                <ListItemText secondary={tick.fechaCreacion?.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).+$/, `Folio: ${tick.ticketEncabezadoId} - Fecha: $4 $3/$2/$1`)} >
                                    <p style={{ fontSize: '15px' }}>
                                        Cliente: {tick.clienteId === -1 ? 'Publico general' : tick.cliente}

                                    </p>
                                    <p style={{ fontSize: '14px' }}>
                                        {tick.correo ? `Correo: ${tick.correo}` : ''}
                                    </p>
                                </ListItemText>
                            </ListItem>
                        ) :
                        <ListItem>
                            <ListItemText primary={`No hay ventas pendientes`} />
                        </ListItem>}
                </List>
        });

    }, [colaTickets])

    //#endregion

    turno = dturnos ? dturnos.listadoTurnos.turnos.find(turn => turn.turnoId == turnoState) : { descripcion: "" };

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: 'Seleccionar plan',
        onClose: ftFormularioClose,
        onAccept: ftFormularioAccept,
        onCancel: null
    });

    const camposFormulario = [
        {
            id: "planId",
            texto: "Plan de credito *",
            valor: listaPlanCredito.length !== 0 ? (ticket.plazoPago || listaPlanCredito[0].value) : [],
            tipo: "select",
            xs: 12,
            regexp: /^.+$/,
            onChange: (e) => handlePlanChange(e.target.value),
            opciones: listaPlanCredito
        }
    ]

    const [camposFormularioState, setCamposFormularioState] = useState(camposFormulario);

    const camposFormularioAutorizacion = [
        {
            id: "alerta",
            texto: "",
            valor: "El cliente seleccionado cuenta con adeudos, si desea realizar la venta debe ingresar la contraseña de autorización",
            tipo: "label",
            xs: 12,
            otros: {
                inputProps: {
                    disabled: true
                }
            }
        },
        {
            id: "passwordAdmin",
            texto: "Contraseña *",
            valor: null,
            tipo: "password",
            xs: 12,
            regexp: /^.+$/,
            ayuda: "Debe ingresar la contraseña de un administrador",
            onChange: (e) => handlePwdAdmin(e.target.value),
        }
    ]

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [listadoCola, setListadoCola] = useState(
        {
            contador: 0,
            listado: <ListItem>
                <ListItemText primary={`No hay ventas pendientes`} />
            </ListItem>
        });

    if (((ticket.turnoId !== null && ticket.turnoId !== undefined)) && !killSession) {
        // delete history.state;
        // delete props.location.state;
        window.history.replaceState(null, '')
    }

    window.tk = ticket
    //TODO html
    return (
        <MenuLateral titulo="Punto de Venta" menu={menu}>
            {
                loadingTemp ?
                    <div style={{ textAlign: "center", width: '100%' }}>

                        <CircularProgress />
                    </div>
                    :
                    <>
                        {
                            (turnoState.turnoId === false || killSession) && Redireccion
                        }

                        <EscanerReceta abrir={receta.abrir} onChange={handleRecetaChange} />

                        <EscanerQr abrir={QR.abrir} tipo={QR.tipo} onAccept={QR.onAccept} onCancel={QR.onCancel} onChange={handleConvenioChange} texto={QR.texto} />

                        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} subParrafo={mensaje.subParrafo} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

                        <Formulario campos={camposFormularioState} abrir={formulario.abrir} titulo={formulario.titulo} onClose={formulario.onClose} onAccept={formulario.onAccept} disableBackdropClick={true} datos={seleccion} />

                        <Grid container>
                            <Grid container item xs={12}>
                                <Encabezado titulo="Punto de Venta" />
                            </Grid>
                        </Grid>

                        <br />

                        <Grid container className={classes.body}>

                            <Grid container item xs={12} justify="space-between">
                                <Typography align="center" variant="h6" style={{ width: '100%' }}>
                                    {nombreClinica}
                                </Typography>
                            </Grid>

                            <Grid container item xs={12}>

                                <Grid item xs={4}>
                                    {/* ----Serie y Folio----- */}
                                    <SerieFolio series={dserie} folios={ticket.folio} />
                                </Grid>

                                <Grid item xs={4}>
                                    <Typography align="center" variant="subtitle1">
                                        Atiende: <b>{`${userToken.nombres} ${userToken.apellidos}`}</b>
                                    </Typography>
                                    <Typography align="center" >
                                        Turno: <b>{ticket.turno || ''}</b>
                                    </Typography>
                                </Grid>

                                <Grid container item xs={4} justify="flex-end">
                                    {/* ----Fecha y Hora---- */}
                                    <FechaHora />
                                </Grid>

                            </Grid>



                            <Grid container item xs={12}>

                            </Grid>
                        </Grid>

                        <br />

                        <Grid container direction="row" justify={"center"} alignItems="center" className={classes.body}>

                            {/* -------Cliente--------- */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <Grid item xs={11}>
                                    <Autocomplete value={clientes.find(p => Number(p.usuarioId) === ticket.clienteId) || null} {...clienteProps} id="cliente" autoHighlight onChange={ClienteChange} disabled={datos.length > 0 ? true : false}
                                        renderInput={params => (
                                            <TextField {...params} label="Cliente" margin="none" fullWidth inputRef={refs.cliente} onFocus={() => setRef({ ...refs, selected: "cliente" })} style={{ marginTop: '8px' }} />
                                        )} />
                                </Grid>
                            </Grid>

                            {/*-----------Doctores----------*/}
                            <Grid container item xs={3} sm={2} justify="center">
                                <Doctores id="doctor"
                                    value={ticket.doctorId}
                                    variables={{
                                        clinica: Number(_clinicaID),
                                        servicio: datos.length > 0 ? null : (seleccion.servicioId && seleccion.servicioId != -1 ? Number(seleccion.servicioId) : null),
                                        doctor: null,
                                        estatus: null
                                    }}
                                    tipo={seleccion.tipoProducto}
                                    onChange={DoctorChange}
                                    onFinishLoading={finishDoctorLoading}
                                    style={{ marginRight: 0, marginTop: '8px' }}
                                    xs={11}
                                    disabled={datos.length > 0} />
                            </Grid>

                            {/* Producto servicio material */}
                            <Grid container item xs={3} sm={3} md={3} justify="center">
                                <Grid item xs={11}>
                                    <Autocomplete
                                        id="producto" autoHighlight noOptionsText="Sin productos"
                                        onChange={ProductoChange} {...productoProps}
                                        value={productos.find(p => p.servicioId === seleccion.servicioId) || null}
                                        renderInput={params => (
                                            <TextField {...params} label="Producto / Servicio" fullWidth inputRef={refs.producto} onFocus={() => setRef({ ...refs, selected: "producto" })} style={{ color: 'red' }} style={{ marginTop: '8px' }} />
                                        )}
                                        renderOption={
                                            (option, { selected }) => (
                                                <div style={{ color: option.color }} >
                                                    {`${option.descripcion}${option.codigoProducto && option.codigoBarras ? '(' : ''} ${option.codigoProducto ? option.codigoProducto : ''}${option.codigoProducto && option.codigoBarras ? ' | ' : ''}${option.codigoBarras ? option.codigoBarras : ''}${option.codigoProducto && option.codigoBarras ? ')' : ''} `
                                                    }
                                                </div>
                                            )
                                        }
                                        disabled={loadServDoc ? true : false} />
                                </Grid>
                            </Grid>

                            {/* cantidad */}
                            <Grid container item xs={2} sm={1} justify="center">
                                <InputGrid xs={10} id="cantidad" value={seleccion.cantidad} texto="Cantidad" tipo="number"
                                    inputProps={{ min: 1 }}
                                    onChange={(e) => onCantidadChange(e)} inputRef={refs.cantidad}
                                    onFocus={() => setRef({ ...refs, selected: "cantidad" })}
                                    inputProps={{ style: { textAlign: 'right' } }}
                                    disabled={seleccion.servicioId === -1 || !seleccion.servicioId ? true : false}
                                    onClick={(e) => seleccionTexto(e)} />
                            </Grid>

                            {/* ------Almacenes---------- */}
                            <Grid container item xs={3} sm={2} justify="center">
                                {
                                    <InputGrid xs={11} id="almacenId" texto="Almacen" tipo="select" value={seleccion.almacenId} opciones={opcionesAlmacen} onChange={AlmacenChange} disabled={loadingAlm ? true : seleccion.inventariable ? false : true} />
                                }
                            </Grid>

                            {/* ------Centro de costos---------- */}
                            {/* <Grid container item xs={3} sm={2} justify="center">
                                {
                                    <InputGrid xs={11} id="centroCostosId" texto="Centro de costos" tipo="select" value={seleccion.centroCostosId || ''} opciones={opcionesCentroCostos} onChange={CentroChange} disabled={loadingAlm ? true : seleccion.inventariable ? false : true} />
                                }
                            </Grid> */}

                            {/* TODO ----Datos producto---- */}

                            {/* precio unitario */}
                            <Grid container item xs={3} sm={1} justify="center">
                                <InputGrid xs={11} id="precioU" texto="Precio s/IVA" value={formatoMoneda(seleccion.precioUnitario, 2)} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* Descuento */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <InputGrid xs={11} id="descuentoCantidad" texto="Descuento" value={formatoMoneda(seleccion.descuentoCantidad, 2)} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* Precio con descuento por cantidad */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <InputGrid xs={11} id="precioDescuentoCantidad" texto="P. c/descuento" value={formatoMoneda(seleccion.precioDescuentoCantidad, 2)} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* Descuento convenio por cantidad */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <InputGrid xs={11} id="descuentoConvenioCantidad" texto="Descuento Convenio" value={formatoMoneda(seleccion.descuentoConvenioCantidad, 2)} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* descripcionTasaIva */}
                            <Grid container item xs={2} sm={1} justify="center">
                                <InputGrid xs={11} id="descripcionTasaIva" texto="% IVA" value={seleccion.descripcionTasaIva || 0} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* Iva precio descuento convenio cantidad */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <InputGrid xs={11} id="ivaTotal" texto="IVA" value={formatoMoneda(seleccion.ivaTotal, 2)} disabled={true} propiedades={{ style: { textAlign: 'right' } }} InputLabelProps={{ style: { textAlign: 'right', width: '130%' } }} />
                            </Grid>

                            {/* Total por cantidad con descuentos e iva */}
                            <Grid container item xs={3} sm={2} justify="center">
                                <InputGrid xs={11} id="precioTotal" texto="Total" margen="none"
                                    value={formatoMoneda(seleccion.precioTotal, 2)} disabled={true}
                                    propiedades={{ style: { fontSize: 20, color: '#444', marginTop: 5, textAlign: 'right' } }}
                                    InputLabelProps={{ style: { fontSize: 20, color: '#333', marginTop: 7, textAlign: 'right', width: '130%' } }}
                                />
                            </Grid>

                            {/*TODO botones agregar y eliminar */}
                            <Grid container justify="flex-end" alignItems="center" item xs={11} style={{ marginBottom: 5 }} >
                                {
                                    (configuracion && configuracion.generarReceta) && <Button variant="contained" color="primary" onClick={(e) => handleRecetaClick(e)} style={{ padding: '1px 8px' }}>Receta</Button>
                                }

                                <Grid item xs={1}>&nbsp;</Grid>

                                <Button variant="contained" color="primary" onClick={(e) => handleAgregarClick(e)} ref={refs.add} onFocus={(e) => { setRef({ ...refs, selected: "add" }); }} className={seleccion.servicioId ? "pulso" : ""} style={{ padding: '1px 8px' }}>Agregar</Button>

                                <Grid item xs={1}>&nbsp;</Grid>

                                <Button variant="contained" color="primary" onClick={() => { setQR({ ...QR, abrir: true }) }} style={{ padding: '1px 8px' }}>Convenio</Button>

                                <Grid item xs={1}>&nbsp;</Grid>

                                <Button variant="contained" color="secondary" onClick={handleLimpiarPartidaClick} style={{ padding: '1px 8px' }}>Limpiar</Button>

                            </Grid>

                            {/*-----------//TODO Tabla de partidas-------------- */}
                            <Grid container justify="flex-start" item xs={11}>
                                <Tabla encabezado={false} columnas={columnas} datos={datos} claseEncabezado={{ padding: '4px' }} claseCelda={{ fontSize: 12, padding: '0 5px' }} />
                            </Grid>

                            <Grid container alignItems="center" justify="center">

                                {/* <InputGrid xs={2} id="credito" valor={ticket.metodoPago === 'PPD'} texto="Credito" margen="none" tipo="checkbox" onChange={handleChangeCredito} disabled={!(ticket.clienteId != -1 && listaPlanCredito.length !== 0 && datos.length !== 0 && listaPlanCredito[0]?.value !== null)} /> */}

                                {ticket.descuentoTotalFinalTicket >= 1 &&
                                    <Grid container item xs={6} justify="center">
                                        <Typography variant="h6" color="red" style={{ lineHeight: '1', color: 'red' }}>
                                            Usted ahorra: {formatoMoneda((ticket.descuentoTotalFinalTicket) || 0, 2)}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item container xs={12} justify="space-around" style={{ marginBottom: "5px", marginTop: '5px' }}>

                                {/*TODO Formas de pago */}
                                <Grid container justify="flex-start" item xs={10} sm={10} md={5} direction="row" style={{ margin: "10px" }}>
                                    <Grid container item xs={12} justify="center">
                                        <Typography color="textSecondary" variant="h6" >Formas de cobro disponibles</Typography>
                                    </Grid>
                                    {
                                        formasPago === null || formasPago === undefined || formasPago.length === 0 ?
                                            <Typography variant="h6" align="center" style={{ margin: '0 auto' }}>
                                                No se encontraron formas de cobro para la sucursal
                                            </Typography>
                                            : formasPago.map(val =>
                                                <React.Fragment key={val.filas.clave}>
                                                    <Grid item xs={6} sm={8} md={8}>
                                                        <FormControlLabel value={val.filas.clave} control={<Checkbox onChange={(evt) => handleChangeFormaPago(evt, val.filas.clave)} checked={!val.filas.checkend} />} label={val.filas.descripcion} key={val.filas.clave}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={4} sm={3} md={3}>
                                                        <FormControl fullWidth variant="filled" >
                                                            <Input
                                                                id={val.filas.clave}
                                                                style={{ paddingTop: "0px" }}
                                                                label="Number"
                                                                margin="none"
                                                                value={val.filas.texto}
                                                                onClick={(evt) => seleccionTexto(evt)}
                                                                onChange={(evt, valor) => handleChangeFormaMonto(evt, val.filas.clave)}
                                                                onBlur={(evt, valor) => {
                                                                    // ftValidarPago(val.filas, Number(evt.target.value.replace(/,/g, '')));
                                                                    ftValidarPago(val.filas, val.filas.costo);
                                                                }}

                                                                disabled={val.filas.checkend}
                                                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                    }
                                </Grid>

                                {/*TODO subtotal y total  */}

                                <div className={classes.root}>
                                    <div className={classes.section1}>

                                        <Divider variant="middle" />

                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    Subtotal:
                                    </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    {formatoMoneda(ticket.totalTicket > 0 ? ticket.subtotalTicket : 0, 2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    IVA:
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    {formatoMoneda(ticket.totalTicket > 0 ? ticket.ivaTotalTicket : 0, 2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        {ticket.porcentajeInteres &&
                                            <Grid container alignItems="center">
                                                <Grid item xs>
                                                    <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                        Interés:
                                                </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                        {formatoMoneda(ticket.totalTicket * ticket.porcentajeInteres || 0, 2)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Divider variant="middle" />

                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h5" style={{ lineHeight: '1' }}>
                                                    Total:
                                    </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    {formatoMoneda(ticket.totalTicket + (ticket.totalTicket * ticket.porcentajeInteres || 0), 2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h5" style={{ lineHeight: '1' }}>
                                                    Importe de pago:
                                    </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    {formatoMoneda(ticket.importePago, 2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid container alignItems="center">
                                            <Grid item xs>
                                                <Typography variant="h5" style={{ lineHeight: '1' }}>
                                                    Cambio:
                                    </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6" style={{ lineHeight: '1' }}>
                                                    {formatoMoneda(ticket.cambio, 2)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Divider variant="middle" />


                                        <Grid container direction="row" justify="center" item xs={12} style={{ paddingTop: 1 }}>

                                            <Button variant="contained" color="primary" onClick={handleCobrarClick} startIcon={<MonetizationOnIcon />}
                                                className={datos.length > 0 ? ticket.importePago < ticket.totalTicket ? "" : "pulso" : ""}
                                                disabled={datos.length > 0 ? ticket.metodoPago !== 'PPD' ? Number(Number(ticket.importePago).toFixed(2)) < Number(Number(ticket.totalTicket).toFixed(2)) ? true : false : false : true}
                                            >
                                                Cobrar
                                            </Button>
                                        </Grid>
                                    </div >
                                </div >

                                <Badge badgeContent={listadoCola.contador} color="error" style={{
                                    display: 'scroll', position: 'fixed', bottom: '50px', right: '15px'
                                }}>
                                    <Fab color="primary" aria-label="add" style={{
                                        display: 'scroll', position: 'fixed', bottom: '5px', right: '5px'
                                    }} onClick={handleClick}>
                                        <ListAltIcon />
                                    </Fab>
                                </Badge>

                                <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: 'top', horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'bottom', horizontal: 'center',
                                    }}
                                >

                                    {
                                        listadoCola.listado
                                    }
                                </Popover>
                            </Grid>
                        </Grid >
                    </>
            }
        </MenuLateral >
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        borderColor: '#D6DCDC'
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    section1: {
        margin: theme.spacing(0, 0),
    },
    section2: {
        margin: theme.spacing(2),
    },
    section3: {
        margin: theme.spacing(3, 1, 1),
    },
    body: {
        border: ' 0px solid #0d47a1',
        backgroundColor: 'white'
    },
    encabezado: {
        backgroundColor: 'white',
        borderRadius: '30px 30px 0px 0px',
        border: ' 1px solid #0d47a1',
        borderBottom: '0px solid #0d47a1',
        width: '90%',
        margin: '0 auto',
        padding: 1
    },
    colaTickets: {
        width: '360px'
    }
}));


export default withRouter(PuntoVenta);
