import React, { useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_CONVENIOS_SERVICIOS,LISTADO_CONVENIOS,LISTADO_PRODUCTOS_SERVICIOS } from '../../query';
import { ELIMINAR_CONVENIO_SERVICIO, AGREGAR_CONVENIO_SERVICIO, MODIFICAR_CONVENIO_SERVIIO} from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
import { useSelector } from 'react-redux';

//Iconos
import { AddIcon, EditIcon, DeleteForever,ThumbUpIcon } from '../../componentesGenericos/Icons';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
//Expresiones regulares
import { rgxNoVacio, rgxNumero, rgxPorcentaje } from '../../Helpers/constantes/Rgx';

const ftSelectFormat = (arreglo, {id, descripcion, otro}) => (arreglo.map(dato => {
    let resultado = { value: dato[id], label: dato[descripcion] };
    if(otro) resultado[otro] = dato[otro];
    return resultado;
}));

export default function ConvenioServicios(props) {
    const configuracion = useSelector(state => state.configuracion);
    let {maxPorcentajeDescto} = configuracion;
    let {history} = props;

    //#region queries
    let { loading, error, data, refetch } = useQuery(LISTADO_CONVENIOS_SERVICIOS, {
        variables: { limite: 1000000 },
        fetchPolicy: 'no-cache',
        onError: (e) =>{
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () =>{
            setFila(-1)
            setSeleccion(seleccionInicial)
            setErrorState(false)
        }
    });
    const [deleteConvenioServicio] = useMutation(ELIMINAR_CONVENIO_SERVICIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el convenio` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addConvenioServicio] = useMutation(AGREGAR_CONVENIO_SERVICIO, {
        onError: (e) => {
            throw new Error (e.message.replace("GraphQL error:", ""))
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el convenio` });
            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modConvenioServicio] = useMutation(MODIFICAR_CONVENIO_SERVIIO, {
        onError: (e) => {
            throw new Error (e.message.replace("GraphQL error:", ""))
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el convenio` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region states
    const dt = new Date();
    const seleccionInicial = {
        convenioServicioIdGeneral: null,
        convenioId: undefined,
        empresa: null,
        correo: null,
        tipoPago: null,
        opcionesPago: null,
        porcentajeDescuento: null,
        servicioId: null,
        servicios: [],
        descripcionServicios: null,
        precioUnitario: null,
        fechaAltaConvenio: null
    };

    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [serviciosDB,setServiciosDB] = useState([]);
    const [convenios,setConvenios] = useState([]);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [errorState, setErrorState] = useState(false);
    const [stateCopy, setStateCopy] = useState({});

    //#endregion

    //#region constantes

    const queryServicios = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        variables: { offset:0, tipo: 1,clinica: null, disponible:1 },

        onError: ({message}) => {
            let label = message.replace('GraphQL error: ', ' ')
            setServiciosDB([{ value: 0, label }]);
        },
        onCompleted: ({listadoProductosServicios:{productosServicios:data}}) => {
            setServiciosDB(ftSelectFormat(data, {
                id: "servicioId",
                descripcion: "servicioClinica"
            }));
        }
    })



    const queryConvenios = useQuery(LISTADO_CONVENIOS, {
        variables: { offset: 0, empresa: null, estatus: 1,correo: null },
        onError: ({message}) => {
            let label = message.replace('GraphQL error: ', ' ')
            setConvenios([{ value: 0, label }]);
        },
        onCompleted: ({listadoConvenios:{convenios:data}}) => {
            setConvenios(ftSelectFormat(data, {
                id: "convenioId",
                descripcion: "empresa"
            }));
        }
    })

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] :[
        {
            id: 'convenioServicioIdGeneral', label: 'convenioServicioId', minWidth: 0, hide: true
        },
        {
            id: 'convenioId', label: 'Convenio', minWidth: 100, align: 'center', textAlign: 'center',hide: true
        },
        {
            id: 'empresa', label: 'Empresa', minWidth: 200, align: 'left',textAlign: 'left', filtro:true,
            format: (valor) => {
                return valor ? valor.toString().replace(seleccion.convenioId, "").replace(seleccion.convenioId, seleccion.empresa): null;
            }
        },
        {
            id: 'correo', label: 'Correo', minWidth: 170, align: 'left',textAlign: 'left',
            hide:true,
        },
        {
            id: 'tipoPago', label: 'Tipo de pago', minWidth: 170, align: 'center', hide: true
        },
        {
            id: 'opcionesPago', label: 'Opciones de pago', minWidth: 170, align: 'center',
            hide:true
        },
        {
            id: 'servicios', label: 'Servicio', minWidth: 170, align: 'center',
          hide: true
        },
        {
            id: 'porcentajeDescuento', label: 'Porcentaje de descuento', minWidth: 50, align: 'right',textAlign: 'right',
            format: (valor) => {
                return `${valor}%`
            }

        },
        {
            id: 'servicioClinica', label: 'Servicio-Sucursal', minWidth: 170, align: 'left',textAlign: 'left',
            // component: 'Chip'
        },
        {
            id: 'precioUnitario', label: 'Precio unitario', minWidth: 170, align: 'center',hide: true
        }
    ];
    const menu = [
        { texto: "Asignar servicios", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar asignación", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar asignación", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Desplazarse a convenios", icono: <ThumbUpIcon />, onClick: (e) => redirige("/convenios")},
        { texto: "Desplazarse a servicios", icono: <LibraryBooksIcon />, onClick: (e) => redirige("/servicios")}
    ];

    const redirige=(texto)=>{
        history.push(texto);
    }

    const camposFormulario = [
        {
            id: "convenioServicioIdGeneral",
            texto: "convenioServicioId",
            valor: seleccion.convenioServicioId,
            tipo: "hidden",
        },
        {
            id: "convenioId",
            texto: "Convenio *",
            valor: seleccion.convenioId,
            tipo: "select",
            opciones: convenios,
            xs: 12,
            ayuda: 'Campo obligatorio',
            regexp: rgxNumero,
            propiedades:
            {
                readOnly: seleccion.convenioServicioIdGeneral ? true: false,
            },
        },
        {
            id: "empresa",
            texto: "empresa",
            valor: seleccion.empresa,
            tipo: "hidden",
        },
        {
            id: "correo",
            texto: "correo",
            valor: seleccion.correo,
            tipo: "hidden",
        },
        {
            id: "tipoPago",
            texto: "tipo de pago",
            valor: seleccion.tipoPago,
            tipo: "hidden",
        },
        {
            id: "opcionesPago",
            texto: "Opciones de pago",
            valor: seleccion.opcionesPago,
            tipo: "hidden",
        },
        {
            id: "servicios",
            texto: "Servicios *",
            valor: seleccion.servicios,
            tipo: "select",
            opciones: serviciosDB,
            multiselect: true,
            xs: 12,
            ayuda: "Debe seleccionar un servicio",
            regexp: rgxNoVacio,

        },
        {
            id: "descripcionServicio",
            texto: "Descripción del servicio",
            valor: seleccion.descripcionServicio,
            tipo: "hidden"
        },
        {
            id: "porcentajeDescuento",
            texto: "Porcentaje de descuento *",
            valor: seleccion.porcentajeDescuento ,
            tipo: "number",
            propiedades:{
                min: 0,
                max: Number((maxPorcentajeDescto) * 100)
            },
            otros:{
                InputProps:{
                    endAdornment: "%",
                  }
            },
            xs:12,
            ayuda: "El campo es obligatorio",
            regexp: rgxPorcentaje,
        },
        {
            id: "precioUnitario",
            texto: "Precio unitario",
            valor: seleccion.precioUnitario,
            tipo: "hidden",
        },
        {
            id: "fechaAltaConvenio",
            texto: "fecha de alta",
            valor: seleccion.fechaAltaConvenio,
            tipo: "hidden",
        }
    ];


    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoConveniosServicios?.convenioServicios;

    //#endregion
    //#region handlers
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.convenioServicioIdGeneral !== null) {
            setSeleccion({
                ...seleccion,
                ...stateCopy,
            });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un convenio" });
        }
    }


    function handleEliminarClick() {
        if (seleccion.convenioServicioIdGeneral !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Esta seguro de eliminar el convenio con la empresa "${seleccion.empresa}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un convenio" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setStateCopy(datos)
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }
    //#endregion

    //#region funciones
    function ftAgregar({ convenioId,servicios,porcentajeDescuento,cleanStateFormulario}) {
        async function ftAgregar()
        {
            try
            {
                if (servicios.length == 0) throw new Error ("Seleccione un servicio")
                if ( Number(porcentajeDescuento) <1 || Number(porcentajeDescuento) > Number((maxPorcentajeDescto) * 100)) throw new Error(`El porcentaje debe ser mayor a 0% y menor o igual a ${Math.round(Number((maxPorcentajeDescto )* 100) )}%`);
                setDisabledAccept(true);

                if(ftValidarObligatorios([convenioId,servicios,porcentajeDescuento]))
                {
                    setSeleccion({ ...seleccion,convenioId,servicios,porcentajeDescuento});
                 await   addConvenioServicio({
                    variables: {
                        convenioServicio: {
                            convenioId: Number(convenioId),
                            servicios: servicios.toString(),
                            porcentajeDescuento:parseFloat(porcentajeDescuento)
                        }
                    }
                });
                if(cleanStateFormulario) cleanStateFormulario();

                }
                else
                {
                    throw new Error("Faltan valores obligatorios o no tienen el formato correcto");
                }


            }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({ convenioId,servicios,porcentajeDescuento,cleanStateFormulario }) {
        async function ftModificar()
        {
            try {
                setDisabledAccept(true);

                let eliminados = [];
                let actualizar= [];
                let agregar = [];

                 for (let i in seleccion.servicios)
                    {
                      if(servicios.includes(seleccion.servicios[i]))
                        {
                           actualizar.push(seleccion.servicios[i]);
                        }
                      else
                        {
                           eliminados.push(seleccion.servicios[i]);
                        }
                    }


                  for (let i in servicios)
                    {
                    if(!actualizar.includes(servicios[i]))
                    {
                      agregar.push(servicios[i]);
                    }
                    }
                    if (ftValidarObligatorios([convenioId,servicios])===true)
                    {
                        if ( Number(porcentajeDescuento) <1 || Number(porcentajeDescuento) > Number((maxPorcentajeDescto) * 100)) throw new Error(`El porcentaje debe ser mayor a 0% y menor o igual a ${Math.round(Number((maxPorcentajeDescto )* 100) )}%`);

                        setSeleccion({ ...seleccion,convenioId,servicios,porcentajeDescuento});
                     await   modConvenioServicio({
                        variables: {
                            convenioServicio: {
                                convenioId: Number(convenioId),
                                eliminados:eliminados.toString(),
                                actualizar:actualizar.toString(),
                                agregar: agregar.toString(),
                                porcentajeDescuento:parseFloat(porcentajeDescuento)
                            }
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();

    }

    function ftEliminar() {
     async function ftEliminar()
     {
        setDisabledDelete(true);
        await deleteConvenioServicio({
            variables: {
                id: Number(seleccion.convenioId),
                servicios: seleccion.servicios.toString()
            } });
            setDisabledDelete(false);
     }   ftEliminar();
    }

    function ftValidarObligatorios(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }



    return (
        <MenuLateral titulo="Convenios" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete}/>

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Asignar servicios" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion}  datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

            <Tabla titulo="Listado de convenios con servicios" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro}  loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["empresa","correo","tipoPago","opcionesPago","servicios","porcentajeDescuento"]} />



        </MenuLateral>
    );
}
