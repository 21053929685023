import React, { useContext, useEffect, useState } from 'react';
//Context
import { CRMContext } from '../../../../context/NotificacionCreditos';
//Rgx
import { rgxLetras } from '../../../../Helpers/constantes/Rgx';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Core
import { Grid, TextField, InputLabel, Select, FormControl, MenuItem, FormHelperText, FormControlLabel, Radio } from '../../../../componentesGenericos/Core';
//QUERY
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_PLANES_CREDITO_FETCH } from '../../../../query/componentes/PlanesCredito';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Recordatorio(props) {

    //DECLARACIONES
    const classes = useStyles();
    let { seleccion } = props;
    let rgxLetrass = new RegExp(rgxLetras);
    const [planes, setPlanes] = useState('');
    const [datos, guardarDatos] = useContext(CRMContext);

    //Eventos

    const handleChangeGuardarStateDatos = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    const handleChangeGurdarRadioBotton = (event) => {
        let { name } = event.target;
        let value = event.target.value
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: false, valor: value
                    }
                }
            });
        }
    };

    const handleChangeGuardarStateDias = event => {
        let { name, value } = event.target;
        if (Number(value)>0 && Number(value) <13) {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: true, valor: value
                    }
                }
            });
        }
    }

    const handleChangeGuardarStateLetras = event => {
        let { name, value } = event.target;
        if (rgxLetrass.test(value)) {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                datos: {
                    ...datos.datos,
                    [name]: {
                        ...datos.datos[name],
                        error: true, valor: value
                    }
                }
            });
        }
    }

    //API
    const FetchPlanes = async () => {
        await FetchGrahpQL({
            query: LISTADO_PLANES_CREDITO_FETCH
        })
            .then(resp => {
                setPlanes(resp)
            })
            .catch((err) => {
            });
    }

    //Ciclo de vida
    useEffect(() => {
        guardarDatos({
            ...datos,
            datos: {
                ...datos.datos,
                descripcionPlazo: {
                    error: false, valor: seleccion.descripcionPlazo
                },
                dias: {
                    error: false, valor: seleccion.dias
                },
                estatus: {
                    error: false, valor: seleccion.estatus
                },
                plazoCreditoId: {
                    error: false, valor: seleccion.plazoCreditoId
                },
                recordarAntesDespues: {
                    error: false, valor: seleccion.recordarAntesDespues
                },
                recordatorioPagoId: {
                    error: false, valor: seleccion.recordatorioPagoId
                },
                tituloRecordatorio: {
                    error: false, valor: seleccion.tituloRecordatorio
                },
                descripcionRecordatorio: {
                    error: false, valor: seleccion.descripcionRecordatorio
                },
            }
        });
        FetchPlanes();
    }, []);


    //FUNCIONES
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        if (data === "") return null;
        if (data === undefined) return null;
        let row = [];

        if (data !== undefined) {
            data.listadoPlanesCredito.map(function (x) {
                row.push(<MenuItem key={`${x.planCreditoID}-plancredito`} value={x.planCreditoID} readOnly>{x.descripcionCredito}</MenuItem>);
                return true;
            });
            return row;
        }
    };


    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={1} container direction="row">

                        <Grid item xs={12} style={{ marginTop: "10px" }}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Planes de crédito</InputLabel>
                                <Select
                                    error={datos.datos.plazoCreditoId.error}
                                    value={datos.datos.plazoCreditoId.valor}
                                    labelId={`plazoCreditoId`}
                                    id={`planCreditoID`}
                                    onChange={handleChangeGuardarStateDatos}
                                    name="planCreditoID"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoga el plazo de crédito'}</MenuItem>
                                    {ftGenerarSelect(planes)}
                                </Select>
                                <FormHelperText>Debe seleccionar un plan de crédito</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ paddingTop: "0px!important" }}>
                            <TextField margin="dense" name="tituloRecordatorio" id={`tituloRecordatorio`} label="Título de recordatorio" type="text" style={{ width: "100%" }} required={true} helperText="El título de recordatorio es un campo obligatorio, tiene como límite 50 caracteres y además, debe ser alfabético  (título del correo)"
                                onChange={handleChangeGuardarStateLetras}
                                value={datos.datos.tituloRecordatorio.valor}
                                error={datos.datos.tituloRecordatorio.error}
                                inputProps={{ maxLength: 50 }} />

                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <TextField margin="dense" name="descripcionRecordatorio" id={`descripcionRecordatorio`} label="Descripción del recordatorio" type="text" style={{ width: "100%" }} required={true} helperText="La descripción del recordatorio es un campo obligatorio, tiene como límite 150 caracteres y además, debe ser alfabético"
                                inputProps={{ maxLength: 150 }}
                                onChange={handleChangeGuardarStateLetras}
                                value={datos.datos.descripcionRecordatorio.valor}
                                error={datos.datos.descripcionRecordatorio.error}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                            <TextField
                                name="dias" id={`dias`}
                                label="Días"
                                type="number"
                                style={{ width: "100%" }}
                                required={true}
                                helperText="Los días del recordatorio sirven para indicar cada cuanto tiempo se le notificará  al cliente del pago (1-12 días)"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{ inputProps: { min: 1, max: 12 } }}
                                variant="outlined"
                                onChange={handleChangeGuardarStateDias}
                                error={datos.datos.dias.error}
                                value={datos.datos.dias.valor}

                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <FormControl component="fieldset">

                                    <FormControlLabel value="0" name="recordarAntesDespues" control={<Radio />} label="Antes de la fecha de expiración"  onChange={handleChangeGurdarRadioBotton} checked={`${datos.datos.recordarAntesDespues.valor}` === "0"}
                                      onChange={handleChangeGurdarRadioBotton}/>
                                    <FormControlLabel value="1"  name="recordarAntesDespues" control={<Radio />} label="Después de la fecha de expiración"   onChange={handleChangeGurdarRadioBotton} checked={`${datos.datos.recordarAntesDespues.valor}` === "1"}/>

                            </FormControl>
                        </Grid>

                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Recordatorio;
