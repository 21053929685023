import gql from 'graphql-tag';

//#region Cajas
//=========Cajas=============
const LISTADO_CAJAS=gql`
query listaCajas($limite:Int,$pagina:Int)
{
listadoCajas(limite:$limite, pagina:$pagina){
  cajas
  {
    cajaId
    sucursalId
    numeroCaja
    responsable
    cobrarEn
    folioTicket
    turno
    numeroTurnos
    folioId
  }
  registros
  paginas
}
}`;

const OBTENER_CAJA=`
query obtenerCaja($id: ID!)
{
  obtenerCaja(id:$id)
  {
    cajaId
    sucursalId
    numeroCaja
    responsable
    cobrarEn
    folioTicket
    turno
    numeroTurnos
    folioId
  }
}`;

 //#endregion
//=====================================================

export {
    LISTADO_CAJAS,
    OBTENER_CAJA
}
