import React, { useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
import { LISATADO_ESTATUS_AGENDA } from '../../query';
import { NUEVO_ESTATUS_AGENDA, MODIFICA_ESTATUS_AGENDA, ELIMINAR_ESTATUS_AGENDA } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';
import AlarmIcon from '@material-ui/icons/Alarm';

import { Colores } from './Colores';

import { ftValidarCampos } from '../../Helpers/constantes/Config/Helper';
import { rgxHexadecimal, rgxSoloLetras } from '../../Helpers/constantes/Rgx';
import { trim } from '../../Helpers';

function EstatusAgenda({ history }) {

    const { loading, error:{message} = {}, data, refetch } = useQuery(LISATADO_ESTATUS_AGENDA, {
        variables: { limite: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted:()=>{
            setErrorState(false);
        }
    });

    const [deleteEstatus] = useMutation(ELIMINAR_ESTATUS_AGENDA, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el estatus de la agenda "${seleccion.descripcionEstatus}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addEstatus] = useMutation(NUEVO_ESTATUS_AGENDA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el estatus` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [updateEstatus] = useMutation(MODIFICA_ESTATUS_AGENDA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el estatus "${seleccion.descripcionEstatus}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const seleccionInicial = {
        estatusID: null,
        descripcionEstatus: null,
        colorEstatus: "",
        estatusPredeterminado: null,
        citaNoModificable: null,
        omitirValidacion: null,
        redirigirPuntoVenta: null
    };

    const disabledInicial = {
        estatusPredeterminado: false,
        citaNoModificable: false,
        omitirValidacion: false,
        redirigirPuntoVenta: false,
    };

    //#region states
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [disabledCheckbox, setDisabledCheckbox] = useState(disabledInicial);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [stateCopy, setStateCopy] = useState({});
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    //#endregion

    const columnas = errorState ? [{ id: 'msj', label: '', format: valor => (<p style={{ fontSize: 18, margin: 0 }}>{valor}</p>) }]
    : [
        { id: 'estatusID', label: 'ID', minWidth: 0, hide: true },
        { id: 'descripcionEstatus', label: 'Descripción', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
        { id: 'colorEstatus', label: 'Color', minWidth: 170, align: 'center', component: "CuadroColor" },
        { id: 'estatusPredeterminado', label: 'Predeterminado', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'horaNoModificable', label: 'Hora no modificable', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'citaNoModificable', label: 'Cita no modificable', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'omitirValidacion', label: 'Omitir validación', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'redirigirPuntoVenta', label: 'Redirigir al punto de venta', minWidth: 170, align: 'center', component: "Checkbox" },
    ];

    const menu = [
        { texto: "Agregar estatus", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar estatus", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar estatus", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Desplazarse a la configuración para programar cambio automático de estatus", icono: <AlarmIcon />, onClick: (e) => redirige("/cambioEstatus") }
    ];

    const camposFormulario = [
        {
            id: "estatusID",
            texto: "estatusID",
            valor: seleccion.estatusID,
            tipo: "hidden"
        },
        {
            id: "descripcionEstatus",
            texto: "Descripción *",
            valor: seleccion.descripcionEstatus,
            tipo: "text",
            xs: 12,
            ayuda: "Solo es posible capturar letras",
            regexp: rgxSoloLetras
        },
        {
            id: "colorEstatus",
            texto: "Color *",
            // valor: !seleccion.colorEstatus ? { label: "", value: 0 } : Colores.find(val => val.value === seleccion.colorEstatus),
            valor: seleccion.colorEstatus,
            tipo: "select",
            opciones: Colores,
            // propiedades: {
            //     options: Colores,
            //     getOptionLabel: option => option.label
            // },
            xs: 12,
            ayuda: "Seleccione un color válido",
            regexp: rgxHexadecimal,
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },

        },
        {
            id: "estatusPredeterminado",
            texto: "Predetermindo",
            valor: seleccion.redirigirPuntoVenta ? false : seleccion.estatusPredeterminado,
            tipo: "checkbox",
            onChange: handleChangeCheckbox,
            xs: 12,
            disabled: seleccion.redirigirPuntoVenta
        },
        {
            id: "horaNoModificable",
            texto: "Impedir la modificación del horario de las citas con este estatus",
            valor: seleccion.redirigirPuntoVenta ? false : seleccion.horaNoModificable,
            tipo: "checkbox",
            onChange: handleChangeHoraNoModificableCheckbox,
            xs: 12,
            disabled: seleccion.redirigirPuntoVenta || seleccion.citaNoModificable
        },
        {
            id: "citaNoModificable",
            texto: "Impedir la modificación de las citas con este estatus",
            valor: seleccion.redirigirPuntoVenta ? seleccion.redirigirPuntoVenta : (seleccion.horaNoModificable ? false : seleccion.citaNoModificable),
            tipo: "checkbox",
            onChange: handleChangeNoModificableCheckbox,
            xs: 12,
            disabled: seleccion.horaNoModificable
        },
        {
            id: "omitirValidacion",
            texto: "Omitir validaciones en las citas con este estatus",
            valor: seleccion.redirigirPuntoVenta ? false : seleccion.omitirValidacion,
            tipo: "checkbox",
            onChange: handleChangeOmitirValidacionCheckbox,
            xs: 12,
            disabled: seleccion.redirigirPuntoVenta
        },
        {
            id: "redirigirPuntoVenta",
            texto: "Redirigir al punto de venta",
            valor: seleccion.estatusPredeterminado ||  seleccion.omitirValidacion ? false : seleccion.redirigirPuntoVenta,
            tipo: "checkbox",
            onChange: handleChangeRedirigirPuntoVentaCheckbox,
            xs: 12,
            disabled: seleccion.estatusPredeterminado ||  seleccion.omitirValidacion
        }
    ];
    let sinRegistros = [{ msj: message ? message : "No se encontraron registros" }];
    const listado = errorState ? sinRegistros : (data ? (data.listadoEstatusCita ? data.listadoEstatusCita.estatusCitas : sinRegistros) : sinRegistros);

    //#region handlers
    const redirige = (texto)=>{
        history.push(texto);
    }

    function handleChangeCheckbox ({target:{checked:estatusPredeterminado}}) {
        setSeleccion({
            ...seleccion,
            estatusPredeterminado
        });
    }

    function handleChangeHoraNoModificableCheckbox ({target:{checked:horaNoModificable}}) {
        setSeleccion({
            ...seleccion,
            horaNoModificable
        });
    }

    function handleChangeNoModificableCheckbox ({target:{checked:citaNoModificable}}) {
        setSeleccion({
            ...seleccion,
            citaNoModificable
        });
    }

    function handleChangeOmitirValidacionCheckbox ({target:{checked:omitirValidacion}}) {
        setSeleccion({
            ...seleccion,
            omitirValidacion
        });
    }

    function handleChangeRedirigirPuntoVentaCheckbox ({target:{checked:redirigirPuntoVenta}}) {
        setSeleccion({
            ...seleccion,
            redirigirPuntoVenta
        });
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.estatusID) {
            setSeleccion({ ...stateCopy });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un estatus" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.estatusID) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el estatus "${seleccion.descripcionEstatus}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un estatus" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setStateCopy(datos); //Copia del state
            setFila(index);
        } else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function ftAgregar({descripcionEstatus, colorEstatus, estatusPredeterminado, citaNoModificable, omitirValidacion, redirigirPuntoVenta, horaNoModificable, cleanStateFormulario}) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                if (!ftValidarCampos([trim(descripcionEstatus), colorEstatus])) throw new Error("Faltan valores obligatorios o no tienen el formato correcto");

                estatusPredeterminado = typeof estatusPredeterminado === "boolean" ? estatusPredeterminado : false;
                horaNoModificable = typeof horaNoModificable === "boolean" ? (omitirValidacion ? false : horaNoModificable) : false;
                omitirValidacion = typeof omitirValidacion === "boolean" ? (horaNoModificable ? false : omitirValidacion) : false;
                redirigirPuntoVenta = ((estatusPredeterminado || omitirValidacion) ? false : (typeof redirigirPuntoVenta === "boolean" ? redirigirPuntoVenta : false));
                citaNoModificable = (redirigirPuntoVenta ? redirigirPuntoVenta : (typeof citaNoModificable === "boolean" ? citaNoModificable : false));
                if(redirigirPuntoVenta) { estatusPredeterminado = !redirigirPuntoVenta; omitirValidacion = !redirigirPuntoVenta; }

                await addEstatus({
                    variables: {
                        descripcionEstatus: trim(descripcionEstatus),
                        colorEstatus,
                        estatusPredeterminado,
                        citaNoModificable,
                        omitirValidacion,
                        redirigirPuntoVenta,
                        horaNoModificable
                    }
                });

                if(cleanStateFormulario) cleanStateFormulario();

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();
    }

    function ftModificar({descripcionEstatus, colorEstatus, estatusPredeterminado, citaNoModificable, omitirValidacion, redirigirPuntoVenta, horaNoModificable, cleanStateFormulario}) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);

                estatusPredeterminado = typeof estatusPredeterminado === "boolean" ? estatusPredeterminado : false;
                horaNoModificable = typeof horaNoModificable === "boolean" ? (omitirValidacion ? false : horaNoModificable) : false;
                omitirValidacion = typeof omitirValidacion === "boolean" ? (horaNoModificable ? false : omitirValidacion) : false;
                redirigirPuntoVenta = ((estatusPredeterminado || omitirValidacion) ? false : (typeof redirigirPuntoVenta === "boolean" ? redirigirPuntoVenta : false));
                citaNoModificable = (redirigirPuntoVenta ? redirigirPuntoVenta : (typeof citaNoModificable === "boolean" ? citaNoModificable : false));
                if(redirigirPuntoVenta) { estatusPredeterminado = !redirigirPuntoVenta; omitirValidacion = !redirigirPuntoVenta; }

                if (!ftValidarCampos([seleccion.estatusID, trim(descripcionEstatus), colorEstatus])) throw new Error("Faltan valores obligatorios o no tienen el formato correcto");

                await updateEstatus({
                    variables: {
                        estatusID: Number(seleccion.estatusID),
                        descripcionEstatus: trim(descripcionEstatus),
                        colorEstatus,
                        estatusPredeterminado,
                        citaNoModificable,
                        omitirValidacion,
                        redirigirPuntoVenta,
                        horaNoModificable
                    }
                });

                if(cleanStateFormulario) cleanStateFormulario();

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteEstatus({ variables: { estatusID: Number(seleccion.estatusID) } });
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {}) {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.error(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    return(
        <MenuLateral titulo="Estatus de la agenda" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={`${seleccion.estatusID ? "Modificar" : "Agregar"} estatus`} onClose={formulario.onClose} onAccept={formulario.onAccept} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

            <Tabla titulo="Estatus de la agenda" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcionEstatus", "colorEstatus"]} />

        </MenuLateral>
    );
}

export default EstatusAgenda;
