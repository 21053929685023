import React, { useState } from 'react';

//Router
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_EXISTENCIAS, LISTADO_PRODUCTOS_SERVICIOS, LISTADO_CLINICA_MATRIZ, LISTADO_CONCEPTOS } from '../../../query';
import { NUEVO_INVENTARIO, MODIFICAR_ENTRADAS_INVENTARIO, ELIMINAR_INVENTARIO } from '../../../mutation';

//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';
import { IconButton } from '@material-ui/core';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../../Helpers/constantes';
import {seleccionExistencias} from '../Constantes'

//REDUX
import { useSelector, useDispatch } from 'react-redux';

//Iconos
import { AddIcon, EditIcon, DeleteForever, AddLocationIcon } from '../../../componentesGenericos/Icons';
import { Phone, Apartment } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';

//Helpers
import { rgxNoVacio, rgxNumero } from '../../../Helpers/constantes/Rgx';
//Formatos
import { cuatroCaracteres } from '../../../Helpers/constantes/formatos';
// import { seleccionInicial } from '../../Usuarios/Constantes';
// import { useSelector, useDispatch } from 'react-redux';
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';

export default function Existencias() {

    let { id, clinicId } = useParams();
    const productoIdRef = React.useRef();
    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let userToken = DecodificarToken(userState);
    // REGION STATES
    const formularioModificarState = (ftFormularioClose) => {
        return{
            abrir: false,
            titulo: "Formulario",
            onClose: ftFormularioClose,
            onAccept: null,
            onCancel: null
        }
    };

    const [seleccion, setSeleccion] = useState(seleccionExistencias);

    const [productolist, setProductoList] = useState([{ nombre: 'No se encontraron productos', id: null }]);

    const [serviciolist, setServicioList] = useState([{ nombre: 'No se encontraron productos', id: null }]);

    const [campo, setCampo] = React.useState('');

    const [cmatriz, setCmatriz] = React.useState('');

    const [formulariomodificar, setFormularioModificar] = useState(formularioModificarState);

    const [actualizar, setActualizar] = useState(false);

    const [fila, setFila] = useState(-1);

    const [filtro, setFiltro] = useState("");

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [entrada, setEntrada] = useState([]);
    const[entradas, setEntradas] = useState([]);

    const [errorState, setErrorState] = useState(false);

    //END REGION STATES

    const { loading, error, data, refetch } = useQuery(LISTADO_EXISTENCIAS, {
        variables: { limite: 100, pagina: 1, clinicaId: null, existenciaId: null, encabezadoId: Number(id) },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            setErrorState(true);

        },
        onCompleted: (e) => {
            setErrorState(false);
        }
    });

    const { data: dataProductos } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        variables: { offset: 0, tipo: 2, disponible: 1, clinica: clinicId ? Number(clinicId) : null },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
            if (dataProductos) {
                setProductoList(dataProductos.listadoProductosServicios.productosServicios)
            }
        }
    });

    // const { data: dataServicios } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
    //     variables: { offset: 0, tipo: 6, disponible: 1, clinica: clinicId ? Number(clinicId) : null },
    //     onError: (e) => {
    //         // if (!e.message.includes('No se encontraron registros'))
    //         //     setErrorState(true);
    //     },
    //     onCompleted: () => {
    //         // setErrorState(false);
    //         if (dataServicios) {
    //             setServicioList(dataProductos.listadoProductosServicios.productosServicios)
    //         }
    //     }
    // });

    const { data: dataConceptoEntrada } = useQuery(LISTADO_CONCEPTOS, {
        variables: { limite: 100, tipo: 1, pagina: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);

            let opcionesConcepto = dataConceptoEntrada ?.listadoConceptos ?.conceptoInventario;
            // .splice(1, 15);


            let opcionesConceptoEntrada = opcionesConcepto.map(valor => ({
                label: valor.descripcion,
                value: Number(valor.conceptoId)
            }))

            setEntrada(opcionesConceptoEntrada)

        }
    });

    const { data: dataEntrada } = useQuery(LISTADO_CONCEPTOS, {
        variables: { limite: 100, tipo: 3, pagina: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });


    const { data: dataConceptoSalida } = useQuery(LISTADO_CONCEPTOS, {
        variables: { limite: 100, tipo: 2, pagina: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);

        },
        onCompleted: () => {
            setErrorState(false);

        }
    });

    const { data: dataClinicaMatriz } = useQuery(LISTADO_CLINICA_MATRIZ, {
        variables: { matriz: true },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (!e.message.includes('No se encontraron registros'))
                setErrorState(true);



        },
        onCompleted: () => {
            setErrorState(false);

            let matrizClinica = dataClinicaMatriz ?
                dataClinicaMatriz.listadoClinicas.clinicas.map(valor => ({
                    label: valor.nombreClinica,
                    value: valor.clinicaId
                })) : null

            let matriz = matrizClinica[0].value;

            setCmatriz(Number(matriz));
        }
    });


    const [addInventario] = useMutation(NUEVO_INVENTARIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se Agrego correctamente al inventario` });

            setSeleccion({ ...seleccion, ...seleccionExistencias });
            // setSeleccionclinica2({...seleccionclinica2, ...seleccionExistencias})
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modInventario] = useMutation(MODIFICAR_ENTRADAS_INVENTARIO,{
        onError:(e) =>{
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted:() =>{
            ftFormularioModificarClose();

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se realizó correctamente la ${campo === 0? "entrada" : "salida"} en el producto ${seleccion.descripcion}` });
            refetch();
            setSeleccion({ ...seleccion, ...seleccionExistencias });
            setFila(-1);
            setActualizar(true);
        }
    })
    // END  QUERY AND MUTATIONS

    let opciones = productolist ? productolist : []

    // REGION CONSTANTES
    const productosProps = {
        options: opciones,
        getOptionLabel: option => option.descripcion,
        noOptionsText:'No se encontraron registros'
    }

    let tipodemovimiento = ['Entrada', 'Salida'];

    let OpcionesTipoMov = tipodemovimiento.map((valor, indice) => ({
        label: valor,
        value: indice
    }));


    let opcionesConEntrada = dataEntrada?
    dataEntrada.listadoConceptos.conceptoInventario.map(valor => ({
        label: valor.descripcion,
        value: Number(valor.conceptoId)
    })): []

    let opcionesConceptoSalida = dataConceptoSalida ?
        dataConceptoSalida.listadoConceptos.conceptoInventario.map(valor => ({
            label: valor.descripcion,
            value: Number(valor.conceptoId)
        })) : []

    const handleChange = event => {
        // setCampo(event.target.value);
        seleccion.entradas = 0;
        seleccion.salidas = 0;
    };

    const camposFormulario = [
        {
            id: "servicioId",
            texto: "Producto *",
            valor: productolist ? seleccion.servicioId ? productolist.find(val => Number(val.servicioId) == Number(seleccion.servicioId)) : -1 : -1,
            tipo: "autocomplete",
            regexp: rgxNoVacio,
            ayuda: "Campo obligatorio",
            xs: 12,
            propiedades: productosProps,
            onChange: handleProductoChange
        },
        {
            id: "conceptoId", texto: "Concepto *", valor: seleccion.conceptoId, tipo: "select", opciones: opcionesConEntrada , xs: 12
        },
        {
            id: "existenciaInicial", texto: "Existencia Inicial *", valor: seleccion.existenciaInicial, tipo: "text", xs: 12, regexp: rgxNumero

        },
        {
            id: "entradas", texto: "Entradas", valor: 0, tipo: "hidden",
        },
        {
            id: "salidas", texto: "Salidas", valor: 0, tipo: "hidden",

        },
        {
            id: "referencia", texto: "Referencia *", valor: seleccion.referencia, tipo: "text", xs: 12, regexp: rgxNoVacio,
            formato: cuatroCaracteres, ayuda: 'Campo obligatorio (Serie y folio de documento "mínimo 4 caracteres")',

        },
    ];

    const FormularioEntradas = [
        {
            id: "conceptoInvId", texto: "Tipo de movimiento *", valor: campo === 1 ? 1 : 0, tipo: "hidden", opciones: OpcionesTipoMov, xs: 12,
        },
        {
            id: "conceptoId", texto: "Concepto *", valor: seleccion.conceptoId, tipo: "select", opciones: campo !== 1 ? entrada : opcionesConceptoSalida , xs: 12
        },
        {
            id: "entradas", texto: "Cantidad de entrada *", valor: "", tipo: campo !== 1 ? "text" : "hidden" , xs: 12, regexp: rgxNumero
        },
        {
            id: "salidas", texto: "Cantidad de salida *", valor: "", tipo: campo === 1 ? "text" : "hidden", xs: 12, regexp: rgxNumero
        },
        {
            id: "referencia", texto: "Referencia *", valor: "", tipo: "text", xs: 12, regexp: rgxNoVacio

        },
    ];



    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }]
        : [
            { id: 'existenciaId', label: "existenciaId", minWidth: 0, hide: true },
            { id: 'descripcion', label: "Producto", minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'existenciaInicial', label: "Existencia inicial", minWidth: 100, align: 'right', textAlign: 'right' },
            { id: 'entradas', label: "Entradas", minWidth: 100, align: 'right', textAlign: 'right' },
            { id: 'salidas', label: "Salidas", minWidth: 100, align: 'right', textAlign: 'right' },
            { id: 'existenciaActual', label: "Existencia actual", minWidth: 100, align: 'right', textAlign: 'right' },
            // { id: 'referencia', label: "Referencia", minWidth: 100, align: 'center', textAlign: 'center', filtro: true },

        ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoExistencias?.Existencias;


    const menu = [
        { texto: "Inventario inicial", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Entradas", icono: <SystemUpdateAltIcon />, onClick: () => handleEntrada() },
        { texto: "Salidas", icono: <PresentToAllIcon />, onClick: () => handleSalida() },
        // { texto: "Eliminar producto", icono: <DeleteForever />, onClick: () => handleEliminar() },
        { texto: "Regresar", icono: <ArrowBackIosIcon />, onClick: () => window.location.href = "/inventario" }
    ];





    // END REGION CONSTANTES

    // REGION ANDLERS

    function handleBusquedaChange(texto) {
        setFiltro(texto)
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setFila(index);
        }
        else {
            // setSeleccion(seleccionExistencias);
            // setFila(-1);
            window.location.href = "/movsalmacen/" + seleccion.encabezadoId+"/"+seleccion.servicioId
        }
    }

    function handleProductoChange(e, val) {
        productoIdRef.current = val ? Number(val.servicioId) : null;
        setSeleccion({ ...seleccion, servicioId: val ? Number(val.servicioId) : null })
    }

    function handleAgregarClick() {
        setSeleccion(seleccionExistencias)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: (state) => ftAgregar(state) });
    }

    function handleEntrada() {
        setCampo(0);
        if (seleccion.existenciaId !== null) {
            setSeleccion({...seleccion, conceptoId: null, entradas: 0, salidas: 0})
            // seleccion.entradas = 0;
            // seleccion.salidas = 0;

            setFormularioModificar({ ...formulariomodificar, abrir: true, onAccept: ftModificar });


        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar un producto" });
        }
    }

    function handleSalida() {
        setCampo(1);
        if (seleccion.existenciaId !== null) {
            setSeleccion({...seleccion, conceptoId: null, entradas: 0, salidas: 0})

            // seleccion.entradas = 0;
            // seleccion.salidas = 0;
            setFormularioModificar({ ...formulariomodificar, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar un producto" });

        }
    }

    function handleEliminar() {
        if (seleccion.existenciaId !== null) {
            // setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `Esta seguro de eliminar "${seleccion.producto}" del inventario?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar un producto" });
        }
    }

    // END REGION ANDLERS

    /// REGION FUNCIONES
    function ftAgregar({ clinicaId, existenciaInicial, existenciaActual = existenciaInicial, cleanStateFormulario, servicioId, referencia,conceptoId }) {

        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                clinicaId = clinicaId || seleccion.clinicaId;

                if (Number(existenciaInicial) >= 1) {

                    if (ftValidar([ servicioId, existenciaInicial, referencia,conceptoId ])) {

                        await addInventario({
                            variables: {
                                existencia: {
                                    // existenciaId:null,
                                    encabezadoId: Number(id),
                                    servicioId: Number(servicioId.servicioId),
                                    existenciaInicial: Number(existenciaInicial),
                                    entradas: 0,
                                    salidas: 0,
                                    existenciaActual: Number(existenciaInicial),
                                    conceptoId: conceptoId,
                                    empresaId: Number(cmatriz),
                                    referencia: referencia,
                                    usuarioId: userToken.usuarioId
                                }
                            }
                        });
                        if (cleanStateFormulario) cleanStateFormulario();
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                    }
                    setDisabledAccept(false);
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "La existencia inicial no puede ser menor a 1" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();
    }

    function ftModificar({ encabezadoId, clinicaId, almacenId, servicioId, tallaId, loteId, centroCostosId, pedimento, existenciaInicial, entradas = 0, salidas = 0, existenciaActual, conceptoId,empresaId, referencia, cleanStateFormulario }) {

        async function ftModificar() {
            try {
                setDisabledAccept(true);

                conceptoId = conceptoId || seleccion.conceptoId;

                if(Number(entradas) > 0 || Number(salidas) > 0){
                if (ftValidar([seleccion.existenciaId, conceptoId, entradas, salidas, referencia ]) === true) {
                    await modInventario({
                        variables: {
                            existencia: {
                                existenciaId:Number(seleccion.existenciaId),
                                encabezadoId: Number(seleccion.encabezadoId),
                                servicioId: Number(seleccion.servicioId),
                                existenciaInicial: Number(seleccion.existenciaInicial),
                                entradas: Number(entradas),
                                salidas: Number(salidas),
                                existenciaActual: Number(seleccion.existenciaActual),
                                conceptoId: Number(conceptoId),
                                empresaId: Number(seleccion.empresaId),
                                referencia: referencia,
                                usuarioId: Number(userToken.usuarioId)
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                    refetch();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            }else {
                setMensaje({ ...mensaje, ...mensajeError, texto: `La cantidad no debe ser igual o menor a cero` })
                setCampo("");
            }
            setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }



    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftFormularioModificarClose() {
        setFormularioModificar({ ...formulariomodificar, abrir: false });
    }

    // END REGION FUNCIONES
    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) });
        setActualizar(false);
    }

    return (
        <MenuLateral titulo="Entradas y salidas" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto}
                onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Existencias" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} cleanAwait={true} />

            <Formulario
                abrir={formulariomodificar.abrir}
                campos={FormularioEntradas}
                titulo={campo === 1 ? "Salida" : "Entrada"}
                onClose={ftFormularioModificarClose}
                onAccept={formulariomodificar.onAccept}
                datos={seleccion}
                disabledAccept={disabledAccept}
                cleanAwait={true} />

            <Tabla titulo="Existencias" titleColumna="Dar doble clic para ver los movimientos" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.mensaje} titleResponsive={["descripcion", "existenciaActual"]} />


        </MenuLateral>
    );


}
