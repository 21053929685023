import React, { useContext,useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
//HELPERS
import {
    verificaValores,
    formatearCadena
} from "../../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../../Helpers/Validacion/ValidacionesCadenasClose";
//Iconos
import {VisibilityIcon,VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//CORE
import {  Button, CardContent, CardActions, Card, Grid, TextField, InputLabel, Select, FormControl, Input,FormHelperText,InputAdornment,IconButton } from '../../../../componentesGenericos/Core';
//QUERY
import {RECUPERAR_CONTRASEÑA} from '../../../../mutation';
//RGX
import {rgxPassword} from '../../../../Helpers/constantes/Rgx';
//CONTEXT
import { CRMContext } from '../../../../context/Perfil';
//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONSTANTES
import { password } from './../../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial,mensajeExito } from '../../../../Helpers/constantes';
const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1:{
        marginBottom:"10px"
    }

});

function Password(props) {

    //DECLARACIONES
    const classes = useStyles();
    let  rgxPasswords=new RegExp(rgxPassword);
    const [boton,setBoton] = useState(false);
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(password);
    const [mensaje, setMensaje] = useState(mensajeInicial);

    //EVENTOS
    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }
    const EventModificarCedula= async () => {
        let campos = [
            "password"
        ];
        let datos = verificaValores(campos, perfil.General);
        let mensaje = formatearCadena(datos.arr);

        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchModificarPassword();
        }
    };
    const handleClickShowPassword = () => {
        guardarPerfiles({
            ...perfil,
            General: {
                ...perfil.General,
                showPassword: {
                    ...perfil.General.showPassword,
                    error: !perfil.General.showPassword.error
                }
            }
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //API
    const FetchModificarPassword = async () => {
        //indicar proceso de la peticion
        deshabilitarBoton(true);
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

     await   FetchGrahpQL({
            query: RECUPERAR_CONTRASEÑA,
            variables: {
                email: props.email,
                password: perfil.General.password.valor
            }
        })
            .then(() => {
                     setMensaje({ ...mensaje, ...mensajeExito, texto: `Se envió  un correo para confirmar la nueva contraseña` });
                     deshabilitarBoton(false);

            })
            .catch((err) => {
                     setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
                     deshabilitarBoton(false);
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        deshabilitarBoton(false);
    };

    //FUNCIONES
    function ftMensajeClose() {
            setMensaje({ ...mensaje, abrir: false });
    }


    return (
        <Card className={classes.mb1}>
             <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />
            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Modificar contraseña</p>
            </div>
            <CardContent>
            {GenerarNotificacionv2(getNotificacion,"w100")}
                <Grid spacing={3} container direction="row">
                    <Grid item sm={12} md={12}>
                        <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={perfil.General.password.error}  htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{paddingTop:"5px"}}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={perfil.General.password.error}
                                    type={perfil.General.showPassword.error ? 'text' : 'password'}
                                    value={perfil.General.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {perfil.General.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula, minúscula, número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"}, tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                            </FormControl>
                    </Grid>
                </Grid>
            </CardContent>


            <CardActions>

                <Button size="small" color="primary" onClick={EventModificarCedula}  disabled={boton}>
                    GUARDAR
        </Button>
            </CardActions>
        </Card>


    );
}

export default Password;
