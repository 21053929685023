import React, { useState, useEffect } from 'react'
import Input from './componentes/InputGrid';
import { Grid, LinearProgress } from '@material-ui/core';
import { Dialog } from '@material-ui/core';

//componentes
import Titulo from '../Mensaje/componentes/Titulo';
import Botones from '../Mensaje/componentes/Botones';
import Contenido from '../Mensaje/componentes/Contenido';
import Mensaje from '../MensajeNoModal';

//estilos
import { makeStyles } from '@material-ui/core/styles';

function Formulario({ campos = [], abrir = false, onClose, titulo = "Formulario", tamTitulo, onAccept = () => { }, onCancel, mensaje = {}, aBotones = [], tipo = "confirmacion", disabledAccept = false, progress, todo = false }) {

    const clases = {
        titulo: titulocls(),
        contenido: contenidocls(),
        botones: botonescls()
    };

    const [msg, setMsg] = useState(mensaje);
    const [errors, setError] = useState({});
    const [state, setState] = useState({});

    function onChange(e, onChange, id, val) {
        let value = "";

        switch (e.target.type) {
            case "file":
                value = e.target.files[0];
                break;
            case "checkbox":
                value = e.target.checked;
                campos.map(campo => {
                    if (campo.id === id) {
                        campo.valor = value;
                    }
                });
                break;
            default:
                value = (val && e.target.type) ? val : e.target.value;
                break;
        }

        setState({ ...state, [id]: value });
        if (onChange) onChange(e, val);
    }

    const ftClose = () => {
        if (onClose) onClose();
        setMsg({ ...msg, openMensaje: false });
        setError({});
        setState({});
    };

    useEffect(() => {
        let tmp = {};
        campos.map(campo => tmp[campo.id] = campo.valor);
        setState({ ...state, ...tmp })
    }, [campos])

    return (
        <Dialog open={abrir} onClose={ftClose} aria-labelledby="dialog-title">

            <Titulo texto={titulo} tamTitulo={tamTitulo} onClose={ftClose} classes={clases.titulo} />
            {
                msg.openMensaje ?
                    <Mensaje
                        variant={msg.ok ? "success" : "warning"}
                        message={msg.mensaje}
                        onClose={msg.handleClose}
                    />
                    : ""
            }

            <Contenido texto={"texto"} color={"inherit"} classes={clases.contenido} tipo={"formulario"}>
                <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                    {campos.map((campo, key) => {
                        return (
                            <Input key={key} xs={campo.xs}
                                propiedades={campo.valor ? campo.propiedades : {}}
                                tipo={campo.tipo}
                                id={campo.id}
                                texto={campo.texto}
                                margen={campo.margen}
                                valor={state[campo.id]}
                                handleChange={(e, val) => onChange(e, campo.onChange, campo.id, val)}
                                multiselect={campo.multiselect}
                                opciones={campo.opciones}
                                disabled={campo.disabled}
                                color={campo.color}
                                estilosGrid={campo.estilosGrid}
                                regexp={campo.regexp}
                                helperText={errors[campo.id]?.valor ? campo.ayuda : " "}
                                error={errors[campo.id]?.valor}
                                {...campo.otros}
                            />
                        )
                    })
                    }
                </Grid>
            </Contenido>

            <Botones
                tipo={tipo}
                classes={clases.botones}
                onAccept={(callback) => {

                    let invalidos = "";
                    Object.keys(errors).map(key => {
                        if (errors[key].valor) {
                            invalidos += '"' + errors[key].texto.replace(/\s*\*+$/, "") + '", ';
                        }
                    });
                    invalidos = invalidos.substring(0, invalidos.length - 2);

                    if (invalidos !== "") {
                        setMsg({
                            ...msg,
                            openMensaje: true,
                            ok: false,
                            mensaje: "Formato incorrecto en: " + invalidos,
                            handleClose: () => { setMsg({ ...msg, openMensaje: false }) }
                        });
                    } else {
                        setMsg({ ...msg, openMensaje: false });
                        setError({});
                        onAccept(state);
                    }
                }}
                onCancel={() => {
                    setMsg({ ...msg, openMensaje: false });
                    setError({});
                    let cancel = onCancel || onClose;
                    cancel();
                }}
                onClose={ftClose}
                aBotones={aBotones}
                disabledAccept={disabledAccept}
                progress={progress}
            />
        </Dialog>

    )
}


const titulocls = makeStyles(theme => ({
    main: {
        margin: 0,
        padding: theme.spacing(1),
        textAlign: 'center'
    },
    btnCerrar: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    }
}));

const contenidocls = makeStyles(theme => ({
    root: {
        textAlign: 'center',
        padding: '5px 50px',
        margin: 0,

    }
}));

const botonescls = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
    },
    Btn: {
        margin: '0 auto'
    },
    BtnCancel: {
        marginRight: '10px'
    },
    BtnNew: {
        marginLeft: '10px'
    },
    contenedor: {
        margin: '0 auto',
        textAlign: 'center'
    }
}));

export default Formulario
