import React, { useContext, useEffect } from 'react';
//apollo
import { useQuery } from '@apollo/react-hooks';
//Queries
import { LISTADO_TIPOS_DOCUMENTOS } from '../../../../query';
//Core
import { Grid, TextField, MenuItem, Select, FormControl, InputLabel, FormHelperText } from '../../../../componentesGenericos/Core';
//REDUX*****
import { useSelector } from "react-redux";
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/AsignacionSeries';
//Help
import { FetchGrahpQL } from '../../../../Helpers/Fetch/FetchGraphql';
import { SELECT_CLINICAS_FETCH } from '../../../../query';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));
function Agregar() {

    //Declaraciones
    const [datos, guardarDatos] = useContext(CRMContext);//state compartido
    const [clinicas, guardarClinicas] = React.useState('');
    const clinica = useSelector((state) => state.selecciondeClinica);
    let clinicaIdRedux = clinica.seleccionClinica[0].clinicaId;
    let clinicaNombreRedux = clinica.seleccionClinica[0].nombre;
    const classes = useStyles();
    const [seleccion, setSeleccion] = React.useState([])

    const { loading, error, data, refech } = useQuery(LISTADO_TIPOS_DOCUMENTOS, {
        variables: { tipoDocumentoId: null },
        onError: (e) => {
            console.log(e.message);
        },
        onCompleted: () => {
            setSeleccion(data.listadoTipoDocumento.TiposDocumentos)

        }
    });

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;

        if (value === null || value.length === 0) {
            value = value.toUpperCase();
            value = value.trim();
            guardarDatos({
                ...datos,
                Serie: {
                    ...datos.Serie,
                    [name]: {
                        ...datos.Serie[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Serie: {
                    ...datos.Serie,
                    [name]: {
                        ...datos.Serie[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    const obtenerClinicas = async () => {
        await FetchGrahpQL({
            query: SELECT_CLINICAS_FETCH,
            variables: {
                limite: 10000, activas: true
            }
        })
            .then(resp => {
                guardarClinicas({ datos: resp.listadoClinicas })
            })
            .catch((err) => {
            });
    }
    //Funciones
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.clinicas.map(function (x) {
                row.push(<MenuItem key={x.clinicaId} value={x.clinicaId} readOnly>{x.nombre}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    const ftGenerarSelectDocumentos = () => {
        if (seleccion === null) return null;
        let row = [];
        row.push();
        if (seleccion !== undefined) {
            seleccion.map(function (x) {
                row.push(<MenuItem key={x.tipoDocumentoId} value={x.tipoDocumentoId} readOnly>{x.nombreDocumento}</MenuItem>)

            })
            return row;
        }
    }

    //Ciclo de vida
    useEffect(() => {
        guardarDatos(data => ({
            ...data,
            Serie: {
                ...data.Serie,
                clinicaId: {
                    ...data.Serie.clinicaId,
                    error: false, valor: clinicaIdRedux
                },
                nombreClinica: {
                    ...data.Serie.nombreClinica,
                    error: false, valor: clinicaNombreRedux
                }
            }
        }));
        obtenerClinicas();
    }, []);
    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>

                            {
                                clinicaIdRedux === null ? <p>sin clinicas asignadas</p> : clinicaIdRedux === 0 ?

                                    <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                        <InputLabel id="demo-simple-select-label"  >Sucursal</InputLabel>
                                        <Select
                                            labelId={`nombreClinica`}
                                            id={`nombreClinica`}
                                            onChange={handleChangeGuardarState}
                                            name="clinicaId"
                                            required={true}
                                            defaultValue={-1}
                                        >
                                            <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge la sucursal'}</MenuItem>
                                            {ftGenerarSelect(clinicas.datos)}
                                        </Select>
                                        <FormHelperText>El nombre de la sucursal es obligatorio</FormHelperText>
                                    </FormControl>

                                    : <TextField margin="dense" name="nombreClinica" id={`nombreClinica`} label="Nombre de sucursal" type="text" style={{ width: "100%" }} onChange={handleChangeGuardarState} defaultValue={clinicaNombreRedux} disabled error={datos.Serie.nombreClinica.error} />
                            }

                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label"  >Tipo de documento</InputLabel>
                                <Select
                                    labelId={`tipoDocumentoId`}
                                    id={`tipoDocumentoId`}
                                    onChange={handleChangeGuardarState}
                                    name="tipoDocumentoId"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge el tipo de documento'}</MenuItem>
                                    {ftGenerarSelectDocumentos()}
                                </Select>
                                <FormHelperText>El tipo de documento es obligatorio</FormHelperText>
                            </FormControl>

                            <TextField margin="dense" name="serieNombre" id={`serieNombre`} label="Serie" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarState} required={true} helperText="La serie es obligatoria" error={datos.Serie.serieNombre.error} value={datos.Serie.serieNombre.valor} />

                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Agregar;
