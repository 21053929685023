import gql from 'graphql-tag';

export const NUEVO_ABONO = gql`
mutation NuevoAbono($input: HistorialPagoPartidas!){
    NuevoAbono(input: $input){
      historialPagoPartidaId
      totalPagoSinIva
      fechaPago
    }
  }
`;

export const ABONOS_PARTIDAS = gql`
mutation NuevoAbonoPartidasCredito($input: abonarInput!){
  AbonoPartidas(input: $input){
    historialPagoPartidaId
    totalPagoSinIva
    fechaPago
    fechaPagoFinalizado
    nombreVendedor
    productoId
    descripcionProducto
    ticketId
    serie
    nombreClinica
  }
}`;

export const ABONO_PARCIAL = gql`
mutation AbonoParcial($abono:abonarParcial!){
    AbonoParcial(input:$abono){
        ticketEncabezadoId
        ticketId
        clienteId
    }
}`;

export const ABONO_CAPITAL = gql`
mutation AbonoCapital($abono:abonarCapital!){
    AbonoCapital(input:$abono){
        ticketEncabezadoId
        ticketId
        clienteId
    }
}`;


export const ABONO_TOTAL = gql`
mutation AbonoTotal($input: AbonoTotal!){
  AbonoTotal(input: $input){
    ticketId
    ticketEncabezadoId
    clienteId
  }
}`;
