import React, { useState,useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_DOCTOR_ASIGNADOS_GQL,LISTADO_SERVICIOS_DOCTORES,LISTADO_PRODUCTOS_SERVICIOS,SELECT_CLINICAS_FETCH } from '../../query';
import { NUEVO_SERVICIO_DOCTOR,MODIFICAR_SERVICIO_DOCTOR,ELIMINAR_SERVICIO_DOCTOR} from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';
//redux
import { useSelector } from 'react-redux';


//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


//Helpers
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat } from './../../Helpers';

//Expresiones regulares
import { rgxNoVacio } from '../../Helpers/constantes/Rgx';

export default function ServiciosDoctores(props) {

    const user = useSelector ((state )=> state.selecciondeClinica);
    let clinicaIdRedux = user.seleccionClinica[0].clinicaId;
    let {history} = props;



    //#region queries
    const queryClinicas = () => {
        async function queryClinicas() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: {
                        limite: 10000,
                        activas: true
                    }
                });

                let {listadoClinicas:{clinicas}} = resultado;

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: "nombre",
                }, "Seleccionar sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinicas();
    }

    let { loading, error, data, refetch } = useQuery(LISTADO_SERVICIOS_DOCTORES, {
        variables: {
            limite: 1000000 ,
            clinicaId: Number(clinicaIdRedux)
        },
        fetchPolicy: 'no-cache',
        onError: (e) =>{
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () =>{
            setErrorState(false)
            setFila(-1)
            setSeleccion(seleccionInicial)
        }
    });

     const [addServicioDoctor] = useMutation(NUEVO_SERVICIO_DOCTOR, {
        onError: (e) => {
            throw new Error (e.message.replace("GraphQL error:", ""))

        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el registro` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [updateServicioDoctor] = useMutation(MODIFICAR_SERVICIO_DOCTOR, {
        onError: (e) => {

            throw new Error (e.message.replace("GraphQL error:", ""))
        },
        onCompleted: (e) => {

            ftFormularioClose();
            setDisabledAccept(false);
            if (e.modificarServicioDoctor[0].toLowerCase() == 'alertmessage')
                setMensaje({ ...mensaje, ...mensajeError, texto: e.modificarServicioDoctor[1]});
            else if  (e.modificarServicioDoctor[0].toLowerCase() == 'warningmessage')
                setMensaje({ ...mensaje, ...mensajeError, texto: e.modificarServicioDoctor[1]});
            else
                setMensaje({ ...mensaje, ...mensajeExito, texto: e.modificarServicioDoctor});

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteServicioDoctor] = useMutation(ELIMINAR_SERVICIO_DOCTOR, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el registro` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    //#endregion

    //#region states
    const seleccionInicial = {
        relacionIdGeneral: null,
        clinicaId: 0,
        usuarioId: undefined,
        nombreCompleto: null,
        cedula: null,
        email: null,
        serviciosId:-2,
        servicios: null,
        especialidadId: null,
        descripcion: null,
        fechaEgreso: null,
        fechaAlta: null,
        clinicaDoctorId: null
    };

    const usrRef = React.useRef();
    const servRef = React.useRef();
    const clinicaRef = React.useRef();

    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [clinicas, setClinica] = useState([]);
    const [doctores,setDoctores] = useState([]);
    const [aux,setAux] = useState([]);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [errorState, setErrorState] = useState(false);
    const [errorSelect, setErrorSelect] = useState(false);
    const [errorSelectServicios, setErrorSelectServicios] = useState(false);
    const [stateCopy, setStateCopy] = useState({});

    //#endregion

    //#region constantes
    const sinOpciones = [
        {
            label: "No se encontraron registros" ,
            value : -2
        }
    ]

    const  handleClinicasClick = ({target:{value}}) => {
        setDoctores(value)
     }

   const { data: servicios} = useQuery(LISTADO_PRODUCTOS_SERVICIOS,
        {
            variables: { offset:0, tipo: 1,clinica:  (!seleccion.relacionIdGeneral ? Number(clinicaIdRedux) :  Number(seleccion.clinicaId)) || Number(doctores), disponible:null },
            onError: (e) =>{
                console.log(e.message)
                setErrorSelectServicios(true)
            },
            onCompleted: (e) =>{
                setErrorSelectServicios(false)
            }
        });

        let opcionesServicios = servicios ? servicios.listadoProductosServicios.productosServicios.map (valor =>({
            label: valor.descripcion,
            value: valor.servicioId
        })) : null

   const { data:doctoresDB } = useQuery(LISTADO_DOCTOR_ASIGNADOS_GQL, {
         variables: {
        id: (!seleccion.relacionIdGeneral ? Number(clinicaIdRedux) :  Number(seleccion.clinicaId)) || Number(doctores),
        correo:1,
        estatus:1
    },
        onError: (e) =>{
            console.log(e.message)
            setErrorSelect(true)
        },
        onCompleted:(e) =>{
            setErrorSelect(false)
        }
    });

    let opcionesDoctores = doctoresDB ? doctoresDB.obtenerDoctoresAsignadosClinicas.clinicaDoctores.map (valor =>({
        label: valor.nombreCompleto,
        value: valor.doctorId,
        clinicaDoctorId: valor.clinicaDoctorId
    }))
    : null ;

  const columnas = errorState ? [{
    id: 'msj', label: '', format: (valor) => {
        return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
    }
}] : [
        {
            id: 'relacionId', label: 'relacionId', minWidth: 0, hide: true
        },
        {
            id: 'clinicaId', label: 'Sucursal', minWidth: 0, hide: true

        },
        {
            id: 'serviciosId', label: 'serviciosId',  align: 'center', textAlign: 'center',hide: true
        },
        {
            id: 'usuarioId', label: 'usuarioId', align: 'center',
            hide:true,
        },
        {
            id: 'nombreCompleto', label: 'Nombre completo', minWidth: 100, align: 'left', textAlign: 'left',
            filtro: true
        },
        {
            id: 'nombres', label: 'Nombres', align: 'center', hide: true
        },
        {
            id: 'apellidos', label: 'Apellidos',  align: 'center',hide: true
        },
        {
            id: 'email', label: 'Correo', minWidth: 100, align: 'left', textAlign: 'left', filtro: true
        },
        {
            id: 'cedula', label: 'Cedula',  align: 'center', hide : true,
            format: (fila) => {
                return fila ? fila : ("Sin cedula");
                }
        },
        {
            id: 'servicios', label: 'Servicios', minWidth: 170, align: 'left', textAlign: 'left', filtro:true
        },
        {
            id: 'nombreClinica', label: 'Sucursal', minWidth: 170, align: 'left', textAlign: 'left'
        }

    ];
    const menu = [
        { texto: "Asignar servicios", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar servicios", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar asignación", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Desplazarse a profesionales", icono: <ArrowBackIosIcon />, onClick: (e) => redirige("/profesionales")}
    ];

    const redirige=(texto)=>{
        history.push(texto);
    }

    const camposFormulario = [
        {
            id: "relacionId",
            texto: "relacionId",
            valor: seleccion.relacionId,
            tipo: "hidden",
        },
        {
            id: "clinicaId",
            texto: "Sucursal *",
            valor: Number(clinicaIdRedux) || Number(seleccion.clinicaId),
            tipo: "select",
            opciones: clinicas,
            cleanDatoState: [{ id: "usuarioId", value: 0 },{ id: "serviciosId", value: [] }],
            propiedades:
            {
                readOnly: (seleccion.relacionIdGeneral || Number(clinicaIdRedux) !==0)? true :false,
            },
            otros:
            {
                variant: seleccion.relacionIdGeneral ? "filled": "outlined",
                size:"small",
            },
            xs: 12,
            onChange: handleClinicasClick
        },
        {
            id: "usuarioId",
            texto: "Profesional *",
            valor: seleccion.usuarioId,
            tipo: "select",
            opciones: errorSelect == false ? opcionesDoctores : sinOpciones,
            xs: 12,
            onChange: handleClinicaDoctorClick,
            propiedades:
            {
                readOnly:  Number(seleccion.relacionIdGeneral) ? true :false,
            },
            otros:
            {
                variant: seleccion.relacionIdGeneral ? "filled": "outlined",
                size:"small",
            },
            ayuda: "Seleccione un profesional",
            regexp: rgxNoVacio,
            disabled : !seleccion.relacionIdGeneral && Number(seleccion.clinicaId) ===0  && Number(clinicaIdRedux) === 0? true : false

        },
        {
            id: "email",
            texto: "correo",
            valor: seleccion.email,
            tipo: "hidden",
        },
        {
            id: "serviciosId",
            texto: "Servicios *",
            valor: seleccion.serviciosId,
            tipo: "select",
            opciones: errorSelectServicios == false ? opcionesServicios : sinOpciones,
            multiselect: true,
            xs: 12,
            disabled : !seleccion.relacionIdGeneral && Number(seleccion.clinicaId) ===0  && Number(clinicaIdRedux) === 0? true : false,
            ayuda: "Seleccione un servicio (se listan disponibles y no disponibles)",
            regexp: rgxNoVacio,
        },
        {
            id: "clinicaDoctorId",
            texto : "Clinica Profesional",
            valor: seleccion.clinicaDoctorId,
            tipo: "hidden"
        }

    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoServiciosDoctores?.serviciosDoctores;

    //#endregion

    //#region handlers
    function handleAgregarClick() {
       setSeleccion(seleccionInicial)
       usrRef.current = null;
       servRef.current = null;
       clinicaRef.current = null;
       setDoctores([])
       setFila(-1);
       setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.relacionIdGeneral !== null) {
            usrRef.current = null;
            servRef.current = null;
            setSeleccion({
                ...seleccion,
                ...stateCopy
            });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.relacionIdGeneral !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar todos los servicios asignados al profesional seleccionado"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setStateCopy(datos)
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleClinicaDoctorClick (e) {
        let {value}= e.target;

        if (Number(value) >=0)
        {

            let [CD] = opcionesDoctores.filter((valor) => {
                    if(Number(valor.value) === Number(value)) return valor;
                });

            setSeleccion(seleccion=>({
                ...seleccion,
                clinicaDoctorId: CD.clinicaDoctorId
            }))
        }
    }
    //#endregion

    function ftAgregar({ usuarioId,serviciosId,clinicaId,clinicaDoctorId,cleanStateFormulario}) {
        async function ftAgregar(){
            try{

                if (usuarioId == -2 || usuarioId == 0 || serviciosId.length == 0) throw new Error("Profesional o servicios incorrectos");
                setDisabledAccept(true);
                setSeleccion({...seleccion, usuarioId,serviciosId,clinicaId,clinicaDoctorId})
                if(ftValidarObligatorios([usuarioId,serviciosId,clinicaId]))
                {
                await  addServicioDoctor({
                        variables: {
                            nuevoServicioDoctor: {
                                usuarioId: Number(usuarioId),
                                serviciosId: serviciosId.toString(),
                                clinicaId: Number(clinicaId),
                                clinicaDoctorId: Number(clinicaDoctorId)
                            }
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else
                {
                    throw new Error("Faltan valores obligatorios o no tienen el formato correcto");
                }
            }
            catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({ relacionIdGeneral,usuarioId,serviciosId,clinicaDoctorId,cleanStateFormulario}) {
        async function ftModificar()
        {
            try{
                setDisabledAccept(true);

                let eliminados = [];
                let actualizar= [];
                let agregar = [];

                 for (let i in seleccion.serviciosId)
                    {
                      if(serviciosId.includes(seleccion.serviciosId[i]))
                        {
                           actualizar.push(seleccion.serviciosId[i]);
                        }
                      else
                        {
                           eliminados.push(seleccion.serviciosId[i]);
                        }
                    }

                  for (let i in serviciosId)
                    {
                    if(!actualizar.includes(serviciosId[i]))
                    {
                      agregar.push(serviciosId[i]);
                    }
                    }

                if(ftValidarObligatorios([usuarioId]))
                {
                 await   updateServicioDoctor({
                        variables: {
                            modificarServicioDoctor: {
                                relacionIdGeneral:Number(relacionIdGeneral),
                                usuarioId: Number(usuarioId),
                                eliminados: eliminados.toString(),
                                agregar: agregar.toString(),
                                clinicaDoctorId: Number(clinicaDoctorId)
                            }
                        }
                    });
                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
            }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

   function ftEliminar() {
       async function ftEliminar(){
        setDisabledDelete(true);
      await  deleteServicioDoctor({
            variables: {
                id: Number(seleccion.usuarioId),
                servicios: seleccion.serviciosId.toString()
            } });
        setDisabledDelete(false);
       } ftEliminar();
    }

    function ftValidarObligatorios(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

if (actualizar === true) {
    refetch().then(() => setErrorState(false)).catch(e => {
        console.log(e.message);
        setErrorState(true);
    });
    setActualizar(false);
}

    useEffect(() => {
        async function ftEffect() {

            const clinicas = await queryClinicas();
            setClinica(clinicas)
        }
        ftEffect();
    }, []);

    useEffect(() => {
        setActualizar(true);
    }, [clinicaIdRedux]);

    return (
        <MenuLateral titulo="Servicios" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept}  disabledAccept={disabledDelete} progress={disabledDelete}/>

            <Formulario abrir={formulario.abrir}  campos={camposFormulario} titulo="Asignar servicios" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut}/>

           <Tabla titulo="Listado de profesionales con servicios" columnas={columnas}
           datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreCompleto","cedula","servicios","nombreClinica"]}/>

        </MenuLateral>
    );
}
