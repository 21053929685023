import React from 'react';
//Iconos
import { AddIcon,SwitchIcon,EditIcon,SwitchIconSecondary} from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    serieId:null,
    serieNombre:null,
    tipoDocumentoId:null,
    nombreDocumento:null,
    estatusSerie:null,
    porDefecto:null,
    clinicaId:null,
    nombreClinica:null,
    aliasClinica:null,
    estatusClinica:null,
    fechaAltaSerie:null
};
//seleccion para columnas
const columnas = [
    { id: 'serieId', label: 'serieId ', minWidth: 0, hide: true },
    { id: 'nombreClinica', label: 'Nombre Clinica ', minWidth: 170, align: 'center', hide: false },
    { id: 'serieNombre', label: 'Nombre De Serie', minWidth: 170, align: 'center', filtro: true },
    { id: 'tipoDocumentoId', label: 'Tipo Documento', minWidth: 170, align: 'center', filtro: false,hide: true},
    { id: 'nombreDocumento', label: 'Nombre Documento ', minWidth: 0, hide: true },
    { id: 'estatusSerie', label: 'Estatus De Serie ', minWidth: 170, align: 'center', hide: false , format: (valor) => {
        return valor.toString().replace("0", "Inactivo").replace("1", "Activo");
    }},
    { id: 'porDefecto', label: 'Por Defecto ', minWidth: 170, align: 'center', hide: false , format: (valor) => {
        return valor.toString().replace("0", "Inactivo").replace("1", "Activo");
    }},
    { id: 'clinicaId', label: 'clinicaId ', minWidth: 170, align: 'center', hide: true},
    { id: 'aliasClinica', label: 'Alias De Clinica ', minWidth: 170, align: 'center', hide: true },
    { id: 'estatusClinica', label: 'Estatus De Clinica ', minWidth: 170, align: 'center', hide: true }
];
//menu lateral


const menu = (handleAgregarClick, handleModificarClick,handleCambiarEstatusClick,handleCambiarEstatusPorDefectoClick,checked,checkedPorDefecto) => [
    { texto: "Agregar serie", icono: <AddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar serie", icono: <EditIcon />, onClick: () => handleModificarClick() },
    { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
    { texto: "Cambiar por defecto", icono: <SwitchIconSecondary checked={checkedPorDefecto} />, onClick: () => handleCambiarEstatusPorDefectoClick() }
];

/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    serieId:null,
    serieNombre:null,
    tipoDocumentoId:null,
    nombreDocumento:null,
    estatusSerie:null,
    porDefecto:null,
    clinicaId:null,
    nombreClinica:null,
    aliasClinica:null,
    estatusClinica:null,
    fechaAltaSerie:null
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
  };


export { columnas, seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,Style };
