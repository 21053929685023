import { CONFIGURACION } from '../Types';

const initialState = {
    descripcionLogin: "Iniciar Sesión",
    urlLogotipo: "/images/logo",
    precioConIVA: false,
    porcentajeDescuento: true,
    tasaIVA: 0.16,
    precisionAgenda: "00:15:00",
    tiempoIniAgenda: "09:00:00",
    tiempoFinAgenda: "20:00:00",
    tiempoCarrusel: 30
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CONFIGURACION:
            return {
                ...action.payload
            }
        default:
            return state;
    }
}
