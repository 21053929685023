import React,{useContext} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
//cocntext
import { CRMContext } from './../../../../context/Registro';
//Componentes Core
import { Button, Stepper, Step, StepLabel, Grid, TextField ,FormControl,InputLabel,Input,FormHelperText,IconButton,InputAdornment}
    from '../../../../componentesGenericos/Core';
//iconos
import { SendIcon, EmailIcon, LockIcon,VisibilityIcon,VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//constantes
import { ColorlibConnector, useColorlibStepIconStyles, RecuperarUseStyles } from '../../../../Helpers/constantes/Css/EstilosUi';
//Rgx
import { rgxEmail,rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Estilos
import { makeStyles } from '@material-ui/core/styles';

//Personalizar Iconos
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    const icons = {
        1: <EmailIcon />,
        2: <LockIcon />,
        3: <SendIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

//Titulos
function getSteps() {
    return ['Correo electrónico', 'Nueva contraseña'];
}

//Estilos
const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    espaciado:{
        padding:"20px"
    }
}));

//Datos
function getStepContent(step,datos,handleChangeGuardarStateCorreo,handleChangeGuardarStatePassword,classesRec,handleClickShowPassword,handleMouseDownPassword) {

    if (step===0) {
            return (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }}  required={true} onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} helperText="El email es un campo obligatorio" defaultValue={datos.Paciente.email.valor}/>
                    </Grid>
                </Grid>
            );
    }else{
            return (
            <Grid container spacing={3}>
                 <FormControl className={clsx(classesRec.margin, classesRec.textField)}>
                                <InputLabel required={true} error={datos.Paciente.password.error}  htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{paddingTop:"5px"}}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Paciente.password.error}
                                    type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                                    value={datos.Paciente.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,numero y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como minimo 8 caracteres y maximo 30 caracteres</FormHelperText>
                            </FormControl>
                </Grid>
            );
        }

}

export default function Recuperar() {

    //Declaracion
    const classes = RecuperarUseStyles();
    const classesRecuperar = useStyles();
    let  rgxPasswords=new RegExp(rgxPassword);
    const [activeStep, setActiveStep] = React.useState(0);
    const [datos, guardarDatos] = useContext(CRMContext);
    let rgxEmails = new RegExp(rgxEmail);
    let steps = getSteps();



    //Eventos
    const handleNext = (activeStep) => {
        if(activeStep===0){
         if(datos.Paciente.email.valor!==null & datos.Paciente.email.valor!==''){
             setActiveStep(prevActiveStep => prevActiveStep + 1);
            //bloquea el boton
             guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    recuperarContraseña: {
                        ...datos.Paciente.recuperarContraseña,
                        error: false, valor: ''
                    }
                }
            });
        }
    }else{
        if(datos.Paciente.password.valor!==null & datos.Paciente.password.valor!==''){
            setActiveStep(prevActiveStep => prevActiveStep + 1);
        }
    }
    };

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleBack = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                password: {
                    ...datos.Paciente.password,
                    error: false, valor: ''
                },
                recuperarContraseña: {
                    ...datos.Paciente.recuperarContraseña,
                    error: true, valor: ''
                }
            }
        });
        setActiveStep(prevActiveStep => prevActiveStep - 1);

    };

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value=value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }


    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                    <div>

                    </div>
                ) : (
                        <div>
                            {getStepContent(activeStep,datos,handleChangeGuardarStateCorreo,handleChangeGuardarStatePassword,classesRecuperar,handleClickShowPassword,handleMouseDownPassword)}

                            <div style={{ paddingTop: "20px" }}>
                                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                    Volver
                                </Button>
                                {
                                activeStep===0?<Button
                                    variant="contained"
                                    color="primary"
                                    onClick={()=>handleNext(activeStep)}
                                    className={classes.button}
                                >
                                     {activeStep === steps.length - 1 ? 'Siguiente' : 'Siguiente'}

                                </Button>:""
                                }
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}
