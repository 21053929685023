import gql from 'graphql-tag';

//#region Movimientos Almacen
const LISTADO_MOVIMIENTOS_ALMACEN = gql`
query ListadoMovsAlmacen($limite: Int, $pagina: Int, $clinicaId: Int, $encabezadoId: Int, $servicioId: Int){
  listadoMovsAlmacen(limite: $limite, pagina: $pagina, clinicaId: $clinicaId, encabezadoId:$encabezadoId, servicioId:$servicioId){
    MovsAlmacenes{
    movsId
    existenciaId
    encabezadoId
    clinicaId
    nombre
    almacenId
    nombreAlmacen
    servicioId
    servicio
    tipoMov
    entradas
    salidas
    fechaMov
    existenciaInicial
    existenciaActual
    existenciaActualTemporal
    conceptoInvId
    nombreCto
    ccostoId
    nombrecosto
    referencia
    usuarioId
    nombreUsuario
    apellidoUsuario
    }
  }
}`;

const OBTENER_MOVIMIENTOS_ALMACEN = gql`
query ObtenerMovsAlmacen($id: ID!){
  obtenerMovsAlmacen(id: $id){
    movsId
    existenciaId
    encabezadoId
    clinicaId
    nombre
    almacenId
    nombreAlmacen
    servicioId
    servicio
    tipoMov
    entradas
    salidas
    fechaMov
    existenciaInicial
    existenciaActualTemporal
    conceptoInvId
    nombreCto
  }
}`;
//==========================================================
//#endregion

//#region Almacen
const LISTADO_ALMACENES = gql`
    query listado($limite: Int,$clinica:Int,$estatus:Int){
        listadoAlmacen(limite:$limite,clinica:$clinica,estatus:$estatus){
            Almacenes{
                almacenId
                clinicaId
                clinica
                descripcion
                estatus
            }
            registros
            paginas
        }
    }
`;

const OBTENER_ALMACENES = gql`
    query obtenerAlmacen($id: Int!){
        obtenerAlmacen(id:$id){
            almacenId
            clinicaId
            clinica
            descripcion
            estatus
        }
    }
`;
//#endregion


export {
    LISTADO_MOVIMIENTOS_ALMACEN,
    OBTENER_MOVIMIENTOS_ALMACEN,
    LISTADO_ALMACENES,
    OBTENER_ALMACENES
}
