import gql from 'graphql-tag';

export const NUEVO_TIPO_PADECIMIENTO = gql `
mutation NuevoTipoPadecimiento($input: TipoPadecimientoInput!){
    nuevoTipoPadecimiento(input: $input){
      tipoPadecimientoId
      descripcion
    }
  }`

  export const MODIFICAR_TIPO_PADECIMIENTO = gql `
  mutation ModificarTipoPadecimiento($input: TipoPadecimientoInput!){
  modificarTipoPadecimiento(input: $input){
    tipoPadecimientoId
    descripcion
  }
}`;

export const ELIMINAR_TIPO_PADECIMIENTO = gql`
mutation EliminarTipoPadecimiento($id: Int!){
  eliminarTipoPadecimiento(id: $id)
}`;
