import {ASIGNAR_CLINICA_USUARIOS}from '../Types';

const initialState={
    clinicaUsuarioCrud: [{ activo: false }]
}

export default function(state=initialState,action){
    switch(action.type){
        case ASIGNAR_CLINICA_USUARIOS:
            return {
                ...state,
                clinicaUsuarioCrud:[action.payload]
            }
        default:
            return state;
    }
}
