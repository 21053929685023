
export const _urlencoded = (json = {}) => {
    const data = new URLSearchParams();
    if(typeof json !== 'object') return data;
    const keys = Object.keys(json);
    keys.forEach(key => { data.append(key, json[key]); });

    return data;
};

export function ftValidarCampos(arreglo) {
    if(!Array.isArray(arreglo)) return false;
    let success = true;
    arreglo.forEach(dato => { if(!dato) success = false; });
    return success;
}

export const distinto = (valor, indice, self) => {
    return self.indexOf(valor) === indice;
}
