import gql from 'graphql-tag';

//#region ProductosServicios
export const NUEVO_PRODUCTO_SERVICIO = gql`
	mutation nuevoProductoServicio($productoServicio: ProductoServicioInput!) {
		nuevoProductoServicio(input: $productoServicio) {
			garantiaId
			descripcion
			codigoProducto
			codigoBarras
			precioUnitario
			iva
			precioTotal
			porcentajeDescuento
			descuentoTotal
			precioUnitarioDescuento
			ivaDescuento
			precioTotalDescuento
			tipoProducto
			especialidadId
			agendable
			inventariable
			disponible
			clinicaId
			tipoIva
			tasaIva
			NoAplicaIva
			fechaInicialDescuento
			fechaFinalDescuento
			venderSinExistencia
		}
	}
`;

export const MODIFICAR_PRODUCTO_SERVICIO = gql`
	mutation modificarProductoServicio($productoServicio: ProductoServicioActualizarInput!) {
		modificarProductoServicio(input: $productoServicio) {
            relacionId
			garantiaId
			servicioId
			descripcion
			codigoProducto
			codigoBarras
			precioUnitario
			iva
			precioTotal
			porcentajeDescuento
			descuentoTotal
			precioUnitarioDescuento
			ivaDescuento
			precioTotalDescuento
			tipoProducto
			especialidadId
			agendable
			inventariable
			disponible
			clinicaId
			tipoIva
			tasaIva
			NoAplicaIva
			fechaInicialDescuento
			fechaFinalDescuento
			venderSinExistencia
		}
	}
`;

export const ELIMINAR_PRODUCTO_SERVICIO = gql`
	mutation eliminar($id: Int!) {
		eliminarProductoServicio(id: $id)
	}
`;
//#endregion
