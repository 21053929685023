import gql from 'graphql-tag';

export const NUEVA_RECETA = gql`
mutation NuevaReceta($input: RecetasInput!){
    nuevaReceta(input: $input){
    recetaId
    fechaReceta
        partidas{
        recetaId
        partidaId
        productoId
        descripcionProducto
      }
    }
  }`;
