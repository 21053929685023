//#region Imports

import React, { useState } from 'react';
//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_PROMOTOR_GQL} from '../../query';
import { NUEVO_PROMOTOR,MODIFICAR_PROMOTOR, ELIMINAR_PROMOTOR } from '../../mutation';
//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/FormularioAntiguo';
//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
//Iconos
import { AddIcon, EditIcon, DeleteForever,ThumbUpIcon } from '../../componentesGenericos/Icons';
//Expresiones regulares
import { rgxAlmenosUnaLetra, rgxNumeroMayor1 } from '../../Helpers/constantes/Rgx';
//Formatos
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';
//#endregion

export default function Promotores(props) {
    let {history} = props;
    //#region queries
    let { loading, error, data, refetch } = useQuery(LISTADO_PROMOTOR_GQL, {
        variables: { limit: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

     const [deletePromotor] = useMutation(ELIMINAR_PROMOTOR, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el promotor "${seleccion.promotor}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [addPromotor] = useMutation(NUEVO_PROMOTOR, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modPromotor] = useMutation(MODIFICAR_PROMOTOR, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region states

    const seleccionInicial = {
        promotorID: null,
        promotor: null,
        comision: null
    };
    const [actualizar, setActualizar] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [stateCopy, setStateCopy] = useState({});

    //#endregion

    //#region constantes

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'promotorID', label: '', minWidth: 0, hide: true },
            { id: 'promotor', label: 'Promotor', minWidth: 70, align: 'left', textAlign: 'left', filtro: true },
            { id: 'comision', label: 'Comisión', minWidth: 50, align: 'right', textAlign: 'right', filtro: true },
        ];
    const menu = [
        { texto: "Agregar promotor", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar promotor", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar promotor", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        {texto: "Desplazarse hacia convenios", icono:<ThumbUpIcon/>,onClick: () => redirige("/convenios")}
    ];
    const camposFormulario = [
        {
            id: "promotorID",
            texto: "promotorID",
            valor: seleccion.promotorID,
            tipo: "text",
            xs: 12,
            tipo: "hidden"
        },
        {
            id: "promotor",
            texto: "Promotor",
            valor: seleccion.promotor,
            tipo: "text",
            xs: 12,sd:9, md:9
        },
        {
            id: "comision",
            texto: "Comisión",
            valor: seleccion.comision,
            tipo: "number",
            xs: 12,sd:3, md:3,
            propiedades:{
                min: 0,
                max: 100
            },
            otros:{
                InputProps:{
                    endAdornment: "%",
                  }
            },
            xs:12,
        },
    ];
    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoPromotores?.promotores;
    //#endregion

    //#region handlers
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.promotorID !== null && seleccion.promotorID !== undefined) {
            setSeleccion({
                ...seleccion,
                ...stateCopy,
            });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un promotor" });
        }
    }

     function handleEliminarClick() {
        if (seleccion.promotorID !== null && seleccion.promotorID !== undefined) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el promotor "${seleccion.promotor}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un promotor" });
        }
    }

    const redirige = (texto) => {
        history.push(texto);
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setStateCopy(datos)
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setFiltro(texto)
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }
    //#endregion

    //#region funciones
    function ftAgregar ({promotor,comision,cleanStateFormulario}){
        async function ftAgregar()
        {
            try{
                setSeleccion({...seleccion,promotor,comision});

                if (Number(comision) <0 || Number(comision) >100 ) throw new Error("La comisión debe ser entre 0 y 100")
                setDisabledAccept(true);
                if (ftValidarObligatorios([promotor,comision]) === true )
                    {
                      await  addPromotor({
                            variables: {
                                input: {
                                    promotor,
                                    comision: Number(comision)
                                }
                            }
                        });
                        if(cleanStateFormulario) cleanStateFormulario();

                    } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
            }catch({message:texto}) {
                    setDisabledAccept(false);
                    setMensaje({ ...mensaje, ...mensajeError, texto });
                }
            } ftAgregar();
        }

    function ftModificar({ promotor, comision,cleanStateFormulario }) {
        async function ftModificar()
        {
            try{
                comision = comision ? comision : seleccion.comision;
                promotor = promotor ? promotor : seleccion.promotor;

                if (Number(comision) <0 || Number(comision) >100 ) throw new Error("La comisión debe ser entre 0 y 100")

                setDisabledAccept(true);

               if (ftValidarObligatorios([promotor,comision]) === true ) {
                await    modPromotor({
                        variables: {
                            input: {
                                promotorID:seleccion.promotorID,
                                promotor,
                                comision: Number(comision)
                            }
                        }
                    });
                    if(cleanStateFormulario) cleanStateFormulario();

             } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        setDisabledAccept(true)
         deletePromotor({ variables: { id: Number(seleccion.promotorID) } });
    }

    function ftValidarObligatorios(campos) {
        if (campos[0] === "" || campos[0] === undefined) return "Debe especificar una nombre";
        if (campos[1] === "" || campos[1] === undefined) return "Debe especificar una comisión";

        return campos.every((valor) => (valor !== null && valor !== undefined ));
    }


    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            if (!e.message.includes("No se encontraron registros")) console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }


    return (
        <MenuLateral titulo="Promotores" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Promotor" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion}  disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

            <Tabla titulo="Listado de promotores" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["promotor", "comision"]}/>

        </MenuLateral>
    );
}
