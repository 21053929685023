import React, { useState, useContext } from 'react';
import { CRMContext, CRMProvider } from './../../context/AutorizarDatos';
//Apollo*****
import { useQuery } from '@apollo/react-hooks';
//Queries****
import { LISTAR_DATOS_USUARIOS_AUTORIZACIONES } from '../../query/componentes/DatosUsuariosAutorizacion';
//****ROUTER***********/
import { withRouter } from "react-router-dom";
//Componentes***
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Modificar from './Modificar';
//constantes***
import { seleccionInicial, menu, filaState, seleccionState, formularioState } from './Constantes';
import { mensajeInicial, mensajeError } from '../../Helpers/constantes';


function AutorizacionDatos() {

    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTAR_DATOS_USUARIOS_AUTORIZACIONES, {
        variables: { offset: 0 }, onError: (e) => { }
    });

    //#endregion

    //#region states
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [openModifica, setOpenModifica] = React.useState(false);
    //#endregion

    //seleccion para columnas
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'datosUsuariosAutorizacionId', label: 'Id nuevo', minWidth: 0, hide: true,aliasColor:"estatus_nuevo" },
            { id: 'usuarioId_nuevo', label: 'Usuario id nuevo', minWidth: 0, hide: true },
            { id: 'nombres_nuevo', label: 'Nombre nuevo', minWidth: 0, hide: true },
            { id: 'apellidos_nuevo', label: 'Apellido nuevo', minWidth: 0, hide: true },
            { id: 'email_nuevo', label: 'Email nuevo', minWidth: 0, hide: true },

            { id: 'cedula_nuevo', label: 'Cedula nuevo', minWidth: 0, hide: true },
            { id: 'fechaEgreso_nuevo', label: 'Fecha egreso nuevo', minWidth: 0, hide: true },
            { id: 'estatus_nuevo', label: 'Estatus nuevo', minWidth: 0, hide: true },
            { id: 'especialidadId_nuevo', label: 'Especialidad id nuevo', minWidth: 0, hide: true },
            { id: 'sexo_nuevo', label: 'Sexo nuevo', minWidth: 0, hide: true },
            { id: 'telefono_nuevo', label: 'Telefono nuevo', minWidth: 0, hide: true },


            { id: 'fechaNacimiento_nuevo', label: 'Fecha nacimiento nuevo', minWidth: 0, hide: true },
            { id: 'solicitantesId', label: 'SolicitantesId nuevo', minWidth: 0, hide: true },
            { id: 'nombre_actual', label: 'Nombre actual', minWidth: 0, hide: true },
            { id: 'apellidos_actual', label: 'Apellidos actual', minWidth: 0, hide: true },
            { id: 'cedula_actual', label: 'Cedula actual', minWidth: 0, hide: true },

            { id: 'fechaEgreso_actual', label: 'Fecha egreso actual ', minWidth: 0, hide: true },
            { id: 'estatus_actual', label: 'Estatus actual ', minWidth: 0, hide: true },
            { id: 'especialidadId_actual', label: 'EspecialidadId actual', minWidth: 0, hide: true },
            { id: 'sexo_actual', label: 'Sexo actual', minWidth: 0, hide: true },
            { id: 'telefono_actual', label: 'Telefono actual', minWidth: 0, hide: true },
            { id: 'fechaNacimiento_actual', label: 'Fecha Nacimeinto actual', minWidth: 0, hide: true },
            { id: 'nombreCompletoSolicitante', label: 'Nombre completo del solicitante ', minWidth: 0, hide: false, align: 'left', textAlign: 'left' },
            { id: 'correoSolicitante', label: 'Correo del solicitante', minWidth: 0, hide: false,filtro:true, align: 'left', textAlign: 'left'  },
            {
                id: 'tipoUsuarioSolicitante', label: 'Tipo de usuario solicitante', filtro:true ,minWidth: 100, hide: false, align: 'left', textAlign: 'left', format: (valor) => {
                    return valor?.toString().replace("1", "Profesional").replace("2", "Cliente").replace("3", "Admin").replace("4", "Usuario");
                }
            },
            { id: 'nombreCompletoAfectado',filtro:true  ,label: 'Nombre completo de afectado ', minWidth: 0, hide: false, align: 'left', textAlign: 'left' },
            { id: 'email_actual', label: 'Correo de usuario afectado', minWidth: 0, hide: false, align: 'left', textAlign: 'left',filtro:true , },
            {
                id: 'tipo', filtro:true ,label: 'Tipo de usuario afectado', minWidth: 100, hide: false, align: 'left', textAlign: 'left', format: (valor) => {
                    return valor?.toString().replace("1", "Profesional").replace("2", "Cliente").replace("3", "Admin").replace("4", "Usuario");
                }
            },
            {

                id: 'autorizacion', label: 'Autorización', minWidth: 100, hide: false, align: 'center', component: "Checkbox", textAlign: 'center'
            },
            { id: 'password_nuevo_estatus', label: 'password', minWidth: 0, hide: true },

        ];


    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }

    function handleModificarClick() {
        if (seleccion.datosUsuariosAutorizacionId !== null && seleccion.datosUsuariosAutorizacionId !== undefined && fila !== -1) {
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una solicitud" });
        }
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    const actualizarTabla = () => {
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }
    //#endregion

    //#region funciones

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    //#endregion

    //#region actualiza


    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.ListadoDatosUsuariosAutorizaciones?.datosUsuariosAutorizacion;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }



    //#endregion


    return (<>
        <CRMProvider value={[usuarios, guardarUsuarios]}>
            <MenuLateral titulo="Usuarios" menu={menu(handleModificarClick)}>

                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />

                <Tabla titulo="Solicitudes de modificación" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombres_nuevo", "nombre_actual"]} />

            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default withRouter(AutorizacionDatos);
