import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import AgregarAnamnesis from './Formulario/index';
import { CRMContext } from '../../../context/Anamnesis';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/AnamnesisAction";
//helper
import { Anamnesis } from '../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Agregar(props) {

    //Declaraciones
    const { actualiza } = props;
    let { open, ftClose } = props;
    const [datos, guardarDatos] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(Anamnesis);


    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const Crud = (tipo, evento, datos,desactivar,cerrar) => dispatch(CRUD(tipo, evento, datos,desactivar,cerrar));

    //Eventos
    const handleClick = () => {
        ActivarDesactivarBoton(true);
        let campos = [
            "pacienteId",
            "padecimientoId",
            "descripcion"
        ];
        let datosV = verificaValores(campos, datos.Anamnesis,
        [["pacienteId","Cliente"],["padecimientoId","Padecimiento"],["descripcion","Descripción"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            Crud(0, actualiza, datos.Anamnesis,(n)=>  ActivarDesactivarBoton(n),ftClose);
            limpiarMensaje();
        }

    }

    //FUNCIONES
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    const ActivarDesactivarBoton = (tipo) => {
        guardarDatos({
            ...datos,
            Anamnesis: {
                ...datos.Anamnesis,
                desactivarBoton: {
                    ...datos.Anamnesis.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }

    //CICLO DE VIDA
    useEffect(() => {
        setNotificacion( getNotificacion =>({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        }));
        guardarDatos({Anamnesis});
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Dar de alta a  cliente </DialogTitle>
                {/*formulario*/}
                <DialogContent>
                <a name="arriba"><p style={{display:"none"}}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <AgregarAnamnesis />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
             </Button>
             <a href="#arriba" style={{textDecoration: "none"}}>
                    <Button
                        disabled={datos.Anamnesis.desactivarBoton.error}
                        onClick={handleClick}
                        color="primary">
                        Guardar
                      </Button>
                      </a>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Agregar;
