import gql from 'graphql-tag';

//#region ImagenesPromociones

const LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD=`
query listadoPromocionesCaducidad($estatus: Int!, $nombreDB: String!) {
    listadoPromocionesCaducidad(estatus: $estatus, nombreDB: $nombreDB) {
      ImagenesPromocionesCaducidades {
        imgPromoId
        descripcion
        urlImagen
        nombreImagen
        estatus
        caducidad
      }
    }
  }
`;


const OBTENER_IMAGENPROMOCION = `
query ImagenPromocion($id:Int!){
    obtenerImagenPromocion(id:$id){
      imgPromoId
      descripcion
      nombreImagen
      urlImagen
      estatus
      fechaAlta
      vigenciaIni
      vigenciaFin

      configuracionId
      precisionAgenda
      folioTickets
      tiempoCarrusel
    }
  }
`;

const LISTADO_IMAGENESPROMOCION = gql`
	query Listado($pagina: Int, $limite: Int) {
		listadoImagenesPromociones(pagina: $pagina, limite: $limite) {
			ImagenesPromociones {
				imgPromoId
				descripcion
                nombreImagen
				urlImagen
				estatus
				fechaAlta
				vigenciaIni
				vigenciaFin
				configuracionId
				precisionAgenda
				folioTickets
				tiempoCarrusel
			}
			registros
			paginas
		}
	}
`;

//#endregion

export {
    OBTENER_IMAGENPROMOCION,
    LISTADO_IMAGENESPROMOCION,
    LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD
}
