import decode from "jwt-decode";

export const Autenticar = (token, success) => {

    let valido = false;
    try {
        if (decode(token) != null) {
            const { exp } = decode(token);
            if (Date.now() >= exp * 1000) {
                return false;
            }
            valido = true;
        }
    } catch (e) {
        return false;
    }
    return valido === true && success === true ? true : false;
}

export const DecodificarToken = (token, callback) => {
    try {
        if (!Autenticar(token, true)) throw new Error("¡El token no es válido!");
        if (!decode(token)) throw new Error("Sin datos");
        let { usuario } = decode(token);
        if (!callback) return usuario;
        callback(usuario);
    } catch ({ message }) {
        console.error(message);
        // localStorage.clear();
        return { tipo: "" };
    }
}


