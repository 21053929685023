import {AGREGAR_ESPECIALIDAD}from '../Types';

const initialState={
    especialidadesCrud: [{ activo: false }]
}

export default function(state=initialState,action){
    switch(action.type){
        case AGREGAR_ESPECIALIDAD:
            return {
                ...state,
                especialidadesCrud:[action.payload]
            }
        default:
            return state;
    }
}
