import React, { useState, useContext, useEffect } from 'react';
import { CRMContext, CRMProvider } from './../../context/NotificacionCreditos';
//REDUX*************
import { useSelector } from "react-redux";
//Apollo************
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries***********
import { LISTADO_RECORDATORIOS_CREDITOS } from '../../query/componentes/RecordatoriosCreditos';
import {
    ACTUALIZAR_ESTATUS_RECORDATORIOS_CREDITOS
} from '../../mutation/componentes/RecordatorioCreditos';
//ROUTER************
import { withRouter } from "react-router-dom";
//Componentes*******
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Agregar from './Agregar';
import Modificar from './Modificar';
//constantes*********
import { seleccionInicial, menu, filaState, seleccionState, formularioState } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';


function RecordatoriosCreditos(props) {


    // #region redux
    const user = useSelector((state) => state.selecciondeClinica);
    let clinicaIdRedux = user.seleccionClinica[0].clinicaId;
    //#endregion

    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_RECORDATORIOS_CREDITOS, {
        fetchPolicy: 'no-cache',
        variables: { estatus: -1, clinicaId: parseInt(clinicaIdRedux) },
        onError: (e) => { }
    });

    const [statusActualizar] = useMutation(ACTUALIZAR_ESTATUS_RECORDATORIOS_CREDITOS, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente` });
            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    //#endregion

    //#region states
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    let { history } = props;
    //#endregion

    //#region columnas
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'descripcionPlazo', label: 'Descripción del Crédito', minWidth: 0, hide: false, filtro: true,aliasColor:"estatus" ,align: 'left', textAlign: 'left'},
            { id: 'dias', label: 'Días', minWidth: 0, hide: false,align: 'right', textAlign: 'right', filtro: true },
            {
                id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center', component: "Checkbox", filtro: true
            },
            {id:'descripcionRecordatorio' ,label:"descripcionRecordatorio", minWidth: 0, hide: true, filtro: true}

        ];
    //#endregion

    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleAgregarClick() {
        ftCloseRespuestaAbrir();
    }



    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }

    function handleModificarClick() {
        if (seleccion.recordatorioPagoId !== null && seleccion.recordatorioPagoId !== undefined && fila !== -1) {
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una notificación de crédito " });
        }
    }


    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusActualizar({
                variables: {
                    recordatorioPagoId: Number(seleccion.recordatorioPagoId),
                    estatus: seleccion.estatus === 0 ? 1 : 0
                }
            })
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function handleCambiarEstatusClick() {
        if (seleccion.recordatorioPagoId !== null && seleccion.recordatorioPagoId !== undefined && fila !== -1) {

            setChecked(seleccion.estatus === 0);
            let opcion = seleccion.estatus === 1 ? "desactivar" : "activar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la notificación de crédito?"`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
            setFila(-1);

        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una notificación de crédito" });
        }
    }

    //#endregion

    //#region funciones


    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseRespuestaAbrir() {
        setOpen(true);
    }

    const handleClose = newValue => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const redirige = (texto) => {
        history.push(texto);
    }

    const actualizarTabla = () => {
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }
    //#endregion

    //#region actualiza
    useEffect(() => {
        setActualizar(true);
    }, [clinicaIdRedux]);

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoRecordatoriosCreditos?.recordatorioCreditos;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }



    //#endregion

    return (<>
        <CRMProvider value={[usuarios, guardarUsuarios]}>
            <MenuLateral titulo="Listado de notificaciones de créditos" menu={menu(handleAgregarClick, handleModificarClick, handleCambiarEstatusClick, checked)}>


                <Agregar
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    botonder={"Agregar"}
                    botonizq={"Cancelar"}
                    actualiza={() => actualizarTabla()}
                    seleccion={seleccion}
                    ftClose={handleClose}
                />

                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />


                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />


                <Tabla titulo="Listado de notificaciones de créditos" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcionPlazo", "dias", "descripcionRecordatorio"]} />


            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default withRouter(RecordatoriosCreditos);
