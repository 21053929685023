import React, { useState } from 'react';
//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Router
import { useParams } from "react-router-dom";

//Queries
import { FILTRO_DIRECCIONES, OBTENER_DIRECCIONES_SAT, LISTADO_CLINICAS } from '../../query';
import { NUEVA_DIRECCION, ELIMINAR_DIRECCION, MODIFICAR_DIRECCION, CAMBIAR_ESTATUS_DIRECCION } from '../../mutation';

//fetch
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//Constantes
import { ftSelectFormat } from '../../Helpers';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';
import { seleccionDirecciones, actualizarState, filaState, formularioState } from './constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever, Phone, SwitchIcon, Apartment } from '../../componentesGenericos/Icons';

//Helpers
import { rgxCodigoPostal, rgxNoVacio } from '../../Helpers/constantes/Rgx';

import { strUrlSat } from '../../Helpers/constantes/Config/UrlServer';

//Formatos
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';

export default function DireccionesClinica() {

    let { id } = useParams();

    const cleanColonias = [{ colonia: 'No se encontraron colonias', claveColonia: -1 }];

    const [collist, setColList] = useState(cleanColonias);

    //
    const { loading, error, data, refetch } = useQuery(FILTRO_DIRECCIONES, {
        variables: {
            limite: 100,
            pagina: 1,
            clinicaId: Number(id),
            direccionId: null
        },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const { data: dataClinicas } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 10 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });
    //
    const [addDireccion] = useMutation(NUEVA_DIRECCION, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...Mensaje, ...mensajeExito, texto: `Se agregó correctamente la dirección` });

            setSeleccion({ ...seleccion, ...seleccionDirecciones });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modDireccion] = useMutation(MODIFICAR_DIRECCION, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la dirección` });

            setSeleccion({ ...seleccion, ...seleccionDirecciones });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusDireccion] = useMutation(CAMBIAR_ESTATUS_DIRECCION, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se seleccionó correctamente la dirección de la sucursal ${seleccion.nombreClinica} como predeterminada` });

            setSeleccion({ ...seleccion, ...seleccionDirecciones });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteDireccion] = useMutation(ELIMINAR_DIRECCION, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la dirección "${seleccion.codigoPostal}"` });

            setSeleccion({ ...seleccion, ...seleccionDirecciones });

            setFila(-1);
            setActualizar(true);
        }
    });

    /// REGION STATES
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [seleccion, setSeleccion] = useState(seleccionDirecciones);

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [formulario, setFormulario] = useState(formularioState);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [errorState, setErrorState] = useState(false);

    const [checked, setChecked] = React.useState(false);

    // END REGION STATES

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'direccionId', label: 'direccionId', minWidth: 0, hide: true },
            { id: 'clinicaId', label: 'clinicaId', minWidth: 0, hide: true },
            { id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left' },
            { id: 'codigoPostal', label: 'Código Postal', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'calle', label: 'Calle', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'claveColonia', label: 'claveColonia', minWidth: 0, hide: true },
            { id: 'colonia', label: 'Colonia', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'claveLocalidad', label: 'claveLocalidad', minWidth: 0, hide: true },
            { id: 'localidad', label: 'Localidad', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'claveMunicipio', label: 'claveMunicipio', minWidth: 0, hide: true },
            { id: 'municipio', label: 'Municipio', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'claveEstado', label: 'claveEstado', minWidth: 0, hide: true },
            { id: 'estado', label: 'Estado', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'clavePais', label: 'clavePais', minWidth: 0, hide: true },
            { id: 'pais', label: 'País', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'estatus', label: 'Activa', minWidth: 10, align: 'center', textAlign: 'center', component: "Checkbox" }

        ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data ?.listadoDirecciones ?.direcciones;

    const menu = [
        { texto: "Agregar dirección", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar dirección", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar dirección", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Agregar teléfono", icono: <Phone />, onClick: () => redireccionar() },
        { texto: "Ir a sucursales", icono: <Apartment />, onClick: () => window.location.href = "/sucursales" }
    ];

    /// REGION HANDLERS

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionDirecciones });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionDirecciones });
        setFila(-1);
    }

    function handleAgregarClick() {
        setSeleccion(seleccionDirecciones)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.direccionId !== null) {
            obtenerDatosCp(seleccion);
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
            if (seleccion.colonia === null || "" || undefined) {
                setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar una colonia" });
            }
            if (seleccion.clinicaId === null || "" || undefined) {
                setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar una sucursal" });
            }



        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar un elemento" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.direccionId !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la dirección?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: "Debe seleccionar un elemento" });
        }
    }

    function handleCambiarEstatusClick() {
        if (seleccion.direccionId !== null) {
            setChecked(seleccion.estatus === false);
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea seleccionar la dirección de la sucursal ${seleccion.nombreClinica} como predeterminada?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una dirección de sucursal" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setFila(index);
            setChecked(datos.estatus)
        }
        else {
            setSeleccion(seleccionDirecciones);
            setFila(-1);
        }

    }

    const handleChange = async ({ target: { id, value } }) => {
        if (value) {
            if (Number(value.length) === 5 && Number(value) > 0) {
                try {
                    const { getCodigoPostal } = await FetchGrahpQL({
                        url: strUrlSat,
                        query: OBTENER_DIRECCIONES_SAT,
                        variables: {
                            cp: value
                        }

                    });
                    let primer = getCodigoPostal.colonias[0].claveColonia;
                    if(primer !== null)
                    {
                        setSeleccion({
                        ...seleccion,
                        ...getCodigoPostal,
                        [id]: id === 'codigoPostal' ? Number(value) : value
                        });
                        setColList(getCodigoPostal.colonias)
                    }
                    else{
                       setColList(cleanColonias);
                       setSeleccion({

                            colonia: "",
                            claveColonia: "",
                            municipio: "",
                            claveMunicipio: "",
                            localidad: "",
                            claveLocalidad: "",
                            estado: "",
                            claveEstado: "",
                            pais: "",
                            clavePais: "",
                            [id]: value
                        });

                    }


                } catch ({ message }) {
                    setColList(cleanColonias);
                }
            } else {
                setSeleccion({
                    ...seleccion,
                    colonia: "",
                    claveColonia: "",
                    municipio: "",
                    claveMunicipio: "",
                    localidad: "",
                    claveLocalidad: "",
                    estado: "",
                    claveEstado: "",
                    pais: "",
                    clavePais: "",
                    [id]: value
                });
                setColList(cleanColonias);
            }
        } else {
            setSeleccion({
                ...seleccion,
                colonia: "",
                claveColonia: "",
                municipio: "",
                claveMunicipio: "",
                localidad: "",
                claveLocalidad: "",
                estado: "",
                claveEstado: "",
                pais: "",
                clavePais: "",
                [id]: value
            });
            setColList(cleanColonias);
        }

    }


    // END REGION HANDLERS
    // FORMULARIO
    let opcionesClinica = dataClinicas ?
        dataClinicas.listadoClinicas.clinicas.map(valor => ({
            label: valor.nombre,
            value: Number(valor.clinicaId)
        })) : null

    let col = seleccion.colonias;
    let opcionesColonia;
    if (col) {
        opcionesColonia = ftSelectFormat(col, {
            id: "colonia",
            descripcion: "colonia",
            otro: "claveColonia"
        });
    }

    const coloniasProps = {
        options: collist,
        getOptionLabel: option => {
            return option.colonia
        },
        noOptionsText:'No se encontraron colonias'
    }


    const handleChangeColonias = (e, val) => {
        if (val) {
            setSeleccion({
                ...seleccion,
                claveColonia: val.claveColonia
            })
        }
    }

    const handleChangeMovs = ({ target: { id, value } }) => {
        setSeleccion({
            ...seleccion,
            [id]: value
        })
    }

    const camposFormulario = [
        {
            id: "clinicaId",
            texto: "Sucursal *",
            valor: id,
            tipo: "select",
            opciones: opcionesClinica,
            xs: 12,
            otros: {
                inputProps: { readOnly: true }
            },
            disabled:true,
            onChange: handleChangeMovs,
            styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
            styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'}
        },
        {
            id: "calle",
            texto: "Calle y Número *",
            valor: seleccion.calle,
            tipo: "text",
            xs: 8,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxNoVacio,
            formato: cuatroCaracteres
        },
        {
            id: "codigoPostal",
            texto: "Código Postal *",
            valor: seleccion.codigoPostal,
            tipo: "text",
            xs: 4,
            onChange: handleChange,
            regexp: rgxCodigoPostal
        },
        {
            id: "colonia",
            texto: "Colonia *",
            valor : collist[0].claveColonia !== -1 ? seleccion.colonia ? collist.find(val => (val.colonia == seleccion.colonia)) || -1 : -1 : "",
            otros: { disabled: false, value: seleccion.colonia, enabled: true},
            tipo: collist[0].claveColonia !== -1 ? "autocomplete" : "text",
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            onChange: collist[0].claveColonia !== -1 ? handleChangeColonias :handleChangeMovs,
            propiedades: coloniasProps,
            xs: 8,

        },
        {
            id: "claveColonia",
            texto: "Clave Colonia",
            tipo: "text",
            otros: { value: seleccion.claveColonia },
            xs: 4,
            disabled: true
        },
        {
            id: "localidad",
            texto: "Localidad",
            tipo: "text",
            otros: { disabled: collist[0].claveColonia !== -1 ? true : false, value: seleccion.localidad },
            xs: 8,
            onChange: handleChangeMovs
        },
        {
            id: "claveLocalidad",
            texto: "Clave Localidad",
            tipo: "text",
            otros: { disabled: true, value: seleccion.claveLocalidad },
            xs: 4,
            onChange: handleChangeMovs
        },
        {
            id: "municipio",
            texto: "Municipio",
            tipo: "text",
            otros: { value: seleccion.municipio },
            xs: 8,
            onChange: handleChangeMovs,
            disabled: collist[0].claveColonia !== -1 ? true : false
        },
        {
            id: "claveMunicipio",
            texto: "Clave Municipio",
            tipo: "text",
            otros: { value: seleccion.claveMunicipio },
            xs: 4,
            onChange: handleChangeMovs,
            disabled: true
        },
        {
            id: "estado",
            texto: "Estado",
            tipo: "text",
            otros: { value: seleccion.estado },
            xs: 6,
            onChange: handleChangeMovs,
            disabled: collist[0].claveColonia !== -1 ? true : false
        },
        {
            id: "claveEstado",
            texto: "Clave Estado",
            tipo: "text",
            otros: { value: seleccion.claveEstado },
            xs: 6,
            onChange: handleChangeMovs,
            disabled: true
        },
        {
            id: "pais",
            texto: "País",
            tipo: "text",
            otros: { value: seleccion.pais },
            xs: 6,
            onChange: handleChangeMovs,
            disabled: collist[0].claveColonia !== -1 ? true : false
        },
        {
            id: "clavePais",
            texto: "Clave País",
            tipo: "text",
            otros: { value: seleccion.clavePais },
            xs: 6,
            onChange: handleChangeMovs,
            disabled: true
        },
        {
            id: "lat",
            texto: "Latitud",
            valor: seleccion.lat,
            tipo: "text",
            xs: 6,
            disabled: true
        },
        {
            id: "lng",
            texto: "Longuitud",
            valor: seleccion.lng,
            tipo: "text",
            xs: 6,
            disabled: true
        },

    ];
    //END FORMULARIO

    // REGION FUNCIONES
    function obtenerDatosCp(datos, index) {
        if (datos.codigoPostal !== '' && datos.codigoPostal !== null) {
            setSeleccion({
                ...seleccion
            });

            FetchGrahpQL({
                url: strUrlSat,
                query: OBTENER_DIRECCIONES_SAT,
                variables: {
                    cp: datos.codigoPostal
                }

            }).then(({ getCodigoPostal }) => {

                    let primer = getCodigoPostal.colonias[0].claveColonia;
                    if(primer !== null)
                    {
                        setSeleccion({
                            ...seleccion,
                            ...getCodigoPostal
                        });
                        setColList(getCodigoPostal.colonias)
                    }
                    else{
                       setColList(cleanColonias);
                    }

            }).catch(console.error);
        } else {
            setColList(cleanColonias)
        }
    }


    function redireccionar() {
        if (seleccion.direccionId !== null) {
            window.location.href = "/telefonos/" + seleccion.direccionId
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Selecciona o crea una dirección" })
        }
    }

    function ftValidar(campos) {
        return campos.every((valor) => {
            if (valor !== null && valor !== undefined) {
                if (valor.trim() !== "") {
                    return true;
                } else {
                    return false;
                }
            }

            return false;
        });

    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftAgregar({ clinicaId, calle, codigoPostal, claveColonia, colonia, claveLocalidad, localidad, claveMunicipio, municipio, claveEstado, estado, clavePais, pais, lat, lng, cleanStateFormulario }) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);
                if (ftValidar([window.codigoPostal.value, window.calle.value, window.colonia.value])) {
                    await addDireccion({
                        variables: {
                            direccion: {
                                clinicaId: Number(id),
                                calle: window.calle.value,
                                codigoPostal: window.codigoPostal.value,
                                claveColonia: window.claveColonia.value,
                                colonia: window.colonia.value,
                                claveLocalidad: window.claveLocalidad.value,
                                localidad: window.localidad.value,
                                claveMunicipio: window.claveMunicipio.value,
                                municipio: window.municipio.value,
                                claveEstado: window.claveEstado.value,
                                estado: window.estado.value,
                                clavePais: window.clavePais.value,
                                pais: window.pais.value,
                                lat: Number(lat),
                                lng: Number(lng)
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                    // colonia = 0;
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();

    }

    function ftModificar({ codigoPostal, claveColonia, colonia, col, claveLocalidad, localidad, claveMunicipio, municipio, claveEstado, estado, clavePais, pais, calle, clinicaId, lat, lng, cleanStateFormulario }) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);
                codigoPostal = codigoPostal || seleccion.codigoPostal;
                claveColonia = claveColonia || seleccion.claveColonia;
                clinicaId = clinicaId || seleccion.clinicaId;

                if (ftValidar([seleccion.direccionId, window.colonia.value])) {
                    await modDireccion({
                        variables: {
                            direccion: {
                                direccionID: Number(seleccion.direccionId),
                                clinicaId: Number(seleccion.clinicaId),
                                calle: window.calle.value,
                                codigoPostal: codigoPostal,
                                claveColonia: window.claveColonia.value,
                                colonia: window.colonia.value,
                                claveLocalidad: window.claveLocalidad.value,
                                localidad: window.localidad.value,
                                claveMunicipio: window.claveMunicipio.value,
                                municipio: window.municipio.value,
                                claveEstado: window.claveEstado.value,
                                estado: window.estado.value,
                                clavePais: window.clavePais.value,
                                pais: window.pais.value,
                                lat: Number(lat),
                                lng: Number(lng)
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusDireccion({
                variables: {
                    direccionId: Number(seleccion.direccionId),
                    estatus: 1,
                    clinicaId: Number(seleccion.clinicaId)
                }
            });
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteDireccion({ variables: { id: Number(seleccion.direccionId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    // Recargar
    if (actualizar === true) { refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) }); setActualizar(false); }





    return (
        <MenuLateral titulo="Direcciones" menu={menu} >
            <Mensaje
                titulo={mensaje.titulo}
                abrir={mensaje.abrir}
                texto={mensaje.texto}
                onClose={ftMensajeClose}
                icono={mensaje.icono}
                tipo={mensaje.tipo}
                color={mensaje.color}
                onAccept={mensaje.onAccept}
                disabledAccept={disabledDelete}
                progress={disabledDelete}
            />
            <Formulario
                abrir={formulario.abrir}
                campos={camposFormulario}
                titulo="Dirección"
                onClose={ftFormularioClose}
                onAccept={formulario.onAccept}
                valor={seleccion}
                disabledAccept={disabledAccept}
                cleanAwait={true} />

            <Tabla titulo="Direcciones" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreClinica", "codigoPostal", "calle"]} />


        </MenuLateral>
    )

}
