import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Modificar from './Formularios/Modificar';
import { CRMContext } from '../../../context/AsignacionSeries';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/SerieAction";
//helper
import { limpiarStateSerie } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function AgregarRelacion(props) {

    //Declaraciones
    const { actualiza,index,seleccion } = props;
    let { open, ftClose } = props;
    const [datos, guardarDatos] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(limpiarStateSerie);
    const [boton,setBoton] = useState(false);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const Crud = (tipo, evento, datos,desactivar,cerrar) => dispatch(CRUD(tipo, evento, datos,desactivar,cerrar));

    //Eventos
    const handleEspecialidades = async () => {
        deshabilitarBoton(true);
        let campos = [
            "serieNombre",
            "serieId"
        ];
         let datosV = verificaValores(campos, datos.Serie,[["serieNombre","serie"]]);
         let mensaje = formatearCadena(datosV.arr);
         if (datosV.estado === false ) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });


            Crud(1, actualiza, datos.Serie,(n)=>  deshabilitarBoton(n),()=>ftClose());
            limpiarMensaje();
        }


    }


    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }

    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        guardarDatos(limpiarStateSerie)
        index(-1);
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Modificar serie </DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Modificar  ftClose={ftClose} seleccion={seleccion}/>
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
             </Button>
                    <Button
                        onClick={handleEspecialidades}
                        disabled={boton}
                        color="primary">
                        GUARDAR
                      </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AgregarRelacion;
