import React from 'react';
import { Chip } from '../../../Core';
import Avatar from './Avatar';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

function Iconos({valor}) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
        {
            Array.isArray(valor)
            ? valor.map(v => (<Chip
                key={v}
                variant="outlined"
                size="small"
                color="primary"
                avatar={<Avatar valor={v} />}
                label={v}
            />))
            : <Chip
                variant="outlined"
                size="small"
                color="primary"
                //avatar={<Avatar valor={valor} />}
                label={valor}
            />
        }
        </div>
    );
}

export default Iconos;
