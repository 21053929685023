import React,{useContext} from 'react';
//Componentes
import DatosPassword from './Formularios/DatosPassword';
//context
import { CRMContext } from '../../../context/Perfil';
//Help
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
//Router
import { withRouter } from "react-router-dom";
function Password(props) {

    const [perfil, guardarPerfiles] = useContext(CRMContext);
    let { history } = props;

    //Eventos
    let componentePrincipal = (token) => {

        let datos = DecodificarToken(token);
        let tipo = datos.tipo;
        let componente;
        if (tipo != null || tipo != undefined) {

            switch (tipo) {
                case 1:
                    componente = (<>  <DatosPassword  email={datos.email}/></>);
                    break;
                case 2:
                    componente = (<>  <DatosPassword email={datos.email}/></>);
                    break;
                case 3:
                    componente = (<>  <DatosPassword  email={datos.email}/></>);
                    break;
                case 4:
                    componente = (<>  <DatosPassword  email={datos.email}/></>);
                    break;
                default:
            }

        } else {
            history.push('/');
        }
        return componente;
    }

    return (
        <>
           {componentePrincipal(perfil.General.token.valor)}
        </>
    );
}

export default withRouter(Password);
