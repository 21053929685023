import React from 'react';
import MenuLateral from '../../componentesGenericos/SideBar';
import AgendaContext from './context';

function Menu () {

    return(
        <MenuLateral titulo="Agenda">
            <AgendaContext />
        </MenuLateral>
    )

}

export default Menu;
