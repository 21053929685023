import gql from 'graphql-tag';

//#region DIRECCIONES API CATALOGO SAT
const OBTENER_DIRECCIONES_SAT = `
query ObtenerCodigoPostal($cp: String!){
  getCodigoPostal(cp:$cp){
   codigopostal
    colonias{
      claveColonia
      colonia
    }
    claveLocalidad
    localidad
    claveMunicipio
    municipio
    claveEstado
    estado
    clavePais
    pais
    agrupaciones
    regexpCP
    regexpRIT
    validacionRIT
    estimuloFranjaFronteriza
    inicioVigencia
    descUsoHorario
    mesIniVerano
    diaIniVerano
    horaIniVerano
    difHorariaVerano
    mesIniInvierno
    diaIniInvierno
    horaIniInvierno
    difHorariaInvierno
  }
}`;
//#endregion

export {
    OBTENER_DIRECCIONES_SAT
}
