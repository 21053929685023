import React, {useEffect, useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_HORARIO_DOCTOR, FILTRO_CLINICA_DOCTOR } from '../../query';
import { NUEVO_HORARIO_DOCTOR, MODIFICAR_HORARIO, ELIMINAR_HORARIO, ESTATUS_HORARIO_DOCTOR } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever } from '../../componentesGenericos/Icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

//Redux
import { useSelector, useDispatch } from 'react-redux';

const ftSelectFormat = (arreglo, {id, descripcion, otro}) => (arreglo.map(dato => {
    let resultado = { value: dato[id], label: dato[descripcion] };
    if(otro) resultado[otro] = dato[otro];
    return resultado;
    }));

export default function HorariosDoctor() {

    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);

    // QUERY AND MUTATIONS
    const { loading, error, data, refetch } = useQuery(LISTADO_HORARIO_DOCTOR, {
        variables: { limite: 100, pagina: 1, clinicaId: Number(_clinicaID) > 0 ? Number(_clinicaID) : null },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }

    });

    const [seleccionclinica, setSeleccionclinica] = useState(-1);
    const [opcionesClinicaState, setOpcionesClinicaState] = useState({})
    const [opcionesCliDocState, setOpcionesCliDocState] = useState({})
    const { data: data2 } = useQuery(FILTRO_CLINICA_DOCTOR, {
        variables: { limite: 150, pagina: 1, clinicaId: -2 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log("ERROR:", e.message)
            // setErrorState(true);
        },
        onCompleted: () => {
            // console.log("Datos:",data2)
            let opcionesClinica = new Array();
            let opca = new Array();

            if (data2) {
                if (data2.listadoClinicaDoctor !== null) {
                    data2.listadoClinicaDoctor.clinicaDoctores.map(valor => {
                        let item = { label: valor.nombre, value: Number(valor.clinicaId) };

                        if (!opca.includes(valor.clinicaId)) {
                            opcionesClinica.push(item);
                        }
                        opca.push(valor.clinicaId);
                        return true;
                    })
                }
            }
            setOpcionesClinicaState(opcionesClinica)
        }
    });



    const { data: data4 } = useQuery(FILTRO_CLINICA_DOCTOR, {
        variables: { limite: 100, pagina: 1, clinicaId: seleccionclinica !== -1 ? seleccionclinica : 0 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            // setErrorState(true);
        },
        onCompleted: () => {
            let opcionesDoctor = data4 ?
                data4.listadoClinicaDoctor.clinicaDoctores.map(valor => ({
                    label: valor.nombreCompleto + " (" + valor.correo + ")",
                    value: valor.clinicaDoctorId
                })) : null

                setOpcionesCliDocState(opcionesDoctor)
        }
    });


    const [addHorarioDoctor] = useMutation(NUEVO_HORARIO_DOCTOR, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el horario` });
            setSeleccionclinica(-1)
            setSeleccion({ ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modHorario] = useMutation(MODIFICAR_HORARIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el horario del profesional "${seleccion.nombres} ${seleccion.apellidos}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    const [statusHorarioDoctor] = useMutation(ESTATUS_HORARIO_DOCTOR, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus de horario del profesional  "${seleccion.nombres} ${seleccion.apellidos}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteHorario] = useMutation(ELIMINAR_HORARIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se elimino correctamente el horario del profesional  "${seleccion.nombres} ${seleccion.apellidos}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    // REGION STATES
    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [filtro, setFiltro] = useState("");

    const [errorState, setErrorState] = useState(false);

    const [checked, setChecked] = React.useState(false);

    const [actualizar, setActualizar] = useState(false);

    const [fila, setFila] = useState(-1);

    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [diasDB,setDiasDB] = useState([]);

    //END REGION STATES
    // REGION CONSTANTES
    const seleccionInicial = {
        horarioId: null,
        dias: [],
        desde: "00:00:00",
        hasta: "00:00:00",
        clinicaId: null,
        clinicaDoctorId: null,
        nombre: null,
        clave: null,
        estatusClinica: false,
        matriz: false,
        estatus: false,
        ausencia: false,
        nombreCompleto: null,
        mail: null,
        correo: null
    };

    const [seleccion, setSeleccion] = useState(seleccionInicial);

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'horarioId', label: 'horarioId', minWidth: 0, hide: true, aliasColor: "estatus" },
            {
                id: 'dias', label: 'Día', minWidth: 30, align: 'left', textAlign: 'left', filtro: true,
                format: (valor) => {
                    return valor.toString().replace("1", "Lunes").replace("2", "Martes").replace("3", "Miercoles").replace("4", "Jueves").replace("5", "Viernes").replace("6", "Sabado").replace("7", "Domingo");
                }
            },
            {
                id: 'desde', label: 'Desde', minWidth: 30, align: 'center',
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
                }
            },
            {
                id: 'hasta', label: 'Hasta', minWidth: 30, align: 'center',
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
                }
            },
            { id: 'clinicaDoctorId', label: 'clinicaDoctorId', minWidth: 0, hide: true },
            { id: 'clinicaId', label: 'clinicaId', minWidth: 0, hide: true },
            { id: 'nombreClinica', label: 'Sucursal', minWidth: 30, align: 'left', textAlign: 'left', filtro: true },
            { id: 'usuarioId', label: 'usuarioId', minWidth: 0, hide: true },
            { id: 'tipo', label: 'tipo', minWidth: 0, hide: true },
            { id: 'nombres', label: 'Profesional', obtenerColumna: 'apellidos', align: 'left', textAlign: 'left', format: (nombres, apellidos) => { return nombres + " " + apellidos } },
            // { id: 'apellidos', label: 'Apellidos', minWidth: 30, align: 'center', textAlign: 'center', filtro: true },
            { id: 'especialidadId', label: 'Especialidad', minWidth: 0, hide: true },
            { id: 'mail', label: 'Mail', minWidth: 0, hide: true },
            { id: 'estatus', label: 'Estatus', minWidth: 30, align: 'center', textAlign: 'center', component: "Checkbox" },
            {
                id: 'ausencia', label: 'Disponibilidad', minWidth: 30, align: 'left', textAlign: 'left',
                format: (valor) => {
                    return valor.toString().replace("false", "Disponible").replace("true", "Ausente");
                }
            }
        ];

    const menu = [
        { texto: "Agregar horario", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar horario", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Cambiar Estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar horario", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Desplazarse a profesionales", icono: <ArrowBackIosIcon />, onClick: () => window.location.href = "/profesionales" }
    ];

    let diasSemana = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];


    let opcionesDia = diasSemana.map((valor, indice) => ({
        label: valor,
        value: indice + 1
    }));

    /*function handleClinicaChange(e, val, limpiar) {
        setSeleccionclinica(e.target.value);
       /* setSeleccion({ ...seleccion,  clinicaID:e.target.value, clinicaDoctoID:null });*/
   /* }*/
     function handleClinicaChange (e) {
        let {value}= e.target;

        if (Number(value) >=0)
        {
            setSeleccionclinica(value);
            setSeleccion(seleccion=>({
                ...seleccion,
                clinicaDoctorId:null
            }))
        }
    }
    const handleChangeMovs = ({ target: { id, value } }) => {
        setSeleccion({
            ...seleccion,
            [id]: value
        })
    }
    const camposFormulario = [
        { id: "horarioId", texto: "horarioId", valor: seleccion.horarioId, tipo: "hidden" },
        {
            id: "clinicaId", texto: seleccion.clinicaId !== null ? "Sucursal *   (campo no modificable)" : "Sucursal *", valor: seleccion.clinicaId, tipo: "select", minWidth: '250px', maxWidth: '500px', opciones: opcionesClinicaState, xs: 12, onChange: handleClinicaChange, cleanDatoState: [{ id: 'clinicaDoctorId', value: '' }], propiedades: { disabled: seleccion.horarioId !== null ? true : false }, styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
            styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'},
        },

        { id: "clinicaDoctorId", texto: seleccion.clinicaId !== null ? "Profesional *  (campo no modificable)" : "Profesional *", valor: seleccion.clinicaDoctorId, tipo: "select", opciones: seleccionclinica !== -1 ? opcionesCliDocState : "Seleccione una sucursal", xs: 12, propiedades: { disabled: seleccion.clinicaId !== null ? true : false }},
        {
            id: "dias",
            texto: "Días *",
            valor: seleccion.dias,
            tipo: "select",
            multiselect:true,
            opciones: diasDB,
            xs: 12,

        },
        {
            id: 'desde', texto: "Desde *", valor: seleccion.desde, tipo: "time", xs: 6,
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
            }

        },
        {
            id: "hasta", texto: "Hasta *", valor: seleccion.hasta, tipo: "time", xs: 6,
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
            }
        },
        { id: "estatus", texto: "estatus", valor: seleccion.estatus,
          tipo: "hidden",
        },
        {
            id: "ausencia", texto: "Disponible", valor: seleccion.ausencia, tipo: "select",
            opciones: [
                { label: "No", value: true },
                { label: "Si", value: false }
            ], xs: 6
        }

    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoHorarioDoctor?.HorariosDoctores;

    // END REGION CONSTANTES

    //REGION HANDLES
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setSeleccionclinica(-1)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: (state) => ftAgregar(state) });

    }

    function handleModificarClick() {

        if (seleccion.horarioId !== null) {

            let todo = [];
            let i =0;
            seleccion.dias.map(resultado => {
                   todo[i] = Number(resultado);
                    i = i +1;
            });
            todo.sort();
            setSeleccion({ ...seleccion, desde: seleccion.desde.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"), hasta: seleccion.hasta.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"), dias:todo});
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un horario" });
        }
    }

    function handleCambiarEstatusClick() {
        if (seleccion.horarioId !== null) {
            setChecked(seleccion.estatus === false);
            let opcion = seleccion.estatus === false ? "activar" : "desactivar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} el horario de "${seleccion.nombres + " " + seleccion.apellidos}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({
                ...mensaje, ...mensajeError, texto: "Debe seleccionar un horario"
            });
        }
    }

    function handleEliminarClick() {
        if (seleccion.horarioId !== null) {
            setMensaje({
                ...mensaje,
                ...mensajeConfirmacion,
                texto: `¿Está seguro de eliminar el horario de "${seleccion.nombres + " " + seleccion.apellidos}"?`, onAccept: ftEliminar
            });
        }
        else {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: "Debe seleccionar un horario "
            });
        }
    }


    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus)
            setSeleccion(datos);
            setFila(index);
            setSeleccionclinica(datos.clinicaId);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }
    // END REGION HANDLES

    //REGION FUNCIONES
    function ftAgregar({ dias, desde, hasta, clinicaDoctorId = null, estatus = false, ausencia, cleanStateFormulario }) {

        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                desde = (desde === undefined || desde === null) ?
                    seleccion.desde : desde;
                hasta = (hasta === undefined || hasta === null) ?
                    seleccion.hasta : hasta;
                ausencia = (ausencia === undefined || ausencia === null) ?
                    seleccion.ausencia : ausencia;
                dias = (dias === undefined || dias === null) ?
                    seleccion.dias : dias

                let ValidarHora = desde < hasta ? true : false
                if (ftValidar([, clinicaDoctorId, dias, desde, hasta, ausencia])) {
                    if (ValidarHora === true) {

                        await addHorarioDoctor({
                            variables: {
                                horario: {
                                    dias : dias.toString(),
                                    desde: desde,
                                    hasta: hasta,
                                    clinicaId: null,
                                    clinicaDoctorId: Number(clinicaDoctorId),
                                    estatus: estatus,
                                    ausencia: ausencia
                                }
                            }
                        });
                        if (cleanStateFormulario) cleanStateFormulario();
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "La hora inicial no puede ser igual o mayor a la final" })
                    }
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();

    }

    function ftModificar({ dias, desde, hasta, clinicaDoctorId, estatus, ausencia, cleanStateFormulario }) {

        async function ftModificar() {
            try {
                setDisabledAccept(true);

                dias = dias ? dias : seleccion.dias;
                desde =  desde ? desde : seleccion.desde;
                hasta = hasta || seleccion.hasta;
                clinicaDoctorId = clinicaDoctorId || seleccion.clinicaDoctorId;
                estatus = estatus || seleccion.estatus;
                ausencia = ausencia == null ? seleccion.ausencia : ausencia;

                let ValidarHora = desde.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1") < hasta.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1") ? true : false


                if (ftValidar([seleccion.horarioId]) === true) {
                    if (ValidarHora === true) {
                        await modHorario({
                            variables: {
                                horario: {
                                    horarioId: seleccion.horarioId,
                                    dias : dias.toString(),
                                    desde: desde.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"),
                                    hasta: hasta.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"),
                                    clinicaId: null,
                                    clinicaDoctorId: Number(clinicaDoctorId),
                                    estatus: estatus,
                                    ausencia: ausencia
                                }
                            }
                        });
                        if (cleanStateFormulario) cleanStateFormulario();
                        refetch();
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "La hora inicial no puede ser igual o mayor a la final" })
                    }
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }


    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusHorarioDoctor({
                variables: {
                    id: Number(seleccion.horarioId),
                    estatus: seleccion.estatus === false ? 1 : 0
                }
            });
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteHorario({ variables: { id: Number(seleccion.horarioId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined && valor !== ""));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //END REGION FUNCIONES
    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) });
        setActualizar(false);
    }
    function stateOutAsignar(valorID = {})  {
        setSeleccion({ ...seleccion,  ...valorID });
    }
    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }
    useEffect(() => {
      setDiasDB(ftSelectFormat(opcionesDia, {  id: "value",
                descripcion: "label"}));
    }, []);


    return (
        <MenuLateral titulo="Horarios de profesionales" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto}
                onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Horario" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

            <Tabla titulo="Horarios de profesionales" columnas={columnas} datos=
                {listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.mensaje} onPaginaChange={handlePaginaChange} titleResponsive={["dias","desde","hasta","nombreClinica","nombres"]} />

        </MenuLateral>
    );
}
