import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {ftAnioNoValido,ftAniosRangosValidosAlterno} from "../../../../Helpers";
//CORE
import { Button, CardContent, CardActions, Card, Grid, TextField, MenuItem, FormHelperText, InputLabel, Select, FormControl } from '../../../../componentesGenericos/Core';
//CONTEXT
import { CRMContext } from '../../../../context/Perfil';
import { ACTUALIZAR_DATOS_PROFESIONALES } from '../../../../mutation';
import { LISTADO_ESPECIALIDAD_DOCTOR_CADENA } from '../../../../query';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//HELPERS
import {
    verificaValores,
    formatearCadena
} from "../../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../../Helpers/Validacion/ValidacionesCadenasClose";
//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONSTANTES
import { cedula } from './../../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial,mensajeExito } from '../../../../Helpers/constantes';
//ESTILOS
const useStyles = makeStyles({
    root: {
        maxWidth: 345,

    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1: {
        marginBottom: "10px"
    }

});

function Cedula(props) {

    //DECLARACIONES
    const classes = useStyles();
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(cedula);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [especialidades, guardarEspecialidades] = React.useState('');
    const [boton, setBoton] = useState(false);
    //EVENTOS
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarFechaEgreso = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value)===false || ftAnioNoValido(value)===true ) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const EventClickModificar = async () => {
        setBoton(true);
        let campos = [
            "especialidadId",
            "fechaEgreso"
        ];
        let datos = verificaValores(campos, perfil.General,[["especialidadId","Especialidad"],["fechaEgreso","Fecha de egreso"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            setBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchModificar();
            setBoton(false);
        }
    };
    //API
    const FetchModificar= async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        await FetchGrahpQL({
            query: ACTUALIZAR_DATOS_PROFESIONALES,
            variables: {
                usuarioId:  parseInt(props.usuarioId),
                especialidadId: parseInt(perfil.General.especialidadId.valor),
                fechaEgreso: perfil.General.fechaEgreso.valor
            }
        })
            .then(() => {
                setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la información` });

            })
            .catch((err) => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };
    const FetchobtenerEspecialidadesApi = async () => {
        await FetchGrahpQL({
            query: LISTADO_ESPECIALIDAD_DOCTOR_CADENA,
            variables: {
                offset: 0
            }
        })
            .then(resp => {
                guardarEspecialidades({ datos: resp.listadoEspecialidadDoctor })
            })
            .catch((err) => {
            });
    }
    //FUNCIONES
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.especialidadesDoctores.map(function (x) {
                row.push(<MenuItem key={x.especialidadId} value={x.especialidadId} readOnly>{x.descripcion}</MenuItem>);
            });
            return row;
        }
    };

    useEffect(() => {
        FetchobtenerEspecialidadesApi();
    }, []);

    return (
        <Card className={classes.mb1}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Datos profesionales</p>
            </div>
            <CardContent>
                {GenerarNotificacionv2(getNotificacion, "w100")}
                <Grid spacing={3} container direction="row">
                <Grid item xs={12} style={{marginTop:"10px"}}>
                    <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                        <InputLabel id="demo-simple-select-label" disabled required={true}>Especialidad</InputLabel>
                        <Select
                            labelId={`especialidad`}
                            id={`especialidad`}
                            error={perfil.General.especialidadId.error}
                            onChange={handleChangeGuardarState}
                            name="especialidadId"
                            disabled
                            required={true}
                            value={perfil.General.especialidadId.valor != null ? perfil.General.especialidadId.valor : -1}
                        >
                            <MenuItem key={'-1'} value={''} disabled>{'Escoge la especialidad'}</MenuItem>
                            {ftGenerarSelect(especialidades.datos)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{marginTop:"10px"}}>
                    <form className={classes.container} noValidate>
                        <TextField
                            fullWidth
                            id="date"
                            error={perfil.General.fechaEgreso.error}
                            label="Fecha de egreso"
                            type="date"
                            disabled
                            required={true}
                            name="fechaEgreso"
                            onChange={handleChangeGuardarFechaEgreso}
                            value={perfil.General.fechaEgreso.valor != null ? (perfil.General.fechaEgreso.valor).split("T")[0] : ""}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </form>
                </Grid>
                </Grid>
            </CardContent>


            <CardActions>

                {/* <Button size="small" color="primary" onClick={EventClickModificar} disabled={true}>
                    GUARDAR
             </Button> */}
            </CardActions>
        </Card>


    );
}

export default Cedula;
