import { useHistory } from "react-router-dom";
import { getUsuario } from '../../Helpers/Usuario';
import { OBTENER_USUARIO, OBTENER_DOCTOR, OBTENER_ADMINISTRADOR, OBTENER_PACIENTE } from '../../query';
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';
import { useDispatch, useSelector } from "react-redux";
import { IniciarSesion } from "../../Redux/Actions/SesionAction";
import { ObtenerMenu } from "../../Redux/Actions/MenuAction";
import { limpiarMenu } from '../../componentes/MenuPrincipal/helper';

export function useUsuario() {
    let history = useHistory();
    const user = useSelector(state => state.user.usuario[0].user.user);
    const usuario = getUsuario(user.token);
    const dispatch = useDispatch();
    const actualizarUsuario = (datos) => dispatch(IniciarSesion(datos));

    let obj = null;
    let query = null;

    if (usuario) {
        switch (usuario.usuario.tipo) {
            case 1: {
                query = OBTENER_DOCTOR;
                obj = 'obtenerDoctor';
                break;
            }
            case 2: {
                query = OBTENER_PACIENTE
                obj = 'obtenerPaciente';
                break;
            }
            case 3: {
                query = OBTENER_ADMINISTRADOR
                obj = 'obtenerAdministrador';
                break;
            }
            case 4: {
                query = OBTENER_USUARIO
                obj = 'obtenerUsuario';
                break;
            }
        }
    }

    async function getInfo() {
        return await FetchGrahpQL({ query, variables: { id: usuario ? usuario.usuario.usuarioId : -1 } }).catch(e => ({ error: e.message }))
    }

    async function validar() {
        let data = await getInfo();

        if (!data.error) {
            if (data[obj]) {
                if (data[obj].estatus === 0) {
                    killSession();
                }
            } else {
                // console.log("sin objeto")
            }
        } else {
            if (data.error === "No se obtuvieron los datos") {
                killSession();
            }
        }
    }

    async function killSession() {
        console.log("Sesión expirada")
        localStorage.clear()
        actualizarUsuario({ user: { user: { success: false, token: "" } } });
        history.push("/");
    }

    return validar;
}

export function useKillSession() {
    let history = useHistory();
    const dispatch = useDispatch();
    const actualizarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const setMenu = (datos) => dispatch(ObtenerMenu(datos));
    const menuSeleccion = useSelector(state => state.menu);

    async function killSession() {
        console.log("Sesión terminada")

        localStorage.clear()
        setMenu(limpiarMenu(menuSeleccion));
        actualizarUsuario({ user: { user: { success: false, token: "" } } });
        history.push("/");
    }

    return killSession;
}
