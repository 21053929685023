import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [conveniosUsuarios, guardarConveniosUsuarios] = useState({
        Convenios: {
            convenioId: { error: false, valor: "" },
            convenioUsuarioId: { error: false, valor: "" },
            empresaConvenio: { error: false, valor: "" },
            usuarioId: { error: false, valor: "" },
            nombreCompleto: { error: false, valor: "" },
            email: { error: false, valor: "" },
            fechaBajaConveniosUsuarios:{ error: false, valor:"" },
            fechaFinalConvenio:{ error: false, valor: "" },
        }
    });
    return (
        <CRMContext.Provider value={[conveniosUsuarios, guardarConveniosUsuarios]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
