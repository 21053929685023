import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Input from '../../Reportes/componentes/Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { queryConfiguracion, ActualizaLogo } from '../Metodos';

import { CRMContext } from '../../../context/Configuracion';
import { urlServer } from '../../../Helpers';

import { useSelector } from "react-redux";
import { rutaPublica, rutaPrivada, rutaPrivadaEmpresa } from '../../../Helpers/constantes/Config/urlRutas';
import { getImagen, postImagen, putImagen } from '../../../Helpers/constantes/Imagenes/getImagen'
import { consultaRutas } from '../../../Helpers/constantes/Config/prueba';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'
import { defaults } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ConfiguracionLogotipo({ setMensaje }) {

    const classes = useStyles();

    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;

    const defaultState = {
        urlLogotipo:urlServer+'/images/logo',
        fileUrl: null,
        rutaLogo:''
    };
    let reader = new FileReader();
    let imgDefault =  urlServer+'/images/logo';
    let rutaImagen = '';
    let sesiones = process.env.REACT_APP_DB;


    const [ state, setState ] = useState(defaultState);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);
    const [ stateImg, setStateImg] = useState(defaultState);

	const campos = [{
        id: 'label',
        texto: 'Solo se permiten archivos del tipo PNG',
        tipo: 'label',
        xs: 12
    },{
        id: 'fileUrl',
        texto: 'Adjuntar la imagen del login',
        valor: stateImg.fileUrl,
        tipo: 'superFile',
        propiedades: { readOnly: false },
        xs: 12
    }];
//({target}, id)
	const handleChange = e => {
        let { files } = e.target;
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = function (e) {
            setStateImg({...stateImg, urlLogotipo: e.target.result, fileUrl: files[0]});
        }

    }
    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);
                let emp = process.env.REACT_APP_NOMBRE_CLINICA;
                let empresa = emp;
                let error = 0;
                let urlLogotipo = '';
                let logoAnt = stateImg.rutaLogo;
                let metodo = 'post';
                let ruta = empresa+'/imagenes/logo/';

                if (logoAnt !== '') {

                    let result = await putImagen(`${'public/kalenday'}${logoAnt}`, stateImg.fileUrl);
                    if (result == true)
                    {
                        urlLogotipo = logoAnt;
                    }
                }
                else
                {
                    urlLogotipo = await postImagen(empresa+'/imagenes/logo/', stateImg.fileUrl, 0);
                }

                if (urlLogotipo !== '')
                {
                    const configuracion = await ActualizaLogo(urlLogotipo);
                    if (configuracion != ''){
                        setActualizar(true);
                        let rutacompleta = rutaPublica + urlLogotipo;
                        setState({
                            ...state,
                            rutacompleta
                        })
                        setStateImg({
                            ...stateImg,
                            rutaLogo: urlLogotipo
                        });

                        setMensaje({
                            visible: true,
                            tipo: 'success',
                            descripcion: '¡La imagen se guardo correctamente!'
                        });

                    }

                }
                else
                {
                    throw new Error("Error al actualizar el logo");
                }

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
            }
            setDisabledAccept(false);
        }
        click();
    }

    useEffect(() => {
        async function ftEffect() {
            const configuracion = await queryConfiguracion({
                nombreDB: sesiones,
            });
            setState({
                ...state,
                ...configuracion
            })
            Obtener();

        } ftEffect();

    }, []);

    const Obtener = async () => {
        let rutaimg = '';
        let rutaBd='';
        const configuracion = await queryConfiguracion({
            nombreDB: sesiones,
        });
        if (configuracion.urlLogotipo == '')
        {
            rutaimg = urlServer+'/images/logo';
        }
        else{
            rutaBd = configuracion.urlLogotipo;
            rutaimg = rutaPublica + rutaBd;
        }
        setStateImg({
            ...stateImg,
            urlLogotipo: rutaimg,
            rutaLogo: rutaBd,
            fileUrl: 'logo'
        });

    };
    return(
        <>
        <Grid handleEvent={stateImg} container direction={"row"} justify={"center"} alignItems={"center"}>
            <img id='logo'
                src={stateImg.urlLogotipo}
                alt="Imagen para inicio de sesión"
                title="Imagen para inicio de sesión"
            />
        </Grid>
        <Input campos={campos} handleChange={handleChange} state={stateImg} />
        <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Guardar" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
    </>);



}

export default ConfiguracionLogotipo;
