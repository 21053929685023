import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//REDUX
import { useSelector } from 'react-redux';

//Queries
import { LISTADO_ALMACENES, LISTADO_CLINICAS } from '../../query';
import { AGREGAR_ALMACEN, MODIFICAR_ALMACEN, CAMBIAR_ESTATUS_ALMACEN, ELIMINAR_ALMACEN } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/FormularioAntiguo';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever } from '../../componentesGenericos/Icons';

//Helpers
import { ValidarNull } from '../../Helpers/Validacion/ValidarNuloUndefined';

//expresiones regulares
import { rgxAlmenosUnaLetra } from '../../Helpers/constantes/Rgx';

export default function Almacen() {

    const clinicaId = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);

    //#region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_ALMACENES, {
        variables: { limite: 100, clinica: Number(clinicaId) },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const { data: Clinicas } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 100 },
        fetchPolicy: 'no-cache',
    });

    const [addAlmacen] = useMutation(AGREGAR_ALMACEN, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el almacén "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modAlmacen] = useMutation(MODIFICAR_ALMACEN, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el almacén "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statAlmacen] = useMutation(CAMBIAR_ESTATUS_ALMACEN, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente al almacén "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteAlmacen] = useMutation(ELIMINAR_ALMACEN, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el almacén "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region states
    const [actualizar, setActualizar] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [fila, setFila] = useState(-1);
    const [checked, setChecked] = useState(false);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState({
        almacenId: null,
        clinicaId: null,
        clinica: null,
        descripcion: null,
        estatus: null
    });
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Almacén",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [modificando, setModificando] = useState(false);
    //#endregion

    //#region constantes
    let clinicas = Clinicas ? Clinicas.listadoClinicas.clinicas.map(cl =>
        ({ label: cl.nombre, value: cl.clinicaId })
    ) : null;
    const seleccionInicial = {
        almacenId: null,
        clinicaId: null,
        clinica: null,
        descripcion: null,
        estatus: 1
    };
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'almacenId', label: 'AlmacenId', minWidth: 0, hide: true, aliasColor: "estatus" },
            { id: 'clinicaId', label: 'clinicaId', minWidth: 0, hide: true },
            { id: 'descripcion', label: 'Descripción', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'clinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'estatus', label: 'Estatus', minWidth: 50, align: 'center', component: "Checkbox" },
        ];
    const menu = [
        { texto: "Agregar almacén", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar almacén", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Activar/Desactivar", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar almacén", icono: <DeleteForever />, onClick: () => handleEliminarClick() }
    ];

    const camposFormulario = [
        {
            id: "AlmacenId",
            texto: "AlmacenId",
            valor: seleccion.almacenId,
            tipo: "hidden",
        },
        {
            id: "descripcion",
            texto: "Descripción *",
            valor: seleccion.descripcion || null,
            onChange: (e) => handleChange(e, "descripcion"),
            tipo: "text",
            xs: 12,
            margen: "dense",
            regexp: rgxAlmenosUnaLetra,
            ayuda: 'Campo obligatorio (Incluya al menos 1 letra)'
        },
        {
            id: "clinicaIdSelect",
            texto: "Sucursal *",
            valor: seleccion.clinicaId ? seleccion.clinicaId : Clinicas ? Clinicas.listadoClinicas.clinicas[0].clinicaId : -1,
            tipo: "select",
            xs: 12,
            opciones: clinicas,
            otros: {
                inputProps: { id: "clinicaId" }
            },
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            disabled: modificando
        },
        {
            id: "estatus",
            texto: "Estatus *",
            valor: seleccion.estatus === null ? 1 : seleccion.estatus,
            tipo: "select",
            onChange: (e) => handleChange(e, "estatus"),
            xs: 12,
            opciones: [
                {
                    label: "Inactivo",
                    value: 0
                },
                {
                    label: "Activo",
                    value: 1
                }
            ]
        }
    ];
    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data ?.listadoAlmacen ?.Almacenes;
    //#endregion

    //#region handlers
    function handleChange(e, id) {
        setSeleccion(seleccion => ({ ...seleccion, [id]: e ?.target ?.value }));
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setModificando(false);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar, titulo: "Agregar almacén" });
    }

    function handleModificarClick() {
        if (seleccion.almacenId !== null && seleccion.almacenId !== undefined) {
            setModificando(true);
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar, titulo: "Modificar almacén" });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un almacén" });
        }
    }

    function handleCambiarEstatusClick() {
        if (seleccion.almacenId !== null && seleccion.almacenId !== undefined) {
            setChecked(seleccion.estatus === 0);
            let opcion = seleccion.estatus === 0 ? "activar" : "desactivar";

            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} el almacén "${seleccion.descripcion}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un almacén" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.almacenId !== null && seleccion.almacenId !== undefined) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el almacén "${seleccion.descripcion}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un almacén" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setChecked(datos.estatus !== 0)
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccion, ...seleccionInicial });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }
    //#endregion

    //#region funciones
    function ftAgregar({ descripcion, clinicaId, estatus }) {
        setDisabledAccept(true);
        let valores = { descripcion, clinicaId, estatus };

        (valores = ValidarNull(valores, { descripcion: window.descripcion.value, clinicaId: window.clinicaId.value, estatus: window.estatus.value }));

        setSeleccion({ ...seleccion, ...valores });

        valores.clinicaId = Number(valores.clinicaId);
        valores.estatus = Number(valores.estatus);

        let validacion = ftValidarObligatorios(valores);
        if (validacion === "") {
            validacion = ftValidarFormato({ ...seleccion, ...valores })
            if (validacion === "") {
                addAlmacen({
                    variables: {
                        Almacen: valores
                    }
                });
            }
        }
        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftModificar({ descripcion, clinicaId, estatus }) {
        setDisabledAccept(true);
        let valores = { almacenId: seleccion.almacenId, descripcion, clinicaId, estatus };

        (valores = ValidarNull(valores, { descripcion: window.descripcion.value, clinicaId: window.clinicaId.value, estatus: window.estatus.value }));

        valores.clinicaId = Number(valores.clinicaId);
        valores.estatus = Number(valores.estatus);

        let validacion = ftValidarObligatorios(valores);
        if (validacion === "") {
            validacion = ftValidarFormato({ ...seleccion, ...valores });
            if (validacion === "") {
                modAlmacen({
                    variables: {
                        Almacen: valores
                    }
                });
            }
        }
        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftCambiarEstatus() {
        setDisabledAccept(true);
        statAlmacen({
            variables: {
                id: Number(seleccion.almacenId),
                estatus: seleccion.estatus === 0 ? 1 : 0
            }
        })
    }

    function ftEliminar() {
        setDisabledAccept(true);
        deleteAlmacen({ variables: { id: Number(seleccion.almacenId) } });
    }

    function ftValidarObligatorios(campos) {
        if (!campos.descripcion) return "Debe especificar una descripción";
        if (campos.descripcion.trim() === "") return "Debe especificar una descripción";
        if (!campos.clinicaId || campos.clinicaId === -1) return "Debe seleccionar una sucursal";
        return Object.values(campos).every((valor) => (valor !== null && valor !== undefined)) ? "" : "Faltan valores obligatorios";
    }

    function ftValidarFormato(campos) {
        return Number(campos.clinicaId) < 1 ? "Debe Seleccionar una sucursal" : "";
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
        // setSeleccion(seleccionInicial);
        // setFila(-1);
    }
    //#endregion

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }, [clinicaId]);

    return (
        <MenuLateral titulo="Almacén" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={formulario.titulo} onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} datos={seleccion} sync />

            <Tabla titulo="Listado de almacenes" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcion", "clinica"]} />

        </MenuLateral>
    );
}
