import React, { useState, useEffect } from 'react';
//RECURSOS
import { makeStyles } from '@material-ui/core/styles';
//REDUX
import { useDispatch } from "react-redux";
import { FotoPerfil } from "../../../../Redux/Actions/FotoPerfilAction";
//ICONOS
import { CloudUploadIcon } from '../../../../componentesGenericos/Icons';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//QUERY
import { OBTENER_FOTO_PERFIL } from '../../../../query/componentes/FotoPerfil';
import { NUEVO_MODIFICAR_FOTO_PERFIL } from '../../../../mutation/componentes/FotoPerfil';
//CORE
import { Button, CardContent, CardActions, Card, Grid, CardMedia, Tooltip } from '../../../../componentesGenericos/Core';
//HELPERS
import {
    verificaValores,
    formatearCadena
} from "../../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../../Helpers/Validacion/ValidacionesCadenasClose";
//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONSTANTES
import { mensajeError, mensajeInicial,mensajeExito } from '../../../../Helpers/constantes';
// Variables
import { urlServer, strUrlSCliente } from '../../../../Helpers';
import { strUrlSArchivos } from '../../../../Helpers/constantes/Config/UrlServer';
import { rutaPublica, rutaPrivada, rutaPrivadaEmpresa } from '../../../../Helpers/constantes/Config/urlRutas';
import { perfilhombre, perfilmujer, iconoDoctor} from '../../../../Helpers/constantes/Config/imgDefault';
import { getImagen, postImagen, putImagen } from '../../../../Helpers/constantes/Imagenes/getImagen'
import { ImageRounded, LensOutlined } from '@material-ui/icons';
import { obtenerStateStorage } from '../../../../Helpers/LocalStorage/LocalStorage'
//ESTILOS
const useStyles = makeStyles({
    root: {
        maxWidth: 345,

    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1: {
        marginBottom: "10px"
    },
    input: {
        display: 'none',
    },

});

function Cedula(props) {

    //DECLARACIONES
    const classes = useStyles();
    let reader = new FileReader();
    let extensionesValidas = ["png", "jpg", "gif", "jpeg", "JPG", "JPEG", "PNG"];
    const [getNotificacion, setNotificacion] = useState({
        General: {
            archivo: { error: false, valor: "" }
        }
    });
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [foto, guardarFoto] = useState({ archivo: { valor: "", error: false, mensaje: "" } });
    const [boton, setBoton] = useState(false);
    const [ruta, setRuta] = useState({ valor: `${rutaPublica}${perfilhombre}`, rutaImagen:'' });

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const agregaRutaPerfil = (datos) => dispatch(FotoPerfil(datos));

    //EVENTOS
    const EventClickPerfil = async () => {
        deshabilitarBoton(true);
        let campos = [
            "archivo"
        ];
        let datos = verificaValores(campos, foto,[["archivo","imagen"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje: foto.archivo.mensaje === "" ? mensaje : `${mensaje}(${foto.archivo.mensaje})`
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await Agregar();
            await Obtener();
            deshabilitarBoton(false);
        }
    };
    const verificaExtension = (e) => {
        try {
            let checador = false;
            for (let index = 0; index < extensionesValidas.length; index++) {
                if (e.target.files[0].type.indexOf(extensionesValidas[index]) === -1) {
                    checador = false;
                } else {
                    checador = true;
                    break;
                }
            }
            return checador;
        } catch (error) {
            return false;
        }
    }
    const verificaSize = (e) => {
        try {
            if ((e.target.files[0].size / 1000000) <= 10 && e.target.files[0].size !== 0) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
    const onChangefileImagen = e => {
        if (verificaSize(e) === true && verificaExtension(e) === true) {
            guardarFoto({ archivo: { valor: e.target.files[0], error: false, mensaje: "" } });
            //Canalizo la fotografia
            reader.readAsDataURL(e.target.files[0]);
            let rutaServer = ruta.rutaImagen;
            reader.onload = function (e) {
                 setRuta({ valor: e.target.result, rutaImagen: rutaServer });
            }

        } else {
            guardarFoto({ archivo: { valor: e.target.files[0], error: true, mensaje: "La imagen supera el tamaño permitido o el formato no es correcto" } });
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje: `imagen (La imagen supera el tamaño permitido o el formato no es correcto)`
            });
        }

    };
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }
    //API
    const Agregar = async (e) => {

        let rutaImagen = '';
        let imagenAnt = ruta.rutaImagen;
        let metodo = 'post';
        let empresa = process.env.REACT_APP_NOMBRE_CLINICA;
        let rutaServer = empresa+'/imagenes/fotosPerfil/';
        if (imagenAnt !== '') {
           metodo = 'put';
           rutaServer = `${'private/kalenday'}${imagenAnt}`;
        }

        let x = await archivo(rutaServer, metodo, foto.archivo.valor, 1).then(imagen => {
            if (imagen !== '' || imagen == true)
            {
                guardarFoto({ archivo: { valor: "", error: false, mensaje: "" }});
                return imagen;
            }
            else {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: `Ocurrio un error al cargar la imagen.` });
            }
        });
        rutaImagen = x;
        if (metodo == 'put' && x == true)
        {
            rutaImagen = imagenAnt;
        }
        if (rutaImagen !== '' )
        {
            await FetchGrahpQL({
                query: NUEVO_MODIFICAR_FOTO_PERFIL,
                variables: {
                    usuarioId: props.usuarioId,
                    ruta: rutaImagen
                }
            })
            .then((data) => {
                console.log(data);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Operación correcta` });
             })
            .catch((error) => {
                console.log(error);
            });
        }
    };
    //GET API

    const Obtener = async () => {

        await FetchGrahpQL({
            query: OBTENER_FOTO_PERFIL,
            variables: {
                id: props.usuarioId,
            }
        })
            .then((data) => {
                let rutaimagen = `${rutaPrivada}${data.obtenerFotoPerfil.ruta}`;
                archivo(rutaimagen, 'get', null, 0).then(imagen => {

                agregaRutaPerfil({ ruta: imagen });
                setRuta({
                    valor: imagen,
                    rutaImagen: data.obtenerFotoPerfil.ruta
                })
                });
            })
        .catch(() => { });
    };
    const archivo = async function(ruta, accion, archivo, privado) { //get storage

        let resultado = '';
        if (accion === 'get') {
           resultado = await getImagen(ruta);
        }
        else if(accion === 'post') {
           resultado = await postImagen(ruta, archivo, privado);
        }
        else{
           resultado = await putImagen(ruta, archivo);
        }
        return resultado;
    };

    //FUNCIONES
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    useEffect(() => {
        Obtener();

    }, []);

     return (
        <Card className={classes.mb1}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Foto del perfil</p>
            </div>
            <CardContent>
                <Grid spacing={3} container direction="row" alignItems="center" justify="center">
                        {GenerarNotificacionv2(getNotificacion, "w100")}
                    <Grid item sm={6} md={6} lg={6}>
                        <CardMedia
                        style={{marginTop:"10px"}}
                            component="img"
                            alt="imagen de perfil"
                            height="auto"
                            width="auto"
                         //   image={ruta.valor}
                            src={ruta.valor}
                            title="Foto perfil"
                            id="fotoPerfil"
                        />

                    </Grid>
                    <Grid item sm={6} md={6} lg={6} style={{ textAlign: "center" }}>
                        <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={onChangefileImagen}
                        />
                        <label htmlFor="contained-button-file">
                            <Tooltip title="La fotografía debe ser menor a los 10 mb (png, jpg ,gif y jpeg)" interactive>
                                <Button
                                    disabled={boton}
                                    variant="contained"
                                    color="default"
                                    component="span"
                                    className={classes.button}
                                    startIcon={<CloudUploadIcon />}
                                >Seleccionar foto del perfil</Button>
                            </Tooltip>
                        </label>
                    </Grid>
                    <Grid item sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={EventClickPerfil} disabled={boton}>
                    GUARDAR
             </Button>
            </CardActions>
        </Card>


    );
}

export default Cedula;
