import React, { useState, useContext,useEffect } from 'react';
import { CRMContext, CRMProvider } from './../../context/AsignacionSeries';
//REDUX*****
import { useSelector } from "react-redux";
//Apollo*****
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries****
import { LISTADO_SERIE } from '../../query';
import { ACTUALIZAR_ESTATUS_SERIE,ACTUALIZAR_ESTATUS_PORDEFECTO_SERIE} from '../../mutation';
//Componentes***
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Agregar from './Agregar';
import Modificar from './Modificar';
//constantes***
import { seleccionInicial, menu, filaState, seleccionState, formularioState, Style } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

function Serie() {


    // #region redux
    const user = useSelector((state) => state.selecciondeClinica);
    let clinicaIdRedux=user.seleccionClinica[0].clinicaId;
    //#endregion
    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_SERIE, {
        fetchPolicy: 'no-cache',
        variables: { clinicaId:parseInt(clinicaIdRedux)===0?null:parseInt(clinicaIdRedux),serieNombre:null,tipoDocumentoId:null,estatusSerie:null,porDefecto:null},
        onError:(e)=>{}
    });


    const [statusUsuario] = useMutation(ACTUALIZAR_ESTATUS_SERIE, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus por defecto correctamente` });
            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });


    const [statusPorDefecto] = useMutation(ACTUALIZAR_ESTATUS_PORDEFECTO_SERIE, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente` });
            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    //#endregion

    //#region states
    const [asignacion, guardarAsignacion] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);
    const [checkedPorDefecto, setCheckedPorDefecto] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    //#endregion

    //#region columnas
    const columnas =errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'serieId', label: 'serieId ', minWidth: 0, hide: true, align: 'left', textAlign: 'left',aliasColor:"estatusSerie" },
        { id: 'nombreClinica', label: 'Sucursal ', minWidth: 170, hide: false, align: 'left', textAlign: 'left' },
        { id: 'serieNombre', label: 'Serie', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'tipoDocumentoId', label: 'Tipo Documento', minWidth: 170, align: 'center', filtro: false,hide: true},
        { id: 'nombreDocumento', label: 'Documento', minWidth: 170, hide: false, textAlign:'left', filtro:true, align: 'left' },
        { id: 'estatusSerie', label: 'Estatus ', minWidth: 170, align: 'center', hide: false ,component: "Checkbox"},
        { id: 'porDefecto', label: 'Por defecto ', minWidth: 170, align: 'center', hide: false , component: "Checkbox"},
        { id: 'clinicaId', label: 'clinicaId ', minWidth: 170, align: 'center', hide: true},
        { id: 'aliasClinica', label: 'Alias De Clinica ', minWidth: 170, align: 'center', hide: true },
        { id: 'estatusClinica', label: 'Estatus De Clinica ', minWidth: 170, align: 'center', hide: true },
        {
            id: 'fechaAltaSerie', label: 'Fecha de alta de serie', minWidth: 170,align: 'left', textAlign: 'left',filtro:true,
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
            },filtro: true
        },
    ];
    //#endregion

    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setSeleccion(seleccionInicial)
        setOpenModifica(false);
    }

    function handleAgregarClick() {
        ftCloseRespuestaAbrir();
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function handleModificarClick() {
        if (seleccion.serieId !== null && seleccion.serieId!==undefined && fila!=-1){
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una serie" });
        }
    }



    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatusSerie !== 0)
            setCheckedPorDefecto(datos.porDefecto !== 0);
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function ftMensajeCancel() {
        setSeleccion(seleccionInicial);
        setChecked(seleccion.estatusSerie !== 0);
        setCheckedPorDefecto(seleccion.porDefecto !== 0);
        ftMensajeClose();
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
    await    statusUsuario({
            variables: {
                serieId: Number(seleccion.serieId),
                estatus: seleccion.estatusSerie === 0 ? 1 : 0
            }
        })
        setDisabledDelete(false);
    }  ftCambiarEstatus();
    }

    function ftCambiarEstatusPorDefecto() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);


   await     statusPorDefecto({
            variables: {
                serieId: Number(seleccion.serieId),
                estatus: seleccion.porDefecto === 0 ? 1 : 0,
                clinicaId: parseInt(seleccion.clinicaId),
                tipoDocumentoId: parseInt(seleccion.tipoDocumentoId),
            }
        })
        setDisabledDelete(false);
    }  ftCambiarEstatus();
    }



    function handleCambiarEstatusClick() {
        if (seleccion.serieId !== null && seleccion.serieId!==undefined && fila != -1) {
            setChecked(seleccion.estatusSerie === 0);
            let opcion = seleccion.estatusSerie === 1 ? "desactivar" : "activar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la serie "${seleccion.serieNombre}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una serie" });
        }
    }

    function handleCambiarEstatusPorDefectoClick() {
        if ( (seleccion.porDefecto !== null && seleccion.porDefecto!==undefined && fila != -1)  ) {
            setCheckedPorDefecto(seleccion.porDefecto === 0);
            let opcion = seleccion.porDefecto === 1 ? "desactivar" : "activar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la serie "${seleccion.serieNombre} por defecto"?`, onAccept: ftCambiarEstatusPorDefecto, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una serie" });

        }
    }

    const actualizarTabla=()=>{
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
           });
           setActualizar(false);
    }


    //#endregion

    //#region funciones
    function ftMensajeClose() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFila(-1);
        setSeleccion(seleccionInicial)
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseRespuestaAbrir() {
        setOpen(true);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    const handleClose = newValue => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    //#endregion


    //#region actualiza

    useEffect(() => {
        setActualizar(true);
        setFila(-1);
    },[clinicaIdRedux]);

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoSerie?.series;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
           });
           setActualizar(false);
       }



//#endregion


    return (<>
        <CRMProvider value={[asignacion, guardarAsignacion]}>
            <MenuLateral titulo="Asignacion" menu={menu(handleAgregarClick, handleModificarClick, handleCambiarEstatusClick,handleCambiarEstatusPorDefectoClick, checked,checkedPorDefecto)}>
                <Agregar
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    botonder={"Agregar"}
                    botonizq={"Cancelar"}
                    actualiza={() => actualizarTabla()}
                    ftClose={handleClose}
                    seleccion={seleccion}
                    index={setFila}
                />
                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()}
                index={setFila}
                setSeleccion={setSeleccion} botonder={"Modificar"}
                botonizq={"Cancelar"} value={value} />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete}/>


                <Tabla titulo="Listado de series" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreClinica", "serieNombre", "nombreDocumento"]} />


            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default Serie;
