import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SELECT_ESTATUS_FETCH, LISATADO_RECORDATORIO, LISTADO_MEDIOS_FETCH } from '../../query';
import { NUEVO_RECORDATORIO, MODIFICA_RECORDATORIO, ELIMINAR_RECORDATORIO } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';

import { ftValidarCampos } from '../../Helpers/constantes/Config/Helper';
import { ftSelectFormat, trim } from '../../Helpers';
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';

import { rgxSelectID, rgxNoVacio, rgxAlmenosUnaLetra } from '../../Helpers/constantes/Rgx';

function Recordatorios () {

    const { loading, error:{message} = {}, data, refetch } = useQuery(LISATADO_RECORDATORIO,
        {
        fetchPolicy: 'no-cache',
        variables: { limite: 100 },
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted:()=>{
            setErrorState(false);
        }
    });

    const [deleteRecordatorio] = useMutation(ELIMINAR_RECORDATORIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el recordatorio del estatus` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addRecordatorio] = useMutation(NUEVO_RECORDATORIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el recordatorio` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [updateRecordatorio] = useMutation(MODIFICA_RECORDATORIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el recordatorio` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const seleccionInicial = {
        recordatorioID: null,
        estatusID: '',
        descripcionEstatus: null,
        colorEstatus: null,
        estatusPredeterminado: false,
        medio: [],
        dia: 0,
        hora: 0,
        minuto: 0,
        tituloRecordatorio: '',
        descripcionRecordatorio: '',
        notificarAntesCita: true,
        confirmacionEmailID: null,
        descripcionConfirmacion: ''
    };

    //#region states
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [disabledConfirmacion, setDisabledConfirmacion] = useState(true);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState({...mensajeInicial});
    const [estatusCitasSel, setEstatusCitasSel] = useState([]);
    const [medios, setMedios] = useState([]);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [stateCopy, setStateCopy] = useState({});
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    //#endregion

    const columnas = errorState ? [{ id: 'msj', label: '', format: valor => (<p style={{ fontSize: 18, margin: 0 }}>{valor}</p>) }]
    : [
        { id: 'recordatorioID', label: 'ID', minWidth: 0, hide: true },
        { id: 'descripcionEstatus', label: 'Estatus', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'medio', label: 'Medio', minWidth: 170, align: 'center', component: "Chip", filtro: true },
        { id: 'tituloRecordatorio', label: 'Recordatorio', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
        { id: 'descripcionRecordatorio', label: 'Descripción', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'descripcionEstatusConfirmacionEmail', label: 'Estatus a modificar por confirmación', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'descripcionConfirmacion', label: 'Descripción del mensaje para confirmación', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'notificarAntesCita', label: 'Notificar antes de la cita', minWidth: 170, align: 'center', component: "Checkbox" }
    ];

    const menu = [
        { texto: "Agregar recordatorio", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar recordatorio", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar recordatorio", icono: <DeleteForever />, onClick: () => handleEliminarClick() }
    ];

    //const medios = ["Email", "Llamada", "MensajeTexto", "NotificacionPush", "GoogleCalendar"].map(v => ({value: v, label: v}));
    // const dias = [0,1,2,3,4,5,6,7,8,9,10].map(n => ({value: n, label: n}));
    const horas = [0,1,2,5,10,15,20].map(n => ({value: n, label: n}));
    const minutos = [0,15,30,45].map(n => ({value: n, label: n}));

    const queryMedios = () => {
        async function queryEstatus() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTADO_MEDIOS_FETCH,
                    variables: { limite: 100 }
                });

                let {listadoMedios:{medios}} = resultado;

                return ftSelectFormat(medios, {
                    id: "clave",
                    descripcion: "descripcion"
                });
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryEstatus();
    };

    const queryEstatus = () => {
        async function queryEstatus() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_ESTATUS_FETCH,
                    variables: { limite: 100, predeterminado: null }
                });

                let {listadoEstatusCita:{estatusCitas}} = resultado;

                return ftSelectFormat(estatusCitas, {
                    id: "estatusID",
                    descripcion: "descripcionEstatus",
                    otro: [{ id: "colorEstatus", alias: "color" }]
                }, "Seleccionar estatus del evento");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryEstatus();
    };

    const camposFormulario = [
        {
            id: "recordatorioID",
            texto: "recordatorioID",
            valor: seleccion.recordatorioID,
            tipo: "hidden"
        },
        {
            id: "estatusID",
            texto: "Seleccionar el estatus de la cita *",
            valor: seleccion.estatusID,
            tipo: "select",
            opciones: estatusCitasSel,
            ayuda: "Seleccione un estatus válido",
            // styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            // styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            regexp: rgxSelectID
        },
        {
            id: "medio",
            texto: "Seleccionar los medios *",
            valor: seleccion.medio,
            tipo: "select",
            opciones: medios,
            multiselect: true,
            xs: 12,
            ayuda: "Seleccione mínimo un medio",
            regexp: rgxNoVacio,
            // styleSelect: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            // styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            onChange: handleChangeMedio
        },
        // {
        //     id: "dia",
        //     texto: "Día",
        //     valor: seleccion.dia,
        //     tipo: "select",
        //     opciones: dias,
        //     xs: 4
        // },
        {
            id: "hora",
            texto: "Horas para notificar",
            valor: seleccion.hora,
            tipo: "select",
            opciones: horas,
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            xs: 6
        },
        {
            id: "minuto",
            texto: "Minutos para notificar",
            valor: seleccion.minuto,
            tipo: "select",
            opciones: minutos,
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            xs: 6
        },
        {
            id: "tituloRecordatorio",
            texto: "Nombre del recordatorio *",
            valor: seleccion.tituloRecordatorio,
            tipo: "text",
            xs: 12,
            ayuda: "Se permite capturar al menos una letra",
            regexp: rgxAlmenosUnaLetra
        },
        {
            id: "descripcionRecordatorio",
            texto: "Descripción",
            valor: seleccion.descripcionRecordatorio,
            tipo: "textarea",
            xs: 12,
        },
        {
            id: "notificarAntesCita",
            texto: "Notificación",
            valor: seleccion.notificarAntesCita,
            opciones: [
                { value: true, label: "Antes de la cita" },
                { value: false, label: "Después de la cita" }
            ],
            tipo: "superradio",
            onChange: handleChangeRadio,
            xs: 12,
        },
        {
            id: "descripcionConfirmacion",
            texto: "Descripción del mensaje",
            valor: seleccion.descripcionConfirmacion,
            tipo: "textarea",
            xs: 12,
        },
        {
            id: "confirmacionEmailID",
            texto: "Estatusa a modificar por confirmación (Email)",
            valor: seleccion.confirmacionEmailID,
            tipo: "select",
            opciones: estatusCitasSel,
            disabled: seleccion.medio.includes("Email") ? false : disabledConfirmacion,
            ayuda: "Seleccione un estatus válido",
            // styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            // styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            regexp: rgxSelectID

        }
    ];
    let sinRegistros = [{ msj: message ? message : "No se encontraron registros" }];
    const listado = errorState ? sinRegistros : (data ? (data.listadoRecordatorios ? data.listadoRecordatorios.recordatorios : sinRegistros) : sinRegistros);

    //#region handlers
    function handleChangeMedio({target:{value}}) {
        if(Array.isArray(value) && value.includes("Email")) setDisabledConfirmacion(false);

        setSeleccion(seleccion => ({
            ...seleccion,
            confirmacionEmailID: null
        }));
        setDisabledConfirmacion(true);
    }

    function handleChangeRadio ({target:{value:notificarAntesCita}}) {

        setSeleccion({
            ...seleccion,
            notificarAntesCita: notificarAntesCita === 'true' ? true : false
        });
    }

    function handleAgregarClick() {
        setDisabledConfirmacion(true);
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleModificarClick() {
        setDisabledConfirmacion(true);
        if (seleccion.recordatorioID) {
            setSeleccion({ ...stateCopy });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un recordatorio" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.recordatorioID) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el recordatorio?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un recordatorio" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion({ ...datos });
            setStateCopy({ ...datos });
            setFila(index);
        } else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function ftAgregar({estatusID, medio, dia, hora, minuto, tituloRecordatorio, descripcionRecordatorio, notificarAntesCita, confirmacionEmailID, descripcionConfirmacion, cleanStateFormulario}) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                let notificarAntesCitaFormat = typeof notificarAntesCita === "boolean" ? notificarAntesCita : false;

                if (ftValidarCampos([estatusID, medio, trim(tituloRecordatorio)]) && (Number(dia) >= 0 && Number(hora) >= 0 && Number(minuto) >= 0)) {

                    if(medio.length === 0) throw new Error("Se debe seleccionar al menos un medio");

                    confirmacionEmailID = Number(confirmacionEmailID);

                    await addRecordatorio({
                        variables: {
                            estatusID: Number(estatusID),
                            medio,
                            dia: Number(dia),
                            hora: Number(hora),
                            minuto: Number(minuto),
                            tituloRecordatorio: trim(tituloRecordatorio),
                            descripcionRecordatorio: trim(descripcionRecordatorio),
                            notificarAntesCita: notificarAntesCitaFormat,
                            confirmacionEmailID: !confirmacionEmailID ? null : confirmacionEmailID,
                            descripcionConfirmacion
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({recordatorioID, estatusID, medio, dia, hora, minuto, tituloRecordatorio, descripcionRecordatorio, notificarAntesCita, confirmacionEmailID, descripcionConfirmacion, cleanStateFormulario}) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);

                let notificarAntesCitaFormat = typeof notificarAntesCita === "boolean" ? notificarAntesCita : false;

                if (ftValidarCampos([recordatorioID, estatusID, medio, trim(tituloRecordatorio)]) && (Number(dia) >= 0 && Number(hora) >= 0 && Number(minuto) >= 0)) {

                    if(Array.isArray(medio) && medio.length === 0) throw new Error("Se debe seleccionar al menos un medio");

                    confirmacionEmailID = Number(confirmacionEmailID);

                    await updateRecordatorio({
                        variables: {
                            recordatorioID: Number(recordatorioID),
                            estatusID,
                            medio,
                            dia: Number(dia),
                            hora: Number(hora),
                            minuto: Number(minuto),
                            tituloRecordatorio: trim(tituloRecordatorio),
                            descripcionRecordatorio: trim(descripcionRecordatorio),
                            notificarAntesCita: notificarAntesCitaFormat,
                            confirmacionEmailID: !confirmacionEmailID ? null : confirmacionEmailID,
                            descripcionConfirmacion
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteRecordatorio({ variables: { id: Number(seleccion.recordatorioID) } });
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.error(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        async function fEffect() {
            const estatus = await queryEstatus();
            const medios = await queryMedios();
            setEstatusCitasSel(estatus);
            setMedios(medios);
        } fEffect();
    }, []);

    return(<MenuLateral titulo="Recordatorios" menu={menu}>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

        <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={`${seleccion.recordatorioID ? "Modificar" : "Agregar"} recordatorio`} onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

        <Tabla titulo="Recordatorios" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcionEstatus","Medio","tituloRecordatorio","descripcionRecordatorio"]} />

    </MenuLateral>);

}

export default Recordatorios;
