import React, { useState, useEffect, useContext } from 'react';
//DEPENDENCIAS
import { QRCode } from "react-qr-svg";
//FETCH
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//QUERY
import { OBTENER_QR } from '../../../query/componentes/ConvenioUsuarios';
//context
import { CRMContext } from '../../../context/Perfil';
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
import { CircularProgress } from '@material-ui/core';

function CodigoQR({ token: enviado, width = 256 }) {

    //DECLARACIONES
    const [token, setToken] = useState("");
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const FetchObtenerQr = async () => {
        let datos = enviado ? DecodificarToken(enviado) : DecodificarToken(perfil.General.token.valor);

        await FetchGrahpQL({
            query: OBTENER_QR,
            variables: {
                id: datos.usuarioId
            }
        })
            .then((resp) => {
                setToken(`${resp.GenerarQRCompleto}`);
                setLoading(false);
                setError(false);
            })
            .catch((err) => {
                let valido = !err.message.includes("El usuario no cuenta con ningún convenio") && !err.message.includes("No se encontraron registros");
                setLoading(false);
                setError(valido);
            });
    };

    //CICLO DE VIDA
    useEffect(() => {
        FetchObtenerQr();
    }, []);

    //FUNCIONES



    return (
        <>
            {
                loading ?
                    <CircularProgress />
                    :
                    error ?
                        <h2>Ocurrió un error al obtener el QR</h2>
                        :
                        <QRCode
                            bgColor="#ffffff"
                            fgColor="#0B0908"
                            level="L"
                            style={{ width }
                            }
                            value={token}
                        />
            }
        </>
    )
}

export default CodigoQR
