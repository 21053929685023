import gql from 'graphql-tag';

//#region Estatus Programado
//=====================================================
const LISATADO_ESTATUS_PROGRAMADO = gql`
  query listadoProgramacion($limite: Int, $pagina: Int) {
    listadoProgramacion(limite: $limite, pagina: $pagina) {
            programados {
                programacionID
                estatusID
                descripcionEstatusCita
                colorEstatusCita
                nuevoEstatusID
                descripcionEstatusCitaNueva
                colorEstatusCitaNueva
                dia
                hora
                minuto
                programarAntesCita
            }
            registros
            paginas
        }
    }
`;
//#endregion

export {
    LISATADO_ESTATUS_PROGRAMADO
}
