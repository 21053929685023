import React, { useState, useContext, useEffect } from 'react';
//****Router*********************************
import { withRouter } from "react-router-dom"
//****PROVIDER**************************************
import { OBTENER_SESION_EMPRESA } from '../../../query/componentes/KalendaySesiones';
import { CRMContext, CRMProvider } from './../../../context/Registro';
//*************Core-components*********************
import { Grid, InputAdornment, TextField, Typography, CardContent, Card, CardActions, Dialog, DialogTitle, IconButton, Fade }
    from '../../../componentesGenericos/Core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
//*************Iconos******************************
import { LockOpenIcon, PersonIcon, CloseIcon } from '../../../componentesGenericos/Icons';
//*************Componentes-Genericos****************
import BotonBoostrap from "../../../componentesGenericos/Botones/BotonBoostrap";
//*************Helper*******************************
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//*************Constantes*****************************
import { notificacionesLogin, usuarioLogin } from '../../../Helpers/constantes/State';
//*************Mutation*******************************
//****REDUX***********/
import { useDispatch } from "react-redux";
import { ObtenerSesiones } from "../../../Redux/Actions/KalendaySesionesAction";


//****HELPER**********/
//****** MATERIAL UI ******
import { makeStyles } from '@material-ui/core/styles';
import { obtenerStateStorage, guardarStateStorage } from '../../../Helpers/LocalStorage/LocalStorage';
import QrReader from 'react-qr-reader';


const useStyles = makeStyles(theme => ({
    fullsize: {
        height: "40%",
        width: "40%",
    }
}));

function IniciarSesionMovil(props) {
    //Configuracion
    const { urlLogotipo = "/images/icon.png" } = props;
    //Variables
    const [openImg, setOpenImg] = useState(false);
    const [getNotificacion, setNotificacion] = useState(notificacionesLogin);
    const [usuario, guardarUsuario] = useContext(CRMContext);
    const [getUsuario, setUsuario] = useState(usuarioLogin);
    const [photo, setPhoto] = useState("")
    const [vista, setVista] = useState("");
    const [claveEmpresaDB, setClaveEmpresaDB] = useState("");
    const [redirigir, setRedirigir] = useState(false);
    const [datosEmpresaDB, setDatosEmpresaDB] = useState({});
    const [abrirLector, setAbrirLector] = useState(false);
    const listImgKalenday = [
        {
            id: "1",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC9jMDE2MzQ0Zi0wOTYxLTRmMTctOGZkNS1kOTA1ZGYxZTIyYjQucG5n",
            descripcion: "Publicidad Kalenday"
        },
        {
            id: "2",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC9mNzYwMjJkZC0yNmU1LTRkZGQtYTIyMi1lOTk2MGZhMWM3MDQucG5n",
            descripcion: "Publicidad Kalenday"
        },
        {
            id: "3",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC8xYjc1MDBhNi03NjYzLTRjMjEtODcxNy1mNWZlOTcyODUxNjMucG5n",
            alt: "Publicidad Kalenday"
        },
        {
            id: "4",
            url: "https://35.225.97.245:4100/public/kalenday/aW1hZ2VuZXMvcHVibGljaWRhZC8zODQ5MmIwNC0yNzMyLTQ0MWUtYmY1ZS05YjRjMThmMGI0YzgucG5n",
            alt: "Publicidad Kalenday"
        }
    ]

    const { history } = props;

    //Logotipo
    const logotipo = `${urlLogotipo}`;
    const classes = useStyles();

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const sesionesKalenday = (datos) => dispatch(ObtenerSesiones(datos));


    //Eventos
    const handleScan = data => {
        if (data) {
            setClaveEmpresaDB(data);
            setDatosEmpresaDB({
            claveEmpresaDB: { error: false, valor: data }
            });
            EventAutenticarCliente();
        }
      };

    const handleError = err => {
        console.error(err)
        }

    const eventoQr = event => {
        if(event){
            setAbrirLector(!abrirLector)
        }
    }


    const EventChangeDatos = event => {
        let { value } = event.currentTarget;

        setClaveEmpresaDB(value);


    };

    const EventAutenticarCliente = async () => {
         await FetchSesiones();
    }

    const passwordIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <LockOpenIcon style={{ color: valor }} />
                </InputAdornment>
            )
        }
    };

    //Funciones
    const ActivarDesactivarBoton = (tipo) => {
        setUsuario({ ...getUsuario, desactivarBoton: { error: tipo, valor: "" } });
    }
    const ftCloseImg = () => {
        setOpenImg(false);
    }

    const generarSelect = () => {
        let row = [];
        listImgKalenday.map(function (x) {
                row.push(
                    <Fade in={true} key={x.imgPromoId}>
                        <div key={x.id} className="maximizaAlto"  onClick={() => { setOpenImg(true); setVista(`${x.url}`) }} >
                            <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${x.url}`} />
                        </div>
                    </Fade>
                );
        });
        return row;
    };


    const FetchSesiones = async () => {
        if (claveEmpresaDB !== ""){
        await FetchGrahpQL({
            query: OBTENER_SESION_EMPRESA,
            variables: { input: { clave: claveEmpresaDB } },
        })
            .then((resp) => {
                setRedirigir(true);
                setDatosEmpresaDB(resp);
                sesionesKalenday(resp.obtenerSesionEmpresa);
                guardarStateStorage(
                    {
                        sesiones: resp.obtenerSesionEmpresa,
                    },
                    "kalendaySesiones"
                );
            })
            .catch(({ message }) => {
                if (message === "Failed to fetch") {
                    setNotificacion({
                        ...getNotificacion,
                        error: 4,
                        mensaje:"Ha ocurrido algo inesperado,inténtalo  más tarde "
                    });
                }else{
                    setNotificacion({
                        ...getNotificacion,
                        error: 4,
                        mensaje:message
                    });
                }
            });
        }
    };

    //Ciclo de vida
    useEffect(() => {
        let dominio = obtenerStateStorage("kalendaySesiones");
        if (redirigir) {
            setTimeout(function () {
                history.push("/");
            },500);
        }
        if (dominio) {
            history.push("/");
        }
    }, [redirigir]);

    return (
        <>
            <CRMProvider value={[usuario, guardarUsuario]}>
                <Grid
                    style={{ width: "100%", height: "100%" }}
                    container
                >
                    <Dialog open={openImg}>
                        <DialogTitle disableTypography style={{ textAlign: "Right" }}>
                            <IconButton aria-label="close" onClick={ftCloseImg} style={{ padding: 0 }}><CloseIcon /></IconButton>
                            <img src={vista} width={'100%'} />
                        </DialogTitle>
                    </Dialog>

                    <Grid item xs={12} style={{
                        height: "40vh"
                    }}>

                        <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={true} transitionTime={1000}>
                            {generarSelect(photo)}
                        </Carousel>

                    </Grid>

                    <Grid item xs={12}>
                        <Card style={{ height: "100%" }}>
                            <CardContent>
                                <Grid item xs={12} container direction="row" justify="center">
                                    {GenerarNotificacionv2(getNotificacion, "w100")}
                                </Grid>
                                <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                {abrirLector === true ?
                                <QrReader
                                delay={300}
                                onError={handleError}
                                onScan={abrirLector === true ? handleScan : console.log("apagando lector")}
                                style={{ width: '80%' }}
                                />: <> </>}
                                    <TextField id="password" label="Clave de Empresa" name="password" margin="dense"
                                    onChange={EventChangeDatos}
                                    type="text" required fullWidth
                                    InputProps={
                                        passwordIcon(datosEmpresaDB?.claveEmpresaDB?.error),
                                        {onKeyPress: event => {
                                            const { key } = event;
                                            if (key === 'Enter') {
                                                EventAutenticarCliente();
                                              }
                                        }
                                    }}
                                    color="primary" error={getUsuario.password.error} />
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                        <BotonBoostrap Texto={"ENTRAR"} Color="primary" EventoClick={EventAutenticarCliente} Desactivar={getUsuario.desactivarBoton.error}></BotonBoostrap>

                                    </Grid>
                                   {/*  <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                        <BotonBoostrap
                                        Texto={abrirLector === false ? "LEER QR":"CERRAR LECTOR"}
                                        Color="primary"
                                        EventoClick={eventoQr}
                                        ></BotonBoostrap>
                                    </Grid> */}
                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} style={{
                        textAlign: "center", background: "white"
                    }}>
                        <Card className="cardMovil">
                            <CardContent className="cardContent" >
                                <img src={logotipo} className={classes.fullsize} alt="Logotipo Kalenday" className="imagenContentMovil" />
                                <Typography component="h1" variant="h5">
                                    {'¡Bienvenido a Kalenday!'}
                                </Typography>
                            </CardContent>
                        </Card>

                    </Grid>
                </Grid>
            </CRMProvider>
        </>
    );
}
export default withRouter(IniciarSesionMovil);
