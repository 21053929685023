import React, { useContext, useState, useEffect } from 'react';
//Core
import { Grid, TextField,FormHelperText} from '../../../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
//Rgx
import { rgxLetras} from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Anamnesis';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//Fetch
import { LISTADO_PACIENTE_FETCH } from '../../../../query/componentes/Usuario';
import { LISTADO_TIPO_PADECIMIENTO_FETCH } from "../../../../query/componentes/TipoPadecimiento";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Agregar(props) {

    //Declaraciones
    let rgxLetra = new RegExp(rgxLetras);
    let [datos, guardarDatos] = useContext(CRMContext);
    let [listadoPacientes, guardarListadoPacientes] = useState([]);
    let [listadoPadecimientos, guardarListadoPadecimientos] = useState([]);

    //API
    const obtenerPacientes = async () => {
        await FetchGrahpQL({
            query: LISTADO_PACIENTE_FETCH,
            variables: {
                offset: 0,
                moroso: null
            }
        })
            .then(resp => {
                let row = [];
                resp.listadoPaciente.pacientes.map(function (x) {
                    row.push({ nombreCompleto: x.nombreCompleto, email: x.email, usuarioId: x.usuarioId });
                });
                guardarListadoPacientes(row)
            })
            .catch(() => {
            });
    };
    const obtenerPadecimientos = async () => {
        await FetchGrahpQL({
            query: LISTADO_TIPO_PADECIMIENTO_FETCH,
            variables: {
            }
        })
            .then(resp => {
                let row = [];
                resp.listadoTipoPadecimiento.TiposPadecimientos.map(function (x) {
                    row.push({ descripcion: x.descripcion, tipoPadecimientoId: x.tipoPadecimientoId });
                });
                guardarListadoPadecimientos(row)
            })
            .catch((err) => {
            });
    };

    //EVENTOS
    const handleChangeGuardarStateUsuarioId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    pacienteId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    pacienteId: {
                        error: false, valor: values.usuarioId
                    }
                }
            });
        }

    }
    const handleChangeGuardarStatePadecimientoId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: false, valor: values.tipoPadecimientoId
                    }
                }
            });
        }

    }
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        if (value === null || value.length > 200 || !rgxLetra.test(value)) {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    //CICLO DE VIDA
    useEffect(() => {
        obtenerPacientes();
        obtenerPadecimientos();
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Sin clientes"}
                                id="combo-box-demo"
                                options={listadoPacientes}
                                name="pacienteId"
                                onChange={handleChangeGuardarStateUsuarioId}
                                getOptionLabel={(option) => { return (`${option.nombreCompleto} (${option.email})`) }}
                                renderInput={(params) => <TextField required={true} {...params} fullWidth label="Selecione al cliente" variant="outlined"
                                    error={datos.Anamnesis.pacienteId.error}
                                />}
                                style={{fontFamily: 'inherit', fontSize: 16 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Sin padecimientos"}
                                id="combo-box-demo"
                                options={listadoPadecimientos}
                                name="padecimientoId"
                                onChange={handleChangeGuardarStatePadecimientoId}
                                getOptionLabel={(option) => { return (`${option.descripcion}`) }}
                                renderInput={(params) => <TextField required={true} {...params} fullWidth label="Selecione el padecimiento" variant="outlined"
                                    error={datos.Anamnesis.padecimientoId.error}
                                />}
                                style={{fontFamily: 'inherit', fontSize: 16 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Ingresar descripción *" style={{ width: "100%", resize: "none", marginTop: "10px", fontStyle: 'normal',fontFamily: 'inherit', fontSize: 16 }} onChange={handleChangeGuardarState} name="descripcion" />
                            <FormHelperText>Tiene un límite de 200 caracteres, y solo permite valores alfabéticos</FormHelperText>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Agregar;


