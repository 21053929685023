import { urlServer, strUrlSCliente, strUrlSArchivos } from '../../../Helpers';

export const banerDefault = "/aW1hZ2VuZXMvcHJvbW9jaW9uZXMvOTgzYWFlNTktZGVjNi00ZGFiLTkyOTUtYjg2YTQzYWE3ZjY2LnBuZw==";

export const iconoAdmin = "/aWNvbm9zL2M0YmMwODYxLTE2NzgtNGZjMC04MWFlLTMxM2UxYjQ3NjliYi5wbmc="

export const iconoPaciente = "/aWNvbm9zL2Y2ZDdiMThmLWM5ZDktNGJjZi05Nzk5LWE5OTY0NTk5YjY4YS5wbmc=";

export const iconoDoctor = "/aWNvbm9zLzAxMzNjMTk0LTM5MDUtNGNiMS05YjU4LWI3Nzk3NzlhNDRlNC5wbmc=";

export const iconoUsuario = "/aWNvbm9zLzFlZGVjOTRmLTQ3YmYtNGY4Zi1iYTYyLTU3ZDM4Mzk2YjYwMy5wbmc=";

export const perfilhombre = "/aW1hZ2VuZXMvRm90b3NQZXJmaWwvMzI5NGE4N2QtOGI0My00MzQxLThhYTQtNzE3NjBjMGYwYTUyLnBuZw==";

export const perfilmujer ="";

export const perfiUsuario = "";

