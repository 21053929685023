import React from 'react';

// Componentes
import Iconos from './otros/Iconos';
import { CuadroColor } from '../../../componentes/EstatusAgenda/Colores';
import { CheckCircleRoundedIcon } from '../../Icons';
import { urlServer, strUrlSArchivos } from '../../../Helpers';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { showFile } from '../../../Helpers';
import { getImagen } from '../../../Helpers/constantes/Imagenes/getImagen'


export default function Componente(component, value) {

    let resultado;

    function consultaArchivo(ruta){

          archivoImg(ruta).then(archivo => {
        //  console.log('st', archivo);
          let tam = ('data:application/pdf;base64,').length;
          let base = archivo.substring(tam);
          showFile(base, 'Reporte.pdf');
        });

    }
    const archivoImg = async function(ruta) { //get storage
        let resultado = '';
           resultado = await getImagen(ruta);
        return resultado;
    };

    switch(component) {
        case "CuadroColor":
            resultado = <CuadroColor color={value} />;
            break;
        case "Checkbox":
            resultado = value ? <CheckCircleRoundedIcon /> :  Number(value) === 0 ? "" : value;
            break;

        case "Chip":
            resultado = <Iconos valor={value} />;
            break;
        case "JSON":
            try {
                const elJeison = JSON.parse(value);
                resultado = <>{elJeison.map(({descripcion, valor}, index) => (<Iconos key={index} valor={`${descripcion}: ${valor}`} />))}</>;
            } catch({message}) { resultado = message; }
            break;
        case "URLserver":
            resultado = <Link href={`${urlServer}${value}`} target="_blank" rel="noopener noreferrer" variant="body2">
                {value}
            </Link>;
            break;
        case "VerArchivo":
           resultado = <Button
                variant="contained"
                color="primary"
                onClick={() =>consultaArchivo(`${strUrlSArchivos}${'/private/kalenday'}${value}`)}//{`windows.location.href=${strUrlSArchivos}${value}`}
                startIcon={<FindInPageIcon />}
            >Ver archivo</Button>;
            break;
        case "Delete":
            resultado = <Button
                variant="contained"
                color="secondary"
                onClick={value}
                startIcon={<DeleteIcon />}
            >Eliminar</Button>;
            break;
        default:
            resultado = value;
            break;
    }

    return resultado;
}
