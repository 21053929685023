import gql from 'graphql-tag';

//#region HistorialPago
//=========HistorialPago=============
const LISTADO_HISTORIAL_PAGO = gql`
query listarHistorialPago ($filtro: filtroHistorialPagos!) {
  listadoHistorialPago(filtro: $filtro)
  {
    historialPagos{
      historialPagoId
      plazoCreditoId
      estatusCredito
      recordatorioPagoId
      tituloRecordatorio
      descripcionRecordatorio
      diasRecordatorio
      estatusRecordatorio
      recordarAntesDespues
      descripcionCredito
      porcentajeInteres
      porcentajeInteresAtraso
      clienteId
      clienteMoroso
      nombreCliente
      correoCliente
      telefonoCliente
      anticipo
      deudaSinIva
      IVADeuda
      totalDeuda
      fechaInicioPago
      fechaAlta
      estatusPago
      fechaPagoTotalFinalizado
      partida{
        historialPagoPartidaId
        totalPagoSinIva
        IVA
        totalPago
        totalPagoSinIva
        IVAPagar
        totalAPagar
        pagoAtrasado
        abonoTotalCapital
        totalMenosCapital
        totalAPagarMenosParcial
        pagoAtrasadoMenosParcial
        fechaPago
        fechaPagoFinalizado
        vendedorId
        nombreVendedor
        estatusPartida
        numeroPago
        ticketEncabezadoId
        fechaEmision
        productoIds
        productos
        clinicaId
        nombreClinica
      }
    }
  }
}
`;

const HISTORIAL_PAGO_CLIENTE_FETCH = `
query listarHistorialPago($filtro: filtroHistorialPagos!) {
    listadoVistaCliente(filtro: $filtro) {
      listadoVistaClientes {
        historialPagoId
        plazoCreditoId
        clienteId
        nombreCliente
        fechaInicioPago
        fechaAlta
        totalPago
        totalAPagar
        fechaPago
        descripcionCredito
        estatusPago
        pagoAtrasado
        fechaPagoFinalizado
      }
    }
  }`;

const HISTORIAL_PAGO_FETCH = `
query listarHistorialPago ($filtro: filtroHistorialPagos!) {
  listadoHistorialPago(filtro: $filtro)
  {
    historialPagos{
      historialPagoId
      plazoCreditoId
      estatusCredito
      recordatorioPagoId
      tituloRecordatorio
      descripcionRecordatorio
      diasRecordatorio
      estatusRecordatorio
      recordarAntesDespues
      descripcionCredito
      porcentajeInteres
      porcentajeInteresAtraso
      clienteId
      clienteMoroso
      nombreCliente
      correoCliente
      telefonoCliente
      anticipo
      deudaSinIva
      IVADeuda
      totalDeuda
      fechaInicioPago
      fechaAlta
      estatusPago
      fechaPagoTotalFinalizado
      partida{
        historialPagoPartidaId
        totalPagoSinIva
        IVA
        totalPago
        totalPagoSinIva
        IVAPagar
        totalAPagar
        pagoAtrasado
        abonoTotalCapital
        totalMenosCapital
        totalAPagarMenosParcial
        pagoAtrasadoMenosParcial
        fechaPago
        fechaPagoFinalizado
        vendedorId
        nombreVendedor
        estatusPartida
        numeroPago
        ticketEncabezadoId
        fechaEmision
        productoIds
        productos
        clinicaId
        nombreClinica
      }
    }
  }
}`;

//#endregion
//=====================================================

export {
    LISTADO_HISTORIAL_PAGO,
    HISTORIAL_PAGO_FETCH,
    HISTORIAL_PAGO_CLIENTE_FETCH,
}
