import {NOTIFICACION_SONIDO}from '../Types';

const initialState={
    notificacion:[{activo:true}]
}

export default function(state=initialState,action){
    switch(action.type){
        case NOTIFICACION_SONIDO:
            return {
                ...state,
                notificacion:[action.payload]
            }
        default:
            return state;
    }
}
