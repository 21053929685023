import {INICIAR_SESION}from '../Types';

const initialState={
    usuario:[{user:{user:{success:false,token:""}}}]
}

export default function(state=initialState,action){
    switch(action.type){
        case INICIAR_SESION:
            return {
                ...state,
                usuario:[action.payload]
            }
        default:
            return state;
    }
}
