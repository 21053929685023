import React, { useState, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Core
import { Grid, TextField, InputLabel, FormControl, Input, FormHelperText, IconButton, InputAdornment } from '../../../../componentesGenericos/Core';
//Rgx
import { rgxEmail, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Registro';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//iconos
import { VisibilityIcon, VisibilityOffIcon } from '../../../../componentesGenericos/Icons';
//FETCH
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Desk() {

    const classes = useStyles();

    let rgxLetra = new RegExp(rgxLetras);
    let rgxEmails = new RegExp(rgxEmail);
    let rgxPasswords = new RegExp(rgxPassword);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value) && value.length>1) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }


    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //API
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }


    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
    }, []);


    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Paciente.nombres.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Paciente.apellidos.error} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={datos.Paciente.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Paciente.password.error}
                                    type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                                    value={datos.Paciente.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula, minúscula, número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"}, tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            } Titulo="Datos generales "></Fieldset>
        </>
    )

}

export default Desk;
