import React, { useState } from 'react'

//React Router
import { useParams } from 'react-router-dom';

//Apollo
import { useQuery } from '@apollo/react-hooks';

//Queries
import { LISTADO_TICKETS, OBTENER_TICKET, FILTRO_TELEFONOS, LISTAR_FORMA_COBRO } from '../../../query';

//estilos
import './estilos.css';

//Formatos
import { formatoFolio, formatoFecha, formatoMoneda } from '../../../Helpers/constantes/formatos';

function TicketCredito(props) {
    document.title = "Ticket de Venta";

    const [imprimirPdf, setImprimirPdf] = useState(null);
    const [tv, setTV] = useState({
        empresa: "Clinica dental Perfiles",
        clinica: "",
        serie: "TVG",
        folio: "0000000000",
        calle: "Sin direccion",
        colonia: "",
        municipio: "",
        estado: "",
        cp: "",
        vendedor: "Clinica Perfiles",
        vendedorId: "",
        telefono: null,
        servicios: [],
        total: "0.00",
        formasCobro: [],
        formasCobroCantidad: []
    });

    const [imgLoaded, setImgLoaded] = useState(false);

    let { id } = useParams();
    const serieFolio = JSON.parse(localStorage.getItem('serieTk'));

    //#region queries
    const { loading, data, error, refetch } = useQuery(OBTENER_TICKET, {
        variables: { id: serieFolio ? null : Number(id), serie: serieFolio ? serieFolio.serieId+'' : null, folio: serieFolio ? serieFolio.folio : null  },
        onError: (e) => console.log(e.message),
        onCompleted: () => {
            let ff = { id: [], texto: [], pago: [] };

            if (data) {
                ff.id = (data?.obtenerTicket.formasCobro + "").split(',');
                ff.pago = (data?.obtenerTicket.formasCobroCantidad + "").split(',');
                ff.texto = (data?.obtenerTicket.formasCobroTexto + "").split(',');
            }

            setTV({ ...tv, ...data?.obtenerTicket, formasCobro: ff.texto, formasCobroCantidad: ff.pago });
            localStorage.removeItem('serieTk')
        }
    });

    const { loading: loadingServicios, data: servicios, error: errorServicios, refetch: refetchServicios } = useQuery(LISTADO_TICKETS, {
        variables: {
            clinicaId: null,
            doctorId: null,
            convenioId: null,
            vendedorId: null,
            clienteId: null,
            serieId: data ? data.obtenerTicket.serieId + '' : null,
            folioId: data ? data.obtenerTicket.folio + '' : null,
            fecha: data ? data.obtenerTicket.fechaEmision : null
        },
        onError: (e) => console.log(e.message),
        onCompleted: () => {

            let ff = { id: [], texto: [], pago: [] };

            if (data) {
                ff.id = (data?.obtenerTicket.formasCobro + "").split(',');
                ff.pago = (data?.obtenerTicket.formasCobroCantidad + "").split(',');
                ff.texto = (data?.obtenerTicket.formasCobroTexto + "").split(',');
            }

            setTV({ ...tv, ...data?.obtenerTicket, formasCobro: ff.texto, formasCobroCantidad: ff.pago });
        }
    });

    const { loading: loadingTel, data: telefonos, error: errorTel } = useQuery(FILTRO_TELEFONOS, {
        variables: {
            limite: 0, pagina: 0, direccionId: data ? data.obtenerTicket.direccionId : null
        },
        onError: (e) => console.log("Error al cargar los teléfonos: ", e.message),
        onCompleted: () => {
            let tels = "";

            telefonos.listadoTelefonos.telefonos.map(tel => {
                tels += tel.telefono + ", ";
            });

            tels = tels.substring(0, tels.length > 1 ? tels.length - 2 : 1);

            setTV({ ...tv, telefono: tels });
        }
    });
    //#endregion

    React.useEffect(() => {
        if (data !== null && data !== undefined && ((!loadingTel && tv.telefono) || errorTel) && !loading && !loadingServicios && imgLoaded) {

            window.print();
            window.close();
        }
    }, [tv, imgLoaded])

    const fecha = new Date().toISOString().replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d+Z/, "$3/$2/$1");
    const hora = new Date().toLocaleTimeString();

    let garantias = [];

    const options = {
        orientation: 'p',
        unit: 'mm',
        format: [200, 1590],
        putOnlyUsedFonts: false,
        floatPrecision: 16
    }

    return (
        <div className="contenedor" >
            <div className="cargando">
                <h2 className="generando">Generando Ticket</h2>
                <img src="/iconos/engrane.png" className="carga" alt="Cargando" onLoad={() => { setImgLoaded(true) }} />
            </div>

            <div className="ticket" style={{ width: '266px' }}>
                <div className="logo">
                    <img src="/iconos/LogoClinicaDental.png" alt="Logotipo" />
                </div>

                <p className="centrado">
                    <span className="titulo">Clinica Dental Perfiles</span><br />
                    {tv.rfc}
                </p>

                <p className="centrado titulo">{tv.clinica}</p>

                <p className="centrado">TICKET DE VENTA</p>

                {
                    tv.direccionId &&
                    <p className="centrado">
                        Expedido en:<br />
                        {tv.calle}, {tv.colonia}, <br /> {tv.municipio}, {tv.estado}
                        {tv.codigoPostal !== "" ? `, CP. ${tv.codigoPostal}` : tv.codigoPostal} <br />
                        {tv.telefono && `Tels: ${tv.telefono}`}
                    </p>
                }

                <p className="centrado">
                    {tv.doctorId == -1 ? '' : `Profesional: ${tv.doctor}`}
                </p>

                <p className="centrado contenedor">
                    Fecha: {formatoFecha(tv.fechaEmision)}
                    <br />Folio: {tv.serie}-{formatoFolio(tv.folio, 6)}
                    {tv.numeroPago &&
                        <>
                            <br />
                                Pago {tv.numeroPago} de {tv.numeroTotalPagos}
                        </>
                    }
                </p>

                <table className="tablaproductos">
                    <thead>
                        <tr>
                            <th className="cantidad">CANT.</th>
                            <th className="codigo">COD.</th>
                            <th className="producto">DESCRIPCIÓN</th>
                            <th className="precio">P. U.</th>
                            {/* <th className="descuento">DESC.</th> */}
                            {/* <th>% DESC.</th>
                        <th>% IVA</th> */}
                            <th className="totalimporte">IMPORTE</th>
                            <th className="espacio"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            servicios ? servicios.listadoTickets.tickets.length > 0 ?
                                servicios.listadoTickets.tickets.map((servicio, index) => {

                                    return (
                                        <tr key={index}>
                                            <td className="cantidad">{servicio.cantidad || 1}</td>
                                            <td className="codigo">{servicio.codProd}</td>
                                            <td className="producto">{servicio.descripcion}</td>
                                            <td className="precio">{formatoMoneda(Number(servicio.precioUnitarioTotal) + Number(servicio.descuentoUnitarioTotal))}</td>
                                            {/* <td className="descuento">{formatoMoneda(servicio.descuentoUnitarioTotal)}</td> */}
                                            {/* <td className="pordesc">{servicio.porcDescuento}%</td>
                                        <td className="iva">{servicio.porcIva}%</td> */}
                                            <td className="totalimporte">{formatoMoneda(servicio.precioTotal)}</td>
                                            <td className="espacio"></td>
                                        </tr>
                                    )
                                })
                                :
                                <tr><td colSpan={6} className="producto">No se registró ninguna venta</td></tr>
                                : <tr><td colSpan={6} className="producto">No se registró ninguna venta</td></tr>
                        }
                        <tr>
                            <td colSpan={6}><hr /></td>
                        </tr>
                    </tbody>
                </table>



                <div className="totales">
                    <table className="tablaTotal">
                        <tbody>
                            <tr>
                                <td colSpan={4} className="izquierda">
                                    TOTAL:
                                </td>
                                <td colSpan={1} className="totaltxt">
                                    {formatoMoneda(tv.totalTicket)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <hr style={{ width: '100%' }} />

                <div className="ahorro">Su pago se realizó en:</div>
                <div className="totales">
                    <table className="tablaTotal">
                        <tbody>
                            {
                                tv.formasCobro && tv.formasCobro.map((forma, i) => {
                                    return <tr key={i}>
                                        <td colSpan={4} className="izquierda">
                                            {forma !== "" ? forma + ":" : ""}
                                        </td>
                                        <td colSpan={1} className="totaltxt">
                                            {formatoMoneda(tv.formasCobroCantidad[i] || 0)}
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>

                <hr style={{ width: '100%' }} />
                {
                    Number(tv.cambio ? tv.cambio.toFixed(2) : 0) > 0 &&
                    <div className="totales">
                        <table className="tablaTotal">
                            <tbody>
                                <tr>
                                    <td colSpan={4} className="izquierda">
                                        CAMBIO:
                                    </td>
                                    <td colSpan={1} className="derecha">
                                        {formatoMoneda(tv.cambio)}
                                    </td>
                                </tr>

                                {tv.metodoPago ?
                                    <tr>
                                        <td colSpan={4} className="izquierda">
                                            METODO DE PAGO:
                                        </td>
                                        <td colSpan={1} className="derecha">
                                            {tv.metodoPago}
                                        </td>
                                    </tr>
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    </div>
                }

                {
                    tv.descuentoTotalTicket >= 1 &&
                    <>
                        <br />
                        <div className="ahorro">USTED AHORRA: {formatoMoneda(tv.descuentoTotalTicket)}</div>
                    </>
                }

                <br />
                <p className="centrado">¡GRACIAS POR SU COMPRA!</p>

                <p className="centrado">
                    Le atendió {tv.vendedor}
                </p>
                <br />

                <div>
                    <p className="centrado">Conserve su ticket para hacer válida la garantía</p>
                </div>
                {
                    garantias.length > 0 &&
                    <div>
                        <p className="centrado">Garantías:</p>
                        <table className="garantias">
                            {garantias}
                        </table>

                    </div>
                }
                <br />
            </div>
        </div>

    )
}

export default TicketCredito
