import React, { useEffect } from 'react';

//Apollo
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';

//Redux
import store from "./Redux/Store";
import { Provider } from "react-redux";

//React Router
import { BrowserRouter as Router } from 'react-router-dom'

//Estilos
import './App.css';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Theme } from './Helpers/constantes/Css/Theme';
import Rutas from './Rutas';

//Helpers
import { strUrlServer } from './Helpers/constantes/Config/UrlServer';
import { obtenerStateStorage } from './Helpers/LocalStorage/LocalStorage';
import socketIOClient from "socket.io-client";

//Sockets
import Sockets from './sockets';

const client = new ApolloClient({
    uri: `${strUrlServer}/graphql`,
    fetchOptions: { credentials: 'include' },
    request: operation => {
        const { user } = obtenerStateStorage('user');
        const token = user.usuario[0].user.user.token || null;
        operation.setContext({ headers: { token } });
    },
    onError: (({ graphQLErrors, ...er }) => {
        if (graphQLErrors) {
            if (graphQLErrors.length > 0)
                if (graphQLErrors[0].message !== "No se encontraron registros")
                    console.log('graphQLErrors', graphQLErrors);
        } else {
            console.log('Errores', er);
        }
    })
});

export const socket = socketIOClient(strUrlServer);

function App() {

    return (
        <ApolloProvider client={client}>
            <Router>
                <Provider store={store}>
                    <MuiThemeProvider theme={Theme}>
                        <Sockets>
                            <Rutas />
                        </Sockets>
                    </MuiThemeProvider>
                </Provider>
            </Router>
        </ApolloProvider>
    );
}

export default App;
