import React, { useState } from 'react';
import {obtenerStateStorage} from '../../Helpers/LocalStorage/LocalStorage';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [perfil, guardarPerfiles] = useState({
        General:{
            notificaciones:{ error: false, valor: "" },
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            emailActual: { error: false, valor: "" },
            password: { error: false, valor: "" },
            sexo: { error: false, valor: "" },
            especialidadId: { error: false, valor: "" },
            cedula: { error: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            telefono:{error:false,valor:""},
            passwordAntigua: { error: false, valor: "" },
            fechaNacimiento:{error:false,valor:""},
            showPassword:{error:false,valor:""},
            token: { error: false, valor: obtenerStateStorage("user").user.usuario[0].user.user.token||"" },
        }

    });
    return (
        <CRMContext.Provider value={[perfil, guardarPerfiles]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
