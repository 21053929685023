import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [usuario, guardarUsuario] = useState({
        Paciente: {
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            password: { error: false, valor: "" },
            genero: { error: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            telefono:{error:false,valor:""},
            fechaNacimiento:{error:false,valor:""},
            showPassword:{error:false,valor:""},
            recuperarContraseña:{error:true,valor:""}
        }
    });
    return (
        <CRMContext.Provider value={[usuario, guardarUsuario]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
