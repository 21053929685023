import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [datos, guardarDatos] = useState({
        datos: {
            recordatorioPagoId:{error:false,valor:""},
            plazoCreditoId:{error:false,valor:""},
            planCreditoID:{error:false,valor:""},
            descripcionPlazo:{error:false,valor:""},
            tituloRecordatorio:{error:false,valor:""},
            descripcionRecordatorio:{error:false,valor:""},
            dias:{error:false,valor:""},
            estatus:{error:false,valor:""},
            recordarAntesDespues:{error:false,valor:""},
            desactivarBoton:{error:false,valor:""},
        },
        AbrirModalModificacion:{
            Open:false
        },
        desactivarBoton: { error: false
        }

    });
    return (
        <CRMContext.Provider value={[datos, guardarDatos]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
