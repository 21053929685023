export const mensajeInicial = {
    abrir: false,
    tipoModal: "dialogo",
    titulo: null,
    icono: null,
    tipo: "mensaje",
    color: null,
    texto: "",
    subParrafo: null,
    onAccept: null,
    onCancel: null,
    newComponente: null
};

export const mensajeError = {
    abrir: true,
    tipoModal: "dialogo",
    titulo: "¡Aviso!",
    icono: null,
    tipo: "mensaje",
    onAccept: null,
};

export const mensajeExito = {
    abrir: true,
    tipoModal: "dialogo",
    titulo: "¡Éxito!",
    icono: null,
    tipo: "mensaje",
    onAccept: null,
};

export const mensajeConfirmacion = {
    abrir: true,
    tipoModal: "dialogo",
    titulo: "¡Advertencia!",
    icono: null,
    tipo: "confirmacion"
}


export const mensajeAdvertencia = {
    abrir: true,
    tipoModal: "dialogo",
    titulo: "¡Advertencia!",
    icono: null,
    tipo: "mensaje",
    onAccept: null,
};
