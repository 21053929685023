import React, { useContext, useState, useEffect } from 'react';
//Core
import { Grid, TextField, FormHelperText } from '../../../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
//Rgx
import { rgxLetras } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Anamnesis';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//Fetch
import { LISTADO_TIPO_PADECIMIENTO_FETCH } from "../../../../query/componentes/TipoPadecimiento";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";


//Estilos
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Eliminar(props) {

    //Declaraciones
    let rgxLetra = new RegExp(rgxLetras);
    let [datos, guardarDatos] = useContext(CRMContext);
    let [listadoPadecimientos, guardarListadoPadecimientos] = useState([]);
    let { seleccion } = props;
    //API
    const obtenerPadecimientos = async () => {
        await FetchGrahpQL({
            query: LISTADO_TIPO_PADECIMIENTO_FETCH,
            variables: {
            }
        })
            .then(resp => {
                let row = [];
                let idsArr = seleccion.padecimientoId.split(",");
                resp.listadoTipoPadecimiento.TiposPadecimientos.map(function (x) {

                    if (idsArr.includes(x.tipoPadecimientoId)) {
                        row.push({ descripcion: x.descripcion, tipoPadecimientoId: x.tipoPadecimientoId });
                    }

                });

                guardarListadoPadecimientos(row)
            })
            .catch((err) => {
            });
    };

    //EVENTOS
    const handleChangeGuardarStatePadecimientoId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: false, valor: values.tipoPadecimientoId
                    }
                }
            });
        }

    }
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        if (value === null || value.length > 200 || !rgxLetra.test(value) || value==="") {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }


    //Ciclo de vida
    useEffect(() => {
        guardarDatos({
            ...datos,
            Anamnesis: {
                ...datos.Anamnesis,
                descripcionId:{
                    error: false, valor: seleccion.descripcionId
                },
                pacienteId: {
                    error: false, valor: seleccion.pacienteId
                },
                anamnesisId: {
                    error: false, valor: seleccion.anamnesisId
                },
                nombreCompleto: {
                    error: false, valor: seleccion.nombreCompleto
                },
                genero: {
                    error: false, valor: seleccion.genero
                },
                padecimientos: {
                    error: false, valor: seleccion.padecimientos
                },
                email: {
                    error: false, valor: seleccion.email
                },
                telefono: {
                    error: false, valor: seleccion.telefono
                },
                fechaNacimiento: {
                    error: false, valor: seleccion.fechaNacimiento
                },
                fechaAlta: {
                    error: false, valor: seleccion.fechaAlta
                },
                desactivarBoton: {
                    error: false, valor: seleccion.desactivarBoton
                },
                descripcion: {
                    error: false, valor: seleccion.descripcion
                },
                padecimientoId: {
                    error: false, valor: seleccion.padecimientoId
                }

            }
        });
        obtenerPadecimientos();
    }, []);

    useEffect(() => {
    let tmp,tmp2;
    if(seleccion.descripcionId!=null && seleccion.descripcionId!='' ){
    let array=seleccion.descripcionId.split(",");
    for (let index = 0; index < array.length; index++) {
        //descripcion
        tmp=array[index].indexOf("-")+1;
        tmp2=array[index].substring(0,tmp-1);
        tmp=array[index].substring(tmp);

        if(tmp2===datos.Anamnesis.padecimientoId.valor){
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    descripcion:{
                        error: false, valor: tmp
                    },
                }
            });
        }
    }
    }},[datos.Anamnesis.padecimientoId.valor]);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombreCompleto" id={`nombreCompleto`} label="Nombre completo" type="text" style={{ width: "100%" }} required={true} disabled={true} defaultValue={seleccion.nombreCompleto} />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Sin padecimientos"}
                                id="combo-box-demo"
                                options={listadoPadecimientos}
                                name="padecimientoId"
                                onChange={handleChangeGuardarStatePadecimientoId}
                                getOptionLabel={(option) => { return (`${option.descripcion}`) }}
                                renderInput={(params) => <TextField required={true} {...params} fullWidth label="Selecione el padecimiento" variant="outlined"
                                    error={datos.Anamnesis.padecimientoId.error}
                                />}
                            />
                            <FormHelperText>Padecimientos actuales del cliente</FormHelperText>
                        </Grid>

                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Eliminar;

