import gql from 'graphql-tag';

export const LISTADO_PLANES_FETCH = `
    query listadoPlanes {
        listadoPlanes {
            planID
            descripcionPlan
            diasPlan
            subDescripcion
        }
    }
`;

export const LISTADO_PLANES_CREDITO = gql`
    query listadoPlanesCredito {
        listadoPlanesCredito {
            planCreditoID
            planID
            descripcionPlan
            diasPlan
            subDescripcion
            plazoCredito
            porcentajeInteresDecimal
            porcentajeInteres
            porcentajeAtrasoDecimal
            porcentajeAtraso
            descripcionCredito
            elegirDiaPago
            estatusCredito
            fechaAlta
            montoMinimo
            montoMaximo
            aplicaTodosClientes
            clientesCredito
        }
    }
`;

export const LISTADO_PLANES_CREDITO_FETCH = `
    query listadoPlanesCredito {
        listadoPlanesCredito {
            planCreditoID
            planID
            descripcionPlan
            diasPlan
            subDescripcion
            plazoCredito
            porcentajeInteresDecimal
            porcentajeInteres
            porcentajeAtrasoDecimal
            porcentajeAtraso
            descripcionCredito
            elegirDiaPago
            estatusCredito
            fechaAlta
            montoMinimo
            montoMaximo
            aplicaTodosClientes
            clientesCredito
        }
    }
`;

export const CREDITO_APLICABLE_CLIENTE_FETCH = `
    query creditoAplicablePorCliente($clienteID: Int!, $monto: Float!) {
        creditoAplicablePorCliente(clienteID: $clienteID, monto: $monto) {
            planCreditoID
            descripcionPlan
            diasPlan
            plazoCredito
            porcentajeInteresDecimal
            porcentajeInteres
            porcentajeAtrasoDecimal
            porcentajeAtraso
            descripcionCredito
            elegirDiaPago
            montoMinimo
            montoMaximo
            clienteID
            nombreCompleto
            email
            fechaNacimiento
            telefono
            descripcionGenero
        }
    }
`;
