import { AGREGAR_USUARIO } from '../Types';

const initialState = {
    usuariosCrud: [{ activo: false }]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case AGREGAR_USUARIO:
            return {
                ...state,
                usuariosCrud: [action.payload]
            }
        default:
            return state;
    }
}
