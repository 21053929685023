import React, { useContext } from 'react';
import { CRMContext, CRMProvider } from '../../context/Agenda';
import Agenda from './Agenda';

// Redux
import { useSelector } from "react-redux";
import { DecodificarToken } from "../../componentesGenericos/Estructura/Autenticar";

function AgendaContext() {

    const user = useSelector((state) => state.user);
    const configuracion = useSelector(state => state.configuracion);
    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);

    const [evento, setEvento] = useContext(CRMContext);

    if(!userToken.tipo) {
        window.location = "";
        return null;
    };

    return (
        <CRMProvider value={[evento, setEvento]}>
            <Agenda
                usuario={userToken}
                token={token}
                configuracion={configuracion}
                _clinicaID={_clinicaID}
            />
        </CRMProvider>
    );
}


export default AgendaContext;
