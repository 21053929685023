import React, { useState, useEffect, useRef } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_PRODUCTOS_SERVICIOS, FILTRO_RECETAS_PARTIDAS } from '../../query';
// Mutations
import { NUEVA_RECETA } from '../../mutation';
//Componentes
import Tabla from '../../componentesGenericos/Tabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputGrid, Typography, CircularProgress, Button, TextField } from '../../componentesGenericos/Core';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
import Padecimientos from '../../componentes/Anamnesis/Padecimientos';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { urlServer, showFile} from '../../Helpers';
import { rgxNoVacio} from '../../Helpers/constantes/Rgx';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%'
    },
    fecha: {
        border: 'red 5px solid'
    },
    fondo: {
        backgroundColor: 'white'
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%',
    },
    inline: {
        color: '#323B88 '
    },
    listitem: {
        margin: '0px',
        padding: '0px'
    },
    textoitem: {
        color: '#646A9E',
        margin: '0px',
        padding: '0px',
        fontWeight: 'bolder',
        fontSize: '10px'
    }
}));

export default function Receta(props) {

    let { agendaId, clinicaDoctoId, pacienteId, clinicaID } = useParams();
    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);

    const dt = new Date();
    let anio = dt.getFullYear();
    let mes = dt.getMonth();
    let dia = dt.getDate();
    // mes = mes < 10 ? `0${mes}` : mes;
    mes = mes.toString().length === 1 ? `0${Number(mes) + 1}` : Number(mes) + 1;
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    const fecha = `${dia}-${mes}-${anio}`;

    const classes = useStyles();
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [datos, setDatos] = useState([]);
    const [modal, setModal] = useState({
        abrir: false,
        handleClose: ftModalClose,
        recetaId: null
    })
    const seleccionInicial = {
        recetaId: null,
        productoId: null,
        descripcion: "",
        productoNoenVenta: "",
        cantidad: 1,
        anotacionesProducto: "",
        resultible: 1,
        partidaId: null
    };
    const seleccionEncabezado = {
        descripcionReceta: ""
    }
    const errorInputs = {
        errorProducto: false,
        errorAnotaciones: false,
        errorDescripcion: false
    }
    const [errorr, setError] = useState(errorInputs);
    const [encabezado, setEncabezado] = useState(seleccionEncabezado)
    const [recetaInsertada, setRecetaInsertada] = useState(-1);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [load, setLoad] = useState(false);
    const [refs, setRef] = useState({
        cantidad: useRef(),
        add: useRef(),
        selected: null
    });

    const {  data } = useQuery(FILTRO_RECETAS_PARTIDAS, {
        fetchPolicy: 'no-cache',
        variables: { limite: 10000, pagina: 1, recetaId: recetaInsertada, productoId: null },
        onError: (e) => {

        },
        onCompleted: () => {
        }

    });


    let productos = [{ descripcion: 'No hay productos en existencia', servicioId: -1 }];

    const { data: dproductos } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        fetchPolicy: 'no-cache',
        variables: { offset: 0, tipo: 2, disponible: 1, clinica: Number(clinicaID) },
        onCompleted: () => {
        }
    });

    //TODO productchange
    function ProductoChange(e, val) {
        setSeleccion({
            ...seleccion, productoId: val !== null ? Number(val.servicioId) : -1, productoNoenVenta: "",
            descripcion: val !== null ? val.descripcion : ""
        })
    }
    if (seleccion.productoId !== null) {
        seleccion.productoNoenVenta = ""
    }
    const onCantidadChange = (e) => {
        setSeleccion({ ...seleccion, cantidad: Number(e.target.value) })
    }
    const handleChange = (e) => {
        setSeleccion({ ...seleccion, resultible: (e.target.value) });
    };

    const handleChangeProducto = ({ target: { value } }) => {
        let val = value;
        setSeleccion({
            ...seleccion, productoNoenVenta: (val),
            productoId: null
        });
        val = typeof val === "string" ? val.trim() : val;

        if (val.length < 5 && rgxNoVacio.test(val)) {
            setError({ ...errorr, errorProducto: true })
        } else {
            setError({ ...errorr, errorProducto: false })
        }
    }

    const handleAnotacionesChange = ({ target: { value } }) => {
        let val = value;
        setSeleccion({ ...seleccion, anotacionesProducto: (val) })
        val = typeof val === "string" ? val.trim() : val;
        if (val.length < 10 && rgxNoVacio.test(val)) {
            setError({ ...errorr, errorAnotaciones: true })
        } else {
            setError({ ...errorr, errorAnotaciones: false })
        }
    }


    const handleChangeDescripcionReceta = ({ target: { value } }) => {
        let val = value;
        setEncabezado({ ...encabezado, descripcionReceta: (val) })
        val = typeof val === "string" ? val.trim() : val;
        if (val.length < 10 && rgxNoVacio.test(val)) {
            setError({ ...errorr, errorDescripcion: true })
        } else {
            setError({ ...errorr, errorDescripcion: false })
        }
    }

    function getDatos() {
        if (dproductos) {
            let { listadoProductosServicios: { productosServicios = [] } = {} } = dproductos;
            productosServicios.map(({ servicioId, descripcion }, index) => {
                if (!Number(index)) productos = [];
                productos = [...productos, {
                    servicioId,
                    descripcion
                }]
            });
        }
    } getDatos();

    const handleAgregarClick = (e, enviado) => {
        let retorno = [];
        let sel = enviado ? enviado : seleccion;
        if ((sel.productoId !== null) || inputValue) {
            sel = sel.productoId === null ? { ...sel, productoNoenVenta: inputValue, productoId: null, descripcion: "" } : sel;

            if (sel.anotacionesProducto.length >= 10) {
                let index = -1;
                //Busca si ya se encuentra en el listado de productos agregados

                datos.map((item, indice) => {
                    if (item.servicioId) {
                        index = indice;
                        return;
                    }
                });

                ftAgregar(index, sel);
            } else {
                setMensaje({ ...mensaje, ...mensajeAdvertencia, abrir: true, texto: "Coloque las indicaciones del producto", onAccept: () => setMensaje({ ...mensaje, abrir: false }) })
            }
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, abrir: true, texto: "Selecciona un producto", onAccept: () => setMensaje({ ...mensaje, abrir: false }) })
        }
        return retorno;
    }

    /**comprueba si se selecciono una partida a eliminar **/
    const handleEliminarClick = (id) => {
        if (id) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: "¿Está seguro de eliminar el producto?", abrir: true, onAccept: () => { ftEliminar(id) } });
        } else {
            setMensaje({ ...mensaje, abrir: true, texto: "selecciona un producto" })
        }
    };

    const productoProps = {
        options: productos,
        getOptionLabel: option => `${option.descripcion}${option.codigoProducto && option.codigoBarras ? '(' : ''} ${option.codigoProducto ? option.codigoProducto : ''}${option.codigoProducto && option.codigoBarras ? ' | ' : ''}${option.codigoBarras ? option.codigoBarras : ''}${option.codigoProducto && option.codigoBarras ? ')' : ''} `,
    };


    const [nuevaReceta] = useMutation(NUEVA_RECETA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {

            setLoad(true)

        }
    });

    const seleccionTexto = (evt, id) => {
        if (evt) if (evt.target) if (evt.target.select) if (typeof evt.target.select === 'function') evt.target.select();
    }


    function ftAgregar(index, enviado) {
        let dtmp = datos;
        let i = index;
        let sel = enviado ? enviado : seleccion;
        if (index === -1) {
            i = datos.length;
            dtmp = [...datos, {
                ...sel
                , no: datos.length + 1,
                idDelete: datos.length + 1
            }];
        } else {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: `Ya se encuentra agregado el producto seleccionado` });

        }

        setDatos([...dtmp]);
        setSeleccion(seleccionInicial);
        return dtmp;
    }

    const columnas = [
        { id: 'productoId', label: 'servicioId', minWidth: 0, hide: true },
        { id: 'no', label: 'No.', align: 'center', hide: true },
        { id: 'descripcion', label: 'Descripción', align: 'center', textAlign: 'center', minWidth: 0, obtenerColumna: 'productoNoenVenta', format: (descripcion, productoNoenVenta) => { return (descripcion ? descripcion : '') + productoNoenVenta } },
        {
            id: 'cantidad', label: 'Cantidad', style: { margin: "1px", maxWidth: '200px' }, align: 'center', textAlign: 'center', minWidth: 0,

        },
        { id: 'anotacionesProducto', label: 'Indicaciones', align: 'center', textAlign: 'center', minWidth: 0 },
        {
            id: 'resultible', label: 'Resurtible', align: 'center', textAlign: 'center', minWidth: 0, format: (valor) => {
                return valor ? valor.toString().replace("0", "No").replace("1", "Si") : "-";
            }
        },
        {
            id: 'idDelete', label: '', align: 'center', minWidth: 0,
            format: (val) => {
                return (
                    <IconButton aria-label="delete" onClick={() => handleEliminarClick(val)}>
                        <DeleteIcon color="secondary" />
                    </IconButton>
                )
            }
        },
    ];

    /**elimina la partida seleccionada**/
    function ftEliminar(id) {
        //TODO eliminar
        let cont = 1;

        let partidas = datos.filter((part, i) => {
            if (Number(part.idDelete) === Number(id)) {
                setSeleccion({ ...seleccion, no: seleccion.no - 1, idDelete: seleccion.idDelete - 1 });
                return false;
            }
            part.no = cont++;
            return true;
        });
        setDatos(partidas);
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftGenerarReceta({ cleanStateFormulario, descripcionReceta }) {
        async function ftGenerarReceta() {
            try {

                setDisabledAccept(true);
                if (datos.length !== 0) {
                    if (encabezado.descripcionReceta.length >= 10) {
                        await nuevaReceta({
                            variables: {
                                input: {
                                    recetaId: null,
                                    agendaId: Number(agendaId),
                                    clinicaDoctoId: Number(clinicaDoctoId),
                                    pacienteId: Number(pacienteId),
                                    descripcionReceta: encabezado.descripcionReceta,
                                    partidas: datos
                                }
                            }
                        })
                            .then(

                                (input) => {
                                    if (input)
                                        if (input.data) {
                                            let recetaInsert = input.data.nuevaReceta.partidas[0].recetaId;
                                            setRecetaInsertada(recetaInsert);
                                        }
                                }

                            ).catch(e => {
                                setMensaje({ ...mensajeAdvertencia, texto: e.message, abrir: true })
                            });
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "Escriba una descripción de la receta" })
                    }
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "No hay productos en la receta" })
                }
                setDisabledAccept(false);

            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftGenerarReceta();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function imprimirReceta() {

        //var details = JSON.stringify({age: 12});

        async function imprimirReceta() {
            // refetch();
            var myHeaders = new Headers();
            myHeaders.append("token", token);
            myHeaders.append("mimeType", "multipart/form-data");
            var formdata = new FormData();
            formdata.append("data", JSON.stringify(data));
            // formdata.append("orientacion", "landscape");
            formdata.append("nombreReporte", "recetaCliente");
            formdata.append("rutaCodigoQr", `${urlServer}/images/public/png/logotipo-642d5d06-fbf4-47d8-b328-027e3388933e`);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata
            };
           // let data2 = JSON.stringify(data);

            fetch(`${urlServer}/service/reportes/receta`, requestOptions)
                .then(response => {
                    if (!response.ok) return response.json();
                    // if(!file) return res.json();
                    //return response.blob()
                    return response.json();
                })
                .then(result => {
                    let { error, mensaje } = result;
                   // if (error) throw new Error(mensaje);
                    showFile(result.archivo, `Receta.pdf`);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Se generó correctamente la receta` });
                    setLoad(false);
                    setSeleccion(seleccionInicial);
                    setEncabezado(seleccionEncabezado);
                    setDatos([])
                    window.location.href = "/Menu"
                })
                .catch(error => console.log('error', error));// imprimirReceta()
            // borrar si hay error recetaInsertada
        } imprimirReceta();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftModalClose() {
        setModal(modal => ({ ...modal, abrir: false }));
    }

    useEffect(() => {
        if (data) {
            imprimirReceta();
         }
    }, [data]);


    return (
        <MenuLateral titulo="Receta">

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

            {/* encabezado del componente */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Encabezado titulo="Receta" />
                </Grid>
            </Grid>
            <Grid className={classes.fondo} >
                {
                    load === true ?
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <CircularProgress />
                        </div>
                        :
                        <div></div>
                }
                <Grid container xs={12} direction="row" className={classes.fondo} align="center">
                    <Grid container justify="center" item xs={12} md={6} style={{ marginTop: '15px', marginBottom: '10px' }}>
                        <Typography
                            component="span"
                            // variant="body2"
                            className={classes.inline}
                            // color={"textPrimary"}
                            variant="h6"
                        >{`${userToken.nombreCompleto}`}</Typography>
                    </Grid>
                    <Grid container justify="center" item xs={12} md={5} style={{ marginTop: '15px', marginBottom: '10px' }}>
                        <Typography
                            component="span"
                            className={classes.inline}
                            variant="h6"
                        >{`Fecha: ${fecha}`}</Typography>
                    </Grid>
                    <Grid container justify="center" item xs={12} md={1} style={{ marginTop: '15px', marginBottom: '10px' }}>
                        <Button size="small" color="primary" onClick={() => {setModal(modalReceta => ({ ...modalReceta, abrir: true })) }} title="Ver padecimientos">
                            <VisibilityIcon />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container xs={12} direction="row" className={classes.fondo} justify="center" >
                    <Grid item xs={12} sm={10} justify="center" alignItems="center" alignContent="center">
                        <TextField
                            id="descripcionReceta"
                            label=""
                            placeholder="Diagnóstico"
                            multiline
                            variant="outlined"
                            value={encabezado.descripcionReceta}
                            onChange={handleChangeDescripcionReceta}
                            fullWidth
                            error={errorr.errorDescripcion}
                            rows={3}
                            helperText={encabezado.descripcionReceta.length < 10 ? "Diagnostico *  Mínimo 10 caracteres" : ""}
                        />
                    </Grid>
                </Grid>


                <Grid container xs={12} justify="flex-start" direction="row" className={classes.fondo} style={{ marginTop: '1%' }}>
                    <Grid container item xs={12} justify="center" alignItems="center" spacing={3} >

                        <Grid item xs={9} sm={6} md={4} lg={4} justify="center" alignContent="center" >
                            <Autocomplete
                                id="producto"
                                autoHighlight
                                noOptionsText="Sin productos"
                                freeSolo
                                onChange={ProductoChange} {...productoProps}
                                // style={{ width: '300px' }}
                                fullWidth
                                value={productos.find(p => Number(p.servicioId) === Number(seleccion.productoId)) || null}
                                renderInput={params => (
                                    <TextField {...params} label="Producto" fullWidth inputRef={refs.producto} onFocus={() => setRef({ ...refs, selected: "producto" })} style={{ color: 'red' }} style={{ marginTop: '8px' }} />
                                )}
                                renderOption={
                                    (option, { selected }) => (
                                        <div style={{ color: option.color }} >
                                            {`${option.descripcion}${option.codigoProducto && option.codigoBarras ? '(' : ''} ${option.codigoProducto ? option.codigoProducto : ''}${option.codigoProducto && option.codigoBarras ? ' | ' : ''}${option.codigoBarras ? option.codigoBarras : ''}${option.codigoProducto && option.codigoBarras ? ')' : ''} `
                                            }
                                        </div>
                                    )
                                }
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                            />
                        </Grid>

                        <Grid item xs={3} sm={2} md={2} lg={1} xl={1} alignContent={"center"} >
                            <Grid item xs={12} sm={12} >
                            <InputGrid  id="cantidad" value={seleccion.cantidad} texto="Cantidad" tipo="number"
                                inputProps={{ min: 1 }}
                                onChange={(e) => onCantidadChange(e)}
                                inputRef={refs.cantidad}
                                onFocus={() => setRef({ ...refs, selected: "cantidad" })}
                                inputProps={{ style: {  textAlign: 'right',margin: '0px', padding: '0px' } }}
                                onClick={(e) => seleccionTexto(e)}
                             />
                            </Grid>
                        </Grid>

                        <Grid item xs={11} sm={10} md= {4} lg={4} alignContent={"center"}
                        >

                            <TextField
                                id="anotacionesProducto"
                                label="Indicaciones para el producto"
                                placeholder="Ej. 'Tomar una pastilla cada 8 hrs por 7 días' "
                                multiline
                                variant="outlined"
                                value={seleccion.anotacionesProducto}
                                onChange={handleAnotacionesChange}
                                error={errorr.errorAnotaciones}
                                helperText={seleccion.anotacionesProducto.length < 10 ? "Mínimo 10 caracteres" : ""}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={2} sm={1} md={1} lg={1} alignContent={"center"} >
                            <Grid item xs={12} sm={12} >
                                <InputLabel id="demo-simple-select-label">Resurtible:</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={seleccion.resultible}
                                    onChange={handleChange}
                                    style={{ margin: '0px', padding: '0px' }}
                                >
                                    <MenuItem value={1}>Si</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-start" item xs={12}>

                        {/* botones */}
                        <Grid container justify="flex-end" alignItems="center" item xs={11} style={{ marginBottom: "25px" }} >
                            <Button variant="contained" color="primary" onClick={(e) => handleAgregarClick(e)} ref={refs.add} onFocus={(e) => { setRef({ ...refs, selected: "add" }); }} className={seleccion.servicioId ? "pulso" : ""} style={{ padding: '1px 8px' }}>Agregar</Button>
                        </Grid>
                    </Grid>


                    {/* tabla */}
                    <Grid container justify="center" item xs={12}>
                        <Grid item xs={11} sm={11} justify="center">
                            <Tabla encabezado={false} columnas={columnas} datos={datos} claseEncabezado={{ padding: '4px' }} claseCelda={{ fontSize: 12, padding: '0 5px' }} titleResponsive={["descripcion", "cantidad", "anotacionesProducto"]} />
                        </Grid>
                    </Grid>

                    {/* boton */}
                    <Grid container justify="flex-end" item xs={11} alignContent="left" alignItems="left" style={{ marginBottom: "25px" }}>
                        <Grid item xs={11} sm={11} justify="flex-end">
                            <Button variant="contained" color="primary" onClick={ftGenerarReceta}>
                                Generar
                        </Button>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" item xs={11} alignContent="left" alignItems="left">
                    </Grid>

                </Grid>
            </Grid>

            <Padecimientos abrir={modal.abrir} onClose={modal.handleClose} pacienteId={pacienteId}  />

        </MenuLateral>)
}

