import gql from 'graphql-tag';

//#region =========Clinicas=============
const LISTADO_CLINICAS = gql`
	query ListadoClinicas($limite: Int, $pagina: Int) {
		listadoClinicas(limite: $limite, pagina: $pagina) {
			clinicas {
				clinicaId
				nombre
				alias
				clave
				estatus
				fechaAlta
				padreId
				matriz
				direccionId
				codigoPostal
				claveColonia
				colonia
				claveLocalidad
				localidad
				claveMunicipio
				municipio
				claveEstado
				estado
				clavePais
				pais
                lat
                lng
			}
		}
	}
`;

const LISTADO_CLINICA_MATRIZ = gql`
	query ListadoClinicas($limite: Int, $pagina: Int, $matriz: Boolean) {
		listadoClinicas(limite: $limite, pagina: $pagina, matriz: $matriz) {
			clinicas {
				clinicaId
				nombre
				alias
				clave
				estatus
				fechaAlta
				padreId
				matriz
				direccionId
				codigoPostal
				claveColonia
				colonia
				claveLocalidad
				localidad
				claveMunicipio
				municipio
				claveEstado
				estado
				clavePais
				pais
                lat
                lng
			}
		}
	}
`;

const FILTRO_CLINICAS = gql`
	query ListadoClinicas($limite: Int, $pagina: Int, $clinicaId: Int) {
		listadoClinicas(limite: $limite, pagina: $pagina, clinicaId: $clinicaId) {
			clinicas {
				clinicaId
				nombre
				alias
				clave
				estatus
				fechaAlta
				padreId
				matriz
				direccionId
				codigoPostal
				claveColonia
				colonia
				claveLocalidad
				localidad
				claveMunicipio
				municipio
				claveEstado
				estado
				clavePais
				pais
                lat
                lng
			}
		}
	}
`;

const LISTADO_CLINICAS_CADENA =`
	query ListadoClinicas($limite: Int, $pagina: Int) {
		listadoClinicas(limite: $limite, pagina: $pagina) {
			clinicas {
				clinicaId
				nombre
				alias
				clave
				estatus
				fechaAlta
				padreId
				matriz
				direccionId
				codigoPostal
				claveColonia
				colonia
				claveLocalidad
				localidad
				claveMunicipio
				municipio
				claveEstado
				estado
				clavePais
                pais
                lat
                lng
			}
		}
	}
`;

// # Listado Bonito de Clínicas
const LISTADO_CLINICA_BONITO = gql`
    query LISTADO_CLINICA_BONITO {
        listadoClinicaDireccionActivas {
            clinicaID
            nombreClinica
            alias
            clave
            estatusClinica
            fechaAlta
            padreID
            matriz
            direccionID
            codigoPostal
            claveColonia
            colonia
            claveLocalidad
            localidad
            claveMunicipio
            municipio
            claveEstado
            estado
            clavePais
            pais
            estatusDireccion
            direccion1de4
            direccion2de4
            direccion3de4
            direccion4de4
            telefonos {
                telefonoID
                telefono
            }
            lat
            lng
        }
    }
`;
const LISTADO_CLINICA_BONITO_FETCH = `
    query LISTADO_CLINICA_BONITO {
        listadoClinicaDireccionActivas {
            clinicaID
            nombreClinica
            alias
            clave
            estatusClinica
            fechaAlta
            padreID
            matriz
            direccionID
            codigoPostal
            claveColonia
            colonia
            claveLocalidad
            localidad
            claveMunicipio
            municipio
            claveEstado
            estado
            clavePais
            pais
            estatusDireccion
            direccion1de4
            direccion2de4
            direccion3de4
            direccion4de4
            telefonos {
                telefonoID
                telefono
            }
            horariosClinica {
                dia
                desde
                hasta
                estatus
                ausencia
            }
            lat
            lng
        }
    }
`;
//#endregion

export {
    LISTADO_CLINICAS,
    LISTADO_CLINICA_MATRIZ,
    FILTRO_CLINICAS,
    LISTADO_CLINICAS_CADENA,
    LISTADO_CLINICA_BONITO,
    LISTADO_CLINICA_BONITO_FETCH
}
