import gql from 'graphql-tag';

//#region pagos parciales
const LISTADO_PAGO_PARCIALES=gql`
query listadoPagoParciales($filtro: filtroPagoParciales) {
  listadoPagoParciales(filtro: $filtro) {
    pagoParciales {
      abonoParcialId
      historialPagoPartidaId
      pagoSinIvaParcial
      ivaParcial
      usuarioRecibioIdParcial
      fechaPago
      estatusPagoParcial
      historialPagoPartidaId
      totalPagoSinIvaPartidas
      ivaPartidas
      fechaPagoPartidas
      fechaPagoFinalizadoPartidas
      usuarioRecibiodoPagoPartidas
      estatusPartidas
      numeroPagoPartidas
      totalAPagarSinIvaPartidas
      ivaAPagarPartidas
    }
  }
}
`;
const LISTADO_PAGO_PARCIALES_FETCH = `
query listadoPagoParciales($filtro: filtroPagoParciales) {
    listadoPagoParciales(filtro: $filtro) {
      pagoParciales {
        abonoParcialId
        historialPagoPartidaId
        pagoSinIvaParcial
        ivaParcial
        usuarioRecibioIdParcial
        fechaPago
        estatusPagoParcial
        historialPagoPartidaId
        totalPagoSinIvaPartidas
        ivaPartidas
        fechaPagoPartidas
        fechaPagoFinalizadoPartidas
        usuarioRecibiodoPagoPartidas
        estatusPartidas
        numeroPagoPartidas
        totalAPagarSinIvaPartidas
        ivaAPagarPartidas
      }
    }
  }
`;
//#endregion

export {
    LISTADO_PAGO_PARCIALES_FETCH,
    LISTADO_PAGO_PARCIALES
}
