import gql from 'graphql-tag';

//#region Agenda
//=====================================================
const SELECT_CLINICAS = gql`
    query SelectClinicas($limite: Int, $activas: Boolean) {
        listadoClinicas(limite: $limite, activas: $activas) {
            clinicas {
                clinicaId
                nombre
                estatus
            }
        }
    }
`;
const SELECT_CLINICAS_FETCH = `
    query SelectClinicas($limite: Int, $activas: Boolean, ) {
        listadoClinicas(limite: $limite, activas: $activas) {
            clinicas {
                clinicaId
                nombre
                estatus
            }
        }
    }
`;
const SELECT_CLINICAS_DOCTOR = gql`
    query SelectClinicasPorDoctor($doctorID: Int!, $clinicaID: Int) {
        listadoClinicasPorDoctor(doctorID: $doctorID, clinicaID: $clinicaID) {
            clinicaDoctorId
            clinicaId
            nombre
            estatus
        }
    }
`;
const SELECT_CLINICAS_DOCTOR_FETCH = `
    query SelectClinicasPorDoctor($doctorID: Int!, $clinicaID: Int) {
        listadoClinicasPorDoctor(doctorID: $doctorID, clinicaID: $clinicaID) {
            clinicaDoctorId
            clinicaId
            nombre
            estatus
        }
    }
`;
const SELECT_CLINICAS_DOCTOR_SERVICIOS = gql`
    query SelectClinicasPorDoctor($clinicaID: Int!) {
        listadoClinicasDoctorConServicios(clinicaID: $clinicaID) {
            clinicaDoctorId
            clinicaId
            nombre
            doctorId
            nombreCompleto
            estatus
        }
    }
`;
const SELECT_CLINICAS_DOCTOR_SERVICIOS_FETCH = `
    query SelectClinicasPorDoctor($clinicaID: Int!) {
        listadoClinicasDoctorConServicios(clinicaID: $clinicaID) {
            clinicaDoctorId
            clinicaId
            nombre
            doctorId
            nombreCompleto
            estatus
        }
    }
`;
const LISTADO_CLINICAS_POR_DOCTOR = gql`
query SelectClinicasPorDoctor($doctorID: Int!, $clinicaID: Int) {
    listadoClinicasPorDoctor(doctorID: $doctorID, clinicaID: $clinicaID) {
        clinicaDoctorId
        clinicaId
        nombre
        estatus
    }
}`;
const SELECT_DOCTORES = gql`
    query SelectDoctores($clinicaID: Int, $estatus: Boolean, $limite: Int) {
        listadoClinicaDoctor(clinicaId: $clinicaID, estatus: $estatus, limite: $limite) {
            clinicaDoctores {
                clinicaDoctorId
                nombreCompleto
                doctorId
                estatus
            }
        }
    }
`;
const SELECT_DOCTORES_FETCH = `
    query SelectDoctores($clinicaID: Int, $estatus: Boolean, $limite: Int) {
        listadoClinicaDoctor(clinicaId: $clinicaID, estatus: $estatus, limite: $limite) {
            clinicaDoctores {
                clinicaDoctorId
                nombreCompleto
                doctorId
                estatus
            }
        }
    }
`;
const SELECT_PACIENTES = gql`
    query SelectPacientes($offset: Int, $moroso: Int) {
        listadoPaciente(offset: $offset, moroso:$moroso) {
            pacientes {
                usuarioId
                nombreCompleto
            }
        }
    }
`;
const SELECT_PACIENTES_FETCH = `
    query SelectPacientes($offset: Int,$moroso: Int) {
        listadoPaciente(offset: $offset,moroso:$moroso) {
            pacientes {
                usuarioId
                nombreCompleto
            }
        }
    }
`;
const SELECT_ESTATUS = gql`
	query SelectEstatusAgenda($limite: Int, $predeterminado: Boolean) {
		listadoEstatusCita(limite: $limite, predeterminado: $predeterminado) {
			estatusCitas {
				estatusID
                descripcionEstatus
                colorEstatus
                citaNoModificable
                estatusPredeterminado
                redirigirPuntoVenta
                omitirValidacion
			}
		}
	}
`;
const SELECT_ESTATUS_FETCH = `
	query SelectEstatusAgenda($limite: Int, $predeterminado: Boolean) {
		listadoEstatusCita(limite: $limite, predeterminado: $predeterminado) {
			estatusCitas {
				estatusID
                descripcionEstatus
                colorEstatus
                citaNoModificable
                estatusPredeterminado
                redirigirPuntoVenta
                omitirValidacion
                horaNoModificable
			}
		}
	}
`;
const SELECT_SERVICIOS = gql`
	query SelectServicios($doctorID: Int!, $clinicaID: Int!, $clinicaDoctoID: Int!, $disponible: Boolean, $agendable: Boolean) {
        listadoServiciosDoctoresPorDoctor(doctorID: $doctorID, clinicaDoctoID: $clinicaDoctoID, disponible: $disponible, agendable: $agendable) {
            relacionId
            descripcion
        }
    }
`;
const SELECT_SERVICIOS_FETCH = `
	query SelectServicios($doctorID: Int!, $clinicaID: Int!, $clinicaDoctoID: Int!, $disponible: Boolean, $agendable: Boolean) {
        listadoServiciosDoctoresPorDoctor(doctorID: $doctorID, clinicaID: $clinicaID, clinicaDoctoID: $clinicaDoctoID, disponible: $disponible, agendable: $agendable) {
            relacionId
            descripcion
        }
    }
`;
const SELECT_SERVICIOS_CLINICA_FETCH = `
    query listadoServiciosPorClinica($clinicaID: Int!) {
        listadoServiciosPorClinica(clinicaID: $clinicaID) {
            servicioId
            descripcion
            porDefecto
        }
    }
`;
const SELECT_DOCTORES_SERVICIOS_FETCH = `
    query listadoDoctoresPorServicios($clinicaID: Int!, $servicioID:Int!) {
        ObtenerDoctoresServicio(clinica: $clinicaID, servicio: $servicioID, estatus: 1, doctor: null) {
            serviciosDoctores {
                relacionId
                clinicaDoctorId
                nombreCompleto
                porDefecto
                usuarioId
            }
        }
    }
`;
const SELECT_ESPECIALIDADES = gql`
	query selectEspecialidad {
    listadoEspecialidadServicio {
      especialidadesServicios {
        especialidadId
        descripcion
      }
    }
  }
`;
const SELECT_ESPECIALIDADES_FETCH = `
	query selectEspecialidad {
    listadoEspecialidadServicio {
      especialidadesServicios {
        especialidadId
        descripcion
      }
    }
  }
`;
const SELECT_CLINICA_USUARIO = gql`
    query selectUsuarios ($usuarioID: Int!) {
        obtenerClinicasUsuario(id: $usuarioID) {
            clinicaUsuarioId
            clinicaId
            nombre
            estatus
        }
    }
`;
const SELECT_CLINICA_USUARIO_FETCH = `
    query selectUsuarios ($usuarioID: Int!) {
        obtenerClinicasUsuario(id: $usuarioID) {
            clinicaUsuarioId
            clinicaId
            nombre
            estatus
        }
    }
`;
export const NO_AGENDAR_FETCH = `
    query NoAgendar($id: ID!) {
        noAgendar(id: $id)
    }
`;
export const VALIDAR_RECETA_FETCH = `
    query ValidarReceta($id: ID!) {
        validarReceta(id: $id)
    }
`;
export const VALIDAR_GENERAR_RECETA_FETCH = `
    query ValidarGenerarReceta($id: ID!) {
        ValidarGenerarReceta(id: $id)
    }
`;
//#endregion
//=====================================================
export {
    SELECT_CLINICAS,
    SELECT_CLINICAS_FETCH,
    SELECT_CLINICAS_DOCTOR,
    SELECT_CLINICAS_DOCTOR_FETCH,
    SELECT_CLINICAS_DOCTOR_SERVICIOS,
    SELECT_CLINICAS_DOCTOR_SERVICIOS_FETCH,
    LISTADO_CLINICAS_POR_DOCTOR,
    SELECT_DOCTORES,
    SELECT_DOCTORES_FETCH,
    SELECT_PACIENTES,
    SELECT_PACIENTES_FETCH,
    SELECT_ESTATUS,
    SELECT_ESTATUS_FETCH,
    SELECT_SERVICIOS,
    SELECT_SERVICIOS_FETCH,
    SELECT_ESPECIALIDADES,
    SELECT_ESPECIALIDADES_FETCH,
    SELECT_CLINICA_USUARIO,
    SELECT_CLINICA_USUARIO_FETCH,
    SELECT_SERVICIOS_CLINICA_FETCH,
    SELECT_DOCTORES_SERVICIOS_FETCH
}
