import React from 'react';

//Material-ui
import { DialogTitle, Typography, IconButton } from '@material-ui/core';

//Estilos
import { makeStyles } from '@material-ui/core/styles';

// URL Servidor
// import { strUrlServer as urlServer } from '../../constant/urlServer';

//iconos
import { Close } from '@material-ui/icons';
const iconoModal = (nombre) => `http://localhost:3500/icono/${nombre}`;

function TituloDialog({ texto, tamTitulo = "h4", classes = {}, onClose, icono, ...otros }) {
    const clases = useStyles();

    return (
        <DialogTitle disableTypography className={classes.main} {...otros}>
            {
                icono ? (
                    <img src={iconoModal(icono)} style={{ width: '20%' }} alt="icono"></img>
                ) : ""
            }


            {
                otros.tipo === "confirmacion" ? imagen[otros.tipo] : imagen[texto]
            }


            <Typography variant={tamTitulo} className={otros.tipo ? clases.titulo : ""}>
                {texto === "" || texto === null ? <br /> : texto}
            </Typography>

            {
                onClose ? (
                    <IconButton aria-label="close" className={classes.btnCerrar} onClick={onClose}>
                        <Close />
                    </IconButton>
                ) : null
            }
        </DialogTitle >
    );
}


const imagen = {
    "¡Éxito!": <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: 'flex' }}>
        <div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
        </div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div>
        <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }} ></div>
        <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
    </div >,
    //Error
    "¡Error!": <div className="swal2-icon swal2-error swal2-icon-show" style={{ display: 'flex' }}>
        <span className="swal2-x-mark">
            <span className="swal2-x-mark-line-left"></span>
            <span className="swal2-x-mark-line-right"></span>
        </span>
    </div>,
    //advertencia
    "¡Aviso!": <div className="swal2-icon swal2-warning swal2-icon-show" style={{ display: 'flex' }}>
        <div className="swal2-icon-content">!</div>
    </div>,
    "¡Advertencia!": <div className="swal2-icon swal2-warning swal2-icon-show" style={{ display: 'flex' }}>
        <div className="swal2-icon-content">!</div>
    </div>,
    //info
    "¡Info!": <div className="swal2-icon swal2-info swal2-icon-show" style={{ display: 'flex' }}>
        <div className="swal2-icon-content">i</div>
    </div>,
    //confirmacion
    "confirmacion": <div className="swal2-icon swal2-question swal2-icon-show" style={{ display: 'flex' }}>
        <div className="swal2-icon-content">?</div>
    </div>
}

const useStyles = makeStyles({
    titulo: {
        position: 'relative',
        maxWidth: '100%',
        margin: '0 0 .4em',
        padding: '0',
        color: '#595959',
        fontSize: '1.875em',
        fontWeight: '600',
        textAlign: 'center',
        textTransform: 'none',
        wordWrap: 'break-word'
    }
})
export default TituloDialog;
