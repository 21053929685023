import React, { useContext, useState, useEffect } from 'react';
//Redux
import { useSelector } from 'react-redux';
//Context
import { CRMContext, CRMProvider } from '../../context/Perfil';
//Componentes
import { Grid, Typography } from '../../componentesGenericos/Core';
import EditarPerfil from './Secciones/EditarPerfil';
import EditarPassword from './Secciones/EditarPassword';
import MenuLateral from '../../componentesGenericos/SideBar';
import CodigoQr from './Secciones/CodigoQR';
import Configuracion from './Secciones/Configuracion';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
import { getUsuario } from '../../Helpers/Usuario';
//FETCH
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//QUERY
import { OBTENER_QR } from '../../query/componentes/ConvenioUsuarios';
//Estilos
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '../../Helpers/constantes/Css/EstilosUi';

function Perfil() {

    //Declariones
    const user = useSelector(state => state.user.usuario[0].user.user);
    const [expanded, setExpanded] = useState('panel1');
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [activarQr, setActivarQr] = useState(false);
    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    let userToken = DecodificarToken(user?.token);

    const FetchObtenerQr = async () => {
        let datos = user.token ? DecodificarToken(user.token) : DecodificarToken(perfil.General.token.valor);

        await FetchGrahpQL({
            query: OBTENER_QR,
            variables: {
                id: datos.usuarioId
            }
        })
            .then(async (resp) => {

                let decode = await getUsuario(resp.GenerarQRCompleto);
                if (!decode.usuarioId) {
                    setActivarQr(false);
                } else {
                    setActivarQr(true);
                }


            })
            .catch((err) => {

                setActivarQr(false)
            });
    };

    //CICLO DE VIDA
    useEffect(() => {
        FetchObtenerQr();
    }, []);

    return (<>
        <CRMProvider value={[perfil, guardarPerfiles]}>
            <MenuLateral>
                <div>
                    <ExpansionPanel square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>EDITAR PERFIL</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid item xs={12}>
                                <EditarPerfil />
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Typography>MODIFICAR CONTRASEÑA</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid item xs={12}>
                                <EditarPassword />
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <ExpansionPanelSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography>CONFIGURACIÓN</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid item xs={12}>
                                <Configuracion />
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>


                    {

                        activarQr === true ?

                            user.token && userToken.tipo === 2 ?
                                <ExpansionPanel square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
                                    <ExpansionPanelSummary aria-controls="panel3d-content" id="panel3d-header">
                                        <Typography>CÓDIGO QR</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container item xs={12} justify="center" alignItems="center">
                                            <CodigoQr token={user.token} />
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                : null

                            : null
                    }
                </div>
            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default Perfil;
