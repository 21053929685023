import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Estilos
import { makeStyles } from '@material-ui/core/styles';
// Componentes
import { Button, FormControl, List, ListItem, DialogTitle, Dialog, Select, MenuItem, InputLabel, Container, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import MenuLateral from '../../../componentesGenericos/SideBar';
//Colores
import { blue } from '@material-ui/core/colors';
//Iconos
import { Icon } from '../../../componentesGenericos/Icons';
//Apollo
import { useQuery } from '@apollo/react-hooks';
//queries
import { LISTADO_TURNOS, FILTRO_CLINICAS, LISTADO_SERIE } from '../../../query';
//Redux
import { SeleccionTurno } from "../../../Redux/Actions/SeleccionTurnoAction";
import { useSelector, useDispatch } from 'react-redux';
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
// Helpers
import { guardarStateStorage } from '../../../Helpers/LocalStorage/LocalStorage';
import { notificacionesLoginPuntoVenta } from '../../../Helpers/constantes/State';
// Router
import { withRouter, Redirect } from 'react-router-dom'
//Constantes
import SeleccionClinica from '../../SeleccionClinica'
// import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        margin: theme.spacing(1),
    },
    alineacion: {
        alignItems: 'center',
        color: theme.palette.text.secondary,
    },
    autoAlinearse: {
        alignSelf: 'center'
    }
}));

export default function LoginPuntoVenta(props) {
    let { history } = props;
    let Redireccionar = (nuevoState) => {
        return <Redirect to={{ pathname: "/puntoventa", state: { ...props.location.state, nombreClinica: descClinica } }} />;
    }
    const [open, setOpen] = React.useState(true);
    const [seleccion, setSeleccion] = React.useState('');
    const [turno, setTurno] = React.useState(0);
    const [descClinica, setDescClinica] = React.useState('');
    const [idClinica, setIdClinica] = React.useState(0);
    const [turnoListado, setTurnoListado] = React.useState([]);
    const [serie, setSerie] = React.useState(false);
    const [aceptar, setAceptar] = React.useState(false);
    // OBTENER HORA
    let horaActual = new Date();

    //REDUX
    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let opcionesClinica;
    let userToken = DecodificarToken(userState);
    const state = props.location.state || { clinicaId: null };
    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    let clinicaValida = Number(state.clinicaId ? state.clinicaId : _clinicaID);

    // Variable turnos
    const dispatch = useDispatch();
    const seleccionarTurno = (datos) => dispatch(SeleccionTurno(datos));

    const [estatusClinica, setEstatusClinica] = useState(false);

    const { data: dataClinicas } = useQuery(FILTRO_CLINICAS, {
        variables: {
            clinicaId: clinicaValida
        },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            opcionesClinica = dataClinicas ?
                dataClinicas.listadoClinicas.clinicas.map(valor => ({
                    value: valor
                })) : null
            setDescClinica(opcionesClinica[0].value.nombre);
            setIdClinica(opcionesClinica[0].value.clinicaId);
            // BusquedaTurno();
            setEstatusClinica(opcionesClinica[0].value.estatus)
        },
        onError: (e) => {
            // console.log("Error", e)
        }

    });
    let opcionesTurnos = null;

    const { data: dataSeries } = useQuery(LISTADO_SERIE, {
        variables: { clinicaId: Number(clinicaValida), serieNombre: null, tipoDocumentoId: null, estatusSerie: null, porDefecto: 1 },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setSerie(true)
        },
        onError: (e) => {
            setSerie(false)

        }

    });

    const { loading, error, data, refetch: refetchTurnos } = useQuery(LISTADO_TURNOS, {

        variables: { usuarioId: userToken.usuarioId, clinicaId: Number(clinicaValida) ===  0 ? -1 : clinicaValida, offset: 0 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            // setTurno(0)

            opcionesTurnos = [<MenuItem value={-1}>No se encontraron turnos</MenuItem>]


            setTurnoListado(opcionesTurnos);

            setTurno(-1);
            setSeleccion(-1);
        },
        onCompleted: () => {

            opcionesTurnos = data ?
                data.listadoTurnos.turnos.map(valor => (
                    <MenuItem value={Number(valor.turnoId)}>{valor.descripcion}</MenuItem>
                )) : null

            setTurnoListado(opcionesTurnos);

            // opcionesTurnos.length === 1 ? setSeleccion(opcionesTurnos[0].props.value) : null;
            if(opcionesTurnos.length === 1){
                setSeleccion(opcionesTurnos[0].props.value)
            }else{
            let turnos = data ?
                data.listadoTurnos.turnos.map((valor) => (
                    valor

                )) : null

            let hoy = new Date();
            let timestamp = date => (Math.floor(new Date(date).getTime() / 1000.0));

            turnos = turnos.map(({ horaInicio, horaFin, turnoId, descripcion }) => {
                let anio = hoy.getFullYear();
                let mes = (hoy.getMonth() + 1) < 10 ? `0${hoy.getMonth() + 1}` : hoy.getMonth() + 1;
                let dia = hoy.getDate();
                return ({
                    horaInicio: new Date(`${anio}-${mes}-${dia}T${horaInicio}`),
                    horaFin: new Date(`${anio}-${mes}-${dia}T${horaFin}`),
                    turnoId,
                    descripcion
                })
            });

            let turno = turnos.filter((turno) => {
                if (timestamp(turno.horaInicio) <= timestamp(hoy) && timestamp(hoy) <= timestamp(turno.horaFin)) return turno;
            });

            let SelecTurno = turno.length > 0 ? <MenuItem value={Number(turno[0].turnoId)}>{turno[0].descripcion}</MenuItem> : opcionesTurnos;

            setTurno(SelecTurno);
            setSeleccion(turno.length > 0 ? turno[0].turnoId : "");
        }}
    });

    function opcionDefaultTurno() {
        return (
            <MenuItem value={-1}>Seleciona una sucursal</MenuItem>
        );
    }

    const classes = useStyles();

    const handleClose = () => {
        history.push("/menu");
    };


    const handleAceptClick = value => {
        //Asignación de valor de turno
        seleccionarTurno(seleccion);
        guardarStateStorage({ TurnoSeleccionado: seleccion }, 'local');
        setOpen(false);

        if (seleccion !== '' && seleccion >= 0 && userToken.tipo === 4 && clinicaValida > 0 && serie === true && estatusClinica === true) {
            // setTimeout(history.push("/puntoventa"), 20000);
            setTimeout(setAceptar(true), 20000);
        } else if (userToken.tipo === 3 && clinicaValida > 0 && serie === true && estatusClinica === true) {
            // setTimeout(history.push("/puntoventa"), 20000);
            setTimeout(setAceptar(true), 20000);
        } else {
            setOpen(true);

        }
    };

    function opcionesNotificacion() {

        if (clinicaValida < 1) {

            return (

                <Alert severity="info">
                    <AlertTitle>Login Punto De Venta</AlertTitle>
                    Debe tener seleccionada una sucursal
                </Alert>

            );

        }

        if (seleccion === 0 || seleccion === '' || seleccion === null && idClinica === 0) {
            if (userToken.tipo === 4) {

                return (
                    <Alert severity="info">
                        <AlertTitle>Login Punto De Venta</AlertTitle>
                        <label>Selecciona un turno</label>
                        <br />
                        <label>Si no cuentas con uno solicitalo</label>
                        <br />
                        <label>con el administrador</label>
                    </Alert>
                );

            } else if (serie === false) {
                return (
                    <Alert severity="info">
                        <AlertTitle>Login Punto De Venta</AlertTitle>

                        <label>No hay una serie definida por</label>
                        <br />
                        <label>defecto para esta sucursal</label>
                    </Alert>
                );
            } else if (estatusClinica === false) {
                return (
                    <Alert severity="info">
                        <AlertTitle>Login Punto De Venta</AlertTitle>
                        <label>La sucursal se encuentra</label>
                        <br />
                        <label>inactiva</label>
                    </Alert>
                );
            }

        }
        else if (serie === false) {
            return (
                <Alert severity="info">
                    <AlertTitle>Login Punto De Venta</AlertTitle>

                    <label>No hay una serie definida por</label>
                    <br />
                    <label>defecto para esta sucursal</label>
                </Alert>
            );
        }
        else {
            return (
                <Alert severity="info">
                    <AlertTitle>Login Punto De Venta</AlertTitle>
                    <label>Bienvenido administrador.</label>
                    <br />
                    <label>Iniciar</label>
                </Alert>
            )
        }
    }

    function NotificacionDefault() {
        return (
            <Alert severity="info">
                <AlertTitle>Login Punto De Venta</AlertTitle>
                Iniciar
            </Alert>
        );
    }

    function SerieDefault() {
        return (
            <Alert severity="info">
                <AlertTitle>Login Punto De Venta</AlertTitle>
                <label>No hay una serie definida por</label>
                <label>defecto para esta sucursal</label>
            </Alert>
        );
    }

    const handleChange = event => {
        setSeleccion(event.target.value);
    };

    return (
        <MenuLateral titulo="Turnos" >
            {
                aceptar && Redireccionar({ ...props.location.state, nombreClinica: descClinica })
            }
            <Container maxWidth="sm" className={classes.root}>

                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} >

                    {idClinica > 0 && seleccion > 0 ? serie === true ? NotificacionDefault() : SerieDefault() : opcionesNotificacion()}
                    <List className={classes.alineacion}>
                        <ListItem>
                            <TextField
                                fullWidth
                                id="standard-read-only-input"
                                label="Usuario: "
                                defaultValue={userToken.nombreCompleto}
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </ListItem>
                        <ListItem style={{maxWidth:'300px'}}>
                            {/* <TextField
                                fullWidth
                                id="NombreClinica"
                                label="Clinica: "
                                value={descClinica}
                                InputProps={{
                                    readOnly: true,
                                }}
                            /> */}
                            <SeleccionClinica />
                        </ListItem>
                        {
                            userToken.tipo === 4 ?

                                <ListItem>
                                    <FormControl className={classes.formControl} style={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-helper-label">Turno</InputLabel>
                                        <Select
                                            // fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={seleccion}
                                            onChange={handleChange}

                                        >
                                            {turnoListado}
                                        </Select>
                                    </FormControl>
                                </ListItem> : <></>
                        }
                        <ListItem>

                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<Icon>send</Icon>}
                                onClick={handleAceptClick}
                                disabled={clinicaValida === 0 ? true : false}
                            >
                                Iniciar Punto de Venta
                             </Button>
                        </ListItem>
                    </List>
                </Dialog>
            </Container>
        </MenuLateral>
    );
}

