import React from 'react';
//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../../componentesGenericos/Icons';
import ImportExportIcon from '@material-ui/icons/ImportExport';


/*FORMATO DE COLUMNAS */

// // seleccion inicial para el query
const espereUnMomento = {value: 0, label: "Espere un momento"},
sinRegistros = { value: 0, label: "No se encontraron registros"},
seleccionInicial = {
    encabezadoId: null,
    clinicaId: null,
    nombreClinica: null,
    almacenId: null,
    nombreAlmacen: null,
    ccostoId: null,
    nombreCcosto: null,
    referencia:null
    // existenciaInicial: null,
    // entradas:null,
    // salidas: null,
    // existenciaActual: null,
    // conceptoId: null,
    // nombreConcepto: null,
    // nombre: null
};

//seleccion para Columnas
const seleccionExistencias = {
        existenciaId:null,
        encabezadoId:null,
        empresaId:null,
        nombreEmpresa:null,
        clinicaId:null,
        nombreClinica:null,
        almacenId:null,
        nombreAlmacen:null,
        centroCostosId:null,
        centroCostos:null,
        conceptoId:null,
        nombreConcepto:null,
      	servicioId:null,
      	descripcion:null,
      	codigoProducto:null,
      	codigoBarras:null,
      	precioUnitario:null,
      	iva:null,
      	precioTotal:null,
      	porcentajeDescuento:null,
      	descuentoTotal:null,
      	precioDescuento:null,
      	ivaDescuento:null,
      	PrecioTotalDescuento:null,
      	comision:null,
      	tipo:null,
      	especialidadId:null,
      	agendable:null,
      	inventariable:null,
      	disponible:null,
      	tipoIva:null,
      	tasaIva:null,
      	noAplicaIva:null,
      	fechaInicialDescuento:null,
      	fechaFinalDescuento:null,
      	venderSinExistencia:null,
        existenciaInicial:null,
        entradas:null,
        salidas:null,
        existenciaActual:null,
        referencia:null
}



// menu lateral
const menu = (handleAgregarClick, handleModificarClick, handleEliminarClick, redireccionar) => [
    { texto: "Agregar ubicación", icono: <AddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar ubicación", icono: <EditIcon />, onClick: () => handleModificarClick() },
    {texto: "Eliminar ubicación", icono: <DeleteForever/>, onClick: () => handleEliminarClick()},
    {texto: "Desplazarse a entradas y salidas", icono: <ImportExportIcon/>, onClick: () => redireccionar()}
];

//estate inicial
 const actualizarState = false;
 const campoState = false;
 const filaState = -1;
const seleccionState = {
    encabezadoId: null,
    clinicaId: null,
    nombreClinica: null,
    almacenId: null,
    nombreAlmacen: null,
    ccostoId: null,
    nombreCcosto: null,
    referencia:null
};
const formularioState = (ftFormularioClose) => {
    return{
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

const formularioModificarState = (ftFormularioClose) => {
    return{
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//Estilos
const Style = {
    height: "90%", textAlign: "center", width:"100%"
};

export { Style, actualizarState, filaState, seleccionInicial, formularioState, seleccionState, menu, formularioModificarState, campoState, sinRegistros, seleccionExistencias};
