import { AGREGAR_ESPECIALIDAD } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_MODIFICAR_CLINICA_DOCTOR, ELIMINAR_CLINICA_DOCTOR } from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos,ActivarDesactivarBoton,ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVO_MODIFICAR_CLINICA_DOCTOR,
                variables: {
                    clinicaId: datos.Clinicas.clinicaId.valor,
                    doctorId: datos.Clinicas.doctorId.valor
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `${resp.agregarActualizarClinicaDoctor.mensaje}`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        } else {
            FetchGrahpQL({
                query: ELIMINAR_CLINICA_DOCTOR,
                variables: {
                    clinicaId: datos.Clinicas.clinicaId.valor,
                    doctorId: datos.Clinicas.doctorId.valor
                }
            })
                .then(resp => {
                    if(resp.eliminarClinicaDoctor[0].toLowerCase() == "alertmessage")
                        Swal.fire("¡Aviso!", `${resp.eliminarClinicaDoctor[1]}`, "warning");//warning, question,info
                    else
                        Swal.fire("¡Éxito!", `${resp.eliminarClinicaDoctor}`, "success");
                     ActivarDesactivarBoton(false);
                     actualiza();
                     ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                     Swal.fire("¡Aviso!", err.message, "info");
                    }
                     ActivarDesactivarBoton(false);
                });
        }
        dispatch(AsingarClinicaDoctor({ activo: true }))
    }
}


export const AsingarClinicaDoctor = usuariosCrud => {
    return {
        type: AGREGAR_ESPECIALIDAD,
        payload: usuariosCrud
    }
}




