import React, { useState, useContext, useEffect } from 'react';
//Componentes Core
import { Dialog, Button, List, AppBar, Toolbar, Slide, Typography, IconButton }
    from '../../../componentesGenericos/Core';
//context
import { CRMContext } from './../../../context/Registro';
//Fetch
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//estilos
import { makeStyles } from '@material-ui/core/styles';
//Icons
import { CloseIcon } from "../../../componentesGenericos/Icons";
//Componentes
import Recuperar from './Formularios/RecuperarMovil';
import Mensaje from '../../../componentesGenericos/Mensaje';
//helpers
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
//query
import { RECUPERAR_CONTRASEÑA } from "../../../mutation";
//constantes
import { agregarPaciente } from './../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial } from '../../../Helpers/constantes';


const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function RecuperarMovil(props) {

    //DECLARACIONES
    const classes = useStyles();
    let {cerrarRegistro, open } = props;
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [getNotificacion, setNotificacion] = useState(agregarPaciente);
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [activarBoton,setActivarBoton]=useState(false);

    //Eventos
    const handleClickPaciente = async () => {
        setActivarBoton(true);
        let campos = [
            "email",
            "password",
        ];
        let datos = verificaValores(campos, usuarios.Paciente);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            setActivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            await FetchRecuperarPassword();
        }
    }

    //API
    const FetchRecuperarPassword = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        FetchGrahpQL({
            query: RECUPERAR_CONTRASEÑA,
            variables: {
                email: usuarios.Paciente.email.valor,
                password: usuarios.Paciente.password.valor,
                nombreDB: process.env.REACT_APP_DB
            }
        })
            .then(() => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Éxito!", texto: `Se envió un correo para confirmar` });
                setActivarBoton(false);
                cerrarRegistro();
            })
            .catch((err) => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto:"Ha ocurrido algo inesperado,inténtalo  más tarde "});
                setActivarBoton(false);
                cerrarRegistro();
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };
    //Funciones
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }
    //Ciclo de vida
    useEffect(() => {
        guardarUsuarios(agregarPaciente)
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }, [open]);

    return (
        <div>
            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />
            <Dialog fullScreen open={open} onClose={cerrarRegistro} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={cerrarRegistro} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Recuperar contraseña
              </Typography>
                        <Button autoFocus color="inherit" onClick={handleClickPaciente} disabled={activarBoton}>
                        Enviar
              </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Recuperar />
                </List>
            </Dialog>
        </div>
    )

}

export default RecuperarMovil;
