import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [evento, setEvento] = useState({
        agendaID: 0,
        clinicaID: 0,
        clinicaDoctoID: 0,
        pacienteID: 0,
        estatusID: 0,
        servicioDoctorID: 0,
        servicioID: 0,
        estatusPredeterminadoID: 0,
        title: "",
        descripcion: "",
        citaNoModificable: false
    });
    return (
        <CRMContext.Provider value={[evento, setEvento]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
