import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {ftAnioNoValido,ftAniosRangosValidos,ftAniosRangosValidosAlterno,ftAniosRangosValidosHoy} from "../../../../Helpers";
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, IconButton,MenuItem,InputAdornment } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
import { VisibilityIcon, VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//Rgx
import { rgxEmail, rgxtel, rgxLetras,rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Paciente(props) {

    //Declaraciones
    let { seleccion } = props;
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxTelefono = new RegExp(rgxtel);
    let rgxLetra = new RegExp(rgxLetras);
    let rgxPasswords = new RegExp(rgxPassword);
    const [sexo, setSexo] = React.useState();
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0   || ftAniosRangosValidosHoy(value)==false || ftAnioNoValido(value)===true) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value)  && value.length>1) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                [name]: {
                    ...datos.Paciente[name],
                    error: false, valor: value
                }
            }
        });
    }
    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }
    const handleChangeGuardarTelefono = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }
    const handleChangeGuardarStatePassword = event => {

        let { name, value } = event.target;

        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    //Funciones
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };
    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    //Api
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }


    //Ciclo de vida
    useEffect(() => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                nombres: {
                    ...datos.Paciente.nombres,
                    error: false, valor: seleccion.nombres
                },
                email: {
                    ...datos.Paciente.nombres,
                    error: false, valor: seleccion.email
                },
                apellidos: {
                    ...datos.Paciente.apellidos,
                    error: false, valor: seleccion.apellidos
                },
                email: {
                    ...datos.Paciente.email,
                    error: false, valor: seleccion.email
                },
                sexo: {
                    ...datos.Paciente.sexo,
                    error: false, valor: seleccion.sexo
                },
                usuarioId: {
                    ...datos.Paciente.usuarioId,
                    error: false, valor: seleccion.usuarioId
                },
                fechaEgreso: {
                    ...datos.Paciente.fechaEgreso,
                    error: false, valor: seleccion.fechaEgreso
                },
                telefono: {
                    ...datos.Paciente.telefono,
                    error: false, valor: seleccion.telefono
                },
                fechaNacimiento: {
                    ...datos.Paciente.fechaNacimiento,
                    error: false, valor: seleccion.fechaNacimiento
                }

            }
        });
        FetchGenero();
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Paciente.nombres.error} defaultValue={seleccion.nombres} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Paciente.apellidos.error} defaultValue={seleccion.apellidos} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} defaultValue={seleccion.email}  required={true} helperText="El email es un campo obligatorio" />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={false} error={datos.Paciente.password.error} htmlFor="standard-adornment-password11">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password11"
                                    name="password"
                                    error={datos.Paciente.password.error}
                                    type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                                    value={datos.Paciente.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como mínimo 8 caracteres y máximo 30 caracteres (No es un campo obligatorio)</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Paciente.telefono.error} defaultValue={seleccion.telefono} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    labelId={`genero`}
                                    id={`genero`}
                                    onChange={handleChangeGuardarStateSexo}
                                    name="sexo"
                                    required={true}
                                    defaultValue={seleccion.sexo}
                                >
                                    <MenuItem key={'-1'} selected value={'null'} disabled>{'Escoge el género '}</MenuItem>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                required={true}
                                    style={{ width: "100%" }}
                                    name="fechaNacimiento"
                                    id="date"
                                    helperText="La fecha de nacimiento es obligatoria"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    error={datos.Paciente.fechaNacimiento.error}
                                    defaultValue={seleccion.fechaNacimiento != null ? (seleccion.fechaNacimiento).split("T")[0] : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Paciente;

