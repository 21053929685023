export const menuPacienteDoctor = [
    {
        id: "anamnesis",
        texto: "Anamnesis",
        imagen: "/icons/anamnesis.png",
        url: "/anamnesis"
    },
    {
        id: "padecimientos",
        texto: "Padecimientos",
        imagen: "/icons/Padecimiento.png",
        url: "/tipoPadecimiento"
    },
    {
        id: "clinicas",
        texto: "Sucursales",
        imagen: "/icons/clinicas.png",
        url: "/sucursales"
    },
    {
        id: "servicios",
        texto: "Servicios",
        imagen: "/icons/servicios.png",
        url: "/servicios"
    },
    {
        id: "comisionesProfesionales",
        texto: "Comisiones profesionales",
        imagen: "/icons/comisionesProfesionales.png",
        url: "/comisionesProfesionales"
    }

];

export const menuAdminUsuario = [
    {
        id: "inicio",
        texto: "Inicio",
        imagen: "/icons/top-menu.png",
        url: "/Menu"
    },
    {
        id: "historialClinico",
        texto: "Historial clínico",
        imagen: "/icons/anamnesis.png",
        url: "/historialClinico"
    },
    {
        id: "padecimientos",
        texto: "Padecimientos",
        imagen: "/icons/Padecimiento.png",
        url: "/tipoPadecimiento"
    },


    // GRUPO DE SUCURSALES
    {
        texto: "Sucursales",
        imagen: "/icons/clinicas.png",
        url: "",
        id: "sucursales",
        subMenu: [
            {
                subId: "clinica",
                texto: "Sucursal",
                imagen: "/icons/clinicas.png",
                url: "/sucursales"
            }, {
                subId: "horarioClinica",
                texto: "Horario sucursal",
                imagen: "/icons/horarioClinica.png",
                url: "/horariosucursal"
            }, {
                subId: "profesionalesClinicas",
                texto: "Asignar profesionales a sucursales",
                imagen: "/icons/asignarDoctoresClinica.png",
                url: "/asignarSucursalesprofesionales"
            }, {
                subId: "usuariosClinicas",
                texto: "Asignar usuarios a sucursales",
                imagen: "/icons/asignarUsuariosClinicas.png",
                url: "/asignarUsuariosSucursales"
            },
        ]
    },

    // GRUPO DE INVENTARIOS
    {
        texto: "Inventarios",
        imagen: "/icons/in-inventory.png",
        url: "",
        id: "inventarios",
        subMenu: [
            {
                subId: "servicios",
                texto: "Servicios",
                imagen: "/icons/servicios.png",
                url: "/servicios"
            },
            {
                subId: "materiales",
                texto: "Materiales",
                imagen: "/icons/materiales.png",
                url: "/materiales"
            },
            {
                subId: "productos",
                texto: "Productos",
                imagen: "/icons/productos.png",
                url: "/productos"
            },
            {
                subId: "almacenes",
                texto: "Almacenes",
                imagen: "/icons/almacen.png",
                url: "/almacen"
            },
            {
                subId: "entradaSalida",
                texto: "Entradas y salidas",
                imagen: "/icons/in-inventory.png",
                url: "/inventario"
            },
            {
                subId: "movsInventario",
                texto: "Movimientos inventario",
                imagen: "/icons/data-in-both-directions.png",
                url: "/movimientosalmacen"
            },
        ]
    },
    // GRUPO VENTAS
    {
        texto: "Ventas",
        imagen: "/icons/ventas.png",
        url: "",
        id: "ventas",
        subMenu: [
            {
                subId: "turnosPuntoVenta",
                texto: "Turnos punto de venta",
                imagen: "/icons/turnoIcon.png",
                url: "/turno"
            },
            {
                subId: "formaCobroPuntoVenta",
                texto: "Forma de cobro punto de venta",
                imagen: "/icons/masterCard.png",
                url: "/formaCobro"
            },
            {
                subId: "serie",
                texto: "Serie",
                imagen: "/icons/serie.png",
                url: "/serie"
            },
            {
                subId: "puntoVenta",
                texto: "Punto de venta",
                imagen: "/icons/pos-terminal.png",
                url: "/loginpuntoventa",
            },
            {
                subId: "tickets",
                texto: "Tickets",
                imagen: "/icons/parcking-ticket.png",
                url: "/ticket"
            }

        ]
    },

    //GRUPO CATALOGOS
    {
        texto: "Catálogos",
        imagen: "/icons/catalogo.png",
        url: "",
        id: "catalogos",
        subMenu: [
            {
                subId: "almacenes",
                texto: "Clientes",
                imagen: "/icons/OpcionPaciente.png",
                url: "/clientes"
            },
            {
                subId: "autorizacionDatos",
                texto: "Autorización de datos",
                imagen: "/icons/autorizarDatos.png",
                url: "/autorizacionDatos"
            },
            {
                subId: "usuarios",
                texto: "Usuarios",
                imagen: "/icons/user-group-man-woman.png",
                url: "/usuarios"
            },
            {
                texto: "Especialidades profesionales",
                subId: "especialidadesDoctores",
                imagen: "/icons/doctor-male.png",
                url: "/especialidades"
            },
            {
                subId: "profesionales",
                texto: "Profesionales",
                imagen: "/icons/OpcionDoctor.png",
                url: "/profesionales"
            },
            {
                subId: "comisiones",
                texto: "Comisiones",
                imagen: "/icons/comisiones.png",
                url: "/comisiones"
            },
            {
                subId: "estatusAgenda",
                texto: "Estatus agenda",
                imagen: "/icons/list.png",
                url: "/estatusagenda"
            },
            {
                subId: "recordatorios",
                texto: "Recordatorios",
                imagen: "/icons/clock--v1.png",
                url: "/recordatorios"
            },
            {
                subId: "comisionesProfesionales",
                texto: "Comisiones profesionales",
                imagen: "/icons/comisionesProfesionales.png",
                url: "/comisionesProfesionales"
            },
        ]
    },

    // GRUPO COMERCIAL
    {
        texto: "Comercial",
        imagen: "/icons/comercial.png",
        url: "",
        id: "comercial",
        subMenu: [
            {
                subId: "promociones",
                texto: "Flyers publicitarios",
                imagen: "/icons/loyalty-card.png",
                url: "/promociones"
            },
            {
                subId: "convenios",
                texto: "Convenios",
                imagen: "/icons/handshake.png",
                url: "/convenios"
            },
        ]
    },


    // {
    //     texto: "Asignar servicios a profesionales",
    //     imagen: "/icons/asignarSeriviciosDoctores.png",
    //     url: "/asignarservicios",
    // },

    //asignar convenios a usuarios
    // {
    //     texto: "Asignar servicios a convenios",
    //     imagen: "/icons/asignarConvenioServicios.png",
    //     url: "/convenioservicios",
    // },

    // {

    //     texto: "Horario Doctor",
    //     imagen: "/icons/data-sheet.png",
    //     url: "/horariodoctor"
    // },
    {
        id: "reportes",
        texto: "Reportes",
        imagen: "/icons/doc.png",
        url: "/reportes"
    },
    // GRUPO CONFIGURACIÓN
    {
        texto: "Configuración",
        imagen: "/icons/ajustes.png",
        url: "",
        id: "configuracion",
        subMenu: [
            {
                subId: "configuracion",
                texto: "Configuración",
                imagen: "/icons/configuracion.png",
                url: "/configuracion"
            },
            {
                subId: "seguridad",
                texto: "Seguridad",
                imagen: "/icons/user-shield.png",
                url: "/seguridad"
            },
            {
                subId: "estatusAgendaProgramado",
                texto: "Estatus agenda programado",
                imagen: "/icons/cronometro.png",
                url: "/cambioEstatus"
            },
        ]
    },
    // {
    //     texto: "Asignar convenios a clientes",
    //     imagen: "/icons/conveniosUsuarios.png",
    //     url: "/asignarconveniosclientes"
    // }
];
