import gql from 'graphql-tag';

const LISTADO_TIPO_PADECIMIENTO = gql`
query ListadoTipoPadecimiento{
    listadoTipoPadecimiento{
      TiposPadecimientos{
        tipoPadecimientoId
        descripcion
      }
      registros
      paginas
    }
  }`;

const LISTADO_TIPO_PADECIMIENTO_FETCH = `
query ListadoTipoPadecimiento{
    listadoTipoPadecimiento{
      TiposPadecimientos{
        tipoPadecimientoId
        descripcion
      }
      registros
      paginas
    }
  }`;

  export{ LISTADO_TIPO_PADECIMIENTO,LISTADO_TIPO_PADECIMIENTO_FETCH }
