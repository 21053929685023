import React, { useContext } from 'react';
import clsx from 'clsx';
//Core
import { Grid, TextField,FormHelperText,InputAdornment,Input,IconButton,FormControl,InputLabel} from '../../../../componentesGenericos/Core';
//Rgx
import { rgxEmail,rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Registro';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//iconos
import {VisibilityIcon,VisibilityOffIcon } from '../../../../componentesGenericos/Icons';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    espaciado:{
        padding:"20px"
    }
}));



function Desk() {

    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxPasswords = new RegExp(rgxPassword);
    const [datos, guardarDatos] = useContext(CRMContext);

    //Eventos

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value=value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid spacing={3} container direction="column"
                justify="center"  className={classes.espaciado}>
                <Grid item xs={12}>
                    <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="Es campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} />
                </Grid>
                <Grid item xs={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={datos.Paciente.password.error}  htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{paddingTop:"5px"}}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Paciente.password.error}
                                    type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                                    value={datos.Paciente.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,numero y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como minimo 8 caracteres y maximo 30 caracteres</FormHelperText>
                            </FormControl>
                        </Grid>

            </Grid>
        </>
    )

}

export default Desk;
