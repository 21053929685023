import React, { useState, useEffect } from 'react';

//COMPONENTES
import EscanerQr from '../../../../componentesGenericos/EscanerQr';

//APOLLO
import { useQuery } from '@apollo/react-hooks';

//QUERIES
import { FILTRO_RECETAS_PARTIDAS } from '../../../../query';

//HELPERS
import { getUsuario } from '../../../../Helpers/Usuario';

function Receta({ abrir = false, onChange = () => { } }) {

    //#region STATES
    const [receta, setReceta] = useState({
        abrir: abrir,
        ok: false,
        tipo: "mensaje",
        texto: "",
        codigo: null,
        onAccept: (codigo) => {
            onChange(false, codigo || null);
            setReceta({ ...receta, abrir: false, codigo: codigo || null });
        },
        onCancel: () => {
            onChange(false, null);
            setReceta({ ...receta, abrir: false });
        }
    });
    //#endregion

    //#region QUERYS
    const { refetch: buscarReceta } = useQuery(FILTRO_RECETAS_PARTIDAS, {
        variables: { recetaId: receta.codigo },
        onCompleted: () => {
        },
        onError: (e) => {
            console.log(e.message)
        }
    })
    //#endregion

    //#region HANDLERS
    const handleRecetaChange = (recetaData) => {
        if (recetaData) {

            let cnv = getUsuario(recetaData);

            if (cnv) {
                if (cnv.recetaId) {
                    let ids = cnv.recetaId;

                    setReceta({
                        ...receta,
                        codigo: ids,
                    });

                } else {
                    setReceta({
                        ...receta,
                        texto: "El código escaneado no coincide con el de una receta",
                        codigo: null,
                        ok: false
                    });
                }
            } else {
                setReceta({
                    ...receta,
                    texto: "Receta no encontrada",
                    codigo: null,
                    ok: false
                });
            }
        }
    }
    //#endregion

    //#region USEEFFECTS
    useEffect(() => {
        setReceta({ ...receta, abrir });
    }, [abrir]);

    useEffect(() => {
        if (receta.codigo !== null) {
            buscarReceta()
                .then(({ data }) => {
                    let errorReceta = true;
                    if (data) {

                        if (data.filtroPartidas) {

                            if (data.filtroPartidas.partidas) {
                                if (data.filtroPartidas.partidas.length > 0) {
                                    errorReceta = false;
                                }
                            }
                        }
                    }

                    if (!errorReceta) {
                        setReceta({
                            ...receta,
                            texto: "Receta encontrada",
                            ok: true
                        });

                        setTimeout(() => {
                            setReceta({
                                ...receta,
                                abrir: false,
                                texto: "",
                                ok: false,
                                codigo: null
                            });
                            onChange(false, receta.codigo, data.filtroPartidas);

                        }, 2000);

                    } else {
                        setReceta({
                            ...receta,
                            texto: "Receta no encontrada",
                            codigo: null,
                            ok: false
                        });
                    }
                }).catch(e => {
                    console.log(e.message);
                    setReceta({
                        ...receta,
                        texto: "El código escaneado no coincide con el de una receta",
                        codigo: null,
                        ok: false
                    });
                });
        }
    }, [receta.codigo])
    //#endregion

    return (
        <EscanerQr abrir={receta.abrir} titulo="Escanear receta" tipo={receta.tipo} onAccept={receta.onAccept} onCancel={receta.onCancel} onChange={handleRecetaChange} texto={receta.texto} success={receta.ok} />
    )
}

export default Receta
