import React from 'react'
import ProductosServicios from './Listado';

function Servicios() {
    return (
        <ProductosServicios titulo="materiales" titulo2="material" tipo="3" />
    )
}

export default Servicios
