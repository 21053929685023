

const ELIMINAR_DATOS_USUARIOS_AUTORIZADOS =`
mutation EliminarDatosUsuariosAutorizaciones($id: Int!) {
    EliminarDatosUsuariosAutorizaciones(id: $id)
}`;

const MODIFICAR_DATOS_USUARIOS_AUTORIZADOS=`
mutation ModificarDatosUsuariosAutorizaciones(
    $usuarioId: ID!
    $nombres: String
    $apellidos: String
    $email: String
    $cedula: String
    $fechaEgreso: Date
    $estatus: Int
    $especialidad: Int
    $sexo: Int
    $telefono: String
    $fechaNacimiento: Date
    $solicitanteId: Int!
    $password: String
  ) {
    ModificarDatosUsuariosAutorizaciones(
      input: {
        usuarioId: $usuarioId
        nombres: $nombres
        apellidos: $apellidos
        email: $email
        cedula: $cedula
        fechaEgreso: $fechaEgreso
        estatus: $estatus
        especialidadId: $especialidad
        sexo: $sexo
        telefono: $telefono
        fechaNacimiento: $fechaNacimiento
        solicitantesId: $solicitanteId
        password: $password
      }
    ) {
      datosUsuariosAutorizacionId
      usuarioId_nuevo
      nombres_nuevo
      apellidos_nuevo
      email_nuevo
      cedula_nuevo
      fechaEgreso_nuevo
      estatus_nuevo
      especialidadId_nuevo
      sexo_nuevo
      telefono_nuevo
      fechaNacimiento_nuevo
      autorizacion
      solicitantesId
      nombre_actual
      apellidos_actual
      email_actual
      cedula_actual
      fechaEgreso_actual
      estatus_actual
      especialidadId_actual
      sexo_actual
      telefono_nuevo
      fechaNacimiento_actual
    }
  }
  `;

export {
    ELIMINAR_DATOS_USUARIOS_AUTORIZADOS,
    MODIFICAR_DATOS_USUARIOS_AUTORIZADOS
}
