import React from 'react';
//Componentes-Core
import { Button, Typography } from "../Core/index";
//Dependencias
import PropTypes from 'prop-types';
//Estilos
import { withStyles, makeStyles } from '@material-ui/core/styles';
const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 12px',
        border: '1px solid',
        lineHeight: 1.5,
        backgroundColor: '#007bff',
        borderColor: '#007bff',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            backgroundColor: '#0069d9',
            borderColor: '#0062cc',
            boxShadow: 'none',
        },
        '&:active': {
            boxShadow: 'none',
            backgroundColor: '#0062cc',
            borderColor: '#005cbf',
        },
        '&:focus': {
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
        '&:disabled': {
            color: "#005cbf!important",

        },
    }
})(Button);

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

function BotonBoostrap(props) {
    const classes = useStyles();

    return (
        <BootstrapButton variant="contained" color={`${props.Color}`} size="large" disableRipple className={classes.margin} style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }} onClick={props.EventoClick} disabled={props.Desactivar}>
            <Typography variant="button">  {props.Texto}</Typography>
        </BootstrapButton>

    );
}
BotonBoostrap.propTypes = {
    Texto: PropTypes.string.isRequired,
    Color: PropTypes.string.isRequired,
    EventoClick: PropTypes.func,
    Desactivar: PropTypes.bool
};
export default BotonBoostrap;

