import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input from './Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { SELECT_CLINICAS_FETCH } from '../../../query';
import { LISTAR_FORMA_COBRO_FETCH } from '../../../query/componentes/FormaCobro';
import { FetchGrahpQL, FetchAPI } from '../../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat, urlServer, showFile, ftAnioNoValido, ftAniosRangosValidos, timestamp } from '../../../Helpers';

import { useSelector } from "react-redux";
import { CRMContext } from '../../../context/Reporte';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ReportePuntoVenta({ setMensaje }) {

    const classes = useStyles();

    const hoyDate = new Date();
    let dia = hoyDate.getDate();
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    let mes = hoyDate.getMonth() + 1;
    mes = mes < 10 ? `0${mes}` : mes;
    let anio = hoyDate.getFullYear();
    const hoy = `${anio}-${mes}-${dia}`;
    const primerDia = `${anio}-01-01`;

    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;
    let sesiones = process.env.REACT_APP_DB;

    const defaultState = {
        reporte: '',
        fechaIni: primerDia,
        fechaFin: hoy,
        clinicaID: '',
        disabledclinicaID: true,
        formasPagoIDs: [],
        disabledformasPagoIDs: true,
        encabezado: false,
        orientacion: "portrait"
    };

    const noAplicaFiltro = 'El filtro no aplica, seleccione un reporte válido';
    const noAplicaFiltroFormaPago = 'El filtro no aplica, seleccione una sucursal válida';

    const defaultSelect = {
        reportes: [{ value: '', label: 'Seleccionar reporte' }],
        clinica: [{ value: '', label: 'Seleccione una sucursal' }],
        formasPago: [{ value: '', label: 'Seleccionar la forma de cobro, si no elige uno se filtra todo' }]
    }

    const [ state, setState ] = useState(defaultState);
    const [ select, setSelect ] = useState(defaultSelect);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);

    const queryFormaPago = () => {
        async function queryFormaPago() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTAR_FORMA_COBRO_FETCH,
                    variables: { clinicaId: Number(state.clinicaID) }
                });

                let { listadoFormaCobro:{ formaCobros }} = resultado;
                let [{ formaCobro }] = formaCobros;
                formaCobro = JSON.parse(formaCobro);
                formaCobro = formaCobro.map(forma => ({ ...forma }));

                return ftSelectFormat(formaCobro, {
                    id: "clave",
                    descripcion: "descripcion"
                });

            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryFormaPago();
    };

    const queryClinica = () => {
        async function queryClinica() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: { limite: 100, activas: null,
                    nombreDB: sesiones}
                });

                let { listadoClinicas:{ clinicas } = {} } = resultado;

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: { valor: (data) => `${data["nombre"]}${data["estatus"] ? "" : " (Inactivo)"}` },
                    otro: [{ id: "estatus", alias: "color", valor: (valor) => valor ? '#212' : '#b71c1c' }]
                }, "Seleccione una sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinica();
    };

	const campos = [{
        id: 'reporte',
        texto: 'Seleccionar reporte *',
        valor: state.reporte,
        opciones: select.reportes,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'fechaIni',
        texto: 'Desde *',
        valor: state.reporte,
        tipo: 'date',
        propiedades: { readOnly: false },
        xs: 4
    },{
        id: 'fechaFin',
        texto: 'Hasta *',
        valor: state.reporte,
        tipo: 'date',
        propiedades: { readOnly: false },
        xs: 4
    },{
        id: 'orientacion',
        texto: "Orientación del reporte",
        valor: state.orientacion,
        opciones: [
            { value: "portrait", label: "Vertical" },
            { value: "landscape", label: "Horizontal" }
        ],
        tipo: "select",
        xs: 4
    },{
        id: 'clinicaID',
        texto: state.disabledclinicaID ? noAplicaFiltro : 'Seleccione una sucursal *',
        valor: state.clinicaID,
        opciones: select.clinica,
        tipo: 'select',
        propiedades: { readOnly: false },
        disabled: state.disabledclinicaID,
        cleanDatoState: [{ id: "formasPagoIDs", value: '' }],
        xs: 12
    },{
        id: 'formasPagoIDs',
        texto: state.disabledformasPagoIDs ? noAplicaFiltroFormaPago : 'Seleccionar la forma de cobro, si no elige uno se filtra todo',
        valor: state.formasPagoIDs,
        opciones: select.formasPago,
        tipo: 'select',
        multiselect: true,
        propiedades: { readOnly: false },
        disabled: state.disabledformasPagoIDs,
        xs: 12
    },{
        id: 'encabezado',
        texto: 'Repetir encabezado en todas las páginas (se omitirá el logotipo configurado)',
        valor: state.encabezado,
        tipo: 'checkbox',
        xs: 12,
        propiedades: { readOnly: false }
    }];

    function ftDisabled(id, value) {
        if(id !== 'reporte') {
            if(id === 'clinicaID') return {
                ...state,
                formasPagoIDs: [],
                disabledformasPagoIDs: (Number(value) === 0 || isNaN(Number(value)))
            };
            return {};
        }
        switch(value) {
            case "FormaCobro":
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: false,
                    formasPagoIDs: [],
                    disabledformasPagoIDs: true
                };
            default:
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: true,
                    formasPagoIDs: [],
                    disabledformasPagoIDs: true
                };
        }
    }

	function handleChange({target}, id, multiselect, onChange, cleanDatoState) {
        let { value, checked } = target, limpiar = {};
        let disabled = ftDisabled(id, value);
        if(id === "encabezado") value = checked;
        if(Array.isArray(cleanDatoState) && state[id] != value) cleanDatoState.forEach(({id, value}) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
        });
        let defaultValue = {
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        };
        setState({
            ...state,
            ...limpiar,
            ...disabled,
            ...defaultValue
        });
        if(onChange) onChange({ ...defaultValue });
    }

    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                let horaIni = "T00:00:00", horaFin = "T23:59:59",
                fechaIni = `${state.fechaIni}${horaIni}`, fechaFin = `${state.fechaFin}${horaFin}`;

                if(!state.reporte) throw new Error('¡Para generar el reporte primero debe seleccionar el reporte!');
                if(!state.clinicaID) throw new Error('¡Para generar el reporte necesita seleccionar una sucursal!');
                if(state.fechaIni === "" || state.fechaFin === "") throw new Error('¡Es necesario seleccionar un rango de fechas!');
                if(timestamp(fechaIni) > timestamp(fechaFin)) throw new Error('¡La fecha inicial debe ser menor a la fecha final!');
                if(ftAnioNoValido(fechaIni) || ftAnioNoValido(fechaFin)) throw new Error('¡El año no tiene un formato válido!');
                if(!ftAniosRangosValidos(fechaIni) || !ftAniosRangosValidos(fechaFin)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');

                let resultado = await FetchAPI({
                    url: `${urlServer}/service/reportes/puntoventa`,
                    params: /*data:*/ {
                        ...state,
                        fechaIni,
                        fechaFin
                    },
                    // method: 'POST',
                    file: false,
                    token
                });

                if(resultado.error) throw new Error(resultado.mensaje);
                if(!resultado) throw new Error('¡Ocurrio un problema, no fue posible obtener el archivo!');
                showFile(resultado.archivo, `${state.reporte}.pdf`);
                setActualizar(true);
                setDisabledAccept(false);
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡El reporte se generó correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
                setDisabledAccept(false);
            }
        }
        click();
    }

    useEffect(() => {
        async function ftEffect() {
            const reportes = [{
                value: '',
                label: 'Seleccionar reporte'
            }, {
                value: 'FormaCobro',
                label: 'Ventas por formas de cobro'
            }];
            const clinica = await queryClinica();
            setSelect({
                ...select,
                reportes,
                clinica
            });
        }
        ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() {
            const formasPago = await queryFormaPago();
            setSelect({
                ...select,
                formasPago
            });
        }
        if(Number(state.clinicaID) > 0 && !isNaN(Number(state.clinicaID))) ftEffect();
        else setSelect({ ...select, formaPago: [] });
    }, [state.clinicaID])

    return(<>
        <Input campos={campos} handleChange={handleChange} state={state} />
        <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Generar Reporte" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
    </>);
}

export default ReportePuntoVenta;
