import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//CORE
import { Button, CardContent, CardActions, Card, Grid, TextField} from '../../../../componentesGenericos/Core';
//CONTEXT
import { CRMContext } from '../../../../context/Perfil';
import { ACTUALIZAR_CEDULA } from '../../../../mutation';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//HELPERS
import {
    verificaValores,
    formatearCadena
} from "../../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../../Helpers/Validacion/ValidacionesCadenasClose";
//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONSTANTES
import { cedula } from './../../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial,mensajeExito } from '../../../../Helpers/constantes';
//ESTILOS
const useStyles = makeStyles({
    root: {
        maxWidth: 345,

    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1: {
        marginBottom: "10px"
    }

});

function Cedula(props) {

    //DECLARACIONES
    const classes = useStyles();
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(cedula);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [boton, setBoton] = useState(false);
    //EVENTOS
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    const EventClickModificar = async () => {
        setBoton(true);
        let campos = [
            "cedula",
        ];
        let datos = verificaValores(campos, perfil.General,[["cedula","cédula"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            setBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchModificar();
            setBoton(false);
        }
    };

    //API
    const FetchModificar= async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        await FetchGrahpQL({
            query: ACTUALIZAR_CEDULA,
            variables: {
                usuarioId:  parseInt(props.usuarioId),
                cedula: perfil.General.cedula.valor
            }
        })
            .then(() => {
                setMensaje({ ...mensaje,...mensajeExito,texto: `Se modificó correctamente  la información` });

            })
            .catch((err) => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };

    //FUNCIONES
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    useEffect(() => {
    }, []);

    return (
        <Card className={classes.mb1}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Cédula</p>
            </div>
            <CardContent>
                <Grid spacing={3} container direction="row">
                    <Grid item sm={12} md={12}>
                        {GenerarNotificacionv2(getNotificacion, "w100")}
                        <TextField margin="dense" name="cedula" id={`cedula`} label="Cédula" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarState}  error={perfil.General.cedula.error} value={perfil.General.cedula.valor} disabled/>
                    </Grid>
                </Grid>
            </CardContent>


            <CardActions>

                {/* <Button size="small" color="primary" onClick={EventClickModificar} disabled={true}>
                    GUARDAR
             </Button> */}
            </CardActions>
        </Card>


    );
}

export default Cedula;
