import React, { useContext, useEffect, useState } from 'react';
import { ftAnioNoValido, ftAniosRangosValidosHoy, ftAniosRangosValidos, ftAniosRangosValidosAlterno } from "../../../../Helpers";
import clsx from 'clsx';
//Iconos
import { VisibilityIcon, VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, InputAdornment, IconButton } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//Rgx
import { rgxEmail, rgxtel, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Usuarios(props) {

    //Declaraciones
    let { seleccion } = props;
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxPasswords = new RegExp(rgxPassword);
    let rgxLetra = new RegExp(rgxLetras);
    let rgxTelefono = new RegExp(rgxtel);
    const [sexo, setSexo] = React.useState();
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || ftAniosRangosValidosHoy(value) == false || ftAnioNoValido(value) === true) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value) && value.length > 1) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                [name]: {
                    ...datos.Usuario[name],
                    error: false, valor: value
                }
            }
        });
    }
    const handleChangeGuardarStateCorreo = event => {
        const { name, value } = event.target;
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }
    const handleChangeGuardarTelefono = event => {
        const { name, value } = event.target;
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }
    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                showPassword: {
                    ...datos.Usuario.showPassword,
                    error: !datos.Usuario.showPassword.error
                }
            }
        });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Usuario: {
                    ...datos.Usuario,
                    [name]: {
                        ...datos.Usuario[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    //API
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //FUNCIONES
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<option key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</option>);
                return true;
            });
            return row;
        }
    };


    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                nombres: {
                    ...datos.Usuario.nombres,
                    error: false, valor: seleccion.nombres
                },
                apellidos: {
                    ...datos.Usuario.apellidos,
                    error: false, valor: seleccion.apellidos
                },
                email: {
                    ...datos.Usuario.email,
                    error: false, valor: seleccion.email
                },
                sexo: {
                    ...datos.Usuario.sexo,
                    error: false, valor: seleccion.sexo
                },
                usuarioId: {
                    ...datos.Usuario.usuarioId,
                    error: false, valor: seleccion.usuarioId
                },
                fechaEgreso: {
                    ...datos.Usuario.fechaEgreso,
                    error: false, valor: seleccion.fechaEgreso
                },
                telefono: {
                    ...datos.Usuario.telefono,
                    error: false, valor: seleccion.telefono
                },
                fechaNacimiento: {
                    ...datos.Usuario.fechaNacimiento,
                    error: false, valor: seleccion.fechaNacimiento
                }

            }
        });
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Usuario.nombres.error} defaultValue={seleccion.nombres} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Usuario.apellidos.error} defaultValue={seleccion.apellidos} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} onChange={handleChangeGuardarStateCorreo} error={datos.Usuario.email.error} defaultValue={seleccion.email}  />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel  error={datos.Usuario.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Usuario.password.error}
                                    type={datos.Usuario.showPassword.error ? 'text' : 'password'}
                                    value={datos.Usuario.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Usuario.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como mínimo 8 caracteres y máximo 30 caracteres (Es un campo obligatorio) </FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Usuario.telefono.error} defaultValue={seleccion.telefono} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%" }}>
                                <InputLabel htmlFor="demo-dialog-native" required={true}>Género</InputLabel>
                                <Select
                                    name="sexo"
                                    native
                                    onChange={handleChangeGuardarStateSexo}
                                    input={<Input id="demo-dialog-native" />}
                                    value={datos.Usuario.sexo.valor}
                                >
                                    <option selected value="null" disabled>Escoge el género </option>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    style={{ width: "100%" }}
                                    name="fechaNacimiento"
                                    id="date"
                                    required={true}
                                    label="Fecha de nacimiento"
                                    type="date"
                                    helperText="La fecha de nacimiento es obligatoria"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    error={datos.Usuario.fechaNacimiento.error}
                                    defaultValue={seleccion.fechaNacimiento != null ? (seleccion.fechaNacimiento).split("T")[0] : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Usuarios;


