import gql from 'graphql-tag';

//#region =========ServiciosDoctores=============
const OBTENER_SERVICIOS_DOCTORES = gql`
query obtenerServiciosDoctores($id:Int!){
    ObtenerServicioDoctor(id:$id){
      relacionId
      servicioId
      serviciosId
      descripcion
      precioUnitario
      descuento
      IVA
      porcentajeIVA
      comision
      agendable
      usuarioId
      nombres
      apellidos
      email
      cedula
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      especialidadId
      descripcionEspecialidad
    }
  }
`;

const OBTENER_DOCTORES_SERVICIO = gql`
query obtenerDoctoresServicio($clinica:Int!,$servicio:Int, $doctor:Int,$estatus:Int){
    ObtenerDoctoresServicio(clinica:$clinica,servicio:$servicio,doctor:$doctor,estatus:$estatus){
        serviciosDoctores{
        relacionId
        servicioId
        descripcion
        usuarioId
        nombreCompleto
        email
        cedula
        estatus
        }
    }
}
`;

const LISTADO_SERVICIOS_DOCTORES = gql`
query ListadoServiciosDoctores($pagina: Int, $limite:Int, $clinicaId: Int){
    listadoServiciosDoctores(pagina:$pagina,limite:$limite, clinicaId: $clinicaId){
      serviciosDoctores{
            relacionIdGeneral
            clinicaDoctorId
            servicioId
            clinicaId
            nombreClinica
            nombreCompleto
            serviciosId
            servicios
            descripcion
            precioUnitario
            descuento
            IVA
            porcentajeIVA
            comision
            agendable
            usuarioId
            nombres
            apellidos
            email
            cedula
            fechaEgreso
            fechaAlta
            estatus
            sexo
            tarjetaQr
            especialidadId
            descripcionEspecialidad
      }
      registros
      paginas
    }
  }`;
//#endregion
//=======================================

export {
    OBTENER_SERVICIOS_DOCTORES,
    OBTENER_DOCTORES_SERVICIO,
    LISTADO_SERVICIOS_DOCTORES
}
