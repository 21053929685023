import React from 'react'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

function Columnas({ columnas,claseEncabezado }) {
    return (
        <TableHead style={{maxHeight:'20px'}}>
            <TableRow>
                {columnas.map(column => (
                    <TableCell
                        key={column.id}
                        align={column.align || 'center'}
                        style={{ minWidth: column.minWidth, display: column.hide ? "none" : '', maxWidth: column.maxWidth, width: column.width, fontSize: column.fontsize,...column.headerStyle,...claseEncabezado }}
                    >
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    )
}

export default Columnas
