import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input from './Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';
import { FetchGrahpQL, FetchAPI } from '../../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat, urlServer, showFile, ftAnioNoValido, ftAniosRangosValidos, timestamp } from '../../../Helpers';

import { SELECT_CLINICAS_FETCH, LISTADO_DOCTOR_ASIGNADOS } from '../../../query';
import {LISTADO_TIPO_SERVICIOS} from '../../../query/componentes/ProductosServicios';

import { useSelector } from "react-redux";
import { CRMContext } from '../../../context/Reporte';

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));
export default function ReporteCredito({setMensaje}){
    const classes = useStyles();

    const hoyDate = new Date();
    let dia = hoyDate.getDate();
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    let mes = hoyDate.getMonth() + 1;
    mes = mes < 10 ? `0${mes}` : mes;
    let anio = hoyDate.getFullYear();
    const hoy = `${anio}-${mes}-${dia}`;
    const primerDia = `${anio}-01-01`;

    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;

    const defaultState = {
        reporte: '',
        fechaIni: primerDia,
        fechaFin: hoy,
        clinicaID: '',
        disabledclinicaID: true,
        encabezado: false,
        orientacion: "portrait",
        tipo:'',
        disabledTipo: true,
        idDoctor:'',
        disabledIdDoctor:true
    };

    const noAplicaFiltro = 'El filtro no aplica, seleccione un reporte válido';

    const defaultSelect = {
        reportes: [{ value: '', label: 'Seleccionar reporte' }],
        clinica: [{ value: '', label: 'Seleccione una sucursal' }],
        tipo: [{ value: '', label: 'Seleccione el tipo de servicio' }],
        idDoctor:[{ value: '', label: 'Seleccione al profesional' }]

    }

    const [ state, setState ] = useState(defaultState);
    const [ select, setSelect ] = useState(defaultSelect);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);

    const queryClinica = () => {
        async function queryClinica() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: { limite: 100, activas: null }
                });

                let { listadoClinicas:{ clinicas } = {} } = resultado;

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: { valor: (data) => `${data["nombre"]}${data["estatus"] ? "" : " (Inactivo)"}` },
                }, "Seleccione una sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinica();
    };

    const queryTipo = () => {
        async function queryTipo() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTADO_TIPO_SERVICIOS,
                    variables: {  }
                });

                let { listadoTipoServicios:{ tiposProductos } = {} } = resultado;

                return ftSelectFormat(tiposProductos, {
                    id: "Id",
                    descripcion:"tipo"
                });

            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryTipo();
    };

    const queryDoctor = () => {
        async function queryDoctor() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTADO_DOCTOR_ASIGNADOS,
                    variables: {
                        id:parseInt(state.clinicaID),
                        correo:0,
                        estatus:0
                    }
                });

                let { obtenerDoctoresAsignadosClinicas:{ clinicaDoctores } = {} } = resultado;

                return ftSelectFormat(clinicaDoctores, {
                    id: "doctorId",
                    descripcion:{ valor: (data) => `${data["nombreCompleto"]} (${data["email"] })` }
                });

            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryDoctor();
    };



    const campos = [{
        id: 'reporte',
        texto: 'Seleccionar reporte *',
        valor: state.reporte,
        opciones: select.reportes,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'orientacion',
        texto: "Orientación del reporte",
        valor: state.orientacion,
        opciones: [
            { value: "portrait", label: "Vertical" },
            { value: "landscape", label: "Horizontal" }
        ],
        tipo: "select",
        xs: 4
    },

    ,{
        id: 'encabezado',
        texto: 'Repetir encabezado en todas las páginas (se omitirá el logotipo configurado)',
        valor: state.encabezado,
        tipo: 'checkbox',
        xs: 12,
        propiedades: { readOnly: false }
    }];

    function ftDisabled(id, value) {
        if(id !== 'reporte') {
            if(id === 'clinicaID') return {
                ...state,
                // formasPagoIDs: [],
                // disabledformasPagoIDs: (Number(value) === 0 || isNaN(Number(value)))
                disabledIdDoctor: (Number(value) === 0 || isNaN(Number(value)))
            };
            return {};
        }
        switch(value) {
            case "Comisiones":
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: false,
                    // formasPagoIDs: [],
                    // disabledformasPagoIDs: true,
                    disabledTipo: false,
                    disabledIdDoctor:false
                };
            default:
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: true,
                    // formasPagoIDs: [],
                    // disabledformasPagoIDs: true,
                    disabledTipo: true,
                    disabledIdDoctor:true
                };
        }
    }

	function handleChange({target}, id, multiselect, onChange, cleanDatoState) {
        let { value, checked } = target, limpiar = {};
        let disabled = ftDisabled(id, value);
        if(id === "encabezado") value = checked;
        if(Array.isArray(cleanDatoState) && state[id] != value) cleanDatoState.forEach(({id, value}) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
        });
        let defaultValue = {
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        };
        setState({
            ...state,
            ...limpiar,
            ...disabled,
            ...defaultValue
        });
        if(onChange) onChange({ ...defaultValue });
    }

    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                let horaIni = "T00:00:00", horaFin = "T23:59:59",
                fechaIni = `${state.fechaIni}${horaIni}`, fechaFin = `${state.fechaFin}${horaFin}`;

                if(!state.reporte) throw new Error('¡Para generar el reporte primero debe seleccionar el reporte!');
                // if(state.clinicaID===0) throw new Error('¡Para generar el reporte necesita seleccionar una sucursal!');
                // if(!state.clinicaID) throw new Error('¡Para generar el reporte necesita seleccionar una sucursal!');
                // if(state.idDoctor === "" ) throw new Error('¡Para generar el reporte necesita seleccionar un profesional!');
                if(state.fechaIni === "" || state.fechaFin === "") throw new Error('¡Es necesario seleccionar un rango de fechas!');
                if(timestamp(fechaIni) > timestamp(fechaFin)) throw new Error('¡La fecha inicial debe ser menor a la fecha final!');
                if(ftAnioNoValido(fechaIni) || ftAnioNoValido(fechaFin)) throw new Error('¡El año no tiene un formato válido!');
                if(!ftAniosRangosValidos(fechaIni) || !ftAniosRangosValidos(fechaFin)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');

                let resultado = await FetchAPI({
                    url: `${urlServer}/service/reportes/clientesMorosos`,
                    params: /*data:*/ {
                        ...state,
                        fechaIni,
                        fechaFin
                    },
                    // method: 'POST',
                    file: false,
                    token
                });
                if(resultado.error) throw new Error(resultado.mensaje);
                if(!resultado) throw new Error('¡Ocurrió un problema, no fue posible obtener el archivo!');

                showFile(resultado.archivo, `${state.reporte}.pdf`);
                setActualizar(true);
                setDisabledAccept(false);
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡El reporte se generó correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
                setDisabledAccept(false);
            }
        }
        click();
    }

    useEffect(() => {
        async function ftEffect() {
            const reportes = [{
                value: '',
                label: 'Seleccionar reporte'
            }, {
                value: 'Clientesmorosos',
                label: 'Usuarios morosos'
            }];
            const clinica = await queryClinica();
            const tipo = await queryTipo();
            setSelect({
                ...select,
                reportes,
                clinica,
                tipo
            });
        }
        ftEffect();
    }, []);

    useEffect(() => {


        async function ftEffect() {
            const idDoctor= await queryDoctor();
            setSelect({
                ...select,
                idDoctor
            });
        }
        if(Number(state.clinicaID) > 0 && !isNaN(Number(state.clinicaID))) ftEffect();
        else setSelect({ ...select, idDoctor: [] });


    }, [state.clinicaID])

    return(<>
        <Input campos={campos}
        handleChange={handleChange}
        state={state}
         />
        <Botones classes={classes.Btn}
        txtBotones={{ btnAceptar: "Generar Reporte" }}
        onAccept={handleClickAccept}
        disabledAccept={disabledAccept}
        />
    </>);
}
