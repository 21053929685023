import React, { useState, createRef, useContext, useEffect } from 'react';
//Apollo
import { /*useQuery,*/ useMutation } from '@apollo/react-hooks';
// FullCalendar
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
//import bootstrapPlugin from '@fullcalendar/bootstrap';
import esLocal from '@fullcalendar/core/locales/es';
//import { toMoment } from '@fullcalendar/moment';
//import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

//Mobile stepperf
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils'
//import '@fullcalendar/bootstrap/main.css';
import './agenda.css';
import { urlServer, ftFormatDateSpanish, diasFormato, horaFormato } from '../../Helpers';
import { TipoUsuario } from '../../Helpers/Enums';
import Upsert from './Upsert';
import Graficas from './Graficas';
import Mensaje from '../../componentesGenericos/Mensaje';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
import { LISTADO_CLINICA_BONITO_FETCH } from '../../query';
import { VALIDAR_GENERAR_RECETA_FETCH } from '../../query/componentes/Agenda';
import { HORARIO_DOCTOR_FETCH } from '../../query/componentes/Horarios';
import { NUEVO_EVENTO, MODIFICAR_EVENTO, MODIFICAR_TIME_EVENTO, ELIMINAR_EVENTO, NUEVO_TICKET_TEMPORAL_AGENDA } from '../../mutation';
import { CRMContext } from '../../context/Agenda';
import { limpiarEvento } from './constantes';
//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
import { ftValidarCampos } from '../../Helpers/constantes/Config/Helper';
import { rgxLetras } from '../../Helpers/constantes/Rgx'
import { trim } from '../../Helpers';
//Notificaciones
import { useSendNotification } from '../Notificaciones/funcionesNotificacion';
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';
//Router
import { Redirect } from 'react-router-dom';

import useMediaQuerys from '../../componentesGenericos/MediaQuerys';

import uuid from 'uuid';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function ftFormatearURLAgenda(object, token) {

    let {
        clinicaID = null,
        clinicaDoctoID = null,
        doctorID = null,
        usuarioID = null,
        pacienteID = null,
        estatusID = null
    } = object,
        filter = {
            clinicaID,
            clinicaDoctoID,
            doctorID,
            usuarioID,
            pacienteID,
            estatusID
        },
        keys = Object.keys(filter),
        urlEvent = `${urlServer}/agenda/${token}`,
        count = 0;

    keys.forEach(key => {
        if (filter[key]) {
            urlEvent += `${count === 0 ? "?" : "&"}${key}=${filter[key]}`;
            count++;
        }
    });

    return urlEvent;
}

function FiltroAgenda({ usuarioId, tipo }, _clinicaID) {
    switch (tipo) {
        case TipoUsuario.CLIENTE.toNumber(): return { pacienteID: usuarioId };
        case TipoUsuario.ADMINISTRADOR.toNumber(): return { clinicaID: _clinicaID ? _clinicaID : null };
        case TipoUsuario.PROFESIONAL.toNumber(): return {
            doctorID: usuarioId,
            clinicaID: _clinicaID ? _clinicaID : null
        };
        case TipoUsuario.USUARIO.toNumber(): return {
            usuarioID: usuarioId,
            clinicaID: _clinicaID ? _clinicaID : null
        };
        default: return {}
    }
}
const contentStyle = { background: 'black', Color: 'white', width: 'auto', height: 'auto', zIndex: '1000', alignItems: 'center' };
const overlayStyle = { color: 'white', alignItems: 'center', zIndex: '1000' };
const arrowStyle = { color: 'white', alignItems: 'center', zIndex: '1000' }; // style for an svg element

function Agenda({ usuario, configuracion, _clinicaID, token }) {

    const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

    const tutorialSteps = [
        {
            label: 'Encuentra tu sucursal más cercana',
            imgPath:
                '/images/Ubicaciones.gif',
        },
        {
            label: 'Agenda tu cita',
            imgPath:
                'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
        },
    ];

    const useStyles = makeStyles((theme) => ({
        root: {
            maxWidth: '230px',
            //   flexGrow: 1,
            alignItems: 'center',
            zIndex: '1000'
        },
        header: {
            zIndex: '1000',
            maxWidth: '230px',
            display: 'flex',
            alignItems: 'center',
            //   height: 50,
            paddingLeft: theme.spacing(4),
            backgroundColor: theme.palette.background.default,
        },
        img: {
            textAlign: 'center',
            alignItems: 'center',
            maxHeight: '280px',
            display: 'block',
            //   maxWidth: '270px',
            width: '230px',
            overflow: 'hidden',
        },
    }));

    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const AddNotificacion = useSendNotification();

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    const { precisionAgendaFormat = "00:15:00", tiempoIniAgenda = "09:00:00", tiempoFinAgenda = "20:00:00", permitirModificarHorarioProfesional = false, permitirModificarHorarioCliente = false, permitirAgendarClientes = false } = configuracion;

    const tituloPredeterminado = "Cita agendada por el cliente";

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(usuario.tipo),
        bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(usuario.tipo),
        bTipoAdministrador = TipoUsuario.ADMINISTRADOR.isEqual(usuario.tipo),

        urlEvent = ftFormatearURLAgenda(FiltroAgenda(usuario, _clinicaID), token),

        calendarRef = createRef(),

        [addEvento] = useMutation(NUEVO_EVENTO, {
            onError: ({ message }) => {
                throw new Error(message.replace("GraphQL error:", ""));
            },
            onCompleted: (e) => {
                setDisabledAccept(no);
                setMensaje({ ...mensaje, ...mensajeExito, texto: "Se agregó  correctamente el evento", newComponente: null });
                actualizarAgenda();
            }
        }),

        [UpdateEvento] = useMutation(MODIFICAR_EVENTO, {
            onError: ({ message }) => {
                throw new Error(message.replace("GraphQL error:", ""));
            },
            onCompleted: (e) => {
                setDisabledAccept(no);
                setMensaje({ ...mensaje, ...mensajeExito, texto: "Se actualizó correctamente el evento", newComponente: null });
                actualizarAgenda();
            }
        }),

        [UpdateTimeEvento] = useMutation(MODIFICAR_TIME_EVENTO, {
            onError: ({ message }) => {
                throw new Error(message.replace("GraphQL error:", ""));
            },
            onCompleted: (e) => {
                setMensaje({ ...mensaje, ...mensajeExito, texto: "Se actualizó correctamente el evento" });
            }
        }),

        [DeleteEvento] = useMutation(ELIMINAR_EVENTO, {
            onError: ({ message }) => {
                setMensaje({ ...mensaje, ...mensajeError, texto: message.replace("GraphQL error:", ""), newComponente: null });
                actualizarAgenda();
            },
            onCompleted: (e) => {
                setMensaje({ ...mensaje, ...mensajeExito, texto: "Se eliminó correctamente el evento", newComponente: null });
                actualizarAgenda();
            }
        }),

        plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin/*, bootstrapPlugin*/],

        si = true, no = false,

        [mensaje, setMensaje] = useState(mensajeInicial),
        [disabledAccept, setDisabledAccept] = useState(no),
        [disabledDelete, setDisabledDelete] = useState(no),
        [estatusPredeterminadoID, setEstatusPredeterminadoID] = useState(no),
        [clinicas, setClinicas] = useState({}),
        [horariosDoctor, setHorariosDoctor] = useState({}),
        [open, setOpen] = useState(no),
        [evento, setEvento] = useContext(CRMContext),
        [agenda, setAgenda] = useState({
            calendarWeekends: si,
            timeZone: 'America/Mexico_City',
            defaultView: responsive ? 'timeGridDay' : 'timeGridWeek',
            allDayText: 'Todo el día',
            slotDuration: precisionAgendaFormat,
            minTime: tiempoIniAgenda,
            maxTime: tiempoFinAgenda,
            events: urlEvent,
            //themeSystem: 'bootstrap',
            //theme: 'minty',
            slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: si,
                meridiem: 'short'
            },
            headerCalendar: {
                left: 'prev,next today',
                center: 'title',
                right: responsive ? 'timeGridDay' : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            },
            buttonText: {
                month: "Mes",
                week: "Semana",
                day: "Día",
                list: "Listado",
                today: "Hoy"
            },
            delayTouch: 500,
            calendarEvents: []
        }),
        [partida, setPartida] = useState({ redirigirPuntoVenta: false }),
        handleConsole = () => {
            console.log("Prueba");
        },

        handleDateClick = ({ event: { start, end, title, _def: props } }) => {
            let inicio = start.getHours() + start.getMinutes() + start.getSeconds();
            let fin = end.getHours() + end.getMinutes() + end.getSeconds();
            if (inicio === 0 && fin === 0) throw new Error("¡Lo sentimos! No es posible agendar una cita en la vista por Mes");
            let { extendedProps } = props;
            setEvento(evento => ({
                ...evento,
                ...extendedProps,
                estatusID: bTipoPaciente && !Number(extendedProps.estatusID) ? estatusPredeterminadoID : extendedProps.estatusID,
                title: bTipoPaciente && !title ? tituloPredeterminado : title,
                start,
                end
            }));
            setOpen(si);
        },

        handleSelect = ({ start, end }) => {
            try {
                let inicio = start.getHours() + start.getMinutes() + start.getSeconds();
                let fin = end.getHours() + end.getMinutes() + end.getSeconds();
                if (inicio === 0 && fin === 0) throw new Error("¡Lo sentimos! No es posible agendar una cita en la vista por Mes");
                setEvento({
                    ...limpiarEvento,
                    estatusID: bTipoPaciente ? estatusPredeterminadoID : 0,
                    title: bTipoPaciente ? tituloPredeterminado : "",
                    start,
                    end
                });
                setOpen(si);
            } catch ({ message: texto }) { setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null }); }
        },

        handleDrop = ({ event: { start: fechaIni, end: fechaFin, _def: props } }) => {
            let { extendedProps: { agendaID, pacienteID, doctorID, clinicaID, clinicaDoctoID }, title } = props;
            async function drop() {
                try {

                    if ((bTipoPaciente && !permitirModificarHorarioCliente) || (bTipoDoctor && !permitirModificarHorarioProfesional)) throw new Error("No cuenta los permisos para la modificación del horario definido");

                    let { data: { actualizarTimeEvento } } = await UpdateTimeEvento({
                        variables: {
                            fechaIni,
                            fechaFin,
                            agendaID
                        }
                    });
                    let { start, end } = actualizarTimeEvento;
                    await notificacionDoctorPaciente({
                        title,
                        pacienteID,
                        doctorID: bTipoDoctor ? usuario.usuarioId : doctorID,
                        fechaIni: new Date(start.replace(".000Z", "")),
                        fechaFin: new Date(end.replace(".000Z", ""))
                    }, false, true);
                } catch ({ message: texto }) {
                    const newComponente = horariosDisponibles(texto, clinicaID, clinicaDoctoID);
                    setMensaje({ ...mensaje, ...mensajeError, texto: texto.replace("[HorarioInvalido] ", "").replace("[HorarioProfesionalInvalido]", ""), newComponente });
                    actualizarAgenda();
                }
            }
            return drop();
        },

        handleResize = ({ event: { start: fechaIni, end: fechaFin, _def: props } }) => {
            async function resize() {
                try {

                    if ((bTipoPaciente && !permitirModificarHorarioCliente) || (bTipoDoctor && !permitirModificarHorarioProfesional)) throw new Error("No cuenta los permisos para la modificación del horario definido");

                    let { extendedProps: { agendaID, pacienteID, doctorID }, title } = props;
                    let { data: { actualizarTimeEvento } } = await UpdateTimeEvento({
                        variables: {
                            fechaIni,
                            fechaFin,
                            agendaID
                        }
                    });
                    let { start, end } = actualizarTimeEvento;
                    await notificacionDoctorPaciente({
                        title,
                        pacienteID,
                        doctorID: bTipoDoctor ? usuario.usuarioId : doctorID,
                        fechaIni: new Date(start.replace(".000Z", "")),
                        fechaFin: new Date(end.replace(".000Z", ""))
                    }, false, true);
                } catch ({ message: texto }) {
                    setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null });
                    actualizarAgenda();
                }
            }
            return resize();
        },

        notificacionDoctorPaciente = ({ title, pacienteID, doctorID, estatus, fechaIni, fechaFin, eliminar }, nuevo = true, time = false) => {
            let fecha = new Date();
            fecha = fecha.toISOString();
            fecha = fecha.split("T")[0];
            let mensaje;
            if (eliminar) mensaje = `${title} ha sido eliminada`;
            else {
                if (time) mensaje = `${title} ha sido modificada y su nueva fecha es del ${ftFormatDateSpanish(fechaIni)} al ${ftFormatDateSpanish(fechaFin)}`;
                else mensaje = `${title} ha sido ${nuevo ? 'agendada' : 'modificada'} con el estatus ${estatus} y su fecha es del ${ftFormatDateSpanish(fechaIni)} al ${ftFormatDateSpanish(fechaFin)}`;

            }
            async function Notificacion() {
                // Notificación Paciente
                let { ok: okP, msj: msjP } = await AddNotificacion({
                    mensaje, usuarioId: Number(pacienteID), fecha, diasRepeticion: 0, estatus: 1
                });
                if (!okP) console.error(msjP);
                // Notificación Doctor
                let { ok: okD, msj: msjD } = await AddNotificacion({
                    mensaje, usuarioId: Number(doctorID), fecha, diasRepeticion: 0, estatus: 1
                });
                if (!okD) console.error(msjD);
            }
            return Notificacion();
        },

        agregarEvento = ({ cleanStateFormulario, queryNoAgendar }) => {
            async function agregarEvento() {
                try {

                    setDisabledAccept(si);

                    let pacienteID, title = trim(evento.title);

                    pacienteID = bTipoPaciente ? usuario.usuarioId : evento.pacienteID;

                    if (!ftValidarCampos([evento.clinicaDoctoID, pacienteID, evento.estatusID, title, evento.start, evento.end])) throw new Error("Algunos campos no deben ir vacios");

                    if (!bTipoPaciente) {
                        if (evento.servicioDoctorID == 0 || evento.servicioDoctorID.length === 0 || (evento.servicioDoctorID.length === 1 && evento.servicioDoctorID[0] === 0)) throw new Error("Debe elegir un servicio válido");
                    } else {
                        if (evento.servicioDoctorID == 0 || evento.servicioDoctorID.length === 0 || (evento.servicioDoctorID.length === 1 && evento.servicioDoctorID[0] === 0)) throw new Error("La sucursal no cuenta con Doctor o servicio configurado");
                    }

                    if (!rgxLetras.test(title)) throw new Error("La descripción del título sólo permite la captura de letras");

                    const { data: { agregarEvento } } = await addEvento({
                        variables: {
                            evento: {
                                clinicaDoctoID: evento.clinicaDoctoID,
                                pacienteID,
                                estatusID: evento.estatusID,
                                servicioDoctorID: evento.servicioDoctorID,
                                titulo: title,
                                descripcion: trim(evento.descripcion),
                                fechaIni: evento.start,
                                fechaFin: evento.end
                            }
                        }
                    });

                    const { redirigirPuntoVenta, descripcionEstatus, start, end } = agregarEvento;

                    await notificacionDoctorPaciente({
                        title,
                        pacienteID,
                        doctorID: bTipoDoctor ? usuario.usuarioId : evento.doctorID,
                        estatus: descripcionEstatus,
                        fechaIni: new Date(start.replace(".000Z", "")),
                        fechaFin: new Date(end.replace(".000Z", ""))
                    });

                    setDisabledAccept(no);
                    close();

                    if (cleanStateFormulario) cleanStateFormulario();
                    if (bTipoPaciente) queryNoAgendar();

                    if (redirigirPuntoVenta) {
                        const { clinicaID: clinicaId, doctorID: doctorId, pacienteID: pacienteId, servicioID: servicioId, agendaID: agendaId } = agregarEvento;

                        setPartida({ ...partida, clinicaId, doctorId, pacienteId, servicioId, agendaId, redirigirPuntoVenta })
                        //TODO variables
                    } else {
                        setPartida({ ...partida, redirigirPuntoVenta })
                    }

                } catch ({ message: texto }) {
                    setDisabledAccept(no);
                    const newComponente = horariosDisponibles(texto, evento.clinicaID, evento.clinicaDoctoID);
                    setMensaje({ ...mensaje, ...mensajeError, texto: texto.replace("[HorarioInvalido] ", "").replace("[HorarioProfesionalInvalido]", ""), newComponente });
                }
            } agregarEvento();
        },

        actualizarEvento = ({ cleanStateFormulario, cleanStateEvento }) => {
            async function actualizarEvento() {
                try {

                    setDisabledAccept(si);

                    if (bTipoPaciente) throw new Error("Lamentablemente su tipo de usuario no tiene permitido realizar la modificación");

                    let title = trim(evento.title);

                    if (!ftValidarCampos([evento.agendaID, evento.clinicaDoctoID, evento.pacienteID, evento.estatusID, title, evento.start, evento.end])) throw new Error("Algunos campos no deben ir vacios");

                    if (!bTipoPaciente) {
                        if (evento.servicioDoctorID === 0 || evento.servicioDoctorID.length === 0 || (evento.servicioDoctorID.length === 1 && evento.servicioDoctorID[0] === 0)) throw new Error("Debe elegir un servicio válido");
                    } else {
                        if (evento.servicioDoctorID === 0 || evento.servicioDoctorID.length === 0 || (evento.servicioDoctorID.length === 1 && evento.servicioDoctorID[0] === 0)) throw new Error("La sucursal no tiene doctor o servicio configurados");
                    }
                    if (!rgxLetras.test(title)) throw new Error("La descripción del título sólo permite la captura de letras");


                    const { data: { actualizarEvento } } = await UpdateEvento({
                        variables: {
                            evento: {
                                agendaID: evento.agendaID,
                                clinicaDoctoID: evento.clinicaDoctoID,
                                pacienteID: evento.pacienteID,
                                estatusID: evento.estatusID,
                                servicioDoctorID: evento.servicioDoctorID,
                                titulo: title,
                                descripcion: trim(evento.descripcion),
                                fechaIni: evento.start,
                                fechaFin: evento.end
                            }
                        }
                    });

                    const { redirigirPuntoVenta, descripcionEstatus, start, end } = actualizarEvento;

                    await notificacionDoctorPaciente({
                        title,
                        pacienteID: evento.pacienteID,
                        doctorID: bTipoDoctor ? usuario.usuarioId : evento.doctorID,
                        estatus: descripcionEstatus,
                        fechaIni: new Date(start.replace(".000Z", "")),
                        fechaFin: new Date(end.replace(".000Z", ""))
                    }, false);

                    setDisabledAccept(no);
                    close();

                    if (cleanStateFormulario) cleanStateFormulario();
                    //if (cleanStateEvento) cleanStateEvento();

                    if (redirigirPuntoVenta) {
                        const { clinicaID: clinicaId, doctorID: doctorId, pacienteID: pacienteId, servicioID: servicioId, agendaID: agendaId } = actualizarEvento;

                        setPartida({ ...partida, clinicaId, doctorId, pacienteId, servicioId, agendaId, redirigirPuntoVenta })
                    } else {
                        setPartida({ ...partida, redirigirPuntoVenta })
                    }
                    //setEvento(...limpiarEvento)

                } catch ({ message: texto }) {
                    setDisabledAccept(no);
                    const newComponente = horariosDisponibles(texto, evento.clinicaID, evento.clinicaDoctoID);
                    setMensaje({ ...mensaje, ...mensajeError, texto: texto.replace("[HorarioInvalido] ", "").replace("[HorarioProfesionalInvalido]", ""), newComponente });
                }
            } actualizarEvento();
        },

        queryValidarGenerarReceta = (id) => {
            async function queryValidarGenerarReceta() {
                try {
                    let resultado = await FetchGrahpQL({
                        query: VALIDAR_GENERAR_RECETA_FETCH,
                        variables: { id }
                    });

                    let { ValidarGenerarReceta = false } = resultado;

                    return ValidarGenerarReceta;

                } catch ({ message: label }) { throw new Error(label); }
            }
            return queryValidarGenerarReceta();
        },

        GenerarReceta = ({ agendaID }) => {
            async function GenerarReceta() {
                setDisabledAccept(si);
                try {
                    if (!agendaID) agendaID = evento.agendaID;
                    if (!ftValidarCampos([agendaID])) throw new Error("No se encuentra el ID de la Agenda");

                    let genValido = await queryValidarGenerarReceta(evento.pacienteID);

                    if (!genValido) throw new Error("En estos momentos no es posible generar una receta");

                    window.location.href = "/receta/" + evento.agendaID + "/" + evento.clinicaDoctoID + "/" + evento.pacienteID + "/" + evento.clinicaID

                } catch ({ message: texto }) {
                    setDisabledAccept(no);
                    setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null });
                }
            } GenerarReceta();
        },

        eliminarEvento = ({ agendaID }) => {
            async function eliminarEvento() {
                try {
                    setDisabledAccept(si);
                    setDisabledDelete(si);

                    if (!agendaID) agendaID = evento.agendaID;

                    if (!ftValidarCampos([agendaID])) throw new Error("No se encuentra el ID de la Agenda");

                    let mensaje = await DeleteEvento({ variables: { agendaID: Number(agendaID) } });

                    if (!mensaje) {
                        setDisabledAccept(no);
                        setDisabledDelete(no);
                        return;
                    }

                    await notificacionDoctorPaciente({
                        title: evento.title,
                        pacienteID: evento.pacienteID,
                        doctorID: bTipoDoctor ? usuario.usuarioId : evento.doctorID,
                        estatus: evento.descripcionEstatus,
                        eliminar: true
                    }, false);

                    setDisabledAccept(no);
                    setDisabledDelete(no);
                    close();

                } catch ({ message: texto }) {
                    setDisabledAccept(no);
                    setDisabledDelete(no);
                    setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null });
                }
            }
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: "¿Está seguro de eliminar el evento?", onAccept: () => eliminarEvento() });
        },

        actualizarAgenda = () => {
            setAgenda({ ...agenda, events: [] });
            setTimeout(() => setAgenda({
                ...agenda,
                events: urlEvent
            }), 500);
        },

        // eventRender = (info) => {
        //     if(info.el.tagName === "A") {
        //         let tooltipx = document.createElement("div");
        //         tooltipx.innerHTML = `<div class="tooltip__content">
        //             <p><em style="color: ${info.event._def.extendedProps.colorEstatus};">${info.event._def.extendedProps.descripcionEstatus}</em></p>
        //             <p><em>Doctor:</em> ${info.event._def.extendedProps.nombresDoctor} ${info.event._def.extendedProps.apellidosDoctor}</p>
        //             <p><em>Clínica:</em> ${info.event._def.extendedProps.nombreClinica}</p>
        //         </div>`;
        //         info.el.querySelector(".fc-content").classList.add("tooltip");
        //         info.el.querySelector(".fc-content").append(tooltipx);
        //         return info.el;
        //     }
        // },
        close = () => {
            setOpen(no);
            setEvento({
                ...limpiarEvento,
                estatusID: bTipoPaciente ? estatusPredeterminadoID : 0,
                title: bTipoPaciente ? tituloPredeterminado : ""
            });
        },
        ftMensajeClose = () => setMensaje({ ...mensaje, abrir: no, newComponente: null });

    const queryListadoClinica = () => {
        async function queryListadoClinica() {
            try {
                let resultado = await FetchGrahpQL({ query: LISTADO_CLINICA_BONITO_FETCH });
                let { listadoClinicaDireccionActivas } = resultado;
                setClinicas(listadoClinicaDireccionActivas);
            } catch ({ message: texto }) {
                setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null });
            }
        }
        return queryListadoClinica();
    };

    const queryListadoHorarioDoctor = () => {
        async function queryListadoHorarioDoctor() {
            try {
                let resultado = await FetchGrahpQL({ query: HORARIO_DOCTOR_FETCH });
                let { listadoHorarioDoctorCute } = resultado;
                setHorariosDoctor(listadoHorarioDoctorCute);
            } catch ({ message: texto }) {
                setMensaje({ ...mensaje, ...mensajeError, texto, newComponente: null });
            }
        }
        return queryListadoHorarioDoctor();
    };

    const horariosDisponibles = (texto, clinicaID, clinicaDoctoID) => {
        let clinicaInvalida = /\[HorarioInvalido\]/.test(texto);
        let profesionalInvalido = /\[HorarioProfesionalInvalido\]/.test(texto);
        if (!clinicaInvalida && !profesionalInvalido) return;
        const clinica = Array.isArray(clinicas) ? clinicas.filter(clinica => Number(clinica.clinicaID) === Number(clinicaID)) : [];
        const doctor = Array.isArray(horariosDoctor) ? horariosDoctor.filter(doctor => Number(doctor.clinicaDoctorId) === Number(clinicaDoctoID)) : [];
        const { horariosClinica = [], nombreClinica } = clinica && Array.isArray(clinica) && clinica.length > 0 ? clinica[0] : {};
        const { horarios = [], nombres } = doctor && Array.isArray(doctor) && doctor.length > 0 ? doctor[0] : {};
        if (horariosClinica.length === 0) return <b style={{ color: "red" }}>La sucursal no cuenta con horarios disponibles</b>;
        return <>
            {
                clinicaInvalida && <><Typography variant="body2" color="textSecondary" component="p" style={{ textAlign: "center" }}><b>{nombreClinica}</b></Typography>
                    {
                        horariosClinica.map((horario, index) => (<Typography key={uuid()} variant="body2" color="textSecondary" component="p">
                            <span style={{ color: horario.ausencia ? "red" : "black" }}>
                                <b>{diasFormato(horario.dia)}</b>: <i>{horaFormato(horario.desde)}</i> &#8212; <i>{horaFormato(horario.hasta)}</i> {horario.ausencia && "(Cerrado)"}
                            </span>
                        </Typography>))
                    }</>
            }
            {
                doctor.length === 0 ? <b style={{ color: "red" }}>El profesional no cuenta con horarios disponibles</b> :
                    (profesionalInvalido && <><Typography variant="body2" color="textSecondary" component="p" style={{ textAlign: "center" }}><b>{nombres}</b></Typography>
                        {
                            horarios.map((horario, index) => (<Typography key={uuid()} variant="body2" color="textSecondary" component="p">
                                <span style={{ color: horario.ausencia ? "red" : "black" }}>
                                    <b>{diasFormato(horario.dia)}</b>: <i>{horaFormato(horario.desde)}</i> &#8212; <i>{horaFormato(horario.hasta)}</i> {horario.ausencia && "(Cerrado)"}
                                </span>
                            </Typography>))
                        }</>)
            }

        </>;
    };

    const setEstatusClientePredeterminado = (id) => { setEstatusPredeterminadoID(id); };

    useEffect(() => {
        setAgenda(agenda => ({
            ...agenda,
            defaultView: responsive ? 'timeGridDay' : 'timeGridWeek',
            headerCalendar: {
                left: 'prev,next today',
                center: 'title',
                right: responsive ? 'timeGridDay' : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
            }
        }));
    }, [responsive]);

    useEffect(() => { actualizarAgenda(); }, [_clinicaID]);
    useEffect(() => {
        async function ftEffect() {
            await queryListadoClinica();
            await queryListadoHorarioDoctor();
        } ftEffect();
    }, []);
    useEffect(() => {

        if (partida.redirigirPuntoVenta) {
            if (bTipoDoctor) {
                FetchGrahpQL({
                    query: NUEVO_TICKET_TEMPORAL_AGENDA,
                    variables: {
                        input: {
                            doctorId: Number(partida.doctorId),
                            clienteId: Number(partida.pacienteId),
                            clinicaId: Number(partida.clinicaId),
                            serviciosId: partida.servicioId,
                            agendaId: Number(partida.agendaId)
                        }
                    }
                })
                    .then((data) => {

                    })
                    .catch((err) => {
                        console.log("ERROR: ", err.message)
                    })
            }
        }
    }, [partida.redirigirPuntoVenta]);

    window.actAgenda = actualizarAgenda;

    //TODO Redirect
    const componenteAgenda = (
        <>
            {bTipoAdministrador && <Graficas />}
            <Encabezado titulo="Agenda" />
            {partida.redirigirPuntoVenta && !bTipoDoctor ? (
                <Redirect
                    to={{
                        pathname: "/puntoventa",
                        state: { ...partida },
                    }}
                />
            ) : null}
            <br />
            <Mensaje
                titulo={mensaje.titulo}
                abrir={mensaje.abrir}
                texto={mensaje.texto}
                onClose={ftMensajeClose}
                icono={mensaje.icono}
                tipo={mensaje.tipo}
                color={mensaje.color}
                onAccept={mensaje.onAccept}
                newComponente={mensaje.newComponente}
                disabledAccept={disabledDelete}
                progress={disabledDelete}
            />
            {/* <Popup
                open={true}
                position="bottom center"
                contentStyle={contentStyle}
                overlayStyle={overlayStyle}
                arrowStyle={arrowStyle}
            >
                <div className={classes.root}>
                    <Paper square elevation={0} className={classes.header}>
                        <Typography>
                            {tutorialSteps[activeStep].label}
                        </Typography>
                    </Paper>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        // interval={0000}
                        autoplay={false}
                    >
                        {tutorialSteps.map((step, index) => (
                            <div key={step.label}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <img
                                        className={classes.img}
                                        src={step.imgPath}
                                        alt={step.label}
                                    />
                                ) : null}
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                Siguiente
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button
                                size="small"
                                onClick={handleBack}
                                disabled={activeStep === 0}
                            >
                                {theme.direction === "rtl" ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                Atras
                            </Button>
                        }
                    />
                </div>
            </Popup> */}
            <FullCalendar
                locale={esLocal}
                defaultView={agenda.defaultView}
                header={agenda.headerCalendar}
                timeZone={agenda.timeZone}
                buttonText={agenda.buttonText}
                weekends={agenda.calendarWeekends}
                //events={agenda.calendarEvents}
                allDayText={agenda.allDayText}
                slotDuration={agenda.slotDuration}
                slotLabelFormat={agenda.slotLabelFormat}
                events={agenda.events}
                /*themeSystem={agenda.themeSystem}
            theme={agenda.theme}*/
                minTime={agenda.minTime}
                maxTime={agenda.maxTime}
                nowIndicator={si}
                plugins={plugins}
                ref={calendarRef}
                //dateClick={handleDateClick}
                select={handleSelect}
                eventClick={handleDateClick}
                eventDrop={handleDrop}
                eventResize={handleResize}
                editable={si}
                selectable={si}
                allDaySlot={no}
                eventLimit={si}
                dragScroll={si}
                longPressDelay={agenda.delayTouch}
                eventLongPressDelay={agenda.delayTouch}
                selectLongPressDelay={agenda.delayTouch}
            />
            <Upsert
                open={open}
                close={close}
                agregarEvento={agregarEvento}
                actualizarEvento={actualizarEvento}
                eliminarEvento={eliminarEvento}
                GenerarReceta={GenerarReceta}
                disabledAccept={disabledAccept}
                usuario={usuario}
                setEstatusPredeterminadoID={setEstatusClientePredeterminado}
                configuracion={configuracion}
            />
        </>
    );

    return (
        <>{configuracion && componenteAgenda}</>
    )

}

export default Agenda;
