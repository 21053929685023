import { obtenerStateStorage } from '../../LocalStorage/LocalStorage';

/*******LOGIN ************************************************/
export const notificacionesLogin = { error: 1, mensaje: "" };
export const usuarioLogin = {
    email: { error: false, valor: "" },
    password: { error: false, valor: "" },
    desactivarBoton: { error: false, valor: "" }
};
/*******END****************************************************/
/******* LOGIN PUNTO VENTA ************************************/
export const notificacionesLoginPuntoVenta = { error: 1, mensaje: "" };
/*********END**************************************************/
/*******AGREGAR USUARIO(usuario-paciente-administrador)********/
export const agregarUsuario = {
    nombres: { error: true, valor: "" },
    apellidos: { error: true, valor: "" },
    email: { error: true, valor: "" },
    password: { error: true, valor: "" },
    sexo: { error: true, valor: "" },
    telefono: { error: true, valor: "" },
    desactivarBoton: { error: false, valor: "" },
    fechaNacimiento: { error: false, valor: "" },
    showPassword: { error: false, valor: "" },
    recuperarContraseña: { error: true, valor: "" }
};
//para doctor
export const agregarDoctor = {
    nombres: { error: false, valor: "" },
    apellidos: { error: false, valor: "" },
    email: { error: false, valor: "" },
    password: { error: false, valor: "" },
    sexo: { error: false, valor: "" },
    especialidad: { error: false, valor: "" },
    cedula: { error: false, valor: "" },
    telefono: { error: true, valor: "" },
    fechaNacimiento: { error: false, valor: "" },
    fechaEgreso: { error: false, valor: "" },
    showPassword: { error: false, valor: "" },
}
//para Paciente
export const agregarPaciente = {
    Paciente: {
        nombres: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        password: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        desactivarBoton: { error: true, valor: "" },
        telefono: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        showPassword: { error: false, valor: "" },
        recuperarContraseña: { error: true, valor: "" }
    }
}


export const limpiarStateAutorizacion = {
    Usuario:{
        datosUsuariosAutorizacionId: { error: false, valor: "" },
        usuarioId_nuevo: { error: false, valor: "" },
        nombres_nuevo: { error: false, valor: "" },
        apellidos_nuevo: { error: false, valor: "" },
        email_nuevo: { error: false, valor: "" },
        cedula_nuevo: { error: false, valor: "" },
        fechaEgreso_nuevo: { serror: false, valor: "" },
        estatus_nuevo:{error:false,valor:""},
        especialidadId_nuevo:{error:false,valor:""},
        sexo_nuevo:{error:false,valor:""},
        telefono_nuevo: { error: false,valor:""},
        fechaNacimiento_nuevo:{error:false,valor:""},
        autorizacion:{error:false,valor:""},
        solicitanteId:{ error: false, valor: "" },
        nombre_actual:{error:false,valor:""},
        apellidos_actual:{error:false,valor:""},
        email_actual:{error:false,valor:""},
        cedula_actual:{error:false,valor:""},
        fechaEgreso_actual:{error:false,valor:""},
        estatus_actual:{error:false,valor:""},
        especialidadId_actual:{error:false,valor:""},
        sexo_actual:{error:false,valor:""},
        telefono_actual:{error:false,valor:""},
        fechaNacimiento_actual:{error:false,valor:""},
        tipo:{error:false,valor:""},
        nombreCompletoSolicitante:{error:false,valor:""},
        correoSolicitante:{error:false,valor:""},
        tipoUsuarioSolicitante:{error:false,valor:""},
        nombreCompletoAfectado:{error:false,valor:""},
    }
};


export const limpiarStateGeneralUsuarios = {
    Usuario: {
        nombres: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        password: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        usuarioId: { error: false, valor: "" },
        fechaEgreso: { error: false, valor: "" },
        telefono: { error: false, valor: "" },
        desactivarBoton: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        recuperarContraseña: { error: true, valor: "" },
        showPassword: { error: false, valor: "" },
        especialidadId: { error: false, valor: "" },
        solicitanteId: { error: false, valor: "" },
        estatus: { error: false, valor: "" },
        cedula: { serror: false, valor: "" }
    },
    Paciente: {
        nombres: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        password: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        desactivarBoton: { error: false, valor: "" },
        telefono: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        showPassword: { error: false, valor: "" },
        recuperarContraseña: { error: true, valor: "" },
        especialidadId: { error: false, valor: "" },
        solicitanteId: { error: false, valor: "" },
        estatus: { error: false, valor: "" },
        cedula: { serror: false, valor: "" },
        fechaEgreso: { error: false, valor: "" },
    },
    Administrador: {
        recuperarContraseña: { error: true, valor: "" },
        nombres: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        password: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        usuarioId: { error: false, valor: "" },
        fechaEgreso: { error: false, valor: "" },
        telefono: { error: false, valor: "" },
        desactivarBoton: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        showPassword: { error: false, valor: "" },
        especialidadId: { error: false, valor: "" },
        solicitanteId: { error: false, valor: "" },
        estatus: { error: false, valor: "" },
        cedula: { serror: false, valor: "" }
    },
    Doctor: {
        nombres: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        password: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        especialidadId: { error: false, valor: "" },
        cedula: { error: false, valor: "" },
        desactivarBoton: { error: false, valor: "" },
        clinicaId: { error: true, valor: "" },
        telefono: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        fechaEgreso: { error: false, valor: "" },
        showPassword: { error: false, valor: "" },
        recuperarContraseña: { error: true, valor: "" },
        solicitanteId: { error: false, valor: "" },
        estatus: { error: false, valor: "" },

    }

}
/*******END********************************************/

/*******AGREGAR CONVENIO(convenio)*************/
export const agregarConvenio = {
    convenioId: { error: false, valor: "" },
    empresa: { error: false, valor: "" },
    tipo_de_pago: { error: false, valor: "" },
    opciones_de_pago: { error: false, valor: "" },
    email: { error: false, valor: "" },
    fechaInicial: { error: false, valor: "" },
    fechaFinal: { error: false, valor: "" },
    RFC: { error: false, valor: "" },
}

export const limpiarConvenio = {
    Convenio: {
        convenioId: { error: false, valor: "" },
        empresa: { error: false, valor: "" },
        tipo_de_pago: { error: false, valor: "" },
        opciones_de_pago: { error: false, valor: "" },
        email: { error: false, valor: "" },
        fechaInicial: { error: false, valor: "" },
        fechaFinal: { error: false, valor: "" },
        RFC: { error: false, valor: "" },
    }
}

export const limpiarEspecialidad = {
    Especialidad: {
        especialidadId: { error: false, valor: "" },
        descripcion: { error: false, valor: "" },
    }
}
/*****************END********************************/


/*******CEDULA***************************************/
export const cedula = {
    General: {
        cedula: { error: false, valor: "" },
        usuarioId: { error: false, valor: "" },
    }
}

export const notificacion = {
    notificacion: {
        notificacion: { error: false, valor: "" }
    }
}

export const password = {
    General: {
        password: { error: false, valor: "" }
    }
}

export const datosGenerales = {

    General: {
        nombre: { error: false, valor: "" },
        apellidos: { error: false, valor: "" },
        telefono: { error: false, valor: "" },
        sexo: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" }
    }
}

/****************************************************/


/*******LIMPIAR STATE(USUARIOS)***********************/
export const limpiarSeleccionUsuarios = {
    usuarioId: null,
    tipo: null,
    nombres: null,
    apellidos: null,
    email: null,
    password: null,
    fechaEgreso: null,
    fechaAlta: null,
    estatus: null,
    sexo: null,
    tarjetaQr: null,
    correo: null,
    cedula: null,
    especialidadId: null,
    descripcion: null,
    telefono: null
}
/*******END********************************************/

/****** ASIGNAR CLINICAS*******************************/
export const limpiarStateAsignarClinicas = {
    Clinicas: {
        doctorId: { error: false, valor: "" },
        clinicaId: { error: false, valor: "" },
    }
}
/*******************************************************/
/******SERIES*******************************/
export const limpiarStateSerie = {
    Serie: {
        serieId: { error: false, valor: "" },
        serieNombre: { error: false, valor: "" },
        tipoDocumentoId: { error: false, valor: "" },
        nombreDocumento: { error: false, valor: "" },
        estatusSerie: { error: false, valor: "" },
        porDefecto: { error: false, valor: "" },
        clinicaId: { error: false, valor: "" },
        nombreClinica: { error: false, valor: "" },
        aliasClinica: { error: false, valor: "" },
        estatusClinica: { error: false, valor: "" },
        //token: { error: false, valor: obtenerStateStorage("user").user.usuario[0].user.user.token }
    }
};
/*******************************************************/
/*******************CONVENIOS-USUARIOS**************************/
export const conveniosUsuario = {
    Convenios: {
        convenioId: { error: false, valor: "" },
        convenioUsuarioId: { error: false, valor: "" },
        empresaConvenio: { error: false, valor: "" },
        usuarioId: { error: false, valor: "" },
        nombreCompleto: { error: false, valor: "" },
        email: { error: false, valor: "" },
        fechaBajaConveniosUsuarios: { error: false, valor: "" },
        fechaFinalConvenio: { error: false, valor: "" },
    }
};
/*******************************************************/
/*******************COMISIONES-PROFESIONALES************/
export const comisionesPorfesionales={ datos: { fechaFinal: { valor: new Date().toISOString().split('T')[0], error: false }, fechaInicio: { valor: "2000-01-01", error: false }, clinicaId: { valor: null, error: false }, usuarioId: { valor: "", error: false } } };
/********************************************************/
/*******************NOTIFICACIONES DE CREDITO************/
export const notificacionDeCredito={
    datos:{
    recordatorioPagoId:{error:false,valor:""},
    plazoCreditoId:{error:false,valor:""},
    descripcionPlazo:{error:false,valor:""},
    tituloRecordatorio:{error:false,valor:""},
    descripcionRecordatorio:{error:false,valor:""},
    dias:{error:false,valor:""},
    estatus:{error:false,valor:""},
    recordarAntesDespues:{error:false,valor:""},
    desactivarBoton:{error:false,valor:""},
    planCreditoID:{error:false,valor:""},
    },
    AbrirModalModificacion:{
        Open:false
    },
    desactivarBoton: { error: false
    }
}
/********************************************************/
/*******************************************************/
/*******************COMISIONES-PROFESIONALES************/
export const HistorialPagos={ datos: { fechaFinal: { valor: new Date().toISOString().split('T')[0], error: false }, fechaInicio: { valor: "2000-01-01", error: false }, clinicaId: { valor: null, error: false }, usuarioId: { valor: "", error: false } } };



/*******************Anamnesis************/
export const Anamnesis={
        pacienteId: { error: false, valor: ""},
        anamnesisId: { error: false, valor: "" },
        nombreCompleto: { error: false, valor: "" },
        genero: { error: false, valor: "" },
        padecimientos: { error: false, valor: "" },
        email: { error: false, valor: "" },
        telefono: { error: false, valor: "" },
        fechaNacimiento: { error: false, valor: "" },
        fechaAlta: { error: false, valor: "" },
        desactivarBoton:{error: false, valor: ""},
        descripcion: { error: false, valor: "" },
        padecimientoId: { error: false, valor: "" },
        descripcionId:{error:false,valor:""}

};
