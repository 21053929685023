import React, { useState, useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';


function GenerarNotificacionv2(getNotificacion, valor = "w50") {


    //DECLARACIONES
    let [open, setOpen] = useState(false);

    //Ciclo de vida
    useEffect(() => {
        setOpen(true);
    }, [getNotificacion]);

    if (getNotificacion.error === 1) {
        return false;
    }
    if (getNotificacion.error === 2) {
        return (
            <div className={`${valor}`}>
                <Collapse in={open}>
                    <Alert
                        variant="filled" severity="warning"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        <strong>Campos incorrectos: </strong>
                        {getNotificacion.mensaje.replace("password","contraseña")}
                    </Alert>
                </Collapse>

            </div>
        );
    }
    if (getNotificacion.error === 3) {
        return (
            <div className={`${valor}`}>
            <Collapse in={open}>
                <Alert
                   variant="filled" severity="info"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                   <strong>Procesando información... </strong>
                {getNotificacion.mensaje}
                </Alert>
            </Collapse>

        </div>
        );
    }
    if (getNotificacion.error === 4) {
        return (
            <div className={`${valor}`}>
            <Collapse in={open}>
                <Alert
                   variant="filled" severity="error"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                   <strong>¡Aviso!: </strong>
                {getNotificacion.mensaje}
                </Alert>
            </Collapse>

        </div>
        );
    }
    if (getNotificacion.error === 5) {
        return (
            <div className={`${valor}`}>
            <Collapse in={open}>
                <Alert
                  variant="filled" severity="success"
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                >
                   <strong>Correcto: </strong>
                {getNotificacion.mensaje}
                </Alert>
            </Collapse>

        </div>
        );
    }

};

export default GenerarNotificacionv2;
