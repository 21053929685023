import React, { useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_CLINICAS } from '../../query';
import { ELIMINAR_CLINICA, NUEVA_CLINICA, MODIFICAR_CLINICA, CAMBIAR_ESTATUS_CLINICA } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, mensajeAdvertencia } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever, AddLocationIcon } from '../../componentesGenericos/Icons';

//Helpers
import { rgxAlmenosUnaLetra } from '../../Helpers/constantes/Rgx';

//Formatos
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';

export default function Clinicas() {

    // Obtener el ID de la Clinica
    let id = window.location.href.split("/");
    id = id[id.length - 1];

    // QUERY AND MUTATIONS
    const { loading, error, data, refetch } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const [deleteClinica] = useMutation(ELIMINAR_CLINICA, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la sucursal ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }

    });

    const [addClinica] = useMutation(NUEVA_CLINICA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente la sucursal ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });


    const [modClinica] = useMutation(MODIFICAR_CLINICA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la sucursal ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusClinica] = useMutation(CAMBIAR_ESTATUS_CLINICA, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente a la sucursal ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    // END QUERY AND MUTATIONS
    // REGION STATES

    const [errorState, setErrorState] = useState(false);

    const [filtro, setFiltro] = useState("");

    const [checked, setChecked] = React.useState(false);

    const [actualizar, setActualizar] = useState(false);

    const [fila, setFila] = useState(-1);

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    //END REGION STATES

    const handleChange = event => {
       console.log('change',  event.target.checked);
        setSeleccion({
            ...seleccion,
            matriz: event.target.checked
        });

    };

    // REGION CONSTANTES
    const seleccionInicial = {
        clinicaId: null,
        nombre: null,
        alias: null,
        clave: null,
        estatus: false,
        fechaAlta: null,
        padreId: null,
        matriz: false
    };

    const [seleccion, setSeleccion] = useState(seleccionInicial);

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'clinicaId', label: 'clinicaId', minWidth: 0, hide: true ,
        aliasColor:"estatus"},
        { id: 'clave', label: 'Clave', minWidth: 10, maxWidth: 20, align: 'left', textAlign: 'left', filtro: true },
        { id: 'nombre', label: 'Nombre', minWidth: 10, maxWidth: 20, align: 'left', textAlign: 'left', filtro: true },
        { id: 'alias', label: 'Alias', minWidth: 10, maxWidth: 20, align: 'left', textAlign: 'left', filtro: true },
        { id: 'estatus', label: 'Estatus', minWidth: 10, align: 'center', textAlign: 'center', component: "Checkbox" },
        {
            id: 'fechaAlta', label: 'Fecha de Alta', minWidth: 10, align: 'center',
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
            }
        },
        { id: 'matriz', label: 'Matriz', minWidth: 10, align: 'center', textAlign: 'center', component: "Checkbox" },

    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoClinicas?.clinicas;

    const menu = [
        { texto: "Agregar sucursal", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar sucursal", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar sucursal", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Agregar dirección", icono: <AddLocationIcon />, onClick: () => redireccionar() }
    ];

    function redireccionar() {
        if (seleccion.clinicaId !== null) {
            window.location.href = "/direccion/" + seleccion.clinicaId
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Selecciona una sucursal" });
        }
    }
    const camposFormulario = [
        {
            id: "clinicaId",
            texto: "clinicaId",
            valor: seleccion.clinicaId,
            tipo: "hidden"
        },
        {
            id: "nombre",
            texto: "Nombre *",
            valor: seleccion.nombre,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "alias",
            texto: "Alias *",
            valor: seleccion.alias,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "clave",
            texto: "Clave *",
            valor: seleccion.clave,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "estatusClinica",
            texto: "estatusClinica",
            valor: seleccion.estatus,
            tipo: "hidden"

        },
        {
            id: "matriz",
            texto: "Matriz",
            valor: seleccion.matriz,
            tipo: "checkbox",
            onChange: handleChange

        }

    ];


    // END REGION CONSTANTES

    // REGION ANDLERS

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.clinicaId !== null) {

            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una sucursal" });
        }
    }


    function handleCambiarEstatusClick() {
        if (seleccion.clinicaId !== null) {
            setChecked(seleccion.estatus === false);
            let opcion = seleccion.estatus === false ? "activar" : "desactivar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la sucursal ${seleccion.nombre}?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una sucursal" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.clinicaId !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la sucursal ${seleccion.nombre}?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una sucursal" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus)
            setSeleccion(datos);
            setFila(index);
            setActualizar(datos.matriz);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto);
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }



    //#region funciones
    function ftAgregar({ nombre, alias, clave, matriz = false, cleanStateFormulario }) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);
                if (ftValidar([nombre, alias, clave, matriz]) === true) {
                    setSeleccion({ ...seleccion, nombre, alias, clave, matriz });

                    await addClinica({
                        variables: {
                            clinica: {
                                nombreClinica: nombre,
                                aliasClinica: alias,
                                claveClinica: clave,
                                matriz: matriz
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function ftModificar({ nombre, alias, clave, matriz, cleanStateFormulario }) {
        async function ftModificar() {
            try {

                setDisabledAccept(true);
                nombre = nombre || seleccion.nombre;
                alias = alias || seleccion.alias;
                clave = clave || seleccion.clave;
                //matriz = matriz ? matriz : seleccion.matriz;
                if (matriz  == undefined){
                   matriz = seleccion.matriz;
                }

                if (ftValidar([seleccion.clinicaId, nombre, alias, clave, matriz]) === true) {
                    await modClinica({
                        variables: {
                            ClinicaActualizar: {
                                clinicaId: seleccion.clinicaId,
                                nombreClinica: nombre,
                                aliasClinica: alias,
                                claveClinica: clave,
                                estatusClinica: seleccion.estatus,
                                matriz: matriz

                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });

            }
        } ftModificar();
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusClinica({
                variables: {
                    id: Number(seleccion.clinicaId),
                    estatus: seleccion.estatus === false ? 1 : 0
                }
            })
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function ftEliminar() {
        async function ftEliminar() {
        setDisabledDelete(true);
         await deleteClinica({ variables: { id: Number(seleccion.clinicaId) } });
        setActualizar(true);
        setDisabledDelete(false);
        }ftEliminar();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion


    if (actualizar === true) { refetch(); setActualizar(false); }

    return (
        <MenuLateral titulo="Sucursales" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto}
                onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete}/>

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Sucursales" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} cleanAwait={true} />

            <Tabla titulo="Sucursales" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message}  onPaginaChange={handlePaginaChange} titleResponsive={["clave", "nombre"]} />

        </MenuLateral>
    );

}
