import React, { useContext } from 'react';
//Core
import { Grid, TextField } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/Especialidades';
//constantes
import {rgxLetrasPrimeraMayuscula } from "../../../../Helpers/constantes/Rgx";
//Estilos

function Agregar(props) {

    let rgxLetra = new RegExp(rgxLetrasPrimeraMayuscula);
    const [datos, guardarDatos] = useContext(CRMContext);

    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value)) {

                guardarDatos({
                    ...datos,
                    Especialidad: {
                        ...datos.Especialidad,
                        [name]: {
                            ...datos.Especialidad[name],
                            error: false, valor: value
                        }
                    }
                });


        } else {
            guardarDatos({
                ...datos,
                Especialidad: {
                    ...datos.Especialidad,
                    [name]: {
                        ...datos.Especialidad[name],
                        error: true, valor: value
                    }
                }
            });
        }
    }

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="descripcion" id={`descripcion`} label="Descripción" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarState} helperText="El campo debe ser alfabético y con mayúscula en la primera letra (tener como mínimo  2 caracteres)"
                                error={datos.Especialidad.descripcion.error}
                            />
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );
}

export default Agregar;
