import gql from 'graphql-tag';

export const LISTADO_TIPOS_DOCUMENTOS =gql`
query FiltroTipoDocumento($count: Int, $limite: Int, $pagina: Int, $tipoDocumentoId: Int){
    listadoTipoDocumento(count:$count, limite:$limite,pagina:$pagina, tipoDocumentoId:$tipoDocumentoId){
      TiposDocumentos{
        tipoDocumentoId
        nombreDocumento
      }
      registros
      paginas
    }
  }`;
