
//#region Configuración
//===========================
const CONFIGURACION =`
query Configuracion($nombreDB: String!) {
    obtenerConfiguracion(nombreDB: $nombreDB) {
        descripcionLogin
        urlLogotipo
        precioConIVA
        porcentajeDescuento
        ventaSinExistencia
        modificarPrecioPuntoVenta
        serviciosInventariables
        tasaIVA
        descripcionTasaIVA
        porcentajeTasaIVA
        maxPorcentajeDescto
        descripcionMaxPorcentajeDescto
        precisionAgenda
        precisionAgendaFormat
        tiempoIniAgenda
        tiempoFinAgenda
        tiempoCarrusel
        leyendaQRCard
        maxPorcentajeComision
        permitirAgendarClientes
        generarReceta
        permitirModificarHorarioProfesional
        permitirModificarHorarioCliente
        validaHorarios
        correoRegistro
    }
}`;
const CONFIGURACION_INICIAL =`
query Configuracion($nombreDB: String!) {
    obtenerConfiguracionInicial(nombreDB: $nombreDB) {
        descripcionLogin
        urlLogotipo
        tiempoCarrusel
    }
}`;
const CONFIGURACION_PUNTO_VENTA =`
query Configuracion {
    obtenerConfiguracionPuntoVenta {
       activarSeguridadPuntoVenta
    }
}`;
const CONFIGURACION_PUNTO_VENTA_DECRYPT =`
query Configuracion($password: String!) {
    obtenerConfiguracionPuntoVentaDecrypt(password: $password) {
        seguridadPuntoVenta
    }
}`;
const VALIDAR_SEGURIDAD_PUNTO_VENTA =`
query Configuracion($password: String!) {
    validarSeguridadPuntoVenta(password: $password)
}`;

const LISTAR_ADMINS_CONFIG = `
query listAdminsConfig{
    listarAdminsConfig{
      adminConfig{
        usuarioID
        correo
      }
    }
  }`
//#endregion

export {
    CONFIGURACION,
    CONFIGURACION_INICIAL,
    CONFIGURACION_PUNTO_VENTA,
    CONFIGURACION_PUNTO_VENTA_DECRYPT,
    VALIDAR_SEGURIDAD_PUNTO_VENTA,
    LISTAR_ADMINS_CONFIG
}
