export const OBTENER_SESION_EMPRESA = `
query obtenerSesionEmpresa( $input: InputGetSesiones!)
{
  obtenerSesionEmpresa( input: $input)
  {
    nombreEmpresa
    claveEmpresa
    nombreDB
  }
}
`;
