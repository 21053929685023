export const cuatroCaracteres = (val) => val ? val.replace(/\s/g, '').length > 3 : false;

export const formatoMoneda2 = (valor, nDecimales = 2) => {
    let formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency', currency: 'MXN', minimumFractionDigits: nDecimales
    });

    return formatter.format(valor);
}


export const formatoMoneda = (valor, nDecimales = 2) => {
    let formatter;

    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency', currency: 'MXN', minimumFractionDigits: nDecimales
    });
    let formato = formatter.format(valor).replace('MX', '');

    return formato;
}

export const formatoFolio = (number, width) => {
    var numberOutput = Math.abs(number); /* Valor absoluto del número */
    var length = number.toString().length; /* Largo del número */
    var zero = "0"; /* String de cero */

    if (width <= length) {
        if (number < 0) {
            return ("-" + numberOutput.toString());
        } else {
            return numberOutput.toString();
        }
    } else {
        if (number < 0) {
            return ("-" + (zero.repeat(width - length)) + numberOutput.toString());
        } else {
            return ((zero.repeat(width - length)) + numberOutput.toString());
        }
    }
}

export const formatoFecha = (fecha) => {
    return (fecha || '').replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
}
