import gql from 'graphql-tag';

//#region LISTADO DIRECCIONES
const FILTRO_DIRECCIONES = gql`
	query filtroDirecciones($limite: Int, $pagina: Int, $clinicaId: Int, $direccionId: Int) {
		listadoDirecciones(limite: $limite, pagina: $pagina, clinicaId: $clinicaId, direccionId: $direccionId) {
			direcciones {
				direccionId
				codigoPostal
                calle
				claveColonia
				colonia
				claveLocalidad
				localidad
				claveMunicipio
				municipio
				claveEstado
				estado
				clavePais
				pais
                clinicaId
                nombreClinica
                estatus
                lat
                lng
            }
		}
	}
`;
//=============================================
////#endregion

export {
    FILTRO_DIRECCIONES
}
