import { AGREGAR_SERIE } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVA_SERIE,MODIFICAR_SERIE} from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos,ActivarDesactivarBoton,ftClose) {
    return async (dispatch) => {
        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVA_SERIE,
                variables: {
                    clinicaId: parseInt(datos.clinicaId.valor),
                    serieNombre:datos.serieNombre.valor,
                    tipoDocumentoId:datos.tipoDocumentoId.valor
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se agregó  correctamente  la serie`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }else{
            FetchGrahpQL({
                query: MODIFICAR_SERIE,
                variables: {
                    serieId: parseInt(datos.serieId.valor),
                    serieNombre:datos.serieNombre.valor,
                    tipoDocumentoId:datos.tipoDocumentoId.valor
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se modificó  correctamente  la serie`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }
        dispatch(AgregarSerie({ activo: true }))
    }
}


export const AgregarSerie = serieCrud => {
    return {
        type: AGREGAR_SERIE,
        payload: serieCrud
    }
}




