import React from 'react';

export const formatearCadena = campos => {
    let mensaje = "";
    for (let index = 0; index < campos.length; index++) {
        if (campos.length === 1) {
            mensaje += campos[index];
            break;
        }
        if (index === campos.length - 1) {
            mensaje += " " + campos[index];
        } else {
            mensaje += campos[index] + ", ";
        }
    }
    return mensaje;
};

export const verificaValores = (arr, dtosLcEmpresa, sustituirCampo = null) => {
    let estado = true;
    let campos = [];
    for (let index = 0; index < arr.length; index++) {
        for (const key in dtosLcEmpresa) {
            if (key === arr[index]) {
                if (
                    dtosLcEmpresa[key].valor === "" ||
                    dtosLcEmpresa[key].valor === "null" ||
                    dtosLcEmpresa[key].valor === null ||
                    dtosLcEmpresa[key].error === true
                ) {
                    estado = false;
                    campos.push(key);
                }
                break;
            }
        }
    }


    if (sustituirCampo != null) {
        campos = sustituir(campos, sustituirCampo)
    }

    campos = limpiarCadenas(campos);

    return { estado, arr: campos };
};


export const sustituir = (arr, sustituirCampos) => {
    for (let index = 0; index < arr.length; index++) {
        for (let index2 = 0; index2 < sustituirCampos.length; index2++) {
            if (arr[index] === sustituirCampos[index2][0]) {
                arr[index] = sustituirCampos[index2][1];
            }
        }
    }
    return arr;
}

export const limpiarCadenas = campos => {
    for (let index = 0; index < campos.length; index++) {
        campos[index] = campos[index].replace("_", " ");
    }
    return campos;
};

export const generarNotificacion = (getNotificacion, valor = "w50") => {
    if (getNotificacion.error === 1) {
        return false;
    }
    if (getNotificacion.error === 2) {
        return (
            <div className={`alert alert-danger ${valor}`}>
                <strong>Campos Incorrectos: </strong>
                {getNotificacion.mensaje}
            </div>
        );
    }
    if (getNotificacion.error === 3) {
        return (
            <div className={`alert alert-warning ${valor} `}>
                <strong>Procesando Informacion... </strong>
                {getNotificacion.mensaje}
            </div>
        );
    }

    if (getNotificacion.error === 4) {
        return (
            <div className={`alert alert-danger ${valor}`}>
                <strong>Error: </strong>
                {getNotificacion.mensaje}
            </div>
        );
    }

    if (getNotificacion.error === 5) {
        return (
            <div className={`alert alert-success ${valor}`}>
                <strong>Correcto: </strong>
                {getNotificacion.mensaje}
            </div>
        );
    }

};
