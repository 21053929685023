import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [asignacion, guardarAsignacion] = useState({
        Clinicas: {
            doctorId: { error: false, valor: ""},
            clinicaId: { error: false, valor: "" },
        }
    });
    return (
        <CRMContext.Provider value={[asignacion, guardarAsignacion]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
