import React, { useState, useRef } from 'react';

import { Dialog } from '@material-ui/core';

//componentes
import Titulo from '../Mensaje/componentes/Titulo';
import Botones from '../Mensaje/componentes/Botones';

//estilos
import { makeStyles } from '@material-ui/core/styles';

//QR reader
import QrReader from 'react-qr-reader';

//Helpers
import { getUsuario } from '../../Helpers/Usuario';

//Iconos
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

function EscanerQR({ abrir, tipo, onClose = () => { }, onAccept = () => { }, onChange = () => { }, texto = "", onCancel, classes, titulo = "Escanear QR", success = false }) {

    const cameraRef = useRef(null);

    const clases = classes || {
        root: {
            position: 'relative',
            boxSizing: 'border-box',
            webkitBoxOrient: 'vertical',
            webkitBoxDirection: 'normal',
            flexDirection: 'column',
            webkitBoxPack: 'center',
            justifyContent: 'center',
            width: '32em',
            maxWidth: '100%',
            padding: '1.25em',
            border: 'none',
            borderRadius: '.3125em',
            background: '#fff',
            fontFamily: 'inherit',
            fontSize: '1rem',
        },
        titulo: makeStyles(theme => ({
            main: {
                margin: 0,
                padding: theme.spacing(2),
                textAlign: 'center'
            },
            btnCerrar: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(),
        contenido: makeStyles(theme => ({
            root: {
                textAlign: 'center',
                padding: '25px 50px',
                color: '#545454',
                fontSize: '1.125em',
                fontWeight: '400',
                lineHeight: 'normal',
                textAlign: 'center',
                wordWrap: 'break-word'
            }
        }))(),
        botones: makeStyles(theme => ({
            root: {
                padding: theme.spacing(1),
            },
            Btn: {
                display: 'inline-block',
                borderLeftColor: 'rgb(48, 133, 214)',
                borderRightColor: 'rgb(48, 133, 214)',
                border: 0,
                borderRadius: '.25em',
                background: 'initial',
                backgroundColor: 'initial',
                backgroundColor: '#3085d6',
                color: '#fff',
                fontSize: '1.0625em',
                margin: '0 auto'
            },
            BtnCancel: {
                marginRight: '10px'
            },
            contenedor: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }))()
    };


    const [qrr, setQrr] = useState({
        result: null,
        id: null
    })

    const handleScan = async data => {
        onChange(data);
    }

    const handleError = err => {
        console.error(err)
        setQrr({
            result: "Ocurrió un error o no se obtuvieron los permisos de la cámara"
        });
    }

    React.useEffect(() => {
        if (abrir === false)
            return () => {
                setQrr({
                    result: null
                })
            }
    }, [abrir])

    return (
        <Dialog open={abrir} onClose={onClose} aria-labelledby="dialog-title" className={clases.root} maxWidth="xs" fullWidth>
            <Titulo texto={titulo} classes={clases.titulo} tipo={tipo} />

            <div style={{ width: '100%', margin: '20px 0' }}>
                <div style={{ display: 'grid', gridTemplateAreas: '"soloyo"', justifyItems: 'center', alignItems: 'center' }}>
                    {
                        success && <CheckCircleOutlineIcon color="#1e7" style={{ color: 'green', fontSize: '300px', gridArea: 'soloyo', zIndex: 5 }} />
                    }
                    <QrReader ref={cameraRef} delay={300} onError={handleError} onScan={handleScan} style={{ width: '80%', gridArea: 'soloyo' }} />
                </div>

                <h3 style={{ textAlign: 'center', margin: 0 }}>{qrr.result || texto}</h3>
            </div>

            <Botones tipo={tipo} classes={clases.botones} onAccept={() => onAccept(qrr.id)} onCancel={onCancel || onClose} onClose={onClose} />

        </Dialog>
    );
}

export default EscanerQR
