import React, { useContext, useState, useEffect } from 'react';
import Swal from "sweetalert2";
import { makeStyles } from '@material-ui/core/styles';
//CORE
import { Button, CardContent, CardActions, Card, Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, MenuItem } from '../../../../componentesGenericos/Core';
//RGX
import { rgxtel, rgxLetras, rgxEmail } from '../../../../Helpers/constantes/Rgx';
//FETCH
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
//QUERY
import { MODIFICAR_DATOS_GENERALES_USUARIOS, ACTUALIZAR_CORREO } from '../../../../mutation';
//COMPONENTES
import Mensaje from '../../../../componentesGenericos/Mensaje';
//CONTEXT
import { CRMContext } from '../../../../context/Perfil';
//CONSTANTES
import { mensajeError, mensajeInicial, mensajeExito } from '../../../../Helpers/constantes';
import { datosGenerales } from './../../../../Helpers/constantes/State';
//HELPER
import {
    verificaValores,
    formatearCadena
} from "../../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../../Helpers/Validacion/ValidacionesCadenasClose";
import { guardarStateStorage } from '../../../../Helpers/LocalStorage/LocalStorage';
import { ftAnioNoValido, ftAniosRangosValidos, ftAniosRangosValidosHoy } from "../../../../Helpers/";
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "15px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    mb1: {
        marginBottom: "10px"
    }
});


function General(props) {

    //DECLARACIONES
    const classes = useStyles();
    let rgxTelefono = new RegExp(rgxtel);
    let rgxLetra = new RegExp(rgxLetras);
    let rgxEmails = new RegExp(rgxEmail);
    const [boton, setBoton] = useState(false);
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(datosGenerales);
    const [sexo, setSexo] = React.useState(0);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [genero, setGenero] = useState('');

    //EVENTOS
    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarStateFecha = event => {
        const { name, value } = event.target;
        if (value === null || value.length === 0 || ftAniosRangosValidosHoy(value) === false || ftAnioNoValido(value) === true) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        }

    }
    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        if (rgxLetra.test(value) && value.length > 1) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }
    const handleChangeGuardarTelefono = event => {
        const { name, value } = event.target;
        if (rgxTelefono.test(value)) {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarPerfiles({
                ...perfil,
                General: {
                    ...perfil.General,
                    [name]: {
                        ...perfil.General[name],
                        error: true, valor: value
                    }
                }
            });
        }
    }
    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarPerfiles({
            ...perfil,
            General: {
                ...perfil.General,
                [name]: {
                    ...perfil.General[name],
                    error: false, valor: value
                }
            }
        });
    }
    const EventClickGeneral = async () => {
        deshabilitarBoton(true);

        let campos = [
            "nombres",
            "apellidos",
            "telefono",
            "sexo",
            "fechaNacimiento"
        ];
        let datos = verificaValores(campos, perfil.General, [["sexo", "género"], ["fechaNacimiento", "Fecha de nacimiento"], ["telefono", "teléfono"]]);
        let mensaje = formatearCadena(datos.arr);

        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });

            if (perfil.General.email.valor !== perfil.General.emailActual.valor) {

                Swal.fire({
                    title: '¿Estás seguro?',
                    text: "Estás seguro de cambiar la dirección de correo electrónico",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText:'Cancelar',
                    confirmButtonText: 'Si',
                }).then(async(result) => {
                    if (result.value) {
                        await FetchModificarCorreo();
                    }
                })

            } else {
                await FetchModificarDatos();
            }
            deshabilitarBoton(false);
        }
    };
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }

    //API
    const FetchModificarDatos = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });
        await FetchGrahpQL({
            query: MODIFICAR_DATOS_GENERALES_USUARIOS,
            variables: {
                nombres: perfil.General.nombres.valor.trim(),
                apellidos: perfil.General.apellidos.valor.trim(),
                telefono: perfil.General.telefono.valor,
                sexo: parseInt(perfil.General.sexo.valor),
                usuarioId: parseInt(props.usuarioId),
                fechaNacimiento: perfil.General.fechaNacimiento.valor,
                email: perfil.General.email.valor
            }
        })
            .then((data) => {

                setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente ` });
                const initialState = {
                    usuario: [{
                        user: {
                            user: {
                                success: data.cambiarDatosGenerales.success,
                                mensaje: data.cambiarDatosGenerales.mensaje,
                                token: data.cambiarDatosGenerales.token,
                                error: data.cambiarDatosGenerales.error,
                                respuesta: data.cambiarDatosGenerales.respuesta,
                            }
                        }
                    }]
                }
                guardarStateStorage({
                    user: initialState
                }, 'user')

            })
            .catch((err) => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };
    const FetchModificarCorreo = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });
        await FetchGrahpQL({
            query: ACTUALIZAR_CORREO,
            variables: {
                usuarioId: parseInt(perfil.General.usuarioId.valor),
                email: perfil.General.email.valor.trim(),
                nombres: perfil.General.nombres.valor.trim(),
            }
        })
            .then(() => {
                setMensaje({
                    ...mensaje, ...mensajeExito, texto: `
                Se envió un correo electrónico para confirmar ` });
            })
            .catch((err) => {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }


    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //FUNCIONES
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
    }, []);

    return (
        <Card className={classes.mb1}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />
            <div className={classes.degradadoAzul + " " + classes.p2 + " " + classes.ftbold}>
                <p className={classes.colorBlanco}>Modificar datos generales</p>
            </div>
            <CardContent>
                {GenerarNotificacionv2(getNotificacion, "w100")}
                <Grid spacing={3} container direction="row">
                    <Grid item sm={12} md={6}>
                        <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={perfil.General.nombres.error} value={perfil.General.nombres.valor} disabled />

                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={perfil.General.apellidos.error} value={perfil.General.apellidos.valor} disabled />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={perfil.General.telefono.error} value={perfil.General.telefono.valor} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} error={perfil.General.email.error} value={perfil.General.email.valor} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                            <InputLabel id="demo-simple-select-label" disabled required={true}>Género</InputLabel>
                            <Select
                                labelId={`genero`}
                                id={`genero`}
                                error={perfil.General.sexo.error}
                                onChange={handleChangeGuardarStateSexo}
                                name="sexo"
                                required={true}
                                value={perfil.General.sexo.valor}
                            >
                                <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge el género '}</MenuItem>
                                {ftGenerarSelect(genero.datos)}
                            </Select>
                            <FormHelperText>El género es un campo obligatorio</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <form className={classes.container} noValidate style={{ width: "100%" }}>
                            <TextField
                                error={perfil.General.fechaNacimiento.error}
                                style={{ width: "100%" }}
                                value={perfil.General.fechaNacimiento.valor ? ((perfil.General.fechaNacimiento.valor).split("T")[0] || "") : ""}
                                id="date"
                                required={true}
                                label="Fecha de nacimiento"
                                helperText="La fecha de nacimiento es obligatoria"
                                type="date"
                                onChange={handleChangeGuardarStateFecha}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="fechaNacimiento"
                            />
                        </form>
                    </Grid>
                </Grid>
            </CardContent>


            <CardActions>

                <Button size="small" color="primary" onClick={EventClickGeneral} disabled={boton}>
                    GUARDAR
        </Button>
            </CardActions>
        </Card>


    );
}

export default General;
