import React, { useState, useEffect, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import { Grid } from '../../../componentesGenericos/Core';

import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';

import { FetchAPI } from '../../../Helpers/Fetch/FetchGraphql';
import { APIkeyGoogle } from '../../../Helpers/GoogleApi';

import { diasFormato, horaFormato } from '../../../Helpers';

import Mapa from './Mapa';

export default function DireccionBonita({xs = 12, clinica = {}}) {

	const {
		// clinicaID,
		nombreClinica,
		// alias,
		// clave,
		// estatusClinica,
		// fechaAlta,
		// padreID,
		// matriz,
		// direccionID,
		codigoPostal,
		// claveColonia,
		colonia,
		// claveLocalidad,
		// localidad,
		// claveMunicipio,
		municipio,
		// claveEstado,
		estado,
		// clavePais,
		// pais,
		// estatusDireccion,
		direccion1de4,
		direccion2de4,
		direccion3de4,
		direccion4de4,
        telefonos = {},
        horariosClinica = {},
        lat,
        lng
    } = clinica;

    const [ location, setLocation ] = useState();
    const [ mostrar, setMostrar ] = useState(false);

    function obtenerLatLon() {
        async function obtenerLatLon() {
            try {

                let locacion = {};

                if(lat && lng) locacion = { lat, lng };
                else {

                    let address = `${direccion1de4} ${colonia} ${codigoPostal} ${municipio} ${estado}`;
                    address = address.replace(/ /g, "+");
                    address = address.replace(/#/g, "");
                    let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_API_KEY_GOOGLE}&address=${address}`;

                    let { results } = await FetchAPI({ url });

                    if(!results) throw new Error("No se obtuvieron resultados");

                    let { geometry:{ location } = {} } = results[0];

                    locacion = { ...location };

                }

                setLocation({ ...locacion });
                setMostrar(true);

            } catch({message}) {
                console.error(message);
                setMostrar(false);
            }
        }
        obtenerLatLon();
    }

    useEffect(() => {
        async function fEffect() {
            await obtenerLatLon();
        }
        fEffect();
    }, []);

    const componente = <Grid item xs={12} sm={12} md={xs}>
			<Card>
				<CardActionArea>
                    { (mostrar && location) ? <Mapa
                        isMarkerShown={true}
                        location={location}
                        titulo={nombreClinica}
                    /> : <Typography gutterBottom variant="h5" component="h2">
                        Espere un momento...
                    </Typography> }
				</CardActionArea>
                <Divider />
				<CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={(xs > 4 && !telefonos) ? 12 : 6} style={{ textAlign: "center" }}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                <BusinessIcon />
                            </Typography>
                            <Divider />
                            <Typography gutterBottom variant="h5" component="h2">
                                { nombreClinica }
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                { direccion1de4 }
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                { direccion2de4 }
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                { direccion3de4 }
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                { direccion4de4 }
                            </Typography>
                        </Grid>
                        {
                            !telefonos ? "" : <Grid item xs={12} sm={12} md={(xs > 4 && !telefonos) ? 12 : 6} style={{ textAlign: "center" }}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <PhoneIcon />
                                </Typography>
                                <Divider />
                                <Typography gutterBottom variant="h5" component="h2">
                                    Contáctanos
                                </Typography>
                                {telefonos.map(({telefono}, index) => (<Typography key={index} variant="body2" color="textSecondary" component="p">
                                    <Link href={`tel:+52${telefono}`} color="inherit">
                                        { telefono }
                                    </Link>
                                </Typography>))}
                            </Grid>
                        }
                        {
                            horariosClinica.length > 0 && <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    <QueryBuilderIcon />
                                </Typography>
                                {
                                    horariosClinica.map((horario, index) => (<Typography key={index} variant="body2" color="textSecondary" component="p">
                                        <span style={{ color: horario.ausencia ? "red" : "black" }}>
                                            <b>{diasFormato(horario.dia)}</b>: <i>{horaFormato(horario.desde)}</i> &#8212; <i>{horaFormato(horario.hasta)}</i> {horario.ausencia && "(Cerrado)"}
                                        </span>
                                    </Typography>))
                                }
                            </Grid>
                        }
                    </Grid>
                </CardContent>
			</Card>
		</Grid>;

	return (<> { componente } </>);
}
