import { MENU, SELECCIONAR_MENU } from '../Types';

export const ObtenerMenu = menu =>{

    return {
        type: MENU,
        payload: menu
    }
}

export const SetMenu = menu =>{

    return {
        type: SELECCIONAR_MENU,
        payload: menu
    }
}
