  import gql from 'graphql-tag';

 //#region Centro de Costos
 //===========================
const OBTENER_CENTRO_COSTO =gql`
query ObtenerCentroCosto($id: Int!){
  obtenerCentrocostos(id: $id){
    ccostoId
    clinicaId
    nombre
    numCcosto
    nombreCcosto
    fechaAlta
    estatusCcostos
  }
}`;

const LISTADO_CENTRO_COSTO =gql`
query ListadoCentroCosto ($limite: Int, $pagina: Int, $estatus: Boolean, $clinicaId: Int){
  listadoCentrocostos(limite: $limite, pagina: $pagina, estatus: $estatus, clinicaId: $clinicaId){
    CentrosDeCostos{
      ccostoId
      clinicaId
      nombre
      numCcosto
      nombreCcosto
      fechaAlta
      estatusCcostos
    }
    registros
    paginas
  }
}`;
//==========================================================
//#endregion

export {
    OBTENER_CENTRO_COSTO,
    LISTADO_CENTRO_COSTO
}
