import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [usuarios, guardarUsuarios] = useState({
        Usuario:{
            datosUsuariosAutorizacionId: { error: false, valor: "" },
            usuarioId_nuevo: { error: false, valor: "" },
            nombres_nuevo: { error: false, valor: "" },
            apellidos_nuevo: { error: false, valor: "" },
            email_nuevo: { error: false, valor: "" },
            cedula_nuevo: { error: false, valor: "" },
            fechaEgreso_nuevo: { serror: false, valor: "" },
            estatus_nuevo:{error:false,valor:""},
            especialidadId_nuevo:{error:false,valor:""},
            sexo_nuevo:{error:false,valor:""},
            telefono_nuevo: { error: false,valor:""},
            fechaNacimiento_nuevo:{error:false,valor:""},
            autorizacion:{error:false,valor:""},
            solicitanteId:{ error: false, valor: "" },
            nombre_actual:{error:false,valor:""},
            apellidos_actual:{error:false,valor:""},
            email_actual:{error:false,valor:""},
            cedula_actual:{error:false,valor:""},
            fechaEgreso_actual:{error:false,valor:""},
            estatus_actual:{error:false,valor:""},
            especialidadId_actual:{error:false,valor:""},
            sexo_actual:{error:false,valor:""},
            telefono_actual:{error:false,valor:""},
            fechaNacimiento_actual:{error:false,valor:""},
            tipo:{error:false,valor:""},
            nombreCompletoSolicitante:{error:false,valor:""},
            correoSolicitante:{error:false,valor:""},
            tipoUsuarioSolicitante:{error:false,valor:""},
            nombreCompletoAfectado:{error:false,valor:""},
            password_nuevo:{error:false,valor:""},
            password_nuevo_estatus:{error:false,valor:""},
            desactivarBoton: {error: false, valor: ''}
        },
        AbrirModalModificacion:{
            Open:false
        },
        desactivarBoton: { error: false
        }

    });
    return (
        <CRMContext.Provider value={[usuarios, guardarUsuarios]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
