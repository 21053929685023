import React from 'react';
import {
	CheckCircleRoundedIcon,
	CalendarTodayIcon,
	CallIcon,
	EmailIcon,
	MessageIcon,
	NotificationsIcon
} from '../../../Icons';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Avatar as Aang } from '../../../Core';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		'& > *': {
			margin: theme.spacing(1)
		}
	},
	blue: {
		color: theme.palette.getContrastText(blue[500]),
		backgroundColor: blue[500]
	}
}));

function Avatar({ valor }) {
	const classes = useStyles();
	let resultado;
	switch (valor) {
		case 'Email':
			resultado = <EmailIcon />;
			break;
		case 'Llamada':
			resultado = <CallIcon />;
			break;
		case 'MensajeTexto':
			resultado = <MessageIcon />;
			break;
		case 'NotificacionPush':
			resultado = <NotificationsIcon />;
			break;
		case 'GoogleCalendar':
			resultado = <CalendarTodayIcon />;
            break;
        case 'WhatsApp':
            resultado = <WhatsAppIcon />
            break;
		default:
			resultado = <CheckCircleRoundedIcon />;
			break;
	}
	return <Aang className={classes.blue}>{resultado}</Aang>;
}

export default Avatar;
