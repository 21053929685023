import React, { useEffect,useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_HORARIO_CLINICA, LISTADO_CLINICAS } from '../../query';
import { NUEVO_HORARIO_CLINICA, MODIFICAR_HORARIO_CLINICA, ELIMINAR_HORARIO, ESTATUS_HORARIO_CLINICA } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever, EditLocationIcon } from '../../componentesGenericos/Icons';

//Redux
import { useSelector, useDispatch } from 'react-redux';


const ftSelectFormat = (arreglo, {id, descripcion, otro}) => (arreglo.map(dato => {
    let resultado = { value: dato[id], label: dato[descripcion] };
    if(otro) resultado[otro] = dato[otro];
    return resultado;
    }));

export default function HorariosClinica() {

    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);

    // QUERY AND MUTATIONS
    const { loading, error, data, refetch } = useQuery(LISTADO_HORARIO_CLINICA, {
        variables: { limite: 100, pagina: 1, clinicaId: Number(_clinicaID) > 0 ? Number(_clinicaID) : null },
        fetchPolicy: 'no-cache',
        onError: ({message}) => {
             let label = message.replace('GraphQL error: ', ' ')
            setErrorState(true);

        },
        onCompleted: () => {
            setErrorState(false);

        }
    });

    const { data: data2 } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 100, pagina: 1 },
        fetchPolicy: 'no-cache',

    });

    const [addHorarioClinica] = useMutation(NUEVO_HORARIO_CLINICA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el horario` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modHorario] = useMutation(MODIFICAR_HORARIO_CLINICA, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: (e) => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el horario de ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusHorarioClinica] = useMutation(ESTATUS_HORARIO_CLINICA, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus de horario de ${seleccion.nombre}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteHorario] = useMutation(ELIMINAR_HORARIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se elimino correctamente el horario` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    // REGION STATES

    const [errorState, setErrorState] = useState(false);

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [filtro, setFiltro] = useState("");

    const [checked, setChecked] = React.useState(false);

    const [actualizar, setActualizar] = useState(false);

    const [fila, setFila] = useState(-1);

    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [diasDB,setDiasDB] = useState([]);

    //END REGION STATES
    const handleCange = event => {

        setSeleccion({
            ...seleccion,
        })
    }
    // REGION CONSTANTES
    const seleccionInicial = {
        horarioId: null,
        dias: [],
        desde: "00:00:00",
        hasta: "00:00:00",
        clinicaId: null,
        clinicaDoctorId: null,
        nombre: null,
        clave: null,
        estatusClinica: false,
        matriz: false,
        estatus: false,
        ausencia: false,
        numDias:1
    };

    const [seleccion, setSeleccion] = useState(seleccionInicial);

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'horarioId', label: 'horarioId', minWidth: 0, hide: true,aliasColor:"estatus" },
            {
                id: 'dias', label: 'Día', minWidth: 30, align: 'left', textAlign: 'left',filtro:true,
                format: (valor) => {
                    return valor.toString().replace("1", "Lunes").replace("2", "Martes").replace("3", "Miercoles").replace("4", "Jueves").replace("5", "Viernes").replace("6", "Sabado").replace("7", "Domingo");
                }
            },
            {
                id: 'desde', label: 'Desde', minWidth: 100, align: 'center',
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
                }
            },
            {
                id: 'hasta', label: 'Hasta', minWidth: 100, align: 'center',
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
                }
            },
            { id: 'clinicaId', label: 'clinicaId', minWidth: 0, hide: true },
            { id: 'nombre', label: 'Nombre', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            { id: 'clave', label: 'Clave', minWidth: 0, hide: true },
            { id: 'estatusClinica', label: 'Estatus', minWidth: 0, hide: true },
            { id: 'matriz', label: 'Matriz', minWidth: 0, hide: true },
            { id: 'estatus', label: 'Estatus', minWidth: 30, align: 'center', textAlign: 'center', component: "Checkbox" },
            {
                id: 'ausencia', label: 'Disponibilidad', minWidth: 30, align: 'left', textAlign: 'left',
                format: (valor) => {
                    return valor ? valor.toString().replace("false", "Ausente").replace("true", "-") : "Disponible";
                }
            }
        ];

    const menu = [
        { texto: "Agregar horario", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar horario", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Cambiar estatus", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar horario", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
    ];

    let diasSemana = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'];


    let opcionesDia = diasSemana.map((valor, indice) => ({
        label: valor,
        value: indice + 1
    }));

    let opcionesClinica = data2 ?
        data2.listadoClinicas.clinicas.map(valor => ({
            label: valor.nombre,
            value: Number(valor.clinicaId)
        })) : null


    const camposFormulario = [
        { id: "horarioId", texto: "horarioId", valor: seleccion.horarioId, tipo: "hidden" },
        {
            id: "dias",
            texto: "Dias *",
            valor:  seleccion.dias,
            tipo: "select",
            multiselect:true,
            xs: 12,
            opciones: diasDB,

        },
        {
            id: 'desde', texto: "Desde *", valor: seleccion.desde, tipo: "time", xs: 6,
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
            }
        },
        {
            id: "hasta", texto: "Hasta *", valor: seleccion.hasta, tipo: "time", xs: 6,
            format: (valor) => {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$4");
            }
        },
        { id: "clinicaId", texto: "Sucursal *", valor: seleccion.clinicaId, tipo: "select",         styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'},
        opciones: opcionesClinica, xs: 12 },
        { id: "clinicaDoctorId", texto: "clinicaDoctorId", valor: seleccion.clinicaDoctorId, tipo: "hidden" },
        { id: "estatus", texto: "estatus", valor: seleccion.estatus, tipo: "hidden" },
        {
            id: "ausencia", texto: "Disponibilidad", valor: seleccion.ausencia, tipo: "select",
            opciones: [
                { label: "Si", value: false },
                { label: "No", value: true }
            ], xs: 12
        }

    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data ?.listadoHorarioClinica ?.HorariosClinicas;

    // END REGION CONSTANTES

    //REGION HANDLES
    function handleAgregarClick() {

        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: (state) => ftAgregar(state) });

    }

    function handleModificarClick() {
        if (seleccion.horarioId !== null) {
             let todo = [];
             let i =0;
             seleccion.dias.map(resultado => {
                   todo[i] = Number(resultado);
                    i = i +1;
             });
             setSeleccion({ ...seleccion, desde: seleccion.desde.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"), hasta: seleccion.hasta.replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d+Z/, "$1"), dias:todo.sort() });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un horario" });
        }
    }


    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleCambiarEstatusClick() {
        if (seleccion.horarioId !== null) {
            setChecked(seleccion.estatus === false);
            let opcion = seleccion.estatus === false ? "activar" : "desactivar";
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} el horario de la sucursal ${seleccion.nombre}?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({
                ...mensaje, ...mensajeError, texto: "Debe seleccionar un horario"
            });
        }
    }

    function handleEliminarClick() {
        if (seleccion.horarioId !== null) {
            setMensaje({
                ...mensaje,
                ...mensajeConfirmacion,
                texto: `¿Está seguro de eliminar horario de la sucursal ${seleccion.nombre}?`, onAccept: ftEliminar
            });
        }
        else {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: "Debe seleccionar un horario de sucursal"
            });
        }
    }


    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus)
            setSeleccion(datos);
            setFila(index);
            //setActualizar(datos.nombre);

        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto);
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }
    // END REGION HANDLES

    //REGION FUNCIONES
    function ftAgregar({ dias, desde, hasta, clinicaId = null, clinicaDoctorId = null, estatus = false, ausencia,  cleanStateFormulario }) {

        async function ftAgregar() {
            try {
                setDisabledAccept(true);


                desde = (desde === undefined || desde === null) ?
                    seleccion.desde : desde;
                hasta = (hasta === undefined || hasta === null) ?
                    seleccion.hasta : hasta;
                ausencia = (ausencia === undefined || ausencia === null) ?
                    seleccion.ausencia : ausencia;

                dias = (dias === undefined || dias === null) ?
                    seleccion.dias : dias;

                let ValidarHora = desde < hasta ? true : false

                if (ftValidar([dias, desde, hasta, clinicaId, ausencia])) {
                    if (ValidarHora === true) {
                        setSeleccion({ ...seleccion, dias, desde, hasta, clinicaId, clinicaDoctorId, estatus, ausencia });

                        await addHorarioClinica({
                            variables: {
                                horario: {
                                    dias: dias.toString(),
                                    desde: desde,
                                    hasta: hasta,
                                    clinicaId: clinicaId,
                                    clinicaDoctorId: clinicaDoctorId,
                                    estatus: estatus,
                                    ausencia: ausencia

                                }
                            }
                        });
                        if (cleanStateFormulario) cleanStateFormulario();
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "La hora inicial no puede ser igual o mayor a la final" })
                    }
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregar();

    }

    function ftModificar({ dias, desde, hasta, clinicaId, clinicaDoctorId, ausencia, cleanStateFormulario, Desde, Hasta }) {

        async function ftModificar() {
            try {
                setDisabledAccept(true);

                dias = dias ? dias : seleccion.dias;
                desde = desde || seleccion.desde;
                hasta = hasta || seleccion.hasta;
                clinicaId = clinicaId || seleccion.clinicaId;
                clinicaDoctorId = clinicaDoctorId ? clinicaDoctorId.clinicaDoctorId ? clinicaDoctorId : seleccion.clinicaDoctorId : seleccion.clinicaDoctorId;
                ausencia = ausencia == null ? seleccion.ausencia : ausencia;

                Desde = desde.toString.length < 8 ?  desde.toString().replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d*Z$/,'$1') : desde
                Hasta = hasta.toString.length < 8 ?  hasta.toString().replace(/^\d{4}-\d{2}-\d{2}T(\d{2}:\d{2}:\d{2}).\d*Z$/,'$1'): hasta

                let ValidarHora = Desde < Hasta ? true : false

                if (ftValidar([seleccion.horarioId]) === true) {
                    if (ValidarHora === true) {
                        await modHorario({
                            variables: {
                                horario: {
                                    horarioId: seleccion.horarioId,
                                    dias : dias.toString(),
                                    desde: Desde,
                                    hasta: Hasta,
                                    clinicaId,
                                    clinicaDoctorId: null,
                                    ausencia: ausencia

                                }
                            }
                        });
                        if (cleanStateFormulario) cleanStateFormulario();
                        refetch();
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: "La hora inicial no puede ser igual o mayor a la final" })
                    }

                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();

    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusHorarioClinica({
                variables: {
                    id: Number(seleccion.horarioId),
                    estatus: seleccion.estatus === false ? 1 : 0
                }
            });
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteHorario({ variables: { id: Number(seleccion.horarioId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //END REGION FUNCIONES
    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) });
        setActualizar(false);
    }

    function stateOutAsignar(valorID = {})  {

        setSeleccion({ ...seleccion,  ...valorID });
    }

    useEffect(() => {
      setDiasDB(ftSelectFormat(opcionesDia, {  id: "value",
                descripcion: "label"}));
    }, []);

    return (
        <MenuLateral titulo="Horarios de Sucursales" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto}
                onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Horario" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion}  datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOutAsignar} />

            <Tabla titulo="Horarios de Sucursales" columnas={columnas} datos=
                {listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.mensaje} onPaginaChange={handlePaginaChange} titleResponsive={["dias","desde","hasta","nombre"]} />
        </MenuLateral>
    );
}
