import React from 'react';

import { Dialog } from '@material-ui/core';

//componentes
import Titulo from './componentes/Titulo';
import Botones from './componentes/Botones';
import Contenido from './componentes/Contenido';

//estilos
import { makeStyles } from '@material-ui/core/styles';
import { DialogContent } from '@material-ui/core';

function Mensaje({ abrir, tipoModal, titulo, icono, tipo, color, texto,subParrafo='', onClose, onAccept, onCancel, classes, children, disabledAccept = false, progress = true, tituloEncabezado="Datos generales", newComponente }) {

    const clases = classes || {
        dialogo: makeStyles(theme => ({
            root: {
                position: 'relative',
                boxSizing: 'border-box',
                webkitBoxOrient: 'vertical',
                webkitBoxDirection: 'normal',
                flexDirection: 'column',
                webkitBoxPack: 'center',
                justifyContent: 'center',
                // width: '32em',
                maxWidth: '100%',
                padding: '1.25em',
                border: 'none',
                borderRadius: '.3125em',
                fontFamily: 'inherit',
                fontSize: '1rem',
            }
        }))(),
        titulo: makeStyles(theme => ({
            main: {
                margin: 0,
                padding: theme.spacing(2),
                textAlign: 'center'
            },
            btnCerrar: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(),
        contenido: makeStyles(theme => ({
            root: {
                textAlign: 'center',
                padding: '25px 50px',
                color: '#545454',
                fontSize: '1.125em',
                fontWeight: '400',
                lineHeight: 'normal',
                textAlign: 'center',
                wordWrap: 'break-word'
            }
        }))(),
        botones: makeStyles(theme => ({
            root: {
                padding: theme.spacing(1),
            },
            Btn: {
                display: 'inline-block',
                borderLeftColor: 'rgb(48, 133, 214)',
                borderRightColor: 'rgb(48, 133, 214)',
                border: 0,
                borderRadius: '.25em',
                background: 'initial',
                backgroundColor: 'initial',
                backgroundColor: '#3085d6',
                color: '#fff',
                fontSize: '1.0625em',
                margin: '0 auto'
            },
            BtnCancel: {
                marginRight: '10px'
            },
            contenedor: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }))()
    };

    return (
        <Dialog open={abrir} onClose={onClose} aria-labelledby="dialog-title" className={clases.dialogo?clases.dialogo.root:""} maxWidth="xs" fullWidth>

            <Titulo texto={titulo} onClose={onClose} icono={icono} classes={clases.titulo} tipo={tipo} />

            <Contenido tipo={tipoModal} texto={texto} subParrafo={subParrafo} color={color} classes={clases.contenido} children={children} />

            { newComponente ? <DialogContent className={clases.contenido.root}>{ newComponente }</DialogContent>: null }

            <Botones tipo={tipo} classes={clases.botones} onAccept={onAccept} onCancel={onCancel || onClose} onClose={onClose} disabledAccept={disabledAccept} progress={progress} />

        </Dialog>
    );
}

export default Mensaje;



