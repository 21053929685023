import React, { useContext } from 'react';
import { CRMContext, CRMProvider } from '../../context/Configuracion';
import Configuracion from './Configuracion';

function ConfiguracionContext() {

    const [actualizar, setActualizar] = useContext(CRMContext);

    return (
        <CRMProvider value={[actualizar, setActualizar]}>
            <Configuracion />
        </CRMProvider>
    );
}

export default ConfiguracionContext;
