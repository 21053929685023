import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Usuario from './Formularios/Usuario';
import Administrador from './Formularios/Administrador';
import { CRMContext } from '../../../context/Usuarios/Usuarios';
//redux
import { useDispatch, useSelector } from "react-redux";
import { CRUD } from "../../../Redux/Actions/UsuariosAction";
//helper
import { agregarUsuario } from './../../../Helpers/constantes/State';
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
import { limpiarStateGeneralUsuarios } from '../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Modificar(props) {

    //Declaraciones
    const [boton, setBoton] = useState(false);
    const { actualiza, index } = props;
    let { open, seleccion, ftOpen, ftClose, setSeleccion } = props;
    const [datos, guardarDatos] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(agregarUsuario);
    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);
    const Crud = (categoria, tipo, evento, datos, desactivar, cerrar) => dispatch(CRUD(categoria, tipo, evento, datos, desactivar, cerrar));

    //Eventos
    const tipoUsuario = (tipo) => {
        let componente;

        if (userToken.tipo !== 4) {
            switch (tipo) {
                case 3://adm
                    componente = <Administrador seleccion={seleccion} />
                    break;
                case 4://Usuario
                    componente = <Usuario seleccion={seleccion} />
                    break;
            }
        } else {
            switch (tipo) {
                case 3://adm
                    ftClose();
                    break;
                case 4://Usuario
                    componente = <Usuario seleccion={seleccion} />
                    break;
            }
        }
        return componente;
    }
    const tipoLabelUsuario = (tipo) => {
        let label;
        switch (tipo) {
            case 3://adm
                label = "administrador";
                break;
            case 4://Usuario
                label = "usuario";
                break;
        }
        return label;
    }
    const handleClick = () => {
        switch (seleccion.tipo) {
            case 3://adm
                handleAdministrador()
                break;
            case 4://Usuario
                handleUsuario();
                break;
        }
    }
    const handleUsuario = () => {
        deshabilitarBoton(true);
        let campos = [
            "usuarioId",
            "nombres",
            "apellidos",
            "email",
            "telefono",
            "sexo",
            "fechaNacimiento"
        ];
        datos.Usuario.solicitanteId = { error: false, valor: userToken.usuarioId };
        datos.Usuario.tipo = { error: false, valor: userToken.tipo };
        let datosV = verificaValores(campos, datos.Usuario, [["sexo", "género"], ["fechaNacimiento", "fecha de nacimiento"], ["telefono", "teléfono"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(1, "Usuario", actualiza, datos.Usuario, (n) => deshabilitarBoton(n), () => ftClose());
            limpiarMensaje();

        }

    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }
    const handleAdministrador = () => {
        deshabilitarBoton(true);
        let campos = [
            "usuarioId",
            "nombres",
            "apellidos",
            "email",
            "sexo",
            "telefono",
            "fechaNacimiento"
        ];
        datos.Administrador.tipo = { error: false, valor: userToken.tipo };
        let datosV = verificaValores(campos, datos.Administrador, [["sexo", "género"], ["fechaNacimiento", "fecha de nacimiento"], ["telefono", "teléfono"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });


            Crud(1, "Administrador", actualiza, datos.Administrador, (n) => deshabilitarBoton(n), () => ftClose());
            limpiarMensaje();
        }

    }
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        guardarDatos(limpiarStateGeneralUsuarios);
        index(-1);
    }, [open]);


    return (
        <>

            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Modificar {tipoLabelUsuario(seleccion.tipo)}</DialogTitle>
                {/*formulario*/}

                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    {tipoUsuario(seleccion.tipo)}
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>

                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
                    </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }} onClick={(e) => { e.preventDefault() }}>
                        <Button
                            disabled={boton}
                            onClick={handleClick}
                            color="primary">
                            GUARDAR
                      </Button>

                    </a>

                </DialogActions>



            </Dialog>


        </>
    )
}
export default Modificar;
