import React, { useState } from 'react';

//Router
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { FILTRO_TELEFONOS, FILTRO_DIRECCIONES } from '../../../query';
import { NUEVO_TELEFONO, ELIMINAR_TELEFONO, MODIFICAR_TELEFONO } from '../../../mutation';

//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever, AddLocationIcon } from '../../../componentesGenericos/Icons';
import { Phone, Apartment } from '@material-ui/icons';

//Helpers
import { rgxTelefono } from '../../../Helpers/constantes/Rgx';

export default function Telefonos() {

    // Obtener el ID de la Clinica
    // let idWindow = window.location.href.split("/");
    // let id = idWindow[idWindow.length - 1]
    // id=id.replace('#arriba','');

    let { id } = useParams();

    // REGION STATES

    const [checked, setChecked] = React.useState(false);

    const [actualizar, setActualizar] = useState(false);

    const [fila, setFila] = useState(-1);

    const [filtro, setFiltro] = useState("");

    const [disabledDelete, setDisabledDelete] = useState(false);

    const [disabledAccept, setDisabledAccept] = useState(false);

    const [mensaje, setMensaje] = useState(mensajeInicial);

    const [campo, setCampo] = React.useState('');

    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [errorState, setErrorState] = useState(false);

    //END REGION STATES

    const { loading, error, data, refetch } = useQuery(FILTRO_TELEFONOS, {
        variables: {
            limite: 10,
            pagina: 1,
            direccionId: Number(id)
        },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const { data: dataDirecciones } = useQuery(FILTRO_DIRECCIONES, {
        variables: {
            limite: 100,
            pagina: 1,
            clinicaId: null,
            direccionId: Number(id)
        },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    // QUERY AND MUTATIONS

    const [deleteTelefono] = useMutation(ELIMINAR_TELEFONO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el número telefónico "${selecciond.telefono}"` });

            setSelecciond({ ...selecciond, ...seleccionTelefono });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addTelefono] = useMutation(NUEVO_TELEFONO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...Mensaje, ...mensajeExito, texto: `Se agregó correctamente el número telefónico "${selecciond.telefono}"` });

            setSelecciond({ selecciond, ...seleccionTelefono });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modTelefono] = useMutation(MODIFICAR_TELEFONO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el número telefónico` });

            setSelecciond({ ...selecciond, ...seleccionTelefono });
            setFila(-1);
            setActualizar(true);
        }
    });
    // END  QUERY AND MUTATIONS

    // REGION CONSTANTES


    const seleccionTelefono = {
        telefonoId: '',
        telefono: '',
        direccionId: ''

    }

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }]
        : [
            { id: 'telefonoId', label: "telefonoId", minWidth: 0, hide: true },
            { id: 'nombre', label: "Nombre de sucursal", minWidth: 100, align: 'center', textAlign: 'center' },
            { id: 'telefono', label: "Teléfono", minWidth: 100, align: 'center', textAlign: 'center' }
        ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoTelefonos?.telefonos;

    const [selecciond, setSelecciond] = useState(seleccionTelefono);

    const menu = [
        { texto: "Agregar teléfono", icono: <AddIcon />, onClick: () => handleAgregarTelefonoClick() },
        { texto: "Modificar teléfono", icono: <EditIcon />, onClick: () => handleModificarTelefono() },
        { texto: "Eliminar teléfono", icono: <DeleteForever />, onClick: () => handleEliminarTelefonoClick() },
        { texto: "Ir a sucursales", icono: <Apartment />, onClick: () => window.location.href = "/sucursales" }
        // { texto: "Ir a Direcciones", icono: <AddLocationIcon />, onClick: () => window.location.href = "/direccion" }
    ];

    let opcionClinicaDireccion = dataDirecciones ?
        dataDirecciones.listadoDirecciones.direcciones.map(valor => ({
            label: valor.nombreClinica,
            value: Number(valor.direccionId)
        })) : null

    const camposFormularioTelefono = [
        {
            id: "direccionId",
            texto: "Sucursal *",
            valor: id,
            tipo: "select",
            opciones: opcionClinicaDireccion,
            xs: 12,
            otros: {
                inputProps: { readOnly: true }
            },
            disabled:true,
            styleInput:{maxWidth:'500px',whiteSpace:'break-spaces'},
            styleMenuItem:{maxWidth:'500px',whiteSpace:'break-spaces'},
        },
        {
            id: "telefono",
            texto: "Número telefónico *",
            valor: selecciond.telefono,
            tipo: "text",
            xs: 12,
            regexp: rgxTelefono
        }
    ];

    // END REGION CONSTANTES

    // REGION ANDLERS

    function handleBusquedaChange(texto) {
        setFiltro(texto)
    }

    function handleAgregarTelefonoClick() {
        setSelecciond(seleccionTelefono)
        setFila(-1);
        setFormulario({ ...formulario, ...selecciond, abrir: true, onAccept: ftAgregarTelefono });
    }

    function handleModificarTelefono() {

        if (selecciond.telefonoId !== '') {

            setFormulario({ ...formulario, abrir: true, onAccept: ftModificarTelefono });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un número telefónico" });
        }
    }

    function handleEliminarTelefonoClick() {
        if (selecciond.telefonoId !== '') {

            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el número telefónico?`, onAccept: ftEliminarTelefono });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un número telefónico" });
        }
    }

    function handleTablaClick(datos, index) {

        if (selecciond !== datos) {
            setSelecciond(datos);
            setFila(index);
            setActualizar(datos.telefono);

        }
        else {
            setSelecciond(seleccionTelefono);
            setFila(-1);
        }
    }

    // END REGION ANDLERS

    /// REGION FUNCIONES

    function ftAgregarTelefono({ direccionId, telefono, cleanStateFormulario }) {
        async function ftAgregarTelefono() {
            try {
                setDisabledAccept(true);
                if (ftValidar([telefono]) === true) {
                    setSelecciond({ ...selecciond, direccionId, telefono });
                    await addTelefono({
                        variables: {
                            telefono: {
                                direccionId: Number(id),
                                telefono: telefono
                            }
                        }

                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto })
            }
        } ftAgregarTelefono();
    }

    function ftModificarTelefono({ telefono, cleanStateFormulario }) {
        async function ftModificarTelefono() {
            try {
                setDisabledAccept(true);
                telefono = telefono || selecciond.telefono;
                if (ftValidar([selecciond.telefonoId, telefono]) === true) {
                    await modTelefono({
                        variables: {
                            telefono: {
                                telefonoID: selecciond.telefonoId,
                                numeroTelefono: telefono


                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();
                    refetch();
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan valores obligatorios" })
                }
                setDisabledAccept(false);
            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificarTelefono();
    }

    function ftEliminarTelefono() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteTelefono({ variables: { id: Number(selecciond.telefonoId) } });
            setDisabledDelete(false);
            setActualizar(true);
        } ftEliminar();
    }

    function ftValidar(campos) {
        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(selecciond.estatus !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    // END REGION FUNCIONES
    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => { setErrorState(true); console.log(e.message) });
        setActualizar(false);
    }

    return (
        <MenuLateral titulo="Teléfonos" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto}
                onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormularioTelefono} titulo="Teléfono" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={selecciond} disabledAccept={disabledAccept} cleanAwait={true}  />

            <Tabla titulo="Teléfonos de la sucursal" columnas={columnas} datos=
                {listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error ?.mensaje} titleResponsive={["nombre", "telefono"]} />


        </MenuLateral>
    );


}
