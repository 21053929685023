import gql from 'graphql-tag';

//#region RECORDATORIOS
//==========================================
export const NUEVO_PROGRAMACION = gql`
	mutation agregarProgramacion($estatusID: ID!, $nuevoEstatusID: ID!, $dia: Int!, $hora: Int!, $minuto: Int!, $programarAntesCita: Boolean) {
        agregarProgramacion(input: {
            estatusID: $estatusID
            nuevoEstatusID: $nuevoEstatusID
            dia: $dia
            hora: $hora
            minuto: $minuto
            programarAntesCita: $programarAntesCita
        }) {
            programacionID
            estatusID
            descripcionEstatusCita
            colorEstatusCita
            nuevoEstatusID
            descripcionEstatusCitaNueva
            colorEstatusCitaNueva
            dia
            hora
            minuto
            programarAntesCita
        }
    }
`;
export const MODIFICA_PROGRAMACION = gql`
	mutation actualizarProgramacion($programacionID: ID!, $estatusID: ID!, $nuevoEstatusID: ID!, $dia: Int!, $hora: Int!, $minuto: Int!, $programarAntesCita: Boolean) {
        actualizarProgramacion(input: {
            programacionID: $programacionID
            estatusID: $estatusID
            nuevoEstatusID: $nuevoEstatusID
            dia: $dia
            hora: $hora
            minuto: $minuto
            programarAntesCita: $programarAntesCita
        }) {
            programacionID
            estatusID
            descripcionEstatusCita
            colorEstatusCita
            nuevoEstatusID
            descripcionEstatusCitaNueva
            colorEstatusCitaNueva
            dia
            hora
            minuto
            programarAntesCita
        }
    }
`;
export const ELIMINAR_PROGRAMACION = gql`
	mutation eliminarProgramacion($id: Int!) {
        eliminarProgramacion(id: $id)
    }
`;
//#endregion
//==========================================
