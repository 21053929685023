import React, { useState, useEffect } from 'react';
//apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_RELACION_DOCTOR, LISTADO_FOTOS_PROFESIONALES, LISTADO_FOTOS_PROFESIONALES_FETCH } from '../../query';
import MenuLateral from '../../componentesGenericos/SideBar';
import { Grid, Card, CardContent, ListItemIcon, ListItemText, ListItem, List, Divider, Typography } from '../../componentesGenericos/Core';
import CardMedia from '@material-ui/core/CardMedia';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, responsiveFontSizes } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
// URL SERVER
import { strUrlServer } from '../../../src/Helpers/constantes/Config/UrlServer';
import { blue } from '@material-ui/core/colors';
import { fontWeight } from '@material-ui/system';
import { getImagen } from '../../../src/Helpers/constantes/Imagenes/getImagen';
import { rutaPublica, rutaPrivada } from '../../../src/Helpers/constantes/Config/urlRutas'
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
import { responsePathAsArray } from 'graphql';
import RegistroDesk from '../Login/RecuperarPassword/RecuperarDesk';
import zIndex from '@material-ui/core/styles/zIndex';
import { actualizarState } from '../AutorizacionDatos/Constantes';
import { trim } from '../../Helpers';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        width: '100%'
    },
    fecha: {
        border: 'red 5px solid'
    },
    fondo: {
        backgroundColor: 'white'
    },
    card: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    inline: {
        color: '#323B88 '
    },
    listitem: {
        margin: '0px',
        padding: '0px'
    },
    textoitem: {
        color: '#646A9E',
        margin: '0px',
        padding: '0px',
        fontWeight: 'bolder',
        fontSize: '10px'
    }
}));


export default function ProfesionalesPaciente() {

    // Estilos
    const classes = useStyles();

    const [errorDatos, setErrorDatos] = useState(false);
    const [profesionales, setProfesionales] = useState([]);
    const [imagenResult, setImagen] = useState({usuarioId:'', foto:''});
    const [actualiza, setActualizar] = useState(false);



    let imagenHombre = '/FotosPerfil/fotoPerfil-AvatarHombre.png';
    let imagenMujer = '/FotosPerfil/fotoPerfil-AvatarMujern.png';
    let registros = [];
    let fotos = [];
    let resultados = []

    async function archivo(ruta, id) { //get storage

        let resultado = '';

        resultado = await getImagen(ruta);

        let valor = {
            id: id,
            foto: resultado
        }
        setImagen(fotos);
        return resultado;
    };
    const Obtener = async () => {
        await FetchGrahpQL({
            query: LISTADO_FOTOS_PROFESIONALES_FETCH,
            variables: {
                offset: 0
            }
        })
        .then(async  (resp) => {

                registros = resp.listadoFotosProfesionales.fotoProfesionales;
                let valor ;
                registros.map(async function(dato){
                let rutaServer = rutaPrivada+dato.ruta;
                let foto = '';
                let foto2 = '';
                    let num = (dato.ruta !== null && dato.ruta !== undefined && dato.ruta !== "")?  trim(dato.ruta).length : 0 ;
                    if (num > 0)
                    {
                       archivo(rutaServer, dato.usuarioId).then(imagen => {

                            valor = {
                                id: dato.usuarioId,
                                foto: imagen
                            }
                            foto = imagen;
                            dato.ruta = imagen;
                            fotos = imagen;
                            var tImg = document.getElementById(dato.usuarioId);
                            if(tImg){
                                tImg.src = imagen;
                            }
                            return imagen ;
                        });

                    }else{
                        foto= `${strUrlServer}${dato.ruta ? dato.ruta : dato.sexo === 1 ? imagenMujer : imagenHombre}`
                        valor = {
                                id: dato.usuarioId,
                                foto: foto
                        }
                    }
                    dato.ruta = foto;

                });
                setProfesionales(registros);


        })
        .catch((err) => {
            console.log('no hay registros')
        });
        setImagen(fotos);
    };


    useEffect(()=> {

        Obtener();

    },[])

    return (
        <MenuLateral titulo="Paciente Doctor">
            {/* encabezado del componente */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Encabezado titulo="Nuestros Profesionales" />
                </Grid>
            </Grid>
            <Grid container xs={12} direction="row" className={classes.fondo}>

                {
                profesionales.length === 0 ?
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>No se encontraron registros</Paper>
                        </Grid>
                    </Grid> :

                    profesionales.map((opcion, index) => {

                        return (

                            <Grid container xs={12} sm={6} md={4} direction="row">

                                <Paper className={classes.paper}>
                                    <List>
                                        <img
                                            style={{ marginTop: "10px", minHeight: "350px", maxHeight: "350px", height:"auto" }}
                                            alt="imagen de perfil"
                                            height="auto"
                                            title="Foto perfil"
                                            src = {opcion.ruta}  id = {opcion.usuarioId}
                                        />

                                        <ListItem className={classes.listitem}>
                                            <ListItemText className={classes.textoitem} >
                                                <Typography
                                                    component="span"
                                                    className={classes.inline}
                                                    variant="h6"
                                                >Nombre: </Typography>
                                                {opcion.nombre} {opcion.apellido}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listitem}>
                                            <ListItemText className={classes.textoitem}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color={"textPrimary"}
                                                    variant="h6"
                                                >Especialidad: </Typography>
                                                {opcion.especialidad}
                                            </ListItemText>
                                        </ListItem>
                                        {
                                            opcion.cedula !== null && opcion.cedula !== "" && opcion.cedula !== undefined ?
                                                <ListItem className={classes.listitem}>
                                                    <ListItemText className={classes.textoitem}>
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            className={classes.inline}
                                                            color={"textPrimary"}
                                                            variant="h6"
                                                        >Cédula profesional: </Typography>
                                                        {opcion.cedula}
                                                    </ListItemText>
                                                </ListItem> : null
                                        }
                                        <ListItem className={classes.listitem}>
                                            <ListItemText className={classes.textoitem}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color={"textPrimary"}
                                                    variant="h6"
                                                >Tel: </Typography>
                                                {opcion.telefono}
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem className={classes.listitem}>
                                            <ListItemText className={classes.textoitem}>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color={"textPrimary"}
                                                    variant="h6"
                                                >Email: </Typography>
                                                {opcion.email}
                                            </ListItemText>
                                        </ListItem>

                                    </List>


                                </Paper>
                            </Grid>
                        )
                    })
                }

            </Grid>




        </MenuLateral>
    )
}
