import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Agregar from './Formularios/Agregar';
import { CRMContext } from '../../../context/AsignacionClinicas';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/ClinicaDoctoresAction";
//helper
import { agregarUsuario } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function AgregarRelacion(props) {

    //Declaraciones
    const { actualiza, seleccion,index } = props;
    let { open, ftClose } = props;
    const [boton,setBoton] = useState(false);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(agregarUsuario);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const Crud = (tipo, evento, datos,desactivar,cerrar) => dispatch(CRUD(tipo, evento, datos,desactivar,cerrar));

    //Eventos
    const handleEspecialidades = async () => {
        deshabilitarBoton(true);
        let campos = [
            "doctorId",
            "clinicaId"
        ];
        let data = await procesarInformacion(datos.Clinicas.doctorId.valor);
        let datosV = verificaValores(campos, data,[["doctorId","profesional"]]);
        let mensaje = formatearCadena(datosV.arr);

        if (datos.Clinicas.doctorId.valor[0] === undefined) {
            mensaje += "Profesionales"
            datosV.estado = false;
        }

        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            let data = await procesarInformacion(datos.Clinicas.doctorId.valor);
            Crud(0, actualiza, data,(n)=>  deshabilitarBoton(n),()=>ftClose());
            limpiarMensaje();
        }

    }

    //funciones
    const procesarInformacion = async (arr) => {
        let data = "";
        arr.forEach(element => {
            data += element.substr(element.indexOf("-") + 1, element.lenght) + ",";
        });
        data = data.substr(0, data.length - 1);
        let obj = datos;
        obj = {
            ...datos,
            Clinicas: {
                ...datos.Clinicas,
                doctorId: {
                    ...datos.Clinicas.doctorId,
                    error: true, valor: data
                }
            }
        }
        return obj;
    }
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }


    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        guardarDatos({
            Clinicas: {
                doctorId: { error: false, valor: "" },
                clinicaId: { error: false, valor: seleccion.clinicaId },
            }
        })
        // index(-1);
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>{`${seleccion.nombre}`}</DialogTitle>
                {/*formulario*/}
                <DialogContent style={{padding:0, margin:0}}>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Agregar  ftClose={ftClose}/>
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
             </Button>
                    <Button
                        onClick={handleEspecialidades}
                        disabled={boton}
                        color="primary">
                        GUARDAR
                      </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AgregarRelacion;
