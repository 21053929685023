import gql from 'graphql-tag';

//#region Estatus Agenda
//=====================================================
const LISATADO_ESTATUS_AGENDA = gql`
  query listadoEstatus($limite: Int, $pagina: Int) {
    listadoEstatusCita(limite: $limite, pagina: $pagina) {
      registros
      paginas
      estatusCitas {
        estatusID
        estatusPredeterminado
        descripcionEstatus
        colorEstatus
        citaNoModificable
        omitirValidacion
        redirigirPuntoVenta
        horaNoModificable
      }
    }
  }
`;
//#endregion
//=====================================================

export {
    LISATADO_ESTATUS_AGENDA
}

