//importanciones de los iconos
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import Icon from '@material-ui/core/Icon';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import Person from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Menu from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import Favorite from '@material-ui/icons/Favorite';
import BusinessIcon from '@material-ui/icons/Business';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteForever from '@material-ui/icons/DeleteForever';
import RefreshIcon from '@material-ui/icons/Refresh';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import SearchIcon from '@material-ui/icons/Search';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ComputerIcon from '@material-ui/icons/Computer';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import LensIcon from '@material-ui/icons/Lens';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import MessageIcon from '@material-ui/icons/Message';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLabelIcon from '@material-ui/icons/GroupAdd';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LockIcon from '@material-ui/icons/Lock';
import SendIcon from '@material-ui/icons/Send';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PrintIcon from '@material-ui/icons/Print';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import { Phone, Apartment } from '@material-ui/icons';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import StyleIcon from '@material-ui/icons/Style';
import DomainIcon from '@material-ui/icons/Domain';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const AntSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[600]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.grey[600],
    },
    checked: {},
}))(Switch);

const AntSwitchSecondary = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.common.white,
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.secondary,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[600]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.grey[600],
    },
    checked: {},
}))(Switch);

export {
    WifiOffIcon, BusinessIcon, AddIcon, EditIcon, BlockIcon, Person as PersonIcon, ChevronRight as ChevronRightIcon, WebAssetIcon,
    ChevronLeft as ChevronLeftIcon, Menu as MenuIcon, Home as HomeIcon, Favorite as FavoriteIcon, RefreshIcon, PersonAddIcon as PersonAdd, DeleteForever, AntSwitch as SwitchIcon, NotificationsNoneIcon,
    SearchIcon, CalendarTodayIcon, AccountCircle as AccountCircleIcon, Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, LockOpenIcon, EditLocationIcon, PowerSettingsNewIcon, ComputerIcon, ScheduleIcon, CheckCircleIcon, ErrorIcon, InfoIcon, CloseIcon, WarningIcon, LensIcon, CheckCircleRoundedIcon, CallIcon, EmailIcon, MessageIcon, NotificationsIcon, SettingsIcon, VideoLabelIcon, GroupAddIcon, LockIcon, SendIcon, AccountCircleRoundedIcon, ImportExportIcon, StorefrontIcon, PrintIcon,Icon,AntSwitchSecondary as SwitchIconSecondary, AddLocationIcon, Phone, Apartment,CloudUploadIcon, PostAddIcon, LibraryBooksIcon,LockOutlinedIcon,AssignmentIcon,MeetingRoomIcon,QueryBuilderIcon,StyleIcon,DomainIcon,ThumbUpIcon,PersonOutlineIcon,NoteAddIcon

};


