import decode from "jwt-decode";

export const getUsuario = (token) => {
    let user;

    try {
        if (token != null) {
            user = decode(token);
        }
    } catch (e) {
        return null;
    }

    return user;
}
