import gql from 'graphql-tag';

//=============================================
//#region TELEFONOS====================
const FILTRO_TELEFONOS = gql`
query filtroTelefonos($limite: Int, $pagina: Int, $direccionId: Int){
  listadoTelefonos(limite:$limite , pagina:$pagina, direccionId: $direccionId){
   telefonos{
    telefonoId
    nombre
    telefono

  }
  }
}`;

const FILTRO_TELEFONOS_FETCH = `
query filtroTelefonos($limite: Int, $pagina: Int, $direccionId: Int){
  listadoTelefonos(limite:$limite , pagina:$pagina, direccionId: $direccionId){
   telefonos{
    telefonoId
    nombre
    telefono

  }
  }
}`;
//#endregion

export {
    FILTRO_TELEFONOS,
    FILTRO_TELEFONOS_FETCH
}
