import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function Encabezado({titulo}) {

    return(<AppBar position="static" style={{ zIndex: 0 }}>
        <Toolbar>
            <div style={{ textAlign: 'center', margin: '0 auto' }}>
                <Typography variant="h6"  align="center">
                    {titulo}
                </Typography>
            </div>
        </Toolbar>
    </AppBar>);
}
