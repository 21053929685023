import gql from 'graphql-tag';

//#region  ANAMNESI
//==========ANAMNESI============================

const LISTADO_ANAMNESIS = gql `
query listadoAnamnesis
{
  listadoAnamnesis{
    anamnesis{
      anamnesisId
      nombreCompleto
      pacienteId
      fechaAlta
      padecimientos
      padecimientoId
      email
      telefono
      fechaNacimiento
      genero
      descripcionId
    }
  }
}
 `;

const FILTRO_RECETA_PARTIDAS_USER_FETCH = `
query listadoHistorialClienteReceta ($recetaId: Int, $pacienteId: Int, $pagina: Int, $limite: Int) {
    FiltroPartidasUsuario(recetaId: $recetaId, pacienteId: $pacienteId, pagina:$pagina, limite:$limite){
      RecetaPartidaUser{
      recetaId
      agendaId
      tituloCita
      descipcionCita
      servicioId
      servicioAgenda
      clinicaDoctoId
      clinicaId
      doctorId
      pacienteId
      nombrePaciente
      apellidoPaciente
      sexoPAciente
      emailPaciente
      telefonoPaciente
      descripcionReceta
      nombreDoctor
      apellidoDoctor
      emailDoctor
      cedulaDoctor
      especialidadDoctorId
      telefonoDoctor
      especialidadDoctor
      nombreClinica
      fechaReceta
      productoId
      descripcionProducto
      codigoProducto
      codigoBarras
      productoNoenVenta
      anotacionesProducto
      cantidad
      partidaId
      resultible
      }
      paginas
      registros
    }
  }`;

const LISTADO_ANAMNESIS_FETCH =  `
query listadoAnamnesis
{
  listadoAnamnesis{
    anamnesis{
      anamnesisId
      fotoId
      fotoPerfil
      nombreCompleto
      pacienteId
      fechaAlta
      padecimientos
      padecimientoId
      email
      telefono
      fechaNacimiento
      generoId
      genero
      descripcionId
    }
  }
}
 `;

 const LISTADO_ANAMNESIS_ENCABEZADO_FETCH = `
 query listadoAnamnesisEncabezado($pacienteId: Int){
  listadoAnamnesisEncabezado(pacienteId: $pacienteId){
    anamnesis{
      anamnesisId
      fotoId
      fotoPerfil
      nombreCompleto
      pacienteId
      fechaAlta
      padecimientos
      padecimientoId
      email
      telefono
      fechaNacimiento
      generoId
      genero
    }
  }
}
 `;

 const LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH = `
 query listadoHistorialClienteObservacionesPadecimientos($pacienteId: Int) {
  listadoHistorialClienteObservacionesPadecimientos(pacienteId: $pacienteId){
    historialClienteObservacionesPadecimientos{
          anamnesisId
          fechaAltaAnamnesis
          pacienteId
          nombreCompleto
          email
          telefono
          anamnesisPadecimientoId
          descripcionAnamnesisPadecimiento
          padecimientoId
          descripcionPadecimiento
    }
  }
}
 `;

 const LISTADO_HISTORIAL_CLIENTE_RECETA = `
 query listadoHistorialClienteReceta ($pacienteId: Int, $fechaReceta: Date, $fechaFin: Date){
  listadoHistorialClienteReceta(pacienteId: $pacienteId, fechaReceta: $fechaReceta, fechaFin: $fechaFin)
  {
    historialClienteReceta{
      recetaId
      agendaId
      descripcionReceta
      fechaReceta
      pacienteId
      nombreCompletoPaciente
      telefonoPaciente
      correoPaciente
      clinicaDoctorId
      clinicaId
      nombreClinica
      doctorId
      nombreCompletoDoctor
      telefonoDoctor
      correoDoctor
    }
  }
}
 `;
//#endregion
//===============================================

export {
    LISTADO_ANAMNESIS,
    LISTADO_ANAMNESIS_FETCH,
    LISTADO_ANAMNESIS_ENCABEZADO_FETCH,
    LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH,
    LISTADO_HISTORIAL_CLIENTE_RECETA,
    FILTRO_RECETA_PARTIDAS_USER_FETCH
}
