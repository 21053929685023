import React, {useState,useEffect} from 'react';

//Apollo
import { useQuery,useMutation } from '@apollo/react-hooks';

//Queries
import { LISTAR_FORMA_COBRO } from '../../../query/componentes/FormaCobro';
import { SELECT_CLINICAS_FETCH } from '../../../query/componentes/Agenda';
import { OBTENER_FORMA_PAGO } from '../../../query/componentes/Convenio';
import { NUEVA_FORMA_COBRO, MODIFICAR_FORMA_COBRO, ELIMINAR_FORMA_COBRO} from '../../../mutation/componentes/FormaCobro';
//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';

//constantes
import { mensajeConfirmacion,mensajeError,mensajeExito, mensajeInicial } from '../../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../../componentesGenericos/Icons';
//Redux
import { useSelector } from 'react-redux';
//Helpers
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat, urlSat } from '../../../Helpers';
import {strUrlSat} from '../../../Helpers/constantes/Config/UrlServer'

//Expresiones regulares
import { rgxNoVacio,rgxAlmenosUnaLetra } from '../../../Helpers/constantes/Rgx';
import { cuatroCaracteres } from '../../../Helpers/constantes/formatos';

import { isArray } from 'util';

export default function FormaCobro() {

    //#region redux
    const user = useSelector ((state )=> state.selecciondeClinica);
    let clinicaIdRedux = user.seleccionClinica[0].clinicaId;

    //#region queries & Mutations


    let { loading, error, data, refetch } = useQuery(LISTAR_FORMA_COBRO, {
        variables: { clinicaId:Number(clinicaIdRedux) },
        onError: (e) =>{
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () =>{
            setFila(-1)
            setSeleccion(seleccionInicial)
            setErrorState(false)

        }
    });

    const [addFormaCobro] = useMutation(NUEVA_FORMA_COBRO, {
        onError: (e) => {
            throw new Error (e.message.replace("GraphQL error:", ""))

        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente la forma de cobro` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modFormaCobro] = useMutation(MODIFICAR_FORMA_COBRO, {
    onError: (e) => {
        throw new Error (e.message.replace("GraphQL error:", ""))

    },
    onCompleted: () => {
        ftFormularioClose();
        setDisabledAccept(false);

        setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la forma de cobro` });

        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setActualizar(true);
    }
    });

    const [deleteFormaCobro] = useMutation(ELIMINAR_FORMA_COBRO,{
    onError: (e) =>{
        setMensaje({
            ...mensaje,
            ...mensajeError,
            texto: e.message.replace("GraphQL error:", "")
        });
    },
    onCompleted:(e) =>{
        setMensaje({
            ...mensaje,
            ...mensajeExito,
            texto: `Se eliminó correctamente la forma de cobro`
        });

        setSeleccion({
            ...seleccion,
            ...seleccionInicial
        });

        setFila(-1);
        setActualizar(true);
    }
    })

//#endregion
//#region states
const seleccionInicial = {
    formaCobroId: null,
    formaCobro: [],
    formaCobroAux: null,
    auxfc: null,
    descripcion: '',
    clinicaId: 0,
    nombreClinica: null,
    aliasClinica: null,
    estatusClinica: null,
    claveClinica: null,
    fechaAltaClinica: null,
}



const [disabledAccept, setDisabledAccept] = useState(false);
const [disabledDelete, setDisabledDelete] = useState(false);
const [clinicas, setClinicas] = useState([]);
const [formaCobro, setFormaCobro] = useState([]);
const [formatoFC, setFormatoFC] = useState([]);
const [auxFCDB, setauxFCDB] = useState([]);
const [filtro, setFiltro] = useState("");
const [actualizar, setActualizar] = useState(false);
const [fila, setFila] = useState(-1);
const [mensaje, setMensaje] = useState(mensajeInicial);
const [seleccion, setSeleccion] = useState(seleccionInicial);
const [formulario, setFormulario] = useState({
          abrir: false,
          titulo: "Formulario",
          onClose: ftFormularioClose,
          onAccept: null,
          onCancel: null
      });

const [errorState, setErrorState] = useState(false);
const [stateCopy, setStateCopy] = useState({});
//#endregion

//#region constantes



    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'formaCobroId', label: 'formaCobro id', minWidth: 0, hide: true },


     { id: 'formaCobro', label: 'Forma de cobro', minWidth: 100, align: 'left', filtro: true, textAlign: 'left',
     format: (fila) => {
        let aux ="";

        fila=JSON.parse(fila);

       for (let valor of fila)
       {
        aux = aux +valor.descripcion +',';
       }
         if(-aux.endsWith(","))aux=aux.substring(0,aux.length-1)

          return aux;
    }
  },
        { id: 'descripcion', label: 'Descripción', minWidth: 170, align: 'left',textAlign: 'left',filtro: true },
     { id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left', filtro: true,
     format: (fila) => {
         return fila.toString().replace("", "").replace(seleccion.clinicaId, seleccion.nombreClinica);
         }
  },

    ];

    const menu = [
        {
            texto: "Agregar forma de cobro",
            icono: <AddIcon />,
            onClick: () => handleAgregarClick()
        },
        {
            texto: "Modificar forma de cobro",
            icono: <EditIcon />,
            onClick: () => handleModificarClick()
        },
        {
            texto: "Eliminar forma de cobro",
            icono: <DeleteForever />,
            onClick: () => handleEliminarClick()
        }
    ];


    const camposFormulario = [
        {
            id: "formaCobroId",
            texto: "formaCobroId",
            valor: seleccion.formaCobroId,
            tipo: "hidden"
        },
        {
            id: "formaCobro",
            texto: "Forma de cobro *",
            valor: seleccion.formaCobro,
            tipo: "select",
            opciones:formaCobro,
            multiselect: true,
            xs:12,
            onChange: handleGetFC,
            ayuda: "Seleccione una forma de cobro",
            regexp: rgxNoVacio,
        },
        {
            id: "descripcion",
            texto: "Descripción *",
            valor: seleccion.descripcion,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "clinicaId",
            texto: "Sucursal *",
            valor: seleccion.clinicaId ,
            tipo: "select",
            opciones : clinicas,
            xs: 12,
            ayuda: "Seleccione una sucursal",
            regexp: rgxNoVacio,
            styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
            styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        }

    ]

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoFormaCobro?.formaCobros;

    const queryClinicas = () => {
        async function queryClinicas() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: {
                        limite: 10000,
                        activas: true
                    }
                });

                let {listadoClinicas:{clinicas}} = resultado;

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: "nombre",
                }, "Seleccione una sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinicas();
    }

    const queryFormaCobro = () => {
        async function queryFormaCobro() {
            try {
                let resultado = await FetchGrahpQL({
                    url: strUrlSat,
                    query: OBTENER_FORMA_PAGO,
                });

                let {getFormaPago} = resultado;

                return ftSelectFormat(getFormaPago, {
                    id: "claveFormaPago",
                    descripcion: "descripcion",
                }, "");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryFormaCobro();
    }

function handleGetFC  (e) {
    let {value} = e.target;

    let FC = value.map(valor =>{
    let auxVal=valor;

    let res = formatoFC.filter ( valor =>{
        if (Number(valor.clave) === Number(auxVal))
        return valor;
    })

    return res;
    })

    let objetofc = JSON.stringify(FC);
    let objetofc2 = JSON.parse(objetofc);
    let auxObjFC = [];
   for(let i=0; i<objetofc2.length;i++)
   {
    auxObjFC.push(objetofc2[i][0]);
   }

   auxObjFC = auxObjFC.sort(function (a, b){
            if ( a.descripcion < b.descripcion )
              return -1;
            if ( a.descripcion > b.descripcion )
              return 1;
            return 0;
        })

    auxObjFC = JSON.stringify(auxObjFC);
    setSeleccion(seleccion =>({
        ...seleccion,
        formaCobroAux: auxObjFC
    }))

}


//#endregion

//#region handlers

function handleAgregarClick() {
    setSeleccion(seleccionInicial)
    setFila(-1);
    setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
}

function handleModificarClick() {
    if (seleccion.formaCobroId !== null && seleccion.formaCobroId !== undefined) {
          let aux ="";
          let fcDB;
          if(!isArray(seleccion.formaCobro))
          setauxFCDB(seleccion.formaCobro);
         let fc = null;
          if (seleccion.auxfc == null)
          {
              fc = seleccion.formaCobro;
            if (isArray(fc))
                fc = auxFCDB
            fc=JSON.parse(fc);
                for (let valor of fc)
                {
                aux = aux +valor.clave +',';
                }
                if(-aux.endsWith(","))aux=aux.substring(0,aux.length-1)
                aux = aux.toString().split(',');
            setSeleccion({
                ...seleccion,
                ...stateCopy,
                formaCobro: aux,
                auxfc: seleccion.formaCobro
            });
          }
          else
          {
            if (isArray(fc))
            {
                fc = auxFCDB
                fc=JSON.parse(fc);
            }
            else
            {
                if (isArray(seleccion.auxfc))
                {
                    fc = auxFCDB;
                    fc=JSON.parse(fc);
                }
                else
                {
                    fc=JSON.parse(seleccion.auxfc);
                }
            }
            for (let valor of fc)
            {
             aux = aux +valor.clave +',';
            }
              if(-aux.endsWith(","))aux=aux.substring(0,aux.length-1)
              aux = aux.toString().split(',');
            setSeleccion({
                ...seleccion,
                ...stateCopy,
                formaCobro: aux,
                auxfc: null
            });
          }
        setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
    } else {
        setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una forma de cobro" });
    }
}

function handleEliminarClick() {
    if (seleccion.formaCobroId !== null && seleccion.formaCobroId !== undefined) {
        setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la forma de cobro "${seleccion.descripcion}" de la sucursal ${seleccion.nombreClinica}?`, onAccept: ftEliminar });
    } else {
        setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una forma de cobro" });
    }
}

function handleTablaClick(datos, index) {
    if(seleccion !== datos){
        setSeleccion(datos);
        setStateCopy(datos);
        setFila(index);
    }else{
        setSeleccion(
            seleccionInicial
        );
        setFila(-1);
    }
 }

 function handleBusquedaChange(texto) {
  setFiltro(texto)
  setSeleccion({ ...seleccion, ...seleccionInicial });
  setFila(-1);
}

function handlePaginaChange(nuevaPagina) {
    setSeleccion({ ...seleccion, ...seleccionInicial });
    setFila(-1);
}

//#endregion


function ftAgregar ({formaCobro,formaCobroAux,descripcion,clinicaId,cleanStateFormulario}){
    async function ftAgregar()
    {
        try{

            if(!formaCobroAux || (!Array.isArray(formaCobroAux) && formaCobroAux.length === 0)) throw new Error("Es necesario elegir minímo una forma de cobro");
            if(descripcion.trim() === "" || !descripcion) throw new Error("Es necesario capturar una descripción");
            if (Number(clinicaId) === 0) throw new Error("Se debe de seleccionar una sucursal");

            setDisabledAccept(true);

            setSeleccion({...seleccion,formaCobro,formaCobroAux,descripcion,clinicaId});

            if (ftValidarObligatorios([formaCobro,descripcion.trim(),clinicaId]) === true  )
                {
                  await  addFormaCobro({
                        variables: {
                            formaCobro: {
                               formaCobro: formaCobroAux.toString(),
                               descripcion: descripcion.trim(),
                               clinicaId: Number(clinicaId)
                            }
                        }
                    });
                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
        }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

function ftModificar({ formaCobroId,formaCobro,formaCobroAux,descripcion,clinicaId,cleanStateFormulario }) {
    async function ftModificar()
    {
        try{


            if(!formaCobroAux || (!Array.isArray(formaCobroAux) && formaCobroAux.length === 0)) throw new Error("Es necesario elegir minímo una forma de cobro");
            if(descripcion.trim() === "" || !descripcion) throw new Error("Es necesario capturar una descripción");
            if (Number(clinicaId) === 0) throw new Error("Se debe de seleccionar una sucursal");

            setDisabledAccept(true);

            if (ftValidarObligatorios([formaCobro,descripcion.trim(),clinicaId]) === true  ) {
            await    modFormaCobro({
                variables: {
                    formaCobro: {
                       formaCobroId: Number(formaCobroId) ,
                       formaCobro: formaCobroAux ? formaCobroAux.toString() : seleccion.formaCobro.toString(),
                       descripcion: descripcion.trim(),
                       clinicaId: Number(clinicaId)
                    }
                }
                });

                if(cleanStateFormulario) cleanStateFormulario();

            } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }


        }catch({message:texto}) {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto });
        }
    } ftModificar();
}

function ftEliminar() {
    async function ftEliminar() {
        setDisabledDelete(true);
       await deleteFormaCobro({ variables: { id: Number(seleccion.formaCobroId) } });
        setDisabledDelete(false);
    } ftEliminar();
}

function ftValidarObligatorios(campos) {
    if (campos[1] === "" || campos[1] === undefined) return "Debe especificar una descripción";

    return campos.every((valor) => (valor !== null && valor !== undefined ));
}

function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
}

function ftFormularioClose() {

        setFormulario({ ...formulario, abrir: false });
}
//#endregion

function stateOut(valorID = {})  {
    setSeleccion({
        ...seleccion,
        ...valorID
    });
}

if (actualizar === true) {
    refetch().then(() => setErrorState(false)).catch(e => {
        console.log(e.message);
        setErrorState(true);
    });
    setActualizar(false);
}

useEffect(() => {
    async function ftEffect() {

        const clinicas = await queryClinicas();
        let fc = await queryFormaCobro();
        setClinicas(clinicas)
        fc = fc.filter( ({label})=> label.trim().toLowerCase() !=="por definir" )
        setFormaCobro(fc)
        let axfcs = fc.map (valor=>({
            descripcion: valor.label,
            clave : valor.value
        }))
        setFormatoFC(axfcs)
    }
    ftEffect();
}, []);

useEffect(() => {
    setActualizar(true);
}, [clinicaIdRedux]);

return (
    <MenuLateral titulo="Forma de cobro" menu={menu}>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete}/>

        <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Forma de cobro" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion}  disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

        <Tabla titulo={"Listado de formas de cobro"} columnas={columnas}
        datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["formaCobro", "descripcion", "nombreClinica"]} />

    </MenuLateral>
);

}
