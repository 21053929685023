import gql from 'graphql-tag';

//#region Recordatorio
//=====================================================
const LISATADO_RECORDATORIO = gql`
  query listadoRecordatorios($limite: Int, $pagina: Int) {
    listadoRecordatorios(limite: $limite, pagina: $pagina) {
        recordatorios {
        estatusID
            descripcionEstatus
            colorEstatus
            estatusPredeterminado
            recordatorioID
            medio
            dia
            hora
            minuto
            tituloRecordatorio
            descripcionRecordatorio
            notificarAntesCita
            confirmacionEmailID
            descripcionEstatusConfirmacionEmail
            colorEstatusConfirmacionEmail
            descripcionConfirmacion
        }
        registros
        paginas
    }
    }
`;
//#endregion

export {
    LISATADO_RECORDATORIO
}
