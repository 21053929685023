import gql from 'graphql-tag';

//#region tickets
const LISTADO_TICKETS = gql`
query listadoticket($clinicaId:Int, $doctorId:Int, $convenioId:Int, $vendedorId:Int, $clienteId:Int,$serieId:String,$folioId:String,$fecha:Date){
  listadoTickets(clinicaId: $clinicaId, doctorId: $doctorId, convenioId: $convenioId , vendedorId: $vendedorId, clienteId: $clienteId,serieId: $serieId,folioId: $folioId,fecha:$fecha){
    tickets{
        estatus,
        ticketId,
        ticketId,
        servicioId,
        codProd,
        descripcion,
        cantidad,
        comision,

        porcentajeDescuento,
        porcentajeDescuentoConvenio,
        porcentajeIva,

        precioUnitario,
        precioUnitarioDescuento,
        precioUnitarioDescuentoConvenio,

        descuentoUnitario,
        descuentoConvenioUnitario,
        descuentoUnitarioTotal,

        ivaUnitario,
        ivaUnitarioDescuento,
        ivaUnitarioTotal,

        precioUnitarioTotal,

        descuentoCantidad,
        descuentoConvenioCantidad,
        precioDescuentoCantidad,
        precioDescuentoConvenioCantidad,

        descuentoTotal,
        ivaTotal,
        precioTotal,

        garantiaId,
        garantia,

        empresaId,
        clinicaId,
        clinica,
        nombreClinica,
        almacenId,
        direccionId,

        vendedorId,
        vendedor,
        clienteId,
        cliente,
        doctorId,
        doctor,

        formasCobro,
        formasCobroCantidad,
        formasCobroTexto,
        metodoPago,
        clavePago,

        serieId,
        serie,
        folioId,
        folio,
        turnoId,
        turno,

        conceptoId,
        tipoDocumentoId,

        importePago,
        cambio,
        descuentoTotalTicket,
        descuentoTotalConvenioTicket,
        descuentoTotalFinalTicket,
        subtotalTicket,
        ivaTotalTicket,
        totalTicket,

        calle,
        codigoPostal,
        colonia,
        estado,
        localidad,
        municipio,

        fechaEmision,

        historialPagoId,
        historialPagoPartidaId,
        fechaLimite,
        fechaPagado,
        numeroPago,
        numeroTotalPagos,
        estatusAbono,
        estatusHistorial
    }
  }
}
`;

const OBTENER_TICKET = gql`
query obtener($id:Int,$serie:String,$folio:String){
  obtenerTicket(ticketId:$id,serie:$serie,folio:$folio){
    ticketId,
    ticketEncabezadoId,
        ticketId,
        servicioId,
        codProd,
        descripcion,
        cantidad,
        comision,

        porcentajeDescuento,
        porcentajeDescuentoConvenio,
        porcentajeIva,

        precioUnitario,
        precioUnitarioDescuento,
        precioUnitarioDescuentoConvenio,

        descuentoUnitario,
        descuentoConvenioUnitario,
        descuentoUnitarioTotal,

        ivaUnitario,
        ivaUnitarioDescuento,
        ivaUnitarioTotal,

        precioUnitarioTotal,

        descuentoCantidad,
        descuentoConvenioCantidad,
        precioDescuentoCantidad,
        precioDescuentoConvenioCantidad,

        descuentoTotal,
        ivaTotal,
        precioTotal,

        garantiaId,
        garantia,

        empresaId,
        clinicaId,
        clinica,
        nombreClinica,
        almacenId,
        direccionId,

        vendedorId,
        vendedor,
        clienteId,
        cliente,
        doctorId,
        doctor,

        formasCobro,
        formasCobroCantidad,
        formasCobroTexto,
        metodoPago,
        clavePago,

        serieId,
        serie,
        folioId,
        folio,
        turnoId,
        turno,

        conceptoId,
        tipoDocumentoId,

        importePago,
        cambio,
        descuentoTotalTicket,
        descuentoTotalConvenioTicket,
        descuentoTotalFinalTicket,
        subtotalTicket,
        ivaTotalTicket,
        totalTicket,

        calle,
        codigoPostal,
        colonia,
        estado,
        localidad,
        municipio,

        fechaEmision,

        historialPagoId,
        historialPagoPartidaId,
        fechaLimite,
        fechaPagado,
        numeroPago,
        numeroTotalPagos,
        estatusAbono,
        estatusHistorial,

        porcentajeInteres
  }
}
`;
const OBTENER_TICKET_FETCH = `
query obtener($id:Int,$serie:String,$folio:String){
  obtenerTicket(ticketId:$id,serie:$serie,folio:$folio){
    ticketId,
    ticketEncabezadoId,
        ticketId,
        servicioId,
        codProd,
        descripcion,
        cantidad,
        comision,

        porcentajeDescuento,
        porcentajeDescuentoConvenio,
        porcentajeIva,

        precioUnitario,
        precioUnitarioDescuento,
        precioUnitarioDescuentoConvenio,

        descuentoUnitario,
        descuentoConvenioUnitario,
        descuentoUnitarioTotal,

        ivaUnitario,
        ivaUnitarioDescuento,
        ivaUnitarioTotal,

        precioUnitarioTotal,

        descuentoCantidad,
        descuentoConvenioCantidad,
        precioDescuentoCantidad,
        precioDescuentoConvenioCantidad,

        descuentoTotal,
        ivaTotal,
        precioTotal,

        garantiaId,
        garantia,

        empresaId,
        clinicaId,
        clinica,
        nombreClinica,
        almacenId,
        direccionId,

        vendedorId,
        vendedor,
        clienteId,
        cliente,
        doctorId,
        doctor,

        formasCobro,
        formasCobroCantidad,
        formasCobroTexto,
        metodoPago,
        clavePago,

        serieId,
        serie,
        folioId,
        folio,
        turnoId,
        turno,

        conceptoId,
        tipoDocumentoId,

        importePago,
        cambio,
        descuentoTotalTicket,
        descuentoTotalConvenioTicket,
        descuentoTotalFinalTicket,
        subtotalTicket,
        ivaTotalTicket,
        totalTicket,

        calle,
        codigoPostal,
        colonia,
        estado,
        localidad,
        municipio,

        fechaEmision,

        historialPagoId,
        historialPagoPartidaId,
        fechaLimite,
        fechaPagado,
        numeroPago,
        numeroTotalPagos,
        estatusAbono,
        estatusHistorial,

        porcentajeInteres
  }
}
`;
//#endregion

const LISTADO_TICKET_TEMPORAL = gql`
query listadoTicketTemporal($clinicaId:Int,$vendedorId:Int){
    listadoTicketTemporal(clinicaId: $clinicaId,vendedorId: $vendedorId){
    tickets{
        estatus,
        ticketId,
        ticketId,
        servicioId,
        descripcion,
        cantidad,

        porcentajeDescuento,
        porcentajeDescuentoConvenio,
        porcentajeIva,

        precioUnitario,
        precioUnitarioDescuento,
        precioUnitarioDescuentoConvenio,

        descuentoUnitario,
        descuentoConvenioUnitario,
        descuentoUnitarioTotal,

        ivaUnitario,
        ivaUnitarioDescuento,
        ivaUnitarioTotal,

        precioUnitarioTotal,

        descuentoCantidad,
        descuentoConvenioCantidad,
        precioDescuentoCantidad,
        precioDescuentoConvenioCantidad,

        descuentoTotal,
        ivaTotal,
        precioTotal,

        garantiaId,
        garantia,

        empresaId,
        clinicaId,
        clinica,
        nombreClinica,
        almacenId,
        direccionId,

        vendedorId,
        vendedor,
        clienteId,
        cliente,
        doctorId,
        doctor,

        formasCobro,
        formasCobroCantidad,
        metodoPago,
        clavePago,

        serieId,
        serie,
        folioId,
        folio,
        turnoId,
        turno,

        conceptoId,
        tipoDocumentoId,

        importePago,
        cambio,
        descuentoTotalTicket,
        descuentoTotalConvenioTicket,
        descuentoTotalFinalTicket,
        subtotalTicket,
        ivaTotalTicket,
        totalTicket,

        calle,
        codigoPostal,
        colonia,
        estado,
        localidad,
        municipio,

        fechaEmision
    }
  }
}
`;

const LISTADO_COLA_TICKET = gql`
query listadoCola($clinicaId:Int){
    listadoCola(clinicaId:$clinicaId){
    tickets{
        estatus,
        ticketEncabezadoId,
        ticketId,
        servicioId,
        descripcion,
        cantidad,

        porcentajeDescuento,
        porcentajeDescuentoConvenio,
        porcentajeIva,

        precioUnitario,
        precioUnitarioDescuento,
        precioUnitarioDescuentoConvenio,

        descuentoUnitario,
        descuentoConvenioUnitario,
        descuentoUnitarioTotal,

        ivaUnitario,
        ivaUnitarioDescuento,
        ivaUnitarioTotal,

        precioUnitarioTotal,

        descuentoCantidad,
        descuentoConvenioCantidad,
        precioDescuentoCantidad,
        precioDescuentoConvenioCantidad,

        descuentoTotal,
        ivaTotal,
        precioTotal,

        garantiaId,
        garantia,

        empresaId,
        clinicaId,
        clinica,
        nombreClinica,
        almacenId,
        direccionId,

        vendedorId,
        vendedor,
        clienteId,
        cliente,
        correo,
        doctorId,
        doctor,

        formasCobro,
        formasCobroCantidad,
        metodoPago,
        clavePago,

        serieId,
        serie,
        folioId,
        folio,
        turnoId,
        turno,

        conceptoId,
        tipoDocumentoId,

        importePago,
        cambio,
        descuentoTotalTicket,
        descuentoTotalConvenioTicket,
        descuentoTotalFinalTicket,
        subtotalTicket,
        ivaTotalTicket,
        totalTicket,

        calle,
        codigoPostal,
        colonia,
        estado,
        localidad,
        municipio,

        fechaCreacion
    }
  }
}
`;

const OBTENER_TICKETS_IDS = gql `
query obtenerListadoIds($ticketEncabezadoId:Int, $Fecha: Date)
{
  obtenerTicketsId(ticketEncabezadoId: $ticketEncabezadoId, fecha: $Fecha)
  {
    ticketsObtenerId
    {
        ticketEncabezadoId
        ticketId
        clienteId
        folioId
        folio
        serieId
        serie
    }
  }
}
`;
const OBTENER_TICKETS_IDS_FETCH =  `
query obtenerListadoIds($ticketEncabezadoId:Int, $Fecha: Date)
{
  obtenerTicketsId(ticketEncabezadoId: $ticketEncabezadoId, fecha: $Fecha)
  {
    ticketsObtenerId
    {
        ticketEncabezadoId
        ticketId
        clienteId
        folioId
        folio
        serieId
        serie
    }
  }
}
`;

export {
    LISTADO_TICKETS,
    OBTENER_TICKET,
    OBTENER_TICKET_FETCH,
    LISTADO_TICKET_TEMPORAL,
    LISTADO_COLA_TICKET,
    OBTENER_TICKETS_IDS,
    OBTENER_TICKETS_IDS_FETCH
}
