import { NOTIFICACIONES_CREDITO } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_RECORDATORIO_CREDITO,MODIFICIAR_RECORDATORIOS_CREDITOS} from "../../mutation/componentes/RecordatorioCreditos";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos, ActivarDesactivarBoton, ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {

                    FetchGrahpQL({
                        query: NUEVO_RECORDATORIO_CREDITO,
                        variables: {
                            plazoCredito: parseInt(datos.plazoCreditoId.valor),
                            tituloRecordatorio: datos.tituloRecordatorio.valor.trim(),
                            descripcionRecordatorio: datos.descripcionRecordatorio.valor.trim(),
                            dias: parseInt(datos.dias.valor),
                            recordarAntesDespues: parseInt(datos.recordarAntesDespues.valor),
                        }
                    })
                        .then(() => {
                            Swal.fire("¡Éxito!", `Se ha creado correctamente`, "success");
                            ActivarDesactivarBoton(false);
                            actualiza();
                            ftClose();
                        })
                        .catch((err) => {
                            if (err.message === "Failed to fetch") {
                                Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                            } else {
                                Swal.fire("¡Aviso!", err.message, "warning");
                            }
                            ActivarDesactivarBoton(false);


                        });

        }else{
            FetchGrahpQL({
                query: MODIFICIAR_RECORDATORIOS_CREDITOS,
                variables: {
                    recordatorioPagoId: parseInt(datos.recordatorioPagoId.valor),
                    plazoCreditoId: parseInt(datos.plazoCreditoId.valor),
                    tituloRecordatorio: datos.tituloRecordatorio.valor.trim(),
                    descripcionRecordatorio: datos.descripcionRecordatorio.valor.trim(),
                    dias: parseInt(datos.dias.valor),
                    recordarAntesDespues: parseInt(datos.recordarAntesDespues.valor)
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se ha modificado correctamente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);

                });
        }
        dispatch(AgregarUsuario({ activo: true }))
    }
}


export const AgregarUsuario = usuariosCrud => {
    return {
        type: NOTIFICACIONES_CREDITO,
        payload: usuariosCrud
    }
}




