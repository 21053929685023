import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Input from '../../Reportes/componentes/Input';
import Icono from '../../../componentesGenericos/Icono';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { queryConfiguracionPuntoVenta, queryConfiguracionPuntoVentaDecrypt, mutationUpdateConfiguracionPuntoVenta } from '../Metodos';

import { CRMContext } from '../../../context/Configuracion';
import { rgxPassword } from '../../../Helpers/constantes/Rgx';

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ConfiguracionPuntoVenta({ setMensaje }) {

    const classes = useStyles();

    const clases = {
        botones: makeStyles(theme => ({
            root: {
                padding: theme.spacing(1),
            },
            Btn: {
                margin: '0 auto'
            },
            BtnCancel: {
                marginRight: '10px'
            },
            BtnNew: {
                marginLeft: '10px'
            },
            contenedor: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }))()
    };

    const ayuda = 'La contraseña debe contener una letra mayúscula, minúscula, número y un carácter especial {"(@#$%¡!*=<>()|¿?&-)"}, tener como mínimo 8 caracteres y máximo 30 caracteres';

    const defaultState = {
        activarSeguridadPuntoVenta: false,
        seguridadPuntoVenta: '',
        confirmarSeguridadPuntoVenta: '',
        passwordAdmin: ''
    };

    const defaultStateDesbloqueo= {
        passwordAdmin: ''
    };

    const defaultError = {
        seguridadPuntoVenta: false,
        confirmarSeguridadPuntoVenta: false
    };

    const defaultCountVisible = 10;

    const defaultPasswordSeguridad = "*****************";

    const imgBloquear = <Icono ruta="/icons/candado.png" height="60px" marginRight="0px" left="0px" texto="configuracion-logotipo" />,
        imgDesbloquear = <Icono ruta="/icons/desbloquear.png" height="60px" marginRight="0px" left="0px" texto="configuracion-logotipo" />;

    const [ state, setState ] = useState(defaultState);
    const [ stateDesbloqueo, setStateDesbloqueo ] = useState(defaultStateDesbloqueo);
    const [ passwordSeguridad, setPasswordSeguridad ] = useState(defaultPasswordSeguridad);
    const [ countVisible, setCountVisible ] = useState(defaultCountVisible);
    const [ error, setError ] = useState(defaultError);
    const [ bloqueaPassword, setBloqueaPassword ] = useState(true);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [ disabledAcceptDesbloqueo, setDisabledAcceptDesbloqueo ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);

	const campos = [{
        id: 'activarSeguridadPuntoVenta',
        texto: 'Activar contraseña de seguridad',
        valor: state.activarSeguridadPuntoVenta,
        tipo: 'checkbox',
        cleanDatoState: [{ id: "seguridadPuntoVenta", value: '' },{ id: "confirmarSeguridadPuntoVenta", value: '' },{ id: "passwordAdmin", value: '' }],
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'seguridadPuntoVenta',
        texto: 'Capture la nueva contraseña de seguridad',
        valor: state.seguridadPuntoVenta,
        tipo: 'password',
        propiedades: { readOnly: false },
        xs: 12,
        disabled: !state.activarSeguridadPuntoVenta,
        regexp: rgxPassword,
        ayuda,
        error: error.seguridadPuntoVenta
    },{
        id: 'confirmarSeguridadPuntoVenta',
        texto: 'Confirme la contraseña de seguridad',
        valor: state.confirmarSeguridadPuntoVenta,
        tipo: 'password',
        propiedades: { readOnly: false },
        xs: 12,
        disabled: !state.activarSeguridadPuntoVenta,
        regexp: rgxPassword,
        ayuda,
        error: error.confirmarSeguridadPuntoVenta
    },{
        id: 'passwordAdmin',
        texto: 'Capture su contraseña de usuario',
        valor: state.passwordAdmin,
        tipo: 'password',
        propiedades: { readOnly: false },
        xs: 12,
        disabled: !state.activarSeguridadPuntoVenta
    }];

    const camposDesbloqueo = [{
        id: 'passwordAdmin',
        texto: 'Capture su contraseña de usuario',
        valor: stateDesbloqueo.passwordAdmin,
        tipo: 'password',
        propiedades: { readOnly: false },
        xs: 12
    }]

	function handleChange({target}, id, multiselect, onChange, cleanDatoState, val, validarRegexp) {
        let { value, type, checked } = target;
        value = type === "checkbox" ? checked : value;
        let limpiar = {}, limpiarError = {};

        if(Array.isArray(cleanDatoState) && state[id] != value) cleanDatoState.forEach(({id, value}) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
            limpiarError = {
                ...limpiarError,
                [id]: false
            }
        });

        setState({
            ...state,
            ...limpiar,
            [id]: value
        });
        if(cleanDatoState) setError(limpiarError);
        validarRegexp({ id, value, setError });
    }

    function handleChangeDesbloqueo({target}, id) {
        let { value} = target;
        setStateDesbloqueo({ ...stateDesbloqueo, [id]: value });
    }

    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                let input = { ...state };

                if(input.activarSeguridadPuntoVenta) {
                    if(!input.seguridadPuntoVenta) throw new Error("Debe capturar la contraseña de seguridad");
                    if(!input.confirmarSeguridadPuntoVenta) throw new Error("Es necesario que confirme la contraseña de seguridad");
                    if(input.seguridadPuntoVenta !== input.confirmarSeguridadPuntoVenta) throw new Error("Las contraseñas capturadas son diferentes");
                    if(!input.passwordAdmin || input.passwordAdmin === '') throw new Error("Es necesario que capture su contraseña");
                }

                delete input.confirmarSeguridadPuntoVenta;

                let { error } = await mutationUpdateConfiguracionPuntoVenta(input);

                if(error) throw new Error(error);

                setActualizar(true);
                setState({
                    ...state,
                    seguridadPuntoVenta: '',
                    confirmarSeguridadPuntoVenta: '',
                    passwordAdmin: ''
                });
                hanldeClickCancel();
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: input.activarSeguridadPuntoVenta ? '¡La contraseña de seguridad se guardo correctamente!' : '¡La contraseña de seguridad se desactivo correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
            }
            setDisabledAccept(false);
        }
        click();
    }

    function handleClickAcceptDesbloqueo() {
        async function click() {
            try {
                setDisabledAcceptDesbloqueo(true);

                let password = stateDesbloqueo.passwordAdmin;

                if(!password) throw new Error("Es necesario que capture la contraseña de su usuario para poder desbloquear la contraseña de seguridad del punto de venta");

                let { error, seguridadPuntoVenta } = await queryConfiguracionPuntoVentaDecrypt({ password });

                if(error) throw new Error(error);

                if(!seguridadPuntoVenta || seguridadPuntoVenta === '') throw new Error("Por el momento no existe ninguna contraseña de seguridad");

                setActualizar(true);
                setBloqueaPassword(false);
                setStateDesbloqueo(defaultStateDesbloqueo);
                setPasswordSeguridad(seguridadPuntoVenta);
                ftVisiblePassword();
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡La contraseña de seguridad se desbloqueo correctamente!'
                });


            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
            }
            setDisabledAcceptDesbloqueo(false);
        }
        click();
    }

    function hanldeClickCancel() {
        setBloqueaPassword(true);
        setStateDesbloqueo(defaultStateDesbloqueo);
        setPasswordSeguridad(defaultPasswordSeguridad);
    }

    function ftVisiblePassword() {
        let tiempo = defaultCountVisible;
        let intervalo = setInterval(() => {
            tiempo = tiempo - 1;
            setCountVisible(tiempo);
            if(tiempo === 0) {
                hanldeClickCancel();
                setCountVisible(defaultCountVisible);
                clearInterval(intervalo);
            }
        }, 1000);
    }

    useEffect(() => {
        async function ftEffect() {
            const configuracion = await queryConfiguracionPuntoVenta();
            const { error:descripcion } = configuracion;
            if(descripcion) setMensaje({
                visible: true,
                tipo: 'error',
                descripcion
            });
            else setState({
                ...state,
                ...configuracion
            })
        } ftEffect();
    }, []);

    return(<>
        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
            <Typography variant="h5" component="h5">Mostrar/ocultar contraseña de seguridad</Typography>
            <div style={{ maxWidth: "1000px", width: "100%", height: "40px" }}></div>
            { bloqueaPassword ? null : <>
                <Typography variant="p" component="p">Tiempo restante para visualizar la contraseña: <b>{countVisible} segundo{Number(countVisible) === 1 ? '' : 's'}</b></Typography>
                <div style={{ maxWidth: "1000px", width: "100%", height: "40px" }}></div>
            </>}
            { bloqueaPassword ? imgBloquear : imgDesbloquear }
            <Typography variant="h3" component="h3">&nbsp;&nbsp;&nbsp;{passwordSeguridad}</Typography>
            <Input campos={camposDesbloqueo} handleChange={handleChangeDesbloqueo} state={stateDesbloqueo} />
            <Botones classes={classes.Btn} txtBotones={{ btnCancelar: "Ocultar", btnAceptar: "Mostrar" }} onAccept={handleClickAcceptDesbloqueo} onCancel={hanldeClickCancel} disabledAccept={disabledAcceptDesbloqueo} classes={clases.botones} tipo="confirmacion" />
        </Grid>
        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
            <div style={{ maxWidth: "1000px", width: "100%", height: "40px" }}></div>
            <div style={{ maxWidth: "1000px", width: "100%", height: "2px", backgroundColor: "black" }}></div>
            <div style={{ maxWidth: "1000px", width: "100%", height: "40px" }}></div>
        </Grid>
        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
            <Typography variant="h5" component="h5">Modificar contraseña de seguridad para el punto de venta</Typography>
            <div style={{ maxWidth: "1000px", width: "100%", height: "40px" }}></div>
            <Input campos={campos} handleChange={handleChange} state={state} />
            <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Guardar" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
        </Grid>
    </>);
}

export default ConfiguracionPuntoVenta;
