import { ANAMNESIS } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVO_ANAMNESIS_FETCH, MODIFICAR_ANAMNESIS_FETCH, ELIMINAR_ANAMNESI_FETCH } from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos, ActivarDesactivarBoton, ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVO_ANAMNESIS_FETCH,
                variables: {
                    nuevoAnamnesis: {
                        anamnesisId: 0,
                        pacienteId: parseInt(datos.pacienteId.valor),
                        padecimientoId: parseInt(datos.padecimientoId.valor),
                        descripcion: datos.descripcion.valor.trim(),
                        agregar: 0,
                    }
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se agregó  correctamente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }
        if (categoria === 1) {
            FetchGrahpQL({
                query: MODIFICAR_ANAMNESIS_FETCH,
                variables: {
                    modAnamnesis: {
                        anamnesisId: parseInt(datos.anamnesisId.valor),
                        pacienteId: parseInt(datos.pacienteId.valor),
                        padecimientoId: parseInt(datos.padecimientoId.valor),
                        descripcion: datos.descripcion.valor,
                        agregar: 0,
                    }
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se modificó correctamente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }

        if (categoria === 2) {
            FetchGrahpQL({
                query: ELIMINAR_ANAMNESI_FETCH,
                variables: {
                    anamnesisId: parseInt(datos.anamnesisId.valor),
                    padecimientoId: parseInt(datos.padecimientoId.valor)
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se modificó correctamente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }
        if (categoria === 3) {
            FetchGrahpQL({
                query: MODIFICAR_ANAMNESIS_FETCH,
                variables: {
                    modAnamnesis: {
                        anamnesisId:  parseInt(datos.anamnesisId.valor),
                        pacienteId: parseInt(datos.pacienteId.valor),
                        padecimientoId: parseInt(datos.padecimientoId.valor),
                        descripcion: datos.descripcion.valor.trim(),
                        agregar: 1,
                    }
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se agregó  correctamente`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }
        dispatch(AgregarEspecialidad({ activo: true }))
    }
}


export const AgregarEspecialidad = usuariosCrud => {
    return {
        type: ANAMNESIS,
        payload: usuariosCrud
    }
}



