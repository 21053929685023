import React, { useState, useContext } from 'react';
import { CRMContext, CRMProvider } from './../../context/Anamnesis';
//Apollo*****
import { useQuery } from '@apollo/react-hooks';
//Queries****
import { LISTADO_ANAMNESIS } from '../../query';
import { Anamnesis } from '../../Helpers/constantes/State';
//****ROUTER***********/
import { withRouter } from "react-router-dom";
//Componentes***
import AgregarPadecimiento from './agregarPadecimiento';
import Agregar from './AgregarCliente';
import Modificar from './Modificar';
import Eliminar from './Eliminar';
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';

//constantes***
import { seleccionInicial, menu, filaState, formularioState, OptionsUsuarios, Style } from './Constantes';
import { mensajeInicial, mensajeError } from '../../Helpers/constantes';


function Cliente(props) {

    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_ANAMNESIS, {
        variables: { offset: 0, moroso: null }, onError: (e) => { }
    });

    //#endregion

    //#region states
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState({ Anamnesis });
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [dobleClic, setDobleClic] = useState(false);
    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));


    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    const [openAgregarPadecimiento, setOpenAgregarPadecimiento] = React.useState(false);
    const [openEliminar, setOpenEliminar] = React.useState(false);
    let { history } = props;
    //#endregion

    //seleccion para columnas
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'pacienteId', label: 'Clave de cliente', minWidth: 0, hide: false, filtro: true, aliasColor: "estatus" },
            { id: 'anamnesisId', label: 'Clave de anamnesis', minWidth: 0, hide: false, filtro: true },
            { id: 'nombreCompleto', label: 'Nombre completo del cliente', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
            { id: 'genero', label: 'Género', minWidth: 0, hide: false, align: 'left', textAlign: 'left' },
            { id: 'padecimientos', label: 'Padecimientos', minWidth: 0, hide: false, align: 'left', textAlign: 'left' },
            { id: 'descripcionId', label: 'descripcion', minWidth: 0, hide: true },
            { id: 'email', label: 'Email', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
            { id: 'telefono', label: 'Teléfono', minWidth: 0, hide: false },
            {
                id: 'fechaNacimiento', label: 'Fecha de nacimiento', minWidth: 170, align: 'center', textAlign: 'center', filtro: true,
                format: (valor) => {
                    return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1") : '' ;
                }
            },
            {
                id: 'fechaAlta', label: 'Fecha de alta del expediente', minWidth: 170, align: 'center', textAlign: 'center', filtro: true,
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
                }
            },

        ];


    //#region handlers

    function handleSetOpenAgregarPadecimiento() {
        setOpenAgregarPadecimiento(true);
    }

    function handleSetCloseAgregarPadecimiento() {
        setSeleccion({...seleccionInicial})
        setOpenAgregarPadecimiento(false);
    }
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
        setSeleccion({...seleccionInicial})
    }

    function handleSetOpenEliminar() {
        setOpenEliminar(true);
    }

    function handleSetCloseEliminar() {
        setSeleccion({...seleccionInicial})
        setOpenEliminar(false);
    }


    function handleAgregarClick() {
        ftCloseRespuestaAbrir();
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }

    function handleModificarClick() {
        if (seleccion.anamnesisId !== null && seleccion.anamnesisId !== undefined && fila !== -1) {
            handleSetOpenModifica();
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
        }
    }

    function handleAgregarPadecimientoClick() {
        if (seleccion.anamnesisId !== null && seleccion.anamnesisId !== undefined && fila !== -1) {
            handleSetOpenAgregarPadecimiento();
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
        }
    }


function handleEliminarPadeciminetoClick() {
    if (seleccion.anamnesisId !== null && seleccion.anamnesisId !== undefined && fila !== -1) {
        handleSetOpenEliminar();
        // setFila(-1);
    } else {
        setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un cliente" });
    }
}



function handlePaginaChange(nuevaPagina) {
    setSeleccion({ ...seleccion, ...seleccionInicial });
    setFila(-1);
}

function handleTablaClick(datos, index) {
    if (seleccion.pacienteId !== datos.pacienteId) {
        setSeleccion({...datos});
        setFila(index);
        setDobleClic(true)
        setTimeout( ()=> {setDobleClic(false)}, 500)
    }
    else {
        setSeleccion(seleccionInicial);
        setFila(-1);
        if (dobleClic)
        {
            window.location.href = "/historialAnamnesis/" + seleccion.pacienteId
        }
    }
}


const actualizarTabla = () => {
    refetch().then(() => setErrorState(false)).catch(e => {

        setErrorState(true);
    });
    setActualizar(false);
}

const redirige = (texto) => {
    history.push(texto);
}


//#endregion

//#region funciones



function ftMensajeClose() {
    setMensaje({ ...mensaje, abrir: false });
}

function ftFormularioClose() {
    setFormulario({ ...formulario, abrir: false });
    setSeleccion({...seleccionInicial})
}

function ftCloseRespuestaAbrir() {
    setOpen(true);
    setSeleccion({...seleccionInicial})
    setFila(-1);
}

const handleClose = newValue => {
    setOpen(false);
    setSeleccion({...seleccionInicial})
    if (newValue) {
        setValue(newValue);
    }
};

//#endregion

//#region actualiza


const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoAnamnesis?.anamnesis;

if (actualizar === true) {

    refetch().then(() => setErrorState(false)).catch(e => {

        setErrorState(true);
    });
    setActualizar(false);
}



//#endregion


return (<>
    <CRMProvider value={[usuarios, guardarUsuarios]}>
        <MenuLateral titulo="Listado de anamnesis" menu={menu(handleAgregarClick, handleModificarClick, handleEliminarPadeciminetoClick, handleAgregarPadecimientoClick)}>

            <Agregar
                id="ringtone-menu"
                keepMounted
                open={open}
                onClose={handleClose}
                value={value}
                datos={OptionsUsuarios}
                botonder={"Agregar"}
                botonizq={"Cancelar"}
                actualiza={() => actualizarTabla()}
                seleccion={seleccion}
                ftClose={handleClose}
            />

            <AgregarPadecimiento open={openAgregarPadecimiento} seleccion={seleccion} ftOpen={handleSetOpenAgregarPadecimiento} ftClose={handleSetCloseAgregarPadecimiento} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />

            <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />

            <Eliminar open={openEliminar} seleccion={seleccion} ftOpen={handleSetOpenEliminar} ftClose={handleSetCloseEliminar} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Tabla titulo="Listado de anamnesis" titleColumna="Ver historial" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreCompleto","genero","padecimientos","email"]} />

        </MenuLateral>
    </CRMProvider>
</>
);
}

export default withRouter(Cliente);
