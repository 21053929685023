import React, { useState, useEffect } from 'react'

//Apollo
import { useQuery } from '@apollo/react-hooks';

//Componentes
import { Grid, TextField } from '../../../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';

//queries
import { OBTENER_DOCTORES_SERVICIO } from '../../../../query';

function Doctores({ id, variables = {}, onChange, xs = 3, align = "center", justify = "flex-start", style, value, disabled, onFinishLoading = () => { }, tipo = 1 }) {

    const doctorInicial = [{ usuarioId: -1, nombreCompleto: "Sin profesionales", cedula: 0 }];
    const [doctores, setDoctores] = useState(doctorInicial);
    const [seleccion, setSeleccion] = useState(value || -1);
    const [activo, setActivo] = useState(false);

    const { loading, data: doctorList = null } = useQuery(OBTENER_DOCTORES_SERVICIO, {
        variables,
        onError: (e) => {
            if (tipo === 1) {
                setDoctores(doctorInicial);
                setSeleccion(-1);
                onChange({ usuarioId: -1 });
                setActivo(false);
            }
            onFinishLoading(true);
        },
        onCompleted: () => {
            let opciones = [];

            if (doctorList) {
                doctorList.ObtenerDoctoresServicio.serviciosDoctores.map(doc => {
                    let añadido = false;

                    opciones.map(op => {
                        if ((op.usuarioId === doc.usuarioId) && !añadido) {
                            añadido = true;
                            op.servicios.push(doc.servicioId);
                        }
                    })

                    if (!añadido)
                        opciones.push({ ...doc, servicios: [doc.servicioId] });

                });
            }
            if (opciones.length === 0) {
                onChange({ usuarioId: -1 });
            }
            setDoctores(opciones);
            setActivo(true);
            onFinishLoading(true);
        }
    });

    const DoctoresChange = (e, val) => {
        if (!val) {
            val = { usuarioId: -1 }
        }
        onChange(val);
        setSeleccion(val.usuarioId);
    };

    useEffect(() => {

        setSeleccion(value || -1)

        let res = doctores.find(d => d.usuarioId === value)

        if (!res) {
            setSeleccion(-1)
            DoctoresChange(null, null)
        }

    }, [value]);

    useEffect(() => {

        let res = doctores.find(d => d.usuarioId === seleccion)

        if (!res) {
            DoctoresChange(null,  { usuarioId: seleccion })
        }

    }, [doctores]);

    return (
        <Grid container justify={justify} alignItems={align} item xs={xs} style={style}>

            <Autocomplete
                id={id}
                value={(() => {

                    return (seleccion === -1 ? null : doctores ? doctores.find(d => d.usuarioId === seleccion) || null : null)
                })()}
                noOptionsText="Sin profesionales"
                options={doctores}
                onChange={DoctoresChange}
                getOptionLabel={op => `${op.nombreCompleto} ${op.cedula ? `[${op.cedula}]` : ""}`}

                renderInput={
                    params => (
                        <TextField {...params} label="Profesional" margin="none" fullWidth />
                    )
                }

                renderOption={
                    (option, { selected }) => (
                        <div style={{ color: option.color }} >
                            {`${option.nombreCompleto} [${option.cedula}]`}
                        </div>
                    )
                }

                style={{ width: '100%' }}
                autoHighlight
                disabled={disabled === true && seleccion !== -1 ? disabled : (loading || !activo) ? true : false}
            />

        </Grid>
    )
}

export default Doctores;
