import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

import MenuLateral from '../../componentesGenericos/SideBar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function AccesoNovalido() {

  const classes = useStyles();

  return (
    <MenuLateral titulo="Acceso no permitido">
        <div className={classes.root}>
            <Alert severity="error">¡Lo sentimos! No cuenta con los permisos correspondientes</Alert>
            <div style={{ textAlign: "center" }}>
                <img
                    src="./images/accesodenegado.jpg"
                    title="Acceso no permitido"
                    alt="Acceso no permitido"
                    style={{
                        maxWidth: "1500px",
                        width: "100%",
                    }}
                />
            </div>
        </div>
    </MenuLateral>
  );
}

