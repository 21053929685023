import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Encabezado from './componentes/Encabezado';
import Paginacion from './componentes/Paginacion';
import Columnas from './componentes/Columnas';
import Cuerpo from './componentes/Cuerpo';
import Acordeon from './componentes/Acordeon';
import { Grid } from '../Core';

import useMediaQuerys from '../MediaQuerys';

const useStyles = makeStyles({
    root: {
        width: '100%',
        textAlign: 'center',
        margin: '0 auto 1px auto',

    },
    container: {
        display: 'flex',
        maxHeight: '75vh',
        Width: "100%"

    },
    encabezado: {
        margin: '0px 0'
    },
    paginacion: {
        backgroundColor: '#f0f0f0'
    }
});

export default function Tabla({ titulo = "", h, titleColumna,encabezado = true, filtro, filtrosSeleccionables = [], columnas, datos, onClick, onSearch, indice, busqueda, claseFila, claseCelda, claseEncabezado, claseTabla,clasePaginacion, hover, seleccionable, onPaginaChange=()=>{}, components, noResponsive = false, titleResponsive = [] }) {
    // console.log("datos", datos)

    const classes = useStyles();
    const [pagina, setPagina] = React.useState(0);
    const [filasPorPagina, setFilasPorPagina] = React.useState(10);
    let tituloBusqueda = "";

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    const handlePaginaChange = (event, nuevaPagina) => {
        setPagina(nuevaPagina);
        onPaginaChange(nuevaPagina);
    };

    const handleFilasPorPaginaChange = event => {
        setFilasPorPagina(+event.target.value);
        setPagina(0);

        onPaginaChange(event.target.value);
    };
    // console.log("filtro", filtro)
    if (datos && filtro) {
        let filtros = columnas.filter(v => v.filtro === true);
        let regex = filtro.replace(/([\(\)\*\.\[\]\+\{\}\\\/\?\¿\¡\!\^\&\%\#\|])/g, "\\$&");
        regex = new RegExp(regex, "i");

        datos = datos.filter(val => {

            for (let i = 0; i < filtros.length; i++) {
                let formato = columnas.find(colum => {
                    if (colum) {
                        return colum.id === filtros[i].id;
                    } else {
                        return null
                    }
                });
                let valor = val[filtros[i].id];

                if (formato.format) {
                    valor = formato.format(val[filtros[i].id]);
                }


                if (regex.test((valor + ""))) {

                    if (filtrosSeleccionables.length)
                        return filtrosSeleccionables.some(filsel => {
                            if (filsel.valor.match(val[filsel.campo] + '') !== null)
                                return true;
                        })
                    else {
                        return true;
                    }
                }
            }
            return false;
        });
    }

    const handleFiltrosChange = (e) => {
        // console.log(e.target.value)
    }

    columnas.map(v => {
        if (v.label !== "" && v.filtro === true) tituloBusqueda += v.label + ", ";
    });

    if (tituloBusqueda.endsWith(', '))
        tituloBusqueda = "Buscar por " + tituloBusqueda.substring(0, tituloBusqueda.length - 2);
    else
        tituloBusqueda = "Buscar";

    if (datos) {
        if (pagina * filasPorPagina >= datos.length && pagina > 0) {
            setPagina(pagina - 1);
        }
    }
    // console.log(filtrosSeleccionables?.componenteProps)
    return (
        <Paper className={classes.root} elevation={5}>

            {
                responsive && !noResponsive ? <Acordeon
                    columnas={columnas}
                    datos={datos}
                    titleColumna={titleColumna}
                    pagina={pagina}
                    filasPagina={filasPorPagina}
                    onClick={onClick}
                    indice={indice}
                    claseCelda={claseCelda}
                    claseFila={claseFila}
                    hover={hover}
                    seleccionable={seleccionable}
                    encabezado={encabezado}
                    filtro={filtro}
                    titulo={titulo}
                    onSearch={onSearch}
                    busqueda={busqueda}
                    tituloBusqueda={tituloBusqueda}
                    filtros={filtrosSeleccionables?.componenteProps}
                    onFiltrosChange={handleFiltrosChange}
                    titleResponsive={titleResponsive}
            /> : <TableContainer className={classes.container} xs={12} style={claseTabla}>
                    <Grid container item xs={12}
                        justify="center" alignitems="center">
                        {encabezado === true ?
                            <Encabezado filtro={filtro} titulo={titulo} h={h} classes={classes} onSearch={onSearch} busqueda={busqueda} tituloBusqueda={tituloBusqueda} filtros={filtrosSeleccionables?.componenteProps} onFiltrosChange={handleFiltrosChange} />
                            : null
                        }

                        <Table stickyHeader aria-label="table" components={components}>
                            <Columnas columnas={columnas} claseEncabezado={claseEncabezado} />

                            <Cuerpo datos={datos} titleColumna={titleColumna} pagina={pagina} filasPagina={filasPorPagina} columnas={columnas} onClick={onClick} indice={indice} claseCelda={claseCelda} claseFila={claseFila} hover={hover} seleccionable={seleccionable} />
                        </Table>
                    </Grid>
                </TableContainer>
            }

            <Paginacion datos={datos} filasPagina={filasPorPagina} pagina={pagina} ftPaginaChange={handlePaginaChange} ftFilasPaginaChange={handleFilasPorPaginaChange} clasePaginacion={classes.paginacion} estiloPaginacion={clasePaginacion}/>
        </Paper>
    );
}
