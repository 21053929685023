import React from 'react'
//Componentes
import Formulario from './IniciarSesion';
//Core-components
import {Grid}  from "../../componentesGenericos/Core"
//estilos
import {useStylesLoginContenedor} from "../../Helpers/constantes/Css/EstilosUi";
const useStyles =useStylesLoginContenedor;

function Login() {
    const classes = useStyles();
    return (
            <Grid container direction="column" justify="center" alignItems="center" className={classes.fullsize}>
                <Formulario />
            </Grid>
    )
}
export default Login;
