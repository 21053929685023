import gql from 'graphql-tag';

//#region =========ProductosServicios=============
const LISTADO_PRODUCTOS_SERVICIOS = gql`
	query listadoProductoServicios($offset: Int, $tipo: Int!, $clinica: Int, $disponible: Int) {
		listadoProductosServicios(offset: $offset, tipo: $tipo, clinica: $clinica, disponible: $disponible) {
			productosServicios {
				servicioId
				descripcion
				codigoProducto
				codigoBarras
				precioUnitario
				iva
				precioTotal
				porcentajeDescuento
				descuentoTotal
				precioUnitarioDescuento
				ivaDescuento
				precioTotalDescuento
				tipoIva
				descripcionTipoIva
				tasaIva
				descripcionTasaIva
				porcentajeIva
				NoAplicaIva
				comision
				agendable
				inventariable
				disponible
				fechaInicialDescuento
				fechaFinalDescuento
				vigenciaDescuento
				especialidadId
				descripcionEspecialidad
				tipoProducto
				relacionId
				garantiaId
				garantia
				clinicaId
				nombreClinica
				existenciaTotal
				servicioClinica
				venderSinExistencia
				venderSinExistenciaGeneral
                nAlmacenes
			}
			registros
			paginas
		}
	}
`;

const LISTADO_PRODUCTOS_SERVICIOS_FETCH = `
	query listadoProductoServicios($offset: Int, $tipo: Int!, $clinica: Int, $disponible: Int) {
		listadoProductosServicios(offset: $offset, tipo: $tipo, clinica: $clinica, disponible: $disponible) {
			productosServicios {
				servicioId
				descripcion
				codigoProducto
				codigoBarras
				precioUnitario
				iva
				precioTotal
				porcentajeDescuento
				descuentoTotal
				precioUnitarioDescuento
				ivaDescuento
				precioTotalDescuento
				tipoIva
				descripcionTipoIva
				tasaIva
				descripcionTasaIva
				porcentajeIva
				NoAplicaIva
				comision
				agendable
				inventariable
				disponible
				fechaInicialDescuento
				fechaFinalDescuento
				vigenciaDescuento
				especialidadId
				descripcionEspecialidad
				tipoProducto
				relacionId
				garantiaId
				garantia
				clinicaId
				nombreClinica
				existenciaTotal
				servicioClinica
				venderSinExistencia
				venderSinExistenciaGeneral
                nAlmacenes
			}
			registros
			paginas
		}
	}
`;

const LISTADO_TIPO_IVA = gql`
	query listTiposIva($offset: Int) {
		listadoTiposIva(offset: $offset) {
			tiposIva {
				tipoId
				descripcion
			}
		}
	}
`;

const LISTADO_TASA_IVA = gql`
	query listTasasIva($offset: Int) {
		listadoTasasIva(offset: $offset) {
			tasasIva {
				tasaId
				descripcion
				porcentajeIva
			}
		}
	}
`;

const LISTADO_TASA_IVA2 = `
query listTasasIva ($offset: Int)
{
  listadoTasasIva(offset: $offset)
  {
    tasasIva{
      tasaId
      descripcion
      porcentajeIva
    }
  }
}`;

const LISTADO_ESPECIALIDAD_SERVICIO = gql`
	query especialidadServicios($offset: Int) {
		listadoEspecialidadServicio(offset: $offset) {
			especialidadesServicios {
				especialidadId
				descripcion
			}
		}
	}
`;

const LISTADO_SERVICOS_PACIENTES =  `
	query ListServPac {
  listadoServiciosPacientes{
    clinicaId
    nombreClinica
    especialidades{
      especialidadId
      descripcionEspecialidad
      servicios{
        servicioId
        descripcion
      }
    }
  }
}
`;


const LISTADO_TIPO_SERVICIOS=`
query {
    listadoTipoServicios {
      tiposProductos {
        Id
        tipo
      }
    }
  }
  `;
//#endregion
//========================================

export {
    LISTADO_TIPO_SERVICIOS,
	LISTADO_PRODUCTOS_SERVICIOS,
	LISTADO_TIPO_IVA,
	LISTADO_TASA_IVA,
	LISTADO_TASA_IVA2,
	LISTADO_ESPECIALIDAD_SERVICIO,
    LISTADO_SERVICOS_PACIENTES,
    LISTADO_PRODUCTOS_SERVICIOS_FETCH
};

