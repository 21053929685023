import React, {useState,useEffect} from 'react';

//Apollo
import { useQuery,useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_TURNOS,LISTADO_USUARIO_CLINICA, SELECT_CLINICAS_FETCH } from '../../../query';
import {ELIMINAR_TURNO,NUEVO_TURNO, MODIFICAR_TURNO } from '../../../mutation';
//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";

//constantes
import { mensajeConfirmacion,mensajeError,mensajeExito, mensajeInicial } from '../../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../../componentesGenericos/Icons';
//Redux
import { useSelector } from 'react-redux';
//Helpers
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat } from '../../../Helpers';
//Expresiones regulares
import { rgxNoVacio,rgxAlmenosUnaLetra } from '../../../Helpers/constantes/Rgx';
import { cuatroCaracteres } from '../../../Helpers/constantes/formatos';
export default function Turno() {

    //#region redux
    const user = useSelector ((state )=> state.selecciondeClinica);
    let clinicaIdRedux = user.seleccionClinica[0].clinicaId;

    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let userToken = DecodificarToken(userState);
    //#endregion

    //#region queries & Mutations


    let { loading, error, data, refetch } = useQuery(LISTADO_TURNOS, {
        variables: { offset:0 ,usuarioId:null,clinicaId:Number(clinicaIdRedux) },
        onError: (e) =>{
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () =>{
            setFila(-1)
            setSeleccion(seleccionInicial)
            setErrorState(false)

        }
    });

    const [addTurno] = useMutation(NUEVO_TURNO, {
        onError: (e) => {
            throw new Error (e.message.replace("GraphQL error:", ""))

        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el turno` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
});

const [modTurno] = useMutation(MODIFICAR_TURNO, {
    onError: (e) => {
        throw new Error (e.message.replace("GraphQL error:", ""))

    },
    onCompleted: () => {
        ftFormularioClose();
        setDisabledAccept(false);

        setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el turno` });

        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setActualizar(true);
    }
});

const [deleteTurno] = useMutation(ELIMINAR_TURNO,{
    onError: (e) =>{
        setMensaje({
            ...mensaje,
            ...mensajeError,
            texto: e.message.replace("GraphQL error:", "")
        });
    },
    onCompleted:(e) =>{
        setMensaje({
            ...mensaje,
            ...mensajeExito,
            texto: e.eliminarTurno
        });

        setSeleccion({
            ...seleccion,
            ...seleccionInicial
        });

        setFila(-1);
        setActualizar(true);
    }
})

//#endregion
//#region states
const seleccionInicial = {
    turnoId: null,
    usuarioId: -2,
    descripcion: '',
    horaInicio: "08:00:00",
    horaFin: "18:00:00",
    nombreCompleto: null,
    email: null,
    telefono: null,
    clinicaId: 0,
    nombreClinica: null,
    claveClinica: null,
    aliasClinica: null,
    clinicaUsuarioId: null
}

const usuarioRef=React.useRef();
const clinicaRef=React.useRef();

const [disabledAccept, setDisabledAccept] = useState(false);
const [disabledDelete, setDisabledDelete] = useState(false);
const [clinicas, setClinica] = useState([]);
const [usuarios, setUsuarios] = useState([]);
const [filtro, setFiltro] = useState("");
const [actualizar, setActualizar] = useState(false);
const [fila, setFila] = useState(-1);
const [mensaje, setMensaje] = useState(mensajeInicial);
const [seleccion, setSeleccion] = useState(seleccionInicial);
const [formulario, setFormulario] = useState({
          abrir: false,
          titulo: "Formulario",
          onClose: ftFormularioClose,
          onAccept: null,
          onCancel: null
      });

const [errorState, setErrorState] = useState(false);
const [errorSelect, setErrorSelect] = useState(false);
const [stateCopy, setStateCopy] = useState({});
//#endregion


const { data:usuariosFiltrados } = useQuery(LISTADO_USUARIO_CLINICA, {
    variables: {
        clinica: Number(usuarios) || Number(seleccion.clinicaId) || -1
    },
    onError: (e) =>{
        console.log(e.message)
        setErrorSelect(true)
    },
    onCompleted: (e =>{
        setErrorSelect(false)
    })
});

let opcionesUsuarios = usuariosFiltrados ? usuariosFiltrados.listadoClinicaUsuario.clinicaUsuarios.map (valor =>({
    label: valor.nombreCompleto,
    value: valor.usuarioId,
    clinicaUsuarioId: valor.clinicaUsuarioId
}))
: null ;

//#region constantes

const sinOpciones = [
    {
        label: "Sin usuarios asignados" ,
        value : -2
    }
]

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'turnoId', label: 'turno id', minWidth: 0, hide: true },


     { id: 'nombreCompleto', label: 'Usuario', minWidth: 100, align: 'left', filtro: true, textAlign: 'left',
     format: (fila) => {
         return fila.toString().replace(seleccion.usuarioId, seleccion.nombreCompleto).replace(seleccion.usuarioId, seleccion.nombreCompleto);
         }
  },
        { id: 'descripcion', label: 'Descripción', minWidth: 170, align: 'left',textAlign: 'left',filtro: true },
        { id: 'horaInicio', label: 'Hora inicial', minWidth: 170, align: 'center',textAlign: 'center',
        format: (valor) => {
            return valor.replace(/(\d{2}):(\d{2}):(\d{2}).\d{7}$/, "$1:$2:$3");
        }
    },
        { id: 'horaFin', label: 'Hora final', minWidth: 170, align: 'center',textAlign: 'center',
        format: (valor) => {
            return valor.replace(/(\d{2}):(\d{2}):(\d{2}).\d{7}$/, "$1:$2:$3");
        }
     },
     { id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left', filtro: true,
     format: (fila) => {
         return fila.toString().replace("", "").replace(seleccion.clinicaId, seleccion.nombreClinica);
         }
  },

    ];

    const  handleClinicasClick = ({target:{value}}) => {
        setUsuarios(value)
     }

    const menu = [
        {
            texto: "Agregar turno",
            icono: <AddIcon />,
            onClick: () => handleAgregarClick()
        },
        {
            texto: "Modificar turno",
            icono: <EditIcon />,
            onClick: () => handleModificarClick()
        },
        {
            texto: "Eliminar turno",
            icono: <DeleteForever />,
            onClick: () => handleEliminarClick()
        }
    ];



    const camposFormulario = [
        {
            id: "turnoId",
            texto: "turnoId",
            valor: seleccion.turnoId,
            tipo: "hidden"
        },
        {
            id: "clinicaId",
            texto: "Sucursal *",
            valor: seleccion.clinicaId,
            tipo: "select",
            opciones:clinicas,
            xs:6,
            onChange: handleClinicasClick,
            cleanDatoState: [{ id: "usuarioId", value: -2 }],
            ayuda: "Seleccione una sucursal",
            regexp: rgxNoVacio,
            styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
            styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        },
        {
            id: "usuarioId",
            texto: "Usuario *",
            valor: seleccion.usuarioId,
            tipo: "select",
            opciones: errorSelect == false ? opcionesUsuarios : sinOpciones,
            xs:6,
            onChange: handleClinicaDoctorClick,
            otros:{
                inputProps: {
                    id: "nombreCompletoSelect"
                }
            },
            ayuda: "Seleccione un usuario",
            regexp: rgxNoVacio,
        },
        {
            id: "descripcion",
            texto: "Descripción *",
            valor: seleccion.descripcion,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres y un máximo de 50)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "horaInicio",
            texto: "Hora inicial *",
            valor: seleccion.horaInicio ,
            tipo: "time",
            xs: 6,
            ayuda: "Ingrese la hora inicial",
            regexp: rgxNoVacio,

        },
        {
            id: "horaFin",
            texto: "Hora final *",
            valor: seleccion.horaFin ,
            tipo: "time",
            xs: 6,
            ayuda: "Ingrese la hora final",
            regexp: rgxNoVacio,
        },
        {
            id: "clinicaUsuarioId",
            texto: "Clinica Usuario",
            valor: seleccion.clinicaUsuarioId ,
            tipo: "hidden"
        },
    ]

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
    : data?.listadoTurnos?.turnos;

    const queryClinicas = () => {
        async function queryClinicas() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: {
                        limite: 10000,
                        activas: true
                    }
                });

                let {listadoClinicas:{clinicas}} = resultado;

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: "nombre",
                }, "Seleccione una sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinicas();
    }

//#endregion

//#region handlers

function handleAgregarClick() {
    setSeleccion(seleccionInicial)
    usuarioRef.current=null;
    clinicaRef.current=null;
    setFila(-1);
    setUsuarios([])

    setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
}

function handleModificarClick() {
    if (seleccion.turnoId !== null && seleccion.turnoId !== undefined) {
        usuarioRef.current=null;
        clinicaRef.current=null;
        setSeleccion({
            ...seleccion,
            ...stateCopy,

        });
        setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
    } else {
        setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un turno" });
    }
}

function handleEliminarClick() {

    if (seleccion.turnoId !== null && seleccion.turnoId !== undefined) {
        setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el turno "${seleccion.descripcion}" del usuario "${seleccion.nombreCompleto}"?`, onAccept: ftEliminar });
    } else {
        setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un turno" });
    }
}

function handleTablaClick(datos, index) {
    if(seleccion !== datos){
        setSeleccion(datos);
        setStateCopy(datos)
        setFila(index);
    }else{
        setSeleccion(
            seleccionInicial
        );
        setFila(-1);
    }
 }

 function handleBusquedaChange(texto) {
  setFiltro(texto)
  setSeleccion({ ...seleccion, ...seleccionInicial });
  setFila(-1);
}

function handlePaginaChange(nuevaPagina) {
    setSeleccion({ ...seleccion, ...seleccionInicial });
    setFila(-1);
}

//#endregion
function handleClinicaDoctorClick (e) {
    let {value}= e.target;
    let CU =[];
    if (Number(value) !== -2){
        [CU] = opcionesUsuarios.filter((valor) => {
                if(Number(valor.value) === Number(value)) return valor;
            });
    }

    setSeleccion(seleccion=>({
        ...seleccion,
        clinicaUsuarioId: CU.clinicaUsuarioId
    }))
}

function ftAgregar ({usuarioId,clinicaId,descripcion,horaInicio,horaFin,clinicaUsuarioId,cleanStateFormulario}){
    async function ftAgregar()
    {
        try{
            if (Number(clinicaId) === 0 || Number(usuarioId) === -2) throw new Error("Sucursal o usuario incorrecto");

            setDisabledAccept(true);
            setSeleccion({...seleccion,usuarioId,clinicaId,descripcion,horaInicio,horaFin,clinicaUsuarioId});
            if (horaInicio > horaFin ) throw new Error("La hora inicial no puede ser mayor a la final");
            if(descripcion.length < 4 || descripcion.length > 50) throw new Error("La descripción debe incluir al menos 4 caracteres y un máximo de 50");

            if (ftValidarObligatorios([usuarioId,clinicaId,descripcion.trim(),horaInicio,horaFin]) === true )
                {
                  await  addTurno({
                        variables: {
                            turno: {
                                usuarioId: Number(usuarioId),
                                clinicaId: Number(clinicaId),
                                descripcion: descripcion.trim(),
                                horaInicio: horaInicio,
                                horaFin: horaFin,
                                clinicaUsuarioId: Number(clinicaUsuarioId)
                            }
                        }
                    });
                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
        }catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

function ftModificar({ usuarioId,clinicaId,descripcion,horaInicio,horaFin,clinicaUsuarioId,cleanStateFormulario }) {
    async function ftModificar()
    {
        try{

            if (usuarioId == -2)
         throw new Error("Usuario incorrecto");

            setDisabledAccept(true);

            if(descripcion.length < 4 || descripcion.length > 50) throw new Error("La descripción debe incluir al menos 4 caracteres y un máximo de 50");

            if (ftValidarObligatorios([usuarioId,clinicaId,descripcion.trim(),horaInicio,horaFin]) === true && (horaInicio < horaFin && horaInicio !== horaFin) ) {
            await    modTurno({
                    variables: {
                        turno: {
                            turnoId: seleccion.turnoId,
                            usuarioId: Number(usuarioId),
                            clinicaId: Number(clinicaId),
                            descripcion: descripcion.trim(),
                            horaInicio: horaInicio,
                            horaFin: horaFin,
                            ini: seleccion.horaInicio.replace(/(\d{2}):(\d{2}):(\d{2}).\d{7}$/, "$1:$2:$3"),
                            fin: seleccion.horaFin.replace(/(\d{2}):(\d{2}):(\d{2}).\d{7}$/, "$1:$2:$3"),
                            clinicaUsuarioId: Number(clinicaUsuarioId)
                        }
                    }
                });
                if(cleanStateFormulario) cleanStateFormulario();

            } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }


        }catch({message:texto}) {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto });
        }
    } ftModificar();
}



function ftEliminar() {
    async function ftEliminar() {
        setDisabledDelete(true);
       await deleteTurno({ variables: { id: Number(seleccion.turnoId) } });
        setDisabledDelete(false);
    } ftEliminar();
}

function ftValidarObligatorios(campos) {
    if (campos[2] === "" || campos[2] === undefined) return "Debe especificar una descripción";

    return campos.every((valor) => (valor !== null && valor !== undefined ));
}


   function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {

        setFormulario({ ...formulario, abrir: false });
    }
//#endregion

function stateOut(valorID = {})  {
    setSeleccion({
        ...seleccion,
        ...valorID
    });
}

if (actualizar === true) {
    refetch().then(() => setErrorState(false)).catch(e => {
        console.log(e.message);
        setErrorState(true);
    });
    setActualizar(false);
}

useEffect(() => {
    async function ftEffect() {

        const clinicas = await queryClinicas();
        setClinica(clinicas)
    }
    ftEffect();
}, []);

useEffect(() => {
    setActualizar(true);
}, [clinicaIdRedux]);

return (
    <MenuLateral titulo="Turnos" menu={menu}>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete}/>

        <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Turno" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion}  disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

        <Tabla titulo={"Listado de turnos"} columnas={columnas}
        datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreCompleto", "descripcion", "nombreClinica"]} />

    </MenuLateral>
);

}
