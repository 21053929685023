import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Autenticar } from "./Autenticar";
import { RestaurantMenuOutlined } from "@material-ui/icons";

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const user = useSelector((state) => state.user);

    return (

        <Route
            {...rest}
            render={props =>
                Autenticar(user.usuario[0].user.user.token, user.usuario[0].user.user.success) === true ? (
                   rest.responsivo == true ?
                    <Component {...props} />                    :
                    <Redirect
                            to={{
                                pathname: "/Menu",
                                state: { from: props.location }
                            }}
                     />

                ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />
                    )
            }
        />)
};

