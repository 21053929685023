import React,{useContext} from 'react';
//Componentes
import DatosConfiguracion from './Formularios/DatosConfiguracion';
//context
import { CRMContext } from '../../../context/Perfil';
//Help
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
//Router
import { withRouter } from "react-router-dom";
function Configuracion(props) {

    const [perfil, guardarPerfiles] = useContext(CRMContext);
    let { history } = props;

    //Eventos
    let componentePrincipal = (token) => {
        let datos = DecodificarToken(token);
        let tipo = datos.tipo;
        let componente;

        if (tipo != null || tipo != undefined) {

            switch (tipo) {
                case 1:
                    componente = (<>  <DatosConfiguracion  usuarioId={datos.usuarioId} sonidoNotificacion={datos.sonidoNotificacion} /></>);
                    break;
                case 2:
                    componente = (<>  <DatosConfiguracion usuarioId={datos.usuarioId} sonidoNotificacion={datos.sonidoNotificacion}/></>);
                    break;
                case 3:
                    componente = (<>  <DatosConfiguracion  usuarioId={datos.usuarioId} sonidoNotificacion={datos.sonidoNotificacion}/></>);
                    break;
                case 4:
                    componente = (<>  <DatosConfiguracion  usuarioId={datos.usuarioId} sonidoNotificacion={datos.sonidoNotificacion}/></>);
                    break;
                default:
            }

        } else {
            history.push('/');
        }
        return componente;
    }

    return (
        <>
           {componentePrincipal(perfil.General.token.valor)}
        </>
    );
}

export default withRouter(Configuracion);
