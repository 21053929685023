import gql from 'graphql-tag';

//#endregion Reportes
const HISTORIAL_REPORTES = gql`
    query historial($reporte: String!) {
        listadoReportes(reporte: $reporte) {
            reporteID
            reporte
            ruta
            filtros
            fechaAlta
            usuarioID
            nombreCompleto
        }
    }
`;
//#endregion

export {
    HISTORIAL_REPORTES
}
