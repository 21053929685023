import gql from 'graphql-tag';

const LISTADO_FOLIOS = gql`
query ListadoFolios($limite: Int, $pagina: Int, $folioId: Int, $clinicaId: Int, $consecutivo: Int, $serieId: Int){
    listadoFolios(limite: $limite, pagina: $pagina, folioId: $folioId, clinicaId: $clinicaId, consecutivo: $consecutivo, serieId: $serieId){
      folios{
        folioId
        clinicaId
        nombre
        consecutivo
        serieId
        nombreSerie
      }
    }
  }`;

  const FOLIOS_FETCH = `
  query ListadoFolios($limite: Int, $pagina: Int, $folioId: Int, $clinicaId: Int, $consecutivo: Int, $serieId: Int){
    listadoFolios(limite: $limite, pagina: $pagina, folioId: $folioId, clinicaId: $clinicaId, consecutivo: $consecutivo, serieId: $serieId){
      folios{
        folioId
        clinicaId
        nombre
        consecutivo
        serieId
        nombreSerie
      }
    }
  }`;

export {LISTADO_FOLIOS, FOLIOS_FETCH};
