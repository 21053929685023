import React, { useState, useEffect } from 'react'
import Input from './componentes/InputGrid';
import { Grid, LinearProgress } from '@material-ui/core';
import { Dialog } from '@material-ui/core';

//componentes
import Titulo from '../Mensaje/componentes/Titulo';
import Botones from '../Mensaje/componentes/Botones';
import Contenido from '../Mensaje/componentes/Contenido';
import Mensaje from '../MensajeNoModal';

//estilos
import { makeStyles } from '@material-ui/core/styles';

function Formulario({ campos = [], abrir = false, onClose, classes, titulo = "Formulario", tamTitulo, onAccept = () => { }, onCancel, datos = {}, mensaje = {}, aBotones = [], tipo = "confirmacion", disabledAccept = false, progress, cleanAwait = false, disableBackdropClick, sync }) {

    const [msg, setMsg] = useState(mensaje);
    const clases = classes || {
        titulo: makeStyles(theme => ({
            main: {
                margin: 0,
                padding: theme.spacing(1),
                textAlign: 'center'
            },
            btnCerrar: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(),
        contenido: makeStyles(theme => ({
            root: {
                textAlign: 'center',
                padding: '5px 50px',
                margin: 0,

            }
        }))(),
        botones: makeStyles(theme => ({
            root: {
                padding: theme.spacing(1),
            },
            Btn: {
                margin: '0 auto'
            },
            BtnCancel: {
                marginRight: '10px'
            },
            BtnNew: {
                marginLeft: '10px'
            },
            contenedor: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }))()
    };
    let dt = {}
    const [errors, setError] = useState({});

    campos.map(campo => dt[campo.id] = campo.valor);

    const [state, setState] = useState({});

    let loading = false;

    let tmp = {};
    Object.keys(state).map(item => {
        tmp[item] = null;
        return;
    });

    function handleAcceptClick() {
        loading = true;
    }

    function onChange(e, onChangee, id, multiselect = false, val, regexp, texto = "", cleanDatoState = [], ftFormato) {
        let value = "";
        switch (e.target.type) {
            case "file":
                value = e.target.files[0];
                break;
            case "checkbox":
                value = e.target.checked;

                campos.map(campo => {
                    if (campo.id === id) {
                        campo.valor = value;
                    }
                });
                break;
            default:
                value = (val && !multiselect && e.target.type) ? val : e.target.value;
                break;
        }

        let limpiar = {};
        if (Array.isArray(cleanDatoState) && state[id] != value) cleanDatoState.forEach(({ id, value }) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
        });

        setState(state => ({
            ...state,
            ...limpiar,
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        }));

        if (onChangee) onChangee(e, val, limpiar);

        let valido = true;

        if (regexp) valido = regexp.test(value !== null && value !== undefined ? value.toString() : "");

        if (ftFormato) {
            if (valido !== false) {
                valido = ftFormato(value.toString());
            }
        }

        setError({ ...errors, [id]: { valor: !valido, texto: texto } });

    }

    const ftClose = () => {
        setState({ ...tmp });
        if (onClose) onClose();
        setMsg({ ...msg, openMensaje: false });
        setError({});
    };

    const cleanStateFormulario = () => { setError({}); setState({ ...tmp }); };

    // useEffect(() => {
    //     let data = datos ? { ...datos } : { ...tmp };
    //     setState({ ...data });
    // }, [datos]);

    useEffect(() => {
        if (sync) {
            let tmp = {};
            campos.map(campo => tmp[campo.id] = campo.valor);
            setState({ ...state, ...tmp })
        }

    }, [campos])

    return (
        <Dialog open={abrir} onClose={ftClose} aria-labelledby="dialog-title" onCancel={() => console.log("")} fullWidth disableBackdropClick={disableBackdropClick} disableEscapeKeyDown>

            <Titulo texto={titulo} tamTitulo={tamTitulo} onClose={ftClose} classes={clases.titulo} />
            {
                msg.openMensaje ? <Mensaje
                    variant={msg.ok ? "success" : "warning"}
                    message={msg.mensaje}
                    onClose={msg.handleClose}
                /> : ""
            }

            <Contenido texto={"texto"} color={"inherit"} classes={clases.contenido} tipo={"formulario"}>
                <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                    {campos.map((campo, key) => {
                        return (
                            <Input key={key} xs={campo.xs}
                                styleSelect={campo.styleSelect}
                                styleInput={campo.styleInput}
                                styleMenuItem={campo.styleMenuItem}
                                propiedades={campo.valor ? campo.propiedades : {}} tipo={campo.tipo} id={campo.id}
                                texto={campo.texto} margen={campo.margen} valor={state[campo.id] ? state[campo.id] : campo.valor}
                                handleChange={(e, val) => onChange(e, campo.onChange, campo.id, campo.multiselect, val, campo.regexp, campo.texto, campo.cleanDatoState, campo.formato)}
                                multiselect={campo.multiselect}
                                opciones={campo.opciones} disabled={campo.disabled} color={campo.color} estilosGrid={campo.estilosGrid}
                                regexp={campo.regexp} helperText={errors[campo.id] ?.valor ? campo.ayuda : " "} error={errors[campo.id] ?.valor}  {...campo.otros} />
                        )
                    })
                    }
                </Grid>
            </Contenido>
            {loading ? <LinearProgress /> : ""}

            <Botones
                tipo={tipo}
                classes={clases.botones}
                onAccept={(callback) => {

                    let invalidos = "";
                    Object.keys(errors).map(key => {
                        if (errors[key].valor) {
                            invalidos += '"' + errors[key].texto.replace(/\s*\*+$/, "") + '", ';
                        }
                    });
                    invalidos = invalidos.substring(0, invalidos.length - 2);

                    if (invalidos !== "") {
                        setMsg({
                            ...msg,
                            openMensaje: true,
                            ok: false,
                            mensaje: "Formato incorrecto en: " + invalidos,
                            handleClose: () => { setMsg({ ...msg, openMensaje: false }) }
                        });
                        //alert("Los campos " + invalidos + " no tienen el formato correcto")
                    } else {
                        setMsg({ ...msg, openMensaje: false });
                        setError({});
                        handleAcceptClick();
                        if (cleanAwait) onAccept({ ...state, cleanStateFormulario }, callback, dt);
                        else {
                            onAccept({ ...state }, callback, dt);
                            cleanStateFormulario();
                        }
                    }
                }}
                onCancel={() => {
                    setState({ ...tmp });
                    setMsg({ ...msg, openMensaje: false });
                    setError({});
                    let cancel = onCancel || onClose;
                    cancel();
                }}
                onClose={ftClose}
                aBotones={aBotones}
                disabledAccept={disabledAccept}
                progress={progress}
            />
        </Dialog>

    )
}

export default Formulario
