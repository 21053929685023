import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from "@material-ui/core/Tooltip";
import AclajePerfil from '../../componentes/AnclajePerfil';
import { Grid, Avatar, CircularProgress, Typography, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { blue } from '@material-ui/core/colors';
import { withRouter, Link } from "react-router-dom";
import { menuAdminUsuario, menuPacienteDoctor } from '../../componentes/MenuPrincipal/menu';
import QR from '../../componentes/Perfil/Secciones/CodigoQR';
import { OBTENER_QR } from '../../query/componentes/ConvenioUsuarios';
import {OBTENER_CONVENIO_USUARIO_ID} from '../../query/componentes/ConvenioUsuarios';
//Apollo
import { useQuery } from '@apollo/react-hooks';
//FETCH
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
import { urlServer } from "../../Helpers";
//Queries
import { LISTADO_SEGURIDAD, OBTENER_LISTADO_GENERAL_USUARIOS } from '../../query';
//****REDUX***********/
import { useDispatch, useSelector } from "react-redux";
import { IniciarSesion } from "../../Redux/Actions/SesionAction";
import { ObtenerMenu } from "../../Redux/Actions/MenuAction";
import { DecodificarToken } from "../../componentesGenericos/Estructura/Autenticar";

//Helpers
import { getUsuario } from '../../Helpers/Usuario';

import Notificaciones from '../../componentes/Notificaciones';

import SeleccionClinica from '../../componentes/SeleccionClinica';

//Estilos
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { TipoUsuario } from '../../Helpers/Enums';
import { handleClick, limpiarMenu } from '../../componentes/MenuPrincipal/helper';

//Socket
import { cerrarSessionServidor } from '../../sockets';

import useMediaQuerys from '../MediaQuerys';

import TarjetaDigital from '../../componentes/TarjetaDigital';

import { obtenerStateStorage } from '../../Helpers/LocalStorage/LocalStorage'
import { rutaPublica } from '../../Helpers/constantes/Config/urlRutas';
const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        width: '100%',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: theme.palette.common.white
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        backgroundColor: blue[900],
        borderRadius: 0,
        height: '100%',
        marginRight: theme.spacing(1),
        '&:hover': {
            backgroundColor: '#7583F7',
            borderColor: blue[900],
            boxShadow: 'none',
        }
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    drawer2: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen2: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose2: {
        zIndex: theme.zIndex.drawer - 1,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        //overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    content2: {
        flexGrow: 1,
        padding: theme.spacing(1),
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth + 60,
    },
    contentSinMenu: {
        flexGrow: 1,
        padding: theme.spacing(1),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    filtro: {
        position: 'relative',
        height: '40%',
        margin: '0',
        border: '1px solid #4572A9',
        borderRadius: '15px',
        backgroundColor: theme.palette.common.black[200],
    },
    notificacion: {
        color: blue[900],
        width: '35px',
        height: '35px',
        display: 'flex',
        overflow: 'hidden',
        position: 'relative',
        fontSize: '1.25rem',
        alignItems: 'center',
        flexShrink: 0,
        justifyContent: 'center',
    },
    input: {
        width: '90%'
    },
    verde: {
        color: theme.palette.getContrastText(blue[900]),
        backgroundColor: blue[900],
    },
    link: {
        paddingLeft: '8px',
        width: '100%'
    },
    sinWidth: {
        width: 0
    },
    menuItem: {
        width: drawerWidth,
        margin: '0px',
        paddingLeft: '15px'
    },
    contenedorTarjeta: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '1300',
    },
    Tarjeta: {
        //TODO tarjeta
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        zIndex: '1301',

        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: 'red',
        backgroundImage: `url(/images/tarjeta.svg)`,

        textAlign: 'center'
    },
    QR: {
        position: 'absolute',
        height: '100%',
        width: '100%'
        // left: '10vw',
    }

}));



function MenuLateral({ menu = [], titulo, children, history }) {
    const classes = useStyles();
    const theme = useTheme();
    const xsbreak = useMediaQuery(theme.breakpoints.up('xs'));
    const smbreak = useMediaQuery(theme.breakpoints.up('sm'));
    const mdbreak = useMediaQuery(theme.breakpoints.up('md'));
    const lbreak = useMediaQuery(theme.breakpoints.up('l'));
    let theme2 = createMuiTheme();
    theme2 = responsiveFontSizes(theme2);
    let sesiones = obtenerStateStorage('kalendaySesiones');
    //#region Redux
    const dispatch = useDispatch();
    const setMenu = (datos) => dispatch(ObtenerMenu(datos));
    const agregarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const user = useSelector((state) => state.user.usuario[0].user.user);
    const urlPerfil = useSelector((state) => state.fotoPerfil.perfil[0].ruta);
    const user1 = useSelector((state) => state.user);
    let userToken = DecodificarToken(user1.usuario[0].user.user.token);
    const configuracion = useSelector(state => state.configuracion);
    const menuSeleccion = useSelector(state => state.menu);
    const [usuarioQR, setUsuarioQr] = useState(null);
    const [datoConvenio,setDatoConvenio] = useState({});
    //#endregion

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(userToken.tipo);
    const bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(userToken.tipo);
    // const bTipoUsuario = TipoUsuario.USUARIO.isEqual(userToken.tipo);
    // const bTipoAdmin = TipoUsuario.ADMINISTRADOR.isEqual(userToken.tipo);

    //#region Querys
    const { loading, error, data, refetch } = useQuery(LISTADO_SEGURIDAD, {
        fetchPolicy: 'no-cache',
        variables: { limite: 1000 },
    });

    const { data: listaUsuarios } = useQuery(OBTENER_LISTADO_GENERAL_USUARIOS, {
        fetchPolicy: 'no-cache',
        variables: { offset: 0, moroso: null },
        onCompleted: () => {

            listaUsuarios.listadoGeneral.usuarios.map(usr => {
                if (usr.usuarioId === userToken.usuarioId + "") {

                    setUsuarioQr({ ...usr });
                }
            })
        }
    });

    //#endregion

    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsive = isTabletOrMobile || isTabletOrMobileDevice;

    let noOpcionesResponsive = {
        // inventarios: { movsInventario: true },
        ventas: { puntoVenta: true, pagosClientes: true, tickets: true },
        reportes:{reportes:true}
    };

    //#region states
    let infoUser = null, permisos = null;
    if (data) {
        infoUser = getUsuario(user.token);
        permisos = data.listadoSeguridad.Seguridad.filter(item => item.tipoUsuarioId === infoUser.usuario.tipo);
    }

    /// VALIDACION PARA VERIFICAR QUE MENU MANDAR POR USUARIO
    let menuPrincipal;
    menuPrincipal = bTipoPaciente || bTipoDoctor ? menuPacienteDoctor : menuAdminUsuario;
    let usuarioMenuRedux = bTipoPaciente || bTipoDoctor ? "menuPacienteDoctor" : "menuAdminUsuario";

    const [open, setOpen] = React.useState(false);
    const [filtro, setFiltro] = React.useState("");
    const [anclaje, setAnclaje] = React.useState(null);
    const [anclajePerfil, setAnclajePerfil] = React.useState(null);
    const [openQR, setOpenQR] = React.useState(false);
    const [activarQr, setActivarQr] = useState(false);
    //#endregion

    //#region Peticiones
    const FetchObtenerQr = async () => {
        let datos = user.token ? DecodificarToken(user.token) : {};
        await FetchGrahpQL({
            query: OBTENER_QR,
            variables: {
                id: datos.usuarioId
            }
        })
            .then(async (resp) => {

                let decode = await getUsuario(resp.GenerarQRCompleto);
                if (!decode.usuarioId) {
                    setActivarQr(false);
                } else {
                    setActivarQr(true);
                }


            })
            .catch((err) => {
                setActivarQr(false)
            });
    };

    const FetchObtenerConvenio = async () => {
        let datos = user.token ? DecodificarToken(user.token) : {};
        await FetchGrahpQL({
            query: OBTENER_CONVENIO_USUARIO_ID,
           variables:{
               usuarioId: datos.usuarioId,
               convenioId: null
            }
        })
            .then(async (resp) => {
                setDatoConvenio({
                    nombreCompleto: resp.obtenerConvenioUsuario.nombres,
                    urlLogo: resp.obtenerConvenioUsuario.urlLogo,
                    convenio: resp.obtenerConvenioUsuario.urlLogo && true
                })
            })
            .catch((err) => {
                setDatoConvenio({convenio: false});
                console.warn("get_convenio: ",err)
            });
    };
    //#endregion

    //#region handlers
    const handleMenuClick = (opcion) => {
        history.push(opcion);
    }

    //***Barrita de perfil***
    const handleClosePerfil = () => {
        setAnclajePerfil(null);
    };

    const handleNotificacionClickPerfil = event => {
        setAnclajePerfil(event.currentTarget);
    };
    //**** */

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleNotificacionClick = event => {
        setAnclaje(event.currentTarget);
    };

    const handleNotificacionClose = () => {
        setAnclaje(null);
    };

    const handleOpenQR = () => {
        setOpenQR(!openQR);
    }
    //#endregion

    //#region funciones
    function allStorage() {

        let values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while ( i-- ) {
            if (keys[i] !== "kalendaySesiones")
            {
                localStorage.removeItem(keys[i]);
            }
        }

        return values;
    }

    const Logout = () => {
        let user = null;
        try {
            user = localStorage.getItem('user');

            if (user) {
                let usuario = JSON.parse(user);
                let userToken = DecodificarToken(usuario.user.usuario[0].user.user.token);
                cerrarSessionServidor(userToken.usuarioId);
            }
        } catch (e) {
            console.log(e.message)
        }

        //localStorage.clear();
        allStorage();
        agregarUsuario({ user: { user: { success: false, token: "" } } });
        setMenu(limpiarMenu(menuSeleccion));
        history.push("/");
    }

    const onSearch = (e) => {
        setFiltro(e.target.value)
    }
    //#endregion

    //#region extras
    const openPush = Boolean(anclaje);
    const openPushPerfil = Boolean(anclajePerfil);
    //#endregion

    const generarSuperMenu = (opcion, index) => {
        let superMenu = [];

        if (opcion.texto) {
            if (permisos[0].permisos.includes(opcion.url.substring(1, opcion.url.length))) {
                if (opcion.imagen) {

                    if(responsive && noOpcionesResponsive[opcion.id] && noOpcionesResponsive[opcion.id][opcion.id]) {
                        superMenu = null;
                    }else{

                    superMenu =
                        <ListItem selected={opcion.subMenu ? menuSeleccion.openMenu[opcion.id] : menuSeleccion[usuarioMenuRedux][opcion.id]} button key={index} className={classes.link} style={{ width: '100%' }} onClick={() => {
                            if (opcion.subMenu) {
                                handleClick(menuSeleccion, setMenu, opcion.id, "openMenu");
                            } else { handleClick(menuSeleccion, setMenu, opcion.id, usuarioMenuRedux, limpiarMenu(menuSeleccion)); handleMenuClick(opcion.url || "/"); }
                        }} title={opcion.texto} >
                            <img src={process.env.PUBLIC_URL + opcion.imagen} style={{ width: '20%', marginRight: '10%', left: '100px' }} alt={"icono " + opcion.texto} />
                            <ListItemText primary={opcion.texto} primaryTypographyProps={{ style: { fontWeight: '450' } }} />
                        </ListItem>

                    if (opcion.subMenu) {
                        let poner = opcion.subMenu.find(sm => {
                            if (permisos[0].permisos.includes(sm.url.substring(1, sm.url.length))) { return true } else return false
                        })

                        if (!poner) {
                            superMenu = null;
                        } else {

                            superMenu = (
                                <div key={index}>
                                    <ListItem selected={opcion.subMenu ? menuSeleccion.openMenu[opcion.id] : menuSeleccion[usuarioMenuRedux][opcion.id]} button onClick={() => {
                                        if (opcion.subMenu) {
                                            handleClick(menuSeleccion, setMenu, opcion.id, "openMenu");
                                        } else { handleClick(menuSeleccion, setMenu, opcion.id, usuarioMenuRedux, limpiarMenu(menuSeleccion)); }
                                    }} className={classes.menuItem} key={index}>
                                        <img src={process.env.PUBLIC_URL + opcion.imagen} style={{ width: '20%', marginRight: '10%', left: '100px' }} alt={"icono " + opcion.texto} />
                                        <ListItemText primary={opcion.texto} primaryTypographyProps={{ style: { fontWeight: '450' } }} />
                                        {opcion.subMenu && menuSeleccion.openMenu[opcion.id] ? <IconExpandLess /> : <IconExpandMore />}
                                    </ListItem>
                                    {
                                        opcion.subMenu.map((opcionSubmenu, index) => {
                                            if (permisos[0].permisos.includes(opcionSubmenu.url.substring(1, opcionSubmenu.url.length))) {

                                                if(responsive && noOpcionesResponsive[opcion.id] && noOpcionesResponsive[opcion.id][opcionSubmenu.subId]) return null;

                                                return (<Collapse in={menuSeleccion.openMenu && menuSeleccion.openMenu[opcion.id]} timeout="auto" unmountOnExit key={index} >
                                                    <Divider />
                                                    <List component="div" disablePadding>
                                                        <ListItem selected={menuSeleccion[usuarioMenuRedux][opcionSubmenu.subId]} button className={classes.menuItem} onClick={() => { handleClick(menuSeleccion, setMenu, opcionSubmenu.subId, usuarioMenuRedux); handleMenuClick(opcionSubmenu.url || "/"); }}>
                                                            <img src={process.env.PUBLIC_URL + opcionSubmenu.imagen} style={{ width: '18%', marginRight: '10%', marginLeft: '20px' }} alt={"icono " + opcionSubmenu.texto} />
                                                            <ListItemText primary={opcionSubmenu.texto} style={{ paddingLeft: '0%', marginRight: '10%' }} />
                                                            {/* inset */}
                                                        </ListItem>
                                                    </List>
                                                </Collapse>)
                                            }
                                        })
                                    }
                                </div>

                            )
                        }
                    }

                   }//fin de validar si el menu es responsivo

                } else {
                    superMenu = <ListItem button key={index} onClick={() => handleMenuClick(opcion.url || "/")}>
                        <ListItemIcon>{opcion.icono}</ListItemIcon>
                        <ListItemText primary={opcion.texto} primaryTypographyProps={{ style: { fontWeight: '450' } }} />
                    </ListItem>
                }
            } else {
                superMenu = (null);
            }
        } else {
            superMenu = <Divider key={index} />;
        }
        return superMenu;
    };

    //CICLO DE VIDA
    useEffect(() => {
        async function ftEffect() {
            await FetchObtenerQr();
            await FetchObtenerConvenio();
            const favicon = document.getElementById("favicon");
            let ruta = rutaPublica + configuracion.urlLogotipoTarjetaConvenio;
            //Actualiza el favicon correspondiente a la empresa
            //favicon.href = ruta;
            //document.title = sesiones.sesiones.nombreEmpresa;
        }
        ftEffect();
    }, []);

    document.onload = () => {
        document.body.style.height = "100vh";
        document.body.style.width = "100vw";
        document.getElementById('cuerpoPrincipal').style.width = '100vw';
    }

    return (
        <div className={classes.root}>
            <CssBaseline />

            {
                //TODO diseño sidebar
                openQR ?

                    <Grid container direction="row" justify="center" id="superdiv" className={`${classes.contenedorTarjeta}`} onClick={handleOpenQR} >
                        <TarjetaDigital
                            nombres={ /* responsive ? userToken.nombres :  */userToken.nombres  + " " + userToken.apellidos }
                            leyendaQR={configuracion.leyendaQRCard ? configuracion.leyendaQRCard : "Cliente distinguido"}
                            telefono={usuarioQR.telefono}
                            email={usuarioQR.email}
                            logotipo={`${rutaPublica}${datoConvenio.urlLogo}`}
                            qr={<QR token={user.token} width={'100%'} height={'100%'} />}
                            convenio={datoConvenio.convenio}
                        />
                    </Grid>
                    :
                    <>
                        {/* Top Menu */}
                        <AppBar position="fixed" xs={12} className={clsx(classes.appBar, { [classes.appBarShift]: open, })}>
                            <Toolbar style={{ paddingRight: 0 }}>

                                <Grid container direction={"row"} justify={"flex-end"} alignItems={"center"}>
                                    {/* icono del menu */}
                                    <Grid container item xs={2} justify={"flex-start"} alignItems={"center"}>
                                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" className={clsx(classes.menuButton, open && classes.hide)}>
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>


                                    {/* opciones */}
                                    <Grid container item xs={10} direction={"row"} justify={"flex-end"} alignItems={"center"}>

                                        {/* Seleccionar Clinica */}
                                        <Grid container item xs={4} sm={5} md={3} direction="row" justify={"flex-end"} alignItems={"center"}>
                                            <SeleccionClinica fontSize={"large"} className={classes.selectEmpty} />
                                        </Grid>

                                        {/*  icono de qr */}
                                        { (activarQr === true && bTipoPaciente && datoConvenio.convenio !==false ) ?
                                            <Grid container item xs={2} sm={2} md={1} justify={"center"}>
                                                <Tooltip title="QR" >
                                                    <img src={"/icons/qr.png"} alt={"QR"} className={classes.notificacion} style={{ cursor: 'pointer' }} onClick={handleOpenQR} />
                                                </Tooltip>
                                            </Grid> : ""
                                        }
                                        {/*  icono de calendario */}
                                        <Grid container item xs={2} sm={2} md={1} justify={"center"}>
                                            <Tooltip title="Agende su cita aquí" >
                                                <Link to="/Menu">
                                                    <img src={"/iconos/agendaTop.png"} alt={"icono agenda"} onClick={(e) => setMenu(limpiarMenu(menuSeleccion))} className={classes.notificacion} style={{ cursor: 'pointer' }} />
                                                </Link>
                                            </Tooltip>
                                        </Grid>

                                        {/* icono de notificacion */}
                                        <Grid container item xs={2} sm={1} md={1} justify={"center"}>
                                            <img src={"/iconos/notificacionesTop.png"} alt={"icono notificaciones"} onClick={(e) => handleNotificacionClick(e)} className={classes.notificacion} style={{ cursor: 'pointer' }} />

                                            <Notificaciones id={"push"} abrir={openPush} Anclaje={anclaje} onClose={handleNotificacionClose} title="Notificaciones" />
                                        </Grid>

                                        {/* perfil */}

                                        {/* <Tooltip title="Perfil" TransitionProps={{ timeout: 600 }}  > */}
                                        <Grid container item xs={2} sm={2} md={1} justify={"center"}>

                                            <Avatar id={"push1"} alt="Perfil" src={urlPerfil} onClick={(e) => handleNotificacionClickPerfil(e)} style={{ cursor: 'pointer' }} className={classes.notificacion} />

                                            <AclajePerfil id={"push1"} abrir={openPushPerfil} Anclaje={anclajePerfil} onClose={handleClosePerfil} />

                                        </Grid>
                                        {/* </Tooltip> */}

                                    </Grid>

                                </Grid>

                            </Toolbar>
                        </AppBar>

                        {/* Menu Principal */}
                        {
                            <Drawer className={classes.drawer} variant="persistent" anchor="left" open={open} classes={{
                                paper: classes.drawerPaper
                            }}>
                                <div className={classes.drawerHeader}>
                                    <IconButton onClick={handleDrawerClose}>
                                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                    </IconButton>
                                </div>

                                <Divider />

                                {/* Opciones del menu */}
                                <List>
                                    {
                                        loading ?
                                            <div style={{ width: '100%', height: '100%', textAlign: "center" }}>
                                                <CircularProgress />
                                            </div>
                                            :
                                            user ?
                                                data ?
                                                    permisos ?
                                                        permisos.length > 0 ?
                                                            menuPrincipal.map((opcion, index) => {
                                                                return (
                                                                    generarSuperMenu(opcion, index)
                                                                )
                                                            })
                                                            : null
                                                        :
                                                        error ?
                                                            "Ocurrio un error al cargar el menu" : null
                                                    : null
                                                : null

                                    }
                                    <ListItem button className={classes.link} style={{ width: '100%' }} onClick={Logout} title={"Cerrar "}>
                                        <img src={process.env.PUBLIC_URL + "/icons/logout.png"} style={{ width: '20%', marginRight: '10%', left: '100px', marginLeft: '5px' }} alt={"icono " + "Cerrar sesión"} />
                                        <ListItemText primary={"Cerrar sesión"} primaryTypographyProps={{ style: { fontWeight: '450' } }} />
                                    </ListItem>
                                </List>
                            </Drawer>
                        }

                        <main id="cuerpoPrincipal" className={clsx(Array.isArray(menu) && menu.length > 0 ? classes.content : classes.contentSinMenu, { [classes.contentShift]: open, })}>
                            <div className={classes.drawerHeader} />
                            {children}
                        </main>

                        {/* menu lateral */}
                        {
                            Array.isArray(menu) && menu.length > 0 && <Drawer hidden={false} variant="permanent" className={clsx(classes.drawer2, {
                                [classes.drawerOpen2]: false,
                                [classes.drawerClose2]: true,
                                [classes.sinWidth]: true
                            })} classes={{ paper: clsx({ [classes.drawerOpen2]: false, [classes.drawerClose2]: true, }), }}>

                                <div className={classes.toolbar}></div>

                                <Divider />
                                <List>
                                    {menu.map((opcion, index) => (
                                        <Tooltip title={opcion.texto} placement="right" enterDelay={0} leaveDelay={100} key={index}>
                                            <ListItem button onClick={typeof opcion.onClick === 'string' ? () => { } : opcion.onClick}>
                                                {opcion.icono !== '' &&
                                                    <ListItemIcon>{opcion.icono}</ListItemIcon>
                                                }
                                                {/* <ListItemText primary={opcion.texto} /> */}
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                                </List>
                            </Drawer>
                        }
                    </>
            }
        </div>
    );
}

export default withRouter(MenuLateral);
