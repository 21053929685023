import gql from 'graphql-tag';

//#region Turnos
//=========Turnos=============
const OBTENER_TURNO = `
query turnos($id: Int!){
    obtenerTurno(id:$id){
        turnoId
        usuarioId
        descripcion
        horaInicio
        horaFin
        nombres
        apellidos
        email
        telefono
        clinicaId
        nombreClinica
        claveClinica
        aliasClinica
        clinicaUsuarioId
      }
    }
}`;
const LISTADO_TURNOS = gql`
	query listado($offset: Int,  $usuarioId: Int, $clinicaId: Int) {
		listadoTurnos(offset: $offset, usuarioId:$usuarioId ,clinicaId: $clinicaId) {
			turnos {
				turnoId
				usuarioId
				descripcion
				horaInicio
				horaFin
				nombreCompleto
                email
                telefono
                clinicaId
                nombreClinica
                claveClinica
                aliasClinica
                clinicaUsuarioId
			}
			registros
			paginas
		}
	}
`;

const LISTADO_USUARIO_CLINICA = gql `
 query clinicUsr ($clinica: Int!)
{
  listadoClinicaUsuario(id: $clinica)
  {
    clinicaUsuarios
    {
      clinicaUsuarioId
      clinicaId
      usuarioId
      tipo
      nombres
      apellidos
      nombreCompleto
      email
      estatus
    }
  }
}`;
//#endregion
//=====================================================

export {
    OBTENER_TURNO,
    LISTADO_TURNOS,
    LISTADO_USUARIO_CLINICA
}
