import React, { Fragment } from 'react'
import Contenedor from '../../componentesGenericos/MenuAnclado';

//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { ObtenerMenu } from "../../Redux/Actions/MenuAction";
import { limpiarMenu } from '../MenuPrincipal/helper';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Router
import { withRouter } from "react-router-dom"

//Queries
import { LISTADO_NOTIFICACIONES } from '../../query';
import { CAMBIAR_ESTATUS_NOTIFICACION } from '../../mutation';

//Helpers
import { getUsuario } from '../../Helpers/Usuario';

//Componentes
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge';

// Icono
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Cached from '@material-ui/icons/Cached';
import ExpandLess from '@material-ui/icons/ExpandLess';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';

//Estilos
import { estilos } from './Utils';

function Notificaciones({ id, abrir, Anclaje, onClose, history }) {
    const classes = estilos();
    const dispatch = useDispatch();

    const [conteo, setConteo] = React.useState(0);
    const userState = useSelector(state => state.user.usuario[0].user.user);
    const activarSonido = useSelector((state) => state.notificacion.notificacion[0]);
    const user = getUsuario(userState.token).usuario;
    const setMenu = (datos) => dispatch(ObtenerMenu(datos));
    const menuSeleccion = useSelector(state => state.menu);

    const [lista, setLista] = React.useState(<ListItem><Cached /><ListItemText primary="Cargando..." /></ListItem>);
    const [pendientes, setPendientes] = React.useState(0);
    const fecha = new Date();
    let cont = 0;

    //#region Querys
    const { data: listado, refetch } = useQuery(LISTADO_NOTIFICACIONES, {
        variables: { id: user.usuarioId },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (e.message === "GraphQL error: No se encontraron registros") {
                setLista([
                    <ListItem key={"sinNot"}>
                        <NotificationsOffIcon />
                        <ListItemText primary="Sin notificaciones" />
                    </ListItem>,
                    <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
                ]);
            } else {
                setLista(
                    <ListItem>
                        <NotificationsOffIcon />
                        <ListItemText primary="Ocurrió un error al obtener las notificaciones" />
                    </ListItem>);
            }
        },
        onCompleted: () => {
            cont = 0;
            if (listado) {
                setLista([listado.listadoNotificaciones.notificaciones.map(item => {

                    if (item.estatus === 1 && item.fecha.split("T")[0] <= fecha.toISOString().split("T")[0]) {
                        cont++;
                        return (<Fragment key={item.notificacionId}>
                            <ListItem className={classes.item} key={item.notificacionId}>
                                <ListItemAvatar className={classes.avatar}>
                                    {item.estatus === 0 ? <NotificationsNoneIcon /> : <NotificationsIcon />}
                                </ListItemAvatar>
                                <ListItemText primary={item.mensaje} secondary={item.fecha.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1")} />
                            </ListItem>
                            <Divider />
                        </Fragment>)
                    }
                }),
                <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
                ]);

            }

            if (cont === 0) {
                setLista([
                    <ListItem key={"sinNot"}>
                        <NotificationsOffIcon />
                        <ListItemText primary="Sin notificaciones" />
                    </ListItem>,
                    <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
                ]);
            } else {
                setConteo(conteo + 1);
                // if (activarSonido.activo === true) {
                //     let audio = new Audio();
                //     audio.src = "./audio/notificacion2.mp3";
                //     audio.play();
                // }
            }
            setPendientes(cont);
        }
    });

    const [cambiarEstatus] = useMutation(CAMBIAR_ESTATUS_NOTIFICACION, {
        onError: (e) => {
            console.log(e.message)
        },
        onCompleted: () => {
            refetch();
            setPendientes(0);

            setLista([
                <ListItem key={"sinNot"}>
                    <NotificationsOffIcon />
                    <ListItemText primary="Sin notificaciones" />
                </ListItem>,
                <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
            ]);
        }
    });
    //#endregion

    //#region handlers
    const handleClick = (opcion) => {
        history.push(opcion);
    }

    function handleClose() {
        if (listado && abrir && pendientes > 0) {
            cambiarEstatus({
                variables: {
                    estatus: 0,
                    usuarioId: user.usuarioId
                }
            })
        }
        onClose();
    }

    function handleRefresh() {
        refetch().then(({ data: lista2 }) => {
            cont = 0;

            if (lista2) {
                setLista([lista2?.listadoNotificaciones.notificaciones.map(item => {
                    if (item.estatus === 1 && item.fecha.split("T")[0] <= fecha.toISOString().split("T")[0]) {
                        cont++;
                        return (<Fragment key={item.notificacionId}>
                            <ListItem className={classes.item} >
                                <ListItemAvatar className={classes.avatar}>
                                    {item.estatus === 0 ? <NotificationsNoneIcon /> : <NotificationsIcon />}
                                </ListItemAvatar>
                                <ListItemText primary={item.mensaje} secondary={item.fecha.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z/, "$3/$2/$1")} />
                            </ListItem>
                            <Divider />
                        </Fragment>)
                    }
                }),
                <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>]);
            }

            if (cont === 0) {
                setLista([
                    <ListItem key={"sinNot"} >
                        <NotificationsOffIcon />
                        <ListItemText primary="Sin notificaciones" />
                    </ListItem>,
                    <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
                ]);
            } else {
                if (activarSonido.activo === true) {
                    let audio = new Audio();
                    audio.src = "./audio/notificacion2.mp3";
                    audio.play();
                }
            }
            setPendientes(cont);
        })
            .catch((e) => {
                if (e.message === "GraphQL error: No se encontraron registros") {
                    setLista([
                        <ListItem key={"sinNot"}>
                            <NotificationsOffIcon />
                            <ListItemText primary="Sin notificaciones" />
                        </ListItem>,
                        <ListItem key={"verTodo"} className={classes.verTodo}><ExpandLess /><ListItemText primary=" Ver todas las notificaciones" /></ListItem>
                    ]);
                } else {
                    setLista(
                        <ListItem>
                            <NotificationsOffIcon />
                            <ListItemText primary="Ocurrió un error al obtener las notificaciones" />
                        </ListItem>);
                }
            });
    }

    window.refresh = handleRefresh;
    //#endregion

    return (
        <Badge badgeContent={pendientes} color="error">
            <Contenedor id={id} abrir={abrir} anclaje={Anclaje} onClose={handleClose}>
                <List className={classes.root} onClick={() => { setMenu(limpiarMenu(menuSeleccion)); handleClick('/notificaciones') }}>
                    {lista}
                </List>
            </Contenedor>
        </Badge>
    )
}

export default withRouter(Notificaciones);
