export const obtenerStateStorage=(tipo)=>{
    const userStorage=localStorage.getItem(tipo);//user
    if(userStorage===null){
        return undefined;
    }
    return JSON.parse(userStorage);
}

export const guardarStateStorage=(state,tipo)=>{
    const userStorage=JSON.stringify(state);
    localStorage.setItem(tipo,userStorage);//user

}
