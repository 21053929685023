import React, { useState, useContext, useEffect } from 'react';
//Componentes Core
import { Dialog, Button, DialogTitle, DialogContent, DialogActions }
    from '../../../componentesGenericos/Core';
//context
import { CRMContext } from './../../../context/Registro';
//Fetch
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//Componentes
import Recuperar from './Formularios/RecuperarDesk';
import Mensaje from '../../../componentesGenericos/Mensaje';
//helpers
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
//query
import { RECUPERAR_CONTRASEÑA } from "../../../mutation";
//constantes
import { agregarPaciente } from './../../../Helpers/constantes/State';
import { mensajeError, mensajeInicial,mensajeExito } from '../../../Helpers/constantes';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'

function RegistroDesk(props) {

    //DECLARACIONES
    let { cerrarRegistro, open } = props;
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [getNotificacion, setNotificacion] = useState(agregarPaciente);
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    let sesiones = process.env.REACT_APP_DB;


    //Eventos
    const habilitarBoton=(valor)=>{
        guardarUsuarios({
            ...usuarios,
            Paciente: {
                ...usuarios.Paciente,
                recuperarContraseña: {
                    ...usuarios.Paciente.recuperarContraseña,
                    error: valor, valor: ''
                }
            }
        });
    }

    const handleClickPaciente = async () => {
        habilitarBoton(true);
        let campos = [
            "email",
            "password"
        ];
        let datos = verificaValores(campos, usuarios.Paciente);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            habilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            await FetchRecuperarPassword();
        }
    }

    //API
    const FetchRecuperarPassword = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        FetchGrahpQL({
            query: RECUPERAR_CONTRASEÑA,
            variables: {
                email: usuarios.Paciente.email.valor,
                password: usuarios.Paciente.password.valor,
                nombreDB: sesiones
            }
        })
            .then(() => {
                setMensaje({ ...mensaje, ...mensajeExito, texto: `Se envió un correo para confirmar` });
                habilitarBoton(false);
                cerrarRegistro();
            })
            .catch((err) => {
                if (err.message === "Failed to fetch") {
                    setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto:"Ha ocurrido algo inesperado,inténtalo  más tarde"});
                } else {
                setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto:"Ha ocurrido algo inesperado,inténtalo  más tarde"});
                }
                habilitarBoton(false);
                cerrarRegistro();
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    };

    //Funciones
    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }




    //Ciclo de vida
    useEffect(() => {
        guardarUsuarios(agregarPaciente)
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }, [open]);

    return (
        <>
            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

            <Dialog maxWidth="sm" open={open}
                onClose={cerrarRegistro}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Recuperar contraseña</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Recuperar />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={cerrarRegistro}
                        color="primary">
                        Cancelar
                    </Button>
                    <Button
                        disabled={ usuarios.Paciente.recuperarContraseña.error}
                        onClick={handleClickPaciente}
                        color="primary">
                        Enviar
              </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}

export default RegistroDesk;
