import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import {ftAnioNoValido,ftAniosRangosValidosHoy} from "../../../../Helpers";
//Core
import { Grid, TextField, InputLabel, Select, MenuItem, FormControl, Input, FormHelperText, IconButton, InputAdornment } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
import { VisibilityIcon, VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//Rgx
import { rgxEmail, rgxtel, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Paciente(props) {

    //Declaraciones
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxPasswords = new RegExp(rgxPassword);
    let rgxTelefono = new RegExp(rgxtel);
    let rgxLetra = new RegExp(rgxLetras);
    const [sexo, setSexo] = React.useState(0);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0  || ftAniosRangosValidosHoy(value)==false || ftAnioNoValido(value)===true) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value)  && value.length>1) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateSexo = event => {
        let { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                [name]: {
                    ...datos.Paciente[name],
                    error: false, valor: value
                }
            }
        });
    }

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleChangeGuardarTelefono = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Paciente: {
                    ...datos.Paciente,
                    [name]: {
                        ...datos.Paciente[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Paciente: {
                ...datos.Paciente,
                showPassword: {
                    ...datos.Paciente.showPassword,
                    error: !datos.Paciente.showPassword.error
                }
            }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    //Funciones
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    //Api
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    //Ciclo de vida
    useEffect(() => {
        FetchGenero();
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio y no debe contener números" error={datos.Paciente.nombres.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Paciente.apellidos.error} />
                        </Grid>
                        <Grid item xs={12}  sd={6} md={6}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Paciente.email.error} />
                        </Grid>
                        <Grid item xs={12} sd={6} md={6}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={datos.Paciente.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Paciente.password.error}
                                    type={datos.Paciente.showPassword.error ? 'text' : 'password'}
                                    value={datos.Paciente.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Paciente.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sd={6} md={6}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Paciente.telefono.error} />
                        </Grid>
                        <Grid item xs={12} sd={6} md={6}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    labelId={`genero`}
                                    id={`genero`}
                                    onChange={handleChangeGuardarStateSexo}
                                    name="sexo"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge el género '}</MenuItem>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                required={true}
                                error={datos.Paciente.fechaNacimiento.error}
                                    style={{ width: "100%" }}
                                    id="date"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    helperText="La fecha de nacimiento es obligatoria"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaNacimiento"
                                />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Paciente;


