import { SELECCIONAR_CLINICA_ACTUAL } from '../Types';

const initialState = {
    seleccionClinica: [{ clinicaId: '' }]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SELECCIONAR_CLINICA_ACTUAL:
            return {
                ...state,
                seleccionClinica: [action.payload]
            }
        default:
            return state;
    }
}
