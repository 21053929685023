import React, { useContext, useState, useEffect } from 'react';
//Core
import { Grid, TextField, FormHelperText } from '../../../../componentesGenericos/Core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Iconos
//Rgx
import { rgxLetras } from '../../../../Helpers/constantes/Rgx';
//Context
import { CRMContext } from '../../../../context/Anamnesis';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
//Fetch
import { LISTADO_TIPO_PADECIMIENTO_FETCH } from "../../../../query/componentes/TipoPadecimiento";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";


//Estilos
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Agregar(props) {

    //Declaraciones
    let rgxLetra = new RegExp(rgxLetras);
    let [datos, guardarDatos] = useContext(CRMContext);
    let [listadoPadecimientos, guardarListadoPadecimientos] = useState([]);
    let { seleccion } = props;
    //API
    const obtenerPadecimientos = async () => {
        await FetchGrahpQL({
            query: LISTADO_TIPO_PADECIMIENTO_FETCH,
            variables: {
            }
        })
            .then(resp => {
                let row = [];
                resp.listadoTipoPadecimiento.TiposPadecimientos.map(function (x) {
                        row.push({ descripcion: x.descripcion, tipoPadecimientoId: x.tipoPadecimientoId });
                });

                guardarListadoPadecimientos(row)
            })
            .catch((err) => {
            });
    };

    //EVENTOS
    const handleChangeGuardarStatePadecimientoId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    padecimientoId: {
                        error: false, valor: values.tipoPadecimientoId
                    }
                }
            });
        }

    }
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        if (value === null || value.length > 200 || !rgxLetra.test(value) || value==="") {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Anamnesis: {
                    ...datos.Anamnesis,
                    [name]: {
                        ...datos.Anamnesis[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }


    //Ciclo de vida
    useEffect(() => {
        guardarDatos({
            ...datos,
            Anamnesis: {
                ...datos.Anamnesis,
                descripcionId:{
                    error: false, valor: seleccion.descripcionId
                },
                pacienteId: {
                    error: false, valor: seleccion.pacienteId
                },
                anamnesisId: {
                    error: false, valor: seleccion.anamnesisId
                },
                nombreCompleto: {
                    error: false, valor: seleccion.nombreCompleto
                },
                genero: {
                    error: false, valor: seleccion.genero
                },
                padecimientos: {
                    error: false, valor: seleccion.padecimientos
                },
                email: {
                    error: false, valor: seleccion.email
                },
                telefono: {
                    error: false, valor: seleccion.telefono
                },
                fechaNacimiento: {
                    error: false, valor: seleccion.fechaNacimiento
                },
                fechaAlta: {
                    error: false, valor: seleccion.fechaAlta
                },
                desactivarBoton: {
                    error: false, valor: seleccion.desactivarBoton
                },
                padecimientoId: {
                    error: false, valor: seleccion.padecimientoId
                }

            }
        });
        obtenerPadecimientos();
    }, []);



    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombreCompleto" id={`nombreCompleto`} label="Nombre completo" type="text" style={{ width: "100%" }} required={true} disabled={true} defaultValue={seleccion.nombreCompleto} />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                noOptionsText={"Sin padecimientos"}
                                id="combo-box-demo"
                                options={listadoPadecimientos}
                                name="padecimientoId"
                                onChange={handleChangeGuardarStatePadecimientoId}
                                getOptionLabel={(option) => { return (`${option.descripcion}`) }}
                                renderInput={(params) => <TextField required={true} {...params} fullWidth label="Selecione el padecimiento" variant="outlined"
                                    error={datos.Anamnesis.padecimientoId.error}
                                />}
                            />
                            <FormHelperText>Padecimientos actuales del cliente</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Ingresar descripción *" style={{ width: "100%", resize: "none", marginTop: "10px",fontStyle: 'normal',fontFamily: 'inherit', fontSize: 16 }} onChange={handleChangeGuardarState}
                            name="descripcion" value={datos.Anamnesis.descripcion.valor}/>
                            <FormHelperText>Tiene un límite de 200 caracteres, y solo permite valores alfabéticos</FormHelperText>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Agregar;

