import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Input from '../../Reportes/componentes/Input';
import { CRMContext } from '../../../context/Configuracion';
import { urlServer } from '../../../Helpers';
import { useSelector } from "react-redux";
import { QRCode } from "react-qr-svg";
import { rgxNumero } from '../../../Helpers/constantes/Rgx';
import { obtenerStateStorage} from '../../../Helpers/LocalStorage/LocalStorage';
import {  mensajeExito } from '../../../Helpers/constantes';

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ConfiguracionQrEmpresa({ setMensaje }) {


    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;
    let sesiones = process.env.REACT_APP_DB;

    const defaultState = {
        nipEmpresa:"Soy un nip",
        imprimir:false

    };

    const defaultError = {
        nipEmpresa: false
    };

    const [ state, setState ] = useState(defaultState);
    const [ error, setError ] = useState(defaultError);

    const campos = [{
        id: 'nipEmpresa',
        texto: 'Capture un código *',
        valor: state.nipEmpresa,
        tipo: 'hr',
        propiedades: {readOnly: false},
        xs:6,
        regexp: rgxNumero,
        ayuda: "Solo admito números",
        error: error.nipEmpresa
    }];



    function handleChange({target}, id, multiselect, validarRegexp) {
        let { value, type, checked } = target;
        value = type === "checkbox" ? checked : value;
        setState({
            ...state,
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        });
        validarRegexp({ id, value, setError });
    }

    function imprimirQr() {

        async function imprimirQr() {
            setState({...state, imprimir:true})
            let myHeaders = new Headers();
            myHeaders.append("token", token);
            myHeaders.append("mimeType", "multipart/form-data");
            let formdata = new FormData();
            formdata.append("data", JSON.stringify(""));
            formdata.append("nombreReporte", "qrEmpresa");
            formdata.append("rutaCodigoQr", `${urlServer}/images/public/png/logotipo-642d5d06-fbf4-47d8-b328-027e3388933e`);
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata
            };
            fetch(`${urlServer}/service/reportes/qrEmpresa`, requestOptions)
                .then(response => {
                    if (!response.ok) return response.json();
                    return response.blob()
                })
                .then(result => {
                    let { error, mensaje } = result;
                    if (error) throw new Error(mensaje);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Se generó correctamente la qrEmpresa` });
                    setState({...state, imprimir:false})

                })
                .catch(error => imprimirQr());
        } imprimirQr();
    }

    useEffect(() => {
        async function ftEffect() {
            try {
                if(state.imprimir === true){
                    imprimirQr();
                }
            } catch (error) {
                console.log("No fue posible imprimir")
            }
        } ftEffect();
    }, [state.imprimir]);

    useEffect(() => {
        async function ftEffect() {
            try {
                console.log(state.nipEmpresa)
            } catch (error) {
                console.log("No fue posible obtener el NIP")
            }
        } ftEffect();
    }, [state.nipEmpresa]);

    return(<>
        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
        {/* <QRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                style={{ width: 256 }}
                value= {sesiones?. claveEmpresa? sesiones.claveEmpresa : "" }
            /> */}
        </Grid>
        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
            <Input campos={campos} handleChange={handleChange} state={state} />
        </Grid>
        <Grid container direction={"row"} justify= {"center"} alignItems = {"center"}>


         {/* <Typography gutterBottom variant="h5" component="h2">
            Código de empresa:  {sesiones?. claveEmpresa? sesiones.claveEmpresa : ""}
          </Typography> */}
        </Grid>

        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
            <Input campos={campos} handleChange={handleChange} state={state} />
        </Grid>

    </>);
}

export default ConfiguracionQrEmpresa;
