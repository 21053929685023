import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, List, ListItem, ListItemText, Button } from '../../../componentesGenericos/Core';
import { LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH } from '../../../query';
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Fieldset from '../../../componentesGenericos/FieldSet';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '551px',
        minWidth: '550px',
        backgroundColor: theme.palette.background.paper,
        padding: '5%'
    },
    inline: {
        display: 'inline',
    },
    rounded: {
        color: '#fff',
        backgroundColor: blue[500],
        marginRight: "3%"
    },
}));


function Padecimientos({ pacienteId, abrir, onClose = () => { } }) {
    const classes = useStyles();
    const [datosModal, setDatosModal] = useState()

    const FetchListadoPadecimientos = async () => {
        FetchGrahpQL({
            query: LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH,
            variables: {
                pacienteId: Number(pacienteId)
            }
        })
            .then((data) => {
                setDatosModal(data.listadoHistorialClienteObservacionesPadecimientos.historialClienteObservacionesPadecimientos)
            })
            .catch((e) => {
                setDatosModal([{ descripcionAnamnesisPadecimiento: null }]);
            })
    }

    const ftGenerarCuerpo = (data) => {
        let row = [];
        if (data !== undefined) {
            data.map(function (x, index) {
                row.push(
                    <>
                        <ListItemText
                            primary={
                                <Typography
                                    component="span"
                                    variant="h5"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {`${x.descripcionPadecimiento !== undefined && x.descripcionAnamnesisPadecimiento !== null ? x.descripcionPadecimiento : "Sin padecimientos"} `}
                                </Typography>
                            }
                            secondary={
                                <>
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {`${x.descripcionAnamnesisPadecimiento !== undefined && x.descripcionAnamnesisPadecimiento !== null ? x.descripcionAnamnesisPadecimiento : ""}`}
                                    </Typography>

                                </>
                            }
                        />
                        <Divider variant="inset" component="li" />
                    </>
                );
                return true;
            });
            return row;
        }
    }

    useEffect(() => {
    }, [datosModal]);

    useEffect(() => {
        FetchListadoPadecimientos();
    }, [abrir]);

    return (
        <Dialog open={abrir}  aria-labelledby="partidas-receta" style={{padding:"10%"}}>
            <Fieldset
                style={{padding:"10%", margin:"10%"}}
                Titulo={
                    <>
                        <DialogTitle id="scroll-dialog-title" >
                            <Typography
                                component="span"
                                variant="h4"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                Padecimientos
                            </Typography>
                        </DialogTitle>
                    </>
                }
                contenido={
                    <>
                        <List className={classes.root}>
                            {ftGenerarCuerpo(datosModal)}
                        </List>
                    </>
                }
            />
            <Button onClick= {onClose} color="primary">
                Cerrar
            </Button>
        </Dialog>
    )
}

export default Padecimientos
