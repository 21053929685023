import React, { useState, useEffect } from 'react';
//COMPONENTES
import MenuLateral from '../../componentesGenericos/SideBar';
import { Typography, ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel, Grid, FormHelperText, Select, FormControl, InputLabel, MenuItem, CardContent, Card, TextField, Button } from '../../componentesGenericos/Core';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
//Query
//REDUX*****
import { useSelector } from "react-redux";
import { LISTADO_CLINICAS_CADENA } from '../../query';
import { SELECT_CLINICAS_FETCH } from '../../componentes/Agenda';
import { HISTORIAL_PAGO_FETCH } from '../../query/componentes/HistorialPago';
//Help
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import Fieldset from '../../componentesGenericos/FieldSet';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
import { ftAnioNoValido, ftAniosRangosValidosAlterno } from "../../Helpers";
import { HistorialPagos } from "../../Helpers/constantes/State";
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
import {
    verificaValores,
    formatearCadena
} from "../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../Helpers/Validacion/ValidacionesCadenasClose";
import { makeStyles } from '@material-ui/core/styles';
import { getDate } from 'date-fns';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));
let estilo = {
    border: `1.5px solid #267f9d`,
    borderRadius: '5px',
    marginBottom: '10px',
    width: `100%`
}


export default function HistorialPago() {

    //DECLARACIONES
    const classes = useStyles();
    let fecha = new Date();
    let [getNotificacion, setNotificacion] = useState(HistorialPagos);
    let [peticion, setPeticion] = useState(HistorialPagos);
    let [boton, setBoton] = useState(false);
    let [clinicas, setClinicas] = useState('');
    let [historialPago, sethistorialPago] = useState('');
    let [expanded, setExpanded] = useState(false);

    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;
    let datos = DecodificarToken(token);

    //EVENTOS
    const handleChangeDatos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || value === -1) {
            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: true, valor: value
                    }
                }
            });
        }
        else {
            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const handleChangeFechaFinal = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) === false || ftAnioNoValido(value) === true) {

            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos.fechaFinal,
                        error: true, valor: value
                    },
                    fechaInicio: {
                        ...peticion.datos.fechaInicio,
                        error: true
                    }
                }
            });


        }
        else {


            if (ftVerificaFecha(peticion.datos.fechaInicio.valor, value) === true) {

                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaFinal: {
                            ...peticion.datos.fechaFinal,
                            error: false, valor: value
                        },
                        fechaInicio: {
                            ...peticion.datos.fechaInicio,
                            error: false
                        }
                    }
                });

            } else {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaFinal: {
                            ...peticion.datos.fechaFinal,
                            error: false, valor: value
                        }
                    }
                });
            }

        }
    }
    const handleChangeFechaInicio = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) === false || ftAnioNoValido(value) === true) {


            setPeticion({
                ...peticion,
                datos: {
                    ...peticion.datos,
                    [name]: {
                        ...peticion.datos[name],
                        error: true, valor: value
                    },
                    fechaFinal: {
                        ...peticion.datos.fechaFinal,
                        error: true,
                    }
                }
            });



        }
        else {


            if (ftVerificaFecha(value, peticion.datos.fechaFinal.valor) === true) {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaInicio: {
                            error: false, valor: value
                        },
                        fechaFinal: {
                            error: false,
                            valor: peticion.datos.fechaFinal.valor
                        }
                    }
                });

            } else {
                setPeticion({
                    ...peticion,
                    datos: {
                        ...peticion.datos,
                        fechaInicio: {
                            error: false, valor: value
                        },
                    }
                });
            }

        }
    }
    const handleClick = async () => {
        deshabilitarBoton(true);
        let campos = [
            "fechaInicio",
            "fechaFinal",
            "clinicaId"
        ];
        let datos = verificaValores(campos, peticion.datos, [["fechaInicio", "fecha inicio"], ["fechaFinal", "fecha final"], ["usuarioId", "doctor"], ["clinicaId", "sucursal"]]);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });


            await FetchHistorialPago();
            deshabilitarBoton(false);
        }
    }
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    //FUNCIONES
    const ftVerificaFecha = (inicio, final) => {
        if ((inicio > final)) {
            return false;
        } else {
            return true;
        }
    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }
    const ftGenerarSelectClinicas = (data) => {
        if (data === null) return null;
        if (data === '') return null;
        let row = [];
        row.push();
        if (data !== undefined) {

            data.listadoClinicas.clinicas.map(function (x) {
                row.push(<MenuItem key={`${x.clinicaId}-doc2`} value={x.clinicaId} readOnly>{x.nombre}</MenuItem>);
                return true;
            });
            return row;
        }
    };
    const ftGenerarBloques = (data) => {
        if (data === '') return null;
        if (data === null) return <h3 style={{ textAlign: "center" }}>No se encontraron registros</h3>;
        let row = [];
        if (data !== undefined) {
            data.listadoHistorialPago.historialPagos.map(function (info, index) {
                row.push(
                    <ExpansionPanel key={`${index}-exp122`} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`${index}-panel1bh-content`}
                            id={`${index}-panel1bh-header`}
                        >
                            <Grid container justify="space-between"
                                alignItems="center">
                                <Grid item xs={12} >
                                    <Typography className={classes.heading}>Crédito: ({info.descripcionCredito})</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography className={classes.secondaryHeading}>
                                        Fecha de alta del crédito: {info.fechaAlta.split('T')[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography className={classes.secondaryHeading}>
                                        Liquidado:{info.estatusPago === false ? <CheckIcon style={{ color: green[500] }} /> : <ClearIcon style={{ color: red[500] }} />}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={3} >

                                {info.partida.map(function (x) {
                                    return (
                                        <Fieldset key={`${index}-user12`} contenido={
                                            <Grid container spacing={1}>
                                                <Grid item xs={12} >
                                                    <p>
                                                        Fecha programada de pago: {x.fechaPago.split('T')[0]}
                                                    </p>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <p>
                                                        {x.estatusPartida === 0 ? `Total a pagar: $${x.totalAPagarMenosParcial.toFixed(2)}` : ""}
                                                        {x.estatusPartida === 1 ? `Pago saldado: $${x.totalPago.toFixed(2)}` : ""}
                                                        {x.estatusPartida === 2 ? `Pago atrasado: $${x.pagoAtrasadoMenosParcial.toFixed(2)}` : ""}


                                                        {x.estatusPartida === 3 ?
                                                            x.fechaPago.split('T')[0] <= fecha.toISOString().slice(0, 10) ? `Pago Parcial: $${x.totalAPagarMenosParcial}` : `Pago Parcial: $${x.pagoAtrasadoMenosParcial}` : ""
                                                        }
                                                    </p>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <p>
                                                        Producto: {x.productos}
                                                    </p>
                                                </Grid>
                                            </Grid>
                                        } Titulo="" estilos={estilo}></Fieldset>
                                    );
                                })}
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>

                );
                return true;
            });
            return row;
        }
    };

    //API
    const FetchHistorialPago = async () => {

        await FetchGrahpQL({
            query: HISTORIAL_PAGO_FETCH,
            variables: {
                filtro: {
                    clinicaId: parseInt(peticion.datos.clinicaId.valor),
                    clienteId: parseInt(datos.usuarioId),
                    estatusPago: null,
                    estatusPartida: null,
                    vendedorId: null,
                    fechaAlta: null,
                    fechaPagoFinal : null,
                    fechaInicioPartida:  peticion.datos.fechaInicio.valor,
                    fechaFinalPartida: peticion.datos.fechaFinal.valor,
                    productoId: null,
                    ticketEncabezadoId: null,
                    PagosAdeudo: null
                }
            }
        })
            .then((data) => {
                sethistorialPago(data);
            })
            .catch((err) => {
                console.log("Error:",err)
                sethistorialPago(null);
            });
    };
    const FetchClinicas = async () => {
        await FetchGrahpQL({
            query: LISTADO_CLINICAS_CADENA,
            variables: {
                limite: 10000,
                pagina: 1
            }
        })
            .then((data) => {
                setClinicas(data);
            })
            .catch((err) => {
                setClinicas(null);
            });
    };

    //CICLO DE VIDA
    useEffect(() => {
        FetchClinicas();
    }, []);


    return (
        <MenuLateral>
            <Encabezado titulo="Historial de pagos"/>
            <Card className={classes.mb1}>
                <CardContent>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            {GenerarNotificacionv2(getNotificacion, "w100")}
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" disabled required={true}>Sucursales </InputLabel>

                                <Select
                                    labelId={`clinicaId`}
                                    id={`clinicaId`}
                                    name="clinicaId"
                                    onChange={handleChangeDatos}
                                    required={true}
                                //  value={peticion.datos.clinicaId.valor}
                                >
                                    <MenuItem value={'-1'} disabled>{'Sucursales'}</MenuItem>
                                    {ftGenerarSelectClinicas(clinicas)}
                                </Select>

                                <FormHelperText>La sucursal es campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <TextField margin="dense" name="usuarioId" id={`usuarioId`} label="Cliente" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeDatos} disabled={true} value={datos.nombreCompleto} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    onChange={handleChangeFechaInicio}
                                    style={{ width: "100%" }}
                                    id="date"
                                    value={peticion.datos.fechaInicio.valor}
                                    required={true}
                                    label="Desde"
                                    helperText="La fecha de inicio es obligatoria"
                                    type="date"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaInicio"
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={3}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    value={peticion.datos.fechaFinal.valor}
                                    onChange={handleChangeFechaFinal}
                                    style={{ width: "100%" }}
                                    id="date"
                                    required={true}
                                    label="Hasta"
                                    helperText="La fecha final es obligatoria"
                                    type="date"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaFinal"
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                            <Button variant="contained" color="primary" disabled={boton} onClick={handleClick}>
                                Buscar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.root} style={{ marginTop: "15px" }}>
                                {ftGenerarBloques(historialPago)}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

        </MenuLateral>
    )
}

