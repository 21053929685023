import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
import { SELECT_ESTATUS_FETCH } from '../../query';
import { LISATADO_ESTATUS_PROGRAMADO } from '../../query/componentes/EstatusAgendaProgramado';
import { NUEVO_PROGRAMACION, MODIFICA_PROGRAMACION, ELIMINAR_PROGRAMACION } from '../../mutation/componentes/EstatusAgendaProgramado';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';

import { ftValidarCampos } from '../../Helpers/constantes/Config/Helper';
import { ftSelectFormat, trim } from '../../Helpers';
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';

import { rgxSelectID  } from '../../Helpers/constantes/Rgx';

function Recordatorios ({ history }) {

    const { loading, error:{message} = {}, data, refetch } = useQuery(LISATADO_ESTATUS_PROGRAMADO,
        { variables: { limite: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted:()=>{
            setErrorState(false);
        }
    });

    const [deleteProgramacion] = useMutation(ELIMINAR_PROGRAMACION, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la programación del estatus` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [addProgramacion] = useMutation(NUEVO_PROGRAMACION, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente la programación` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [updateProgramacion] = useMutation(MODIFICA_PROGRAMACION, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la programación` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const seleccionInicial = {
        programacionID: null,
        estatusID: null,
        nuevoEstatusID: null,
        dia: 0,
        hora: 0,
        minuto: 0,
        programarAntesCita: true
    };

    //#region states
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState({...mensajeInicial});
    const [estatusCitasSel, setEstatusCitasSel] = useState([]);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [stateCopy, setStateCopy] = useState({});
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    //#endregion

    const columnas = errorState ? [{ id: 'msj', label: '', format: valor => (<p style={{ fontSize: 18, margin: 0 }}>{valor}</p>) }]
    : [
        { id: 'programacionID', label: 'ID', minWidth: 0, hide: true },
        { id: 'descripcionEstatusCita', label: 'Estatus a afectar', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'descripcionEstatusCitaNueva', label: 'Estatus nuevo', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'hora', label: 'Hora(s) para cambio', minWidth: 170, align: 'center', filtro: true },
        { id: 'minuto', label: 'Minuto(s) para cambio', minWidth: 170, align: 'center', filtro: true },
        { id: 'programarAntesCita', label: 'Modificar antes de la cita', minWidth: 170, align: 'center', component: "Checkbox" }
    ];

    const menu = [
        { texto: "Agregar estatus programado", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar estatus programado", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar estatus programado", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Desplazarse al catálogo de los estatus de la agenda", icono: <EventAvailableIcon />, onClick: (e) => redirige("/estatusagenda") }
    ];

    // const dias = [0,1,2,3,4,5,6,7,8,9,10].map(n => ({value: n, label: n}));
    const horas = [0,1,2,5,10,15,20].map(n => ({value: n, label: n}));
    const minutos = [0,15,30,45].map(n => ({value: n, label: n}));

    const queryEstatus = () => {
        async function queryEstatus() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_ESTATUS_FETCH,
                    variables: { limite: 100, predeterminado: null }
                });

                let {listadoEstatusCita:{estatusCitas}} = resultado;

                return ftSelectFormat(estatusCitas, {
                    id: "estatusID",
                    descripcion: "descripcionEstatus",
                    otro: [{ id: "colorEstatus", alias: "color" }]
                }, "Seleccionar estatus del evento");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryEstatus();
    };

    const camposFormulario = [
        {
            id: "programacionID",
            texto: "programacionID",
            valor: seleccion.programacionID,
            tipo: "hidden",
            xs: 12
        },
        {
            id: "estatusID",
            texto: "Seleccionar el estatus de la cita *",
            valor: seleccion.estatusID,
            tipo: "select",
            opciones: estatusCitasSel,
            ayuda: "Seleccione un estatus válido",
            regexp: rgxSelectID,
            xs: 12
        },
        {
            id: "nuevoEstatusID",
            texto: "Selecciona el estatus de la cita al que cambiará *",
            valor: seleccion.nuevoEstatusID,
            tipo: "select",
            opciones: estatusCitasSel,
            ayuda: "Seleccione un estatus válido",
            regexp: rgxSelectID,
            xs: 12
        },
        // {
        //     id: "dia",
        //     texto: "Día",
        //     valor: seleccion.dia,
        //     tipo: "select",
        //     opciones: dias,
        //     xs: 4
        // },
        {
            id: "hora",
            texto: "Horas para realizar el cambio",
            valor: seleccion.hora,
            tipo: "select",
            opciones: horas,
            xs: 6
        },
        {
            id: "minuto",
            texto: "Minutos para realizar el cambio",
            valor: seleccion.minuto,
            tipo: "select",
            opciones: minutos,
            xs: 6
        },
        {
            id: "programarAntesCita",
            texto: "Programación",
            valor: seleccion.programarAntesCita,
            opciones: [
                { value: true, label: "Antes de la cita" },
                { value: false, label: "Después de la cita" }
            ],
            tipo: "superradio",
            onChange: handleChangeRadio,
            xs: 12,
        }
    ];
    let sinRegistros = [{ msj: message ? message : "No se encontraron registros" }];
    const listado = errorState ? sinRegistros : (data ? (data.listadoProgramacion ? data.listadoProgramacion.programados : sinRegistros) : sinRegistros);

    //#region handlers
    const redirige=(texto)=>{
        history.push(texto);
    }

    function handleChangeRadio ({target:{value:programarAntesCita}}) {

        setSeleccion({
            ...seleccion,
            programarAntesCita: programarAntesCita === 'true' ? true : false
        });
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleModificarClick() {
        if (seleccion.programacionID) {
            setSeleccion({ ...stateCopy });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un estatus programado" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.programacionID) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el estatus programado?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un estatus programado" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion({ ...datos });
            setStateCopy({ ...datos });
            setFila(index);
        } else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function ftAgregar({estatusID, nuevoEstatusID, dia, hora, minuto, programarAntesCita, cleanStateFormulario}) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                let programarAntesCitaFormat = typeof programarAntesCita === "boolean" ? programarAntesCita : false;

                if(Number(estatusID) === Number(nuevoEstatusID)) throw new Error("No es correcto seleccionar el mismo estatus, selecione uno diferente");

                if (ftValidarCampos([estatusID, nuevoEstatusID]) && (Number(dia) >= 0 && Number(hora) >= 0 && Number(minuto) >= 0)) {

                    await addProgramacion({
                        variables: {
                            estatusID: Number(estatusID),
                            nuevoEstatusID: Number(nuevoEstatusID),
                            dia: Number(dia),
                            hora: Number(hora),
                            minuto: Number(minuto),
                            programarAntesCita: programarAntesCitaFormat
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({programacionID, estatusID, nuevoEstatusID, dia, hora, minuto, programarAntesCita, cleanStateFormulario}) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);

                let programarAntesCitaFormat = typeof programarAntesCita === "boolean" ? programarAntesCita : false;

                if(Number(estatusID) === Number(nuevoEstatusID)) throw new Error("No es correcto seleccionar el mismo estatus, selecione uno diferente");

                if (ftValidarCampos([programacionID, estatusID, nuevoEstatusID]) && (Number(dia) >= 0 && Number(hora) >= 0 && Number(minuto) >= 0)) {

                    await updateProgramacion({
                        variables: {
                            programacionID: Number(programacionID),
                            estatusID: Number(estatusID),
                            nuevoEstatusID: Number(nuevoEstatusID),
                            dia: Number(dia),
                            hora: Number(hora),
                            minuto: Number(minuto),
                            programarAntesCita: programarAntesCitaFormat
                        }
                    });

                    if(cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteProgramacion({ variables: { id: Number(seleccion.programacionID) } });
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.error(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        async function fEffect() {
            const estatus = await queryEstatus();
            setEstatusCitasSel(estatus);
        } fEffect();
    }, []);

    return(<MenuLateral titulo="Cambio automático del estatus de la agenda" menu={menu}>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

        <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={`${seleccion.programacionID ? "Modificar" : "Agregar"} automatización`} onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

        <Tabla titulo="Cambio automático del estatus de la agenda" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcionEstatusCita", "descripcionEstatusCitaNueva"]} />

    </MenuLateral>);

}

export default Recordatorios;
