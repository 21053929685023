import React from 'react';
import PropTypes from 'prop-types';
function Fieldset({Titulo, contenido,estilos=null}) {

let estilo={
    border: `1.5px solid #267f9d`,
    borderRadius: '5px',
    marginBottom:'5px',
    display: 'flex',
    justifyContent:'left',

}

    return (
        <fieldset style={estilos===null?estilo:estilos}>
            <legend>{Titulo}</legend>
            {contenido}
        </fieldset>
    )
}

Fieldset.propTypes = {
    color: PropTypes.string,
    Titulo: PropTypes.string.isRequired,
    contenido: PropTypes.object.isRequired
};

export default Fieldset;
