import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Recordatorio from './Formulario/Recordatorio';
import { CRMContext } from '../../../context/NotificacionCreditos';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/RecordatorioCreditoAction";
//helper
import { notificacionDeCredito } from '../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Agregar(props) {

    //Declaraciones
    const { actualiza } = props;
    let { open, ftClose ,seleccion} = props;
    const [datos, guardarDatos] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(notificacionDeCredito);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const Crud = (categoria, evento, datos, desactivar, cerrar) => dispatch(CRUD(categoria, evento, datos, desactivar, cerrar));

    //Eventos
    const handleClick = () => {
         ActivarDesactivarBoton(true);
        let campos = [
            "recordatorioPagoId",
            "plazoCredito",
            "tituloRecordatorio",
            "descripcionRecordatorio",
            "dias",
            "recordarAntesDespues"
        ];
        let datosV = verificaValores(campos, datos.datos,[["recordatorioPagoId","Recordatorio de pago"],["tituloRecordatorio","titulo de recordatorio"],["descripcionRecordatorio","descripción de recordatorio"],["dias","días"],["recordarAntesDespues","recordar antes y después"],["plazoCredito","plazo de credito"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(1, actualiza, datos.datos,(n)=>  ActivarDesactivarBoton(n),ftClose);
            limpiarMensaje();
        }

    }

    //Funciones
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    const ActivarDesactivarBoton = (tipo) => {
        guardarDatos({
            ...datos,
            datos: {
                ...datos.datos,
                desactivarBoton: {
                    ...datos.datos.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }


    //Ciclo de vida
    useEffect(() => {
        setNotificacion( getNotificacion => ({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        }));
        guardarDatos(notificacionDeCredito);
    }, [open]);


    return (
        <>
            <Dialog maxWidth="lg" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">

                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Modificar notificación de crédito</DialogTitle>

                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Recordatorio seleccion={seleccion} />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
                    </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button
                            onClick={handleClick}
                            color="primary">
                            GUARDAR
                      </Button>
                    </a>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Agregar;
