import React from 'react';
//Iconos
import { AddIcon, EditIcon, BlockIcon, SwitchIcon} from '../../../componentesGenericos/Icons';




//seleccion para Columnas
const columnas = [
    {id:'movsId', label: 'movsId', minWidth: 0, hide: true},
    {id:'clinicaId', label: 'clinicaID', minWidth: 0, hide: true},
    {id: 'nombre', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left', filtro: true},
    {id:'almacenId', label: 'almacenId', minWidth: 0, hide: true},
    {id: 'nombreAlmacen', label: 'Almacén', minWidth: 100, align: 'left', textAlign: 'left', filtro: true},
    {id:'ccostoId', label: 'ccostoId', minWidth: 0, hide: true},
    {id: 'nombrecosto', label: 'Centro de costo', minWidth: 100, align: 'left', textAlign: 'left', filtro: true},
    {id:'servicioId', label: 'servicioId', minWidth: 0, hide: true},
    {id: 'servicio', label: 'Producto', minWidth: 100, align: 'left', textAlign: 'left', filtro: true},
    {id:'tallaId', label: 'tallaId', minWidth: 0, hide: true},
    {id: 'talla', label: 'Talla', minWidth: 0, hide: true},
    {id:'loteId', label: 'loteId', minWidth: 0, hide: true},
//     {id: 'tipoMov', label: 'Tipo Movimiento', minWidth: 30, align: 'center', textAlign: 'center',
//     format: (valor) => {
//         return valor?  valor.toString().replace("0", "Entrada").replace("1", "Salida"): "";
//     }
// },
    {id:'conceptoInvId', label: 'conceptoInvId', minWidth: 0, hide: true},
    {id: 'pedimento', label: 'Pedimento', minWidth: 0, hide:true},
    {id:'existenciaId', label: 'existenciaId', minWidth: 0, hide: true},
    {id: 'entradas', label: 'Entrada', minWidth: 100, align: 'right', textAlign: 'right'},
    {id: 'salidas', label: 'Salida', minWidth: 100, align: 'right', textAlign: 'right'},
    {id: 'existenciaActualTemporal', label: 'Existencia actual', minWidth: 100, align: 'right', textAlign: 'right'},
    {id: 'nombreCto', label:'Concepto', minWidth:100, align:'left', textAlign: 'left', filtro: true},
    { id: 'referencia', label: "Referencia", minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
    { id: 'nombreUsuario', label: 'Realizado por',obtenerColumna: 'apellidoUsuario',align: 'left', textAlign: 'left', format: (nombreUsuario, apellidoUsuario) => { return nombreUsuario + " " + apellidoUsuario }},
    {id: 'fechaMov', label: 'Fecha de movimiento', minWidth: 100, align: 'center',
    format: (valor) => {
        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1  $4");
    }},
];




//HABILITAR AGREGAR Y MODIFICAR

//estate inicial
 const actualizarState = false;
 const filaState = -1;


//Estilos
const Style = {
    height: "90%", textAlign: "center", width:"100%"
};

export {columnas, Style, actualizarState, filaState};
