import gql from 'graphql-tag';

//#region CONVENIO USUARIO

const LISTAR_CONVENIO_USUARIO_ID=gql`
query listarConvenioUsuario($offset: Int!) {
    listarConvenioUsuario(offset: $offset) {
      convenioUsuarios {
        convenioUsuarioId
        convenioId
        usuarioId
        qrConveniosUsuarios
        estatusConveniosUsuarios
        fechaAltaConveniosUsuarios
        fechaBajaConveniosUsuarios
        tipo
        nombres
        apellidos
        email
        fechaEgreso
        fechaAlta
        estatus
        sexo
        tarjetaQr
        correo
        telefono
        empresaConvenio
        tipoPagoConvenio
        opcionesPagoConvenio
        estatusConvenio
        fechaAltaConvenio
        fechaInicialConvenio
        fechaFinalConvenio
        RFCConvenio
        emailConvenio
        nombreCompleto
      }
    }
  }
`;

const OBTENER_CONVENIO_USUARIO_ID = `
query obtenerConvenioUsuario($convenioId: ID, $usuarioId: ID) {
    obtenerConvenioUsuario(convenioId: $convenioId, usuarioId: $usuarioId) {
      convenioUsuarioId
      convenioId
      usuarioId
      qrConveniosUsuarios
      estatusConveniosUsuarios
      fechaAltaConveniosUsuarios
      fechaBajaConveniosUsuarios
      tipo
      nombres
      apellidos
      email
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      empresaConvenio
      tipoPagoConvenio
      opcionesPagoConvenio
      estatusConvenio
      fechaAltaConvenio
      fechaInicialConvenio
      fechaFinalConvenio
      RFCConvenio
      emailConvenio
      urlLogo
    }
  }
`;

const OBTENER_QR=`
query QRCompleto($id:Int!){
    GenerarQRCompleto(id:$id)
}`;

const DESCUENTOS_CONVENIOS=gql`
query obt($convenio:Int!,$clinica:Int!){
  ObtenerDescuentos(convenio:$convenio,clinica:$clinica){
    DescuentosConvenio{
      servicioId
      porcDescuento
      clinicaId
    }
  }
}
`;
//#endregion

export {
    OBTENER_CONVENIO_USUARIO_ID,
    LISTAR_CONVENIO_USUARIO_ID,
    OBTENER_QR,
    DESCUENTOS_CONVENIOS
}
