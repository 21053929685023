import gql from 'graphql-tag';

//#region Garantias
//=========Garantias=============
const OBTENER_GARANTIA = `
query garantia($id: Int!){
    obtenerGarantia(id:$id){
      garantiaId
      garantia
      diasGarantia
      fechaAlta
      servicio{
        servicioId
        servicio
        pu
        descto
        iva
        porcIva
        comision
        agendable
        tipoId
        tipo
      }
    }
}`;

const LISTADO_GARANTIAS = gql`
	query listado($off: Int, $limit: Int) {
		listadoGarantias(pagina: $off, limite: $limit) {
			garantias {
				garantiaId
				garantia
				diasGarantia
				fechaAlta
				servicioId
				servicio
				pu
				descto
				iva
				porcIva
				comision
				agendable
				tipoId
				tipo
			}
			registros
			paginas
		}
	}
`;

const LISTADO_GARANTIAS_DESCRIPCION = `
	query listado($off: Int, $limit: Int) {
		listadoGarantiasDescripcion(pagina: $off, limite: $limit) {
			garantias {
				garantiaId
				garantia
			}
			registros
			paginas
		}
	}
`;
//#endregion
//=====================================================

export {
    OBTENER_GARANTIA,
    LISTADO_GARANTIAS,
    LISTADO_GARANTIAS_DESCRIPCION
}
