import gql from 'graphql-tag';

//#region Especialidades Doctores
//=========Especialidades==Doctores=============

const OBTENER_ESPECIALIDAD_DOCTOR = `
query obtenerEspecialidadDoctor($id:Int!){
    obtenerEspecialidadDoctor(id:$id){
      especialidadId
      descripcion
    }
}`;
const LISTADO_ESPECIALIDAD_DOCTOR=gql`
query listadoEspecialidadDoctor($offset:Int!){
    listadoEspecialidadDoctor(offset:$offset){
      registros
      paginas
      especialidadesDoctores{
        especialidadId
        descripcion
      }
    }
  }`;
const LISTADO_ESPECIALIDAD_DOCTOR_CADENA=`
query listadoEspecialidadDoctor($offset:Int!){
    listadoEspecialidadDoctor(offset:$offset){
      registros
      paginas
      especialidadesDoctores{
        especialidadId
        descripcion
      }
    }
  }`;

//#endregion
//===============================================

//#region Especialidades Doctores
//=========Clinica=Doctor========================
const LISTADO_CLINICA_DOCTOR = gql`
query listadoClinicaDoctor($limite: Int!, $pagina: Int!, $clinicaId: Int) {
    listadoClinicaDoctor(limite: $limite, pagina: $pagina, clinicaId: $clinicaId) {
      registros
      paginas
      clinicaDoctores {
        clinicaId
        doctorId
        clinicaDoctorId
      }
    }
  }
`;
const OBTENER_CLINICA_DOCTOR = `
query obtenerClinicaDoctor($id: Int!) {
    obtenerClinicaDoctor(id: $id) {
      clinicaId
      doctorId
      clinicaDoctorId
    }
  }
`;
//#endregion
//===============================================

export {
    OBTENER_ESPECIALIDAD_DOCTOR,
    LISTADO_ESPECIALIDAD_DOCTOR,
    LISTADO_ESPECIALIDAD_DOCTOR_CADENA,
    LISTADO_CLINICA_DOCTOR,
    OBTENER_CLINICA_DOCTOR
}
