import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Doctor from './Formulario/Doctor';
import { CRMContext } from '../../../context/Usuarios/Usuarios';
//redux
import { useDispatch, useSelector } from "react-redux";
import { CRUD } from "../../../Redux/Actions/UsuariosAction";
//helper
import { limpiarStateGeneralUsuarios } from '../../../Helpers/constantes/State';
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
import { agregarDoctor } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Agregar(props) {

    //Declaraciones
    const { actualiza } = props;
    let { open, ftClose } = props;
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(agregarDoctor);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);
    const Crud = (categoria, tipo, evento, datos, desactivar, cerrar) => dispatch(CRUD(categoria, tipo, evento, datos, desactivar, cerrar));

    //Eventos
    const handleClickUsuario = () => {
        ActivarDesactivarBoton(true);
        let campos = [
            "nombres",
            "apellidos",
            "email",
            "password",
            "sexo",
            "telefono",
            "especialidadId",
            // "cedula",
            "fechaNacimiento",
            "fechaEgreso"
        ];
        usuarios.Doctor.tipo = { error: false, valor: userToken.tipo };
        let datosV = verificaValores(campos, usuarios.Doctor, [["sexo", "género"], ["fechaNacimiento", "fecha de nacimiento"],
        // ["cedula","cédula"],
        ["telefono", "teléfono"], ["fechaEgreso", "fecha de egreso"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });

            Crud(0, "Doctor", actualiza, usuarios.Doctor, (n) => ActivarDesactivarBoton(n), ftClose);
            limpiarMensaje();

        }

    }

    //Funciones
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        // guardarUsuarios(limpiarStateGeneralUsuarios);
    }

    const ActivarDesactivarBoton = (tipo) => {
        guardarUsuarios({
            ...usuarios,
            Doctor: {
                ...usuarios.Doctor,
                desactivarBoton: {
                    ...usuarios.Doctor.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }


    //Ciclo de vida
    useEffect(() => {

        setNotificacion((getNotificacion) => ({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        }));

        guardarUsuarios({ ...limpiarStateGeneralUsuarios });
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">

                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Agregar profesional</DialogTitle>

                {/*formulario*/}
                <DialogContent>
                    <a name="arriba"><p style={{ display: "none" }}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Doctor />
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
             </Button>
                    <a href="#arriba" style={{ textDecoration: "none" }}>
                        <Button
                            disabled={usuarios.Doctor.desactivarBoton.error}
                            onClick={handleClickUsuario}
                            color="primary">
                            GUARDAR
                      </Button>
                    </a>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Agregar;
