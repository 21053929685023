// Fetch
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';

// Gráficas
import { GRAFICA_FETCH }  from '../../../query/componentes/Graficas';

//#region Querys
export const queryGraficas = (data) => {
    async function queryGraficas() {
        try {
            let resultado = await FetchGrahpQL({
                query: GRAFICA_FETCH,
                variables: { ...data }
            });
            let { obtenerGrafica } = resultado;
            return obtenerGrafica;
        } catch({message:error}) { return { error } }
    } return queryGraficas();
};
