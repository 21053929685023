import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
//****Router**************************************
import { withRouter } from "react-router-dom"
//****PROVIDER**************************************
import { CRMContext, CRMProvider } from './../../../context/Registro';
//****Componentes*********************************
import Mensaje from '../../../componentesGenericos/Mensaje';
import RegistroDesk from '../Registro/RegistroDesk';
import RecupertarPassword from '../RecuperarPassword/RecuperarDesk';
//*************Core-components*********************
import { Grid, InputAdornment, TextField, Typography, Button, ButtonGroup, CardContent, Card, Link, CssBaseline, Paper, Avatar, Checkbox, Box, FormControlLabel, Dialog, DialogTitle, IconButton, Fade }
    from '../../../componentesGenericos/Core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../../../index.css';
//*************Iconos******************************
import { LockOpenIcon, PersonIcon, LockOutlinedIcon, CloseIcon } from '../../../componentesGenericos/Icons';
//*************Componentes-Genericos****************
import BotonBoostrap from "../../../componentesGenericos/Botones/BotonBoostrap";
//*************Helper*******************************
import {
    verificaValores,
    formatearCadena,
} from "../../../Helpers/Validacion/ValidacionCadenas";

import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";

import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//*************Mutation*******************************
import { INICIAR_SESION } from "../../../mutation";
import { LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD } from "../../../query/componentes/Promociones";
import { queryConfiguracionInicial } from '../../Configuracion/Metodos/index';
//*************Constantes*****************************
import { mensajeError, mensajeInicial } from '../../../Helpers/constantes';
import { notificacionesLogin, usuarioLogin } from '../../../Helpers/constantes/State';
//****REDUX***********/
import { useDispatch } from "react-redux";
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'
import { IniciarSesion } from "../../../Redux/Actions/SesionAction";
import { SonidoActivacion } from "../../../Redux/Actions/SonidoNotificacionesAction";
import { FotoPerfil } from "../../../Redux/Actions/FotoPerfilAction";
//****HELPER**********/
import { UseFocus } from "../../../Helpers/Eventos/Hooks";
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
//****** MATERIAL UI ******
import { makeStyles } from '@material-ui/core/styles';
import { urlServer, strUrlSCliente, strUrlSArchivos } from '../../../Helpers';
import { banerDefault, iconoAdmin, iconoPaciente, iconoDoctor, iconoUsuario } from '../../../Helpers/constantes/Config/imgDefault';
import { rutaPublica, rutaPrivada } from '../../../Helpers/constantes/Config/urlRutas';
import { getImagen } from '../../../Helpers/constantes/Imagenes/getImagen'

//Socket
import { socket } from '../../../App';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        cursor: "pointer",
        backgroundRepeat: 'no-repeat!important',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover!important',
        backgroundPosition: 'center!important',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));


function IniciarSesionDesk(props) {
    let sesiones = process.env.REACT_APP_DB;


    //Configuracion
    const { descripcionLogin = "", urlLogotipo = "/images/icono_dentalPerfiles.jpg" } = props.configuracion;
    const rutasConfig = {rutaPublica:"", rutaPrivada:""};
    const logotipo = `${urlServer}${urlLogotipo}`;
    const defaultState = {
        urlLogotipo: logotipo,
        fileUrl: null
    };
    //Variables
    const [openImg, setOpenImg] = useState(false);
    const [usuario, guardarUsuario] = useContext(CRMContext);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [getNotificacion, setNotificacion] = useState(notificacionesLogin);
    const [getUsuario, setUsuario] = useState(usuarioLogin);
    const [open, setOpen] = useState(false);
    const [abrirRecuperarPassword, enviarRecuperarPassword] = useState(false);
    const [photo, setPhoto] = useState("")
    const [vista, setVista] = useState("");
    const [configuracion,setConfiguracion]= useState("");
    const [cargaImagen, setCargaImagenes] = useState({});
    const [urlImagen, setUrlImagen] = useState("");
    const [ stateImg, setStateImg] = useState(defaultState);

    let bdEmp = sesiones ;
    const classes = useStyles();

    //Focus
    const [input1Ref, setInput1Focus] = UseFocus();
    const [input2Ref, setInput2Focus] = UseFocus();

    const { history } = props;

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const agregarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const agregaRutaPerfil = (datos) => dispatch(FotoPerfil(datos));
    const agregarSonido = (datos) => dispatch(SonidoActivacion(datos));

    //Eventos
    /* useEffect(() => {
        async function ftEffect() {
            try {
                let rutas = await consultaRutas();
                setCargaImagenes(rutas);
            } catch({message}) {
                console.log(message);
            }
        } ftEffect();
    }, []);*/
    const EventChangeDatos = event => {
        let { name, value } = event.currentTarget;
        value = value.trim();
        if (value === null || value.length === 0) {
            setUsuario({ ...getUsuario, [name]: { error: true, valor: "" } });
        } else {
            setUsuario({ ...getUsuario, [name]: { error: false, valor: value } });
        }
    };
    const EventAutenticarCliente = async () => {
        ActivarDesactivarBoton(true);
        let campos = [
            "email",
            "password"
        ];
        let datos = verificaValores(campos, getUsuario);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchIniciarSesion();
        }
    };
    const userIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonIcon style={{ color: valor }} />
                </InputAdornment>
            )
        }
    };
    const passwordIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <LockOpenIcon style={{ color: valor }} />
                </InputAdornment>
            )
        }
    };
    const AbrirRegistro = () => {
        setOpen(true);
    }
    const CerrarRegistro = () => {
        setOpen(false);
    }
    const CerrarRecuperarPassword = () => {
        enviarRecuperarPassword(false);
    }
    const AbrirRecuperarPassword = () => {
        enviarRecuperarPassword(true);
    }
    const ftCloseImg = () => {
        setOpenImg(false);
    }

    //genero dinamicamente fotos
    const generarSelect = galerias => {
        if (galerias === null) {
            return null;
        }
        if (galerias.listadoPromocionesCaducidad == null) {
            return null;
        }
        //se enlista las promociones validas
        let row = [];
        if (galerias != undefined) {


            //let imagenes = cargaImagen ? cargaImagen : "cargando";

            let rutaEmpresa = rutaPublica;
            let rutaDefault = rutaEmpresa;// imagenes.rutaPublica;

            galerias.listadoPromocionesCaducidad.ImagenesPromocionesCaducidades.map(function (x) {
                if (x.imgPromoId === "-1") {
                    row.push(
                        <Fade in={true} key={x.imgPromoId}>
                            <div key={x.imgPromoId} className="maximizaAlto" onClick={() => { setOpenImg(true); setVista(`${rutaDefault}${x.urlImagen}`) }}>
                                <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${rutaDefault}${x.urlImagen}`} />
                            </div>
                        </Fade>
                    );
                } else {
                    row.push(
                        <Fade in={true} key={x.imgPromoId}>
                            <div key={x.imgPromoId} className="maximizaAlto" onClick={() => { setOpenImg(true); setVista(`${rutaEmpresa}${x.urlImagen}`) }}>
                                <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${rutaEmpresa}${x.urlImagen}`} />
                            </div>
                        </Fade>
                    );
                }

            });
            return row;
        }
    };

    //API
    const FetchIniciarSesion = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        FetchGrahpQL({
            query: INICIAR_SESION,
            variables: {
                email: getUsuario.email.valor,
                password: getUsuario.password.valor,
                nombreDB: sesiones
            }
        })
            .then(resp => {
                if (resp.iniciarSesion.success === false) {
                    setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: resp.iniciarSesion.error });
                } else {
                    Login(resp);
                }
                ActivarDesactivarBoton(false);
                return resp;
            })
            .catch((err) => {
                if (err.message === "Failed to fetch") {
                    setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: "Ha ocurrido algo inesperado,inténtalo  más tarde " });
                } else {
                    setMensaje({ ...mensaje, ...mensajeError, titulo: "¡Aviso!", texto: err.message });
                }
                ActivarDesactivarBoton(false);
            });

        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });

    };
    const FetchObtenerFotosPromociones = async () => {
            await FetchGrahpQL({
                query: LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD,
                variables: {
                    estatus: 1,
                    nombreDB: bdEmp,
                },
            })
                .then((resp) => {
                    setPhoto(resp === null || resp === "" ? "" : resp);
                })
                .catch(() => {
                    setPhoto({
                        listadoPromocionesCaducidad: {
                            ImagenesPromocionesCaducidades: [
                                {
                                    imgPromoId: "-1",
                                    descripcion: "imagen por defecto",
                                    urlImagen: banerDefault,
                                    nombreImagen: "",
                                    estatus: "",
                                    caducidad: "",
                                },
                            ],
                        },
                    });
                });
    }
    const FetchConfiguracion = async () => {
            let data = await queryConfiguracionInicial({
                nombreDB: sesiones,
            });
            setConfiguracion(data);
    }

    const Obtener = async () => {
        const favicon = document.getElementById("favicon");

        const configuracion = await queryConfiguracionInicial({
            nombreDB: sesiones,
        });
        let rutaimg = configuracion.urlLogotipo;

        let ruta = rutaPublica + rutaimg;
        setStateImg({
            ...stateImg,
            urlLogotipo: ruta,
        });
        //Actualiza el favicon correspondiente a la empresa
        //favicon.href = ruta;
        /*archivoImg(ruta).then(imagen => {
        setStateImg({
            ...stateImg,
            urlLogotipo: imagen
         });
       });*/
    };

    //Ciclo de vida

    useEffect( ()=>{
        if (!sesiones)
            history.push("/")
    },[])

    useEffect(() => {
        if (sesiones)
        {
            FetchConfiguracion();
            FetchObtenerFotosPromociones();
            Obtener();
            //document.title = sesiones.sesiones.nombreEmpresa;
        }
    }, []);


    //Funciones
    const ActivarDesactivarBoton = (tipo) => {
        setUsuario({ ...getUsuario, desactivarBoton: { error: tipo, valor: "" } });
    }
    const ftMensajeClose = () => {
        setMensaje({ ...mensaje, abrir: false });
    }
    const Login = (resp) => {
        //redux
        let rutaImagen = "";
        //let imagenes = cargaImagen ? cargaImagen : "cargando";
        let data = DecodificarToken(resp.iniciarSesion.token);
        if (data.url === null || data.url === '') {
            switch (data.tipo) {
                case 1:
                    rutaImagen = `${rutaPublica}${iconoDoctor} `;
                    break;
                case 2:
                    rutaImagen = `${rutaPublica}${iconoPaciente}`;
                    break;
                case 3:
                    rutaImagen = `${rutaPublica}${iconoAdmin} `;
                    break;
                case 4:
                    rutaImagen = `${rutaPublica}${iconoUsuario}`;
                    break;
            }
            agregaRutaPerfil({ ruta: rutaImagen });
        } else {
            let rutaServerS =  `${rutaPrivada}${data.url}`;
            archivoImg(rutaServerS).then(imagen => {
                agregaRutaPerfil({ ruta: imagen });
                return imagen;
            });
        }

        agregarSonido({ activo: data.sonidoNotificacion === 1 ? true : false });
        agregarUsuario({ user: { user: resp.iniciarSesion } });
        //TODO register
        socket.emit('register', data.usuarioId);
        //modal
        setMensaje({ ...mensaje, ...mensajeError, titulo: "Correcto", texto: resp.iniciarSesion.mensaje });
        history.push("/Menu")
    }
    const archivoImg = async function(ruta) { //get storage

        let resultado = '';
           resultado = await getImagen(ruta);
        return resultado;
    }

  /*  useEffect(() => {
        async function ftEffect() {
            try {
                let rutas1 = await rutas(subdominio);
                console.log('use', rutas1.rutaPublica);
                setCargaImagenes(rutas1);
            } catch({message}) {
                console.log(message);
            }
        } ftEffect();
    }, []);*/





    return (

        <Grid container component="main" className={classes.root} style={{ width: "100%", overflow: "hidden" }}>
            <CRMProvider value={[usuario, guardarUsuario]}>
                <CssBaseline />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} />

                <RegistroDesk open={open} cerrarRegistro={CerrarRegistro} />
                <RecupertarPassword open={abrirRecuperarPassword} cerrarRegistro={CerrarRecuperarPassword} />

                <Dialog open={openImg}>
                    <DialogTitle disableTypography style={{ textAlign: "Right" }}>
                        <IconButton aria-label="close" onClick={ftCloseImg} style={{ padding: 0 }}><CloseIcon /></IconButton>
                        <img src={vista} width={'100%'} />
                    </DialogTitle>
                </Dialog>

                <Grid item xs={false} sm={4} md={7}>
                    <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={true} transitionTime={1000}>
                        {generarSelect(photo)}
                    </Carousel>
                </Grid>

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar alt="Remy Sharp" src={stateImg.urlLogotipo} className={classes.large} style={{ minWidth: "110px", minHeight: "110px" }} />
                        <Typography component="h1" variant="h5">
                            {descripcionLogin}
                            {/* {sesiones ? sesiones.sesiones.nombreEmpresa : "No hay sesión aún..."} */}
                            {/* {sesiones ? "Kalenday" : "Cargando..."} */}
                        </Typography>
                        {GenerarNotificacionv2(getNotificacion, "w100")}
                        <form className={classes.form} noValidate>

                            <TextField id="username" label="Correo electrónico" name="email" margin="dense" onChange={EventChangeDatos} required fullWidth InputProps={userIcon(getUsuario.email.error)} color="primary" error={getUsuario.email.error} inputRef={input1Ref} onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    setInput2Focus()
                                }
                            }} />

                            <TextField id="password" inputRef={input2Ref} label="Contraseña" name="password" margin="dense" onChange={EventChangeDatos} type="password" required fullWidth InputProps={passwordIcon(getUsuario.password.error)} color="primary" error={getUsuario.password.error} onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                    EventAutenticarCliente()
                                }
                            }} />
                            <BotonBoostrap Texto={"ENTRAR"} Color="primary" EventoClick={EventAutenticarCliente} Desactivar={getUsuario.desactivarBoton.error}></BotonBoostrap>

                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <Typography className={classes.root} variant="overline" style={{ cursor: "pointer" }}>
                                        <Link onClick={AbrirRecuperarPassword}>
                                            Recuperar Contraseña
                                    </Link>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={{ textAlign: "center" }}>
                                    <ButtonGroup color="primary" aria-label="outlined primary button group">
                                        <Button onClick={AbrirRegistro}>Registrate</Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                            <Box mt={5}>

                            </Box>
                        </form>
                    </div>
                </Grid>
            </CRMProvider>
        </Grid>
    );
}

export default withRouter(IniciarSesionDesk);
