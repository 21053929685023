import React, { useState } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_SEGURIDAD, LISTADO_MODULOS } from '../../query';
import { ELIMINAR_SEGURIDAD, NUEVA_SEGURIDAD, MODIFICAR_SEGURIDAD } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/FormularioSeguridad';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';

function Servicios() {
    //#region queries
    const { loading, data, error, refetch } = useQuery(LISTADO_SEGURIDAD, {
        fetchPolicy: 'no-cache',
        variables: { limite: 100 },
        onError: (e) => {
            console.log(e.message)
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const listadoModulos = useQuery(LISTADO_MODULOS, {
        fetchPolicy: 'no-cache',
        variables: { limite: 100 },
        onError: e => console.log(e.message)
    });

    const [deleteSeguridad] = useMutation(ELIMINAR_SEGURIDAD, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminaron correctamente los permisos para el tipo de usuario "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setAux({ ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [addSeguridad] = useMutation(NUEVA_SEGURIDAD, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregaron correctamente los permisos a "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setAux({ ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modSeguridad] = useMutation(MODIFICAR_SEGURIDAD, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificaron correctamente los permisos del tipo de usuario "${seleccion.descripcion}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setAux({ ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region states
    const seleccionInicial = {
        seguridadId: null,
        descripcion: null,
        permisos: null,
        perfilId: null,
        tipoUsuarioId: null,
        todos: false
    };
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [aux, setAux] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    const [modificando, setModificando] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    //#endregion

    //#region constantes
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'seguridadId', label: 'seguridadId', minWidth: 0, hide: true },
            {
                id: 'descripcion', label: 'Tipo de usuario', minWidth: 100, align: 'left', textAlign: 'left', width: "10%", filtro: true
            },
            { id: 'perfilId', label: 'PerfilId', minWidth: 170, align: 'center', hide: true },
            {
                id: 'permisos', label: 'Permisos', minWidth: 150, width: "30%", maxWidth: 500, align: 'left', textAlign: 'left', filtro: true,
                format: (valor) => {
                    if (valor) {
                        let str = "";
                        valor = valor.split(",");

                        for (let i = 0; i < valor.length; i++) {
                            str += valor[i] + ", \r\n";
                        }
                        return str.substring(0, str.length - 4);
                    } else {
                        return "";
                    }
                }
            },
            { id: 'tipoUsuarioId', label: 'Tipo usuario', minWidth: 170, align: 'center', hide: true }
        ];
    const menu = [
        // { texto: "Agregar Seguridad", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar permisos", icono: <EditIcon />, onClick: () => handleModificarClick() },
        // { texto: "Eliminar seguridad", icono: <DeleteForever />, onClick: () => handleEliminarClick() }
    ];
    const camposFormulario = [
        {
            id: "tipoUsuarioId",
            texto: "Tipo de usuario *",
            valor: seleccion.tipoUsuarioId,
            tipo: "select",
            opciones: [
                {
                    label: "Doctor",
                    value: 1
                },
                {
                    label: "Paciente",
                    value: 2
                },
                {
                    label: "Administrador",
                    value: 3
                },
                {
                    label: "Usuario",
                    value: 4
                }
            ],
            xs: 12,
            otros: {
                disabled: modificando
            }
        },
        {
            id: "seguridadId",
            texto: "seguridadId",
            valor: seleccion.seguridadId,
            tipo: "hidden",
            xs: 12
        },
        {
            id: "todos",
            texto: "Seleccionar todo",
            valor: seleccion.todos,
            tipo: "checkbox",
            onChange: (e) => {
                let td = {};

                Object.keys(seleccion).map(k => {
                    if (seleccion[k] === false || seleccion[k] === true) {
                        td[k] = e.target.checked;
                    }
                })

                setSeleccion({ ...seleccion, ...td, todos: e.target.checked });
            },
            xs: 12
        }
    ];
    if (listadoModulos)
        if (listadoModulos.data) {
            listadoModulos.data.listadoModulos.Modulos.map(modulo => {
                camposFormulario.push({
                    id: modulo.permiso,
                    texto: modulo.modulo,
                    valor: seleccion[modulo.permiso] || false,
                    tipo: "checkbox",
                    xs: 4
                })
                seleccion[modulo.permiso] = seleccion[modulo.permiso] || false;
                return true;
            });
        } else {
            camposFormulario.push({
                id: "no",
                texto: "No se pudo obtener el listado de permisos",
                valor: "No se pudo obtener el listado de permisos",
                tipo: "label",
                xs: 12,
                propiedades: {
                    style: { margin: 20, fontSize: 18, color: 'rgb(107, 36, 21)' }
                },
                estilosGrid: {
                    marginTop: 20,
                    backgroundColor: 'rgb(253, 236, 234)',
                    borderRadius: 4
                }
            })
        }

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoSeguridad?.Seguridad;
    //#endregion

    //#region handlers
    function handleAgregarClick() {
        setModificando(false);
        setSeleccion({ ...seleccionInicial });
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        setModificando(true);
        setSeleccion({ ...aux });

        if (aux.seguridadId !== null) {
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar el tipo de usuario" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.seguridadId !== null) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar los permisos de "${seleccion.descripcion}"?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un elemento antes de eliminar" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion({ ...datos, ...ftMarcarPermisos(datos) });
            setAux({ ...datos, ...ftMarcarPermisos(datos) });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setAux(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setAux({...seleccionInicial });
        setFila(-1);
        setFiltro(texto)
    }

    function handlePaginaChange(texto) {
        setSeleccion({...seleccionInicial });
        setAux({...seleccionInicial });
        setFila(-1);
    }


    //#endregion

    //#region funciones
    async function ftAgregar(valores) {
        setDisabledAccept(true);

        let vtipoUsuarioId = valores.tipoUsuarioId;
        delete valores.todos;

        let permisos = Object.keys(valores).filter(key => {
            if (valores[key] === true)
                return key;
            else
                return "";
        }) + "";

        setSeleccion({ ...seleccionInicial, ...valores });

        let validacion = ftValidarObligatorios({ vtipoUsuarioId, permisos });
        if (validacion === "") {
            addSeguridad({
                variables: {
                    seguridad: {
                        tipoUsuarioId: vtipoUsuarioId,
                        permisos
                    }
                }
            });
        } else {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftModificar(valores, callback, dt) {
        setDisabledAccept(true);

        delete valores.tipoUsuarioId;
        delete valores.todos;
        delete valores.no;

        let permisos = Object.keys(valores).filter(key => {
            if (valores[key] === true)
                return key;
            else
                return "";
        }) + "";

        setSeleccion({ ...seleccion, ...valores });

        let validacion = ftValidarObligatorios({ vtipoUsuarioId: seleccion.tipoUsuarioId, perfilId: seleccion.perfilId, permisos });

        if (validacion === "") {
            modSeguridad({
                variables: {
                    seguridad: {
                        seguridadId: seleccion.seguridadId,
                        perfilId: seleccion.perfilId,
                        tipoUsuarioId: seleccion.tipoUsuarioId,
                        permisos
                    }
                }
            });
        } else {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion })
        }
    }

    function ftEliminar() {
        setDisabledAccept(true);
        deleteSeguridad({ variables: { id: Number(seleccion.seguridadId) } });
    }

    function ftValidarObligatorios(campos) {
        if (!campos.vtipoUsuarioId) return "Debe elegir un tipo de usuario";
        if (!campos.permisos) return "No ha seleccionado los permisos";
        return ftValidarFormato(campos);
    }

    function ftValidarFormato(campos) {
        if (campos.vtipoUsuarioId < 1) return "Debe seleccionar un tipo de usuario";
        if (campos.permisos.length < 1) return "No se han asignado los permisos";
        return "";
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftMarcarPermisos(datos) {
        let marcados = {};
        let permisos = datos.permisos || "";

        if (listadoModulos)
            if (listadoModulos.data)
                listadoModulos.data.listadoModulos.Modulos.map(modulo => {
                    marcados[modulo.permiso] = new RegExp(`^((.+,|^)${modulo.permiso}($|,.+))$`).test((permisos));
                });
        return marcados;
    }
    //#endregion

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    return (
        <MenuLateral titulo="Seguridad" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Seguridad" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} todo={seleccion.todo} />

            <Tabla titulo="Seguridad" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcion", "permisos"]} />

        </MenuLateral>
    );
}

export default Servicios;
