import React from 'react'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { makeStyles } from '@material-ui/core/styles';
import Elementos from './Elementos';
import { Tooltip } from '../../Core';



function Cuerpo({ datos = [], pagina, titleColumna="", filasPagina, columnas = [], onClick = () => { }, indice = -1, claseCelda, claseFila, hover = true, seleccionable = true }) {

    const useStyle = makeStyles({
        fila: {
            "&:hover": {
                backgroundColor: hover ? "#80b3ff !important" : "",
                color: hover ? "#000000 !important" : ""
            },
            "&:focus": {
                backgroundColor: seleccionable ? '#2869ff !important' : "",
                color: seleccionable ? "#ffffff !important" : ""
            },
        },
        root: {
            '&$selected': {
                backgroundColor: seleccionable ? '#2869ff !important' : "",
                color: seleccionable ? "#ffffff !important" : ""
            }
        },
        selected: {}
    });

    const useStyleColor = makeStyles({
        fila: {
            "&:hover": {
                backgroundColor: hover ? "#transparent  !important" : "",
                color: hover ? "#000000 !important" : ""
            },
            "&:focus": {
                backgroundColor: seleccionable ? '#transparent !important' : "",
                color: seleccionable ? "#ffffff !important" : ""
            },
        },
        root: {
            '&$selected': {
                backgroundColor: seleccionable ? '#transparent !important' : "",
                color: seleccionable ? "#ffffff !important" : ""
            }
        },
        selected: {}
    });

    const classes = useStyle();
    const classes2= useStyleColor();
    function ftClick(row, key) { onClick(row, key); }
    let seleccionada = false;
    let claseFi = { root: classes.root, selected: classes.selected };
    let claseFi2 = { root: classes2.root, selected: classes2.selected };
    let id = "";
    let aliasColor=columnas!=null&&columnas.length!=0&&columnas!=undefined?columnas[0].aliasColor?columnas[0].aliasColor:null:null;

    return (
        <TableBody>
            {datos.slice(pagina * filasPagina, pagina * filasPagina + filasPagina).map((row, key) => {
                seleccionada = seleccionable ? Number(indice) === key ? true : false : false;
                return (
                    <TableRow hover={hover} role="checkbox" tabIndex={-1} key={`${row[Object.keys(row)[0]]}_${key}`} onClick={() => ftClick(row, key)}
                    selected={seleccionada}
                     className={row[aliasColor]==0||row[aliasColor]==false?classes2.fila:classes.fila}
                     classes={row[aliasColor]==0||row[aliasColor]==false?claseFi2:claseFi} style={claseFila}
                     >


                        {columnas.map((column, llave) => {

                            if( column.obtenerColumna) {
                            id=row[column.obtenerColumna];
                            }

                            const value = row[column.id];
                            return (
                                <Tooltip title={titleColumna} key={llave}>

                                <TableCell key={llave} align={column.textAlign || 'center'} style={{ display: column.hide ? "none" : '', ...column.style, ...claseCelda,color:'inherit' }}
                                  className={
                                      row[aliasColor]===0||row[aliasColor]==false?`colorFila`
                                      :
                                      `colorNegro`
                                      }
                                      >


                                    {column.format ? column.format(value,id) : Elementos(column.component, value,id)}

                                </TableCell>

                                </Tooltip>
                            );
                        })}
                    </TableRow>
                );
            })}
        </TableBody>
    )
}

export default Cuerpo
