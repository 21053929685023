import React, { useEffect, useState } from 'react';
//*************Dependencias***********************
import Media from 'react-media';
//*************Componentes*********************
import IniciarSesionDesk from "./IniciarSesionDesk";
import IniciarSesionMovil from "./IniciarSesionMovil"
// Configuracion
import { queryConfiguracionInicial } from '../../Configuracion/Metodos';
//LocalStorage
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'


export default function Formulario() {

    const [configuracion, setConfiguracion] = useState({});


    useEffect(() => {
        async function ftEffect() {
            let sesiones = process.env.REACT_APP_DB;

            const config = await queryConfiguracionInicial({
                nombreDB: sesiones,
            });
            setConfiguracion({ ...config });
        } ftEffect();
    }, []);

    return (

        <Media queries={{ small: { maxWidth: 770 } }}>
          {matches =>
            matches.small ? (
             <IniciarSesionMovil configuracion={configuracion} />
            ) : (
                <IniciarSesionDesk configuracion={configuracion} />
            )
          }
        </Media>

    );
}
