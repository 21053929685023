import 'date-fns';
import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//material-ui Componentes
import { TextField, Grid, MenuItem, FormControlLabel, Radio, Checkbox, Hidden, Input, Chip, InputLabel, Select, FormControl } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        width: "100%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    fileContainer: {
        border: '1px solid grey',
        overflow: 'hidden',
        borderRadius: 15,
        width: '100%',
        maxHeight: '46px'
    },
    btnFile: {
        padding: '5px 0',
        background: '#0d47a1',
        cursor: 'pointer',
        height: '100%',
        '&:hover': {
            background: '#3173d4',
        }
    },
    fileUploader: {
        margin: '0',
        padding: '8px',
        background: '#0d47a1',
        color: '#fff',
        height: '100%',
        '&:hover': {
            color: '#fff',
            background: '#3173d4',
            cursor: 'pointer'
        }
    },
    fileName: {
        fontSize: 14,
        paddingLeft: 5
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        },
    },
};

function InputGrid({ xs = 12, tipo = "text", id, propiedades = { readOnly: false }, texto, margen = "normal", valor = "", handleChange, opciones = null, color = "primary", multiselect = false, disabled = false, otros, estilosGrid, ...otraProp }) {
    otros = { ...otros, ...otraProp };

    const classes = useStyles();
    const theme = useTheme();
    const [image, setImage] = React.useState(valor);

    let select = {}, componente;
    if (tipo === "select") select.select = true;

    //const [state, setState] = React.useState(valor);

    switch (tipo) {
        case "radio": {
            componente = <FormControlLabel
                value={valor}
                control={<Radio id={id} />}
                label={texto}
                {...otros}
            />;
            break;
        }
        case "checkbox": {
            let checked = valor === "" ? { checked: false } : { checked: valor }
            delete otros.helperText;
            delete otros.error;
            componente = <FormControlLabel
                control={
                    <Checkbox
                        id={id}
                        {...checked}
                        onChange={handleChange}
                        color={color}
                        disabled={disabled}
                    />
                }
                label={texto}
                {...otros}
            />;
            break;
        }
        case "hidden": {
            componente = <Hidden xsDown smDown mdDown lgDown xlDown>
                <TextField
                    id={id}
                    type={tipo}
                    name={id}
                    label={texto}
                    value={valor}
                    {...otros}
                >
                    {opciones ? opciones.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                        >
                            {option.label}
                        </MenuItem>
                    )) : ""}
                </TextField>
            </Hidden>;
            break;
        }
        case "textarea": {
            componente = <TextField
                id={id}
                label={texto}
                multiline
                rows="4"
                fullWidth
                inputProps={propiedades}
                defaultValue={valor !== undefined ? valor : ""}
                onChange={handleChange}
                margin={margen}
                style={{ paddingRight: 5 }}
                disabled={disabled}
            />;
            break;
        }
        case "autocomplete": {
            componente = <Autocomplete {...propiedades} id={id} autoHighlight onChange={handleChange} value={valor === -1 ? null : valor} renderInput={params => (
                <TextField {...params} label={texto} margin="normal" fullWidth {...otros} />
            )} />
            break;
        }
        case "label": {
            componente = <p id={id} {...propiedades}>{valor}</p>
            break;
        }
        case "superFile": {
            componente =
                <Grid container item xs={12} direction="row" alignItems="center" className={classes.fileContainer}>

                    <Grid container justify="center" item xs={3} className={classes.btnFile}>
                        <label for={id} className={classes.fileUploader}> Subir archivo </label>
                        <input id={id} onChange={(e) => { handleChange && handleChange(e); setImage(e.target?.files[0]?.name) }} type="file" style={{ display: 'none' }} accept="image/*" />
                    </Grid>

                    <Grid item xs={8} className={classes.fileName}><div id="info">{image}</div></Grid>
                </Grid>

            //     (<TextField
            //     id={id}
            //     type={tipo}
            //     name={id}
            //     label={texto}
            //     margin={margen}
            //     defaultValue={valor === null ? "" : valor}
            //     inputProps={propiedades}
            //     onChange={handleChange}
            //     fullWidth {...select}
            //     style={{ paddingRight: 5 }}
            //     disabled={disabled}
            //     {...otros}
            // >
            //     {opciones && Array.isArray(opciones) ? opciones.map(option => (
            //         <MenuItem key={option.value} value={option.value} style={{ color: option.color ? option.color : "#212" }}>
            //             {option.label}
            //         </MenuItem>
            //     )) : opciones ? <MenuItem key={opciones} value={opciones}>No hay opciones</MenuItem> : ""}
            // </TextField>);
            break;
        }
        default:
            componente = multiselect ? (<FormControl className={classes.formControl}>
                <InputLabel id={`labelmulti-${id}`}>{texto}</InputLabel>
                <Select
                    labelId={`labelmulti-${id}`}
                    id={id}
                    multiple
                    value={Array.isArray(valor) ? valor : []}
                    onChange={handleChange}
                    input={<Input id={`sel-mul-chip-${id}`} />}
                    disabled={disabled}
                    renderValue={selected => {
                        if (selected.map)
                            return <div className={classes.chips}>
                                {selected.map(value => {
                                    let chip = null;
                                    opciones.forEach(({ value: valor, label }) => {
                                        if (value === valor) chip = label;
                                    });
                                    return (
                                        <Chip key={chip} label={chip} className={classes.chip} />
                                    )
                                })}
                            </div>
                    }}
                    fullWidth
                    style={{ paddingRight: 5 }}
                    {...otros}
                    MenuProps={MenuProps}
                >
                    {opciones && Array.isArray(opciones) ? opciones.map(option => (
                        <MenuItem
                            key={option.value}
                            value={option.value}
                            style={{ fontWeight: theme.typography.fontWeightRegular, color: option.color ? option.color : "#212" }}
                        >
                            {option.label}
                        </MenuItem>
                    ))
                        : <MenuItem key={opciones} value={opciones}>
                            No hay opciones
                    </MenuItem>}
                </Select>
            </FormControl>)
                :
                (<TextField
                    id={id}
                    type={tipo}
                    name={id}
                    label={texto}
                    margin={margen}
                    defaultValue={valor === null ? "" : valor}
                    inputProps={propiedades}
                    onChange={handleChange}
                    fullWidth {...select}
                    style={{ paddingRight: 5 }}
                    disabled={disabled}
                    {...otros}
                >
                    {opciones && Array.isArray(opciones) ? opciones.map(option => (
                        <MenuItem key={option.value} value={option.value} style={{ color: option.color ? option.color : "#212" }}>
                            {option.label}
                        </MenuItem>
                    )) : opciones ? <MenuItem key={opciones} value={opciones}>No hay opciones</MenuItem> : ""}
                </TextField>);
            break;
    }

    return (
        <Grid item xs={xs} style={estilosGrid || { textAlign: "left" }}>
            {componente}
        </Grid>
    )
}

export default InputGrid
