import { AGREGAR_ESPECIALIDAD } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { NUEVA_ESPECIALIDAD_DOCTOR, MODIFICAR_ESPECIALIDAD_DOCTOR } from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(categoria, actualiza, datos, ActivarDesactivarBoton, ftClose) {
    return async (dispatch) => {

        if (categoria === 0) {
            FetchGrahpQL({
                query: NUEVA_ESPECIALIDAD_DOCTOR,
                variables: {
                    descripcion: datos.descripcion.valor,
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se agregó  correctamente la especialidad ${resp.nuevaEspecialidadDoctor.descripcion}`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        } else {
            FetchGrahpQL({
                query: MODIFICAR_ESPECIALIDAD_DOCTOR,
                variables: {
                    especialidadId: datos.especialidadId.valor,
                    descripcion: datos.descripcion.valor
                }
            })
                .then(resp => {
                    Swal.fire("¡Éxito!", `Se modificó  la especialidad ${resp.modificarEspecialidadesDoctor.descripcion}`, "success");
                    ActivarDesactivarBoton(false);
                    actualiza();
                    ftClose();
                })
                .catch((err) => {
                    if (err.message === "Failed to fetch") {
                        Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                    } else {
                        Swal.fire("¡Aviso!", err.message, "warning");
                    }
                    ActivarDesactivarBoton(false);
                });
        }
        dispatch(AgregarEspecialidad({ activo: true }))
    }
}


export const AgregarEspecialidad = usuariosCrud => {
    return {
        type: AGREGAR_ESPECIALIDAD,
        payload: usuariosCrud
    }
}




