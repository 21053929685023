import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [asignacion, guardarAsignacion] = useState({
        Anamnesis: {
            pacienteId: { error: false, valor: ""},
            anamnesisId: { error: false, valor: "" },
            nombreCompleto: { error: false, valor: "" },
            genero: { error: false, valor: "" },
            padecimientos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            telefono: { error: false, valor: "" },
            fechaNacimiento: { error: false, valor: "" },
            fechaAlta: { error: false, valor: "" },
            desactivarBoton:{error: false, valor: ""},
            descripcion: { error: false, valor: "" },
            padecimientoId: { error: false, valor: "" },
            descripcionId:{error:false,valor:""},
        }
    });
    return (
        <CRMContext.Provider value={[asignacion, guardarAsignacion]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
