import { createMuiTheme } from "@material-ui/core/styles";
export const   Theme = createMuiTheme({
    palette: {
        primary: {
            light: '#2699FB',
            main: '#2699FB',
            dark: '#2699FB',
            contrastText: '#fff',
          },
        secondary: {
            light: '#ff7961',
            main: '#f44336',
            dark: '#ba000d',
            contrastText: '#fff',
        }
    },
});
