import React from 'react';
//Iconos
import { AddIcon, EditIcon, DeleteForever} from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    especialidadId: null,
    descripcion: null,
};
//seleccion para columnas
const columnas = [
    { id: 'especialidadId', label: 'ID ', minWidth: 0, hide: false },
    { id: 'descripcion', label: 'Descripcion', minWidth: 170, align: 'center', filtro: true },
];
//menu lateral
const menu = (handleAgregarClick, handleModificarClick, handleEliminarClick) => [
    { texto: "Agregar especialidad", icono: <AddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar especialidad", icono: <EditIcon />, onClick: () => handleModificarClick() },
    { texto: "Eliminar especialidad", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
];

/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    especialidadId: null,
    descripcion: null,
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
  };


export { columnas, seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,Style };
