import React from 'react';
import { List, ListItem, ListItemText, Divider } from '../../componentesGenericos/Core';
import { AccountCircleIcon, MeetingRoomIcon } from '../../componentesGenericos/Icons';
import Contenedor from '../../componentesGenericos/MenuAnclado';
//Router
import { withRouter } from "react-router-dom"
//Componentes
import Badge from '@material-ui/core/Badge';
//****REDUX***********/
import { useSelector, useDispatch } from "react-redux";
import { IniciarSesion } from "../../Redux/Actions/SesionAction";
import { ObtenerMenu } from "../../Redux/Actions/MenuAction";
import { makeStyles } from '@material-ui/core/styles';

import { limpiarMenu } from '../MenuPrincipal/helper';
import { DecodificarToken } from '../../componentesGenericos/Estructura/Autenticar';
//Socket
import { cerrarSessionServidor } from '../../sockets';

//Estilos
export const estilos = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        color: '#0d67e1'
    },
    item: {
        '&:hover': {
            backgroundColor: '#f0f0f0'
        }
    },
    avatar: {
        minWidth: '30px'
    },
    verTodo: {
        backgroundColor: '#eaeaea',
        padding: '2px 16px',
        cursor: 'pointer'
    }
}));

function Notificaciones({ id, abrir, Anclaje, onClose, history }) {
    //ESTILOS
    const classes = estilos();
    //REDUX
    const dispatch = useDispatch();
    const agregarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const setMenu = (datos) => dispatch(ObtenerMenu(datos));
    const menuSeleccion = useSelector(state => state.menu);

    function allStorage() {

        let values = [],
            keys = Object.keys(localStorage),
            i = keys.length;

        while ( i-- ) {
            if (keys[i] !== "kalendaySesiones")
            {
                localStorage.removeItem(keys[i]);
            }
        }

        return values;
    }

    //EVENTOS
    const Logout = () => {
        // cerrarSessionServidor(userToken);
        let user = null;
        try {
            user = localStorage.getItem('user');

            if (user) {
                let usuario = JSON.parse(user);
                let userToken = DecodificarToken(usuario.user.usuario[0].user.user.token);
                cerrarSessionServidor(userToken.usuarioId);
            }
        } catch (e) {
            console.log(e.message)
        }

        //localStorage.clear();
        allStorage();
        agregarUsuario({ user: { user: { success: false, token: "" } } });
        setMenu(limpiarMenu(menuSeleccion));
        history.push("/"); // history.push("/") es el que funciona actualmente... history.push("/login") provoca el error de dato undefined por el localstorage limpio
    }
    const handleClick = (opcion) => {
        history.push(opcion);
    }

    return (
        <Badge color="error">
            <Contenedor id={id} abrir={abrir} anclaje={Anclaje} onClose={onClose}>
                <List className={classes.root} >
                    <ListItem key={"sinNot"} onClick={(e) => { setMenu(limpiarMenu(menuSeleccion)); handleClick("/perfil") }} style={{ cursor: 'pointer' }} >
                        <AccountCircleIcon />
                        <ListItemText primary="Ver perfil" />
                    </ListItem>
                    <Divider />
                    <ListItem key={"sinNot2"} >
                        <MeetingRoomIcon />
                        <ListItemText primary="Cerrar sesión" onClick={() => Logout()} style={{ cursor: 'pointer' }} />
                    </ListItem>
                </List>
            </Contenedor>
        </Badge>
    )
}

export default withRouter(Notificaciones);
