import gql from 'graphql-tag';

//#region Garantias
//============Garantias==================
export const NUEVA_ENCABEZADO = gql`
	mutation NuevoEncabezado($encabezado: EncabezadoInput!){
    nuevoEncabezado(input:$encabezado){
    encabezadoId
    clinicaId
    nombreClinica
    almacenId
    nombreAlmacen
    ccostoId
    nombreCcosto
  }
}
`;

export const MODIFICAR_ENCABEZADO = gql`
	mutation ModificarEncabezado($encabezado: EncabezadoInput!){
    modificarEncabezado(input:$encabezado){
    encabezadoId
    clinicaId
    nombreClinica
    almacenId
    nombreAlmacen
    ccostoId
    nombreCcosto
  }
}
`;

export const ELIMINAR_ENCABEZADO = gql`
	mutation EliminarEncabezado($id: Int!){
  eliminarEncabezado(id: $id)
}
`;

//#endregion
//==========================================
