import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {LensIcon} from '../../componentesGenericos/Icons';

export const Colores = [
    {
        value: 0,
        label: "Seleccionar el color"
    }, {
        color: "#f44336",
        hover: "#ef9a9a",
        value: "#f44336",
        label: "Rojo"
    }, {
        color: "#e91e63",
        hover: "#f48fb1",
        value: "#e91e63",
        label: "Rosa"
    }, {
        color: "#9c27b0",
        hover: "#ce93d8",
        value: "#9c27b0",
        label: "Morado"
    }, {
        color: "#673ab7",
        hover: "#b39ddb",
        value: "#673ab7",
        label: "Morado Oscuro"
    }, {
        color: "#3f51b5",
        hover: "#9fa8da",
        value: "#3f51b5",
        label: "Índigo"
    }, {
        color: "#2196f3",
        hover: "#90caf9",
        value: "#2196f3",
        label: "Azul"
    }, {
        color: "#03a9f4",
        hover: "#81d4fa",
        value: "#03a9f4",
        label: "Azul Claro"
    }, {
        color: "#00bcd4",
        hover: "#80deea",
        value: "#00bcd4",
        label: "Cian"
    }, {
        color: "#009688",
        hover: "#80cbc4",
        value: "#009688",
        label: "Verde Azulado"
    }, {
        color: "#4caf50",
        hover: "#a5d6a7",
        value: "#4caf50",
        label: "Verde"
    }, {
        color: "#8bc34a",
        hover: "#c5e1a5",
        value: "#8bc34a",
        label: "Verde Claro"
    }, {
        color: "#cddc39",
        hover: "#e6ee9c",
        value: "#cddc39",
        label: "Lima"
    }, {
        color: "#ffeb3b",
        hover: "#fff59d",
        value: "#ffeb3b",
        label: "Amarillo"
    }, {
        color: "#ffc107",
        hover: "#ffe082",
        value: "#ffc107",
        label: "Ámbar"
    }, {
        color: "#ff9800",
        hover: "#ffcc80",
        value: "#ff9800",
        label: "Naranja"
    }, {
        color: "#ff5722",
        hover: "#ffab91",
        value: "#ff5722",
        label: "Naranja Oscuro"
    }
];

export function CuadroColor({color}) {

    const useStyles = makeStyles(theme => ({
        primary: { color }
    }));

    const classes = useStyles();

    let componente = color ? <LensIcon className={classes.primary} /> : "";

    return(componente);

}
