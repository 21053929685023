import React, { useState, useEffect, Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Grid } from '../../../componentesGenericos/Core';

import BusinessIcon from '@material-ui/icons/Business';


export default function Servicios({xs = 12, clinica = {}}) {

	const {
        clinicaId,
        nombreClinica,
        especialidades
    } = clinica;

    const componente = <Grid item xs={12} sm={12} md={xs}>
			<Card raised={true}>
				<Card style={{backgroundColor: '#EBEEF0'}}>
                   <br/>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                    <BusinessIcon />
                        </Typography>
                    <Typography gutterBottom variant="h5" component="h2" align="center">
                       {nombreClinica}
                    </Typography>
				</Card>
                 <Divider />
				<CardContent>
                    <Grid container spacing={2}>
                        {
                            !especialidades ? "" : <Grid item xs={12} sm={12} md={12} style={{ textAlign: "left" }}>
                                    {/* <ul> */}
                                        {  especialidades.map((especialidad,index)=>(
                                              <Typography gutterBottom key={index} variant="body2" color="textPrimary" component="ul" align="left">
                                                    <Typography gutterBottom variant="h6" component="h3" color="textPrimary" align="left">
                                                    {especialidad.descripcionEspecialidad}
                                              </Typography>
                                                <ul>
                                                    {
                                                        especialidad.servicios.map(({descripcion},index)=>(
                                                            <Typography key={index} variant="body2" color="textPrimary" component="li" align="left">
                                                                {descripcion}
                                                            </Typography>
                                                        ))
                                                    }
                                                </ul>
                                    </Typography>
                                            ))
                                    }
                                    {/* </ul> */}
                            </Grid>
                        }
                    </Grid>
                </CardContent>
			</Card>
		</Grid>;

    return (<>{ componente }</>);

}
