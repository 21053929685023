import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Fetch Graphql
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';

//Queries
import { LISTADO_PRODUCTOS_SERVICIOS, LISTADO_GARANTIAS_DESCRIPCION, LISTADO_ESPECIALIDAD_SERVICIO, LISTADO_TIPO_IVA, LISTADO_TASA_IVA, LISTADO_TASA_IVA2, SELECT_CLINICAS_FETCH } from '../../../query';
import { ELIMINAR_PRODUCTO_SERVICIO, NUEVO_PRODUCTO_SERVICIO, MODIFICAR_PRODUCTO_SERVICIO } from '../../../mutation';
//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';

//constantes
import { mensajeConfirmacion, mensajeError, mensajeExito, mensajeInicial } from '../../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever, StyleIcon } from '../../../componentesGenericos/Icons';
//Formatear select

import { useSelector } from 'react-redux';
import { ftSelectFormat, ftAnioNoValido, ftAniosRangosValidosAlterno } from '../../../Helpers';
//****ROUTER***********/
import { withRouter } from "react-router-dom";
//Expresiones regulares
import { rgxNoVacio, rgxPorcentaje, rgxDecimales, rgxAlmenosUnaLetra } from '../../../Helpers/constantes/Rgx';
import { cuatroCaracteres } from '../../../Helpers/constantes/formatos';

function ProductosServicios(props) {
    let { history } = props;
    //#region redux
    const user = useSelector((state) => state.selecciondeClinica);
    let clinicaId = user.seleccionClinica[0].clinicaId;

    const configuracion = useSelector(state => state.configuracion);
    const { precioConIVA, porcentajeDescuento: porcDescBlock, tasaIVA: tasaIvaBlock, serviciosInventariables, maxPorcentajeDescto } = configuracion;
    //#endregion

    //#region queries & Mutations

    let { loading, error, data, refetch } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        variables: { offset: 0, tipo: parseInt(props.tipo), clinica: Number(clinicaId), disponible: null },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () => {
            setErrorState(false)
            setFila(-1)
            setSeleccion(seleccionInicial)
        }
    });


    const { data: data3 } = useQuery(LISTADO_ESPECIALIDAD_SERVICIO, {
        variables: { offset: 0 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
        }
    });



    const { data: tipoIva } = useQuery(LISTADO_TIPO_IVA, {
        variables: { offset: 0 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
        }
    });

    const { data: tasaIva } = useQuery(LISTADO_TASA_IVA, {
        variables: { offset: 0 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
        }
    });

    const [addProductoServicio] = useMutation(NUEVO_PRODUCTO_SERVICIO, {
        onError: (e) => {
            setActualizar(true);

            throw new Error(e.message.replace("GraphQL error:", ""))
        },
        onCompleted: (e) => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el registro` });

            setSeleccion({ ...seleccionInicial, servicioId: -1 });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modProductoServicio] = useMutation(MODIFICAR_PRODUCTO_SERVICIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""));
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el registro` });

            setSeleccion({ ...seleccionInicial, servicioId: -1, clinicaId: [] });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteProductoServicio] = useMutation(ELIMINAR_PRODUCTO_SERVICIO, {
        onError: (e) => {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: e.message.replace("GraphQL error:", "")
            });
        },
        onCompleted: (e) => {
            setMensaje({
                ...mensaje,
                ...mensajeExito,
                texto: e.eliminarProductoServicio
            });

            setSeleccion({
                ...seleccion,
                ...seleccionInicial
            });

            setFila(-1);
            setActualizar(true);
        }
    })

    //#endregion

    //#region states
    const seleccionInicial = {
        garantiaId: -1,
        servicioId: null,
        descripcion: null,
        codigoProducto: '',
        codigoBarras: '',
        precioUnitario: 0,
        iva: 0,
        precioTotal: 0,
        porcentajeDescuento: 0,
        descuentoTotal: 0,
        tipoIva: 1,
        descripcionTipoIva: null,
        tasaIva: 1,
        descripcionTasaIva: null,
        porcentajeIva: null,
        NoAplicaIva: false,
        comision: null,
        agendable: 0,
        tipo: null,
        relacionId: null,
        garantia: null,
        especialidadId: -1,
        descripcionEspecialidad: null,
        inventariable: (props.tipo == 2 || props.tipo == 3) ? 1 : 0,
        disponible: 0,
        fechaInicialDescuento: '',
        fechaFinalDescuento: '',
        vigenciaDescuento: null,
        clinicaId: [],
        nombreClinica: null,
        precioUnitarioDescuento: null,
        ivaDescuento: null,
        precioTotalDescuento: null,
        venderSinExistencia: false
    }

    const [auxAgregar, setAuxAgregar] = useState([]);
    const [clinicasDB, setClinicaDB] = useState([]);
    const [tituloProp, setTituloProp] = useState([]);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [tasaIvaDB, setTasaIvaDB] = useState([]);
    const [garantiasDB, setGarantiasDB] = useState([]);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [errorState, setErrorState] = useState(false);
    const [stateCopy, setStateCopy] = useState({});

    const garantiaRef = React.useRef();
    const especialiadRef = React.useRef();
    //#endregion

    //#region constantes

    const columnas = errorState ?
        [{
            id: 'msj', label: '', format: (valor) => {
                return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
            }
        }] :
        [
            { id: 'servicioId', label: 'servicio ID', minWidth: 0, hide: true, aliasColor: "disponible" },
            { id: 'descripcion', label: 'Descripción', minWidth: 100, align: 'left', filtro: true, textAlign: 'left' },
            {
                id: 'codigoProducto', label: `Código del ${props.titulo2}`, minWidth: 100, align: 'left', filtro: true, textAlign: 'left',
                format: (valor) => {
                    return valor ? valor.toUpperCase() : ""
                }
            },
            {
                id: 'codigoBarras', label: 'Código de barras', minWidth: 80, align: 'left', filtro: true, textAlign: 'left',
                format: (valor) => {
                    return valor ? valor.toUpperCase() : ""
                }
            },
            //     { id: 'precioUnitario', label: 'Precio unitario', minWidth: 170, align: 'center' ,
            //     format: (valor) =>
            //     {
            //         return Number(valor).toFixed(6);
            //     }
            // },
            // {
            //     id:'NoAplicaIva', label: 'No aplica IVA', minWidth: 170, align: 'center' ,  component: 'Checkbox'
            // },
            // {
            //     id:'descripcionTipoIva', label: 'Tipo IVA', minWidth: 170, align: 'center' ,
            // },
            // {
            //     id:'descripcionTasaIva', label: 'Tasa IVA', minWidth: 170, align: 'center' ,
            // },
            //     { id: 'iva', label: 'IVA', minWidth: 170, align: 'center' ,
            //     format: (valor) =>
            //     {
            //         return Number(valor).toFixed(6);
            //     }
            // },
            {
                id: 'precioTotal', label: 'Precio total', minWidth: 120, align: 'right', textAlign: 'right',
                format: (valor) => {
                    return Number(valor).toFixed(2);
                }
            },
            //     { id: 'precioUnitarioDescuento', label: 'Precio unitario con descuento', minWidth: 170, align: 'center' ,
            //     format: (valor) =>
            //     {
            //         return Number(valor).toFixed(6);
            //     }
            // },
            //     { id: 'ivaDescuento', label: 'IVA con descuento', minWidth: 170, align: 'center' ,
            //     format: (valor) =>
            //     {
            //         return Number(valor).toFixed(6);
            //     }
            // },
            {
                id: 'precioTotalDescuento', label: 'Precio total con descuento', minWidth: 120, align: 'right', textAlign: 'right',
                format: (valor) => {
                    return Number(valor).toFixed(2);
                }
            },
            {
                id: 'descripcionEspecialidad', label: 'Grupo', minWidth: 120, align: 'left', textAlign: 'left',
                format: (fila) => {
                    return fila ? fila.toString().replace("-1", "Sin grupo").replace(seleccion.especialidadId, seleccion.descripcionEspecialidad) : "Sin grupo";
                },
                filtro: true
            },
            {
                id: 'garantia', label: 'Garantia', minWidth: 120, align: 'left', textAlign: 'left',
                format: (fila) => {
                    return fila ? fila.toString().replace("-1", "Sin garantía").replace(seleccion.garantiaId, seleccion.garantia) : "Sin garantía";
                }
            },
            {
                id: 'agendable', label: 'Agendable', minWidth: 50, align: 'center', component: "Checkbox", hide: (Number(props.tipo) === 2 || Number(props.tipo) === 3) ? true : false,
            },
            {
                id: 'inventariable', label: 'Inventariable', minWidth: 50, align: 'center', component: "Checkbox",
            },
            {
                id: 'disponible', label: 'Disponible', minWidth: 50, align: 'center', component: "Checkbox",
            },
            { id: 'tipo', label: 'tipo', minWidth: 100, align: 'center', hide: true },
            {
                id: 'garantiaId', label: 'garantiaId', minWidth: 170, align: 'center', hide: true,
            },
            ,
            {
                id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left',
            },
        ];


    const menu = [
        {
            texto: `Agregar ${props.titulo}`,
            icono: <AddIcon />,
            onClick: () => handleAgregarClick()
        },
        {
            texto: `Modificar ${props.titulo}`,
            icono: <EditIcon />,
            onClick: () => handleModificarClick()
        },
        {
            texto: `Eliminar ${props.titulo}`,
            icono: <DeleteForever />,
            onClick: () => handleEliminarClick()
        },
        {
            texto: props.tipo == 1 ? `Desplazarse hacia asignar servicios a convenios` : "",
            icono: props.tipo == 1 ? <StyleIcon /> : "",
            onClick: props.tipo == 1 ? (e) => redirige("/convenioservicios") : ""
        }
    ];



    const queryTasaIva = () => {
        async function queryTasaIva() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTADO_TASA_IVA2,
                    variables: { offset: 0 }
                });

                let { listadoTasasIva: { tasasIva } } = resultado;

                return ftSelectFormat(tasasIva, {
                    id: "tasaId",
                    descripcion: "descripcion",
                    otro: "porcentajeIva"
                }, "Seleccionar tasa iva");
            } catch ({ message: label }) { return [{ value: 0, label }]; }
        }
        return queryTasaIva();
    }

    const queryGarantias = () => {
        async function queryGarantias() {
            try {
                let resultado = await FetchGrahpQL({
                    query: LISTADO_GARANTIAS_DESCRIPCION,
                    variables: { off: 0, limit: 1000000 }
                });

                let { listadoGarantiasDescripcion: { garantias } } = resultado;

                return ftSelectFormat(garantias, {
                    id: "garantiaId",
                    descripcion: "garantia"
                }, "");
            } catch ({ message: label }) { return [{ value: 0, label }]; }
        }
        return queryGarantias();
    }

    const queryClinicas = () => {
        async function queryClinicas() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_FETCH,
                    variables: {
                        limite: 10000,
                        activas: true
                    }
                });

                let { listadoClinicas: { clinicas } } = resultado;

                setSeleccion({
                    ...seleccion,
                    ...seleccionInicial
                });

                return ftSelectFormat(clinicas, {
                    id: "clinicaId",
                    descripcion: "nombre",
                }, "");
            } catch ({ message: label }) { return [{ value: 0, label }]; }
        }
        return queryClinicas();
    }

    let opcionesEspecialidades = data3 ? data3.listadoEspecialidadServicio.especialidadesServicios.map(valor => ({
        label: valor.descripcion,
        value: valor.especialidadId
    })) : null

    //añadir opción "sin grupo" al select de especialidad
    let auxOpcionesEspecialidad = opcionesEspecialidades ? opcionesEspecialidades.unshift({ label: "Sin grupo", value: "-1" }) : null

    let opcionesTipoIva = tipoIva ? tipoIva.listadoTiposIva.tiposIva.map(valor => ({
        label: valor.descripcion,
        value: valor.tipoId,
        disabled: valor.descripcion.toLowerCase() === "cuota" ? true : false
    })) : null

    let opcionesTasaIva = tasaIva ? tasaIva.listadoTasasIva.tasasIva.map(valor => ({
        label: valor.descripcion,
        value: valor.tasaId,
        porcentaje: valor.porcentajeIva,
        disabled: (valor.tasaId != tasaIvaBlock && valor.tasaId != 1) ? true : false
    })) : null


    const opcYesNot = [
        {
            label: "NO",
            value: 0
        },
        {
            label: "SI",
            value: 1
        }
    ];

    const camposFormulario = [
        {
            id: "servicioId",
            texto: "servicioId",
            valor: seleccion.servicioId,
            tipo: "hidden"
        },
        {
            id: "clinicaId",
            texto: "Asignar a sucursal *",
            valor: seleccion.clinicaId,
            tipo: "select",
            opciones: clinicasDB,
            multiselect: Number(auxAgregar) === 1 ? true : false,
            xs: 12,
            // ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleSelect: { maxWidth: '480px', whiteSpace: 'break-spaces' },
        },
        {
            id: "descripcion",
            texto: "Descripción *",
            valor: seleccion.descripcion,
            tipo: "text",
            xs: 12,
            ayuda: 'Campo obligatorio (Incluya 1 letra, al menos 4 caracteres)',
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres
        },
        {
            id: "codigoProducto",
            texto: `Código de ${props.titulo2}`,
            valor: seleccion.codigoProducto,
            tipo: "text",
            xs: 6,
            onChange: handleUpperCaseCP
            // ayuda: `Ingrese un código para el ${props.titulo2}`,
            // regexp: rgxLetras
            //pendiente debido a que no son obligatorios (por ahora)
        },
        {
            id: "codigoBarras",
            texto: "Código de barras",
            valor: seleccion.codigoBarras,
            tipo: "text",
            xs: 6,
            onChange: handleUpperCaseCB
            // ayuda: `Ingrese un código de barras para el ${props.titulo2}`,
            // regexp: rgxLetras
            //pendiente debido a que no son obligatorios (por ahora)
        },
        {
            id: "tipoIva",
            texto: "Tipo IVA *",
            valor: seleccion.tipoIva,
            tipo: seleccion.NoAplicaIva == true ? "hidden" : "select",
            opciones: opcionesTipoIva,
            xs: 6,
            onChange: handleTipoIvaChange,
            cleanDatoState: seleccion.tipoIva,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            // disabled: true
        },
        {
            id: "tasaIva",
            texto: "Tasa IVA *",
            valor: seleccion.tasaIva,
            tipo: seleccion.NoAplicaIva == true ? "hidden" : "select",
            opciones: opcionesTasaIva,
            xs: 6,
            propiedades:
            {
                readOnly: seleccion.tipoIva != 2 ? true : false,
            },
            onChange: handleTasaIvaChange,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio
        },
        {
            id: "NoAplicaIva",
            texto: "No aplica IVA",
            valor: seleccion.NoAplicaIva,
            tipo: "checkbox",
            onChange: handleNoAplicaChange,
            xs: 6
        },
        {
            id: "venderSinExistencia",
            texto: "Vender sin existencia",
            valor: seleccion.venderSinExistencia,
            tipo: seleccion.inventariable == 1 ? "checkbox" : "hidden",
            onChange: handleVentaSinExistenciaChange,
            xs: 6
        },
        {
            id: "precioUnitario",
            texto: "Precio unitario (sin IVA) *",
            valor: seleccion.precioUnitario,
            tipo: "number",
            xs: 6,
            propiedades:
            {
                readOnly: true,
            },
            otros: {
                inputProps:
                {
                    min: 0,
                    readOnly: precioConIVA ? true : false,
                },
                InputProps: {
                    startAdornment: "$",
                },
                onClick: (evt) => { seleccionTexto(evt) }
            },
            onChange: handlePrecioUnitarioChange,
            ayuda: "Precio no valido",
            regexp: rgxDecimales
        },
        {
            id: "iva",
            texto: "IVA *",
            valor: seleccion.iva,
            tipo: "number",
            xs: 6,
            otros: {
                inputProps:
                {
                    readOnly: true
                },
                variant: "filled",
                size: "small",
            }
        },
        {
            id: "porcentajeIva",
            texto: "IVA *",
            valor: seleccion.porcentajeIva,
            tipo: "hidden"
        },
        {
            id: "precioTotal",
            texto: "Precio total *",
            valor: seleccion.precioTotal,
            tipo: "number",
            xs: 6,
            otros: {
                inputProps:
                {
                    min: 0,
                    readOnly: precioConIVA ? false : true,
                },
                InputProps: {
                    startAdornment: "$",
                },
                onClick: (evt) => { seleccionTexto(evt) }

            },
            onChange: handlePrecioTotalSinDescChange,
            ayuda: "Precio no valido",
            regexp: rgxDecimales
        },
        {
            id: "porcentajeDescuento",
            texto: "Porcentaje de descuento ",
            valor: seleccion.porcentajeDescuento,
            tipo: "number",
            xs: 6,
            onChange: handlePorcDescuento,
            otros: {
                inputProps:
                {
                    min: 0,
                    max: Number(maxPorcentajeDescto) * 100,
                    readOnly: (porcDescBlock && (seleccion.precioUnitario > 0 || seleccion.precioTotal > 0)) ? false : true
                },
                InputProps: {
                    endAdornment: "%",
                },
                onClick: (evt) => { seleccionTexto(evt) }

            },
            ayuda: "El porcentaje debe ser entre 0 y 100",
            regexp: rgxPorcentaje
        },
        {
            id: "descuentoTotal",
            texto: "Descuento de precio unitario",
            valor: seleccion.descuentoTotal,
            tipo: "number",
            xs: 6,
            otros: {
                inputProps:
                {
                    min: 0,
                    readOnly: (porcDescBlock || (seleccion.precioUnitario < 0 || seleccion.precioTotal < 0)) ? true : false
                },
                InputProps: {
                    startAdornment: "$",
                },
                onClick: (evt) => { seleccionTexto(evt) }

            },
            onChange: handleImporteDescuento
        },
        {
            id: "precioUnitarioDescuento",
            texto: "Precio unitario con descuento",
            valor: seleccion.precioUnitarioDescuento,
            tipo: (seleccion.descuentoTotal > 0 || seleccion.porcentajeDescuento > 0) ? "number" : "hidden",
            xs: 6,
            otros: {
                inputProps:
                {
                    readOnly: true
                },
                variant: "filled",
                size: "small",
            }
        },
        {
            id: "ivaDescuento",
            texto: "IVA descuento",
            valor: seleccion.ivaDescuento,
            tipo: (seleccion.descuentoTotal > 0 || seleccion.porcentajeDescuento > 0) ? "number" : "hidden",
            xs: 6,
            otros: {
                inputProps:
                {
                    readOnly: true
                },
                variant: "filled",
                size: "small",
            }
        },
        {
            id: "precioTotalDescuento",
            texto: "Precio total con descuento",
            valor: seleccion.precioTotalDescuento,
            tipo: (seleccion.descuentoTotal > 0 || seleccion.porcentajeDescuento > 0) ? "Number" : "hidden",
            xs: 6,
            otros: {
                inputProps:
                {
                    min: 0,
                    readOnly: true
                },
                variant: "filled",
                size: "small",
            },
            onChange: handlePrecioTotalSinDescChange,
            ayuda: "Precio no valido",
            regexp: rgxDecimales
        },
        {
            id: "fechaInicialDescuento",
            // texto: "Fecha inicial del descuento",
            valor: seleccion.fechaInicialDescuento,
            tipo: (seleccion.descuentoTotal > 0 || seleccion.porcentajeDescuento > 0) ? "date" : "hidden",
            xs: 6,
            otros: {
                helperText: "Fecha inicial del descuento"
            }
            // ayuda: "Campo obligatorio (si aplica)",
            // regexp: rgxNoVacio
        },
        {
            id: "fechaFinalDescuento",
            // texto: "Fecha final del descuento",
            valor: seleccion.fechaFinalDescuento,
            tipo: (seleccion.descuentoTotal > 0 || seleccion.porcentajeDescuento > 0) ? "date" : "hidden",
            xs: 6,
            otros: {
                helperText: "Fecha final del descuento"
            }
            // ayuda: "Campo obligatorio (si aplica)",
            // regexp: rgxNoVacio
        },
        {
            id: "especialidadId",
            texto: "Grupo *",
            valor: seleccion.especialidadId || -1,
            tipo: "select",
            opciones: opcionesEspecialidades,
            xs: 6,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio
        },
        {
            id: "agendable",
            texto: "Agendable *",
            valor: seleccion.agendable,
            tipo: "select",
            opciones: opcYesNot,
            xs: 4,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            otros: {
                inputProps: {
                    readOnly: (Number(props.tipo) === 2 || Number(props.tipo) === 3) ? true : false,
                }
            }
        },
        {
            id: "inventariable",
            texto: "Inventariable *",
            valor: seleccion.inventariable,
            tipo: "select",
            opciones: opcYesNot,
            xs: 4,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            otros: {
                inputProps: {
                    readOnly: !serviciosInventariables && Number(props.tipo) === 1 ? true : false,
                }
            }
        },
        {
            id: "disponible",
            texto: "Disponible *",
            valor: seleccion.disponible,
            tipo: "select",
            opciones: opcYesNot,
            xs: 4,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio
        },
        {
            id: "tipo",
            texto: "Tipo",
            valor: props.tipo,
            tipo: "hidden",
        },
    ]


    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoProductosServicios?.productosServicios;

    //#endregion

    //#region  handlers
    function handleAgregarClick() {
        setAuxAgregar(1);
        setSeleccion({ ...seleccionInicial, servicioId: null, clinicaId: [] })
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.servicioId !== null && seleccion.servicioId !== undefined) {
            setAuxAgregar(2);
            especialiadRef.current = null;
            garantiaRef.current = null;

            setSeleccion(
                {
                    ...seleccion,
                    fechaInicialDescuento: seleccion.fechaInicialDescuento !== null ? seleccion.fechaInicialDescuento.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                    fechaFinalDescuento: seleccion.fechaFinalDescuento !== null ? seleccion.fechaFinalDescuento.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                    // ...stateCopy
                })
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: `Debe seleccionar un ${props.titulo2}` });
        }
    }

    function handleEliminarClick() {
        if (seleccion.servicioId !== null && seleccion.servicioId !== undefined) {
            setMensaje({
                ...mensaje,
                ...mensajeConfirmacion,
                texto: `¿Está seguro de eliminar el registro con la descripcion: ${seleccion.descripcion}?`, onAccept: ftEliminar
            });
        }
        else {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: `Debe seleccionar un ${props.titulo2}`
            });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion(datos);
            setStateCopy(datos)
            setFila(index);
        } else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    const redirige = (texto) => {
        history.push(texto);
    }

    function handleTipoIvaChange(event) {
        let { value } = event.target;

        let pu = Number(seleccion.precioUnitario);
        let iva = 0;
        let pt = pu * (1 + iva);
        let desc = Number(seleccion.porcentajeDescuento) / 100;
        let descTotal = pu * desc;
        let pud = pu - descTotal;
        let ptd = pud * (1 + iva);

        setSeleccion(seleccion => ({
            ...seleccion,
            tasaIva: 1,
            porcentajeIva: 0,
            iva: iva.toFixed(6),
            precioTotal: pt.toFixed(6),
            precioUnitarioDescuento: pud.toFixed(6),
            ivaDescuento: 0,
            precioTotalDescuento: ptd.toFixed(6),
        }))
    }

    function handleTasaIvaChange(event) {
        let { value } = event.target;
        let [TI] = tasaIvaDB.filter((valor) => {
            if (Number(valor.value) === Number(value)) return valor;
        });

        let pu = 0;
        let iva = 0;
        let pt = 0;
        let desc = 0;
        let descTotal = 0;
        let pud = 0;
        let ivaDesc = 0;
        let ptd = 0;

        if (Number(seleccion.descuento) > 0 || Number(seleccion.porcentajeDescuento) > 0) {
            pu = Number(seleccion.precioUnitario);
            iva = pu * Number(TI.porcentajeIva);
            pt = pu + iva;
            desc = Number(seleccion.porcentajeDescuento) / 100;
            descTotal = pu * desc;
            pud = pu - descTotal;
            ivaDesc = pud * Number(TI.porcentajeIva);
            ptd = pud + ivaDesc;
        }
        else {
            pu = Number(seleccion.precioUnitario);
            iva = pu * Number(TI.porcentajeIva);
            pt = pu + iva;
        }




        setSeleccion(seleccion => ({
            ...seleccion,
            porcentajeIva: TI.porcentajeIva,
            precioUnitario: pu.toFixed(6),
            iva: iva.toFixed(6),
            precioTotal: pt.toFixed(6),
            descuentoTotal: descTotal.toFixed(6),
            precioUnitarioDescuento: pud.toFixed(6),
            ivaDescuento: ivaDesc.toFixed(6),
            precioTotalDescuento: ptd.toFixed(6),
        }))
    }

    function handlePrecioUnitarioChange(event) {
        let pu = Number(event.target.value);
        let iva = 0;
        let pt = 0;
        let desc = Number(seleccion.porcentajeDescuento) / 100;
        let descTotal = 0;
        let pud = 0;
        let ivaDesc = 0;
        let ptd = 0;
        if (Number(seleccion.descuentoTotal) > 0 || Number(seleccion.porcentajeDescuento) > 0) {
            iva = pu * Number(seleccion.porcentajeIva);
            pt = pu + iva;
            descTotal = pu * desc;
            pud = pu - descTotal;
            ivaDesc = pud * Number(seleccion.porcentajeIva);
            ptd = pud + ivaDesc;
        }
        else {
            iva = pu * Number(seleccion.porcentajeIva);
            pt = pu + iva;
        }

        setSeleccion(seleccion => ({
            ...seleccion,
            iva: iva.toFixed(6),
            precioTotal: pt.toFixed(6),
            descuentoTotal: descTotal.toFixed(6),
            ivaDescuento: ivaDesc.toFixed(6),
            precioUnitarioDescuento: pud.toFixed(6),
            precioTotalDescuento: ptd.toFixed(6),
        }))
    }

    function handlePrecioTotalSinDescChange(event) {

        let pt = Number(event.target.value);
        let pu = pt / (1 + Number(seleccion.porcentajeIva));
        let iva = pt - pu;

        setSeleccion(seleccion => ({
            ...seleccion,
            iva: iva.toFixed(6),
            precioUnitario: pu.toFixed(6),
            porcentajeDescuento: 0,
            descuentoTotal: 0,
            precioUnitarioDescuento: 0,
            ivaDescuento: 0,
            precioTotalDescuento: 0
        }))
    }

    function handleImporteDescuento(event) {
        let pu = Number(seleccion.precioUnitario);
        let iva = pu * Number(seleccion.porcentajeIva)
        let pt = pu + iva;
        let descTotal = Number(event.target.value);
        let desc = (descTotal * 100) / pu;
        let pud = pu - descTotal;
        let ivaDesc = (pu - descTotal) * Number(seleccion.porcentajeIva);
        let ptd = pud + ivaDesc;


        setSeleccion(seleccion => ({
            ...seleccion,
            porcentajeDescuento: desc.toFixed(6),
            precioUnitarioDescuento: pud.toFixed(6),
            ivaDescuento: ivaDesc.toFixed(6),
            precioTotalDescuento: ptd.toFixed(6)
        }))

    }

    function handlePorcDescuento(event) {
        let pu = Number(seleccion.precioUnitario);
        let desc = Number(event.target.value) / 100;
        let descTotal = pu * desc;
        let pud = pu - descTotal;
        let ivaDesc = pud * Number(seleccion.porcentajeIva);
        let ptd = pud + ivaDesc;

        setSeleccion(seleccion => ({
            ...seleccion,
            descuentoTotal: descTotal.toFixed(6),
            precioUnitarioDescuento: pud.toFixed(6),
            ivaDescuento: ivaDesc.toFixed(6),
            precioTotalDescuento: ptd.toFixed(6)
        }))
    }

    function handleNoAplicaChange(event) {
        let noAplica = event.target.checked;
        let pu = Number(seleccion.precioUnitario);
        let iva = 0;
        let pt = 0;
        let desc = Number(seleccion.porcentajeDescuento);
        let descTotal = Number(seleccion.descuentoTotal);
        let pud = 0;
        let ptd = 0;

        if (noAplica) {

            iva = pu * 0;
            pt = pu + iva;
            pud = pu - descTotal;
            ptd = pud + iva;//iva o ivaDescuento da igual porque si no aplica iva es 0 para ambos casos


            setSeleccion(seleccion => ({
                ...seleccion,
                NoAplicaIva: noAplica,
                porcentajeIva: 0,
                tipoIva: 1,
                tasaIva: 1,
                iva: iva.toFixed(6),
                precioTotal: pt.toFixed(6),
                precioUnitarioDescuento: pud.toFixed(6),
                ivaDescuento: 0,
                precioTotalDescuento: ptd.toFixed(6),
            }))
        }
        else {
            iva = pu * Number(seleccion.porcentajeIva);
            pt = pu + iva;

            setSeleccion(seleccion => ({
                ...seleccion,
                noAplica: noAplica,
                precioUnitario: pu.toFixed(6),
                iva: iva.toFixed(6),
                precioTotal: pt.toFixed(6)
            }))
        }
    }

    function handleVentaSinExistenciaChange(event) {
        let vse = event.target.checked;
        setSeleccion(seleccion => ({
            ...seleccion,
            venderSinExistencia: vse
        }))
    }

    function handleUpperCaseCP(e) {
        let { value } = e.target;
        setSeleccion(seleccion => ({
            ...seleccion,
            codigoProducto: value.toUpperCase()
        }))
    }

    function handleUpperCaseCB(e) {
        let { value } = e.target;
        setSeleccion(seleccion => ({
            ...seleccion,
            codigoBarras: value.toUpperCase()
        }))
    }

    // function seleccionTexto (evt) {
    //     evt.target.select();
    // }

    const seleccionTexto = (evt) => {
        try {
            if (evt)
                if (evt.target)
                    evt.target.select();
        } catch (e) {
            // console.log(e)
        }
    }

    //#endregion

    //#region funciones
    function ftAgregar({ garantiaId, descripcion, codigoProducto, codigoBarras, precioUnitario, iva, precioTotal, porcentajeDescuento, descuentoTotal, precioUnitarioDescuento, ivaDescuento, precioTotalDescuento, especialidadId, agendable, inventariable, disponible, clinicaId, tipoIva, tasaIva, NoAplicaIva, fechaInicialDescuento, fechaFinalDescuento, venderSinExistencia, cleanStateFormulario }) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);
                let auxFechaIni = `${fechaInicialDescuento}T00:00:00`;
                let auxFechaFin = `${fechaFinalDescuento}T23:59:59`;
                if (fechaInicialDescuento != '') {
                    if (ftAnioNoValido(auxFechaIni)) throw new Error('El año no tiene un formato válido');
                    if (!ftAniosRangosValidosAlterno(auxFechaIni)) throw new Error('El año capturado no se encuentra en el rango permitido');
                }
                if (fechaFinalDescuento != '') {
                    if (ftAnioNoValido(auxFechaFin)) throw new Error('El año no tiene un formato válido');
                    if (!ftAniosRangosValidosAlterno(auxFechaFin)) throw new Error('El año capturado no se encuentra en el rango permitido');
                }
                if (fechaInicialDescuento != '' && fechaFinalDescuento != '') {
                    if (fechaFinalDescuento < fechaInicialDescuento) throw new Error("La fecha inicial no puede ser mayor a la fecha final");
                }
                if (clinicaId.length == 0 || clinicaId[0] == 0) throw new Error("Seleccione una sucursal");
                if (Number(porcentajeDescuento) > Number((maxPorcentajeDescto) * 100)) throw new Error(`El porcentaje debe ser entre 0% y ${Math.round(Number((maxPorcentajeDescto) * 100))}%`);
                if (Number(descuentoTotal) < 0) throw new Error("El descuento total no puede ser menor a 0");
                if (Number(descuentoTotal) > Number(precioUnitario)) throw new Error("El descuento total no puede ser mayor al precio unitario");
                if (ftValidarObligatorios([descripcion, precioUnitario]) === true) {
                    setSeleccion({ ...seleccion, garantiaId, descripcion, codigoProducto, codigoBarras, precioUnitario, iva, precioTotal, porcentajeDescuento, descuentoTotal, precioUnitarioDescuento, ivaDescuento, precioTotalDescuento, especialidadId, agendable, inventariable, disponible, clinicaId, tipoIva, tasaIva, NoAplicaIva, fechaInicialDescuento, fechaFinalDescuento, venderSinExistencia });
                    await addProductoServicio({
                        variables: {
                            productoServicio: {
                                garantiaId: Number(garantiaId),
                                descripcion: descripcion.trim(),
                                codigoProducto: codigoProducto.trim().toUpperCase(),
                                codigoBarras: codigoBarras.trim().toUpperCase(),
                                precioUnitario: Number(precioUnitario),
                                iva: Number(iva),
                                precioTotal: Number(precioTotal),
                                porcentajeDescuento: Number(porcentajeDescuento) / 100,
                                descuentoTotal: Number(descuentoTotal),
                                precioUnitarioDescuento: Number(precioUnitarioDescuento),
                                ivaDescuento: Number(ivaDescuento),
                                precioTotalDescuento: Number(precioTotalDescuento),
                                tipoProducto: Number(props.tipo),
                                especialidadId: Number(especialidadId),
                                agendable: Number(agendable),
                                inventariable: Number(inventariable),
                                disponible: Number(disponible),
                                clinicaId: clinicaId.toString(),
                                tipoIva: Number(tipoIva),
                                tasaIva: Number(tasaIva),
                                NoAplicaIva: Number(NoAplicaIva),
                                fechaInicialDescuento: fechaInicialDescuento,
                                fechaFinalDescuento: fechaFinalDescuento,
                                venderSinExistencia: Number(venderSinExistencia),
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
            }
            catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({ relacionId, servicioId, garantiaId, descripcion, codigoProducto, codigoBarras, precioUnitario, iva, precioTotal, porcentajeDescuento, descuentoTotal, precioUnitarioDescuento, ivaDescuento, precioTotalDescuento, especialidadId, agendable, inventariable, disponible, clinicaId, tipoIva, tasaIva, NoAplicaIva, fechaInicialDescuento, fechaFinalDescuento, venderSinExistencia, cleanStateFormulario }) {
        async function ftModificar() {
            try {
                let auxFechaIni = (fechaInicialDescuento !== null && fechaInicialDescuento !== "") ? `${fechaInicialDescuento}T00:00:00` : null;
                let auxFechaFin = (fechaFinalDescuento !== null && fechaFinalDescuento !== "") ? `${fechaFinalDescuento}T23:59:59` : null;
                if (auxFechaIni !== null) {
                    if (ftAnioNoValido(auxFechaIni)) throw new Error('El año no tiene un formato válido');
                    if (!ftAniosRangosValidosAlterno(auxFechaIni)) throw new Error('El año capturado no se encuentra en el rango permitido');
                }
                if (auxFechaFin !== null) {
                    if (ftAnioNoValido(auxFechaFin)) throw new Error('El año no tiene un formato válido');
                    if (!ftAniosRangosValidosAlterno(auxFechaFin)) throw new Error('El año capturado no se encuentra en el rango permitido');
                }
                if (auxFechaIni !== null && auxFechaFin !== null) {
                    if (auxFechaFin < auxFechaIni) throw new Error("La fecha inicial no puede ser mayor a la fecha final");
                }
                garantiaRef.current = garantiaId ? garantiaId : garantiaRef.current;
                especialiadRef.current = especialidadId ? especialidadId : especialiadRef.current;
                garantiaId = garantiaRef.current || seleccion.garantiaId || -1;
                especialidadId = especialiadRef.current || seleccion.especialidadId || -1;
                codigoProducto = codigoProducto ? codigoProducto : '';
                codigoBarras = codigoBarras ? codigoBarras : '';
                tipoIva = NoAplicaIva == true ? 0 : tipoIva;
                tasaIva = NoAplicaIva == true ? 0 : tasaIva;
                if (clinicaId[0] == 0) throw new Error("Seleccione una sucursal");
                if (Number(porcentajeDescuento) > Number((maxPorcentajeDescto) * 100)) throw new Error(`El porcentaje debe ser entre 0% y ${Math.round(Number((maxPorcentajeDescto) * 100))}%`);

                if (Number(porcentajeDescuento) > 100) throw new Error("El porcentaje debe ser entre 0 y 100");
                if (Number(descuentoTotal) > Number(precioUnitario)) throw new Error("El descuento total no debe ser mayor al precio unitario");
                setDisabledAccept(true);
                if (ftValidarObligatorios([descripcion, precioUnitario]) == true) {
                    await modProductoServicio({
                        variables: {
                            productoServicio: {
                                relacionId: relacionId,
                                servicioId: Number(servicioId),
                                garantiaId: Number(garantiaId),
                                descripcion: descripcion.trim(),
                                codigoProducto: codigoProducto.trim().toUpperCase(),
                                codigoBarras: codigoBarras.trim().toUpperCase(),
                                precioUnitario: Number(precioUnitario),
                                iva: Number(iva),
                                precioTotal: Number(precioTotal),
                                porcentajeDescuento: Number(porcentajeDescuento) / 100,
                                descuentoTotal: Number(descuentoTotal),
                                precioUnitarioDescuento: Number(precioUnitarioDescuento),
                                ivaDescuento: Number(ivaDescuento),
                                precioTotalDescuento: Number(precioTotalDescuento),
                                tipoProducto: Number(props.tipo),
                                especialidadId: Number(especialidadId),
                                agendable: Number(agendable),
                                inventariable: Number(inventariable),
                                disponible: Number(disponible),
                                clinicaId: Number(clinicaId),
                                tipoIva: Number(tipoIva),
                                tasaIva: Number(tasaIva),
                                NoAplicaIva: Number(NoAplicaIva),
                                fechaInicialDescuento: auxFechaIni,
                                fechaFinalDescuento: auxFechaFin,
                                venderSinExistencia: Number(venderSinExistencia),
                                codProdDB: seleccion.codigoProducto,
                                codBarraDB: seleccion.codigoBarras
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();


                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            }
            catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();

    }

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);

            await deleteProductoServicio({
                variables: {
                    id: Number(seleccion.servicioId)
                }
            });
            setDisabledDelete(false);

        } ftEliminar();

    }

    function ftValidarObligatorios(campos) {
        if (campos[0] === "" || campos[0] === undefined) return "Debe especificar una descripción";

        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftValidarFormato(campos) {
        if (Number(campos.precioUnitario) < 0) {
            return false;
        }
        return true;
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    //#endregion

    function stateOut(valorID = {}) {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        async function ftEffect() {
            const tasas = await queryTasaIva();
            const garantias = await queryGarantias();
            setTasaIvaDB(tasas)
            setGarantiasDB(garantias)
            if (garantias[0].value == 0)
                garantias.splice(0, 1, { value: -1, label: "Sin garantía" })
            else
                garantias.splice(0, 0, { value: -1, label: "Sin garantía" })
            const clinicas = await queryClinicas();
            setClinicaDB(clinicas)
            if (props.tipo == 1)
                setTituloProp("Servicios")
            else if (props.tipo == 2)
                setTituloProp("Productos")
            else if (props.tipo == 3)
                setTituloProp("Materiales")
        }
        ftEffect();
    }, []);

    useEffect(() => {
        setActualizar(true);
    }, [clinicaId]);

    return (
        <MenuLateral titulo={props.titulo} menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={tituloProp} onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

            <Tabla titulo={"Listado de " + props.titulo} columnas={columnas}
                datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcion", "codigoProducto", "precioTotal"]}/>

        </MenuLateral>
    );
}


export default withRouter(ProductosServicios);
