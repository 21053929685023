import React, { useState } from 'react';

//Material-ui
import { DialogActions, Button, CircularProgress } from '@material-ui/core';

function BotonesDialog({ tipo = "mensaje", txtBotones = {}, classes = {}, onAccept, onCancel, onClose, aBotones = [], disabledAccept = false, progress = true, ...otros }) {

    const { btnAceptar = "Aceptar", btnCancelar = "Cancelar" } = txtBotones;

    const ftAccept = () => {
        const aceptar = onAccept === undefined || onAccept === null ? onClose : onAccept;
        aceptar();
    };

    const Cancelar = <Button variant="contained" color="secondary" className={classes.BtnCancel} onClick={onCancel} disabled={disabledAccept}>{btnCancelar}</Button>;

    const Aceptar = <Button variant="contained" color="primary" className={classes.Btn} onClick={ftAccept} disabled={disabledAccept} {...otros}>
        {disabledAccept && progress ? <CircularProgress /> : ""}&nbsp;&nbsp;{btnAceptar}</Button>;

    const botones = {
        "mensaje": Aceptar,
        "confirmacion":
            <div className={classes.contenedor}>
                { Cancelar }
                { Aceptar }
            </div>,
        "multiopcion":
            <div className={classes.contenedor}>
                { Cancelar }
                { Aceptar }
                {
                    aBotones.map((boton, index) => {
                        let { Disabled = {}, Readonly = {}, Texto, Color, EventoClick } = boton,
                            { agregar:Dagregar = false, modificar:Dmodificar = false, variable:Dvariable = null } = Disabled,
                            { agregar:rOagregar = false, modificar:rOmodificar = false, variable:rOvariable = null } = Readonly,
                            disabled = Dvariable === null ? false : (!Dvariable ? Dagregar : Dmodificar),
                            readonly = rOvariable === null ? false : (!rOvariable ? rOagregar : rOmodificar);

                        return (<Button
                            key={index}
                            variant="contained"
                            color={Color}
                            className={classes.BtnNew}
                            onClick={EventoClick}
                            disabled={disabled ? disabled : disabledAccept}
                            readOnly={readonly}
                        >{Texto}</Button>);
                    })
                }
            </div>
    }

    return (
        <DialogActions>
            {botones[tipo]}
        </DialogActions>
    )
}

export default BotonesDialog;
