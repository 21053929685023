const LISTADO_GENERO=`
query {
  listadoGenero {
    genero {
      idGenero
      genero
    }
  }
}
`;

export {LISTADO_GENERO};
