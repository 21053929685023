import gql from 'graphql-tag';

//#region
const LISTADO_EXISTENCIAS = gql`
query ListadoExistencias($limite: Int, $pagina: Int, $clinicaId: Int, $existenciaId: Int, $encabezadoId: Int){
  listadoExistencias(limite:$limite, pagina: $pagina, clinicaId: $clinicaId, existenciaId: $existenciaId, encabezadoId: $encabezadoId){
    Existencias{
        existenciaId
        encabezadoId
        empresaId
        nombreEmpresa
        clinicaId
        nombreClinica
        almacenId
        nombreAlmacen
        centroCostosId
        centroCostos
        conceptoId
        nombreConcepto
      	servicioId
      	descripcion
      	codigoProducto
      	codigoBarras
      	precioUnitario
      	iva
      	precioTotal
      	porcentajeDescuento
      	descuentoTotal
      	precioDescuento
      	ivaDescuento
      	PrecioTotalDescuento
      	comision
      	tipo
      	especialidadId
      	agendable
      	inventariable
      	disponible
      	tipoIva
      	tasaIva
      	noAplicaIva
      	fechaInicialDescuento
      	fechaFinalDescuento
      	venderSinExistencia
        existenciaInicial
        entradas
        salidas
        existenciaActual
        referencia
        usuarioId
        nombre
        apellido
    }
    registros
    paginas
  }
}`;

const OBTENER_EXISTENCIA = gql`
query obtenerExistencia($id: Int, $servicio: Int, $clinica: Int){
    obtenerExistencia(id:$id, servicio: $servicio, clinica: $clinica){
        Existencias{
            existenciaId
            empresaId
            nombreEmpresa
            clinicaId
            nombreClinica
            almacenId
            nombreAlmacen
            servicioId
            centroCostosId
            centroCostos
            existenciaInicial
            entradas
            salidas
            existenciaActual
            conceptoId
            nombreConcepto
        }
    }
}`;

const OBTENER_EXISTENCIA_FETCH = `
query obtenerExistencia($id: Int, $servicio: Int, $clinica: Int){
    obtenerExistencia(id:$id, servicio: $servicio, clinica: $clinica){
        Existencias{
            existenciaId
            empresaId
            nombreEmpresa
            clinicaId
            nombreClinica
            almacenId
            nombreAlmacen
            servicioId
            centroCostosId
            centroCostos
            existenciaInicial
            entradas
            salidas
            existenciaActual
            conceptoId
            nombreConcepto
        }
    }
}`;
//#endregion

//#region  CONCEPTO DE INVENTARIO
const LISTADO_CONCEPTOS = gql`
query ListadoConceptoEntrada($limite: Int!, $pagina: Int!, $tipo: Int!){
  listadoConceptos(limite: $limite pagina: $pagina tipo: $tipo){
    conceptoInventario{
      conceptoId
      descripcion
      tipo
    }
    registros
    paginas
  }
}`;
/*const OBTENER_CONCEPTO=gql`
query obtenerConcepto($id: Int!) {
  obtenerConcepto(id: $id) {
    conceptoId
    descripcion
    tipo
  }
}
`;*/
const OBTENER_USUARIOS_ASIGNADOS = `
query obtenerUsuariosAsignados($id: Int!, $correo: Int!, $estatus: Int!) {
    obtenerUsuariosAsignados(id: $id, correo:$correo,estatus: $estatus) {
      clinicaUsuarios {
        clinicaUsuarioId
        clinicaId
        usuarioId
        nombre
        tipo
        nombres
        apellidos
        nombreCompleto
        email
      }
    }
  }
  `;
//#endregion

export {
    LISTADO_EXISTENCIAS,
    LISTADO_CONCEPTOS,
    // OBTENER_CONCEPTO,
    OBTENER_EXISTENCIA,
    OBTENER_EXISTENCIA_FETCH,
    OBTENER_USUARIOS_ASIGNADOS
}
