import React, { useContext, useEffect, useState } from 'react';
//Core
import { Grid, FormControl, Chip,ListItemText, Tooltip,MenuItem, Input, Select, InputLabel, Checkbox,FormHelperText, Typography } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/AsignacionClinicas';
//Query
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_DOCTOR_ASIGNADOS } from '../../../../query';
//Dependencias
import Swal from "sweetalert2";
//Estilos
import { useStyleAsignar, MenuProps } from '../../../../Helpers/constantes/Css/EstilosUi';

const useStyles = useStyleAsignar;

function Modificar(props) {
    //Declaraciones
    const classes = useStyles();
    const [datos, guardarDatos] = useContext(CRMContext);//state compartido
    const [person, setPerson] = useState([]); // se guarda los nombre de doctores
    const [personName, setPersonName] = useState([]); //la seleccion de los nombres

    //Eventos
    const handleChangeGuardarState = async event => {
        setPersonName(event.target.value);
    }



    //Api
    const obtenerDoctores = async () => {
        await FetchGrahpQL({
            query: LISTADO_DOCTOR_ASIGNADOS,
            variables: {
                id:parseInt(datos.Clinicas.clinicaId.valor),
                correo:0,
                estatus:0
            }
        })
            .then(resp => {
                let row = [];
                resp.obtenerDoctoresAsignadosClinicas.clinicaDoctores.map(function (x) {
                    if (x.porDefecto !== 1){
                    row.push(`${x.nombreCompleto}(${x.email})-${x.doctorId}`);
                    }
                });
                setPerson(row)
            })
            .catch((err) => {
                props.ftClose();
                if (err.message === "Failed to fetch") {
                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "error");
                } else {
                Swal.fire("¡Aviso!", err.message, "error");
                }
            });
    }

    //Ciclo de vida
    useEffect(() => {
        obtenerDoctores();
    }, []);

    //Ciclo de vida
    useEffect(() => {
        guardarDatos({
            ...datos,
            Clinicas: {
                ...datos.Clinicas,
                doctorId: {
                    ...datos.Clinicas.doctorId,
                    error: true, valor: personName
                }
            }
        });
    }, [personName]);


    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-mutiple-chip-label" required={true}>Profesionales</InputLabel>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    id="demo-mutiple-chip"
                                    multiple
                                    name="doctorId"
                                    value={personName}
                                    onChange={handleChangeGuardarState}
                                    input={<Input id="select-multiple-chip" />}

                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                          {selected.map(value => (
                                            <Chip key={value} label={value.substr(0, value.indexOf("-"))} className={classes.chip} />
                                          ))}
                                        </div>
                                      )}
                                    MenuProps={MenuProps}
                                >
                                    {person.map((name, index) => {

                                       let tam = name.length;
                                       let ind = name.indexOf("(");
                                       let ind2 = name.lastIndexOf("-");
                                       let ult = tam - ind - (tam-ind2);

                                        return(
                                        <MenuItem key={index} value={name}>
                                            <Checkbox checked={personName.indexOf(name) > -1} />
                                            <Typography variant="inherit" wrap>
                                            <Tooltip title={`${name.substr(0, name.indexOf("("))}`}>
                                            <ListItemText primary={name.substr(0, name.indexOf("("))}
                                            secondary={name.substr(name.indexOf("("),ult)}
                                            />
                                            </Tooltip>
                                            </Typography>
                                        </MenuItem>
                                        );
                                 })}
                                </Select>
                                <FormHelperText>Puede quitar la asignación de profesionales a la sucursal seleccionada </FormHelperText>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            } Titulo="Seleccione"></Fieldset>
        </>

    );
}

export default Modificar;
