// Fetch
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';

// IVA
import { LISTADO_TASA_IVA2 } from '../../../query';
// Configuracion
import { CONFIGURACION, CONFIGURACION_INICIAL, CONFIGURACION_PUNTO_VENTA, CONFIGURACION_PUNTO_VENTA_DECRYPT, VALIDAR_SEGURIDAD_PUNTO_VENTA,LISTAR_ADMINS_CONFIG }  from '../../../query/componentes/Configuracion';
import { ACTUALIZAR_CONFIGURACION, ACTUALIZAR_CONFIGURACION_PUNTO_VENTA,ACTUALIZAR_URL_LOGOTIPO,ACTUALIZAR_CORREO_REGISTRO  }  from '../../../mutation/componentes/Configuracion';

// Helpers
import { ftSelectFormat } from '../../../Helpers';

//#region Querys
export const queryConfiguracion = (input) => {
    async function queryConfiguracion() {
        try {
            let resultado = await FetchGrahpQL({ query: CONFIGURACION, variables: {nombreDB: input.nombreDB} });
            let { obtenerConfiguracion } = resultado;
            return obtenerConfiguracion;
        } catch({message:error}) { return { error } }
    } return queryConfiguracion();
};

export const queryConfiguracionInicial = (input) => {
    async function queryConfiguracionInicial() {
        try {
            //console.log( 'd',input.nombreDB);
            let resultado = await FetchGrahpQL({ query: CONFIGURACION_INICIAL,variables: {nombreDB: input.nombreDB} });
            let { obtenerConfiguracionInicial } = resultado;
            return obtenerConfiguracionInicial;
        } catch({message:error}) { return { error } }
    } return queryConfiguracionInicial();
};

export const queryConfiguracionPuntoVenta = () => {
    async function queryConfiguracionPuntoVenta() {
        try {
            let resultado = await FetchGrahpQL({ query: CONFIGURACION_PUNTO_VENTA });
            let { obtenerConfiguracionPuntoVenta } = resultado;
            return obtenerConfiguracionPuntoVenta;
        } catch({message:error}) { return { error } }
    } return queryConfiguracionPuntoVenta();
};

export const queryConfiguracionAdminis = () => {
    async function queryConfiguracionAdminis() {
        try {
            let resultado = await FetchGrahpQL({
                query: LISTAR_ADMINS_CONFIG,
                variables: {
                    limite: 10000,
                    activas: true
                }
            });

            let {listarAdminsConfig:{adminConfig}} = resultado;

            return ftSelectFormat(adminConfig, {
                id: "usuarioID",
                descripcion: "correo",
            });
        } catch({message:label}) { return [{ value: 0, label }]; }
    }
    return queryConfiguracionAdminis();
}

export const queryConfiguracionPuntoVentaDecrypt = (campos) => {
    async function queryConfiguracionPuntoVentaDecrypt() {
        try {
            let resultado = await FetchGrahpQL({
                query: CONFIGURACION_PUNTO_VENTA_DECRYPT,
                variables: { ...campos  }
            });
            let { obtenerConfiguracionPuntoVentaDecrypt } = resultado;
            return obtenerConfiguracionPuntoVentaDecrypt;
        } catch({message:error}) { return { error } }
    } return queryConfiguracionPuntoVentaDecrypt();
};

export const queryValidarSeguridadPuntoVenta = (campos) => {
    async function queryValidarSeguridadPuntoVenta() {
        try {
            let resultado = await FetchGrahpQL({
                query: VALIDAR_SEGURIDAD_PUNTO_VENTA,
                variables: { ...campos  }
            });
            let { validarSeguridadPuntoVenta } = resultado;
            return validarSeguridadPuntoVenta;
        } catch({message:error}) { return { error } }
    } return queryValidarSeguridadPuntoVenta();
};

export const queryTasaIVA = () => {
    async function queryTasaIVA() {
        try {
            let resultado = await FetchGrahpQL({ query: LISTADO_TASA_IVA2, variables: { offset: 0 } });
            let { listadoTasasIva:{tasasIva} } = resultado;
            return ftSelectFormat(tasasIva, {
                id: "tasaId",
                descripcion: "descripcion",
                otro: "porcentajeIva"
            });
        } catch({message:error}) { return { error } }
    } return queryTasaIVA();
};
//#endregion

//#region Mutation
export const mutationUpdateConfiguracion = (input) => {
    async function mutationUpdateConfiguracion() {
        try {

            let resultado = await FetchGrahpQL({
                query: ACTUALIZAR_CONFIGURACION,
                variables: { input }
            });
            let { actualizarConfiguracion } = resultado;
            return actualizarConfiguracion;
        } catch({message:error}) {  return { error } }
    } return mutationUpdateConfiguracion();
};

export const mutationUpdateConfiguracionPuntoVenta = (input) => {
    async function mutationUpdateConfiguracionPuntoVenta() {
        try {
            let resultado = await FetchGrahpQL({
                query: ACTUALIZAR_CONFIGURACION_PUNTO_VENTA,
                variables: { input }
            });
            let { actualizarConfiguracionPuntoVenta } = resultado;
            return actualizarConfiguracionPuntoVenta;
        } catch({message:error}) { return { error } }
    } return mutationUpdateConfiguracionPuntoVenta();
};
export const ActualizaLogo = (urlLogotipo) => {

    async function mutationConfiguraUrlLogo() {
        try {

            let resultado = await FetchGrahpQL({
                query:  ACTUALIZAR_URL_LOGOTIPO,
                variables: {input:{urlLogotipo}}
            });
            let { actualizarLogo } = resultado;
            let url = actualizarLogo.urlLogotipo;
            return url;
        } catch({message:error}) { return { error } }
    } return mutationConfiguraUrlLogo();
}

export const ActualizaCorreoRegistro = (correo) => {

    async function mutationConfiguraCorreoRegistro() {
        try {

            let resultado = await FetchGrahpQL({
                query:  ACTUALIZAR_CORREO_REGISTRO,
                variables: {input:{usuarioID: Number(correo)}}
            });
            let { actualizarCorreoRegistro } = resultado;

            return actualizarCorreoRegistro;
        } catch({message:error}) { return { error } }
    } return mutationConfiguraCorreoRegistro();
}
//#endregion
