import gql from 'graphql-tag';

//#region FOTO DE PERFIL
const NUEVO_MODIFICAR_FOTO_PERFIL = `
mutation nuevoModificarFotoPerfil($usuarioId: ID!, $ruta: String!) {
    nuevoModificarFotoPerfil(input: { usuarioId: $usuarioId, ruta: $ruta }) {
      fotoId
      usuarioId
      ruta
    }
  }
`;

//#endregion

export {
    NUEVO_MODIFICAR_FOTO_PERFIL
}
