import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery } from '@apollo/react-hooks';
import { LISTADO_PLANES_CREDITO } from '../../query/componentes/PlanesCredito';
import { selectPlanes, selectClientesActivos, mutationAddPlanCredito, mutationUpdatePlanCredito, mutationDeletePlanCredito, mutationAsignarClientesPlanCredito } from './Metodos';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import { ftValidarCampos } from '../../Helpers/constantes/Config/Helper';
import { rgxSelectID, rgxPorcentaje, rgxNumeroDosDigitosSinCero, rgxNumeroConHastaSeisDecimales } from '../../Helpers/constantes/Rgx';

function PlanesCredito () {

    const { loading, error:{message} = {}, data, refetch } = useQuery(LISTADO_PLANES_CREDITO,
        { variables: { limite: 100 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted:()=>{
            setErrorState(false);
        }
    });

    const seleccionInicial = {
        planCreditoID: 0,
        planID: 0,
        plazoCredito: 1,
        porcentajeInteres: null,
        porcentajeAtraso: null,
        montoMinimo: null,
        montoMaximo: null,
        descripcionCredito: 'Uno o más de los campos capturados no son válidos',
        elegirDiaPago: false,
        estatusCredito: true
    };
    const seleccionInicialAsignar = {
        planCreditoID: 0,
        aplicaTodosClientes: false,
        clientesCredito: []
    };

    //#region states
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState({...mensajeInicial});
    const [planSelect, setPlanSelect] = useState([]);
    const [clientesSelect, setClientesSelect] = useState([]);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [seleccionAsignar, setSeleccionAsignar] = useState(seleccionInicialAsignar);
    const [stateCopy, setStateCopy] = useState({});
    const [formulario, setFormulario] = useState({
        abrir: false,
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [formularioAsignar, setFormularioAsignar] = useState({
        abrir: false,
        onClose: ftFormularioCloseAsignar,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    //#endregion

    const columnas = errorState ? [{ id: 'msj', label: '', format: valor => (<p style={{ fontSize: 18, margin: 0 }}>{valor}</p>) }]
    : [
        { id: 'planCreditoID', label: 'ID', minWidth: 0, hide: true,aliasColor:"estatusCredito" },
        { id: 'descripcionCredito', label: 'Descripción del crédito', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
        // { id: 'elegirDiaPago', label: 'Permitir al cliente elegir día de pago', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'aplicaTodosClientes', label: 'Plan de crédito aplica a todos', minWidth: 170, align: 'center', component: "Checkbox" },
        { id: 'estatusCredito', label: 'Estatus', minWidth: 170, align: 'center', component: "Checkbox" },
    ];

    const menu = [
        { texto: "Agregar plan de crédito", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar plan de crédito", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar plan de crédito", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
        { texto: "Asignar clientes al crédito", icono: <GroupAddIcon />, onClick: () => handleAsignarClick() }
    ];

    // const plazoCredito = [3,6,9,12,18,24,32].map(n => ({value: n, label: n}));

    const camposFormulario = [
        {
            id: "planCreditoID",
            texto: "planCreditoID",
            valor: seleccion.planCreditoID,
            tipo: "hidden"
        },
        {
            id: "plazoCredito",
            texto: "Plazo del crédito *",
            valor: seleccion.plazoCredito,
            tipo: "text",
            ayuda: "Capture un valor válido entre 1 y 99",
            regexp: rgxNumeroDosDigitosSinCero,
            xs: 6,
        },
        {
            id: "planID",
            texto: "Seleccionar el plan del crédito *",
            valor: seleccion.planID,
            tipo: "select",
            opciones: planSelect,
            ayuda: "Seleccione un plan de crédito válido",
            regexp: rgxSelectID,
            xs: 6,
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
        },
        {
            id: "porcentajeInteres",
            texto: "% interés",
            valor: seleccion.porcentajeInteres,
            tipo: "text",
            ayuda: "Capture un porcentaje válido",
            regexp: rgxPorcentaje,
            xs: 6
        },
        {
            id: "porcentajeAtraso",
            texto: "% interés por atraso",
            valor: seleccion.porcentajeAtraso,
            tipo: "text",
            ayuda: "Capture un porcentaje válido",
            regexp: rgxPorcentaje,
            xs: 6
        },
        {
            id: "montoMinimo",
            texto: "Monto mínimo para el crédito",
            valor: seleccion.montoMinimo,
            tipo: "text",
            ayuda: "Capture un monto válido",
            regexp: rgxNumeroConHastaSeisDecimales,
            xs: 6
        },
        {
            id: "montoMaximo",
            texto: "Monto máximo para el crédito",
            valor: seleccion.montoMaximo,
            tipo: "text",
            ayuda: "Capture un monto válido",
            regexp: rgxNumeroConHastaSeisDecimales,
            xs: 6
        },
        {
            id: "descripcionCredito",
            texto: "Descripción del plan de crédito *",
            valor: seleccion.descripcionCredito,
            tipo: "textarea",
            propiedades: { readOnly: true },
            xs: 12,
        },
        {
            id: "estatusCredito",
            texto: "Estatus",
            valor: seleccion.estatusCredito,
            opciones: [
                { value: true, label: "Activo" },
                { value: false, label: "Inactivo" }
            ],
            tipo: "superradio",
            onChange: handleChangeRadio,
            xs: 12,
        },
        // {
        //     id: "elegirDiaPago",
        //     texto: "Permitir al cliente elegir el día de pago",
        //     valor: seleccion.elegirDiaPago,
        //     tipo: "checkbox",
        //     xs: 12,
        // }
    ];
    const camposFormularioAsignar = [
        {
            id: "planCreditoID",
            texto: "planCreditoID",
            valor: seleccionAsignar.planCreditoID,
            tipo: "hidden"
        },
        {
            id: "descripcionCredito",
            texto: "Plan de crédito",
            valor: seleccionAsignar.descripcionCredito,
            tipo: "textarea",
            disabled: true,
            xs: 12,
        },
        {
            id: "aplicaTodosClientes",
            texto: "Aplicar crédito a todos los clientes",
            valor: seleccionAsignar.aplicaTodosClientes,
            tipo: "checkbox",
            xs: 12,
            onChange: handleChangeAplicaTodo
        },
        {
            id: "clientesCredito",
            texto: "Seleccionar clientes a los que aplica el crédito",
            valor: seleccionAsignar.clientesCredito,
            tipo: "select",
            opciones: clientesSelect,
            disabled: seleccionAsignar.aplicaTodosClientes,
            multiselect: true,
            xs: 12,
        }
    ];
    let sinRegistros = [{ msj: message ? message : "No se encontraron registros" }];
    const listado = errorState ? sinRegistros : (data ? (data.listadoPlanesCredito ? data.listadoPlanesCredito : sinRegistros) : sinRegistros);

    //#region handlers
    function handleChangeRadio ({target:{value:estatusCredito}}) {
        setSeleccion({
            ...seleccion,
            estatusCredito: estatusCredito === 'true' ? true : false
        });
    }

    function handleChangeAplicaTodo ({target:{checked}}) {
        if(checked) {
            setSeleccionAsignar(seleccionAsignar => ({
                ...seleccionAsignar,
                clientesCredito: []
            }));
        }
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleModificarClick() {
        if (seleccion.planCreditoID) {
            setSeleccion({ ...stateCopy });
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un plan de crédito" });
        }
    }

    function handleAsignarClick() {
        if (seleccion.planCreditoID) {
            setSeleccionAsignar({ ...stateCopy });
            setFormularioAsignar({ ...formularioAsignar, abrir: true, onAccept: ftModificarAsignar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un plan de crédito" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.planCreditoID) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar el plan de crédito?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un plan de crédito" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setSeleccion({ ...datos });
            setSeleccionAsignar({ ...datos });
            setStateCopy({ ...datos });
            setFila(index);
        } else {
            setSeleccion(seleccionInicial);
            setSeleccionAsignar(seleccionInicialAsignar);
            setFila(-1);
        }
    }

    function formatearDinero(valor) {
        let number = Number(valor);
        if(isNaN(number)) return valor;
        return new Intl.NumberFormat("en-US").format(number);
    }

    function ftAgregar({plazoCredito, planID, porcentajeInteres, porcentajeAtraso, montoMinimo, montoMaximo, descripcionCredito, estatusCredito, elegirDiaPago, cleanStateFormulario}) {
        async function ftAgregar() {
            try {
                setDisabledAccept(true);

                let estatusCreditoFormat = typeof estatusCredito === "boolean" ? estatusCredito : false;
                let elegirDiaPagoFormat = typeof elegirDiaPago === "boolean" ? elegirDiaPago : false;

                if (ftValidarCampos([planID, plazoCredito, descripcionCredito])) {

                    porcentajeInteres = (isNaN(Number(porcentajeInteres)) || Number(porcentajeInteres) === 0) ? null : Number(porcentajeInteres)/100;
                    porcentajeAtraso = (isNaN(Number(porcentajeAtraso)) || Number(porcentajeAtraso) === 0) ? null : Number(porcentajeAtraso)/100;
                    montoMinimo = (isNaN(Number(montoMinimo)) || Number(montoMinimo) === 0) ? null : Number(montoMinimo);
                    montoMaximo = (isNaN(Number(montoMaximo)) || Number(montoMaximo) === 0) ? null : Number(montoMaximo);

                    if(montoMaximo && montoMinimo > montoMaximo) throw new Error("El monto mínimo no puede ser mayor al monto máximo");

                    let { error } = await mutationAddPlanCredito({
                        plazoCredito: Number(plazoCredito),
                        planID: Number(planID),
                        porcentajeInteres,
                        porcentajeAtraso,
                        montoMinimo,
                        montoMaximo,
                        descripcionCredito,
                        estatusCredito: estatusCreditoFormat,
                        elegirDiaPago: false
                    });

                    if(error) throw new Error(error);

                    if(cleanStateFormulario) cleanStateFormulario();

                    ftFormularioClose();
                    setDisabledAccept(false);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el plan de crédito` });
                    setSeleccion({ ...seleccion, ...seleccionInicial });
                    setFila(-1);
                    setActualizar(true);

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({planCreditoID, plazoCredito, planID, porcentajeInteres, porcentajeAtraso, montoMinimo, montoMaximo, descripcionCredito, estatusCredito, elegirDiaPago, cleanStateFormulario}) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);

                let estatusCreditoFormat = typeof estatusCredito === "boolean" ? estatusCredito : false;
                let elegirDiaPagoFormat = typeof elegirDiaPago === "boolean" ? elegirDiaPago : false;

                if (ftValidarCampos([planID, plazoCredito, descripcionCredito])) {

                    porcentajeInteres = (isNaN(Number(porcentajeInteres)) || Number(porcentajeInteres) === 0) ? null : Number(porcentajeInteres)/100;
                    porcentajeAtraso = (isNaN(Number(porcentajeAtraso)) || Number(porcentajeAtraso) === 0) ? null : Number(porcentajeAtraso)/100;
                    montoMinimo = (isNaN(Number(montoMinimo)) || Number(montoMinimo) === 0) ? null : Number(montoMinimo);
                    montoMaximo = (isNaN(Number(montoMaximo)) || Number(montoMaximo) === 0) ? null : Number(montoMaximo);

                    if(montoMaximo && montoMinimo > montoMaximo) throw new Error("El monto mínimo no puede ser mayor al monto máximo");

                    let { error } = await mutationUpdatePlanCredito({
                        planCreditoID: Number(planCreditoID),
                        plazoCredito: Number(plazoCredito),
                        planID: Number(planID),
                        porcentajeInteres,
                        porcentajeAtraso,
                        montoMinimo,
                        montoMaximo,
                        descripcionCredito,
                        estatusCredito: estatusCreditoFormat,
                        elegirDiaPago: false
                    });

                    if(error) throw new Error(error);

                    if(cleanStateFormulario) cleanStateFormulario();

                    ftFormularioClose();
                    setDisabledAccept(false);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Se actualizó correctamente el plan de crédito` });
                    setSeleccion({ ...seleccion, ...seleccionInicial });
                    setFila(-1);
                    setActualizar(true);

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftModificarAsignar({planCreditoID, aplicaTodosClientes, clientesCredito, cleanStateFormulario}) {
        async function ftModificar() {
            try {
                setDisabledAccept(true);

                let aplicaTodosClientesFormat = typeof aplicaTodosClientes === "boolean" ? aplicaTodosClientes : false;

                let { error } = await mutationAsignarClientesPlanCredito({
                    planCreditoID: Number(planCreditoID),
                    aplicaTodosClientes: aplicaTodosClientesFormat,
                    clientesCredito
                });

                if(error) throw new Error(error);

                if(cleanStateFormulario) cleanStateFormulario();

                ftFormularioCloseAsignar();
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeExito, texto: "Se actualizaron correctamente los clientes asignados al plan del crédito" });
                setSeleccionAsignar({ ...seleccionAsignar, ...seleccionInicialAsignar });
                setFila(-1);
                setActualizar(true);

            } catch({message:texto}) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }

    function ftEliminar() {
        async function ftEliminar() {
            try {

                setDisabledDelete(true);

                let texto = await mutationDeletePlanCredito(Number(seleccion.planCreditoID));
                let { error } = typeof texto === 'object' && texto ? texto : {};

                if(error) throw new Error(error);

                setMensaje({ ...mensaje, ...mensajeExito, texto });
                setSeleccionAsignar({ ...seleccion, ...seleccionInicial });
                setFila(-1);
                setActualizar(true);

            } catch({message:texto}) {
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({...seleccionInicial});
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftFormularioCloseAsignar() {
        setFormularioAsignar({ ...formularioAsignar, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {})  {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    function stateOutAsignar(valorID = {})  {
        setSeleccionAsignar({
            ...seleccionAsignar,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.error(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        try {
            let valido = false;
            let plan = '';
            if(Array.isArray(planSelect) && Number(seleccion.planID) > 0) {
                let [{subDescripcion}] = planSelect.filter(({value}) => Number(value) === Number(seleccion.planID));
                plan = Number(seleccion.plazoCredito) > 1 ? subDescripcion : subDescripcion.replace(/e?s$/, '');
                valido = (seleccion.plazoCredito < 100 && seleccion.plazoCredito > 0) && (Number(seleccion.porcentajeInteres) <= 100 && Number(seleccion.porcentajeInteres) >= 0) && (Number(seleccion.porcentajeAtraso) <= 100 && Number(seleccion.porcentajeAtraso) >= 0);
            }
            setSeleccion(seleccion => ({
                ...seleccion,
                descripcionCredito: valido ? `${seleccion.plazoCredito} ${plan} ${Number(seleccion.porcentajeInteres) > 0 ? `con ${seleccion.porcentajeInteres}% de interés` : 'sin intereses'} ${Number(seleccion.porcentajeAtraso) > 0 ? `y con ${seleccion.porcentajeAtraso}% de interés por atraso` : 'y sin interés por atraso'} ${Number(seleccion.montoMinimo) > 0 ? `(mínimo de compra: $${formatearDinero(seleccion.montoMinimo)}` : ''}${Number(seleccion.montoMaximo) > 0 ? (`${Number(seleccion.montoMinimo) > 0 ? '; ' : '('}máximo de compra: $${formatearDinero(seleccion.montoMaximo)})`) : (Number(seleccion.montoMinimo) > 0 || Number(seleccion.montoMaximo) > 0 ? ')' : '')}` : 'Uno o más de los campos capturados no son válidos'
            }));
        } catch({message:descripcionCredito}) {
            setSeleccion(seleccion => ({
                ...seleccion,
                descripcionCredito
            }));
        }
    }, [seleccion.planID, seleccion.plazoCredito, seleccion.porcentajeInteres, seleccion.porcentajeAtraso, seleccion.montoMinimo, seleccion.montoMaximo]);

    useEffect(() => {
        async function fEffect() {
            const planes = await selectPlanes("Seleccione un plan");
            const clientes = await selectClientesActivos();
            setPlanSelect(planes);
            setClientesSelect(clientes);
        } fEffect();
    }, []);

    return(<MenuLateral titulo="Planes de crédito" menu={menu}>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

        <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={`${seleccion.planCreditoID ? "Modificar" : "Agregar"} plan de crédito`} onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} />

        <Formulario abrir={formularioAsignar.abrir} campos={camposFormularioAsignar} titulo={`Asignar clientes`} onClose={formularioAsignar.onClose} onAccept={formularioAsignar.onAccept} valor={seleccionAsignar} datos={seleccionAsignar} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicialAsignar} stateOut={stateOutAsignar} />

        <Tabla titulo="Planes de crédito" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={message} onPaginaChange={handlePaginaChange} titleResponsive={["descripcionCredito"]} />

    </MenuLateral>);

}

export default PlanesCredito;
