import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input from '../../Reportes/componentes/Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { queryConfiguracion, queryTasaIVA, mutationUpdateConfiguracion } from '../Metodos';

import { CRMContext } from '../../../context/Configuracion';

import { rgxHora, rgxLetras50max } from '../../../Helpers/constantes/Rgx';
import { trim } from '../../../Helpers';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'

const useStyles = makeStyles((theme) => ({
    Btn: { margin: '0 auto' }
}));

function ConfiguracionGeneral({ setMensaje }) {

    const classes = useStyles();

    const defaultState = {
        descripcionLogin: 'Login',
        leyendaQRCard: 'Leyenda tarjeta QR',
        precioConIVA: false,
        porcentajeDescuento: true,
        ventaSinExistencia: false,
        modificarPrecioPuntoVenta: false,
        porcentajeDescuento: false,
        serviciosInventariables: false,
        permitirModificarHorarioProfesional: false,
        permitirAgendarClientes: false,
        permitirModificarHorarioCliente: false,
        generarReceta: false,
        tasaIVA: 0.16,
        maxPorcentajeDescto: 0.5,
        precisionAgenda: 15,
        tiempoIniAgenda: '09:00:00',
        tiempoFinAgenda: '20:00:00',
        tiempoCarrusel: 30,
        maxPorcentajeComision: 0.5
    };

    const maxPorcentajeDescto = () => {
        let valor = 5, valorIni = 5, valorMax = 90, arreglo = [];
        while(valor <= valorMax) {
            arreglo.push(valor);
            valor += valorIni;
        }
        return arreglo.map(valor => ({
            value: valor/100,
            label: `${valor}%`
        }));
    }

    const maxPorcentajeComision = () => {
        let valor = 5, valorIni = 5, valorMax = 90, arreglo = [];
        while(valor <= valorMax) {
            arreglo.push(valor);
            valor += valorIni;
        }
        return arreglo.map(valor => ({
            value: valor/100,
            label: `${valor}%`
        }));
    }

    const defaultSelect = {
        precision: [15, 30, 45, 60].map(value => ({
            value,
            label: `${value} minutos`
        })),
        carrusel: [30, 60, 90, 120, 150].map(value => ({
            value,
            label: `${value} segundos`
        })),
        maxPorcentajeDescto: maxPorcentajeDescto(),
        maxPorcentajeComision:maxPorcentajeComision(),
        tasaIVA: []
    };

    const defaultError = {
        descripcionLogin: false,
        leyendaQRCard: false,
        tiempoIniAgenda: false,
        tiempoFinAgenda: false
    };

    const [ state, setState ] = useState(defaultState);
    const [ select, setSelect ] = useState(defaultSelect);
    const [ error, setError ] = useState(defaultError);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);

	const campos = [{
        id: 'descripcionLogin',
        texto: 'Capture la descripción para el login *',
        valor: state.descripcionLogin,
        tipo: 'text',
        propiedades: { readOnly: false },
        xs: 12,
        regexp: rgxLetras50max,
        ayuda: "El formato de captura solo permite letras (los siguientes símbolos: -_.,); y un máximo de 50 caracteres",
        error: error.descripcionLogin
    },{
        id: 'leyendaQRCard',
        texto: 'Leyenda para la tarjeta digital QR *',
        valor: state.leyendaQRCard,
        tipo: 'text',
        propiedades: { readOnly: false },
        xs: 12,
        regexp: rgxLetras50max,
        ayuda: "El formato de captura solo permite letras (los siguientes símbolos: -_.,); y un máximo de 50 caracteres",
        error: error.leyendaQRCard
    },{
        id: 'precioConIVA',
        texto: 'Capturar precios con IVA',
        valor: state.precioConIVA,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12,
        md: 6
    },{
        id: 'porcentajeDescuento',
        texto: 'Capturar descuentos por porcentaje',
        valor: state.porcentajeDescuento,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12,
        md: 6
    },{
        id: 'ventaSinExistencia',
        texto: 'Vender productos sin existencia',
        valor: state.ventaSinExistencia,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12,
        md: 6
    },{
        id: 'modificarPrecioPuntoVenta',
        texto: 'Modificar el precio en el punto de venta',
        valor: state.modificarPrecioPuntoVenta,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12,
        md: 6
    },{
        id: 'serviciosInventariables',
        texto: 'Permitir inventariar los servicios',
        valor: state.serviciosInventariables,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'permitirModificarHorarioProfesional',
        texto: 'Permitir modificar el horario en la agenda al profesional',
        valor: state.permitirModificarHorarioProfesional,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'permitirAgendarClientes',
        texto: 'Permitir agendar más de una cita a los clientes',
        valor: state.permitirAgendarClientes,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: 'permitirModificarHorarioCliente',
        texto: 'Permitir modificar el horario en la agenda al cliente',
        valor: state.permitirModificarHorarioCliente,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        disabled: !state.permitirAgendarClientes,
        xs: 12
    },{
        id: 'generarReceta',
        texto: 'Permitir a los profesionales generar recetas',
        valor: state.generarReceta,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12
    },
    {
        id: 'validaHorarios',
        texto: 'Solo agendar cita en horarios disponibles de profesional y sucursal',
        valor: state.validaHorarios,
        tipo: 'checkbox',
        propiedades: { readOnly: false },
        xs: 12,
        propiedades: { visible: false },
    },
    {
        id: 'tasaIVA',
        texto: 'Tasa de IVA *',
        valor: state.tasaIVA,
        opciones: select.tasaIVA,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 6
    },{
        id: 'maxPorcentajeDescto',
        texto: 'Máximo porcentaje de descuento permitido *',
        valor: state.maxPorcentajeDescto,
        opciones: select.maxPorcentajeDescto,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 6
    },{
        id: 'tiempoCarrusel',
        texto: 'Intervalo carrusel *',
        valor: state.tiempoCarrusel,
        opciones: select.carrusel,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 6
    },{
        id: 'precisionAgenda',
        texto: 'Precisión agenda *',
        valor: state.precisionAgenda,
        opciones: select.precision,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 6
    },{
        id: 'tiempoIniAgenda',
        texto: 'Inicio agendable (24 hrs) *',
        valor: state.tiempoIniAgenda,
        tipo: 'text',
        propiedades: { readOnly: false },
        xs: 6,
        regexp: rgxHora,
        ayuda: "El formato debe ser de 24 horas, ejemplo: 09:00:00",
        error: error.tiempoIniAgenda
    },{
        id: 'tiempoFinAgenda',
        texto: 'Fin agendable (24 hrs) *',
        valor: state.tiempoFinAgenda,
        tipo: 'text',
        propiedades: { readOnly: false },
        xs: 6,
        regexp: rgxHora,
        ayuda: "El formato debe ser de 24 horas, ejemplo: 09:00:00",
        error: error.tiempoFinAgenda
    },
    {
        id: 'maxPorcentajeComision',
        texto: 'Máximo porcentaje de comisiones',
        valor: state.maxPorcentajeComision,
        opciones: select.maxPorcentajeComision,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 12
    },
];

	function handleChange({target}, id, multiselect, onChange, cleanDatoState, val, validarRegexp) {
        let { value, type, checked } = target;
        value = type === "checkbox" ? checked : value;
        setState({
            ...state,
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        });
        validarRegexp({ id, value, setError });
    }

    function validarRangoHoras({ horaFin, horaIni }) {

        if(typeof horaFin !== "string" || typeof horaIni !== "string") throw new Error("Las horas no tienen el formato correcto");

        const msjError1 = "¡La hora inicial debe ser menor que la final!";
        const msjError2 = "¡Las horas deben de ser diferentes!";

        const hf = horaFin.split(":");
        const hi = horaIni.split(":");

        let h0 = (Number(hi[0]) === Number(hf[0]));
        let h1 = (Number(hi[1]) === Number(hf[1]));
        let h2 = (Number(hi[2]) === Number(hf[2]));

        if(Number(hi[0]) > Number(hf[0])) throw new Error(msjError1);
        if(h0 && Number(hi[1]) > Number(hf[1])) throw new Error(msjError1);
        if(h0 && h1 && Number(hi[2]) > Number(hf[2])) throw new Error(msjError1);
        if(h0 && h1 && h2) throw new Error(msjError2);

    }

    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                let input = { ...state };
                delete input.urlLogotipo;
                delete input.precisionAgendaFormat;
                delete input.descripcionTasaIVA;
                delete input.porcentajeTasaIVA;
                delete input.descripcionMaxPorcentajeDescto;
                delete input.descripcionMaxPorcentajeComision;

                input.descripcionLogin = trim(input.descripcionLogin);
                input.leyendaQRCard = trim(input.leyendaQRCard);
                input.tasaIVA = Number(input.tasaIVA);
                input.maxPorcentajeDescto = Number(input.maxPorcentajeDescto);
                input.maxPorcentajeComision = Number(input.maxPorcentajeComision);
                input.precisionAgenda = Number(input.precisionAgenda);
                input.tiempoCarrusel =  Number(input.tiempoCarrusel);

                input.permitirModificarHorarioCliente = !state.permitirAgendarClientes ? false : input.permitirModificarHorarioCliente;

                input.validaHorarios = !state.validaHorarios ? false : input.validaHorarios;

                if(!rgxLetras50max.test(input.descripcionLogin) || input.descripcionLogin === '') throw new Error("¡La descripción del login no es válida!");
                if(!rgxLetras50max.test(input.leyendaQRCard) || input.leyendaQRCard === '') throw new Error("¡La leyenda de la tarjeta QR no es válida!");
                if(!rgxHora.test(input.tiempoIniAgenda)) throw new Error("¡La hora inicial no tiene el formato correcto!");
                if(!rgxHora.test(input.tiempoFinAgenda)) throw new Error("¡La hora final no tiene el formato correcto!");

                validarRangoHoras({
                    horaIni: input.tiempoIniAgenda,
                    horaFin: input.tiempoFinAgenda
                });

                let { error } = await mutationUpdateConfiguracion(input);

                if(error) throw new Error(error);

                setActualizar(true);
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡La configuración se guardo correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
            }
            setDisabledAccept(false);
        }
        click();
    }

    useEffect(() => {
        if(!state.permitirAgendarClientes) {
            setState({
                ...state,
                permitirModificarHorarioCliente: false
            });
        }
    }, [state.permitirAgendarClientes]);

    useEffect(() => {
        async function ftEffect() {
            let sesiones = process.env.REACT_APP_DB;

            const configuracion = await queryConfiguracion({
                nombreDB: sesiones,
            });
            const tasaIVA = await queryTasaIVA();

            setState({
                ...state,
                ...configuracion
            });
            setSelect({
                ...defaultSelect,
                tasaIVA
            });
        } ftEffect();
    }, []);

    return(<>
        <Input campos={campos} handleChange={handleChange} state={state} />
        <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Guardar" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
    </>);
}

export default ConfiguracionGeneral;
