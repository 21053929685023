import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_CONVENIOS, OBTENER_FORMA_PAGO, LISTADO_PROMOTOR } from '../../query';
import { NUEVO_REGISTRO_USUARIO_CONVENIO, NUEVO_CONVENIO, MODIFICAR_CONVENIO, ELIMINAR_CONVENIO, NUEVO_PROMOTOR, MODIFICAR_PROMOTOR } from '../../mutation';
//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/Formulario';
import { Dialog, DialogTitle, IconButton } from '@material-ui/core';
import Promotores from '../Promotores';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
//constantes
import { mensajeConfirmacion, mensajeError, mensajeExito, mensajeInicial } from '../../Helpers/constantes';
//****ROUTER***********/
import { withRouter } from "react-router-dom";
//Iconos
import { AddIcon, EditIcon, DeleteForever, ThumbUpIcon, StyleIcon, CloseIcon } from '../../componentesGenericos/Icons';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
//Redux
import { useSelector } from 'react-redux';
//Helpers
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat, ftAnioNoValido, ftAniosRangosValidosAlterno } from '../../Helpers';
import { strUrlSat } from '../../Helpers/constantes/Config/UrlServer'

//Expresiones regulares
import { rgxNoVacio, rgxRFCFisico, rgxCorreo, rgxAlmenosUnaLetra, rgxMultiCorreos } from '../../Helpers/constantes/Rgx';
import { cuatroCaracteres } from '../../Helpers/constantes/formatos';
import { postImagen, putImagen, deleteArchivo } from '../../Helpers/constantes/Imagenes/getImagen'
import { rutaPublica } from '../../Helpers/constantes/Config/urlRutas';

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function Convenios(props) {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        consultaPromotores();
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Text in a modal</h2>
            <p id="simple-modal-description">
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </p>
        </div>
    );

    let { history } = props;
    //#region queries & Mutations
    let { loading, error, data, refetch } = useQuery(LISTADO_CONVENIOS, {
        variables: { offset: 0, empresa: null, estatus: null, correo: null },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message)
            setErrorState(true)
            setFila(-1)
            setSeleccion(seleccionInicial)
        },
        onCompleted: () => {
            setFila(-1)
            setSeleccion(seleccionInicial)
            setErrorState(false)

        }
    });

    const [addConvenio] = useMutation(NUEVO_CONVENIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""))
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se agregó correctamente el convenio` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modConvenio] = useMutation(MODIFICAR_CONVENIO, {
        onError: (e) => {
            throw new Error(e.message.replace("GraphQL error:", ""))
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente el convenio de la empresa "${seleccion.empresa}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [deleteConvenio] = useMutation(ELIMINAR_CONVENIO, {
        onError: (e) => {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: e.message.replace("GraphQL error:", "")
            });
        },
        onCompleted: (e) => {
            setMensaje({
                ...mensaje,
                ...mensajeExito,
                texto: `Se eliminó correctamente el convenio de la empresa "${seleccion.empresa}"`
            });
            //await eliminarIMG(`public/kalenday${seleccion.urlLogo}`);

            setSeleccion({
                ...seleccion,
                ...seleccionInicial
            });

            setFila(-1);
            setActualizar(true);
        }
    })

    //#endregion
    //#region states
    const dt = new Date();
    const seleccionInicial = {
        convenioId: null,
        empresa: '',
        tipoPago: [],
        formaCobroAux: null,
        auxfc: null,
        opcionesPago: 1,
        email: '',
        estatus: null,
        fechaFinal: dt.toISOString().split("T")[0],
        fechaInicial: dt.toISOString().split("T")[0],
        RFC: 'XAXX010101000',
        urlLogo: null,
        imagen: null,
        tipoConvenio: "Individual",
        promotorID: null,
        promotor: "",
        comision: 0
    }

    let result = null;


    const [disabledAccept, setDisabledAccept] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [openImg, setOpenImg] = useState(false);
    const [formaCobro, setFormaCobro] = useState([]);
    const [formatoFC, setFormatoFC] = useState([]);
    const [auxFCDB, setauxFCDB] = useState([]);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });

    const [errorState, setErrorState] = useState(false);
    const [stateCopy, setStateCopy] = useState({});
    const [openCorreos, setOpenCorreos] = useState(false);
    const [correos, setCorreos] = useState("");
    const [openPromotor, setOpenPromotor] = useState(false);
    const tiposConvenio = [
        {
            label: "Individual",
            value: "Individual"
        },
        {
            label: "Grupal",
            value: "Grupal"
        }
    ];
    const [listPromotores, setListPromotores] = useState({});

    //#endregion


    //#region constantes


    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
        { id: 'convenioId', label: 'convenioId ', minWidth: 0, hide: true, aliasColor: "estatus" },
        { id: 'empresa', label: 'Empresa/Convenio', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        { id: 'RFC', label: 'RFC', minWidth: 170, align: 'left', textAlign: 'left', },
        {
            id: 'tipoPago', label: 'Tipo de pago', minWidth: 100, align: 'left', textAlign: 'left',
            format: (fila) => {
                let aux = "";

                fila = JSON.parse(fila);

                for (let valor of fila) {
                    aux = aux + valor[0].descripcion + ',';
                }
                if (-aux.endsWith(",")) aux = aux.substring(0, aux.length - 1)

                return aux;
            },
            hide: true
        },
        {
            id: 'tipoConvenio', label: 'Tipo de convenio', minWidth: 170, align: 'left', textAlign: 'left',
            // format: (fila) => {
            //     return fila.toString().replace("1", "Sucursal").replace("2", "Nómina");
            // }
        },
        {
            id: 'opcionesPago', label: 'Opciones de pago', minWidth: 170, align: 'left', textAlign: 'left',
            format: (fila) => {
                return fila.toString().replace("1", "Sucursal").replace("2", "Nómina");
            }
        },
        { id: 'email', label: 'Correo', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
        {
            id: 'estatus', label: 'Activo', minWidth: 170, align: 'center',
            component: "Checkbox"
        },
        {
            id: 'urlLogo', label: 'Imagen', minWidth: 170, align: 'center', format: (urlLogo, convenioId) => {
                return urlLogo ? <img id={urlLogo} src={`${rutaPublica}${urlLogo}${'?'}${Math.random()}`} width={'20%'} onClick={() => seleccionaImagen(urlLogo)} /> : "Sin imagen"
            }
        },
        {
            id: 'fechaInicial', label: 'Fecha de inicio', minWidth: 170, align: 'center',
            format: (valor) => {
                return valor ? valor.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : null;
            }
        },
        {
            id: 'fechaFinal', label: 'Fecha de finalización', minWidth: 170, align: 'center',
            format: (valor) => {
                return valor ? valor.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : null;
            }
        }

    ];



    const menu = [
        {
            texto: "Agregar convenio",
            icono: <AddIcon />,
            onClick: () => handleAgregarClick()
        },
        {
            texto: "Modificar convenio",
            icono: <EditIcon />,
            onClick: () => handleModificarClick()
        },
        {
            texto: "Eliminar convenio",
            icono: <DeleteForever />,
            onClick: () => handleEliminarClick()
        },
        {
            texto: "Desplazarse hacia asignar convenios a clientes",
            icono: <ThumbUpIcon />,
            onClick: (e) => redirige("/asignarconveniosclientes")
        },
        {
            texto: "Desplazarse hacia asignar servicios a convenios",
            icono: <StyleIcon />,
            onClick: (e) => redirige("/convenioservicios")
        },
        {
            texto: "Enviar correo para registrar usuario al convenio",
            icono: <MailOutlinedIcon />,
            onClick: (e) => handleEnviarCorreoRegistro()
        },
        {
            texto: "Despalazarse hacia promotores",
            icono: <PersonAddIcon />,
            onClick: (e) => redirige("/promotores")
        },
    ];

    const redirige = (texto) => {
        history.push(texto);
    }

    const opcTipoPago = [
        {
            label: "Sucursal",
            value: 1
        },
        {
            label: "Nómina",
            value: 2
        }
    ];

    const promotorProps = {
        options: listPromotores,
        getOptionLabel: option => option.promotor,
        noOptionsText: 'No se encontraron registros'
    };

    const inputEnviarCorreos = [
        {
            id: "empresa",
            texto: "Empresa/Convenio *",
            valor: seleccion.empresa,
            tipo: "text",
            xs: 12,
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
        },
        {
            id: "correos",
            texto: "Correos *",
            valor: correos,
            tipo: "textarea",
            xs: 12,
            sm: 12,
            md: 12,
            lg: 12,
            ayuda: "Ingrese los correos separados por ; (sin espacios)",
            regexp: rgxMultiCorreos,
            onChange: (e) => setCorreos(e.target.value),
            formato: cuatroCaracteres,
            propiedades:
            {
                // readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
                helperText: "Ingrese los correos separados por ; (sin espacios)",
            },
        }
    ];

    const inputPromotor = [
        {
            id: "promotorID",
            texto: "promotorID",
            valor: seleccion.promotorID,
            tipo: "text",
            xs: 12,
            tipo: "hidden"
        },
        {
            id: "promotor",
            texto: "Promotor",
            valor: seleccion.promotor,
            tipo: "text",
            xs: 12, sd: 9, md: 9
        },
        {
            id: "comision",
            texto: "Comisión",
            valor: seleccion.comision,
            tipo: "number",
            xs: 12, sd: 3, md: 3,
            propiedades: {
                min: 0,
                max: 100
            },
            otros: {
                InputProps: {
                    endAdornment: "%",
                }
            },
            xs: 12,
        },
    ]

    const camposFormulario = [
        {
            id: "convenioId",
            texto: "convenioId",
            valor: seleccion.convenioId,
            tipo: "hidden"
        },
        {
            id: "empresa",
            texto: "Empresa/Convenio *",
            valor: seleccion.empresa,
            tipo: "text",
            xs: 12,
            ayuda: "Campo obligatorio (Minímo cuatro caracteres)",
            regexp: rgxAlmenosUnaLetra,
            formato: cuatroCaracteres,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
        },
        {
            id: "RFC",
            texto: "RFC *",
            valor: seleccion.RFC,
            tipo: "text",
            onChange: handleUpperCase,
            xs: 12, sd: 6, md: 6,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
            ayuda: "RFC no valido",
            regexp: rgxRFCFisico,
        },
        {
            id: "opcionesPago",
            texto: "Tipo de pago *",
            valor: seleccion.opcionesPago,
            tipo: "select",
            opciones: opcTipoPago,
            xs: 12, sd: 6, md: 6,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
        },
        {
            id: "email",
            texto: "Correo *",
            valor: seleccion.email,
            tipo: "text",
            xs: 12, sd: 8, md: 8,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
            ayuda: "Campo obligatorio",
            regexp: rgxCorreo,
        },
        {
            id: "tipoConvenio",
            texto: "Tipo de Convenio *",
            valor: !seleccion.tipoConvenio ? "Individual" : seleccion.tipoConvenio,
            tipo: "select",
            opciones: tiposConvenio,
            xs: 12, sd: 4, md: 4,
            regexp: rgxNoVacio,
            disabled: (seleccion.convenioId) ? true : false
        },
        {
            id: "promotorID",
            texto: "Seleccione un promotor",
            valor: listPromotores.length > 0 ? seleccion.promotorID ? listPromotores.find(val => val.promotorID == seleccion.promotorID) : -1 : -1,
            tipo: "autocomplete",
            xs: 12,
            propiedades: promotorProps,
            onChange: handlePromotorChange
        },
        {
            id: "tipoPago",
            texto: "Formas de cobro *",
            valor: seleccion.tipoPago,
            tipo: "select",
            opciones: formaCobro,
            onChange: handleGetFC,
            xs: 12,
            multiselect: true,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            disabled: (seleccion.convenioId && seleccion.estatus) ? true : false
        },
        {
            id: "fechaInicial",
            texto: "Fecha de inicio *",
            valor: seleccion.fechaInicial,
            tipo: "date",
            xs: 12, sd: 6, md: 6,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
        },
        {
            id: "fechaFinal",
            texto: "Fecha de finalización *",
            valor: seleccion.fechaFinal,
            tipo: "date",
            xs: 12, sd: 6, md: 6,
            ayuda: "Campo obligatorio",
            regexp: rgxNoVacio,
            propiedades:
            {
                readOnly: (seleccion.convenioId && seleccion.estatus) ? true : false,
            },
        },
        {
            id: "urlLogo",
            texto: "Imagen",
            valor: (seleccion.imagen || seleccion.urlLogo) ? "Logotipo convenio" : "",
            tipo: "superFile",
            xs: 12,
            otros: {
                inputProps: { accept: "image/*" }
            },
            onChange: handleImagenChange
        },
    ]

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoConvenios?.convenios;

    const queryFormaCobro = () => {
        async function queryFormaCobro() {
            try {
                let resultado = await FetchGrahpQL({
                    url: strUrlSat,
                    query: OBTENER_FORMA_PAGO,
                });

                let { getFormaPago } = resultado;

                return ftSelectFormat(getFormaPago, {
                    id: "claveFormaPago",
                    descripcion: "descripcion",
                }, "");
            } catch ({ message: label }) { return [{ value: 0, label }]; }
        }
        return queryFormaCobro();
    }


    function handleGetFC(e) {
        let { value } = e.target;

        let FC = value.map(valor => {
            let auxVal = valor;

            let res = formatoFC.filter(valor => {
                if (Number(valor.clave) === Number(auxVal))
                    return valor;
            })

            return res;
        })

        let objetofc = JSON.stringify(FC);

        setSeleccion(seleccion => ({
            ...seleccion,
            formaCobroAux: objetofc
        }))

    }

    function handlePromotorChange(e, val) {
        setSeleccion({ ...seleccion, promotorID: val ? Number(val.promotorID) : null })
    }

    function handleUpperCase(e) {
        let { value } = e.target;
        setSeleccion(seleccion => ({
            ...seleccion,
            RFC: value.toUpperCase()
        }))

    }

    //#endregion

    //#region handlers

    function seleccionaImagen(ruta) {

        let fila = -1;
        for (let i = 0; i < listado.length; i++) {

            if (ruta == listado[i].urlLogo) {
                fila = i;
            }
        }
        handleTablaClickImg(listado[fila], fila);
        setOpenImg(true);
    }

    function handleImagenChange({ target }) {
        setSeleccion({ ...seleccion, imagen: target.files[0], nombreImagen: target.files[0].name });
    }

    function ftCloseImg() {
        setOpenImg(false);
    }

    function handleAgregarClick() {
        setSeleccion(seleccionInicial)

        setFila(-1);

        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        // if (seleccion.convenioId !== null && seleccion.convenioId !== undefined && Number(seleccion.estatus) === 1) {
        //     setDisabledAccept(true);
        // }

        if (seleccion.convenioId !== null && seleccion.convenioId !== undefined) {
            setSeleccion({
                ...seleccion,
                ...stateCopy,
            });

            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un convenio" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.convenioId !== null && seleccion.convenioId !== undefined && Number(seleccion.estatus) === 1) {
            setMensaje({ ...mensaje, ...mensajeError, texto: "El convenio no puede ser eliminado una vez aceptado por la empresa" });
        }
        else if (seleccion.convenioId !== null && seleccion.convenioId !== undefined) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Esta seguro de eliminar el convenio de la empresa "${seleccion.empresa}" ?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un convenio" });
        }
    }

    function handleEnviarCorreoRegistro() {
        if ((seleccion.convenioId !== null || seleccion.convenioId !== undefined) && seleccion.tipoConvenio === "Grupal" && seleccion.estatus === 1) {
            setOpenCorreos(true);
        } else if (seleccion.convenioId && seleccion.tipoConvenio === "Individual") {
            setMensaje({ ...mensaje, ...mensajeError, texto: "No disponible en convenio individual" });
        } else if (seleccion.convenioId && seleccion.estatus === 0) {
            setMensaje({ ...mensaje, ...mensajeError, texto: "El convenio debe de estar activo" });
        }
        else if (seleccion.convenioId === undefined || seleccion.convenioId === null) {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un convenio" });
        }
    }

    function handleRegistroPromotor() {
        /*  setSeleccion(seleccionInicial)

         setFila(-1);

         setOpenPromotor(true); */
        setOpenModal(true);

    }
    function consultaPromotores() {
        async function consultaPromotores() {

            await FetchGrahpQL({
                query: LISTADO_PROMOTOR,
                variables: {}
            })
                .then((data) => {
                    setListPromotores(data.listadoPromotores.promotores)

                })
                .catch((err) => {
                    setListPromotores([{ promotor: "No se encontraron registros", id: null }])
                })
        } consultaPromotores();
    }

    function handleTablaClickImg(datos, index) {
        if (seleccion !== datos) {
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function handleTablaClick(datos, index) {
        let aux = "";
        console.clear();
        if (seleccion !== datos) {
            if (!Array.isArray(datos.tipoPago))
                setauxFCDB(datos.tipoPago);
            let fc = null;
            if (datos.auxfc == null) {
                fc = datos.tipoPago;
                if (Array.isArray(fc))
                    fc = auxFCDB
                fc = JSON.parse(fc);
                for (let valor of fc) {
                    aux = aux + valor[0].clave + ',';
                }
                if (-aux.endsWith(",")) aux = aux.substring(0, aux.length - 1)
                aux = aux.toString().split(',');
                setStateCopy({
                    ...datos,
                    tipoPago: aux,
                    auxfc: datos.tipoPago,
                    auxfc: datos.tipoPago,
                    fechaInicial: datos.fechaInicial ? datos.fechaInicial.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                    fechaFinal: datos.fechaFinal ? datos.fechaFinal.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                })

            }
            else {
                if (Array.isArray(fc)) {
                    fc = auxFCDB
                    fc = JSON.parse(fc);
                }
                else {
                    if (Array.isArray(datos.auxfc)) {
                        fc = auxFCDB;
                        fc = JSON.parse(fc);
                    }
                    else {
                        fc = JSON.parse(datos.auxfc);
                    }
                }
                for (let valor of fc) {
                    aux = aux + valor[0].clave + ',';
                }
                if (-aux.endsWith(",")) aux = aux.substring(0, aux.length - 1)
                aux = aux.toString().split(',');
                setStateCopy({
                    ...datos,
                    tipoPago: aux,
                    auxfc: datos.tipoPago,
                    auxfc: datos.tipoPago,
                    fechaInicial: datos.fechaInicial ? datos.fechaInicial.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                    fechaFinal: datos.fechaFinal ? datos.fechaFinal.replace(/T\d{2}:\d{2}:\d{2}.\d{3}Z/, "") : "",
                })
            }
            setSeleccion(datos);

            setFila(index);
        } else {
            setSeleccion({
                ...seleccionInicial, urlLogo: datos.urlLogo
            });
            setFila(-1);
        }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    async function eliminarIMG(ruta) {
        let resultado = await deleteArchivo(ruta);
        return resultado;
    }

    async function ftRecuperarImagen(urlImagen) {
        return await fetch(rutaPublica + urlImagen)//urlServer + "/promociones/img/" + urlImagen
            .then(response => {
                return response.blob()
            }).then(blob => {
                return new File([blob], "img", { type: blob.type });
            }).catch(e => {
                return null;
            });
    }

    //#endregion


    function ftAgregar({ empresa, RFC, tipoPago, formaCobroAux, opcionesPago, email, fechaInicial, fechaFinal, imagen, tipoConvenio, promotorID, cleanStateFormulario }) {
        async function ftAgregar() {
            try {

                setDisabledAccept(true);
                let auxFechaIni = `${fechaInicial}T00:00:00`;
                let auxFechaFin = `${fechaFinal}T23:59:59`;
                if (ftAnioNoValido(auxFechaIni) || ftAnioNoValido(auxFechaFin)) throw new Error('El año no tiene un formato válido');
                if (!ftAniosRangosValidosAlterno(auxFechaIni) || !ftAniosRangosValidosAlterno(auxFechaFin)) throw new Error('El año capturado no se encuentra en el rango permitido');
                if (tipoPago.length == 0) throw new Error("Debe seleccionar por lo menos una forma de cobro");
                setSeleccion((seleccion) => ({ ...seleccion, empresa, RFC, tipoPago, formaCobroAux, opcionesPago, email, fechaInicial, fechaFinal, imagen }));

                if (ftValidarObligatorios([empresa.trim(), RFC.trim(), tipoPago, opcionesPago, email.trim(), fechaInicial, fechaFinal]) === true) {
                    if (tipoPago[0] == "0")
                        throw new Error("Forma de cobro no válida")
                    let empresaSesion = "Dental Perfiles";
                    if (imagen !== null) {
                        result = await postImagen(empresaSesion + '/imagenes/convenios/', imagen, 0);
                        if (!result) throw new Error("Ocurrio un error al intentar guardar la imagen");
                    }




                    /*!seleccion.tipoConvenio ? "Individual" : tipoConvenio*/

                    await addConvenio({
                        variables: {
                            convenio: {
                                empresa: empresa.trim(),
                                RFC: RFC.trim(),
                                tipoPago: formaCobroAux.toString(),
                                opcionesPago: Number(opcionesPago),
                                email: email.trim(),
                                fechaInicial: fechaInicial,
                                fechaFinal: fechaFinal,
                                urlLogo: result ? result : null,
                                tipoConvenio: tipoConvenio,
                                promotorID: promotorID
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }
            } catch ({ message: texto }) {
                await eliminarIMG(`public/kalenday${result}`);
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregar();
    }

    function ftModificar({ empresa, RFC, estatus, tipoPago, formaCobroAux, opcionesPago, email, fechaInicial, fechaFinal, imagen, urlLogo, tipoConvenio, promotorID, cleanStateFormulario }) {
        async function ftModificar() {
            try {
                let dataImagen;
                setDisabledAccept(true);
                let auxFechaIni = `${fechaInicial}T00:00:00`;
                let auxFechaFin = `${fechaFinal}T23:59:59`;
                if (ftAnioNoValido(auxFechaIni) || ftAnioNoValido(auxFechaFin)) throw new Error('El año no tiene un formato válido');
                if (!ftAniosRangosValidosAlterno(auxFechaIni) || !ftAniosRangosValidosAlterno(auxFechaFin)) throw new Error('El año capturado no se encuentra en el rango permitido');
                if (ftValidarObligatorios([empresa.trim(), RFC.trim(), tipoPago, opcionesPago, email.trim(), fechaInicial, fechaFinal])) {
                    if (!imagen && urlLogo) {
                        dataImagen = await ftRecuperarImagen(urlLogo);
                    }
                    let ruta = `public/kalenday${urlLogo}`;
                    result = await putImagen(ruta, dataImagen ? dataImagen : imagen);

                    await modConvenio({
                        variables: {
                            convenio: {
                                convenioId: Number(seleccion.convenioId),
                                empresa: empresa.trim(),
                                RFC: RFC.trim(),
                                estatus,
                                tipoPago: formaCobroAux ? formaCobroAux.toString() : seleccion.tipoPago.toString(),
                                opcionesPago: opcionesPago,
                                email: email.trim(),
                                fechaInicial: fechaInicial,
                                fechaFinal: fechaFinal,
                                urlLogo: result ? urlLogo : null,
                                // tipoConvenio: !seleccion.tipoConvenio ? "Individual" : tipoConvenio,
                                tipoConvenio: tipoConvenio,
                                promotorID: promotorID,
                            }
                        }
                    });
                    if (cleanStateFormulario) cleanStateFormulario();

                } else { throw new Error("Faltan valores obligatorios o no tienen el formato correcto"); }


            } catch ({ message: texto }) {
                setDisabledAccept(false);
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftModificar();
    }



    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteConvenio({ variables: { id: Number(seleccion.convenioId) } });
            await eliminarIMG(`public/kalenday${seleccion.urlLogo}`);
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftEnviarCorreos() {
        async function ftEnviarCorreo() {
            try {
                if (correos === "") {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Ingrese por lo menos un correo" });
                }
                else {
                    let { enviarCorreoUsuarioConvenio: respuesta } = await FetchGrahpQL({
                        query: NUEVO_REGISTRO_USUARIO_CONVENIO,
                        variables: { input: { correos, convenioID: Number(seleccion.convenioId) } }
                    });
                    setMensaje({ ...mensaje, ...mensajeExito, texto: respuesta });
                    setOpenCorreos(false);
                    setCorreos("");


                }
            } catch ({ message: texto }) {
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftEnviarCorreo();
    }

    function ftAgregarPromotor() {
        async function ftAgregarPromotor() {
            try {
                if (seleccion.promotor === "" || seleccion.comision === 0) {
                    setMensaje({ ...mensaje, ...mensajeError, texto: "Faltan datos obligatorios" });
                }
                else {
                    await FetchGrahpQL({
                        query: NUEVO_PROMOTOR,
                        variables: { input: { promotor: seleccion.promotor, comision: Number(seleccion.comision) } }
                    });
                    setMensaje({ ...mensaje, ...mensajeExito, texto: "Promotor agregado correctamente" });
                    setOpenPromotor(false);
                    setSeleccion(seleccionInicial);

                }
            } catch ({ message: texto }) {
                setMensaje({ ...mensaje, ...mensajeError, texto });
            }
        } ftAgregarPromotor();
    }

    function ftValidarObligatorios(campos) {
        if (campos[0] === '' || campos[0] === undefined || campos[0] === null) return "";
        if (campos[1] === '' || campos[1] === undefined || campos[1] === null) return "";
        if (campos[4] === '' || campos[4] === undefined || campos[4] === null) return "";

        return campos.every((valor) => (valor !== null && valor !== undefined));
    }

    function ftCloseCorreos() {
        setOpenCorreos(false);
        setCorreos("");
    }

    function ftClosePromotor() {
        setOpenPromotor(false);
        setSeleccion(seleccionInicial);
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setDisabledAccept(false);

        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    function stateOut(valorID = {}) {
        setSeleccion({
            ...seleccion,
            ...valorID
        });
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.log(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        async function ftEffect() {

            const fc = await queryFormaCobro();
            setFormaCobro(fc)
            let axfcs = fc.map(valor => ({
                descripcion: valor.label,
                clave: valor.value
            }));
            consultaPromotores();
            setFormatoFC(axfcs)
        }
        ftEffect();
    }, []);

    console.log("STATE:", seleccion);
    return (
        <MenuLateral titulo="Convenios" menu={menu}>

            <Dialog open={openImg}>
                <DialogTitle disableTypography style={{ textAlign: "Right" }}>
                    <IconButton aria-label="close" onClick={ftCloseImg} style={{ padding: 0 }}><CloseIcon /></IconButton>
                    <img src={`${rutaPublica}${seleccion.urlLogo}${'?'}${Math.random()}`} width={'100%'} />
                </DialogTitle>
            </Dialog>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />

            <Formulario style={{ margin: 0, padding: 1 }} abrir={formulario.abrir} campos={camposFormulario} titulo="Convenio" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} progress={false} />

            {
                openCorreos && <Formulario style={{ margin: 0, padding: 100, width: "100px", height: "100px" }} abrir={openCorreos} campos={inputEnviarCorreos} titulo="Enviar correo" onClose={ftCloseCorreos} onAccept={ftEnviarCorreos} valor={seleccion} datos={seleccion} disabledAccept={disabledAccept} cleanAwait={true} stateLimpio={seleccionInicial} stateOut={stateOut} progress={false} />
            }

            <Tabla titulo={"Listado de convenios"} columnas={columnas}
                datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["empresa", "RFC", "tipoPago", "opcionesPago", "email"]} />

            {
                openModal && <div>
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <Promotores />

                    </Modal>
                </div>
            }

        </MenuLateral>
    );

}

export default withRouter(Convenios)
