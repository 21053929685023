import gql from 'graphql-tag';

//#region HORARIOS
//=====================================
const LISTADO_HORARIO_CLINICA = gql`
	query ListadoHorarioClinica($limite: Int, $pagina: Int, $clinicaId: Int) {
		listadoHorarioClinica(limite: $limite, pagina: $pagina, clinicaId: $clinicaId) {
			HorariosClinicas {
				horarioId
				dias
				desde
				hasta
				clinicaId
				nombre
				clave
				estatusClinica
				matriz
				estatus
				ausencia
			}
            registros
            paginas
		}
	}
`;

const LISTADO_HORARIO_DOCTOR =gql`
query ListadoHorarioDoctor ($limite: Int, $pagina: Int, $clinicaId: Int){
  listadoHorarioDoctor(limite: $limite, pagina: $pagina, clinicaId: $clinicaId){
    HorariosDoctores{
      horarioId
      dias
      desde
      hasta
      clinicaDoctorId
      clinicaId
      nombreClinica
      usuarioId
      tipo
      nombres
      apellidos
      especialidadId
      mail
      estatus
      ausencia
    }
  }
}`;

const FILTRO_CLINICA_DOCTOR = gql `
query listadoDoctor($limite: Int!, $pagina: Int!,$clinicaId: Int) {
    listadoClinicaDoctor(limite: $limite, pagina: $pagina, clinicaId:$clinicaId) {
      registros
      paginas
      clinicaId
      clinicaDoctores {
        clinicaDoctorId
        clinicaId
        nombre
        doctorId
        nombres
        apellidos
        nombreCompleto
        email
        correo
      }
    }
  }`;
//#endregion

const HORARIO_DOCTOR_FETCH = `
    query HorarioDoctores {
        listadoHorarioDoctorCute {
            clinicaDoctorId
            nombreClinica
            nombres
            horarios {
                dia
                desde
                hasta
                ausencia
            }
        }
    }
`;

export {
    LISTADO_HORARIO_CLINICA,
    LISTADO_HORARIO_DOCTOR,
    FILTRO_CLINICA_DOCTOR,
    HORARIO_DOCTOR_FETCH
}
