import React, { useState, useContext, useEffect } from 'react';
// Apollo
import { SELECT_ESTATUS_FETCH, SELECT_CLINICAS_FETCH, SELECT_CLINICAS_DOCTOR_FETCH, SELECT_PACIENTES_FETCH, SELECT_SERVICIOS_FETCH, SELECT_CLINICAS_DOCTOR_SERVICIOS_FETCH, SELECT_CLINICA_USUARIO_FETCH, NO_AGENDAR_FETCH, SELECT_SERVICIOS_CLINICA_FETCH, SELECT_DOCTORES_SERVICIOS_FETCH }  from '../../../query/componentes/Agenda';
import { selectClientesActivos } from '../../PlanesCredito/Metodos';

// Context
import { CRMContext } from '../../../context/Agenda';
// Helpers
import { TipoUsuario } from '../../../Helpers/Enums';
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat } from '../../../Helpers';

import { Dialog, Grid } from '@material-ui/core';
import Input from '../../Reportes/componentes/Input';
import Contenido from '../../../componentesGenericos/Mensaje/componentes/Contenido';
import Titulo from '../../../componentesGenericos/Mensaje/componentes/Titulo';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';

import { limpiarEvento } from '../constantes';

import { rgxSelectID, rgxLetras, rgxNoVacio } from '../../../Helpers/constantes/Rgx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'

import uuid from 'react-uuid';

function CitaAgendada({campo}) {
    let componente;
    switch(campo.id) {
        case "clinicaID":
        case "clinicaDoctoID":
            let filtro = campo.opciones.filter(dato => { if(Number(dato.value) === Number(campo.valor)) return dato; });
            componente = <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                <Grid item xs={12}><Typography variant="h6">{campo.id === "clinicaID" ? "Sucursal:" : "Profesional:"}</Typography></Grid>
                <Grid item xs={12}><Typography variant="p">{ filtro[0] ? filtro[0].label : "" }</Typography></Grid>
            </Grid>;
            break;
        default: componente = null;
            break;
    }
    return <>{ componente }</>;
}

function IconoMensaje({tipo}) {
    let componente;
    switch(tipo) {
        case "warning": componente = <div
                className="swal2-icon swal2-warning swal2-icon-show"
                style={{ display: 'flex' }}
            >
                <div className="swal2-icon-content">!</div>
            </div>;
            break;
        default: componente = <div
                className="swal2-icon swal2-info swal2-icon-show"
                style={{ display: 'flex' }}
            >
                <div className="swal2-icon-content">i</div>
            </div>;
            break;
    }
    return <>{ componente }</>;
}

function Upsert({open, close, agregarEvento, actualizarEvento, eliminarEvento, GenerarReceta, disabledAccept, usuario, setEstatusPredeterminadoID, configuracion}) {

    let sesiones = process.env.REACT_APP_DB;


    const clases = {
        titulo: makeStyles(theme => ({
            main: {
                margin: 0,
                padding: theme.spacing(1),
                textAlign: 'center'
            },
            btnCerrar: {
                position: 'absolute',
                right: theme.spacing(1),
                top: theme.spacing(1),
                color: theme.palette.grey[500]
            }
        }))(),
        contenido: makeStyles(theme => ({
            root: {
                textAlign: 'center',
                padding: '5px 50px',
                margin: 0,

            }
        }))(),
        botones: makeStyles(theme => ({
            root: {
                padding: theme.spacing(1),
            },
            Btn: {
                margin: '0 auto'
            },
            BtnCancel: {
                marginRight: '10px'
            },
            BtnNew: {
                marginLeft: '10px'
            },
            contenedor: {
                margin: '0 auto',
                textAlign: 'center'
            }
        }))()
    };
    const [cargaCampos, setCarga] = useState({clinica:0, doctor:0, servicio:0});
    const [tituloCita, setTitulo] = useState('Agendar cita');

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(usuario.tipo),
    bTipoUsuario = TipoUsuario.USUARIO.isEqual(usuario.tipo),
    bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(usuario.tipo),
    // bTipoAdmin = TipoUsuario.ADMINISTRADOR.isEqual(usuario.tipo),

    espereUnMomento = { value: 0, label: "Espere un momento..." },
    sinRegistros = { value: 0, label: "No se encontraron registros"},

    defaultError = {
        clinicaID: false,
        clinicaDoctoID: false,
        servicioDoctorID: false,
        servicioID: false,
        pacienteID: false,
        estatusID: false,
        title: false
    },

    selectInicial = {
        clinicaID: 0,
        clinicaDoctorID: 0,
        servicioID: 0,
        doctorID: 0,
        servicioDoctorID: 0,
        readonlyDoctor: false,
        clinica: [espereUnMomento],
        disabledClinica: false,
        readonlyClinica: false,
        clinicaDoctor: [espereUnMomento],
        disabledClinicaDoctor: false,
        disabledServicios: false,
        paciente: [espereUnMomento],
        pacientesProps: {},
        readonlyPaciente: false,
        estatus: [espereUnMomento],
        servicio: [espereUnMomento],
        clinicaServicio: [sinRegistros],
        disabledClinicaServicio: false,
        readonlyClinicaServicio: false,
        servicioDoctor: [sinRegistros],
        disabledServicioDoctor: false,
        readonlyServicioDoctor: false,
        titulo: "Agendar cita"
    },

    [evento, setEvento] = useContext(CRMContext),

    [select, setSelect] = useState(selectInicial),

    [error, setError] = useState(defaultError),

    [noAgendar, setNoAgendar] = useState(false),

    limpiarSelect = {
        ...selectInicial,
        clinica: select.clinica,
        paciente: select.paciente,
        estatus: select.estatus,
        clinicaDoctor: [sinRegistros],
        servicio: [sinRegistros],
        clinicaServicio: [sinRegistros],
        servicioDoctor: [sinRegistros],
        clinicaID: 0,
        clinicaDoctorID: 0,
        servicioID: 0,
        doctorID: 0,
        servicioDoctorID: 0,
       // titulo: "Agendar cita limpiar",
        readonlyClinica: false,
        readonlyDoctor: false,
        readonlyPaciente: false,
        readonlyClinicaServicio: false,
        readonlyServicioDoctor: false
    },

    cleanStateFormulario = () => setSelect({ ...limpiarSelect }),
    cleanStateEvento = () =>  setEvento(limpiarEvento),
    cleanCarga = () => setCarga({clinica:0, doctor:0, servicio:0}),

    handleAccept = () => {
       if(evento.agendaID) {
           //actualizarEvento({ cleanStateFormulario,cleanStateEvento});
            console.log('aceptar');
            actualizarEvento({ cleanStateFormulario,cleanStateEvento});
            setCarga({clinica:0, doctor:0, servicio:0});

       }else {
           agregarEvento({ cleanStateFormulario, queryNoAgendar });
           setCarga({clinica:0, doctor:0, servicio:0});
        }
        //setCarga({clinica:0, doctor:0, servicio:0});
    },

    handleChangeClinica = ({value}, limpiar = {}) => {
        if(Number(select.clinicaID) !== Number(value)) {

           let info = { clinicaDoctorID: 0, doctorID: 0, clinicaID: value, ...limpiar };

            setSelect({
                ...select,
                ...info,
                disabledClinica: true,
                disabledClinicaDoctor: true,
                disabledServicios: true,
                disabledClinicaServicio: true,
                disabledServicioDoctor: true
            });
        }
    },

    handleChangeClinicaServicio = ({value}, limpiar = {}) => {
        if(Number(select.servicioID) !== Number(value)) {
            let info = { clinicaDoctorID: 0, doctorID: 0, servicioID: value, ...limpiar };

            setSelect({
                ...select,
                ...info,
                disabledClinica: true,
                disabledClinicaDoctor: true,
                disabledServicios: true,
                disabledClinicaServicio: true,
                disabledServicioDoctor: true
            });
        }
    },

    handleChangeDoctor = ({value}, limpiar = {}) => {
        if(Number(select.clinicaDoctorID) !== Number(value)) {
            let [doctor] = select.clinicaDoctor.filter((doctor) => {
                if(Number(doctor.value) === Number(value)) return doctor;
            });
            let info = { doctorID: doctor.doctorId, clinicaDoctorID: value, ...limpiar };
            setSelect({
                ...select,
                ...info,
                disabledClinica: true,
                disabledClinicaDoctor: true,
                disabledServicios: true,
                disabledClinicaServicio: true,
                disabledServicioDoctor: true
            });
        }
    },

    handleChangeDoctorServicio = ({value}, limpiar = {}) => {
        if(Number(select.clinicaDoctorID) !== Number(value)) {
            let [doctor] = select.servicioDoctor.filter((doctor) => {
                if(Number(doctor.value) === Number(value)) return doctor;
            });
            setEvento(evento => ({
                ...evento,
                servicioDoctorID: doctor ? doctor.servicioDoctorID : 0
            }));
        }
    },

    handleChangeEstatus = ({value}) => {
        if(Number(select.estatusID) !== Number(value)) {
            let [estatus] = select.estatus.filter((estatus) => {
                if(Number(estatus.value) === Number(value)) return estatus;
            });
            return { estatus: estatus.label };
        } else return {};
    },

    campos = [{
        id: "agendaID",
        texto: "Folio del evento",
        valor: evento.agendaID,
        tipo: !evento.agendaID ? "hidden" : "text",
        disabled: true
    },{
        id: bTipoDoctor ? "clinicaDoctoID" : "clinicaID",
        texto: "Seleccionar sucursal *",
        valor: bTipoDoctor ? evento.clinicaDoctoID : evento.clinicaID,
        tipo: "select",
        opciones: select.clinica,
        propiedades: ((bTipoPaciente && evento.agendaID) || evento.citaNoModificable) ? { readOnly: true } : { readOnly: select.readonlyClinica },
        onChange: handleChangeClinica,
        disabled: select.disabledClinica,
        cleanDatoState: bTipoDoctor ? [{ id: "servicioDoctorID", value: 0 }] : ((bTipoPaciente && !evento.agendaID) ? [{ id: "servicioID", value: 0 }] : [{ id: "clinicaDoctoID", value: 0 }, { id: "servicioDoctorID", value: 0 }]),
        ayuda: "Seleccione una sucursal válida",
        regexp: rgxSelectID,
        error: bTipoDoctor ? error.clinicaDoctoID : error.clinicaID,
        styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleSelect:{maxWidth:'480px',whiteSpace:'break-spaces'}
    },{
        id: "servicioID",
        texto: "Seleccionar el servicio *",
        valor: evento.servicioID,
        tipo: (bTipoPaciente && !evento.agendaID) ? "select" : "hidden",
        opciones: select.clinicaServicio,
        propiedades: {},
        onChange: handleChangeClinicaServicio,
        cleanDatoState: bTipoDoctor ? [{ id: "clinicaDoctoID", value: 0 }] : [],
        disabled: select.disabledClinicaServicio,
        ayuda: "Seleccione un servicio válido",
        regexp: rgxSelectID,
        error: error.servicioID
    },{
        id: bTipoDoctor ? "clinicaID" : "clinicaDoctoID",
        texto: "Seleccionar profesional *",
        valor: bTipoDoctor ? evento.clinicaID : evento.clinicaDoctoID,
        tipo: (bTipoDoctor ) ? "hidden" : "select",
        opciones: (bTipoPaciente && !evento.agendaID) ? select.servicioDoctor : select.clinicaDoctor,
        propiedades: {
            readOnly: (bTipoDoctor || (bTipoPaciente )  || evento.citaNoModificable) ? true : select.readonlyDoctor
        },
        onChange: bTipoDoctor ? () => {} : ((bTipoPaciente && !evento.agendaID) ? handleChangeDoctorServicio : handleChangeDoctor),
        disabled: bTipoPaciente ? true : select.disabledClinicaDoctor,
        cleanDatoState: bTipoDoctor ? [] : [{ id: "servicioDoctorID", value: 0 }],
        ayuda: "Seleccione un profesional válido",
        regexp: bTipoDoctor ? undefined : rgxSelectID,
        error: bTipoDoctor ? error.clinicaID : error.clinicaDoctoID,
        styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleSelect:{maxWidth:'480px',whiteSpace:'break-spaces'}
    },{
        id: "doctorID",
        valor: usuario.usuarioId,
        tipo: bTipoDoctor ? "select" : "hidden",
        opciones: [{ value: usuario.usuarioId, label: usuario.nombreCompleto }],
        propiedades: { readOnly: true }
    },{
        id: (bTipoPaciente && !evento.agendaID) ? "servicioDoctorID" : "id",
        valor: (bTipoPaciente && !evento.agendaID) ? evento.servicioDoctorID : null,
        tipo: "hidden",
        propiedades: { readOnly: true }
    },{
        id: (bTipoPaciente && !evento.agendaID) ? "id" : "servicioDoctorID",
        texto: "Seleccionar el servicio *",
        valor: (bTipoPaciente && !evento.agendaID) ? null : ((select.servicio.length > 0 && Number(select.servicio[0].value) > 0) ? evento.servicioDoctorID : null),
        tipo: (bTipoPaciente && !evento.agendaID) ? "hidden" : "select",
        opciones: select.servicio,
        propiedades: ((bTipoPaciente && evento.agendaID) || evento.citaNoModificable) ? { readOnly: true } : {},
        disabled: bTipoPaciente ? true : select.disabledServicios,
        ayuda: "Seleccione un servicio válido",
        multiselect: (bTipoPaciente && !evento.agendaID) ? false : true,
        regexp: rgxNoVacio,
        error: error.servicioDoctorID
    },{
        id: "pacienteID",
        texto: bTipoPaciente ? "Cliente" : "Seleccionar cliente *",
        valor: bTipoPaciente ? usuario.usuarioId : evento.pacienteID /*(!evento.pacienteID ? { label: "", value: 0 } : select.paciente.find(val => Number(val.value) === Number(evento.pacienteID)))*/,
        tipo: /*bTipoPaciente ?*/ "select" /*: "autocomplete"*/,
        opciones: bTipoPaciente ? [{ value: usuario.usuarioId, label: usuario.nombreCompleto }] : select.paciente,
        propiedades: {
            readOnly: ((bTipoPaciente || evento.citaNoModificable) ? true : select.readonlyPaciente)/*,
            ...select.pacientesProps*/
        },
        ayuda: "Seleccione un paciente válido",
        regexp: rgxSelectID,
        error: error.pacienteID,
        styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleSelect:{maxWidth:'480px',whiteSpace:'break-spaces'}
    },{
        id: "estatusID",
        texto: bTipoPaciente ? "Estatus del evento" : "Seleccionar estatus del evento *",
        valor: evento.estatusID,
        tipo: "select",
        opciones: select.estatus,
        propiedades: (bTipoPaciente || evento.citaNoModificable)  ? { readOnly: true } : {},
        ayuda: "Seleccione un estatus válido",
        regexp: rgxSelectID,
        error: error.estatusID,
        styleInput:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleMenuItem:{maxWidth:'480px',whiteSpace:'break-spaces'},
        styleSelect:{maxWidth:'480px',whiteSpace:'break-spaces'}
    },{
        id: "title",
        texto: "Título del evento *",
        valor: evento.title,
        tipo: "text",
        propiedades: (bTipoPaciente || evento.citaNoModificable) ? { readOnly: true } : {},
        ayuda: "La descripción del título sólo permite la captura de letras",
        regexp: rgxLetras,
        error: error.title
    },{
        id: "descripcion",
        texto: (bTipoPaciente && !evento.agendaID) ? "¿Cuál es la razón de su visita?" : "Descripción del evento",
        valor: evento.descripcion,
        tipo: (bTipoPaciente ? "hidden" : "textarea"),
        propiedades: ((bTipoPaciente && evento.agendaID) || evento.citaNoModificable) ? { readOnly: true } : {}
    }],

    queryClinica = () => {
        async function queryClinica() {
            try {
                let resultado = await FetchGrahpQL({
                    query: bTipoDoctor ? SELECT_CLINICAS_DOCTOR_FETCH : (bTipoUsuario ? SELECT_CLINICA_USUARIO_FETCH : SELECT_CLINICAS_FETCH),
                    variables: {
                        limite: 10000,
                        activas: true,
                        usuarioID: (bTipoUsuario ? Number(usuario.usuarioId) : null),
                        doctorID: (bTipoDoctor ? Number(usuario.usuarioId) : null),
                        clinicaID: null,
                        nombreDB: sesiones
                    }
                });

                let { listadoClinicas:{ clinicas } = {}, listadoClinicasPorDoctor, obtenerClinicasUsuario } = resultado;
                let campos = bTipoDoctor ? listadoClinicasPorDoctor : (bTipoUsuario ? obtenerClinicasUsuario : clinicas);

                return ftSelectFormat(campos, {
                    id: bTipoDoctor ? "clinicaDoctorId" : "clinicaId",
                    descripcion: "nombre"
                }, "Seleccionar sucursal");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinica();
    },

    queryClinicaDoctor = () => {
        async function queryClinicaDoctor() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_DOCTOR_SERVICIOS_FETCH,
                    variables: { limite: 10000, estatus: true, clinicaID: Number(select.clinicaID) }
                });

                let {listadoClinicasDoctorConServicios} = resultado;

                return ftSelectFormat(listadoClinicasDoctorConServicios, {
                    id: "clinicaDoctorId",
                    descripcion: "nombreCompleto",
                    otro: "doctorId"
                }, "Seleccionar profesional");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinicaDoctor();
    },

    queryClinicaDoctorID = () => {
        async function queryClinicaDoctorID() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_CLINICAS_DOCTOR_FETCH,
                    variables: { limite: 10000, doctorID: Number(usuario.usuarioId), clinicaID: Number(select.clinicaID) }
                });

                let { listadoClinicasPorDoctor } = resultado;
                let campos =  listadoClinicasPorDoctor;

                if(campos[0]) {
                    let { clinicaDoctorId:clinicaDoctoID } = campos[0];
                    setEvento(evento => ({
                        ...evento,
                        clinicaDoctoID
                    }));
                }

            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryClinicaDoctorID();
    },

    queryPaciente = () => {
        async function queryPaciente() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_PACIENTES_FETCH,
                    variables: { offset: 0, moroso:null }
                });

                let {listadoPaciente:{pacientes}} = resultado;

                return ftSelectFormat(pacientes, {
                    id: "usuarioId",
                    descripcion: "nombreCompleto"
                }, "Seleccionar cliente");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryPaciente();
    },

    queryNoAgendar = () => {
        async function queryNoAgendar() {
            try {
                let resultado = await FetchGrahpQL({
                    query: NO_AGENDAR_FETCH,
                    variables: { id: (bTipoPaciente ? Number(usuario.usuarioId) : 0) }
                });

                let { noAgendar = false } = resultado;

                setNoAgendar(noAgendar);

            } catch({message:label}) {
                console.error('No Agendar', label);
                setNoAgendar(false);
            }
        }
        return queryNoAgendar();
    },

    queryEstatus = () => {
        async function queryEstatus() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_ESTATUS_FETCH,
                    variables: { limite: 10000, predeterminado: (bTipoPaciente ? (Number(evento.agendaID) === 0 ? true : null) : null),
                        nombreDB: sesiones }
                });

                let {listadoEstatusCita:{estatusCitas}} = resultado;

                if(bTipoPaciente) {
                    let id = 0, estatus = '';
                    estatusCitas.forEach(({ estatusID, estatusPredeterminado, descripcionEstatus, citaNoModificable }) => {
                        if(estatusPredeterminado && citaNoModificable == false) {
                            id = estatusID;
                            estatus = descripcionEstatus;
                        }
                    });
                    setEstatusPredeterminadoID(id);
                    setEvento(evento => ({
                        ...evento,
                        estatus
                    }));
                }

                return ftSelectFormat(estatusCitas, {
                    id: "estatusID",
                    descripcion: bTipoPaciente ? "descripcionEstatus"
                        : { valor: data => (`${data["descripcionEstatus"]}${data["citaNoModificable"] ? " (La cita no podrá modificarse)" : (data["horaNoModificable"] ? " (El horario no puede modificarse)" : "")}`) },
                    otro: [{ id: "colorEstatus", alias: "color" }]
                }, "Seleccionar estatus del evento");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryEstatus();
    },

    queryServicio = () => {
        async function queryServicio() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_SERVICIOS_FETCH,
                    variables: { doctorID: bTipoDoctor ? Number(usuario.usuarioId) : Number(select.doctorID), clinicaDoctoID: Number(evento.clinicaDoctoID), clinicaID: Number(evento.clinicaID), disponible: true, agendable: true,
                        nombreDB: sesiones  }
                });

                let {listadoServiciosDoctoresPorDoctor:serviciosDoctores} = resultado;

                return ftSelectFormat(serviciosDoctores, {
                    id: "relacionId",
                    descripcion: "descripcion"
                });
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryServicio();
    },

    queryServicioClinica = () => {
        async function queryServicioClinica() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_SERVICIOS_CLINICA_FETCH,
                    variables: { clinicaID: Number(evento.clinicaID),
                        nombreDB: sesiones }
                });

                let {listadoServiciosPorClinica:serviciosDoctores} = resultado;

                return ftSelectFormat(serviciosDoctores, {
                    id: "servicioId",
                    descripcion: "descripcion",
                    otro: "porDefecto"
                });
            } catch({message:label})
            { return [{ value: 0, label }]; }
        }
        return queryServicioClinica();
    },

    queryDoctoresServicios = (valor) => {
        async function queryDoctoresServicios() {
            try {
                let resultado = await FetchGrahpQL({
                    query: SELECT_DOCTORES_SERVICIOS_FETCH,
                    variables: { clinicaID: Number(evento.clinicaID), servicioID: evento.servicioID ? Number(evento.servicioID) : Number(valor),  nombreDB: sesiones  }
                });

                let {ObtenerDoctoresServicio:{serviciosDoctores:doctores}} = resultado;

                return ftSelectFormat(doctores, {
                    id: "clinicaDoctorId",
                    descripcion: "nombreCompleto",
                    otro: [{ id: "relacionId", alias: "servicioDoctorID"},{id: "porDefecto", alias: "porDefecto" },
                    {id: "usuarioId", alias: "doctorId"}
                    ]
                }, "Seleccionar el profesional");
            } catch({message:label}) { return [{ value: 0, label }]; }
        }
        return queryDoctoresServicios();
    },

    { generarReceta:activarReceta = false } = configuracion,

    botones = [];

    if(!bTipoPaciente) botones.push({
        Color: "secondary",
        EventoClick: eliminarEvento,
        Texto: "Eliminar",
        Disabled: {
            agregar: true,
            modificar: bTipoPaciente,
            variable: evento.agendaID
        }
    });

    if(activarReceta && bTipoDoctor) botones.push({
        Color: "primary",
        EventoClick: GenerarReceta,
        Texto: "Generar Receta",
        Disabled: {
            agregar: true,
            modificar: !(bTipoDoctor && evento.generarReceta),
            variable: evento.agendaID
        }
    });

    function handleChange({target}, id, multiselect, onChange, cleanDatoState, val, validarRegexp) {

        let { value } = target, limpiar = {}, estatus = {};

        if(Array.isArray(cleanDatoState) && evento[id] != value) cleanDatoState.forEach(({id, value}) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
        });

        if(id === "estatusID") estatus = handleChangeEstatus(target);
        let valor = (val && !multiselect && (target.type || target.type === "")) ? val.value : value;
        setEvento(evento => ({
            ...evento,
            ...limpiar,
            ...estatus,
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : valor === undefined ? "" : valor
        }));

        if(onChange) onChange(target, limpiar);
        validarRegexp({ id, value, setError });
    }

    function handleClose() {
        close();
        setTimeout(() => {
            setError(defaultError);
            cleanStateFormulario();
            setEvento(limpiarEvento);
        }, 500);
        setCarga({clinica:0, doctor:0, servicio:0});
    }

    useEffect(() => {
        let titulo = 'Agendar cita';
        if(Number(evento.agendaID) > 0){
            titulo = evento.citaNoModificable ? "Historial" : (bTipoPaciente
                ? ((Number(usuario.usuarioId) !== Number(evento.pacienteID) && Number(evento.pacienteID) !== 0) ? "¡Horario no disponible!" : "Cita") : "Modificar cita");

            setSelect({
            ...select,
            clinicaID: evento.clinicaID,
            clinicaDoctorID: evento.clinicaDoctorID,
            doctorID: evento.doctorID,
            servicioID: evento.servicioID,
            titulo: evento.citaNoModificable ? "Historial" : (bTipoPaciente
                ? ((Number(usuario.usuarioId) !== Number(evento.pacienteID) && Number(evento.pacienteID) !== 0) ? "¡Horario no disponible!" : "Cita") : "Modificar cita"),
            readonlyClinica: true,
            readonlyPaciente: true,
            readonlyDoctor: evento.citaNoModificable ? true : false,
            disabledClinicaServicio: true,
            disabledServicioDoctor: true
            });

        }
        else setSelect({ ...limpiarSelect });
        setTitulo(titulo);
    }, [evento.agendaID]);

    useEffect(() => {
        async function ftEffect() {
            let clinicaDoctor = null, servicio = null, estatus = null, clinicaServicio = null, servicioDoctor = null;
            let actualizar = true;
            let valor = cargaCampos.servicio;
            let itemServ = 0;
            let itemDr = 0;

            if(bTipoPaciente) {
                estatus = await queryEstatus();
                if(select.clinicaID) clinicaServicio = await queryServicioClinica();

                if (clinicaServicio !== null ) {
                    itemServ = clinicaServicio.find(opcion => opcion.porDefecto === 1);

                    itemServ = itemServ ? itemServ.value : "0";
                }
                if(select.servicioID || itemServ > 0)
                  servicioDoctor = await queryDoctoresServicios(itemServ);

                if (servicioDoctor !== null ) {
                    itemDr = servicioDoctor.find(opcion => opcion.porDefecto === 1);
                }

            }
            if(bTipoDoctor) {
                await queryClinicaDoctorID();
                servicio = await queryServicio();

            } else {
                if(select.clinicaID) clinicaDoctor = await queryClinicaDoctor();
                if(select.doctorID) servicio = await queryServicio();

                console.log('clinica', clinicaDoctor, 'serv', servicio)
            }

            if(evento.agendaID > 0){
                if (cargaCampos.servicio <4){

                    valor = valor + 1;
                    setCarga((cargaCampos)=>({...cargaCampos, servicio:valor}));

                    setSelect({
                    ...select,
                    disabledClinica: false,
                    disabledClinicaDoctor: false,
                    disabledServicios: false,
                    disabledClinicaServicio: false,
                    disabledServicioDoctor: false,
                    clinicaDoctor: clinicaDoctor ? clinicaDoctor : [sinRegistros],
                    clinicaServicio: clinicaServicio ? clinicaServicio : [sinRegistros],
                    servicio: servicio ? servicio : [sinRegistros],
                    servicioDoctor: select.servicioID ? (servicioDoctor ? servicioDoctor : [sinRegistros]) : [sinRegistros],
                    estatus: estatus ? estatus : select.estatus
                    });
                }
            }
            else{

                 setSelect({
                    ...select,
                    disabledClinica: false,
                    disabledClinicaDoctor: bTipoPaciente ? true : false,
                    disabledServicios: false,
                    disabledClinicaServicio: bTipoPaciente ? true : false,
                    disabledServicioDoctor: bTipoPaciente ? true : false,
                    clinicaDoctor: clinicaDoctor ? clinicaDoctor : [sinRegistros],
                    clinicaServicio: clinicaServicio ? clinicaServicio : [sinRegistros],
                    servicio: servicio ? servicio : [sinRegistros],
                    servicioDoctor: select.servicioID || itemServ ? (servicioDoctor ? servicioDoctor : [sinRegistros]) : [sinRegistros],
                    estatus: estatus ? estatus : select.estatus
                    });
                }

            if (bTipoPaciente && evento.agendaID == 0){
                let clinicaDoctoId = "0";
                let doctor = "0";
                let servDr ="0";

                if (itemDr !== undefined)
                {
                   clinicaDoctoId = servicioDoctor !== null ? itemDr.value.toString() : "0";
                   doctor = servicioDoctor !== null ? itemDr.doctorId.toString() : "0";
                   servDr = servicioDoctor !== null ? itemDr.servicioDoctorID.toString() : "0";
                }
                setEvento(evento => ({
                ...evento,
                servicioID : clinicaServicio !== null ? (itemServ) : 0,
                clinicaDoctoID: clinicaDoctoId,
                doctorID: doctor,
                servicioDoctorID: servDr
                }));

            }else{
                setEvento(evento => ({
                ...evento,
                doctorID: select.doctorID
                }));
            }
        }
        ftEffect();
    }, [select.clinicaID, select.doctorID, select.servicioID]);

    useEffect(() => {
        async function ftEffect() {
            const clinica = await queryClinica();

            const clinicaDoctor = await queryClinicaDoctor();
            const paciente = await selectClientesActivos("Seleccionar cliente");//queryPaciente();
            const estatus = await queryEstatus();
            const servicio = await queryServicio();

            const clinicaServicio = await queryServicioClinica();
            const servicioDoctor = await queryDoctoresServicios(0);
            setSelect({
                ...select,
                clinica,
                clinicaDoctor,
                paciente,
                estatus,
                servicio,
                clinicaServicio,
                servicioDoctor,
                pacientesProps: bTipoPaciente ? {} : {
                    options: paciente,
                    getOptionLabel: option => option.label
                }
            });
        }
        ftEffect();
    }, []);

    useEffect(() => {
        async function ftEffect() { if(bTipoPaciente) await queryNoAgendar(); }
        ftEffect();
    }, [bTipoPaciente]);

    return(
        <>
            <Dialog open={open} aria-labelledby="dialog-title" onCancel={()=>console.log("")}>
                {
                    (bTipoPaciente && noAgendar && Number(evento.agendaID) === 0) ? <Contenido texto={"texto"} color={"inherit"} tipo={"dialogo"} classes={clases.contenido}>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                            <Grid item xs={12}>
                                <IconoMensaje tipo="warning" />
                            </Grid>
                            <Titulo texto={tituloCita} onClose={handleClose} classes={clases.titulo} />
                            <Grid item xs={12}>
                                <><Typography variant="p">¡No tiene permisos para agendar citas!</Typography></>
                            </Grid>
                        </Grid>
                        <br />
                    </Contenido> : (bTipoPaciente && Number(usuario.usuarioId) !== Number(evento.pacienteID) && Number(evento.pacienteID) !== 0
                    ? <Contenido texto={"texto"} color={"inherit"} tipo={"dialogo"} classes={clases.contenido}>
                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                            <Grid item xs={12}>
                                <IconoMensaje tipo="info" />
                            </Grid>
                            <Titulo texto={tituloCita} onClose={handleClose} classes={clases.titulo} />
                            <Grid item xs={12}>
                                <>{ campos.map(campo => (<CitaAgendada campo={campo} key={uuid()} />)) }</>
                            </Grid>
                        </Grid>
                        <br />
                    </Contenido>
                    : <>
                        <Titulo texto={tituloCita} onClose={handleClose} classes={clases.titulo} />
                        <Contenido texto={"texto"} color={"inherit"} tipo={"formulario"} classes={clases.contenido}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                <Input
                                    campos={campos}
                                    handleChange={handleChange}
                                    state={evento}
                                />
                            </Grid>
                        </Contenido>
                        <Botones
                            tipo="multiopcion"
                            txtBotones={{ btnAceptar: "Aceptar" }}
                            aBotones={botones}
                            classes={clases.botones}
                            onAccept={handleAccept}
                            onCancel={handleClose}
                            disabledAccept={evento.citaNoModificable ? (Number(evento.citaNoModificable) === 1) : (bTipoPaciente ? (Number(evento.agendaID) > 0 ? true : disabledAccept) : disabledAccept)}
                            progress={evento.citaNoModificable ? !evento.citaNoModificable : (bTipoPaciente && Number(evento.agendaID) > 0 ? false : true)}
                        />
                    </>)
                }
            </Dialog>
        </>
    )
}

export default Upsert;
