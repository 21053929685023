import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Agregar from './Formularios/Agregar';
import { CRMContext } from '../../../context/ConveniosUsuarios';
//redux
import { useDispatch } from "react-redux";
import { CRUD } from "../../../Redux/Actions/ConveniosUsuariosAction";
//helper
import { conveniosUsuario } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function AgregarRelacion(props) {

    //Declaraciones
    const [boton,setBoton] = useState(false);
    let { open, ftClose,actualiza } = props;
    const [conveniosUsuarios, guardarConveniosUsuarios] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(conveniosUsuario);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const Crud = (tipo, evento, datos,desactivar,cerrar) => dispatch(CRUD(tipo, evento, datos,desactivar,cerrar));

    //Eventos
    const handleClickGuardar = async () => {
        deshabilitarBoton(true);
        let campos = [
            "convenioId",
            "usuarioId"
        ];
        let datosV = verificaValores(campos, conveniosUsuarios.Convenios,[["convenioId","convenio"],["usuarioId","cliente"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            deshabilitarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(0, actualiza, conveniosUsuarios.Convenios,(n)=>  deshabilitarBoton(n),()=>ftClose());
            limpiarMensaje();
        }
    }
    //FUNCIONES
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }
    const deshabilitarBoton = (tipo) => {
        setBoton(tipo)
    }

    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        guardarConveniosUsuarios({
                Convenios: {
                    convenioId: { error: false, valor: "" },
                    convenioUsuarioId: { error: false, valor: "" },
                    empresaConvenio: { error: false, valor: "" },
                    usuarioId: { error: false, valor: "" },
                    nombreCompleto: { error: false, valor: "" },
                    email: { error: false, valor: "" },
                }
        })
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>{`Asignar convenio a clientes`}</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Agregar  ftClose={ftClose}/>
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cancelar
             </Button>
                    <Button
                        onClick={handleClickGuardar}
                        disabled={boton}
                        color="primary">
                        GUARDAR
                      </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default AgregarRelacion;
