import { SELECCIONAR_TURNO_ACTUAL } from '../Types';

const initialState = {
    seleccionTurno: [{ turnoId: false }]
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SELECCIONAR_TURNO_ACTUAL:
            return {
                ...state,
                seleccionTurno: [action.payload]
            }
        default:
            return state;
    }
}
