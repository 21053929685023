import React, { useContext, useEffect, useState } from 'react';
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, FormHelperText, MenuItem, FormControlLabel, TextareaAutosize } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/AutorizarDatos';
//Fetch
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_ESPECIALIDAD_DOCTOR_CADENA } from '../../../../query';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Paciente(props) {

    //Declaraciones
    let { seleccion } = props;
    const classes = useStyles();
    const [datos, guardarDatos] = useContext(CRMContext);
    const [especialidades, guardarEspecialidades] = React.useState('');
    const [genero, setGenero] = useState('');
    //Eventos

    //Funciones
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };
    const ftGenerarSelectEspecialidades = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.especialidadesDoctores.map(function (x) {
                row.push(<MenuItem key={x.especialidadId} value={x.especialidadId} readOnly>{x.descripcion}</MenuItem>);
            });
            return row;
        }
    };

    //Api
    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }
    const obtenerEspecialidadesApi = async () => {
        await FetchGrahpQL({
            query: LISTADO_ESPECIALIDAD_DOCTOR_CADENA,
            variables: {
                offset: 0
            }
        })
            .then(resp => {
                guardarEspecialidades({ datos: resp.listadoEspecialidadDoctor })
            })
            .catch((err) => {
            });
    }


    //Ciclo de vida
    useEffect(() => {
        obtenerEspecialidadesApi();
        guardarDatos({
            ...datos,
            Usuario: {
                ...datos.Usuario,
                datosUsuariosAutorizacionId: {
                    error: false, valor: seleccion.datosUsuariosAutorizacionId
                },
                usuarioId_nuevo:
                {
                    error: false, valor: seleccion.usuarioId_nuevo
                },
                nombres_nuevo: {
                    error: false, valor: seleccion.nombres_nuevo
                },
                apellidos_nuevo: {
                    error: false, valor: seleccion.apellidos_nuevo
                },
                email_nuevo: {
                    error: false, valor: seleccion.email_nuevo
                },
                cedula_nuevo: {
                    error: false, valor: seleccion.cedula_nuevo
                },
                fechaEgreso_nuevo: {
                    error: false, valor: seleccion.fechaEgreso_nuevo
                },
                estatus_nuevo: {
                    error: false, valor: seleccion.estatus_nuevo
                },
                especialidadId_nuevo: {
                    error: false, valor: seleccion.especialidadId_nuevo
                },
                sexo_nuevo: {
                    error: false, valor: seleccion.sexo_nuevo
                },
                telefono_nuevo: {
                    error: false, valor: seleccion.telefono_nuevo
                },
                fechaNacimiento_nuevo: {
                    error: false, valor: seleccion.fechaNacimiento_nuevo
                },
                autorizacion: {
                    error: false, valor: seleccion.autorizacion
                },
                solicitanteId: {
                    error: false, valor: seleccion.solicitanteId
                },
                nombre_actual: {
                    error: false, valor: seleccion.nombre_actual
                },
                apellidos_actual: {
                    error: false, valor: seleccion.apellidos_actual
                },
                email_actual: {
                    error: false, valor: seleccion.email_actual
                },
                cedula_actual: {
                    error: false, valor: seleccion.cedula_actual
                },
                fechaEgreso_actual: {
                    error: false, valor: seleccion.fechaEgreso_actual
                },
                estatus_actual: {
                    error: false, valor: seleccion.estatus_actual
                },
                especialidadId_actual: {
                    error: false, valor: seleccion.especialidadId_actual
                },
                sexo_actual: {
                    error: false, valor: seleccion.sexo_actual
                },
                telefono_actual: {
                    error: false, valor: seleccion.telefono_actual
                },
                fechaNacimiento_actual: {
                    error: false, valor: seleccion.fechaNacimiento_actual
                },
                tipo: {
                    error: false, valor: seleccion.tipo
                },
                nombreCompletoSolicitante: {
                    error: false, valor: seleccion.nombreCompletoSolicitante
                },
                correoSolicitante: {
                    error: false, valor: seleccion.correoSolicitante
                },
                tipoUsuarioSolicitante: {
                    error: false, valor: seleccion.tipoUsuarioSolicitante
                },
                nombreCompletoAfectado: {
                    error: false, valor: seleccion.nombreCompletoAfectado
                },
                password_nuevo_estatus: {
                    error: false, valor: seleccion.password_nuevo_estatus
                },

            }
        });
        FetchGenero();
    }, []);

    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre_n`} label="Nombre" type="text" style={{ width: "100%" }} required={true} helperText="El nombre actualizado" error={datos.Usuario.nombres_nuevo.error} value={datos.Usuario.nombres_nuevo.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre_n1`} label="Nombre" type="text" style={{ width: "100%" }} required={true} helperText="El nombre actual" error={datos.Usuario.nombre_actual.error} value={datos.Usuario.nombre_actual.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido_n1`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido actualizado" error={datos.Usuario.apellidos_nuevo.error} value={datos.Usuario.apellidos_nuevo.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido_n`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido actual" error={datos.Usuario.apellidos_actual.error} value={datos.Usuario.apellidos_actual.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id={`email_n`} label="Email" type="text" style={{ width: "100%" }} error={datos.Usuario.email_nuevo.error} value={datos.Usuario.email_nuevo.valor} required={true} helperText="El email actualizado" disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="email" id={`email_n1`} label="Email" type="text" style={{ width: "100%" }} error={datos.Usuario.email_actual.error} value={datos.Usuario.email_actual.valor} required={true} helperText="El email actual" disabled />
                        </Grid>
                        <Grid item xs={12}>

                            {datos.Usuario.password_nuevo_estatus.valor === 1 ?
                                <TextareaAutosize rows={1} rowsMax={1} style={{ width: "100%", resize: "none" }} aria-label="empty textarea" placeholder={"Ha modificado la contraseña"} disabled />
                                : <TextareaAutosize rows={1} rowsMax={1} style={{ width: "100%", resize: "none" }} aria-label="empty textarea" placeholder={"No ha modificado la contraseña"} disabled />
                            }

                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="telefono" id={`telefono_n1`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono actualizado" error={datos.Usuario.telefono_nuevo.error} value={datos.Usuario.telefono_nuevo.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="telefono" id={`telefono_n`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono actual" error={datos.Usuario.telefono_actual.error} value={datos.Usuario.telefono_actual.valor} disabled />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    labelId={`genero_n`}
                                    id={`genero_n`}
                                    name="sexo"
                                    required={true}
                                    value={datos.Usuario.sexo_nuevo.valor}
                                    disabled
                                >
                                    <MenuItem key={'-1'} selected value={'null'} disabled>{'Escoge el género '}</MenuItem>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género actualizado</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    labelId={`genero_n2`}
                                    id={`genero_n2`}
                                    name="sexo"
                                    required={true}
                                    value={datos.Usuario.sexo_actual.valor}
                                    disabled
                                >
                                    <MenuItem key={'-1'} selected value={'null'} disabled>{'Escoge el género '}</MenuItem>
                                    {ftGenerarSelect(genero.datos)}
                                </Select>
                                <FormHelperText>El género actual</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    disabled
                                    required={true}
                                    style={{ width: "100%" }}
                                    name="fechaNacimiento"
                                    id="date_n1"
                                    helperText="La fecha de nacimiento actualizada"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    className={classes.textField}
                                    error={datos.Usuario.fechaNacimiento_nuevo.error}
                                    value={datos.Usuario.fechaNacimiento_nuevo.valor != null ? (datos.Usuario.fechaNacimiento_nuevo.valor).split("T")[0] : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    disabled
                                    required={true}
                                    style={{ width: "100%" }}
                                    name="fechaNacimiento"
                                    id="date_n2"
                                    helperText="La fecha de nacimiento actual"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    className={classes.textField}
                                    error={datos.Usuario.fechaNacimiento_actual.error}
                                    value={datos.Usuario.fechaNacimiento_actual.valor != null ? (datos.Usuario.fechaNacimiento_actual.valor).split("T")[0] : ""}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} />
                            </form>
                        </Grid>



                    </Grid>
                </div>
            } Titulo="Datos generales"></Fieldset>



{
    datos.Usuario.tipo.valor === 1 ?
            <Fieldset contenido={
                <div>
                            <Grid spacing={3} container direction="row">
                             <Grid item xs={12}>
                                <TextField margin="dense" name="cedula" id={`cedula`} label="Cédula " type="text" style={{ width: "100%" }} required={true} helperText="La cédula actualizada" error={datos.Usuario.cedula_nuevo.error} value={datos.Usuario.cedula_nuevo.valor} disabled />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="cedula" id={`cedula_n1`} label="Cédula " type="text" style={{ width: "100%" }} required={true} helperText="La cédula actual" error={datos.Usuario.cedula_actual.error} value={datos.Usuario.cedula_actual.valor} disabled />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="fechaEgreso_actual" id={`fechaEgreso_actual2`} label="Fecha de egreso  " type="text" style={{ width: "100%" }} required={true} helperText="La fecha de egreso  actualizada" error={datos.Usuario.fechaEgreso_nuevo.error}
                                    value={datos.Usuario.fechaEgreso_nuevo.valor != null ? (datos.Usuario.fechaEgreso_nuevo.valor).split("T")[0] : ""}
                                    disabled />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField margin="dense" name="fechaEgreso_actual" id={`Fecha egreso_n1`} label="Fecha de egreso " type="text" style={{ width: "100%" }} required={true} helperText="La fecha de egreso  actual" error={datos.Usuario.fechaEgreso_actual.error}
                                    value={datos.Usuario.fechaEgreso_actual.valor != null ? (datos.Usuario.fechaEgreso_actual.valor).split("T")[0] : ""}
                                    disabled />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                    <InputLabel id="demo-simple-select-label" required={true}>Especialidad</InputLabel>
                                    <Select
                                        error={datos.Usuario.especialidadId_nuevo.error}
                                        labelId={`especialidad`}
                                        id={`especialidad`}
                                        disabled
                                        name="especialidadId"
                                        required={true}
                                        value={datos.Usuario.especialidadId_nuevo.valor}
                                    >
                                        <MenuItem key={'-1'} value={''} disabled>{'Escoge la especialidad'}</MenuItem>
                                        {ftGenerarSelectEspecialidades(especialidades.datos)}
                                    </Select>
                                    <FormHelperText>La especialidad actualizada</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                    <InputLabel id="demo-simple-select-label" required={true}>Especialidad</InputLabel>
                                    <Select
                                        error={datos.Usuario.especialidadId_actual.error}
                                        labelId={`especialidad`}
                                        id={`especialidad`}
                                        disabled
                                        name="especialidadId"
                                        required={true}
                                        value={datos.Usuario.especialidadId_actual.valor}
                                    >
                                        <MenuItem key={'-1'} value={''} disabled>{'Escoge la especialidad'}</MenuItem>
                                        {ftGenerarSelectEspecialidades(especialidades.datos)}
                                    </Select>
                                    <FormHelperText>La especialidad actual</FormHelperText>
                                </FormControl>
                            </Grid>


                        </Grid>
                </div>
            } Titulo="Datos específicos"></Fieldset>
            : ""
        }
        </>

    );

}

export default Paciente;

