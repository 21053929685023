import React from 'react';
// Gráficas
import { Pie } from 'react-chartjs-2';
import { Colores } from '../../componentes/EstatusAgenda/Colores.js';

const GraficaPie = ({ text = "Gráfica", total = 0, data = [] }) => {

    const listadoColor = { ...Colores };
    delete listadoColor[0];

    const keys = Object.keys(listadoColor);
    const color = keys.map(key => (listadoColor[key]));

    const backgroundColor = data.map((info, index) => (color[index - (15*Math.floor(index/15))].color));
    const hoverBackgroundColor = data.map((info, index) => (color[index - (15*Math.floor(index/15))].hover));

    const datos = {
        labels: data.map(({label}) => (label)),
        datasets: [{
            data: data.map(({angle}) => (angle)),
            backgroundColor,
            hoverBackgroundColor
        }]
    };

    const options = {
        legend: { display: data.length < 5 },
        // maintainAspectRatio: false,
        animation: false,
        responsive: true,
    };

    return (<>
        <br />
        <div style={{ textAlign: "center" }}><b>{text}</b></div>
        <Pie data={datos} options={options} />
        <br />
    </>);
};

export default GraficaPie;
