import React, { useContext, useState, useEffect } from 'react';
//Core
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '../../../componentesGenericos/Core';
import Paciente from './Formulario/Paciente';
import { CRMContext } from '../../../context/Usuarios/Usuarios';
//redux
import { useDispatch,useSelector } from "react-redux";
import { CRUD } from "../../../Redux/Actions/UsuariosAction";
//helper
import { limpiarStateGeneralUsuarios } from '../../../Helpers/constantes/State';
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
import { agregarUsuario } from './../../../Helpers/constantes/State';
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
function Agregar(props) {

    //Declaraciones
    const { actualiza,seleccion,index } = props;
    let { open, ftClose,setSeleccion } = props;
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [getNotificacion, setNotificacion] = useState(agregarUsuario);

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = user.usuario[0].user.user.token;
    let userToken = DecodificarToken(token);
    const Crud = (categoria, tipo, evento, datos,desactivar,cerrar) => dispatch(CRUD(categoria, tipo, evento, datos,desactivar,cerrar));

    //Eventos
    const handleClickUsuario = () => {
        ActivarDesactivarBoton(true);
        let campos = [
            "email",
            "usuarioId",
            "nombres",
            "apellidos",
            "email",
            "sexo",
            "telefono",
            "fechaNacimiento"
        ];
        usuarios.Paciente.tipo= { error: false, valor: userToken.tipo};
        usuarios.Paciente.solicitanteId= { error: false, valor: userToken.usuarioId};
        let datosV = verificaValores(campos, usuarios.Paciente,[["sexo","género"],["fechaNacimiento","fecha de nacimiento"],["telefono","teléfono"]]);
        let mensaje = formatearCadena(datosV.arr);
        if (datosV.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 3,
                mensaje: ""
            });
            Crud(1, "Paciente", actualiza, usuarios.Paciente,(n)=>  ActivarDesactivarBoton(n),ftClose);
            limpiarMensaje();
        }

    }

       //Funciones
    const limpiarMensaje = () => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
    }

    const ActivarDesactivarBoton = (tipo) => {
        guardarUsuarios({
            ...usuarios,
            Paciente: {
                ...usuarios.Paciente,
                desactivarBoton: {
                    ...usuarios.Paciente.desactivarBoton,
                    error: tipo, valor: ''
                }
            }
        });
    }

    //Ciclo de vida
    useEffect(() => {
        setNotificacion({
            ...getNotificacion,
            error: 1,
            mensaje: ""
        });
        guardarUsuarios(limpiarStateGeneralUsuarios);
        index(-1)
    }, [open]);

    return (
        <>
            <Dialog maxWidth="sm" open={open}
                onClose={ftClose}
                aria-labelledby="form-dialog-title">
                {/* Titulo del modal*/}
                <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>Modificar cliente</DialogTitle>
                {/*formulario*/}
                <DialogContent>
                <a name="arriba"><p style={{display:"none"}}></p></a>
                    {GenerarNotificacionv2(getNotificacion)}
                    <Paciente seleccion={seleccion}/>
                </DialogContent>
                {/*botones del modal*/}
                <DialogActions>
                    <Button
                        onClick={ftClose}
                        color="primary">
                        Cerrar
             </Button>
             <a href="#arriba" style={{textDecoration: "none"}}>
                    <Button
                           disabled={usuarios.Paciente.desactivarBoton.error}
                        onClick={handleClickUsuario}
                        color="primary">
                        GUARDAR
                      </Button>
                      </a>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default Agregar;
