import gql from 'graphql-tag';

//#region Garantias
//============Garantias==================
export const NUEVA_GARANTIA = gql`
	mutation nuevoGarantia($garantia: GarantiaInput!) {
		nuevoGarantia(input: $garantia) {
			garantiaId
			garantia
			diasGarantia
			fechaAlta
		}
	}
`;

export const MODIFICAR_GARANTIA = gql`
	mutation modificarGarantia($garantia: GarantiaInput!) {
		modificarGarantia(input: $garantia) {
			garantiaId
			garantia
			diasGarantia
			fechaAlta
		}
	}
`;

export const ELIMINAR_GARANTIA = gql`
	mutation eliminarGarantia($id: Int!) {
		eliminarGarantia(id: $id)
	}
`;

//#endregion
//==========================================
