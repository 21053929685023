import React, { useEffect } from 'react'

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { SeleccionClinica as SeleccionClinicaId } from "../../Redux/Actions/SeleccionClinicaAction";
import { TipoUsuario } from '../../Helpers/Enums';

//Queries
import { SELECT_CLINICAS_DOCTOR_FETCH, SELECT_CLINICAS_FETCH, SELECT_CLINICA_USUARIO_FETCH } from '../../query';

//Componentes
import { Select, MenuItem } from '../../componentesGenericos/Core';
import { DecodificarToken } from "../../componentesGenericos/Estructura/Autenticar";
//Helper
import { obtenerStateStorage, guardarStateStorage } from '../../Helpers/LocalStorage/LocalStorage';
import { FetchGrahpQL } from '../../Helpers/Fetch/FetchGraphql';
import { ftSelectFormat } from '../../Helpers';

export default function SeleccionClinica({ id, valor, opciones = null, xs = 12, ...otros }) {
    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const seleccionarClinica = (datos) => dispatch(SeleccionClinicaId(datos));

    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let userToken = DecodificarToken(userState);

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(userToken.tipo),
        bTipoUsuario = TipoUsuario.USUARIO.isEqual(userToken.tipo),
        bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(userToken.tipo),
        bTipoAdmin = TipoUsuario.ADMINISTRADOR.isEqual(userToken.tipo);

    const [state, setState] = React.useState(0);
    const [disabled, setDisabled] = React.useState(false);
    const [opcionesClinicas, setOpcionesClinicas] = React.useState([]);

    const queryClinica = () => {
        async function queryClinica() {
            try {
                let resultado = await FetchGrahpQL({
                    query: bTipoDoctor ? SELECT_CLINICAS_DOCTOR_FETCH : (bTipoUsuario ? SELECT_CLINICA_USUARIO_FETCH : SELECT_CLINICAS_FETCH),
                    fetchPolicy: 'no-cache',
                    variables: {
                        limite: 100,
                        activas: (bTipoAdmin ? null : true),
                        usuarioID: (bTipoUsuario ? Number(userToken.usuarioId) : null),
                        doctorID: (bTipoDoctor ? Number(userToken.usuarioId) : null),
                        clinicaID: null
                    }
                });

                let { listadoClinicas: { clinicas } = {}, listadoClinicasPorDoctor, obtenerClinicasUsuario } = resultado;
                let campos = bTipoDoctor ? listadoClinicasPorDoctor : (bTipoUsuario ? obtenerClinicasUsuario : clinicas);
                let ruta = window.location.pathname.replace(/^\/(\w+\-*\w*)(\#.*)*(\/.*)*$/, '$1');

                let unica = (bTipoUsuario || bTipoDoctor) && Array.isArray(campos) && campos.length === 1;
                setDisabled(unica);

                let select = ftSelectFormat(campos, {
                    id: "clinicaId",
                    descripcion: { valor: (data) => bTipoAdmin ? `${data["nombre"]}${data["estatus"] ? "" : " (Inactivo)"}` : data["nombre"] },
                    otro: [{ id: "estatus", alias: "color", valor: (valor) => valor ? '#212' : '#b71c1c' }]
                }, !unica && (ruta === 'loginpuntoventa' ? "Selecciona una sucursal" : `Mostrar todas las sucursales${bTipoAdmin ? "" : " asignadas"}`));


                if (unica && Array.isArray(select) && select[0]["value"]) handleChange({ target: { value: select[0]["value"], descripcion: select[0]["label"] } });
                return select;

            } catch ({ message: label }) {
                handleChange({ target: { value: 0, descripcion: label } });
                return [{ value: '', label }];
            }
        }
        return queryClinica();
    };

    useEffect(() => {
        async function ftEffect() {
            let clinicas = await queryClinica();
            setOpcionesClinicas(clinicas)
        }
        if (!bTipoPaciente) ftEffect();
    }, []);

    useEffect(() => {
        if (opcionesClinicas) if (opcionesClinicas.length > 0) {
            async function ftEffect() {
                let defaultClinicaID = obtenerStateStorage('sc');
                let clinicaId = defaultClinicaID ? defaultClinicaID.SeleccionClinica.clinicaId : 0;
                let nombre = defaultClinicaID ? defaultClinicaID.SeleccionClinica.nombre : '';

                setState(clinicaId);
                seleccionarClinica({ clinicaId, nombre });
            }
            ftEffect();
        }
    }, [opcionesClinicas]);

    const handleChange = ({ target: { value, descripcion } }) => {
        let [filtro] = opcionesClinicas.filter(dato => (Number(dato.value) === Number(value)));
        let { label: nombre } = filtro ? filtro : { label: '' };
        seleccionarClinica({ clinicaId: value, nombre: descripcion ? descripcion : nombre });
        guardarStateStorage({ SeleccionClinica: { clinicaId: value, nombre: descripcion ? descripcion : nombre } }, 'sc')
        setState(value);
    };

    // Tipo PACIENTE
    if (bTipoPaciente) return <></>;
    // Punto de Venta
    if (window.location.pathname === '/puntoventa') return <></>;


    return (
        <>
            <Select
                labelId={`labelmulti-clinicaId`}
                inputProps={{ id: 'id' }}
                value={opcionesClinicas.length > 0 ? state : ''}
                onChange={handleChange}
                style={{ width: '100%' }}
                disabled={disabled}
            >
                {
                    opcionesClinicas.map(({ label, value, color }, index) => (
                        <MenuItem key={index} value={value} style={{ color, maxWidth: '325px', whiteSpace: 'break-spaces' }}>
                            {label}
                        </MenuItem>
                    ))
                }
            </Select>
        </>
    )

}
