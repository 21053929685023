import React from 'react';
//Iconos
import { AddIcon, EditIcon, DeleteForever,SwitchIcon,StyleIcon } from '../../../componentesGenericos/Icons';

/*FORMATO DE COLUMNAS */

//seleccion inicial para el query
const seleccionInicial = {
    usuarioId: null,
    tipo: null,
    nombres: null,
    apellidos: null,
    email: null,
    password: null,
    fechaEgreso: null,
    fechaAlta: null,
    estatus: null,
    sexo: null,
    tarjetaQr: null,
    correo: null,
    cedula: null,
    especialidadId: null,
    descripcion: null,
    telefono:null
};
//seleccion para columnas
const columnas = [
    { id: 'usuarioId', label: 'Usuario', minWidth: 0, hide: false },
    {
        id: 'tipo', label: 'Tipo', minWidth: 100, align: 'center', textAlign: 'center', format: (valor) => {
            return valor.toString().replace("1", "Doctor").replace("2", "Paciente").replace("3", "Admin").replace("4", "Usuario");
        }
    },
    { id: 'email', label: 'Email', minWidth: 170, align: 'center', filtro: true },
    {
        id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center', format: (valor) => {
            return valor.toString().replace("0", "Inactivo").replace("1", "Activo");
        }
    },
    {
        id: 'fechaAlta', label: 'Fecha de alta', minWidth: 170, align: 'center',
        format: (valor) => {
            return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
        }
    },
    {
        id: 'fechaEgreso', label: 'Fecha de egreso', minWidth: 170, align: 'center',
        format: (valor) => {
            if (valor != null) {
                return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
            }
        },hide: true
    },
    { id: 'especialidadId', label: 'Especialidad', minWidth: 0, hide: true },
    { id: 'cedula', label: 'Cedula', minWidth: 0, hide: true },
    { id: 'telefono', label: 'Telefono', minWidth: 0, hide: true },

];
//menu lateral
const menu = (handleModificarClick) => [
    { texto: "Revisar solicitud", icono: <EditIcon />, onClick: () => handleModificarClick() },
];

/*STATE INICIAL */
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    usuarioId: null,
    tipo: null,
    nombres: null,
    apellidos: null,
    email: null,
    password: null,
    fechaEgreso: null,
    fechaAlta: null,
    estatus: null,
    sexo: null,
    tarjetaQr: null,
    correo: null,
    cedula: null,
    especialidadId: null,
    descripcion: null,
    telefono:null
};
const formularioState = (ftFormularioClose) => {
    return {
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

/*CONFIGURACION */
const OptionsUsuarios = [
    'Usuario',
    'Doctor',
    'Paciente',
    'Administrador'
];

//ESTILOS
const Style = {
    height: "90%", textAlign: "center", width: "100%"
};

export { columnas, seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState,OptionsUsuarios,Style };
