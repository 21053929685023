import React from 'react';
import { Snackbar } from '../../../componentesGenericos/Core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Mensaje({ visible, descripcion, tipo, ocultarAl = 6000, handleClose }) {
    return(
        <Snackbar open={visible} autoHideDuration={ocultarAl} onClose={handleClose}>
            <Alert onClose={handleClose} severity={tipo}>
                {descripcion}
            </Alert>
        </Snackbar>
    )
}

export default Mensaje;
