import React, { useState, useEffect } from 'react';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//REDUX
import { useSelector } from 'react-redux';

//Queries
import { LISTADO_COMISIONES, LISTADO_CLINICAS, LISTADO_SERVICIOS_DOCTORES, LISTADO_PRODUCTOS_SERVICIOS } from '../../query';
import { ESTATUS_COMISION, NUEVA_COMISION, MODIFICAR_COMISION } from '../../mutation';

//Componentes
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Formulario from '../../componentesGenericos/FormularioComision';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, DeleteForever } from '../../componentesGenericos/Icons';

//Helpers

//expresiones regulares
import { rgxNoVacio, rgxPorcentaje } from '../../Helpers/constantes/Rgx';


export default function Comisiones() {

    const clinicaId = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    const configuracion = useSelector(state => state.configuracion);

    //#region states
    const seleccionInicial = {
        comisionId: null,
        clinicaProfesionalId: null,
        clinicaId: null,
        nombreClinica: null,
        profesionalId: null,
        nombreCompleto: null,
        correo: null,
        estatusComision: null,
        productoId: null,
        descripcionProducto: null,
        porcentajeComision: null,
        productosIdComision: null,
        productosComision: null,
        fechaAsignacion: null
    };
    const [seleccion, setSeleccion] = useState(seleccionInicial);

    const [listados, setListados] = useState({
        clinicas: null,
        doctores: null,
        tipos: null,
        servicios: null,
    })

    const stateInicial = {
        clinicaId: null,
        doctorId: null,
        nombreCompleto: "",
        clinicaProfesionalId: null,
        serviciosDoctor: [],
        seleccionTipo: [{ descripcion: "Todos los tipos", tipoId: -1 }],
        productosId: [{ descripcion: "Todos", servicioId: -1 }],
        porcentajeComision: 0,
    }

    const [state, setState] = useState(stateInicial);

    const [actualizar, setActualizar] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [fila, setFila] = useState(-1);
    const [checked, setChecked] = useState(false);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Comisiones",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    const [modificando, setModificando] = useState(false);
    //#endregion


    //#region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_COMISIONES, {
        variables: { tipo: 2, offset: 0, clinicaId: Number(clinicaId), profesionalId: null, estatus: 1 },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const { loading: loadingClinicas, data: Clinicas } = useQuery(LISTADO_CLINICAS, {
        variables: { limite: 1000 },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setState(state => ({ ...state, clinicaId: Clinicas ? Clinicas.listadoClinicas.clinicas[0].clinicaId : -1 }));
        }
    });

    const { data: DoctorClinica } = useQuery(LISTADO_SERVICIOS_DOCTORES, {
        variables: { clinicaId: Number(state.clinicaId) },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            let doctores = [];

            if (DoctorClinica) {
                DoctorClinica.listadoServiciosDoctores.serviciosDoctores.map(sd => {
                    if (Number(state.clinicaId) === sd.clinicaId)
                        doctores.push({ label: sd.nombreCompleto, value: sd.clinicaDoctorId });
                }
                );
            }

            if (doctores.length === 0) {
                doctores = [{ label: 'No se encontraron registros', value: -1 }];
            }

            setListados(listados => ({ ...listados, doctores }));


            // let doctores = DoctorClinica ? DoctorClinica.listadoServiciosDoctores.serviciosDoctores.map(sd =>
            //     ({ label: sd.nombreCompleto, value: sd.clinicaDoctorId })
            // ) : null;

            // setListados(listados => ({ ...listados, doctores }));

            // if (formulario.abrir === true) {
            //     setState({ ...state, clinicaProfesionalId: null });
            // }
        },
        onError: (e) => {
            if (e.message.includes("No se encontraron registros")) {

                setListados(listados => ({ ...listados, doctores: [{ label: 'No se encontraron registros', value: -1 }] }));

                setState(state => ({ ...state, clinicaProfesionalId: -1 }));

            } else {
                console.log(e.message)
            }
        }
    });

    const { loading: loadingProductos, data: Productos } = useQuery(LISTADO_PRODUCTOS_SERVICIOS, {
        variables: { offset: 0, tipo: 4, clinica: null, disponible: null },
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            let prods = (Productos.listadoProductosServicios.productosServicios) || [];

            if (prods.length > 0) {
                if (prods[0].servicioId !== -1) {
                    prods.unshift({ descripcion: "Todos", servicioId: -1 });
                }
            }
            else {
                prods.unshift({ descripcion: "Sin productos/servicios", servicioId: -1 });
            }

            setListados({ ...listados, servicios: prods })
        },
        onError: (e) => {
            console.log("Error al cargar el listado de productos: ", e.message.replace('GraphQL Error:', ''))
        }
    });
    //#endregion

    //#region MUTATIONS
    const [asignarComision] = useMutation(NUEVA_COMISION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se asignó correctamente la comisión de ${Number(state.porcentajeComision)}% al profesional ${state.nombreCompleto}` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setState({ ...state, ...stateInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [modificarComision] = useMutation(MODIFICAR_COMISION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se modificó correctamente la comisión del profesional ${seleccion.nombreCompleto}` });

            setSeleccion({ ...seleccionInicial });
            setState({ ...stateInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [cambiarEstatusComision] = useMutation(ESTATUS_COMISION, {
        onError: (e) => {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setDisabledAccept(false);

            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente la comisión a "${seleccion.nombreCompleto}"` });

            setSeleccion({ ...seleccionInicial });
            setState({ ...stateInicial });
            setFila(-1);
            setActualizar(true);
        }
    });
    //#endregion

    //#region constantes
    let clinicas = Clinicas ? Clinicas.listadoClinicas.clinicas.map(cl =>
        ({ label: cl.nombre, value: cl.clinicaId })
    ) : null;

    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'comisionId', label: 'comisionId', hide: true },
            { id: 'clinicaProfesionalId', label: 'clinicaProfesionalId', hide: true },
            { id: 'clinicaId', label: 'clinicaId', hide: true },
            { id: 'profesionalId', label: 'profesionalId', hide: true },
            { id: 'productosIdComision', label: 'productosIdComision', hide: true },

            { id: 'correo', label: 'correo', hide: true },
            { id: 'descripcionProducto', label: 'Descripción', hide: true },
            { id: 'productoId', label: 'productoId', hide: true },

            { id: 'nombreClinica', label: 'Sucursal', align: 'left', textAlign: 'left', filtro: true },
            { id: 'nombreCompleto', label: 'Profesional', align: 'left', textAlign: 'left', filtro: true },
            { id: 'productosComision', label: 'Productos/Servicios', style: { maxWidth: 250 }, align: 'left', textAlign: 'left', filtro: true },
            { id: 'porcentajeComision', label: 'Comisión', align: 'right', textAlign: 'right', filtro: true, format: (v) => v !== null && v !== undefined ? v + '%' : '-' },

            { id: 'estatusComision', label: 'Estatus', component: "Checkbox", hide: true },

            {
                id: 'fechaAsignacion', label: 'Fecha de modificación', filtro: true, format: (v) => {
                    return v ? v.replace(/^(\d{4})-(\d{2})-(\d{2})T00:00:00.000Z$/, '$3/$2/$1') : ''
                }
            },
        ];

    const menu = [
        { texto: "Asignar comisión", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar comisión", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Eliminar comisión", icono: <DeleteForever checked={checked} />, onClick: () => handleEliminarClick() }
    ];

    const Tipos = [
        {
            tipoId: -1,
            descripcion: 'Todos los tipos'
        },
        {
            tipoId: 1,
            descripcion: 'Servicios asignados'
        },
        {
            tipoId: 2,
            descripcion: 'Productos'
        },
        {
            tipoId: 3,
            descripcion: 'Materiales'
        },
    ];

    let camposFormulario = [
        {
            id: "comisionId",
            texto: "comisionId",
            valor: seleccion.comisionId,
            tipo: "hidden",
        },
        {
            id: "clinicaId",
            texto: "Sucursal*",
            valor: state.clinicaId ? state.clinicaId : -1,
            tipo: "select",
            xs: 12,
            opciones: clinicas,
            onChange: handleClinicaChange,
            disabled: modificando,
            ayuda: 'Seleccione una sucursal',
            regexp: rgxNoVacio,
            margen: 'none',
            styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' }
        },
        {
            id: "clinicaProfesionalId",
            texto: "Profesional*",
            valor: state.clinicaProfesionalId ? state.clinicaProfesionalId : listados?.doctores ? listados.doctores.length === 1 && listados.doctores[0].value === -1 ? listados.doctores[0].value : null : null,
            tipo: "select",
            xs: 12,
            onChange: handleDoctorChange,
            opciones: listados.doctores,
            disabled: loadingClinicas || modificando || !DoctorClinica || state.clinicaProfesionalId === -1 || !state.clinicaId,
            ayuda: 'Seleccione un profesional',
            regexp: rgxNoVacio,
            margen: 'none'
        },
        {
            id: "tipo",
            texto: "Tipo*",
            valor: state.seleccionTipo || stateInicial.seleccionTipo,
            tipo: "autocomplete",
            xs: 12,
            onChange: handleTipoChange,
            propiedades: {
                noOptionsText: "Sin registros",
                options: Tipos,
                getOptionLabel: (option) => option.descripcion,
                multiple: true,
                disableCloseOnSelect: true,
                getOptionSelected: (opcion, valor) => {
                    return opcion.tipoId === valor.tipoId
                },
                disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId || state.clinicaProfesionalId === -1
            },
            margen: 'none'
        },
        {
            id: "productosId",
            texto: "Producto/Servicio*",
            valor:  state.productosId || stateInicial.productosId,
            tipo: "autocomplete",
            xs: 12,
            onChange: handleProductoChange,
            propiedades: {
                noOptionsText: "Sin productos",
                options: listados.servicios,
                getOptionLabel: (op) => op.descripcion,
                multiple: true,
                disableCloseOnSelect: true,
                getOptionSelected: (opcion, valor) => {
                    return opcion.servicioId === valor.servicioId
                },
                disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId || !listados.servicios || loadingProductos || state.clinicaProfesionalId === -1
            },
            margen: 'none'
        },
        {
            id: "porcentajeComision",
            texto: "Comisión *",
            valor: state.porcentajeComision || null,
            tipo: "number",
            onChange: handlePorcentajeChange,
            xs: 12,
            otros: {
                inputProps: { min: 0, max: 100, startAdornment: '$' },
                InputProps: {
                    endAdornment: "%",
                },
            },
            disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId || state.productosId.length < 1 || state.clinicaProfesionalId === -1,
            ayuda: "El porcentaje de comisión debe ser mayor a 0 y menor a " + (configuracion.maxPorcentajeComision !== null && configuracion.maxPorcentajeComision !== undefined ? configuracion.maxPorcentajeComision * 100 : 100),
            regexp: rgxPorcentaje,
            margen: 'none',
            formato: (val) => {
                if (val <= configuracion.maxPorcentajeComision * 100) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    ];
    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoComisiones?.comisiones;
    //#endregion
    window.st = state;
    //#region handlers
    function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setState({ ...stateInicial });
        setFila(-1);
        setModificando(false);

        setFormulario({
            ...formulario,
            abrir: true,
            onAccept: ftAgregar,
            titulo: "Asignar comisión"
        });

    }

    function handleModificarClick() {
        if (state.clinicaProfesionalId !== null && state.clinicaProfesionalId !== undefined) {
            setModificando(true);
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar, titulo: "Modificar comisión" });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar a un profesional" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.clinicaProfesionalId !== null && seleccion.clinicaProfesionalId !== undefined) {
            setChecked(seleccion.estatusComision === 0);

            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea eliminar la comisión de ${seleccion.porcentajeComision}% para "${seleccion.nombreCompleto}"?`, onAccept: ftEliminar, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar a un profesional" });
        }
    }

    function handleClinicaChange(e) {
        if (e?.target) {
            let doctores = [];

            if (DoctorClinica) {
                DoctorClinica.listadoServiciosDoctores.serviciosDoctores.map(sd => {
                    if (Number(e.target.value) === sd.clinicaId)
                        doctores.push({ label: sd.nombreCompleto, value: sd.clinicaDoctorId });
                }
                );
            }

            if (doctores.length === 0) {
                doctores = [{ label: 'No se encontraron registros', value: -1 }];
                setState(state => ({ ...state, clinicaId: e.target.value, clinicaProfesionalId: -1 }));
            } else {
                setState(state => ({ ...state, clinicaId: e.target.value, clinicaProfesionalId: null }));
            }

            setListados(listados => ({ ...listados, doctores }));

            // setState({ ...state, clinicaId: e.target.value, clinicaProfesionalId: null });
        }
    }

    function handleDoctorChange(e) {
        if (e?.target) {
            let doc = DoctorClinica.listadoServiciosDoctores.serviciosDoctores.filter(dc => dc.clinicaDoctorId === e.target.value);

            if (doc?.length > 0) {

                let tipos = state.seleccionTipo.map(t => t.tipoId);

                let prods = (Productos.listadoProductosServicios.productosServicios.filter(pp =>
                    (
                        (
                            (pp.servicioId === -1 || tipos.includes(-1))
                            &&
                            (pp.tipoProducto !== 1 || doc[0].serviciosId.includes(pp.servicioId))
                        )
                        ||
                        (
                            (tipos.includes(pp.tipoProducto))
                            &&
                            (pp.tipoProducto !== 1 || doc[0].serviciosId.includes(pp.servicioId))

                        )
                    )
                    &&
                    (pp.clinicaId === Number(state.clinicaId) || Number(state.clinicaId) === 0 || pp.servicioId === -1)
                )) || [];

                setListados(listados => ({ ...listados, servicios: prods }));

                setState(state => ({ ...state, clinicaProfesionalId: e.target.value, serviciosDoctor: doc[0].serviciosId, nombreCompleto: doc[0].nombreCompleto, productosId: stateInicial.productosId }))
            }
        }
    }


    function handleTipoChange(e, val) {

        let tiposElegidos = stateInicial.seleccionTipo;

        if (val) {
            if (val.length > 0) {

                let tipos = val.map(t => t.tipoId);

                //Si se marco "TODOS"
                if (val[val.length - 1].tipoId === -1) {
                    tiposElegidos = stateInicial.seleccionTipo;
                } else {
                    if (tipos.includes(-1)) {
                        val = val.filter(v => v.tipoId !== -1);
                    }
                    if (val.length === 3) {
                        val = stateInicial.seleccionTipo;
                    }
                    tiposElegidos = val;
                }

                tipos = tiposElegidos.map(t => t.tipoId);

                let prods = (Productos.listadoProductosServicios.productosServicios.filter(pp =>
                    (
                        (
                            (pp.servicioId === -1 || tipos.includes(-1))
                            &&
                            (pp.tipoProducto !== 1 || state.serviciosDoctor.includes(pp.servicioId))
                        )
                        ||
                        (
                            (tipos.includes(pp.tipoProducto))
                            &&
                            (pp.tipoProducto !== 1 || state.serviciosDoctor.includes(pp.servicioId))

                        )
                    )
                    &&
                    (pp.clinicaId === Number(state.clinicaId) || Number(state.clinicaId) === 0 || pp.servicioId === -1)
                )) || [];

                setListados({ ...listados, servicios: prods })
            }
        }

        setState(state => ({ ...state, seleccionTipo: tiposElegidos, productosId: stateInicial.productosId }));
    }

    function handleProductoChange(e, val) {
        if (val) {
            if (val.length > 0) {

                let tipos = val.map(t => t.servicioId);

                //Si se marco "TODOS" al final
                if (val[val.length - 1].servicioId === -1) {
                    setState(state => ({ ...state, productosId: [{ descripcion: "Todos", servicioId: -1 }] }));
                } else {
                    if (tipos.includes(-1)) {
                        val = val.filter(v => v.servicioId !== -1);
                    }
                    if (listados.servicios.length - 1 === val.length) {
                        val = [{ descripcion: "Todos", servicioId: -1 }];
                    }
                    setState(state => ({ ...state, productosId: val }));
                }
            } else {
                setState(state => ({ ...state, productosId: stateInicial.productosId }));
            }
        }
    }

    function handlePorcentajeChange(e) {
        setState({ ...state, porcentajeComision: e.target.value });
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            let prds = datos.productosIdComision.split(',');


            setSeleccion({
                ...datos,
                clinicaId: datos.clinicaId,
                clinicaProfesionalId: datos.clinicaProfesionalId,
                productosId: Productos ? Productos.listadoProductosServicios.productosServicios.filter(p => prds.includes(p.servicioId)) : listados.servicios ? listados.servicios.filter(p => prds.includes(p.servicioId)) : [{ descripcion: "Todos", servicioId: -1 }],
                porcentajeComision: datos.porcentajeComision
            });

            setState({
                ...state,
                clinicaId: datos.clinicaId,
                clinicaProfesionalId: datos.clinicaProfesionalId,
                doctorId: datos.profesionalId,
                productosId: Productos ? Productos.listadoProductosServicios.productosServicios.filter(p => prds.includes(p.servicioId)) : listados.servicios ? listados.servicios.filter(p => prds.includes(p.servicioId)) : [{ descripcion: "Todos", servicioId: -1 }],
                porcentajeComision: datos.porcentajeComision,
                // estatusComision:1
            });

            if (DoctorClinica) {

                let doctores = [];

                DoctorClinica.listadoServiciosDoctores.serviciosDoctores.map(sd => {
                    if (Number(datos.clinicaId) === sd.clinicaId)
                        doctores.push({ label: sd.nombreCompleto, value: sd.clinicaDoctorId });
                });

                if (doctores.length === 0) {
                    doctores = [{ label: 'No se encontraron registros', value: -1 }];
                }

                setListados(listados => ({ ...listados, doctores }));

                let doc = DoctorClinica.listadoServiciosDoctores.serviciosDoctores.filter(dc => dc.clinicaDoctorId === datos.clinicaProfesionalId);

                if (doc?.length > 0) {

                    let tipos = [-1];

                    let prods = (Productos?.listadoProductosServicios.productosServicios.filter(pp =>
                        (
                            (
                                (pp.servicioId === -1 || tipos.includes(-1))
                                &&
                                (pp.tipoProducto !== 1 || doc[0].serviciosId.includes(pp.servicioId))
                            )
                            ||
                            (
                                (tipos.includes(pp.tipoProducto))
                                &&
                                (pp.tipoProducto !== 1 || doc[0].serviciosId.includes(pp.servicioId))

                            )
                        )
                        &&
                        (pp.clinicaId === Number(datos.clinicaId) || Number(datos.clinicaId) === 0 || pp.servicioId === -1)
                    )) || [];

                    setListados(listados => ({ ...listados, servicios: prods }))
                }
            }

            setChecked(datos.estatusComision !== 0)
            setFila(index);
        }
        // else {
        //     setSeleccion(seleccionInicial);
        //     setState({ ...stateInicial });
        //     setFila(-1);
        // }
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setState({ ...stateInicial });
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setState({ ...stateInicial });
        setFila(-1);
    }
    //#endregion

    //#region funciones
    function ftAgregar(result) {
        setDisabledAccept(true);

        let productosId = "";

        if (state.productosId?.length > 0) {
            state.productosId.map(p => {
                productosId += p.servicioId + ",";
                return null
            })
        }

        if (productosId.length > 0) {
            productosId = productosId.substring(0, productosId.length - 1);
        }

        let tipos = "";

        if (state.seleccionTipo?.length > 0) {
            state.seleccionTipo.map(p => {
                tipos += p.tipoId + ",";
                return null
            })
        }

        if (tipos.length > 0) {
            tipos = tipos.substring(0, tipos.length - 1);
        }

        if (tipos === "-1") {
            tipos = "1,2,3";
        }

        let comision = {
            clinicaId: Number(state.clinicaId),
            clinicaProfesionalId: Number(state.clinicaProfesionalId),
            productosId,
            porcentajeComision: Number(state.porcentajeComision),
            tipoProducto: tipos,
            estatusComision: 1
        };

        let validacion = ftValidarObligatorios(comision);
        if (validacion === "") {
            validacion = ftValidarFormato(comision)
            if (validacion === "") {
                asignarComision({ variables: { comision } });
            }
        }

        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftModificar(result) {
        setDisabledAccept(true);

        let productosId = "";

        if (state.productosId?.length > 0) {
            state.productosId.map(p => {
                productosId += p.servicioId + ",";
                return null
            })
        }

        if (productosId.length > 0) {
            productosId = productosId.substring(0, productosId.length - 1);
        }

        let tipos = "";

        if (state.seleccionTipo?.length > 0) {
            state.seleccionTipo.map(p => {
                tipos += p.tipoId + ",";
                return null
            })
        }

        if (tipos.length > 0) {
            tipos = tipos.substring(0, tipos.length - 1);
        }

        if (tipos === "-1") {
            tipos = "1,2,3";
        }

        let updComision = {
            clinicaId: Number(state.clinicaId),
            clinicaProfesionalId: Number(state.clinicaProfesionalId),
            productosId,
            porcentajeComision: Number(state.porcentajeComision),
            comisionAnterior: Number(seleccion.porcentajeComision),
            tipoProducto: tipos,
            estatusComision: 1
        };

        let validacion = ftValidarObligatorios(updComision);
        if (validacion === "") {
            validacion = ftValidarFormato(updComision);

            let anterior = {
                clinicaId: Number(seleccion.clinicaId),
                clinicaProfesionalId: Number(seleccion.clinicaProfesionalId),
                productosId: seleccion.productosIdComision,
                porcentajeComision: Number(seleccion.porcentajeComision),
                comisionAnterior: Number(seleccion.porcentajeComision),
                tipoProducto: tipos,
                estatusComision: 1

            }

            if (JSON.stringify(updComision) === JSON.stringify(anterior)) {
                validacion = "No se realizó ningún cambio";
            }

            if (validacion === "") {
                modificarComision({
                    variables: {
                        updComision
                    }
                });
            }
        }

        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftEliminar() {
        setDisabledAccept(true);

        cambiarEstatusComision({
            variables: {
                estatusComision: {
                    clinicaProfesionalId: Number(seleccion.clinicaProfesionalId),
                    porcentajeComision: seleccion.porcentajeComision,
                    estatusComision: seleccion.estatusComision === 0 ? 1 : 0
                }
            }
        })
    }

    function ftValidarObligatorios(campos) {

        if (!campos.clinicaId || campos.clinicaId === -1) return "Debe seleccionar una sucursal";
        if (!campos.clinicaProfesionalId) return "Debe seleccionar un profesional";
        if (!campos.productosId || campos.productosId.length < 1) return "Debe seleccionar almenos un producto/servicio";
        return Object.values(campos).every((valor) => (valor !== null && valor !== undefined)) ? "" : "Faltan valores obligatorios";
    }

    function ftValidarFormato(campos) {
        if (Number(campos.porcentajeComision) < 1 || Number(campos.porcentajeComision) > 100) return "El porcentaje de comisión debe estar entre 1 y " + (configuracion.maxPorcentajeComision !== null && configuracion.maxPorcentajeComision !== undefined ? configuracion.maxPorcentajeComision * 100 : 100)
        return Number(campos.clinicaId) < 1 ? "Debe Seleccionar una sucursal" : "";
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatusComision !== 0);
        ftMensajeClose();
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false, titulo: modificando ? "Modificar comisión" : "Asignar comisión" });

        setState({
            ...state,
            clinicaId: seleccion.clinicaId,
            clinicaProfesionalId: seleccion.clinicaProfesionalId,
            seleccionTipo: [{ descripcion: "Todos los tipos", tipoId: -1 }],
            productosId: seleccion.productosId,
            porcentajeComision: seleccion.porcentajeComision,
            // estatusComision:1
        });
    }
    //#endregion

    if (actualizar === true) {
        refetch()
            .then(() => setErrorState(false))
            .catch(e => {
                console.log(e.message);
                setErrorState(true);
            });
        setActualizar(false);
    }

    useEffect(() => {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setState({ ...stateInicial });
        setFila(-1);
    }, [clinicaId]);

    useEffect(() => {
        setFormulario({ ...formulario, onAccept: modificando ? ftModificar : ftAgregar });
        setMensaje({ ...mensaje, onCancel: ftMensajeCancel });

        camposFormulario = [
            {
                id: "comisionId",
                texto: "comisionId",
                valor: seleccion.comisionId,
                tipo: "hidden",
            },
            {
                id: "clinicaId",
                texto: "Sucursal *",
                valor: state.clinicaId ? state.clinicaId : -1,
                tipo: "select",
                xs: 12,
                opciones: clinicas,
                onChange: handleClinicaChange,
                styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                disabled: modificando
            },
            {
                id: "clinicaProfesionalId",
                texto: "Profesional *",
                valor: state.clinicaProfesionalId ? state.clinicaProfesionalId : null,
                tipo: "select",
                xs: 12,
                onChange: handleDoctorChange,
                styleInput: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                opciones: listados.doctores,
                disabled: loadingClinicas || modificando || !DoctorClinica || state.clinicaProfesionalId === -1 || !state.clinicaId
            },
            {
                id: "tipo",
                texto: "Tipo *",
                valor: state.seleccionTipo || stateInicial.seleccionTipo,
                tipo: "autocomplete",
                xs: 12,
                onChange: handleTipoChange,
                propiedades: {
                    noOptionsText: "Sin registros",
                    options: Tipos,
                    getOptionLabel: (option) => option.descripcion,
                    multiple: true,
                    disableCloseOnSelect: true,
                    getOptionSelected: (opcion, valor) => {
                        return opcion.tipoId === valor.tipoId
                    },
                    disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId
                },
                styleSelect: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            },
            {
                id: "productosId",
                texto: "Producto / Servicio *",
                valor: state.productosId || stateInicial.productosId,
                tipo: "autocomplete",
                xs: 12,
                onChange: handleProductoChange,
                propiedades: {
                    noOptionsText: "Sin productos",
                    options: listados.servicios,
                    getOptionLabel: (op) => op.descripcion,
                    multiple: true,
                    disableCloseOnSelect: true,
                    getOptionSelected: (opcion, valor) => {
                        return opcion.servicioId === valor.servicioId
                    },
                    disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId || !listados.servicios || loadingProductos
                },
                styleSelect: { maxWidth: '480px', whiteSpace: 'break-spaces' },
                styleMenuItem: { maxWidth: '480px', whiteSpace: 'break-spaces' },
            },
            {
                id: "porcentajeComision",
                texto: "Comisión *",
                valor: state.porcentajeComision || 0,
                tipo: "number",
                onChange: handlePorcentajeChange,
                xs: 12,
                otros: {
                    inputProps: { min: 0, max: 100, startAdornment: '$' }
                },
                disabled: loadingClinicas || !DoctorClinica || !state.clinicaId || !state.clinicaProfesionalId || state.productosId.length < 1
            }
        ];
    }, [state]);

    return (
        <MenuLateral titulo="Comisiones" menu={menu}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo={formulario.titulo} onClose={ftFormularioClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} datos={state} sync/>

            <Tabla titulo="Comisiones asignadas" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["correo","descripcionProducto","nombreClinica","nombreCompleto"]} />

        </MenuLateral>
    );
}
