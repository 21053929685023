import gql from 'graphql-tag';

//#region Modulos
const LISTADO_MODULOS=gql`
    query listadoModulos($limite:Int){
        listadoModulos(limite:$limite){
            Modulos{
                moduloId
                permiso
                modulo
            }
            registros
            paginas
        }
    }
`;

const OBTENER_MODULO=gql`
    query obtenerModulo($id:Int!){
        obtenerModulo(id:$id){
            moduloId
            permiso
            modulo
        }
    }
`;

//#endregion

export {
    LISTADO_MODULOS,
    OBTENER_MODULO
}
