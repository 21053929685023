import React,{useState} from 'react'
import { Grid } from '../../../componentesGenericos/Core';

function FechaHora() {
    const tiempo = new Date();
    const [hora, setHora] = useState("00:00:00");
    const [fecha, setFecha] = useState("00/00/2020");

    const getHora = () => {
        let hora = tiempo.getHours() < 10 ? '0' + tiempo.getHours() : tiempo.getHours();
        let minutos = tiempo.getMinutes() < 10 ? '0' + tiempo.getMinutes() : tiempo.getMinutes();
        let segundos = tiempo.getSeconds() < 10 ? '0' + tiempo.getSeconds() : tiempo.getSeconds();
        let dia = tiempo.getDate() < 10 ? '0' + tiempo.getDate() : tiempo.getDate();
        let mes = tiempo.getMonth() < 9 ? '0' + (tiempo.getMonth() + 1) : (tiempo.getMonth() + 1);
        let año = tiempo.getFullYear();

        setFecha(`${dia}/${mes}/${año}`);
        setHora(`${hora}:${minutos}:${segundos}`);
    }
    setTimeout(getHora, 1000);

    return (
        <Grid container direction="column" item xs={12} justify="flex-start" alignItems="center">
        <Grid item xs={12}>
            <label>Fecha:&nbsp;&nbsp;</label>
            <label>{fecha}</label>
            <br/>
            <label>Hora:&nbsp;&nbsp;</label>
            <label>{hora}</label>
        </Grid>
    </Grid>
    )
}

export default FechaHora
