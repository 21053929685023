import React from 'react'
import ProductosServicios from './Listado';

function Servicios() {
    return (
            <ProductosServicios titulo="servicios" titulo2="servicio" tipo="1" />
    )
}

export default Servicios
