import React from 'react'
import { Grid } from '../../../componentesGenericos/Core';

function SerieFolio({series,folios}) {

    return (
        <Grid container item xs={12} justify="center" alignItems="center">
            {/* ------Serie----- */}
            <Grid item xs={8} >
                <label>Serie:&nbsp;&nbsp;</label>
                <label>{series ? series.obtenerSerie.serieNombre : ""}</label>
            </Grid>

            {/*---- Folio -----*/}
            <Grid item xs={8}>
                <label>Folio:&nbsp;&nbsp;</label>
                <label>{folios || 0}</label>
            </Grid>
        </Grid>
    )
}

export default SerieFolio
