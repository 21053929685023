import React from 'react';
import './style.css';

export default function TarjetaDigital({nombres, leyendaQR, telefono, email, logotipo, qr,convenio}) {

    return (
        <>
        {
            convenio ?
            <div id="td-container">
            <div className="td-product-details">
                <img src={logotipo} alt="logotipo" title="logotipo convenio" />
            </div>
            <p className="td-information">{nombres}</p>
           <div className="td-product-qr">
               {qr}
            </div>
        </div>
        : //Si el convenio no tiene imagen
        <div id="td-container">
            <div className="td-product-qr-2">
               {qr}
            </div>
            <div className="td-product-details-2">
                {/* <img src={logotipo} alt="logotipo" title="logotipo convenio" /> */}
                <br/>
                <h1>{nombres}</h1>
                <p className="td-information-2">Teléfono: {telefono}</p>
                <p className="td-information-2">Email: {email}</p>
                <p className="td-information-2">{leyendaQR}</p>
            </div>
        </div>
        }
        </>
    )
}
