
export function TicketTemporal(temporal) {
    let ticketTemp = null;

    if (temporal.length > 0) {
        let {
            clavePago,
            clienteId,
            doctorId,
            formasCobro,
            formasCobroCantidad,
            conceptoId,
            metodoPago,
            tipoDocumentoId,
            importePago,
            cambio,
            subtotalTicket,
            ivaTotalTicket,
            totalTicket,
        } = temporal[0];

        let encabezado = {
            clavePago,
            clienteId,
            doctorId: Number(doctorId),
            conceptoId,
            metodoPago,
            tipoDocumentoId,
            importePago,
            cambio,
            subtotalTicket,
            ivaTotalTicket,
            totalTicket,
        }


        let formasPago = {
            formasCobro: (formasCobro + "").split(','),
            formasCobroCantidad: (formasCobroCantidad + "").split(','),
        }

        let partidasServId = [];
        let partidasCant = [];

        temporal.map((tmp) => {
            let {
                servicioId,
                // descripcion,
                cantidad,
                // almacenId,

                // descuentoCantidad,
                // descuentoConvenioCantidad,
                // descuentoConvenioUnitario,
                // descuentoTotal,
                // descuentoTotalConvenioTicket,
                // descuentoTotalFinalTicket,
                // descuentoTotalTicket,
                // descuentoUnitario,
                // descuentoUnitarioTotal,

                // garantia,
                // garantiaId,

                // ivaTotal,
                // ivaUnitario,
                // ivaUnitarioDescuento,
                // ivaUnitarioTotal,

                // porcentajeDescuento,
                // porcentajeDescuentoConvenio,
                // porcentajeIva,

                // precioDescuentoCantidad,
                // precioDescuentoConvenioCantidad,
                // precioTotal,
                // precioUnitario,
                // precioUnitarioDescuento,
                // precioUnitarioDescuentoConvenio,
                // precioUnitarioTotal
            } = tmp;

            partidasServId.push(servicioId);
            partidasCant.push(cantidad);
        });

        ticketTemp = { encabezado, formasPago, partidasServId, partidasCant };

    }

    return ticketTemp;
}
