import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Componentes
import MenuLateral from '../../componentesGenericos/SideBar';
import { Grid, Card, CardContent, ListItemIcon, ListItemText, ListItem, List, Divider } from '../../componentesGenericos/Core';

// Componentes genéricos
import Icono from '../../componentesGenericos/Icono';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
import Mensaje from '../Reportes/componentes/Mensaje';

// Configuraciones
import ConfiguracionGeneral from './componentes/ConfiguracionGeneral';
import ConfiguracionLogotipo from './componentes/ConfiguracionLogotipo';
import ConfiguracionPuntoVenta from './componentes/ConfiguracionPuntoVenta';
import ConfiguracionQrEmpresa from './componentes/ConfiguracionQrEmpresa';
import ConfiguracionCorreoRegistro from './componentes/ConfiguracionCorreoRegistro';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	details: {
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
        flex: '2 0 auto'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	playIcon: {
		height: 38,
		width: 38
    }
}));

function Configuracion() {

    const classes = useStyles();

    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    const defaultSelected = {
        general: false,
        logotipo: false,
        passwordSeguridad: false
    };

    const [ mensaje, setMensaje ] = useState(defaultMensaje);
    const [ selected, setSelected ] = useState(defaultSelected);
    const [ componente, setComponente ] = useState();

    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setMensaje({
            ...mensaje,
            visible: false
        });
    };

    const setMensajeNot = (props) => {
        setMensaje({
            ...mensaje,
            ...props
        });
    }

    const configGeneral = <ConfiguracionGeneral setMensaje={setMensajeNot} />;
    const configLogotipo = <ConfiguracionLogotipo setMensaje={setMensajeNot} />;
    const configPuntoVenta = <ConfiguracionPuntoVenta setMensaje={setMensajeNot} />;
    const ConfigQrEmpresa = <ConfiguracionQrEmpresa setMensaje = {setMensajeNot}/>;
    const ConfigCorreoRegistro = <ConfiguracionCorreoRegistro setMensaje = {setMensajeNot}/>

    const menuConfiguracion = [{
        id: "general",
        descripcion: "General",
        componente: configGeneral,
        icono: <Icono ruta="/icons/icons8-ajustes-128.png" texto="configuracion" />
    },{
        id: "logotipo",
        descripcion: "Logotipo",
        componente: configLogotipo,
        icono: <Icono ruta="/icons/icons8-foto-128.png" texto="configuracion-logotipo" />
    },{
        id: "passwordSeguridad",
        descripcion: "Contraseña de seguridad",
        componente: configPuntoVenta,
        icono: <Icono ruta="/icons/icons8-llave-128.png" texto="configuracion-punto-venta" />
    },
    {
        id: "correoRegistro",
        descripcion: "Correo de registro",
        componente: ConfigCorreoRegistro,
        icono: <Icono ruta="/icons/icons8-enviar-box-64.png" texto="configuracion-correo-registro" />
    },
];

    useEffect(() => {
        setComponente(configGeneral);
        setSelected({
            ...selected,
            general: true
        });
    }, []);

	return (
		<MenuLateral titulo="Configuración">
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={12}>
					<Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                            <Encabezado titulo="Configuración" />
                        </Grid>
						<Grid item xs={12} sm={12} md={3} lg={4}>
							<Card>
								<div>
									<CardContent>
										<List>
											{menuConfiguracion.map(({id, descripcion, componente, icono}, index) => (
												<Fragment key={index}>
                                                    <ListItem
                                                        selected={selected[id]}
                                                        button
                                                        onClick={() => {
                                                            setComponente(componente);
                                                            setSelected({
                                                                ...defaultSelected,
                                                                [id]: true
                                                            });
                                                    }}>
                                                        <ListItemIcon>
                                                            {icono}
                                                        </ListItemIcon>
                                                        <ListItemText primary={descripcion} />
                                                    </ListItem>
                                                    <Divider />
                                                </Fragment>
											))}
										</List>
									</CardContent>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} md={8} lg={6}>
                            <Card>
                                <div>
                                    <CardContent>
                                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                            {componente}
                                        </Grid>
                                    </CardContent>
                                </div>
                            </Card>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
            <Mensaje visible={mensaje.visible} descripcion={mensaje.descripcion} tipo={mensaje.tipo} handleClose={handleCloseMensaje} />
		</MenuLateral>
	);
}

export default Configuracion;
