import React, { useState, useEffect } from 'react'

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { ObtenerConfiguracion } from "./Redux/Actions/ConfiguracionAction";
import { ObtenerMenu } from "./Redux/Actions/MenuAction";

// Configuracion
import { queryConfiguracion } from './componentes/Configuracion/Metodos';

//Apollo
import { useQuery } from '@apollo/react-hooks';

//Router
import { Switch, Route as PublicRoute, withRouter } from 'react-router-dom'
import { PrivateRoute } from './componentesGenericos/Estructura/PrivateRoute';

//Queries
import { OBTENER_PERMISO } from './query';
import { LISTADO_NOTIFICACIONES } from './query';

// Tipos de Usuarios
import { TipoUsuario } from './Helpers/Enums';
import { DecodificarToken } from "./componentesGenericos/Estructura/Autenticar";
import { obtenerIDSelectURL, limpiarMenu } from "./componentes/MenuPrincipal/helper";
import { menuAdminUsuario, menuPacienteDoctor } from './componentes/MenuPrincipal/menu';
import useMediaQuerys from './componentesGenericos/MediaQuerys';

//Componentes
import AutorizacionDatos from '../src/componentes/AutorizacionDatos';
import Garantias from './componentes/Garantias';
import Promociones from './componentes/Promociones';
import Login from './componentes/Login';
import LoginKalenday from './componentes/LoginKalenday/';
import Clinicas from './componentes/Clinicas';
import ClinicaUsuario from './componentes/Clinicas/ClinicaUsuario';
import Agenda from './componentes/Agenda';
import PuntoVenta from './componentes/PuntoVenta';
import Telefonos from './componentes/Clinicas/Telefonos/Telefonos';
import HorariosDoctor from './componentes/Horarios/HorariosDoctor';
import HorariosClinica from './componentes/Horarios/HorariosClinica';
import Usuarios from './componentes/Usuarios';
import Convenio from './componentes/Convenios';
import Especialidades from './componentes/Especialidades';
import EstatusAgenda from './componentes/EstatusAgenda';
import AsignarClinicasProfesionales from './componentes/AsignarClinicasProfesionales';
import Recordatorios from './componentes/Recordatorios';
import MovimientosAlmacen from './componentes/MovimientosAlmacen';
import CentroCostos from './componentes/CentroCostos';
import Seguridad from './componentes/Seguridad';
import Inventario from './componentes/Inventario';
import Perfil from './componentes/Perfil';
import ConvenioServicios from './componentes/ConvenioServicios';
import AsignarServicios from './componentes/AsignarServicios';
import Cliente from './componentes/Cliente';
import Profesionales from './componentes/Profesionales';
import Almacen from './componentes/Almacen';
import DirecionesClinica from './componentes/DireccionesClinica';
import Reportes from './componentes/Reportes';
import Notificaciones from './componentes/Notificaciones/Listado';
import Turno from './componentes/PuntoVenta/Turno';
import Tickets from './componentes/Ticket';
import Ticket from './componentes/Ticket/Diseño';
import TicketCredito from './componentes/Ticket/DiseñoCredito';
import Serie from './componentes/Serie';
import LoginPuntoVenta from './componentes/PuntoVenta/LoginPuntoVenta';
import AsignarUsuariosClinicas from './componentes/AsignarClinicasUsuarios';
import Loader from './componentes/Loader';
import Anamnesis from './componentes/Anamnesis';
import FormaCobro from './componentes/PuntoVenta/FormaCobro';
import ConveniosClientes from './componentes/ConveniosCliente';
import TipoPadecimiento from './componentes/TipoPadecimiento';
import AnamnesisPaciente from './componentes/Anamnesis/VistaPaciente';
import Configuracion from './componentes/Configuracion';
import ServiciosPaciente from './componentes/Paciente/Servicios/servicios';
import AccesoNoValido from './componentes/AccesoNoValido';
import ProfesionalesPaciente from './componentes/ProfesionalesPaciente';
import CambioEstatusAgenda from './componentes/CambioEstatusAgenda';
import Existencias from './componentes/Inventario/Existencias';
import TodosMovimientosAlmacen from './componentes/MovimientosAlmacen/MovimientosTodos';
import Receta from './componentes/Receta';
import Comisiones from './componentes/Comisiones';
import PlanesCredito from './componentes/PlanesCredito';
import ComisionesProfesionales from './componentes/ComisionesProfesionales';
import PagosClientes from './componentes/PagosClientes';
import RecordatorioCredito from './componentes/RecordatoriosCreditos';
import TicketCreditoToPdf from './componentes/Ticket/DiseñoCredito/toPDF';
import HistorialPagos from './componentes/HistorialPago';
import HistorialClinico from './componentes/Anamnesis/HistorialClinico';
import Servicios from './componentes/ProductosServicios/Servicios';
import Productos from './componentes/ProductosServicios/Productos';
import Materiales from './componentes/ProductosServicios/Materiales';
import ConveniosRegistro from './componentes/ConvenioRegistro';
import Promotores from './componentes/Promotores';
import { obtenerStateStorage } from './Helpers/LocalStorage/LocalStorage'
import { socket } from './App';

function Rutas({ history }) {

    const vista = window.location.pathname.replace(/^\/(\w+\-*\w*)(\#.*)*(\/.*)*$/, '$1');
    let sesiones = process.env.REACT_APP_DB;
    let dominioURL = window.location.href;
    let separador = dominioURL.split("/");
    //#region Configuracion
    const dispatch = useDispatch();
    const setConfiguracion = (datos) => dispatch(ObtenerConfiguracion(datos));
    const setMenu = (datos) => dispatch(ObtenerMenu(datos));
    const activarSonido = useSelector((state) => state?.notificacion?.notificacion[0]);

    const actConfiguracion = () => {
        async function ftEffect() {

            const config = await queryConfiguracion({nombreDB: sesiones });
            setConfiguracion({ ...config });
        } ftEffect();
    };

    //#endregion
    let { isTabletOrMobile, isTabletOrMobileDevice } = useMediaQuerys();
    let responsiveMovil = isTabletOrMobile || isTabletOrMobileDevice;


    let noOpcionesResponsive = {
        // inventarios: { movsInventario: true },
        ventas: { puntoVenta: true, pagosClientes: true, tickets: true },
        reportes:{reportes:true}
    };


    actConfiguracion();
    //#endregion

    const menuSeleccion = useSelector(state => state.menu);
    const user = useSelector((state) => state.user.usuario[0].user.user);
    let userToken = DecodificarToken(user.token);

    const bTipoPaciente = TipoUsuario.CLIENTE.isEqual(userToken.tipo);
    const bTipoDoctor = TipoUsuario.PROFESIONAL.isEqual(userToken.tipo);
    const bTipoUsuario = TipoUsuario.USUARIO.isEqual(userToken.tipo);
    const bTipoAdmin = TipoUsuario.ADMINISTRADOR.isEqual(userToken.tipo);

    let usuarioMenuRedux = bTipoPaciente || bTipoDoctor ? "menuPacienteDoctor" : "menuAdminUsuario";
    let menu = bTipoPaciente || bTipoDoctor ? menuPacienteDoctor : menuAdminUsuario;

    let { open, select } = obtenerIDSelectURL(menu, vista);

    let limpiar = limpiarMenu({ ...menuSeleccion });
    const fecha = new Date();

    if (typeof menuSeleccion[usuarioMenuRedux][select] === 'boolean' && !menuSeleccion[usuarioMenuRedux][select]) {
        let openMenu = open ? { ...limpiar.openMenu, [open]: true } : { ...limpiar.openMenu };
        setMenu({
            ...limpiar,
            openMenu,
            [usuarioMenuRedux]: {
                ...limpiar[usuarioMenuRedux],
                [select]: true
            }
        });
    }

    if (menuSeleccion[usuarioMenuRedux][select] === undefined && !menuSeleccion["nada"]["nada"]) { setMenu({ ...limpiar, nada: { nada: true } }); }

    //#region Querys
    const { loading, data } = useQuery(OBTENER_PERMISO, {
        fetchPolicy: "cache-and-network",
        variables: { tipo: userToken.tipo, vista },
        onError: (e) => {
            if (sesiones) {
                if (separador[3] !== "registro") //esto es para la página de registro
                        {
                            history.push("/"); //leer texto comentado del if separador[3] !== "registro"
                        }
                //history.push("/login"); //se podría obtener el dominio principal y comparando si se esta en el dominio principal no se haga el history.push ya que este lo hace para obtener los permisos correspondientes del menú. Esto no debe de ir en la página de Kalenday.app ya que siempre regresará a la misma por no contar con permisos en ese momento.
                console.log("Opcion no permitida");
            }
        },
        onCompleted: () => {
            if (sesiones) {
                if (data) {
                    if (data.obtenerPermiso !== 1) {
                        if (separador[3] !== "registro") //esto es de la página de registro, ya que no se tiene una sesión inicada porque apenas se registrarán
                        {
                            history.push("/Menu"); //aquí es el mismo caso mencionado anteriormente del history(/login)
                        }
                    }
                }
            }
        },
    });

    const { data: listado } = useQuery(LISTADO_NOTIFICACIONES, {
        variables: { id: userToken.usuarioId },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            if (e.message === "GraphQL error: No se encontraron registros") {

            } else {
                console.log(e.message)
            }
        },
        onCompleted: () => {
            let cont = 0;
            if (listado) {

                listado.listadoNotificaciones.notificaciones.map(item => {
                    if (item.estatus === 1 && item.fecha.split("T")[0] <= fecha.toISOString().split("T")[0]) {
                        cont++;
                        return null
                    }
                });

            }

            if (cont !== 0) {
                if (activarSonido) {
                    if (activarSonido.activo === true) {
                        let audio = new Audio();
                        audio.src = "./audio/notificacion2.mp3";
                        audio.play();
                    }
                }
            }
        }
    });
    //#endregion

    let rutas = [
        {
            path: "/historialPagos",
            component:  AccesoNoValido,
            responsive: true
        },
        {
            path: "/recordatoriosCredito",
            component: AccesoNoValido,
            responsive: true,
        },
        {
            path: "/comisionesProfesionales",
            component: (bTipoAdmin) ? ComisionesProfesionales : AccesoNoValido,
            responsive: true
        },
        {
            path: "/direccion/:id",
            component: (bTipoUsuario || bTipoAdmin) ? DirecionesClinica : AccesoNoValido,
            responsive: true
        },
        {
            path: "/autorizacionDatos",
            component: (bTipoAdmin) ? AutorizacionDatos : AccesoNoValido,
            responsive: true
        },
        {
            path: "/servicios",
            component: bTipoAdmin ? Servicios : ServiciosPaciente,
            responsive: true
        },
        {
            path: "/productos",
            component: (bTipoAdmin) ? Productos : AccesoNoValido,
            responsive: true
        },
        {
            path: "/materiales",
            component: (bTipoAdmin) ? Materiales : AccesoNoValido,
            responsive: true
        },
        {
            path: "/garantias",
            component:  AccesoNoValido,
            responsive: true,
        },
        {
            path: "/sucursales",
            component: (bTipoPaciente || bTipoDoctor) ? ClinicaUsuario : Clinicas,
            responsive: true

        },
        {
            path: "/promociones",
            component: (bTipoUsuario || bTipoAdmin) ? Promociones : AccesoNoValido,
            responsive: true
        },
        {
            path: "/Menu",
            component: Agenda,
            responsive: true
        },
        {
            path: "/telefonos/:id",
            component: (bTipoUsuario || bTipoAdmin) ? Telefonos : AccesoNoValido,
            responsive: true
        },
        {
            path: "/horarioprofesional",
            component: (bTipoUsuario || bTipoAdmin) ? HorariosDoctor : AccesoNoValido,
            responsive: true
        },
        {
            path: "/horariosucursal",
            component: (bTipoUsuario || bTipoAdmin) ? HorariosClinica : AccesoNoValido,responsive: true
        },
        {
            path: "/agenda",
            component: Agenda,
            responsive: true
        },
        {
            path: "/puntoventa",
            component: (bTipoUsuario || bTipoAdmin) ? PuntoVenta : AccesoNoValido,responsive: true
        },
        {
            path: "/loginpuntoventa",
            component: (bTipoUsuario || bTipoAdmin) ? LoginPuntoVenta : AccesoNoValido,responsive: responsiveMovil ? false : true
        },
        {
            path: "/ticket",
            component: (bTipoUsuario || bTipoAdmin) ? Tickets : AccesoNoValido,responsive: responsiveMovil ? false : true
        },
        {
            path: "/movsalmacen/:encabezadoID/:servicioID",
            component: (bTipoUsuario || bTipoAdmin) ? MovimientosAlmacen : AccesoNoValido,responsive: true
        },
        {
            path: "/movimientosalmacen",
            component: (bTipoAdmin) ? TodosMovimientosAlmacen : AccesoNoValido,responsive: true
        },
        {
            path: "/almacen",
            component: (bTipoUsuario || bTipoAdmin) ? Almacen : AccesoNoValido,responsive: true
        },
        {
            path: "/usuarios",
            component: (bTipoAdmin || bTipoUsuario) ? Usuarios : AccesoNoValido,responsive: true
        },
        {
            path: "/convenios",
            component: (bTipoAdmin || bTipoUsuario) ? Convenio : AccesoNoValido,responsive: true
        },
        {
            path: "/promotores",
            component: (bTipoAdmin || bTipoUsuario) ? Promotores : AccesoNoValido,responsive: true
        },
        {
            path: "/especialidades",
            component: bTipoAdmin ? Especialidades : AccesoNoValido,responsive: true
        },
        {
            path: "/estatusagenda",
            component: (bTipoUsuario || bTipoAdmin) ? EstatusAgenda : AccesoNoValido,responsive: true
        },
        {
            path: "/recordatorios",
            component: (bTipoUsuario || bTipoAdmin) ? Recordatorios : AccesoNoValido,responsive: true
        },
        {
            path: "/asignarSucursalesprofesionales",
            component: (bTipoAdmin || bTipoUsuario) ? AsignarClinicasProfesionales : AccesoNoValido,responsive: true
        },
        {
            path: "/centrocostos",
            component: AccesoNoValido,
            responsive: true
        },
        {
            path: "/inventario",
            component: (bTipoAdmin || bTipoUsuario) ? Inventario : AccesoNoValido,responsive: true
        },
        {
            path: "/convenioservicios",
            component: (bTipoAdmin || bTipoUsuario) ? ConvenioServicios : AccesoNoValido,responsive: true
        },
        {
            path: "/asignarservicios",
            component: (bTipoAdmin || bTipoUsuario) ? AsignarServicios : AccesoNoValido,responsive: true
        },
        {
            path: "/perfil",
            component: Perfil,responsive: true
        },
        {
            path: "/seguridad",
            component: bTipoAdmin ? Seguridad : AccesoNoValido,responsive: true
        },
        {
            path: "/clientes",
            component: (bTipoAdmin || bTipoUsuario) ? Cliente : AccesoNoValido,responsive: true
        },
        {
            path: "/profesionales",
            component: (bTipoAdmin ) ? Profesionales : AccesoNoValido,
            responsive: true
        },
        {
            path: "/notificaciones",
            component: Notificaciones,responsive: true
        },
        {
            path: "/turno",
            component: (bTipoAdmin || bTipoUsuario) ? Turno : AccesoNoValido,
            responsive: true
        },
        {
            path: "/reportes",
            component: (bTipoAdmin || bTipoUsuario) ? Reportes : AccesoNoValido,
            responsive: responsiveMovil ? false : true
        },
        {
            path: "/serie",
            component: bTipoAdmin ? Serie : AccesoNoValido,responsive: true
        },
        {
            path: "/asignarUsuariosSucursales",
            component: (bTipoAdmin || bTipoUsuario) ? AsignarUsuariosClinicas : AccesoNoValido,
            responsive: true
        },
        {
            path: "/anamnesis",
            component: bTipoDoctor ? Anamnesis : AccesoNoValido,
            responsive: true
        },
        {
            path: "/formaCobro",
            component: bTipoAdmin ? FormaCobro : AccesoNoValido,
            responsive: true
        },
        {
            path: "/asignarconveniosclientes",
            component: (bTipoAdmin || bTipoUsuario) ? ConveniosClientes : AccesoNoValido,
            responsive: true
        },
        {
            path: "/tipoPadecimiento",
            component: bTipoDoctor ? TipoPadecimiento : AccesoNoValido,responsive: true
        },
        {
            path: "/configuracion",
            component: bTipoAdmin ? Configuracion : AccesoNoValido,
            responsive: true
        },
        {
            path: "/profesional",
            component: bTipoPaciente || bTipoDoctor ? ProfesionalesPaciente : AccesoNoValido,
            responsive: true
        },
        {
            path: "/cambioEstatus",
            component: bTipoAdmin ? CambioEstatusAgenda : AccesoNoValido,responsive: true
        },
        {
            path: "/existencias/:id/:clinicId",
            component: (bTipoAdmin || bTipoUsuario) ? Existencias : AccesoNoValido,
            responsive: true
        },
        {
            path: "/receta/:agendaId/:clinicaDoctoId/:pacienteId/:clinicaID",
            component: bTipoDoctor ? Receta : AccesoNoValido,
            responsive: true
        },
        {
            path: "/comisiones",
            component: Comisiones,
            responsive: true
        },
        {
            path: "/planCredito",
            component: AccesoNoValido,
            responsive: true
        },
        {
            path: "/pagosClientes",
            component: AccesoNoValido,
            responsive: responsiveMovil ? false : true
        },
        {
            path: "/historialAnamnesis/:pacienteId",
            component: bTipoDoctor ? HistorialClinico : AccesoNoValido,
            responsive: true
        }

    ]

    function generar() {
        let rutasGen = [];

        if (!loading) {
            rutasGen = rutas.map(ruta => {
                return <PrivateRoute path={ruta.path} component={ruta.component} key={ruta.path} responsivo = {ruta.responsive} />
            });

            rutasGen.push(<PrivateRoute path={"/notificaciones"} component={Notificaciones} key="notifh" responsivo= {true} />)
            rutasGen.push(<PrivateRoute path={"/ticket"} component={Tickets} key="ticketh" responsivo= {true} />)
            rutasGen.push(<PrivateRoute path={"/ver-ticket/:id"} component={Ticket} key="vertickh" responsivo= {true} />)
            rutasGen.push(<PrivateRoute path={"/enviar-ticket/:clienteId/:ticketEncabezadoId/:ticketId"} component={TicketCredito} key="enviartickh" responsivo= {true} />)
            rutasGen.push(<PrivateRoute path={"/ticketCredito/:clienteId/:ticketEncabezadoId/:ticketID"} component={TicketCreditoToPdf} key="enviartickCredh" responsivo= {true} />)
            rutasGen.push(<PrivateRoute path={"/Menu"} component={Agenda} key="menuh"responsivo= {true}  />)
            rutasGen.push(<PrivateRoute path={"/ver-receta/:id"} component={Receta} key="recetah" responsivo= {true} />)
        }

        //rutasGen.push(<PublicRoute path={"/login"} component={Login} key="loginEmpresa" responsivo= {true} />);
        rutasGen.push(<PublicRoute path={"/registro/:datos"} component={ConveniosRegistro} key="registroConvenio" responsivo= {true} />);
        rutasGen.push(<PublicRoute path={"/"} component={user.token ? Loader : Login} key="loader" responsivo= {true} />);
        return rutasGen
    }

    useEffect(() => {
        if (socket) {
            if (userToken) {
                socket.emit('register', userToken.usuarioId);
            }
        }
    }, [])

    return (
        <Switch>

            {
                generar()
            }

        </Switch>
    )
}

export default withRouter(Rutas);
