import React, { useContext, useEffect} from 'react';
//Core
import { Grid,TextField } from '../../../../componentesGenericos/Core';
//REDUX*****
import { useSelector } from "react-redux";
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/AsignacionSeries';
//Help
import {FetchGrahpQL} from '../../../../Helpers/Fetch/FetchGraphql';
import {SELECT_CLINICAS_FETCH} from '../../../../query';


function Modificar(props) {

    //Declaraciones
    let{seleccion}=props;
    const [datos, guardarDatos] = useContext(CRMContext);//state compartido
    const [clinicas,guardarClinicas]=React.useState('');
    const clinica = useSelector((state) => state.selecciondeClinica);

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value=value.toUpperCase();
        value=value.trim();
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                Serie: {
                    ...datos.Serie,
                    [name]: {
                        ...datos.Serie[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Serie: {
                    ...datos.Serie,
                    [name]: {
                        ...datos.Serie[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }
    const obtenerClinicas =async()=>{
        await FetchGrahpQL({
            query: SELECT_CLINICAS_FETCH,
            variables: {
                limite: 10000,activas:true
            }
        })
            .then(resp => {
                guardarClinicas({datos:resp.listadoClinicas})
            })
            .catch((err) => {
            });
    }

    //Ciclo de vida
    useEffect(() => {
        guardarDatos(data=>({
            ...data,
            Serie: {
                ...data.Serie,
                clinicaId: {
                    ...data.Serie.clinicaId,
                    error: false, valor:seleccion.clinicaId
                },
                serieNombre: {
                    ...data.Serie.serieNombre,
                    error: false, valor: seleccion.serieNombre
                },
                nombreClinica: {
                    ...data.Serie.nombreClinica,
                    error: false, valor: seleccion.nombreClinica
                },
                serieId: {
                    ...data.Serie.serieId,
                    error: false, valor: seleccion.serieId
                },
                tipoDocumentoId:{
                    ...data.Serie.tipoDocumentoId,
                    error: false, valor: seleccion.tipoDocumentoId
                },
                nombreDocumento:{
                    ...data.Serie.nombreDocumento,
                    error: false, valor: seleccion.nombreDocumento
                }
            }
        }));
        obtenerClinicas();
    },[]);
    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>

                        <TextField margin="dense" name="nombreClinica" id={`nombreClinica`} label="Nombre de la sucursal" type="text" style={{ width: "100%" }}  onChange={handleChangeGuardarState}  defaultValue={seleccion.nombreClinica} disabled error={datos.Serie.nombreClinica.error} />

                        <TextField margin="dense" name="nombreDocumento" id={`nombreDocumento`} label="Tipo de documento" type="text" style={{ width: "100%" }}  onChange={handleChangeGuardarState}  defaultValue={seleccion.nombreDocumento} disabled error={datos.Serie.nombreDocumento.error} />


                        <TextField margin="dense" name="serieNombre" id={`serieNombre`} label="Serie" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarState} required={true} helperText="La serie es obligatoria" error={datos.Serie.serieNombre.error}  value={datos.Serie.serieNombre.valor}/>

                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Modificar;
