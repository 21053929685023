import React, { useState } from 'react';
//apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries
import { LISTADO_MOVIMIENTOS_ALMACEN, OBTENER_MOVIMIENTOS_ALMACEN } from '../../../query';
//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/Formulario';
import { Grid, CircularProgress } from '../../../componentesGenericos/Core';
//Constantes
import { columnas, Style, actualizarState, filaState } from '../Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../../Helpers/constantes';
//Redux
import { useSelector, useDispatch } from 'react-redux';
//Router
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, useParams } from "react-router-dom";

export default function TodosMovimientosAlmacen() {
    let { encabezadoID, servicioID } = useParams();
    // console.log("useparams: ", encabezadoID, servicioID)
    const _clinicaID = useSelector(state => state.selecciondeClinica.seleccionClinica[0].clinicaId);
    // console.log("_clinicaID", _clinicaID)
    // # Region Queries & Mutations
    const { loading, error, data, refetch } = useQuery(LISTADO_MOVIMIENTOS_ALMACEN, {
        variables: { limite: 100, pagina: 1, clinicaId: _clinicaID !== 0 ? Number(_clinicaID) : null, encabezadoId:  null, servicioId: null },
        fetchPolicy: 'no-cache',
        onError: (e) => {
            setErrorState(true);
            // console.log("error", e.message)
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });
    // # Región States
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(actualizarState);
    const [fila, setFila] = useState(filaState);
    const [errorState, setErrorState] = useState(false);

    //Region Handlers
    function handleBusquedaChange(texto) {
        setFiltro(texto)
    }

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data ?.listadoMovsAlmacen ?.MovsAlmacenes;

    // Recargar
    if (actualizar === true) {
        refetch(); setActualizar(false);
    }
    return (
        <MenuLateral>
            <Mensaje />
            <Formulario />

            <Tabla
                titulo="Movimientos inventario"
                columnas={columnas}
                datos={listado}
                onSearch={handleBusquedaChange}
                indice={fila}
                filtro={filtro}
                loading={loading}
                msjError={error ?.mensaje}
                titleResponsive={["nombre", "nombreAlmacen", "servicio"]}
            />

        </MenuLateral>
    )
}
