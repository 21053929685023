import React, { useState } from 'react';
const CRMContext = React.createContext([{}, () => { }]);

const CRMProvider = props => {

    const [usuarios, guardarUsuarios] = useState({
        Usuario: {
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            password: { error: false, valor: "" },
            cedula: { serror: false, valor: "" },
            sexo: { error: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            estatus:{error:false,valor:""},
            telefono:{error:false,valor:""},
            desactivarBoton: { error: false,valor:""},
            fechaNacimiento:{error:false,valor:""},
            recuperarContraseña:{error:true,valor:""},
            showPassword:{error:false,valor:""},
            especialidadId: { error: false, valor: "" },
            solicitanteId:{ error: false, valor: "" },
            cedula: { serror: false, valor: "" }
        },
        Paciente: {
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            password: { error: false, valor: "" },
            sexo: { error: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            telefono:{error:false,valor:""},
            desactivarBoton: { error: false,valor:""},
            fechaNacimiento:{error:false,valor:""},
            showPassword:{error:false,valor:""},
            especialidadId: { error: false, valor: "" },
            solicitanteId:{ error: false, valor: "" },
            estatus:{error:false,valor:""},
            cedula: { serror: false, valor: "" },
        },
        Administrador: {
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            password: { error: false, valor: "" },
            sexo: { error: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            telefono:{error:false,valor:""},
            desactivarBoton: { error: false,valor:"" },
            desactivarBoton: { error: false,valor:""},
            fechaNacimiento:{error:false,valor:""},
            showPassword:{error:false,valor:""},
            especialidadId: { error: false, valor: "" },
            solicitanteId:{ error: false, valor: "" },
            estatus:{error:false,valor:""},
            cedula: { serror: false, valor: "" }
        },
        Doctor:{
            nombres: { error: false, valor: "" },
            apellidos: { error: false, valor: "" },
            email: { error: false, valor: "" },
            password: { error: false, valor: "" },
            sexo: { error: false, valor: "" },
            especialidadId: { error: false, valor: "" },
            cedula: { serror: false, valor: "" },
            usuarioId:{error:false,valor:""},
            fechaEgreso:{error:false,valor:""},
            telefono:{error:false,valor:""},
            desactivarBoton: { error: false,valor:""},
            fechaNacimiento:{error:false,valor:""},
            showPassword:{error:false,valor:""},
            solicitanteId:{ error: false, valor: "" },
            estatus:{error:false,valor:""},

        },
        AbrirModalModificacion:{
            Open:false
        },
        desactivarBoton: { error: false
        }

    });
    return (
        <CRMContext.Provider value={[usuarios, guardarUsuarios]}>
            {props.children}
        </CRMContext.Provider>
    )
}

export { CRMContext, CRMProvider };
