import React, { useContext } from 'react';
import { CRMContext, CRMProvider } from '../../context/Reporte';
import Reportes from './Reportes';

function ReporteContext() {

    const [actualizar, setActualizar] = useContext(CRMContext);

    return (
        <CRMProvider value={[actualizar, setActualizar]}>
            <Reportes />
        </CRMProvider>
    );
}

export default ReporteContext;
