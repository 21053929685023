import gql from 'graphql-tag';

const LISTAR_FORMA_COBRO = gql`

  query listadoFC($clinicaId: Int){
  listadoFormaCobro(clinicaId: $clinicaId){
  formaCobros{
      formaCobroId
    formaCobro
    descripcion
    clinicaId
    nombreClinica
    aliasClinica
    estatusClinica
    claveClinica
    fechaAltaClinica
  }
  registros
  paginas
  }
}
`;

const LISTAR_FORMA_COBRO_FETCH = `
    query listadoFC($clinicaId: Int) {
        listadoFormaCobro(clinicaId: $clinicaId){
            formaCobros{
                formaCobroId
                formaCobro
                descripcion
                clinicaId
                nombreClinica
                aliasClinica
                estatusClinica
                claveClinica
                fechaAltaClinica
            }
            registros
            paginas
        }
    }
`;

export {
    LISTAR_FORMA_COBRO,
    LISTAR_FORMA_COBRO_FETCH
}
