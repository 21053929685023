import React from 'react';
import { mensajeInicial } from '..';
import { strUrlSArchivos } from '../Config/UrlServer';
//const blobToBase64 = require('blob-to-base64');

export async function getImagen(rutaimagen) {

    let resultado ='';
    var i='';
    if (rutaimagen != "")
    {
        try {
            let tokenfijo = "c29mdHdvbmUyMDIwQCM=";
            const response = await fetch(
                `${rutaimagen}`,//url de la imagen
                {
                    method: "GET",
                    headers: {
                        token: tokenfijo//props.token
                    }
                }
            )
                .then(res => res.blob())
                //.then(blob => {return blob});
                .then(blob => {

                  let r = blobToBase64(blob).then(res => {
                    return res;
                  });
                  return r;

                });

           resultado = response;

        } catch (error) {
            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde ` );
        }

    }
    return resultado;

 };
 export async function postImagen(rutaimagen, archivo, privado) {

    let rutaArchivo = '';

        try {
            const formData = new FormData();
            formData.append(
                "archivo", archivo//foto.archivo.valor
            );
            formData.append(
                "aplicacion", "kalenday"
            );
            formData.append(
                "ruta", rutaimagen//"imagenes/fotoPerfil"
            );
            if (privado === 1)
            {
                formData.append(
                    "privado", "true"
                );
            }
            else{
                formData.append(
                    "publico", "true"
                );
            }
           // console.log(formData.getAll('archivo'));
            let tokenfijo = "c29mdHdvbmUyMDIwQCM=";
            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "POST",
                    headers: {
                        token: tokenfijo//props.token
                    },
                    body: formData
                }
            )
                .then(res => res.json())
                .then(res => {
                    if (res.ok ===  true) {
                        let url = res.resultado;
                        let tam = url.length;
                        let base = strUrlSArchivos+'/privado/kalenday';
                        let tam2 = base.length;
                        if (privado == 0)
                        {
                            tam2 = tam2 -1;
                        }
                        url = url.substring(tam2);
                        return url;
                    } else {
                        console.log(res.mensaje);
                    }
                });
        rutaArchivo = response;
        } catch (error) {

            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `);
        }

    return rutaArchivo;

 };
 export async function putImagen(rutaimagen, archivo) {

    let respuesta = true;

    try {
            const formData = new FormData();
            formData.append(
                "archivo", archivo//foto.archivo.valor
            );
            formData.append(
                "ruta", rutaimagen//"imagenes/fotoPerfil"
            );

           // console.log(formData.getAll('archivo'));
            let tokenfijo = "c29mdHdvbmUyMDIwQCM=";
            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "PUT",
                    headers: {
                        token: tokenfijo//props.token
                    },
                    body: formData
                }
            )
                .then(res => res.json())
                .then(res => {
                   return res.ok;
                });
        respuesta = response;
        } catch (error) {

            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `);
        }

    return respuesta;

 };
 export async function deleteArchivo(rutaimagen) {

    let respuesta = true;

        try {
            const formData = new FormData();
            formData.append(
                "ruta", rutaimagen//"imagenes/fotoPerfil"
            );

           // console.log(formData.getAll('archivo'));
            let tokenfijo = "c29mdHdvbmUyMDIwQCM=";
            let response = await fetch(
                `${strUrlSArchivos}/upload`,
                {
                    method: "DELETE",
                    headers: {
                        token: tokenfijo//props.token
                    },
                    body: formData
                }
            )
                .then(res => res.json())
                .then(res => {
                   return res.ok;
                });
        respuesta = response;
        } catch (error) {

            console.log(`Ha ocurrido algo inesperado, inténtalo  más tarde `);
        }

    return respuesta;

 };

 const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        resolve(dataUrl);
      };
    });
  };






/*export function getImagen() {
    async function ftGuardar() {

        let { compradorID, comprador } = state;

        let error = await handleValidaciones({ comprador });
        if(error) return error;

        if(compradorID) await updateComCompradores({
            input: { compradorID, comprador: comprador.toUpperCase()}
        }, token);
        else await addComCompradores({
            input: { comprador: comprador.toUpperCase() }
        }, token);

    }
    return ftGuardar();
}*/

