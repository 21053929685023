import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, List, ListItemText, Button } from '../../../../componentesGenericos/Core';
import { FILTRO_RECETA_PARTIDAS_USER_FETCH } from '../../../../query';
import { FetchGrahpQL } from '../../../../Helpers/Fetch/FetchGraphql';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Fieldset from '../../../../componentesGenericos/FieldSet';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '551px',
        minWidth: '550px',
        backgroundColor: theme.palette.background.paper,
        padding: '5%'
    },
    inline: {
        display: 'inline',
    },
}));

function FormularioPartidasReceta({ recetaId, abrir, onClose = () => { } }) {
    const classes = useStyles();
   

    const [datosModal, setDatosModal] = useState([]);
    const FetchInformacionReceta = async () => {
        FetchGrahpQL({
            query: FILTRO_RECETA_PARTIDAS_USER_FETCH,
            variables: {
                recetaId: recetaId
            }
        })
            .then((data) => {
                setDatosModal(data.FiltroPartidasUsuario.RecetaPartidaUser)
            })
            .catch((e) => {
                console.log(e.message)
            })
    }

    const ftGenerarCuerpo = (data) => {
        let row = [];
        if (data !== undefined) {
            data.map(function (x, index) {
                row.push(
                    <>
                        <ListItemText
                            primary={
                                <Typography
                                    component="span"
                                    variant="h5"
                                    className={classes.inline}
                                    color="textPrimary"
                                >
                                    {`${x.productoNoenVenta === "" ? x.descripcionProducto : x.productoNoenVenta} (${x.cantidad} Caja/s)`}
                                </Typography>
                            }
                            secondary={
                                <>
                                    {/* {" — "} */}
                                    <Typography
                                        component="span"
                                        variant="subtitle1"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {`${x.anotacionesProducto}`}
                                    </Typography>

                                </>
                            }
                        />
                        <Divider variant="inset" component="li" />
                    </>
                );
                return true;
            });
            return row;
        }
    }

    useEffect(() => {
        setDatosModal([])
        FetchInformacionReceta();
    }, [abrir]);
   

    return (
        <Dialog open={abrir} aria-labelledby="partidas-receta">
            <Fieldset
                Titulo={
                    <>  <DialogTitle id="scroll-dialog-title" >

                        <Typography
                            component="span"
                            variant="h4"
                            className={classes.inline}
                            color="textPrimary"
                        >
                            Receta
                </Typography>
                    </DialogTitle>
                    </>}
                contenido={
                    <>  <List className={classes.root}>
                        {ftGenerarCuerpo(datosModal)}
                    </List></>
                }
            />
             <Button onClick= {onClose} color="primary">
                Cerrar
            </Button>
        </Dialog>
    )
}

export default FormularioPartidasReceta
