import React, { useState, useEffect } from 'react';
//Queries
import { LISTADO_ANAMNESIS_ENCABEZADO_FETCH, LISTADO_HISTORIAL_CLIENTE_RECETA, LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH, FILTRO_RECETAS_PARTIDAS, FILTRO_RECETAS_PARTIDAS_FETCH } from '../../../query';
//Componentes
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import EventIcon from '@material-ui/icons/Event';
import WcIcon from '@material-ui/icons/Wc';
import CakeIcon from '@material-ui/icons/Cake';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import EmailIcon from '@material-ui/icons/Email';
import Padecimientos from '../../Anamnesis/Padecimientos';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';
//****ROUTER***********/
import { useParams } from "react-router-dom";
//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion, meRnsajeAdvertencia, mensajeAdvertencia } from '../../../Helpers/constantes';
import { timestamp, ftAnioNoValido, ftAniosRangosValidos, ftSelectFormat, urlServer, showFile, } from '../../../Helpers';
import { Grid, Typography, Card, CardContent, FormControl, InputLabel, Button, TextField, Paper } from '../../../componentesGenericos/Core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
// URL SERVER
import { strUrlServer } from '../../../../src/Helpers/constantes/Config/UrlServer';
//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
import Encabezado from '../../../componentesGenericos/EncabezadoComponentes';
import clsx from 'clsx';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CardActionArea from '@material-ui/core/CardActionArea';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FetchGrahpQL } from '../../../Helpers/Fetch/FetchGraphql';
import FormularioPartidasReceta from './FormularioPartidasReceta';
import PrintIcon from '@material-ui/icons/Print';
import { rutaPublica, rutaPrivada, rutaPrivadaEmpresa } from '../../../Helpers/constantes/Config/urlRutas';
import { perfilhombre, perfilmujer, iconoDoctor} from '../../../Helpers/constantes/Config/imgDefault';
import { getImagen } from '../../../Helpers/constantes/Imagenes/getImagen';

//ESTILOS
const useStyles = makeStyles({
    root: {
        maxWidth: 150,
        flexGrow: 1,

    },
    degradadoAzul: {
        background: "linear-gradient(to right, #1488cc, #2b32b2)"
    },
    p2: {
        padding: "5px"
    },
    ftbold: {
        fontWeight: "bold"
    },
    colorBlanco: {
        color: "white"
    },
    bullet: {
        display: 'inline-block',
        margin: '0 1px',
        transform: 'scale(0.5)',
    },
    mb1: {
        marginBottom: "4px"
    },
    input: {
        display: 'none',
    },
    cardMedia: {
        margin: '10px'
    },
    h4: {
        textAlign: "center",
        fontSize: "16px",
        margin: "5px"
    },
    tipografia: {
        textAlign: "center",
        fontSize: "16px",
        wordWrap: 'break-word'
    }

});


export default function AnamnesisPacienteHistorial() {

    const [recetaModal, setRecetaModal] = useState(-1);
    const [fila, setFila] = useState(-1);
    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;

    const [errorState, setErrorState] = useState(false);
    const [bloquear, setBloquear] = useState(false);
    const [imprimir, setImprimir] = useState(false);
    const [error, setError] = useState(0);


    const menu = [
        { texto: "Regresar", icono: <ArrowBackIosIcon />, onClick: () => window.location.href = "/anamnesis" }
    ];

    const columnas = error===1 ? [ {id: 'msj', label: '', format: (valor) => {
        return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
    }}] :  [
        { id: 'recetaId', label: 'Código receta', minWidth: 0, hide: false },
        {
            id: 'agendaId', label: 'AgendaId', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        { id: 'descripcionReceta', label: 'Diagnóstico', minWidth: 170, align: 'left', textAlign: 'left' },
        {
            id: 'fechaReceta', label: 'Fecha de receta', minWidth: 170, align: 'center', textAlign: 'center',
            format: (valor) => {
                return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 ") : ''
            }

        },
        {
            id: 'pacienteId', label: 'Paciente', minWidth: 170, align: 'center', textAlign: 'center', hide: true,

        },
        {
            id: 'nombreCompletoPaciente', label: 'Nombre del paciente', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        {
            id: 'correoPaciente', label: 'Nombre del paciente', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        {
            id: 'clinicaDoctorId', label: 'Relacion clinicaId', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        {
            id: 'clinicaId', label: 'ClinicaId', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        {
            id: 'nombreClinica', label: 'Sucursal', minWidth: 100, align: 'left', textAlign: 'left', hide: false, filtro: true,
        },
        {
            id: 'doctorId', label: 'Doctor Id', minWidth: 100, align: 'left', textAlign: 'left', hide: true,
        },
        {
            id: 'nombreCompletoDoctor', label: 'Profesional', minWidth: 100, align: 'left', textAlign: 'left', hide: false, filtro: true,
        },
        {
            id: 'telefonoDoctor', label: 'Teléfono del profesional', minWidth: 100, align: 'left', textAlign: 'left', hide: false,
        },
        {
            id: 'correoDoctor', label: 'Correo del profesional', minWidth: 100, align: 'left', textAlign: 'left', hide: false,
        },
        {
            id: 'modal', label: 'Ver productos recetados', minWidth: 100, align: 'center', textAlign: 'center', format: (val) => {
                return (
                    <Grid item sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                        <Button size="small" color="primary" onClick={() => { setModalReceta(modalReceta => ({ ...modalReceta, abrir: true })) }}>
                            <VisibilityIcon />
                        </Button>
                    </Grid>
                )
            }
        },
        {
            id: 'reimprimir', label: 'Reimprimir receta', minWidth: 100, align: 'center', textAlign: 'center', format: (val) => {
                return (
                    <Grid item sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                        {
                            bloquear === false ?
                                <Button size="small" color="primary" onClick={() => { setBloquear(true); }}>
                                    <PrintIcon />
                                </Button> :
                                <Button size="small" color="primary"  disabled>
                                    <PrintIcon />
                                </Button>
                        }

                    </Grid>
                )
            }
        }

    ]

    ;

    let { pacienteId } = useParams();
    // Estilos
    const classes = useStyles();
    //Redux
    const userState = useSelector(state => state.user.usuario[0].user.user.token);
    let userToken = DecodificarToken(userState);
    // Obtener fechas
    const dt = new Date();
    let anio = dt.getFullYear();
    let mes = dt.getMonth();
    mes = mes < 10 ? `0${mes}` : mes;
    let dia = dt.getDate();
    dia = dia.toString().length === 1 ? `0${dia}` : dia;
    const mesAnterior = `${anio}-${mes}-${dia}`;
    dt.setHours(23);

    const seleccionInicial = {
        fechaInicio: mesAnterior,
        fechaFin: dt.toISOString().split("T")[0]
    }
    const [partidasreceta, setPartidasReceta] = useState();
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [open, setOpen] = useState(false);
    const [datosEncabezado, SetDatosEncabezado] = useState([]);
    const [datosPartidas, SetDatosPartidas] = useState([]);
    const [datosPadecimientos, SetDatosPadecimientos] = useState([]);
    const [modalReceta, setModalReceta] = useState({
        abrir: false,
        handleClose: ftModalClose,
        recetaId: null
    })
    const [modal, setModal] = useState({
        abrir: false,
        handleClose: ftModalPadecimientosClose,
        recetaId: null
    });
    const [imagenPerfil, setImagen] = useState('');

    let imagenHombre =  '/FotosPerfil/fotoPerfil-AvatarHombre.png';;
    let imagenMujer = '/FotosPerfil/fotoPerfil-AvatarMujern.png';





    // <<<<<<<<<<<<< QUERY >>>>>>>>>>>>>>>>>>>>>>>>
    const FetchListadoHistorialEncabezado = async () => {
        let info = '';
        FetchGrahpQL({
            query: LISTADO_ANAMNESIS_ENCABEZADO_FETCH,
            variables: {
                pacienteId: Number(pacienteId)
            }
        })
            .then((data) => {
                info = data.listadoAnamnesisEncabezado.anamnesis[0];
                SetDatosEncabezado(data.listadoAnamnesisEncabezado.anamnesis[0])
                let foto = '';

                if(!info.fotoPerfil)
                {
                    foto = info.generoId === 1 ? `${strUrlServer}${imagenMujer}` : `${strUrlServer}${imagenHombre}`;
                    
                    setImagen(foto);
                }
                else{
                    let ruta = rutaPrivada + info.fotoPerfil;
                    archivo(ruta).then(imagen => {
                        setImagen(imagen);   
                        return imagen ;
                    });
                }

            })
            .catch((e) => {
                console.log(e.message)
            })

    }
    async function archivo(ruta) { //get storage

        let resultado = '';
        resultado = await getImagen(ruta);
        return resultado;
    };   

    const FetchListadoHistorialReceta = async () => {
        FetchGrahpQL({
            query: LISTADO_HISTORIAL_CLIENTE_RECETA,
            variables: {
                pacienteId: Number(pacienteId),
                fechaReceta: seleccion.fechaInicio,
                fechaFin: seleccion.fechaFin
            }
        })
            .then((data) => {
                SetDatosPartidas(data.listadoHistorialClienteReceta.historialClienteReceta)
                setError(0)
            })
            .catch((e) => {
                //console.log(e.message)
                SetDatosPartidas([{msj: 'No se encontraron registros'}])
                setError(1)
            })
    }

    const FetchListadoPadecimientos = async () => {
        FetchGrahpQL({
            query: LISTADO_HISTORIAL_CLIENTE_OBSERVACIONES_FETCH,
            variables: {
                pacienteId: Number(pacienteId)
            }
        })
            .then((data) => {
                SetDatosPadecimientos(data.listadoHistorialClienteObservacionesPadecimientos.historialClienteObservacionesPadecimientos)
            })
            .catch((e) => {
                console.log(e.message)
            })
    }

    const FetchImpresionReceta = async () => {
        FetchGrahpQL({
            query: FILTRO_RECETAS_PARTIDAS_FETCH,
            variables:{ limite: 10000, pagina: 1, recetaId: recetaModal, productoId: null }
        })
            .then((data)=> {
                setPartidasReceta(data)
            })
            .catch((e) =>{console.log(e.message)})
    }
    // <<<<<<<<<<<<< END QUERY >>>>>>>>>>>>>>>>>>>>>>>>>
    // <<<<<<<<<<<<< CONSTANTES >>>>>>>>>>>>>>>>>>>>>

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function ftModalClose() {
        setModalReceta(modalReceta => ({ ...modalReceta, abrir: false }));
    }

    function ftModalPadecimientosClose() {
        setModal(modal => ({ ...modal, abrir: false }));
    }
    // <<<<<<<<<<<<< END CONSTANTES >>>>>>>>>>>>>>>>>>

    // <<<<<<<<<<<<<<< FUNCIONES >>>>>>>>>>>>>>>>>>>>>
    function imprimirReceta() {

        //var details = JSON.stringify({age: 12});

        async function imprimirReceta() {
            // refetch();
            var myHeaders = new Headers();
            myHeaders.append("token", token);
            myHeaders.append("mimeType", "multipart/form-data");
            var formdata = new FormData();
            formdata.append("data", JSON.stringify(partidasreceta));
            formdata.append("nombreReporte", "recetaCliente");
            formdata.append("rutaCodigoQr", `${urlServer}/images/public/png/logotipo-642d5d06-fbf4-47d8-b328-027e3388933e`);
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata
            };

            fetch(`${urlServer}/service/reportes/receta`, requestOptions)
                .then(response => {
                    if (!response.ok) return response.json();
                    return response.blob()
                })
                .then(result => {
                    let { error, mensaje } = result;
                    if (error) throw new Error(mensaje);
                    showFile(result, `Receta.pdf`);
                    setMensaje({ ...mensaje, ...mensajeExito, texto: `Se generó correctamente la receta` });
                    setBloquear(false);
                })
                .catch(error => {
                    setMensaje({ ...mensaje, ...mensajeAdvertencia, texto: `Ocurrio un problema al generar la receta` });
                    setBloquear(false);
                    console.log("ERROR: ", error.message);
                }
                );
        } imprimirReceta();
    }

    useEffect(() => {
        if (partidasreceta !== undefined) imprimirReceta();
    }, [partidasreceta]);

    useEffect(() => {
        if(bloquear === true) FetchImpresionReceta();
    }, [bloquear]);

    function handleFechaFinChange(e, val) {
        try {
            let hasta = `${e.target.value}`;
            setSeleccion({
                ...seleccion,
                fechaFin: hasta
            });
            if (timestamp(seleccion.fechaInicio) > timestamp(hasta))
                throw new Error('¡La fecha final debe ser mayor a la fecha inicial!');
            if (ftAnioNoValido(hasta)) throw new Error('¡La fecha no tiene un formato válido!');
            if (!ftAniosRangosValidos(hasta)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');
        } catch ({ message: descripcion }) {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: descripcion,
                abrir: true
            });
            setSeleccion({
                ...seleccion,
                fechaFin: seleccionInicial.fechaFin
            });
        }
    }

    function handleFechaInicioChange(e, val) {
        try {
            let desde = `${e.target.value}`;
            setSeleccion({
                ...seleccion,
                fechaInicio: desde
            });
            if (timestamp(desde) > timestamp(seleccion.fechaFin))
                throw new Error('¡La fecha inicial debe ser menor a la fecha final!');
            if (ftAnioNoValido(desde)) throw new Error('¡La fecha no tiene un formato válido!');
            if (!ftAniosRangosValidos(desde)) throw new Error('¡El año capturado no se encuentra en el rango permitido o aún ni siquiera hemos llegado a el!');

        } catch ({ message: descripcion }) {
            setMensaje({
                ...mensaje,
                ...mensajeError,
                texto: descripcion,
                abrir: true
            });
            setSeleccion({
                ...seleccion,
                fechaInicio: seleccionInicial.fechaInicio
            });
        }
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftModalClose() {
        setModalReceta(modalReceta => ({ ...modalReceta, abrir: false }));
    }

    // <<<<<<<<<<<<<<< END FUNCIONES >>>>>>>>>>>>>>>>>>>>>

    const ftGenerarModal = (data) => {
        let row = [];
        if (data.length !== 0) {
            data.map(function (info, index) {
                row.push(
                    <>
                        <Grid  container item xs={12} direction="row" style={{ marginTop: '15px' }} >
                            <Grid item xs={6} style={{ border: "#CDCDCDCD 1px solid" }}>
                                <Typography variant="body2" className={classes.tipografia} color="textSecondary" component="p" >

                                    {info.descripcionPadecimiento}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ border: "#CDCDCDCD 1px solid" }}>

                                <Typography variant="body2" className={classes.tipografia} color="textSecondary" component="p" >
                                    {info.descripcionAnamnesisPadecimiento}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                );
                return true;
            });
            return row;
        } else {
            return (
                <Grid container item xs={12} direction="row" style={{ marginTop: '15px' }} >
                    <Grid item xs={12} style={{ border: "#CDCDCDCD 1px solid" }}>
                        <Typography variant="body2" style={{ textAlignLast: "center" }} className={classes.tipografia} color="textSecondary" component="p" >
                            No cuenta con registros
                                </Typography>
                    </Grid>
                </Grid>
            )
        }
    }

    function handleTablaClick(datos, index) {
        setRecetaModal(datos.recetaId);
        setFila(index);
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    // <<<<<<<<<<<<<<< USE EFFECT >>>>>>>>>>>>>>>>>>>>>>>>
    useEffect(() => {
        FetchListadoHistorialReceta();
        FetchListadoHistorialEncabezado();
        FetchListadoPadecimientos();
    }, [])
    useEffect(() => {
        FetchListadoHistorialReceta();
    }, [seleccion, error])
    // <<<<<<<<<<<<<< END USE EFFECT >>>>>>>>>>>>>>>>>>>>>

  
    return (<MenuLateral titulo="Anamnesis" menu={menu}>
        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

        <Dialog
            fullWidth
            maxWidth='sm'
            open={open}
            onClose={handleClose}
            aria-labelledby="max-width-dialog-title"
        >
            <Grid style={{ margin: "13px", border: "#2699FB 1px solid", borderRadius: "7px" }}>
                <DialogTitle id="max-width-dialog-title" style={{ textAlignLast: "center" }}>Padecimientos </DialogTitle>
                <DialogContent className={classes.Dialog} >
                    <Grid container spacing={3} alignItems="center">
                        {datosPadecimientos.length !== 0 ?
                            <>
                                <Grid item xs={6}>
                                    <h4 className={classes.h4}> Padecimiento: </h4>
                                </Grid>
                                <Grid item xs={6} >
                                    <h4 className={classes.h4}> Descripción: </h4>
                                </Grid>
                            </> :
                            <div></div>
                        }
                    </Grid>
                    {ftGenerarModal(datosPadecimientos)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cerrar
                </Button>
                </DialogActions>
            </Grid>
        </Dialog>

        <Card className={classes.mb1}>
            <CardContent>
                <Grid spacing={3} container direction="row"  justify="center">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Encabezado titulo="Historial Clínico" />
                        </Grid>
                    </Grid>
                    {
                       datosEncabezado.length !== 0 ?
                            <Grid item sm={12} md={4} lg={2}>
                                <CardMedia
                                    style={{ marginTop: "10px", width: 230 }}
                                    component="img"
                                    alt="Foto de perfil"
                                    image={`${imagenPerfil}`}
                                    title="Foto de perfil"
                                />
                            </Grid>
                            :
                            <div className={classes.root}>
                                <CircularProgress />
                            </div>
                    }
                    <Grid container direction="row" item sm={12} md={8} lg={9} style={{ textAlign: "left" }}>
                        <Grid item sm={12} md={8} lg={12} style={{ textAlign: "left" }}>
                            <CardContent>
                                <Typography variant="h4" color="textPrimary" component="p" >
                                    {datosEncabezado.length !== 0 ? ` ${datosEncabezado.nombreCompleto}` : ''}
                                </Typography>
                            </CardContent>
                        </Grid>
                        <CardContent>
                            <Typography variant="h6" color="textPrimary" component="p" >
                                {datosEncabezado.fechaAlta ? <EventIcon titleAccess="Fecha de alta" /> : ''}
                                {`${datosEncabezado.fechaAlta ? ` ${datosEncabezado.fechaAlta.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 ")}` : ""}`}
                            </Typography>
                            <Typography variant="h6" color="textPrimary" component="p" >
                                {datosEncabezado.genero ? <WcIcon titleAccess="Género" /> : ''}
                                {datosEncabezado.genero ? ` ${datosEncabezado.genero.substring(0, 1) + datosEncabezado.genero.toLowerCase().slice(1)}` : ""}
                            </Typography>
                            <Typography variant="h6" color="textPrimary" component="p" style={{ textAlign: 'left' }}>
                                {datosEncabezado.fechaNacimiento ? <CakeIcon titleAccess="Fecha de nacimiento" /> : ''}
                                {datosEncabezado.fechaNacimiento ? ` ${datosEncabezado.fechaNacimiento.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 ")}` : ""}
                            </Typography>
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={3} alignItems="center">
                                <Grid item xs={12} sm={4} md={12}  >
                                    <Typography variant="h6" color="textPrimary" component="p" >
                                        {datosEncabezado.telefono ? <PhoneAndroidIcon titleAccess="Teléfono" /> : ''}
                                        {datosEncabezado.telefono ? ` ${datosEncabezado.telefono}` : ""}
                                    </Typography>
                                    <Typography variant="h6" color="textPrimary" component="p">
                                        {datosEncabezado.email ? <EmailIcon titleAccess="Correo" /> : ''}
                                        {datosEncabezado.email ? ` ${datosEncabezado.email}` : ""}
                                    </Typography>
                                    <CardActions>
                                        <Button size="small" color="primary" onClick={() => { setModal(modalReceta => ({ ...modalReceta, abrir: true })) }} title="Ver padecimientos">
                                            {datosEncabezado.length !== 0 ? <VisibilityIcon /> : ''}
                                            {datosEncabezado.length !== 0 ? ' Ver padecimientos' : ''}
                                        </Button>
                                    </CardActions>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        {/* {
                    datosPartidas.length !== 0 ? */}
        <Card className={classes.mb1}>
            <CardContent>
            <Grid container justify="center" alignItems="center">
                        <Grid container item xs={3} alignItems="center" justify="center" alignContent="center">
                        {/* <form className={classes.container} noValidate> */}
                            <TextField
                                id="date"
                                label="Fecha de inicio"
                                type="date"
                                value={seleccion.fechaInicio}
                                onChange={handleFechaInicioChange}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        {/* </form> */}
                    </Grid>
                    <Grid container item xs={3} justify="center">
                        {/* <form className={classes.container} noValidate> */}
                            <TextField
                                id="date"
                                label="Fecha fin"
                                type="date"
                                value={seleccion.fechaFin}
                                onChange={handleFechaFinChange}
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        {/* </form> */}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

        <Tabla titulo={"Historial"} columnas={columnas} datos={datosPartidas} msjError={error ?.message} onClick={handleTablaClick} onSearch={handleBusquedaChange} filtro={filtro} indice={fila} /*loading={loading}  onPaginaChange={handlePaginaChange} */ titleResponsive={["descripcionReceta","nombreCompletoPaciente","correoPaciente","nombreClinica","nombreCompletoDoctor"]} />

        <FormularioPartidasReceta abrir={modalReceta.abrir} onClose={modalReceta.handleClose} recetaId={recetaModal} />

        <Padecimientos abrir={modal.abrir} onClose={modal.handleClose} pacienteId={Number(pacienteId)} />


    </MenuLateral >)

}
