import React from 'react';
//Iconos
import { AddIcon, EditIcon, DeleteForever, SwitchIcon} from '../../../componentesGenericos/Icons';


/*FORMATO DE COLUMNAS */

// seleccion inicial para el query
const seleccionInicial = {
    tipoPadecimientoId: null,
    descripcion: null
};



//menu lateral
const menu = (handleAgregarClick, handleModificarClick, handleEliminarClick) => [
    { texto: "Agregar padecimiento", icono: <AddIcon />, onClick: () => handleAgregarClick() },
    { texto: "Modificar padecimiento", icono: <EditIcon />, onClick: () => handleModificarClick() },
    { texto: "Eliminar padecimiento", icono: <DeleteForever />, onClick: () => handleEliminarClick() },
];

//estate inicial
const actualizarState = false;
const filaState = -1;
const seleccionState = {
    ccostoId: null,
    clinicaId: null,
    nombre: null,
    numCcosto: null,
    nombreCcosto: null,
    fechaAlta: "00:00:00",
    estatusCcostos: false
};
const formularioState = (ftFormularioClose) => {
    return{
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    }
};

//Estilos
const Style = {
    height: "90%", textAlign: "center", width:"100%"
};

export { seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState, Style};
