import gql from 'graphql-tag';

const NUEVA_COMISION = gql `
    mutation addComision($comision: ComisionInput!)
    {
    nuevaComision(input: $comision)
    }
`;

const MODIFICAR_COMISION = gql `
mutation updateComision($updComision: ComisionInput!)
{
  ModificarComision(input: $updComision)
}
`;

const ESTATUS_COMISION = gql `
    mutation changeComision($estatusComision: EstatusComision!)
    {
    cambiarEstatusComision(input:$estatusComision)
    }
`;

export {
    NUEVA_COMISION,
    ESTATUS_COMISION,
    MODIFICAR_COMISION
}