import React, { useContext, useState,useEffect } from 'react';
import { ftAnioNoValido, ftAniosRangosValidosAlterno, ftAniosRangosValidos } from "../../../../Helpers";
//Core
import { Grid } from '../../../../componentesGenericos/Core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Context
import { CRMContext } from '../../../../context/ConveniosUsuarios';
//Dependencias
//Query
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_PACIENTE_FETCH } from '../../../../query/componentes/Usuario';
import {LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH} from '../../../../query/componentes/Convenio';


function AgregarRelacion() {

    let [conveniosUsuarios, guardarConveniosUsuarios] = useContext(CRMContext);
    let [listadoPacientes, guardarListadoPacientes] = useState([]);
    let [listadoConvenios, guardarListadoConvenios] = useState([]);

    //EVENTOS
    const handleChangeGuardarStateConvenioId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    convenioId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    convenioId: {
                        error: false, valor: values.convenioId
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateUsuarioId = (event, values) => {
        if (values === null || values.length === 0) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    usuarioId: {
                        error: true, valor: ""
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    usuarioId: {
                        error: false, valor: values.usuarioId
                    }
                }
            });
        }

    }
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        if (value === null || value.length === 0) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    [name]: {
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    [name]: {
                        error: false, valor: value
                    }
                }
            });
        }

    }
    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;

        let fecha = (conveniosUsuarios.Convenios.fechaFinalConvenio.valor).split("T")[0];

        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) == false || fecha<value) {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    [name]: {
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarConveniosUsuarios({
                ...conveniosUsuarios,
                Convenios: {
                    ...conveniosUsuarios.Convenios,
                    [name]: {
                        error: false, valor: value
                    }
                }
            });
        }

    }

    const obtenerPacientes = async () => {
        await FetchGrahpQL({
            query: LISTADO_PACIENTE_FETCH,
            variables: {
                offset: 0,
                moroso:null
            }
        })
            .then(resp => {
                let row = [];
                resp.listadoPaciente.pacientes.map(function (x) {
                    row.push({nombreCompleto:x.nombreCompleto,email:x.email,usuarioId:x.usuarioId});
                });
                guardarListadoPacientes(row)
            })
            .catch(() => {
            });
    };

    const obtenerConvenios = async () => {
        await FetchGrahpQL({
            query: LISTADO_CONVENIOS_FECHA_ESTATUS_FETCH,
            variables: {
                offset: 0
            }
        })
            .then(resp => {

                let row = [];
                resp.listadoTodosLosConvenios.convenios.map(function (x) {
                    row.push({convenioId:x.convenioId,empresa:x.empresa});
                });
                guardarListadoConvenios(row)
            })
            .catch((err) => {

            });

    };

    //CICLO DE VIDA
    useEffect(() => {
        obtenerPacientes();
        obtenerConvenios();
    }, []);

    return (<>
        <Fieldset contenido={
            <div>
                <Grid spacing={3} container direction="row">
                    <Grid item xs={12}>
                        <Autocomplete
                        noOptionsText="Sin convenios"
                            disabled={true}
                            options={listadoConvenios}
                            id="combo-box-demo"
                            defaultValue={{ empresa: `${conveniosUsuarios.Convenios.empresaConvenio.valor}`, convenioId: conveniosUsuarios.Convenios.convenioId.valor }}
                            onChange={handleChangeGuardarStateConvenioId}
                            getOptionLabel={(option) => { return (`${option.empresa}`) }}
                            renderInput={params => (
                                <TextField
                                    required={false}
                                    name="Convenios"
                                    style={{ width: "100%" }}
                                    {...params}
                                    label="Selecione el convenio"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            disabled={true}
                            noOptionsText={"Sin clientes"}
                            options={listadoPacientes}
                            id="combo-box-demo"
                            defaultValue={{ nombreCompleto: `${conveniosUsuarios.Convenios.nombreCompleto.valor}`, email: conveniosUsuarios.Convenios.email.valor, usuarioId: conveniosUsuarios.Convenios.usuarioId.valor }}
                            onChange={handleChangeGuardarStateUsuarioId}
                            getOptionLabel={(option) => { return (`${option.nombreCompleto}(${option.email})`) }}
                            renderInput={params => (
                                <TextField
                                    required={false}
                                    name="usuarioId"
                                    style={{ width: "100%" }}
                                    {...params}
                                    label="Selecione al cliente"
                                    variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required={true}
                            style={{ width: "100%" }}
                            name="fechaBajaConveniosUsuarios"
                            id="date"
                            label="Fecha de baja del convenio del cliente"
                            type="date"
                            helperText={`La fecha limite del convenio asignado es: ${(conveniosUsuarios.Convenios.fechaFinalConvenio.valor).split("T")[0]}`}
                            onChange={handleChangeGuardarStateFecha}
                            error={conveniosUsuarios.Convenios.fechaBajaConveniosUsuarios.error}
                            defaultValue={conveniosUsuarios.Convenios.fechaBajaConveniosUsuarios.valor != null ? (conveniosUsuarios.Convenios.fechaBajaConveniosUsuarios.valor).split("T")[0] : ""}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        } Titulo="" />
    </>);
}

export default AgregarRelacion;
