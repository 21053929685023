import gql from 'graphql-tag';

//#region =========Comisiones=============

const LISTADO_COMISIONES = gql `
query listComisiones($offset: Int,$tipo: Int!, $clinicaId: Int, $profesionalId: Int, $estatus: Int)
{
  listadoComisiones(offset:$offset,tipo: $tipo, clinicaId: $clinicaId, profesionalId: $profesionalId,estatus:$estatus){
    comisiones{
      comisionId
      clinicaProfesionalId
      clinicaId
      nombreClinica
      profesionalId
      nombreCompleto
      correo
      productoId
      descripcionProducto
      productosComision
      productosIdComision
      porcentajeComision
      estatusComision
      fechaAsignacion
      tipoProducto
    }
    registros
    paginas
  }
}`;


//#endregion
//=======================================

//#region =========Comisiones Profesionales=============

const LISTADO_COMISIONES_PROFESIONAL =  `
query listComisionProfesional ($comisionProfesional: FiltroComisionProfesional!)
{
  listadoComisionesProfesional(filtro: $comisionProfesional)
  {
    comisionesProfesional{
      doctorId
      nombreCompletoUsuario
      descripcionProducto
      tipoServicioProductoMaterialId
      tipoServicioProductoMaterial
      porcComision
      nombreClinica
      clinicaId
      productoId
    }
    registros
    paginas
  }
}`;

const LISTADO_COMISIONES_PROFESIONAL_TOTAL =  `
query listComisionProfesionalTotal ($comisionProfesionalTotal: FiltroComisionProfesionalTotal!)
{
  listadoComisionesProfesionalTotal(filtro: $comisionProfesionalTotal)
  {
    comisionesProfesionalTotal{
      clinicaId
      nombreClinica
      doctorId
      servicioId
      comisionVenta
      nombreCompletoUsuario
      clienteId
      nombreCliente
      descripcionProducto
      productoId
      porcComision
      precioTotal
      totalComision
      fechaEmision
      porcComisionAlvender
      cantidad
      ComisionProductoVenta
    }
    registros
    paginas
  }
}`;

//#endregion
//=======================================

export {
    LISTADO_COMISIONES,
    LISTADO_COMISIONES_PROFESIONAL,
    LISTADO_COMISIONES_PROFESIONAL_TOTAL
}
