import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { HISTORIAL_REPORTES } from '../../../query';
import { ELIMINAR_REPORTE } from '../../../mutation';
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import Mensaje from '../../../componentesGenericos/Mensaje';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../../Helpers/constantes';
import { CRMContext } from '../../../context/Reporte';

function ReporteTabla({ historial:reporte =  "Agenda" }) {

        const { loading, error:{message} = {}, data, refetch } = useQuery(HISTORIAL_REPORTES, {
        variables: { reporte : reporte },
        onError: (e) => {
            console.log(e.message);
            setErrorState(true);
        },
        onCompleted:()=>{
            setErrorState(false);
        }
    });


    const [deleteReporte] = useMutation(ELIMINAR_REPORTE, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente el reporte` });
            setActualizar(true);
        }
    });

    const [fila] = useState(-1);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useContext(CRMContext);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);
    const [mensaje, setMensaje] = useState(mensajeInicial);

    const columnas = errorState ? [{ id: 'msj', label: '', format: valor => (<p style={{ fontSize: 18, margin: 0 }}>{valor}</p>) }]
    : [
        { id: 'reporteID', label: 'ID', minWidth: 0, hide: true },
        { id: 'ruta', label: 'Documento', minWidth: 100, align: 'left', textAlign: 'left', component: "VerArchivo", filtro: true },
        { id: 'filtros', label: 'Filtros', minWidth: 100, align: 'center', textAlign: 'center', component: "JSON", filtro: true },
        { id: 'fechaAlta', label: 'Fecha generado', minWidth: 100, align: 'center', textAlign: 'center',
            format: (valor) => {
                return valor ? valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4") : "";
            } },
        { id: 'nombreCompleto', label: 'Generó', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
        { id: 'eliminar', label: '', minWidth: 100, align: 'center', textAlign: 'center', component: "Delete" },
    ];

    let sinRegistros = [{ msj: message ? message : "No se encontraron registros" }];
    const listadoReporte = errorState ? sinRegistros : (data ? (data.listadoReportes ? data.listadoReportes : sinRegistros) : sinRegistros);
    const listado = listadoReporte.map(data => ({
        ...data,

        eliminar: () => {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: "¿Está seguro de eliminar el reporte?", onAccept: () => ftEliminar(data.reporteID) });

        }
    }));

    function ftEliminar(reporteID) {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteReporte({ variables: { reporteID: Number(reporteID) } });
            setActualizar(true);
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function handleBusquedaChange(texto) {
        setFiltro(texto);
    }

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            console.error(e.message);
            setErrorState(true);
        });
        setActualizar(false);
    }

    return(<>

        <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} disabledAccept={disabledDelete} progress={disabledDelete} />

        <Tabla
            titulo={`Historial reportes ${reporte.toLowerCase()}`}
            columnas={columnas}
            datos={listado}
            onSearch={handleBusquedaChange}
            indice={fila}
            filtro={filtro}
            loading={loading}
            msjError={message}
            hover={false}
            seleccionable={false}
            titleResponsive={["ruta", "nombreCompleto", "fechaAlta"]}
        />

    </>)
}

export default ReporteTabla;
