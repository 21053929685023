import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Input from './Input';
import Botones from '../../../componentesGenericos/Mensaje/componentes/Botones';
import { FetchAPI } from '../../../Helpers/Fetch/FetchGraphql';
import {  urlServer, showFile} from '../../../Helpers';

import { useSelector } from "react-redux";
import { CRMContext } from '../../../context/Reporte';

const useStyles = makeStyles((theme) => ({
    Btn: {
        margin: '0 auto'
    }
}));

function ReporteGarantia({ setMensaje }) {

    const classes = useStyles();
    const user = useSelector((state) => state.user);
    let token = user.usuario[0].user.user.token;

    const defaultState = {
        reporte: '',
        disabledclinicaID: true,
        encabezado: false,
        orientacion: "portrait",
        tipo:'',
        disabledTipo: true,
        disabledIdDoctor:true,
        SerieFolio:''
    };

    const noAplicaFiltro = 'El filtro no aplica, seleccione un reporte válido';

    const defaultSelect = {
        reportes: [{ value: '', label: 'Seleccionar reporte' }],
        SerieFolio:[{ value: '', label: 'Coloque el folio de la garantía' }],

    }

    const [ state, setState ] = useState(defaultState);
    const [ select, setSelect ] = useState(defaultSelect);
    const [ disabledAccept, setDisabledAccept ] = useState(false);
    const [, setActualizar] = useContext(CRMContext);



	const campos = [{
        id: 'reporte',
        texto: 'Seleccionar reporte *',
        valor: state.reporte,
        opciones: select.reportes,
        tipo: 'select',
        propiedades: { readOnly: false },
        xs: 12
    },{
        id: "SerieFolio",
        texto: "Colocar folio",
        valor: state.SerieFolio,
        tipo: "text",
        xs:12,
        ayuda: "Campo obligatorio",
    },
    {
        id: 'orientacion',
        texto: "Orientación del reporte",
        valor: state.orientacion,
        opciones: [
            { value: "portrait", label: "Vertical" },
            { value: "landscape", label: "Horizontal" }
        ],
        tipo: "select",
        xs: 12
    },{
        id: 'encabezado',
        texto: 'Repetir encabezado en todas las páginas (se omitirá el logotipo configurado)',
        valor: state.encabezado,
        tipo: 'checkbox',
        xs: 12,
        propiedades: { readOnly: false }
    }];

    function ftDisabled(id, value) {
        if(id !== 'reporte') {
            if(id === 'clinicaID') return {
                ...state,
                // formasPagoIDs: [],
                // disabledformasPagoIDs: (Number(value) === 0 || isNaN(Number(value)))
                disabledIdDoctor: (Number(value) === 0 || isNaN(Number(value)))
            };
            return {};
        }
        switch(value) {
            case "Garantias":
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: false,
                    // formasPagoIDs: [],
                    // disabledformasPagoIDs: true,
                    disabledTipo: false,
                    disabledIdDoctor:false,
                    SerieFolio:""
                };
            default:
                return {
                    ...state,
                    clinicaID: '',
                    disabledclinicaID: true,
                    // formasPagoIDs: [],
                    // disabledformasPagoIDs: true,
                    disabledTipo: true,
                    disabledIdDoctor:true,
                    SerieFolio:""
                };
        }
    }

	function handleChange({target}, id, multiselect, onChange, cleanDatoState) {
        let { value, checked } = target, limpiar = {};
        let disabled = ftDisabled(id, value);
        if(id === "encabezado") value = checked;
        if(Array.isArray(cleanDatoState) && state[id] != value) cleanDatoState.forEach(({id, value}) => {
            limpiar = {
                ...limpiar,
                [id]: value === undefined ? null : value
            }
        });
        let defaultValue = {
            [id]: multiselect
                ? (Array.isArray(value) ? [...value] : [value])
                : value
        };
        setState({
            ...state,
            ...limpiar,
            ...disabled,
            ...defaultValue
        });
        if(onChange) onChange({ ...defaultValue });
    }

    function handleClickAccept() {
        async function click() {
            try {
                setDisabledAccept(true);

                if(!state.reporte) throw new Error('¡Para generar el reporte primero debe seleccionar el reporte!');
                if(!state.SerieFolio) throw new Error('¡Para generar el reporte primero debe introducir  un folio!');


                let resultado = await FetchAPI({
                    url: `${urlServer}/service/reportes/garantia`,
                    params: /*data:*/ {
                        ...state,
                    },
                    // method: 'POST',
                    file: false,
                    token
                });

                if(resultado.error) throw new Error(resultado.mensaje);
                if(!resultado) throw new Error('¡Ocurrio un problema, no fue posible obtener el archivo!');

                showFile(resultado.archivo, `${state.reporte}.pdf`);
                setActualizar(true);
                setDisabledAccept(false);
                setMensaje({
                    visible: true,
                    tipo: 'success',
                    descripcion: '¡El reporte se generó correctamente!'
                });

            } catch({message:descripcion}) {
                setMensaje({
                    visible: true,
                    tipo: 'error',
                    descripcion
                });
                setDisabledAccept(false);
            }
        }
        click();
    }

    useEffect(() => {
        async function ftEffect() {
            const reportes = [{
                value: '',
                label: 'Seleccionar reporte'
            }, {
                value: 'Garantia',
                label: 'Garantías'
            }];
            setSelect({
                ...select,
                reportes
            });
        }
        ftEffect();
    }, []);



    return(<>
        <Input campos={campos} handleChange={handleChange} state={state} />
        <Botones classes={classes.Btn} txtBotones={{ btnAceptar: "Generar Reporte" }} onAccept={handleClickAccept} disabledAccept={disabledAccept} />
    </>);
}

export default ReporteGarantia;
