/*************LOGIN**************/
import { makeStyles,withStyles } from '@material-ui/core/styles';
import {StepConnector,MuiExpansionPanel,MuiExpansionPanelSummary,MuiExpansionPanelDetails }
from '../../../componentesGenericos/Core';

export const  useStylesLogin = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));


  export const useStylesLoginContenedor= makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: "100%"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    fullsize: {
        height: "100%",
        width: "100%"
    },
    cardFooter: {
        marginBottom: '20px',
        marginRight: '20px'
    },
    link: {
        textAlign: 'right',
    },
    contenedorBtn: {
        textAlign: 'right',
    },
    btn: {
        backgroundColor: '#006287',
        color: 'white',
        '&:hover': {
            backgroundColor: '#005277'
        }
    },
    input: {
        margin: '15px'
    }
}));
/*************END*******************/


  /*************Asignar Clinicas**************/
export const useStyleAsignar= makeStyles(theme => ({
    formControl: {
      margin: 0,
      minWidth: 300,
      maxWidth: 350,
      marginLeft: 0,
      padding: 0,
      display: 'left'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(0),
    },
  }));

 export  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 150,
      },
    },
  };
  /*************END*******************/



/*************Recuperar Contraseña**************/


export const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( to right, #1a2980, #26d0ce)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
        'linear-gradient( to right, #1a2980, #26d0ce)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

  export const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( to right, #1a2980, #26d0ce)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
      'linear-gradient( to right, #1a2980, #26d0ce)',
    },
  });

  export const RecuperarUseStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));


/*************END*******************************/

/*************PERFIL USUARIO********************/
export const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles({
    root: {
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiExpansionPanelDetails);

/*************END*******************************/
