import React from 'react'
import { Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: '10px',
        border:'1px solid #53afff',
        top: '50px'
    },
}));

function MenuAnclado({ id, abrir = false, anclaje, onClose, anchorOrigin = { vertical: 'bottom', horizontal: 'center' }, transformOrigin = { vertical: 'top', horizontal: 'center' }, children }) {
    const classes = useStyles();

    return (
        <Popover id={id} open={abrir} anchorEl={anclaje} onClose={() => onClose()} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} PaperProps={{ classes: { root: classes.root } }}>

            {children}

        </Popover >
    )
}

export default MenuAnclado
