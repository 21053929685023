import React, { useContext, useEffect } from 'react';
//Componentes
import DatosGenerales from './Formularios/DatosGenerales';
import DatosProfesionales from './Formularios/DatosProfesionales';
import FotoPerfil from './Formularios/FotoPerfil';
import DatosCedula from './Formularios/DatosCedula';
//context
import { CRMContext } from '../../../context/Perfil';
//FETCH
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
import { OBTENER_DOCTOR, OBTENER_ADMINISTRADOR, OBTENER_USUARIO, OBTENER_PACIENTE } from '../../../query';
//Help
import { DecodificarToken } from '../../../componentesGenericos/Estructura/Autenticar';
//Router
import { withRouter } from "react-router-dom";

function General(props) {
    const [perfil, guardarPerfiles] = useContext(CRMContext);
    let { history } = props;
    //Eventos
    let componentePrincipal = (token) => {
        let datos = DecodificarToken(token);
        let tipo = datos.tipo;
        let componente;
        if (tipo != null || tipo != undefined) {
            switch (tipo) {
                case 1:
                    componente = (<><FotoPerfil token={token} usuarioId={datos.usuarioId} /> <DatosGenerales usuarioId={datos.usuarioId} /> <DatosProfesionales usuarioId={datos.usuarioId} /> <DatosCedula usuarioId={datos.usuarioId} /></>);
                    break;
                case 2:
                    componente = (<><FotoPerfil token={token} usuarioId={datos.usuarioId} />  <DatosGenerales usuarioId={datos.usuarioId} /></>);
                    break;
                case 3:
                    componente = (<><FotoPerfil token={token} usuarioId={datos.usuarioId} />  <DatosGenerales usuarioId={datos.usuarioId} /></>);
                    break;
                case 4:
                    componente = (<><FotoPerfil token={token} usuarioId={datos.usuarioId} />  <DatosGenerales usuarioId={datos.usuarioId} /></>);
                    break;
                default:
            }
        } else {
            history.push('/');
        }
        return componente;
    }
    //API
    const FetchObtenerUsuario = async (token) => {
        let datos = DecodificarToken(token);
        let query;
        let contenedor;
        switch (datos.tipo) {
            case 1:
                query = OBTENER_DOCTOR;
                contenedor = "obtenerDoctor";
                break;
            case 2:
                query = OBTENER_PACIENTE;
                contenedor = "obtenerPaciente";
                break;
            case 3:
                query = OBTENER_ADMINISTRADOR;
                contenedor = "obtenerAdministrador";
                break;
            case 4:
                query = OBTENER_USUARIO;
                contenedor = "obtenerUsuario";
                break;
        }
        await FetchGrahpQL({
            query: query,
            variables: {
                id: datos.usuarioId
            }
        })
            .then((resp) => {
                guardarPerfiles(perfiles => ({
                    ...perfiles,
                    General: {
                        ...perfiles.General,
                        nombres: {
                            ...perfiles.General.nombres,
                            error: false, valor: resp[contenedor].nombres
                        },
                        apellidos: {
                            ...perfiles.General.apellidos,
                            error: false, valor: resp[contenedor].apellidos
                        },
                        telefono: {
                            ...perfiles.General.telefono,
                            error: false, valor: resp[contenedor].telefono
                        },
                        sexo: {
                            ...perfiles.General.sexo,
                            error: false, valor: resp[contenedor].sexo
                        },
                        cedula: {
                            ...perfiles.General.cedula,
                            error: false, valor: resp[contenedor].cedula
                        },
                        fechaNacimiento: {
                            ...perfiles.General.fechaNacimiento,
                            error: false, valor: resp[contenedor].fechaNacimiento
                        },
                        fechaEgreso: {
                            ...perfiles.General.fechaEgreso,
                            error: false, valor: resp[contenedor].fechaEgreso
                        },
                        especialidadId: {
                            ...perfiles.General.especialidadId,
                            error: false, valor: resp[contenedor].especialidadId
                        },
                        email: {
                            ...perfiles.General.email,
                            error: false, valor: resp[contenedor].email
                        },
                        emailActual: {
                            ...perfiles.General.email,
                            error: false,
                            valor: resp[contenedor].email,
                        },
                        usuarioId: {
                            ...perfiles.General.usuarioId,
                            error: false,
                            valor: resp[contenedor].usuarioId,
                        }
                    }
                }));
            })
            .catch((err) => {

            });

    }
    //CICLO DE VIDA
    useEffect(() => {
        FetchObtenerUsuario(perfil.General.token.valor);
    }, []);

    return (
        <>
            {componentePrincipal(perfil.General.token.valor)}
        </>
    );
}

export default withRouter(General);
