import gql from 'graphql-tag';

//#region CONVENIO USUARIO
const NUEVO_CONVENIO_USUARIO_ID = `
mutation nuevaConvenioUsuario(
    $convenioId: ID!,
    $usuarioId: ID!
  ) {
    nuevaConvenioUsuario(input:
      { convenioId: $convenioId,
        usuarioId: $usuarioId}) {
      convenioId
      convenioId
      usuarioId
      qrConveniosUsuarios
      estatusConveniosUsuarios
      fechaAltaConveniosUsuarios
      fechaBajaConveniosUsuarios
      tipo
      nombres
      apellidos
      email
        fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      empresaConvenio
      tipoPagoConvenio
      opcionesPagoConvenio
      estatusConvenio
      fechaAltaConvenio
      fechaInicialConvenio
      fechaFinalConvenio
      RFCConvenio
      emailConvenio
    }
  }
`;

const ELIMINAR_CONVENIO_USUARIO=gql`
mutation modificarConvenioUsuario($convenioId: ID!, $usuarioId: ID!) {
    eliminarConvenioUsuario(
      input: { convenioId: $convenioId, usuarioId: $usuarioId }
    )
  }
  `;

const CAMBIAR_ESTATUS_CONVENIO_USUARIO=gql`
mutation actualizarEstatusConvenioUsuario(
    $convenioId: ID!
    $usuarioId: ID!
    $estatusConveniosUsuarios: Int!
  ) {
    actualizarEstatusConvenioUsuario(
      input: {
        convenioId: $convenioId
        usuarioId: $usuarioId
        estatusConveniosUsuarios: $estatusConveniosUsuarios
      }
    ) {
      convenioId
      convenioId
      usuarioId
      qrConveniosUsuarios
      estatusConveniosUsuarios
      fechaAltaConveniosUsuarios
      fechaBajaConveniosUsuarios
      tipo
      nombres
      apellidos
      email
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      empresaConvenio
      tipoPagoConvenio
      opcionesPagoConvenio
      estatusConvenio
      fechaAltaConvenio
      fechaInicialConvenio
      fechaFinalConvenio
      RFCConvenio
      emailConvenio
    }
  }
  `;

const MODIFICAR_CONVENIO_USUARIO_ID=`
mutation modificarConvenioUsuario(
    $convenioId: ID!
    $usuarioId: ID!
    $fechaBajaConveniosUsuarios: Date
  ) {
    modificarConvenioUsuario(
      input: {
        fechaBajaConveniosUsuarios: $fechaBajaConveniosUsuarios
        convenioId: $convenioId
        usuarioId: $usuarioId
      }
    ) {
      convenioId
      convenioId
      usuarioId
      qrConveniosUsuarios
      estatusConveniosUsuarios
      fechaAltaConveniosUsuarios
      fechaBajaConveniosUsuarios
      tipo
      nombres
      apellidos
      email
      fechaEgreso
      fechaAlta
      estatus
      sexo
      tarjetaQr
      correo
      telefono
      empresaConvenio
      tipoPagoConvenio
      opcionesPagoConvenio
      estatusConvenio
      fechaAltaConvenio
      fechaInicialConvenio
      fechaFinalConvenio
      RFCConvenio
      emailConvenio
    }
  }
`;

//#endregion

export {
    NUEVO_CONVENIO_USUARIO_ID,
    MODIFICAR_CONVENIO_USUARIO_ID,
    ELIMINAR_CONVENIO_USUARIO,
    CAMBIAR_ESTATUS_CONVENIO_USUARIO
}
