import gql from 'graphql-tag';

//#region Notificaciones
const LISTADO_NOTIFICACIONES=gql`
    query ($id: Int){
        listadoNotificaciones(id:$id){
            notificaciones{
                notificacionId
                usuarioId
                nombres
                apellidos
                mensaje
                fecha
                estatus
                diasRepeticion
                fechaCreacion
            }
            registros
            paginas
        }
    }
`;

const OBTENER_NOTIFICACION=gql`
    query obtenerNotificacion($id: Int!){
        obtenerNotificacion(id:$id){
            notificacionId
            usuarioId
            nombres
            apellidos
            mensaje
            fecha
            diasRepeticion
            estatus
            fechaCreacion
        }
    }
`;
//#endregion

export {
    LISTADO_NOTIFICACIONES,
    OBTENER_NOTIFICACION
}
