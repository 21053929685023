import { AUTORIZAR_DATOS } from '../Types';
//Fetch
import { FetchGrahpQL } from "../../Helpers/Fetch/FetchGraphql";
//Querys
import { AUTORIZACION_MODIFICACION } from "../../mutation/index";
//Dependencias
import Swal from "sweetalert2";

//crear funcion principal
export function CRUD(tipo, actualiza, datos, ActivarDesactivarBoton, ftClose) {
    return async (dispatch) => {

        FetchGrahpQL({
            query: AUTORIZACION_MODIFICACION,
            variables: {
                nombreCompletoSolicitante:datos.nombreCompletoSolicitante.valor,
                datosUsuariosAutorizacionId:parseInt(datos.datosUsuariosAutorizacionId.valor),
                nombres: datos.nombres_nuevo.valor.trim(),
                apellidos: datos.apellidos_nuevo.valor.trim(),
                email: datos.email_nuevo.valor,
                password: datos.password_nuevo.valor||null,
                sexo: parseInt(datos.sexo_nuevo.valor),
                especialidadId: parseInt(datos.especialidadId_nuevo.valor),
                cedula: datos.cedula_nuevo.valor,
                telefono: datos.telefono_nuevo.valor,
                fechaNacimiento: datos.fechaNacimiento_nuevo.valor,
                fechaEgreso: datos.fechaEgreso_nuevo.valor,
                usuarioId: parseInt(datos.usuarioId_nuevo.valor),
                correoSolicitante:datos.correoSolicitante.valor,
                rechazo: tipo,
                tipo: parseInt(datos.tipo.valor),
            }
        })
            .then(() => {
                Swal.fire("¡Éxito!", `Se ha enviado un correo al solicitante para avisar de la respuesta respecto a la autorización`, "success");
                ActivarDesactivarBoton(false);
                actualiza();
                ftClose();
            })
            .catch((err) => {
                if (err.message === "Failed to fetch") {
                    Swal.fire("¡Aviso!", "Ha ocurrido algo inesperado,inténtalo  más tarde ", "warning");
                } else {
                    Swal.fire("¡Aviso!", err.message, "warning");
                }
                ActivarDesactivarBoton(false);

            });

        dispatch(datosActualizado({ activo: true }))
    }
}


export const datosActualizado = datos => {
    return {
        type: AUTORIZAR_DATOS,
        payload: datos
    }
}



