import React from 'react';
import { compose, withProps } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { APIkeyGoogle } from '../../../Helpers/GoogleApi';

const Mapa = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${APIkeyGoogle}`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `400px` }} />,
		mapElement: <div style={{ height: `100%` }} />
	}),
	withScriptjs,
	withGoogleMap
)(({ isMarkerShown, location, titulo = "" }) => {

	let { lat, lng } = location;

	return (
		<GoogleMap defaultZoom={17} defaultCenter={{ lat, lng }}>
			{isMarkerShown && <Marker position={{ lat, lng }} label={{
                color: "#0d47a1",
                fontFamily: "Helvetica",
                fontSize: "1.5em",
                fontWeight: "bold",
                text: titulo
            }} title={titulo} />}
		</GoogleMap>
	);
});

export default Mapa;
