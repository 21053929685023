import React, { useState, useContext, useEffect } from 'react';
//****Router*********************************
import { withRouter } from "react-router-dom"
//****PROVIDER**************************************
import { CRMContext, CRMProvider } from './../../../context/Registro';
//*************Core-components*********************
import { Grid, InputAdornment, TextField, Typography, Button, ButtonGroup, CardContent, Card, Link, CardActions,Dialog,DialogTitle,IconButton,Fade }
    from '../../../componentesGenericos/Core';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
//*************Iconos******************************
import { LockOpenIcon, PersonIcon,CloseIcon } from '../../../componentesGenericos/Icons';
//*************Componentes-Genericos****************
import BotonBoostrap from "../../../componentesGenericos/Botones/BotonBoostrap";
//*************Helper*******************************
import {
    verificaValores,
    formatearCadena
} from "../../../Helpers/Validacion/ValidacionCadenas";
import GenerarNotificacionv2 from "../../../Helpers/Validacion/ValidacionesCadenasClose";
import { FetchGrahpQL } from "../../../Helpers/Fetch/FetchGraphql";
//*************Constantes*****************************
import { notificacionesLogin, usuarioLogin } from '../../../Helpers/constantes/State';
//*************Mutation*******************************
import { LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD } from "../../../query/componentes/Promociones";
import { INICIAR_SESION } from "../../../mutation";
//****REDUX***********/
import { useDispatch } from "react-redux";
import { SonidoActivacion } from "../../../Redux/Actions/SonidoNotificacionesAction";
import { IniciarSesion } from "../../../Redux/Actions/SesionAction";
import { FotoPerfil } from "../../../Redux/Actions/FotoPerfilAction";
//****COMPONENTES******
import RegistroMovil from '../Registro/RegistroMovil';
import RecupertarPassword from '../RecuperarPassword/RecuperarMovil';
//****HELPER**********/
import { DecodificarToken } from "../../../componentesGenericos/Estructura/Autenticar";
//****** MATERIAL UI ******
import { makeStyles } from '@material-ui/core/styles';
import { urlServer, strUrlSCliente } from '../../../Helpers';
import { obtenerStateStorage } from '../../../Helpers/LocalStorage/LocalStorage'
import { rutaPublica, rutaPrivada } from '../../../Helpers/constantes/Config/urlRutas';
import { getImagen, postImagen } from '../../../Helpers/constantes/Imagenes/getImagen'


const useStyles = makeStyles(theme => ({
    fullsize: {
        height: "40%",
        width: "40%",
    }
}));

function IniciarSesionMovil(props) {
    let sesiones = process.env.REACT_APP_DB;

    //Configuracion
    const { descripcionLogin = "", urlLogotipo = "" } = props.configuracion;
    //Variables
    const [openImg, setOpenImg] = useState(false);
    const [getNotificacion, setNotificacion] = useState(notificacionesLogin);
    const [usuario, guardarUsuario] = useContext(CRMContext);
    const [getUsuario, setUsuario] = useState(usuarioLogin);
    const [abrirRecuperarPassword, enviarRecuperarPassword] = useState(false);
    const agregaRutaPerfil = (datos) => dispatch(FotoPerfil(datos));
    const [open, setOpen] = useState(false);
    const [photo, setPhoto] = useState("")
    const [vista, setVista] = useState("");
    const { history } = props;

    //Logotipo
    const logoAux = `${urlServer}${"/images/icono_dentalPerfiles.jpg"}`;
    const logotipo = `${rutaPublica}${urlLogotipo}`;
    const classes = useStyles();

    //Dispatch para ejecutar nuestras acciones
    const dispatch = useDispatch();
    const agregarUsuario = (datos) => dispatch(IniciarSesion(datos));
    const agregarSonido = (datos) => dispatch(SonidoActivacion(datos));
    let bdEmp = sesiones;

    //Eventos
    const CerrarRegistro = () => {
        setOpen(false);
    }
    const AbrirRegistro = () => {
        setOpen(true);
    }
    const EventChangeDatos = event => {
        let { name, value } = event.currentTarget;
        value = value.trim();
        if (value === null || value.length === 0) {
            setUsuario({ ...getUsuario, [name]: { error: true, valor: "" } });
        } else {
            setUsuario({ ...getUsuario, [name]: { error: false, valor: value } });
        }
    };
    const EventAutenticarCliente = async () => {
        ActivarDesactivarBoton(true);
        let campos = [
            "email",
            "password"
        ];
        let datos = verificaValores(campos, getUsuario);
        let mensaje = formatearCadena(datos.arr);
        if (datos.estado === false) {
            setNotificacion({
                ...getNotificacion,
                error: 2,
                mensaje
            });
            ActivarDesactivarBoton(false);
        } else {
            setNotificacion({
                ...getNotificacion,
                error: 1,
                mensaje: ""
            });
            await FetchIniciarSesion();
        }
    };
    const userIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <PersonIcon style={{ color: valor }} />
                </InputAdornment>
            )
        }
    };
    const passwordIcon = (value) => {
        let valor = value === false ? "#2699FB" : "red";
        return {
            startAdornment: (
                <InputAdornment position="start">
                    <LockOpenIcon style={{ color: valor }} />
                </InputAdornment>
            )
        }
    };
    const CerrarRecuperarPassword = () => {
        enviarRecuperarPassword(false);
    }
    const AbrirRecuperarPassword = () => {
        enviarRecuperarPassword(true);
    }
    //API
    const FetchIniciarSesion = async () => {
        //indicar proceso de la peticion
        setNotificacion({
            ...getNotificacion,
            error: 3,
            mensaje: ""
        });

        FetchGrahpQL({
            query: INICIAR_SESION,
            variables: {
                email: getUsuario.email.valor,
                password: getUsuario.password.valor,
                nombreDB: sesiones
            }
        })
            .then(resp => {
                if (resp.iniciarSesion.success === false) {
                    setNotificacion({
                        ...getNotificacion,
                        error: 4,
                        mensaje: resp.iniciarSesion.error
                    });
                } else {
                    Login(resp);
                }
                ActivarDesactivarBoton(false);
                return resp;
            })
            .catch((err) => {

                if (err.message === "Failed to fetch") {
                    setNotificacion({
                        ...getNotificacion,
                        error: 4,
                        mensaje:"Ha ocurrido algo inesperado,inténtalo  más tarde "
                    });
                }else{
                    setNotificacion({
                        ...getNotificacion,
                        error: 4,
                        mensaje:err.message
                    });
                }

                ActivarDesactivarBoton(false);
            });


    };
    const FetchObtenerFotosPromociones = async () => {
        await FetchGrahpQL({
            query: LISTADO_IMAGENES_PROMOCIONES_CADUCIDAD,
            variables: {
                estatus: 1,
                nombreDB: bdEmp
            }
        })
            .then(resp => {
                setPhoto(resp === null || resp === "" ? "" : resp);
            })
            .catch(() => {
                setPhoto({
                    listadoPromocionesCaducidad: {
                        ImagenesPromocionesCaducidades: [{
                            imgPromoId: "-1",
                            descripcion: "imagen por defecto",
                            urlImagen: `/images/fondoPromociones.jpg`,
                            nombreImagen: "",
                            estatus: "",
                            caducidad: "",
                        }]
                    }
                });
            });
    }
    //Funciones
    const ActivarDesactivarBoton = (tipo) => {
        setUsuario({ ...getUsuario, desactivarBoton: { error: tipo, valor: "" } });
    }
    const ftCloseImg = () => {
        setOpenImg(false);
    }
    //genero dinamicamente fotos
    const generarSelect = galerias => {
        if (galerias === null) {
            return null;
        }
        if (galerias.listadoPromocionesCaducidad == null) {
            return null;
        }
        //se enlista las promociones validas
        let row = [];
        if (galerias != undefined) {
            galerias.listadoPromocionesCaducidad.ImagenesPromocionesCaducidades.map(function (x) {
                if (x.imgPromoId === "-1") {
                    row.push(
                        <Fade in={true} key={x.imgPromoId}>
                            <div key={x.imgPromoId} className="maximizaAlto" onClick={() => { setOpenImg(true); setVista(`${strUrlSCliente}${x.urlImagen}`) }}>
                                <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${strUrlSCliente}${x.urlImagen}`} />
                            </div>
                        </Fade>
                    );
                } else {
                    row.push(
                        <Fade in={true} key={x.imgPromoId}>
                            <div key={x.imgPromoId} className="maximizaAlto" onClick={() => { setOpenImg(true); setVista(`${rutaPublica}${x.urlImagen}`) }}>
                                <img alt={`${x.descripcion}`} className="maximizaAltoImagen" src={`${rutaPublica}${x.urlImagen}`} />
                            </div>
                        </Fade>
                    );
                }
            });
            return row;
        }
    };
    const Login = (resp) => {
        //redux
        let rutaImagen = "";
        let data = DecodificarToken(resp.iniciarSesion.token);
        if (data.url === null) {
            switch (data.tipo) {
                case 1:
                    rutaImagen = `${strUrlSCliente}/icons/OpcionDoctor.png`;
                    break;
                case 2:
                    rutaImagen = `${strUrlSCliente}/icons/OpcionPaciente.png`;
                    break;
                case 3:
                    rutaImagen = `${strUrlSCliente}/icons/OpcionAdministrador.png`;
                    break;
                case 4:
                    rutaImagen = `${strUrlSCliente}/icons/OpcionUsuario.png`;
                    break;
            }
            agregaRutaPerfil({ ruta: rutaImagen });
        } else {
            //rutaImagen = `${urlServer}${data.url}`;
            let rutaServerS =  `${rutaPrivada}${data.url}`;
            archivoImg(rutaServerS).then(imagen => {
                agregaRutaPerfil({ ruta: imagen });
                return imagen;
            });
        }
        //agregaRutaPerfil({ ruta: rutaImagen });
        agregarSonido({ activo:data.sonidoNotificacion===1?true:false});
        agregarUsuario({ user: { user: resp.iniciarSesion } });
        //muetro notificacion
        setNotificacion({
            ...getNotificacion,
            error: 5,
            mensaje: resp.iniciarSesion.mensaje
        });
        //ruta
        history.push("/Menu")
    }
    const archivoImg = async function(ruta) { //get storage

        let resultado = '';
           resultado = await getImagen(ruta);
        return resultado;
    }

    //Ciclo de vida
    useEffect( ()=>{
        if (!sesiones)
            history.push("/")
    },[])

    useEffect(() => {
        if (sesiones)
        {
            //document.title = sesiones.sesiones.nombreEmpresa;
            FetchObtenerFotosPromociones();
        }
    }, []);

    return (
        <>
            <CRMProvider value={[usuario, guardarUsuario]}>
                <Grid
                    style={{ width: "100%", height: "100%" }}
                    container
                >
                    <RegistroMovil open={open} cerrarRegistro={CerrarRegistro} />
                    <RecupertarPassword open={abrirRecuperarPassword} cerrarRegistro={CerrarRecuperarPassword} />
                    <Dialog open={openImg}>
                        <DialogTitle disableTypography style={{ textAlign: "Right" }}>
                            <IconButton aria-label="close" onClick={ftCloseImg} style={{ padding: 0 }}><CloseIcon /></IconButton>
                            <img src={vista} width={'100%'} />
                        </DialogTitle>
                    </Dialog>

                    <Grid item xs={12} style={{
                        height: "40vh"
                    }}>

                        <Carousel showThumbs={false} autoPlay={true} showStatus={false} infiniteLoop={true} transitionTime={1000}>
                            {generarSelect(photo)}
                        </Carousel>

                    </Grid>

                    <Grid item xs={12}>
                        <Card style={{ height: "100%" }}>
                            <CardContent>
                                <Grid item xs={12} container direction="row" justify="center">
                                    {GenerarNotificacionv2(getNotificacion, "w100")}
                                </Grid>
                                <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                    <TextField id="username" label="Correo electrónico" name="email" margin="dense" onChange={EventChangeDatos} required autoFocus={true} fullWidth InputProps={userIcon(getUsuario.email.error)} color="primary" error={getUsuario.email.error} />
                                </Grid>

                                <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                    <TextField id="password" label="Contraseña" name="password" margin="dense" onChange={EventChangeDatos} type="password" required fullWidth InputProps={passwordIcon(getUsuario.password.error)} color="primary" error={getUsuario.password.error} />
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                                    <Grid item xs={12} container direction="row" justify="center" alignItems="center">
                                        <BotonBoostrap Texto={"ENTRAR"} Color="primary" EventoClick={EventAutenticarCliente} Desactivar={getUsuario.desactivarBoton.error}></BotonBoostrap>
                                    </Grid>

                                    <Grid item xs={12} md={12} container direction="row" justify="center" alignItems="center">

                                        <Typography className={classes.root} variant="overline" >
                                            <Link onClick={AbrirRecuperarPassword}>
                                                Recuperar Contraseña
                                     </Link>
                                        </Typography>

                                    </Grid>

                                    <Grid item xs={12} md={12} container direction="row" justify="center" alignItems="center">
                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                            <Button onClick={AbrirRegistro}>Registrate</Button>
                                        </ButtonGroup>
                                    </Grid>

                                </Grid>
                            </CardActions>
                        </Card>
                    </Grid>

                    <Grid item xs={12} style={{textAlign: "center", background: "white"
                    }}>
                        <Card className="cardMovil">
                            <CardContent className="cardContent" >
                                <img src={urlLogotipo ? logotipo : logoAux} className={classes.fullsize} alt="Logotipo sucursal" className="imagenContentMovil" />
                                <Typography component="h1" variant="h5">
                                    {descripcionLogin}
                                </Typography>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </CRMProvider>
        </>
    );
}
export default withRouter(IniciarSesionMovil);
