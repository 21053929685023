import { MENU, SELECCIONAR_MENU } from '../Types';
import { menuAdminUsuario, menuPacienteDoctor } from '../../componentes/MenuPrincipal/menu';
import { obtenerStateInicialSelectedMenu } from '../../componentes/MenuPrincipal/helper';

let adminUsu = obtenerStateInicialSelectedMenu(menuAdminUsuario);
let pacienteDocto = obtenerStateInicialSelectedMenu(menuPacienteDoctor);

const initialState = {
    openMenu: {
        sucursales: false,
        inventarios: false,
        ventas: false,
        catalogos: false,
        configuracion: false,
        comercial: false
    },
    menuAdminUsuario: { ...adminUsu },
    menuPacienteDoctor: { ...pacienteDocto },
    nada: { nada: false }
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MENU:
            return {
                ...action.payload
            }
        case SELECCIONAR_MENU:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
