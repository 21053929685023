import gql from 'graphql-tag';


const NUEVA_FORMA_COBRO = gql`
mutation agregarFC ($formaCobro: FormaCobroInput!)
{
  nuevaFormaCobro(input: $formaCobro){
     formaCobroId
    formaCobro
    descripcion
    clinicaId
    nombreClinica
    aliasClinica
    estatusClinica
    claveClinica
    fechaAltaClinica
  }
}
`;

const MODIFICAR_FORMA_COBRO = gql`
  mutation modificarFC ($formaCobro: FormaCobroInput!)
{
modificarFormaCobro(input: $formaCobro){
     formaCobroId
    formaCobro
    descripcion
    clinicaId
    nombreClinica
    aliasClinica
    estatusClinica
    claveClinica
    fechaAltaClinica
  }

}  
`;

const ELIMINAR_FORMA_COBRO = gql `
mutation eliminarFC($id: Int!)
{
  eliminarFormaCobro(id: $id)
}
`;

export {
    NUEVA_FORMA_COBRO,
MODIFICAR_FORMA_COBRO,
ELIMINAR_FORMA_COBRO
}