import React, { useState, useContext, useEffect } from 'react';
import { CRMContext, CRMProvider } from './../../context/Usuarios/Usuarios';
//REDUX*****
import { useSelector } from "react-redux";
//Apollo*****
import { useQuery, useMutation } from '@apollo/react-hooks';
//Queries****
import { LISTADO_RELACION_DOCTOR } from '../../query';
import {
    ELIMINAR_USUARIO, ACTUALIZAR_ESTATUS_USUARIOS,ACTUALIZAR_ESTATUS_CORREO,
} from '../../mutation';

//****ROUTER***********/
import { withRouter } from "react-router-dom";
//Componentes***
import Tabla from '../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../componentesGenericos/SideBar';
import Mensaje from '../../componentesGenericos/Mensaje';
import Agregar from './Agregar';
import Modificar from './Modificar';
//constantes***
import { seleccionInicial, menu, actualizarState, filaState, seleccionState, formularioState, OptionsUsuarios, Style } from './Constantes';
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../Helpers/constantes';
//CORE**********

function Profesionales(props) {


    // #region redux
    const user = useSelector((state) => state.selecciondeClinica);
    let clinicaIdRedux = user.seleccionClinica[0].clinicaId;
    //#endregion

    // #region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_RELACION_DOCTOR, {
        variables: { estatus: -1, clinicaId: parseInt(clinicaIdRedux) },
        fetchPolicy: 'no-cache',
        onError: (e) => { }
    });

    const [deleteUsuario] = useMutation(ELIMINAR_USUARIO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se eliminó correctamente al profesional "${seleccion.nombres}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });

            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusUsuario] = useMutation(ACTUALIZAR_ESTATUS_USUARIOS, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente al profesional "${seleccion.nombres}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    const [statusUsuarioCorreo] = useMutation(ACTUALIZAR_ESTATUS_CORREO, {
        onError: (e) => {
            setMensaje({ ...mensaje, ...mensajeError, texto: e.message.replace("GraphQL error:", "") });
        },
        onCompleted: () => {
            ftFormularioClose();
            setMensaje({ ...mensaje, ...mensajeExito, texto: `Se cambió el estatus correctamente al profesional  "${seleccion.nombres}"` });

            setSeleccion({ ...seleccion, ...seleccionInicial });
            setFila(-1);
            setActualizar(true);
        }
    });

    //#endregion

    //#region states
    const [usuarios, guardarUsuarios] = useContext(CRMContext);
    const [filtro, setFiltro] = useState("");
    const [actualizar, setActualizar] = useState(false);
    const [fila, setFila] = useState(filaState);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [seleccion, setSeleccion] = useState(seleccionState);
    const [errorState, setErrorState] = useState(false);
    const [disabledDelete, setDisabledDelete] = useState(false);

    //manejar el tipo de formulario
    const [formulario, setFormulario] = useState(formularioState(ftFormularioClose));

    //usuario para activar o desactivar
    const [checked, setChecked] = React.useState(false);
    //usuario para activar o desactivar el correo
    const [checkeded, setCheckeded] = React.useState(false);

    //abrir modal de tipo de usuarios
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState('');
    const [openModifica, setOpenModifica] = React.useState(false);
    let { history } = props;
    //#endregion

    //#region columnas
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'usuarioId', label: 'Código de usuario', minWidth: 0, hide: false, filtro: true ,aliasColor:"estatus"},
            { id: 'fechaNacimiento', label: 'Fecha de nacimiento', minWidth: 0, hide: true },
            {
                id: 'tipo', label: 'Tipo', minWidth: 100, align: 'center', textAlign: 'center', format: (valor) => {
                    return valor.toString().replace("1", "Profesional").replace("2", "Cliente").replace("3", "Admin").replace("4", "Usuario");
                }
            },
            { id: 'nombreCompleto', label: 'Nombre completo', minWidth: 170, align: 'left', textAlign: 'left', filtro: true },
            { id: 'email', label: 'Email', minWidth: 170, filtro: true, align: 'left', textAlign: 'left' },
            { id: 'correo', label: 'Correo activado', minWidth: 170, component: "Checkbox", align: 'center' },
            {
                id: 'estatus', label: 'Estatus', minWidth: 170, align: 'center', component: "Checkbox"
                // format: (valor) => {
                //     return valor.toString().replace("0", "Inactivo").replace("1", "Activo");
                // }
            },
            {
                id: 'fechaAlta', label: 'Fecha de alta', minWidth: 170, align: 'center', textAlign: 'center', filtro: true,
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4");
                }
            },
            {
                id: 'fechaEgreso', label: 'Fecha de egreso', minWidth: 170, align: 'center',
                format: (valor) => {
                    if (valor != null) {
                        return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1 $4")
                    }
                }, hide: true
            },
            { id: 'especialidadId', label: 'Especialidad', minWidth: 0, hide: true },
            { id: 'cedula', label: 'Cedula', minWidth: 0, hide: true },
            { id: 'telefono', label: 'Telefono', minWidth: 0, hide: true },
        ];
    //#endregion

    //#region handlers
    function handleSetOpenModifica() {
        setOpenModifica(true);
    }

    function handleSetCloseModifica() {
        setOpenModifica(false);
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }

    function handleAgregarClick() {
        ftCloseRespuestaAbrir();
    }



    function handleBusquedaChange(texto) {
        setFiltro(texto)
        setFila(-1);
        setSeleccion({ ...seleccionInicial });
    }

    function handleModificarClick() {
        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            handleSetOpenModifica();
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar al profesional  "${seleccion.nombres}"?`, onAccept: ftEliminar });
            setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    function handleTablaClick(datos, index) {
        if (seleccion !== datos) {
            setChecked(datos.estatus !== 0)
            setCheckeded(datos.correo !== 0)
            setSeleccion({ ...seleccionInicial, ...datos });
            setFila(index);
        }
        else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        setCheckeded(seleccion.correo !== 0);
        ftMensajeClose();
    }

    function ftCambiarEstatus() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusUsuario({
                variables: {
                    usuarioId: Number(seleccion.usuarioId),
                    estatus: seleccion.estatus === 0 ? 1 : 0,
                    tipo: seleccion.tipo
                }
            })
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function ftCambiarEstatusCorreo() {
        async function ftCambiarEstatus() {
            setDisabledDelete(true);
            await statusUsuarioCorreo({
                variables: {
                    usuarioId: Number(seleccion.usuarioId),
                    estatus: seleccion.correo === 0 ? 1 : 0,
                    tipo: seleccion.tipo
                }
            })
            setDisabledDelete(false);
        } ftCambiarEstatus();
    }

    function handleCambiarEstatusClick() {
        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            if (seleccion.correo === 0) {
                setMensaje({ ...mensaje, ...mensajeError, texto: "El profesional no ha activado su correo" });
            } else {
                setChecked(seleccion.estatus === 0);
                let opcion = seleccion.estatus === 1 ? "desactivar" : "activar";
                setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} al profesional "${seleccion.nombres}"?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
                setFila(-1);
            }
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    function handleCambiarEstatusCorreoClick() {

        if (seleccion.usuarioId !== null && seleccion.usuarioId !== undefined && fila !== -1) {
            setCheckeded(seleccion.correo === 0);
                let opcion = seleccion.correo === 1 ? "desactivar" : "activar";
                setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} el correo del profesional "${seleccion.nombres}"?`, onAccept: ftCambiarEstatusCorreo, onCancel: ftMensajeCancel });
                setFila(-1);
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar un profesional" });
        }
    }

    //#endregion

    //#region funciones

    function ftEliminar() {
        async function ftEliminar() {
            setDisabledDelete(true);
            await deleteUsuario({ variables: { usuarioId: Number(seleccion.usuarioId) } });
            setDisabledDelete(false);
        } ftEliminar();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }

    function ftCloseRespuestaAbrir() {
        setOpen(true);
    }

    const handleClose = newValue => {
        setOpen(false);

        if (newValue) {
            setValue(newValue);
        }
    };

    const redirige = (texto) => {
        history.push(texto);
    }

    const actualizarTabla = () => {
        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }
    //#endregion

    //#region actualiza
    useEffect(() => {
        setFila(-1)
        setActualizar(true);
    }, [clinicaIdRedux]);

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoRelacionDoctor?.doctores;

    if (actualizar === true) {

        refetch().then(() => setErrorState(false)).catch(e => {

            setErrorState(true);
        });
        setActualizar(false);
    }



    //#endregion

    return (<>
        <CRMProvider value={[usuarios, guardarUsuarios]}>
            <MenuLateral titulo="Usuarios" menu={menu(handleAgregarClick, handleModificarClick, handleEliminarClick, handleCambiarEstatusClick, checked, redirige,checkeded,handleCambiarEstatusCorreoClick)}>
                <Agregar
                    id="ringtone-menu"
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    value={value}
                    datos={OptionsUsuarios}
                    botonder={"Agregar"}
                    botonizq={"Cancelar"}
                    actualiza={() => actualizarTabla()}
                    seleccion={seleccion}
                    ftClose={handleClose}
                />
                <Modificar open={openModifica} seleccion={seleccion} ftOpen={handleSetOpenModifica} ftClose={handleSetCloseModifica} actualiza={() => actualizarTabla()} index={setFila} setSeleccion={setSeleccion} />

                <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={mensaje.onCancel} disabledAccept={disabledDelete} progress={disabledDelete} />


                <Tabla titulo="Listado de profesionales" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange} titleResponsive={["nombreCompleto", "email"]} />


            </MenuLateral>
        </CRMProvider>
    </>
    );
}

export default withRouter(Profesionales);
