import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Componentes
import Icono from '../../componentesGenericos/Icono';
import Encabezado from '../../componentesGenericos/EncabezadoComponentes';
import MenuLateral from '../../componentesGenericos/SideBar';
import { Grid, Card, CardContent, ListItemIcon, ListItemText, ListItem, List, Divider } from '../../componentesGenericos/Core';

// Reportes
import Mensaje from './componentes/Mensaje';
import Tabla from './componentes/Tabla';
import ReporteAgenda from './componentes/ReporteAgenda';
import ReportePuntoVenta from './componentes/ReportePuntoVenta';
import ReporteComision from './componentes/ReporteComision';
import ReporteCredito from './componentes/ReporteCredito';
import ReporteGarantia from './componentes/ReporteGarantia';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	details: {
		display: 'flex',
		flexDirection: 'column'
	},
	content: {
        flex: '2 0 auto'
	},
	controls: {
		display: 'flex',
		alignItems: 'center',
		paddingLeft: theme.spacing(1),
		paddingBottom: theme.spacing(1)
	},
	playIcon: {
		height: 38,
		width: 38
    }
}));

function Reportes() {

    const classes = useStyles();

    const defaultMensaje = {
        visible: false,
        descripcion: '',
        tipo: 'error' // Opciones: error, warning, info, success
    };

    const defaultSelected = {
        agenda: false,
        puntoVenta: false
    };

    const [ mensaje, setMensaje ] = useState(defaultMensaje);
    const [ componente, setComponente ] = useState();
    const [ selected, setSelected ] = useState(defaultSelected);
    const [ historial, setHistorial ] = useState();

    const handleCloseMensaje = (event, reason) => {
        if (reason === 'clickaway') return;
        setMensaje({
            ...mensaje,
            visible: false
        });
    };

    const setMensajeNot = (props) => {
        setMensaje({
            ...mensaje,
            ...props
        });
    }

    const Agenda = <ReporteAgenda setMensaje={setMensajeNot} />;
    const PuntoVenta = <ReportePuntoVenta setMensaje={setMensajeNot} />;
    const Comision =<ReporteComision setMensaje={setMensajeNot} />;
    const Creditos = <ReporteCredito setMensaje={setMensajeNot}/>;
    const Garantia =<ReporteGarantia setMensaje={setMensajeNot}/>

    const menuReportes = [{
        id: "agenda",
        descripcion: "Agenda",
        componente: Agenda,
        icono: <Icono ruta="/icons/calendar.png" texto="agenda" />
    },{
        id: "puntoVenta",
        descripcion: "Punto de venta",
        componente: PuntoVenta,
        icono: <Icono ruta="/icons/pos-terminal.png" texto="punto de venta" />
    },
    {
        id: "Comision",
        descripcion: "Comisiones",
        componente: Comision,
        icono: <Icono ruta="/icons/comisiones.png" texto="Comisiónes" />
    },
];

    useEffect(() => {
        setComponente(Agenda);
        setSelected({
            ...selected,
            agenda: true
        });
    }, []);
   // <Tabla historial={historial} /> dentro del grid container
	return (
		<MenuLateral titulo="Reportes">
			<Grid container className={classes.root} spacing={2}>
				<Grid item xs={12}>
					<Grid container justify="center" spacing={2}>
                        <Grid item xs={12}>
                            <Encabezado titulo="Reportes" />
                        </Grid>
						<Grid item xs={12} sm={12} md={5} lg={4}>
							<Card>
								<div>
									<CardContent>
										<List>
											{menuReportes.map(({id, descripcion, componente, icono}, index) => (
												<Fragment key={index}>
                                                    <ListItem
                                                        selected={selected[id]}
                                                        button
                                                        onClick={() => {
                                                            setComponente(componente);
                                                            setSelected({
                                                                ...defaultSelected,
                                                                [id]: true
                                                            });
                                                            setHistorial(descripcion);
                                                    }}>
                                                        <ListItemIcon>
                                                            {icono}
                                                        </ListItemIcon>
                                                        <ListItemText primary={descripcion} />
                                                    </ListItem>
                                                    <Divider />
                                                </Fragment>
											))}
										</List>
									</CardContent>
								</div>
							</Card>
						</Grid>
						<Grid item xs={12} sm={12} md={7} lg={6}>
                            <Card>
                                <div>
                                    <CardContent>
                                        <Grid container direction={"row"} justify={"center"} alignItems={"center"}>
                                            {componente}
                                        </Grid>
                                    </CardContent>
                                </div>
                            </Card>
						</Grid>
                        <Grid item xs={12}>
                            <Grid container direction={"row"} justify={"center"} alignItems={"center"}>

                            </Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
            <Mensaje visible={mensaje.visible} descripcion={mensaje.descripcion} tipo={mensaje.tipo} handleClose={handleCloseMensaje} />
		</MenuLateral>
	);
}

export default Reportes;
