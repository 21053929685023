import { _urlencoded } from '../constantes/Config/Helper';
import { createApolloFetch } from 'apollo-fetch';
import { strUrlServer as urlServer } from '../constantes/Config/UrlServer';
import { obtenerStateStorage } from '../LocalStorage/LocalStorage';

//import {cPostal} from '../../componentes/Clinicas/Direcciones/Direcciones';
//nuevo esto y nueva constantes
const formatURL = (url, params) => {
    if(!params) return url;
    let keys = Object.keys(params);
    let cont = 0;
    keys.forEach(key => {
        url += params[key] ? `${cont === 0 ? "?" : "&"}${key}=${params[key]}` : "";
        cont++;
    });
    return url;
};

export const FetchAPI = async (json = {}) => {
    try {

        if (typeof json !== 'object') throw new Error("Se necesita enviar un objeto");

        let { url, data, token, method = "GET", file = false, params } = json;

        if (!url) throw new Error("La ruta no es válida");

        let header = {};

        if (method === "GET") {
            if (token) {
                header = {
                    method: "GET",
                    headers: { 'token': token }
                };
            }
            url = formatURL(url, params);
        } else {
            header = {
                method,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Access-Control-Allow-Origin': '*'
                }
            };

            if (token) header.headers.token = token;
            if (data) header.body = _urlencoded(data);
        }

        return await fetch(url, header)
            .then(res => {
                if(!res.ok) return res.json();
                if(!file) return res.json();
                return res.blob();
            });

    } catch ({message}) { throw new Error(message); }
};

export const FetchGrahpQL = async (json = {}) => {
    try {

        if (typeof json !== 'object') throw new Error("Se necesita enviar un objeto");

        let token = obtenerStateStorage("user");

        try { token = token.user.usuario[0].user.user.token;
        } catch (error) { token = null; }

        let { url = urlServer, query, variables, signal } = json;

        if (!query) throw new Error("Se necesita un query");

        const fetchApollo = createApolloFetch({
            uri: `${url}/graphql`,
        });

        fetchApollo.use(({ request, options }, next) => {
            if (!options.headers) { options.headers = {}; }
            options.headers['token'] = token;
            next();
        });

        const { data, errors } = await fetchApollo({ query, variables, signal });

        if (errors) throw new Error(errors[0].message);

        return data;

    } catch ({message}) { throw new Error(message); }
};
