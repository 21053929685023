import React, { useState, useEffect } from 'react';

//Redux
import { useSelector } from 'react-redux';

//Apollo
import { useQuery, useMutation } from '@apollo/react-hooks';

//Queries
import { LISTADO_NOTIFICACIONES, OBTENER_LISTADO_GENERAL_USUARIOS, LISTADO_USUARIO, LISTADO_DOCTOR, LISTADO_PACIENTE, LISTADO_ADMINISTRADOR, } from '../../../query';

import { CAMBIAR_ESTATUS_NOTIFICACION } from '../../../mutation';

//Componentes
import { TextField } from '../../../componentesGenericos/Core';
import Tabla from '../../../componentesGenericos/ContenedorTabla';
import MenuLateral from '../../../componentesGenericos/SideBar';
import Mensaje from '../../../componentesGenericos/Mensaje';
import Formulario from '../../../componentesGenericos/FormularioAntiguo';

//constantes
import { mensajeInicial, mensajeError, mensajeExito, mensajeConfirmacion } from '../../../Helpers/constantes';

//Iconos
import { AddIcon, EditIcon, SwitchIcon, DeleteForever } from '../../../componentesGenericos/Icons';

//Funciones
import { useSendNotification, useModNotification, useChangeStatusNotification, useDelNotification } from '../funcionesNotificacion';
import { getUsuario } from '../../../Helpers/Usuario';

//Expresiones regulares
import { rgxNoVacio, rgxFechaValida } from '../../../Helpers/constantes/Rgx';


export default function ListadoNotificaciones() {
    const user = getUsuario(useSelector(state => state.user.usuario[0].user.user.token)).usuario;
    const AddNotificacion = useSendNotification();
    const modNotificacion = useModNotification();
    const statNotificacion = useChangeStatusNotification();
    const delNotificacion = useDelNotification();

    const [usuarioslist, setUsuarios] = useState([{ nombres: 'No se encontraron usuarios', id: null }]);

    const usuarioIdRef = React.useRef();

    //#region states
    let dt = new Date();
    const seleccionInicial = {
        notificacionId: null,
        tipoUsuarioId: 0,
        usuarioId: null,
        mensaje: null,
        fecha: dt.toISOString().split("T")[0],
        estatus: 1,
        diasRepeticion: 0,
        fechaCreacion: null
    };

    const [actualizar, setActualizar] = useState(false);
    const [filtro, setFiltro] = useState("");
    const [fila, setFila] = useState(-1);
    const [mensaje, setMensaje] = useState(mensajeInicial);
    const [checked, setChecked] = useState(false);
    const [seleccion, setSeleccion] = useState(seleccionInicial);
    const [formulario, setFormulario] = useState({
        abrir: false,
        titulo: "Formulario",
        onClose: ftFormularioClose,
        onAccept: null,
        onCancel: null
    });
    const [errorState, setErrorState] = useState(false);
    const [disabledAccept, setDisabledAccept] = useState(false);
    //#endregion


    //#region queries
    const { loading, error, data, refetch } = useQuery(LISTADO_NOTIFICACIONES, {
        fetchPolicy:"cache-and-network",
        variables: /*user.tipo === 4 ||*/ user.tipo === 3 ? {} : { id: user.usuarioId },
        onError: (e) => {
            console.log(e.message)
            setErrorState(true);
        },
        onCompleted: () => {
            setErrorState(false);
        }
    });

    const { data: usuarios, refetch: refetchUsuario } = useQuery(OBTENER_LISTADO_GENERAL_USUARIOS, {
        variables: { offset: 0 ,moroso: null},
        onError: (e) => {
            console.log(e.message);
            setUsuarios([{ nombres: 'No se encontraron usuarios', id: null }])
        },
        onCompleted: () => {
            if (usuarios) {
                setUsuarios(usuarios.listadoGeneral.usuarios);

            }
        }
    })
    //#endregion

    //#region mutations
    const [cambiarEstatus] = useMutation(CAMBIAR_ESTATUS_NOTIFICACION, {
        onError: (e) => {
            console.log(e.message)
        },
        onCompleted: () => {
            if (window.refresh) window.refresh();
        }
    });
    //#endregion

    //#region constantes
    const usuarioProps = {
        options: usuarioslist,
        getOptionLabel: option => option.nombreCompleto + " (" + option.email + ")",
        noOptionsText:'No se encontraron registros'
    };
    const columnas = errorState ? [{
        id: 'msj', label: '', format: (valor) => {
            return <p style={{ fontSize: 18, margin: 0 }}>{valor}</p>
        }
    }] : [
            { id: 'notificacionId', label: 'notificacionId', minWidth: 0, hide: true },
            { id: 'nombres', label: 'Usuario', minWidth: 100, align: 'left', textAlign: 'left', filtro: user.tipo === 2 || user.tipo === 1 ? false : true , hide: user.tipo === 2 || user.tipo === 1 ? true : false },
            { id: 'mensaje', label: 'Mensaje', minWidth: 100, align: 'left', textAlign: 'left', filtro: true },
            {
                id: 'estatus', label: 'Estatus', minWidth: 100, align: 'left', textAlign: 'left',component: "Checkbox", filtro: true,
                format: (valor) => {
                    return valor === 0 ? "Leído" : "Pendiente";
                }
            },
            {
                id: 'fecha', label: 'Fecha de entrega', minWidth: 170, align: 'center', filtro: true, format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1");
                }
            },
            {
                id: 'fechaCreacion', label: 'Fecha de alta', minWidth: 170, align: 'center', filtro: user.tipo === 2 || user.tipo === 1 ? false : true , hide: user.tipo === 2 || user.tipo === 1 ? true : false ,
                format: (valor) => {
                    return valor.replace(/^(\d{4})-(\d{2})-(\d{2})T(\d{2}:\d{2}:\d{2}).\d{3}Z$/, "$3/$2/$1");
                }
            }
        ];
    const menu = [
        { texto: "Agregar notificación", icono: <AddIcon />, onClick: () => handleAgregarClick() },
        { texto: "Modificar notificación", icono: <EditIcon />, onClick: () => handleModificarClick() },
        { texto: "Activar/Desactivar", icono: <SwitchIcon checked={checked} />, onClick: () => handleCambiarEstatusClick() },
        { texto: "Eliminar notificación", icono: <DeleteForever />, onClick: () => handleEliminarClick() }
    ];
    const camposFormulario = [
        {
            id: "mensaje",
            texto: "Mensaje *",
            valor: seleccion.mensaje,
            tipo: "text",
            xs: 12,
            regexp: rgxNoVacio,
            ayuda: "Campo obligatorio",
            onChange: handleMensajeChange
        },
        {
            id: "tipoUsuarioId",
            texto: "Tipo de usuario *",
            valor: seleccion.tipoUsuarioId,
            tipo: "select",
            xs: 12,
            onChange: (e) => handleTipoChange(e.target.value),
            opciones: [
                {
                    label: "Todos",
                    value: 0
                },
                {
                    label: "Doctor",
                    value: 1
                },
                {
                    label: "Paciente",
                    value: 2
                },
                {
                    label: "Administrador",
                    value: 3
                },
                {
                    label: "Usuario",
                    value: 4
                }
            ]
        },
        {
            id: "usuarioId",
            texto: "Usuario *",
            valor: usuarioslist ? seleccion.usuarioId ? usuarioslist.find(val => val.usuarioId == seleccion.usuarioId) : -1 : -1,
            tipo: "autocomplete",
            regexp: rgxNoVacio,
            ayuda: "Campo obligatorio",
            xs: 12,
            propiedades: usuarioProps,
            onChange: handleUsuariosChange
        },
        {
            id: "fecha",
            texto: "Fecha de entrega *",
            valor: seleccion.fecha,
            tipo: "date",
            xs: 12,
            ayuda: 'Formato de fecha no valido',
            regexp: rgxFechaValida,
            otros: {
                inputProps: { min: 1 }
            }
        }
    ];

    const listado = errorState ? [{ msj: error ? error.message : "No se encontraron registros" }]
        : data?.listadoNotificaciones?.notificaciones;
    //#endregion

    //#region handlers
    async function handleAgregarClick() {
        setSeleccion(seleccionInicial)
        setFila(-1);
        usuarioIdRef.current = null;
        setFormulario({ ...formulario, abrir: true, onAccept: ftAgregar });
    }

    function handleModificarClick() {
        if (seleccion.notificacionId !== null && seleccion.notificacionId !== undefined) {
            setFormulario({ ...formulario, abrir: true, onAccept: ftModificar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una notificación" });
        }
    }

    function handleCambiarEstatusClick() {
        if (seleccion.notificacionId !== null && seleccion.notificacionId !== undefined) {
            setChecked(seleccion.estatus === 0);
            let opcion = seleccion.estatus === 0 ? "activar" : "desactivar";

            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Desea ${opcion} la notificación?`, onAccept: ftCambiarEstatus, onCancel: ftMensajeCancel });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una notificación" });
        }
    }

    function handleEliminarClick() {
        if (seleccion.notificacionId !== null && seleccion.notificacionId !== undefined) {
            setMensaje({ ...mensaje, ...mensajeConfirmacion, texto: `¿Está seguro de eliminar la notificación?`, onAccept: ftEliminar });
        } else {
            setMensaje({ ...mensaje, ...mensajeError, texto: "Debe seleccionar una notificación" });
        }
    }


    function handleTipoChange(tipo) {
        let nuevoLs = [];
        if (usuarios) {
            nuevoLs = usuarios.listadoGeneral.usuarios.filter(user => {
                if (user.tipo == tipo || tipo == 0) {
                    return true;
                }
                return false;
            })
        }
        setUsuarios(nuevoLs);
        setSeleccion({ ...seleccion, usuarioId: null })
    }

    function handleMensajeChange(e){
        setSeleccion({ ...seleccion, mensaje: e?.target?.value });
    }

    function handleUsuariosChange(e, val) {
        usuarioIdRef.current = val ? Number(val.usuarioId) : null;
        setSeleccion({ ...seleccion, usuarioId: val ? Number(val.usuarioId) : null })
    }

    function handleBusquedaChange(texto) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
        setFiltro(texto)
    }

    function handlePaginaChange(nuevaPagina) {
        setSeleccion({ ...seleccion, ...seleccionInicial });
        setFila(-1);
    }


    function handleTablaClick(datos, index) {
        if (datos.notificacionId !== null && datos.notificacionId !== undefined) {

            if (seleccion !== datos) {
                let tipo = 0;
                if (usuarios) {
                    usuarios.listadoGeneral.usuarios.find(user => {
                        if (user.usuarioId == datos.usuarioId) {
                            tipo = Number(user.tipo);
                            return true;
                        }
                        return false;
                    })
                }
                let nuevoLs = [];
                if (usuarios) {
                    nuevoLs = usuarios.listadoGeneral.usuarios.filter(user => {
                        if (user.tipo == tipo) {
                            return true;
                        }
                        return false;
                    })
                }
                setUsuarios(nuevoLs);

                setChecked(datos.estatus !== 0)
                setSeleccion({ ...datos, fecha: datos.fecha.split("T")[0], tipoUsuarioId: tipo });
                setFila(index);
                usuarioIdRef.current = datos.usuarioId;
            }
            else {
                setSeleccion(seleccionInicial);
                setFila(-1);
                usuarioIdRef.current = null;
            }
        } else {
            setSeleccion(seleccionInicial);
            setFila(-1);
        }
    }
    //#endregion

    //#region funciones
    function ftAgregar({ usuarioId, mensaje, fecha, diasRepeticion = 0, estatus = 1 }) {
        setDisabledAccept(true);
        usuarioId = usuarioId ? Number(usuarioId.usuarioId) : usuarioIdRef.current;

        mensaje = mensaje || window.mensaje.value;
        fecha = fecha || window.fecha.value;
        diasRepeticion = diasRepeticion || 0;
        estatus = estatus || window.estatus.value || 1;

        setSeleccion({ ...seleccion, usuarioId, mensaje });

        let validacion = ftValidarObligatorios({ usuarioId, mensaje, fecha });

        if (validacion === "") {
            validacion = ftValidarFormato({ ...seleccion, usuarioId, mensaje, fecha });
            if (validacion === "") {
                AddNotificacion({ mensaje, usuarioId, fecha, diasRepeticion, estatus }).then(resp => {
                    setDisabledAccept(false);
                    if (resp.ok) {
                        ftFormularioClose();
                        setMensaje({ ...mensaje, ...mensajeExito, texto: resp.msj });

                        setSeleccion({ ...seleccion, ...seleccionInicial });
                        setFila(-1);
                        setActualizar(true);
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: resp.msj });
                    }
                });
            }
        }
        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftModificar({ usuarioId, mensaje, fecha, diasRepeticion = 0, estatus = 1 }) {
        setDisabledAccept(true);

        usuarioId = usuarioId ? Number(usuarioId.usuarioId) : usuarioIdRef.current;
        mensaje = mensaje || window.mensaje.value;
        fecha = fecha || window.fecha.value;

        let validacion = ftValidarObligatorios({ notificacionId: seleccion.notificacionId, mensaje, usuarioId, fecha });
        if (validacion === "") {
            validacion = ftValidarFormato({ usuarioId, mensaje, fecha });
            if (validacion === "") {
                modNotificacion({ notificacionId: seleccion.notificacionId, usuarioId, mensaje, fecha, diasRepeticion, estatus }).then(resp => {
                    setDisabledAccept(false);
                    if (resp.ok) {
                        ftFormularioClose();
                        setMensaje({ ...mensaje, ...mensajeExito, texto: resp.msj });

                        setSeleccion({ ...seleccion, ...seleccionInicial });
                        setFila(-1);
                        setActualizar(true);
                    } else {
                        setMensaje({ ...mensaje, ...mensajeError, texto: resp.msj });
                    }
                });
            }
        }
        if (validacion !== "") {
            setDisabledAccept(false);
            setMensaje({ ...mensaje, ...mensajeError, texto: validacion });
        }
    }

    function ftCambiarEstatus() {
        setDisabledAccept(true);
        statNotificacion({ id: seleccion.notificacionId, estatus: seleccion.estatus === 0 ? 1 : 0, usuarioId: seleccion.usuarioId }).then(resp => {
            setDisabledAccept(false);

            if (resp.ok) {
                ftFormularioClose();
                setMensaje({ ...mensaje, ...mensajeExito, texto: resp.msj });

                setSeleccion({ ...seleccion, ...seleccionInicial });
                setFila(-1);
                setActualizar(true);
            } else {
                setMensaje({ ...mensaje, ...mensajeError, texto: resp.msj });
            }
        });
    }

    function ftEliminar() {
        setDisabledAccept(true);
        delNotificacion({ id: seleccion.notificacionId }).then(resp => {
            setDisabledAccept(false);
            if (resp.ok) {
                setMensaje({ ...mensaje, ...mensajeExito, texto: resp.msj });
                setSeleccion({ ...seleccion, ...seleccionInicial });
                setFila(-1);
                setActualizar(true);
            } else {
                setMensaje({ ...mensaje, ...mensajeError, texto: resp.msj });
            }
        });
    }

    function ftValidarObligatorios(campos) {
        if (!campos.mensaje) return "El mensaje no debe de ir vacio";
        if (campos.mensaje.trim() === "") return "El mensaje no debe de ir vacio";
        if (!campos.usuarioId) return "Seleccione un usuario";
        if (campos.usuarioId === -1) return "Seleccione un usuario";
        if (!campos.fecha) return "Debe establecer una fecha";
        return Object.values(campos).every((valor) => (valor !== null && valor !== undefined && valor !== "")) ? "" : "Faltan valores obligatorios";
    }

    function ftValidarFormato(campos) {
        if (Number(campos.usuarioId) < 1) return "Seleccione un usuario";

        let fc = new Date(campos.fecha);
        fc.setDate(fc.getDate() + 1);
        let factual = new Date();
        factual.setHours("0");

        if (fc < factual) return "La fecha seleccionada es anterior a la fecha actual"
        return "";
    }

    function ftMensajeCancel() {
        setChecked(seleccion.estatus !== 0);
        ftMensajeClose();
    }

    function ftMensajeClose() {
        setMensaje({ ...mensaje, abrir: false });
    }

    function ftFormularioClose() {
        setFormulario({ ...formulario, abrir: false });
    }
    //#endregion

    if (actualizar === true) {
        refetch().then(() => setErrorState(false)).catch(e => {
            setErrorState(true);
        });
        setActualizar(false);
    }

    useEffect(() => {
        if (user) {
            cambiarEstatus({
                variables: {
                    id: null,
                    estatus: 0,
                    usuarioId: user.usuarioId
                }
            })
        }
    }, [actualizar]);

    return (
        <MenuLateral titulo="Notificaciones" menu={user.tipo === 4 || user.tipo === 3 ? menu : []}>

            <Mensaje titulo={mensaje.titulo} abrir={mensaje.abrir} texto={mensaje.texto} onClose={ftMensajeClose} icono={mensaje.icono} tipo={mensaje.tipo} color={mensaje.color} onAccept={mensaje.onAccept} onCancel={ftMensajeCancel} disabledAccept={disabledAccept} />

            <Formulario abrir={formulario.abrir} campos={camposFormulario} titulo="Formulario" onClose={formulario.onClose} onAccept={formulario.onAccept} valor={seleccion} disabledAccept={disabledAccept} />

            <Tabla titulo="Listado de notificaciones" columnas={columnas} datos={listado} onClick={handleTablaClick} onSearch={handleBusquedaChange} indice={fila} filtro={filtro} loading={loading} msjError={error?.message} onPaginaChange={handlePaginaChange}
            titleResponsive={["nombres", "mensaje", "fecha"]}
            /*filtrosSeleccionables={[{
                componenteProps: [{
                    id: "filtroEstatus", texto: "Filtrar por estatus", valor: 1, opciones: [{ value: 0, label: "Inactivo" }, { value: 1, label: "Activo" }]
                }], campo: "estatus", valor: '1'
            }]}*/ />

        </MenuLateral >
    );
}
