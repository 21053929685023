import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { ftAniosRangosValidos, ftAniosRangosValidosHoy, ftAnioNoValido, ftAniosRangosValidosAlterno } from "../../../../Helpers";
//Core
import { Grid, TextField, InputLabel, Select, FormControl, Input, MenuItem, FormHelperText, InputAdornment, IconButton } from '../../../../componentesGenericos/Core';
//Genericos
import Fieldset from '../../../../componentesGenericos/FieldSet';
//Dependencias
import Swal from "sweetalert2";
//Rgx
import { rgxEmail, rgxtel, rgxLetras, rgxPassword } from '../../../../Helpers/constantes/Rgx';
//Query
import { FetchGrahpQL } from "../../../../Helpers/Fetch/FetchGraphql";
import { LISTADO_ESPECIALIDAD_DOCTOR_CADENA } from '../../../../query';
import { LISTADO_GENERO } from "../../../../query/componentes/Genero";
//Context
import { CRMContext } from '../../../../context/Usuarios/Usuarios';
//Iconos
import { VisibilityIcon, VisibilityOffIcon }
    from '../../../../componentesGenericos/Icons';
//Estilos
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Doctor() {

    //Declaraciones
    const classes = useStyles();
    let rgxEmails = new RegExp(rgxEmail);
    let rgxPasswords = new RegExp(rgxPassword);
    let rgxTelefono = new RegExp(rgxtel);
    let rgxLetra = new RegExp(rgxLetras);
    const [sexo, setSexo] = React.useState(0);
    const [datos, guardarDatos] = useContext(CRMContext);
    const [especialidades, guardarEspecialidades] = React.useState('');
    const [genero, setGenero] = useState('');

    //Eventos
    const handleChangeGuardarState = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0) {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: false, valor: value
                    }
                }
            });
        }
    }

    const handleChangeGuardarStateFecha = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || ftAniosRangosValidosHoy(value) === false || ftAnioNoValido(value) === true || handleVerificaFecha(value, datos.Doctor.fechaEgreso.valor) === true) {

            if ((ftAniosRangosValidosAlterno(datos.Doctor.fechaEgreso.valor) === false || ftAnioNoValido(datos.Doctor.fechaEgreso.valor) === true || handleVerificaFecha(value, datos.Doctor.fechaEgreso.valor) === true)){

                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        [name]: {
                            ...datos.Doctor[name],
                            error: true, valor: value
                        },
                        fechaEgreso: {
                            ...datos.Doctor.fechaEgreso,
                            error: true
                        },
                    }
                });

            }else{

                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        [name]: {
                            ...datos.Doctor[name],
                            error: true, valor: value
                        }
                    }
                });

            }



        } else {

            if (!(ftAniosRangosValidosAlterno(datos.Doctor.fechaEgreso.valor) === false || ftAnioNoValido(datos.Doctor.fechaEgreso.valor) === true || handleVerificaFecha(value, datos.Doctor.fechaEgreso.valor) === true)) {

                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        fechaEgreso: {
                            ...datos.Doctor.fechaEgreso,
                            error: false
                        },
                        [name]: {
                            ...datos.Doctor[name],
                            error: false, valor: value
                        }

                    }
                });

            } else {

                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        [name]: {
                            ...datos.Doctor[name],
                            error: false, valor: value
                        }
                    }
                });

            }
        }
    }


    const handleChangeGuardarStateFechaEgreso = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (value === null || value.length === 0 || ftAniosRangosValidosAlterno(value) === false || ftAnioNoValido(value) === true || handleVerificaFecha(datos.Doctor.fechaNacimiento.valor, value) === true) {


            if ((ftAniosRangosValidosHoy(datos.Doctor.fechaNacimiento.valor) == false || ftAnioNoValido(datos.Doctor.fechaNacimiento.valor) === true || handleVerificaFecha(datos.Doctor.fechaNacimiento.valor, value) === true)) {

                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        fechaNacimiento: {
                            ...datos.Doctor.fechaNacimiento,
                            error: true
                        },
                        [name]: {
                            ...datos.Doctor[name],
                            error: true, valor: value
                        }

                    }
                });

            }else{

            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: value
                    },
                }
            });

        }

        } else {


            if (!(ftAniosRangosValidosHoy(datos.Doctor.fechaNacimiento.valor) == false || ftAnioNoValido(datos.Doctor.fechaNacimiento.valor) === true || handleVerificaFecha(datos.Doctor.fechaNacimiento.valor, value) === true)) {


                guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        fechaNacimiento: {
                            ...datos.Doctor.fechaNacimiento,
                            error: false
                        },
                        [name]: {
                            ...datos.Doctor[name],
                            error: false, valor: value
                        }

                    }
                });

            } else {
            guardarDatos({
                    ...datos,
                    Doctor: {
                        ...datos.Doctor,
                        [name]: {
                            ...datos.Doctor[name],
                            error: false, valor: value
                        }
                    }
            });
            }


        }
    }

    const handleVerificaFecha = (nacimiento, egreso) => {
        if (nacimiento === egreso || !(egreso > nacimiento)) {
            return true;
        } else {
            return false;
        }
    }

    const handleChangeGuardarStatePassword = event => {
        let { name, value } = event.target;
        if (rgxPasswords.test(value)) {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: value
                    }
                }
            });
        }

    }

    const handleChangeGuardarNombresApellidos = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxLetra.test(value) && value.length > 1) {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: ''
                    }
                }
            });
        }

    }

    const handleChangeGuardarStateSexo = event => {
        const { name, value } = event.target;
        setSexo(event.target.value);
        guardarDatos({
            ...datos,
            Doctor: {
                ...datos.Doctor,
                [name]: {
                    ...datos.Doctor[name],
                    error: false, valor: value
                }
            }
        });
    }

    const handleChangeGuardarStateCorreo = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxEmails.test(value)) {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const obtenerEspecialidadesApi = async () => {
        await FetchGrahpQL({
            query: LISTADO_ESPECIALIDAD_DOCTOR_CADENA,
            variables: {
                offset: 0
            }
        })
            .then(resp => {
                guardarEspecialidades({ datos: resp.listadoEspecialidadDoctor })
            })
            .catch((err) => {

            });
    }

    const FetchGenero = async () => {
        await FetchGrahpQL({
            query: LISTADO_GENERO
        })
            .then(resp => {
                setGenero({ datos: resp.listadoGenero })
            })
            .catch((err) => {
            });
    }

    const handleChangeGuardarTelefono = event => {
        let { name, value } = event.target;
        value = value.trim();
        if (rgxTelefono.test(value)) {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: false, valor: value
                    }
                }
            });
        } else {
            guardarDatos({
                ...datos,
                Doctor: {
                    ...datos.Doctor,
                    [name]: {
                        ...datos.Doctor[name],
                        error: true, valor: ''
                    }
                }
            });
        }
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        guardarDatos({
            ...datos,
            Doctor: {
                ...datos.Doctor,
                showPassword: {
                    ...datos.Doctor.showPassword,
                    error: !datos.Doctor.showPassword.error
                }
            }
        });
    };

    //Funciones
    const ftGenerarSelect = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.especialidadesDoctores.map(function (x) {
                row.push(<MenuItem key={x.especialidadId} value={x.especialidadId} readOnly>{x.descripcion}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    const ftGenerarSelectGenero = (data) => {
        if (data === null) return null;
        let row = [];
        row.push();
        if (data !== undefined) {
            data.genero.map(function (x) {
                row.push(<MenuItem key={x.idGenero} value={x.idGenero} readOnly>{x.genero}</MenuItem>);
                return true;
            });
            return row;
        }
    };

    useEffect(() => {
        FetchGenero();
        obtenerEspecialidadesApi();
    }, []);


    return (
        <>
            <Fieldset contenido={
                <div>
                    <Grid spacing={3} container direction="row">
                        <Grid item xs={12}>
                            <TextField margin="dense" name="nombres" id={`nombre`} label="Nombre" type="text" style={{ width: "100%" }} required={true} onChange={handleChangeGuardarNombresApellidos} helperText="El nombre es un campo obligatorio  y no debe contener números" error={datos.Doctor.nombres.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" name="apellidos" id={`apellido`} label="Apellidos" type="text" style={{ width: "100%" }} required={true} helperText="El apellido es un campo obligatorio  y no debe contener números" onChange={handleChangeGuardarNombresApellidos} error={datos.Doctor.apellidos.error} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="email" id={`email`} label="Email" type="text" style={{ width: "100%" }} required={true} helperText="El email es un campo obligatorio" onChange={handleChangeGuardarStateCorreo} error={datos.Doctor.email.error} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={clsx(classes.margin, classes.textField)}>
                                <InputLabel required={true} error={datos.Doctor.password.error} htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    style={{ paddingTop: "5px" }}
                                    id="standard-adornment-password"
                                    name="password"
                                    error={datos.Doctor.password.error}
                                    type={datos.Doctor.showPassword.error ? 'text' : 'password'}
                                    value={datos.Doctor.password.valor}
                                    onChange={handleChangeGuardarStatePassword}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {datos.Doctor.showPassword.error ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText>La contraseña debe contener una letra mayúscula,minúscula,número y un caracter especial {"(@#$%¡!*=<>()|¿?&-)"} ,tener como mínimo 8 caracteres y máximo 30 caracteres</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="cedula" id={`cedula`} label="Cédula" type="text" style={{ width: "100%" }}  helperText="La cédula no es campo obligatorio" onChange={handleChangeGuardarState} error={datos.Doctor.cedula.error} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" name="telefono" id={`telefono`} label="Teléfono" type="text" style={{ width: "100%" }} required={true} helperText="El teléfono es un campo obligatorio, y debe incluir su lada  (10)" onChange={handleChangeGuardarTelefono} error={datos.Doctor.telefono.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Género</InputLabel>
                                <Select
                                    error={datos.Doctor.sexo.error}
                                    labelId={`genero`}
                                    id={`genero`}
                                    onChange={handleChangeGuardarStateSexo}
                                    name="sexo"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge el género'}</MenuItem>
                                    {ftGenerarSelectGenero(genero.datos)}
                                </Select>
                                <FormHelperText>El género es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl className={classes.formControl} style={{ width: "100%", margin: "0px" }} >
                                <InputLabel id="demo-simple-select-label" required={true}>Especialidad</InputLabel>
                                <Select
                                    error={datos.Doctor.especialidadId.error}
                                    labelId={`especialidad`}
                                    id={`especialidad`}
                                    onChange={handleChangeGuardarState}
                                    name="especialidadId"
                                    required={true}
                                    defaultValue={-1}
                                >
                                    <MenuItem key={'-1'} selected value={'-1'} disabled>{'Escoge la especialidad'}</MenuItem>
                                    {ftGenerarSelect(especialidades.datos)}
                                </Select>
                                <FormHelperText>La especialidad es un campo obligatorio</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    required={true}
                                    style={{ width: "100%" }}
                                    error={datos.Doctor.fechaEgreso.error}
                                    id="date"
                                    label="Fecha de egreso"
                                    type="date"
                                    helperText="La fecha de egreso es obligatoria"
                                    onChange={handleChangeGuardarStateFechaEgreso}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaEgreso"
                                />
                            </form>
                        </Grid>
                        <Grid item xs={12}>
                            <form className={classes.container} noValidate style={{ width: "100%" }}>
                                <TextField
                                    required={true}
                                    style={{ width: "100%" }}
                                    error={datos.Doctor.fechaNacimiento.error}
                                    id="date"
                                    label="Fecha de nacimiento"
                                    type="date"
                                    helperText="La fecha de nacimiento es obligatoria"
                                    onChange={handleChangeGuardarStateFecha}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="fechaNacimiento"
                                />
                            </form>
                        </Grid>
                    </Grid>
                </div>
            } Titulo=""></Fieldset>
        </>

    );

}

export default Doctor;
